<template>
    <div class="c__task-editor">
        <div class="c__task-input">
            <textarea
                v-model="text"
                class="basic input"
                :placeholder="$t('designer.comment_placeholder')"
            />
        </div>
        <div class="c__task-input-controls">
            <Button
                theme="primary"
                :loading="loading"
                @click="addComment"
                :disable-icon-view-only-on-mobile="true">
                {{ $t('general.save') }}
            </Button>
        </div>
    </div>
</template>

<script>
    import Button from '../../global/buttons/Button'
    import APIFactory from '../../../api/factory'
    import Helpers from '../../../helpers'

    export default {
    	data() {
    		return {
    			text: null,
                loading: false,
            }
        },
    	methods: {
    		addComment($event) {
    			$event.stopPropagation()
    			this.loading = true
    			APIFactory.createComment(
    				this.$route.params.slug,
                    this.$route.params.teamId,
                    this.text,
                    this.$route.params.folderId).then(res => {
    				if (res && res.success) {
    					Helpers.callToast('is-success', this.$t('designer.comment_save_message'))
                        this.$emit('success', res.data.comments)
                        this.text = null
                    }
                }).finally(() => this.loading = false)
            }
        },
        components: {
	        Button
        }
    }
</script>

<style lang="scss" scoped>
    .c__task-editor {
        flex-shrink: 0;
        padding: 12px 16px 16px;

        .c__task-input-controls {
            justify-content: flex-end;
            margin-top: 16px;
            display: flex;
        }
    }
</style>

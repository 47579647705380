<template>
  <MenuLayout
    @search="search"
    @selectPage="selectPage"
    @selectChapter="selectChapter"
    @setMobileMenu="forceShowContent = $event"
    :folder="folder"
    :keyword="keyword"
    :preview="preview"
    :forceShowContent="forceShowContent"
    :current-page-index="currentPageIndex"
    :current-chapter-index="currentChapterIndex">
    <div class="c-display-content" ref="folder">
      <MobileMenu
        :title="title"
        v-if="isMobile"
        @back="forceShowContent = false"
      />
      <div
        :class="{
          'c-display-content-aspect-ratio': true,
          'c-display-content-aspect-ratio--brochure': folder && folder.display_type === 'brochure'
        }">
        <ChapterPage
          class="c-display-page"
          :chapter="currentChapter"
          :folder="folder"
          v-if="!isMobile || isChapterVisible"
          :key="currentChapter ? currentChapter.id : null"
        />
        <ContentPage
          class="c-display-page"
          @previousPage="prev"
          @nextPage="next"
          :preview="preview"
          :page="currentPage"
          :folder="folder"
          :keyword="keyword"
          :chapter="currentChapter"
          :has-next-page="hasNextPage"
          :has-previous-page="hasPreviousPage"
          v-if="!isMobile || !isChapterVisible"
          :key="currentPage ? currentPage.id : '1'"
        />
        <!-- Braces -->
        <div class="c-display-page-brace c-display-page-brace-top"
             v-if="!isMobile && (folder && folder.display_type === 'folder')">
          <img src="images/folder-brace.svg">
        </div>
        <div class="c-display-page-brace c-display-page-brace-bottom"
             v-if="!isMobile && (folder && folder.display_type === 'folder')">
          <img src="images/folder-brace.svg">
        </div>
      </div>
      <MobilePagination
        v-if="isMobile"
        @nextPage="next"
        @previousPage="prev"
        :has-next-page="hasNextPage"
        :has-previous-page="hasPreviousPage"
      />
    </div>
  </MenuLayout>
</template>

<script>
  import {mapGetters} from 'vuex'
  import MobileMenu from './content/MobileMenu'
  import MenuLayout from './content/MenuLayout'
  import ChapterPage from './content/ChapterPage'
  import ContentPage from './content/ContentPage'
  import MobilePagination from './content/MobilePagination'

  export default {
    props: [
      'folder',
      'preview',
    ],
    computed: {
      ...mapGetters({
        isMobile: 'app/isMobileMode'
      }),
      hasNextPage() {
        if (this.isMobile) {
          return this.currentTotalIndex < this.folder.totalElements
        }
        return this.currentChapterIndex < (this.maximumChapters - 1)
          || (this.currentPageIndex > -1 && this.currentPageIndex < (this.maximumChapterPages - 1))
      },
      hasPreviousPage() {
        if (this.isMobile) {
          return this.currentTotalIndex > 1
        }

        return this.currentPageIndex > 0
          || this.currentChapterIndex > 0
      },
      currentPage() {
        // Mobile
        if (this.isMobile && !this.isChapterVisible) {
          return this.folder.chapters
          .flatMap(chapter => chapter.pages)
          .find(page => page.totalIndex === this.currentTotalIndex)
        }

        if (this.folder
          && this.folder.chapters
          && this.folder.chapters[this.currentChapterIndex]
          && this.folder.chapters[this.currentChapterIndex].pages) {
          return this.folder.chapters[this.currentChapterIndex].pages[this.currentPageIndex]
        }
      },
      currentChapter() {
        // Mobile
        if (this.isMobile) {
          let chapter = this.folder.chapters.find(chapter => chapter.totalIndex === this.currentTotalIndex)
          if (!chapter) {
            let page = this.folder.chapters
            .flatMap(chapter => chapter.pages)
            .find(page => page.totalIndex === this.currentTotalIndex)
            if (page) {
              chapter = this.folder.chapters.find(chapter => chapter.id === page.chapter_id)
            }
          }
          return chapter
        }
        return this.folder.chapters[this.currentChapterIndex]
      },
      maximumChapters() {
        if (this.folder
          && this.folder.chapters) {
          return this.folder.chapters.length
        }
      },
      maximumChapterPages() {
        if (this.folder
          && this.folder.chapters
          && this.folder.chapters[this.currentChapterIndex]
          && this.folder.chapters[this.currentChapterIndex].pages) {
          return this.folder.chapters[this.currentChapterIndex].pages.length
        }
      },
      title() {
        if (this.folder) {
          return this.folder.name
        }
      },
      isChapterVisible() {
        if (this.isMobile && this.folder) {
          return this.folder.chapters.find(chapter => chapter.totalIndex === this.currentTotalIndex)
        }
      }
    },
    data() {
      return {
        forceShowContent: false,
        currentPageIndex: 0,
        currentChapterIndex: 0,
        currentTotalIndex: 1,
        keyword: null,
      }
    },
    created() {
      if (this.$route.query.p) {
        let chapter = null
        let page = this.folder.chapters.flatMap(c => c.pages).find(p => p.id == this.$route.query.p)
        if (page) {
          chapter = this.folder.chapters.find(c => c.id == page.chapter_id)
          this.selectPage({
            page,
            chapter
          })
        }
      }
    },
    methods: {
      search($event) {
        this.keyword = $event
        let tmpChapters = this.folder.chapters.map(chapter => {
          return {
            ...chapter,
            pages: chapter.pages.map(page => {
              return {
                ...page,
                objects: page.objects.map(object => {
                  return {
                    ...object,
                    highlighted: this.keyword && ((object.name
                      && object.name.toLowerCase().indexOf(this.keyword.toLowerCase()) > -1)
                      || (object.note && object.note.toLowerCase().indexOf(this.keyword.toLowerCase()) > -1)
                      || (object.tags.filter(tag => {
                        return tag && tag.toLowerCase().indexOf(this.keyword.toLowerCase()) > -1
                      }).length > 0)),
                    note_highlighted: this.keyword && (object.note && object.note.toLowerCase().indexOf(this.keyword.toLowerCase()) > -1)
                  }
                })
              }
            })
          }
        })
        this.$set(this.folder, 'chapters', tmpChapters)
      },
      next() {
        // Mobile
        if (this.isMobile && this.currentTotalIndex < this.folder.totalElements) {
          this.currentTotalIndex++
          return
        }

        if (this.currentChapterIndex < this.maximumChapters
          && this.currentPageIndex < this.maximumChapterPages) {
          this.currentPageIndex++
        }

        if (this.currentPageIndex === this.maximumChapterPages) {
          this.currentPageIndex = 0
          this.currentChapterIndex++
        }
      },
      prev() {
        // Mobile
        if (this.isMobile && this.currentTotalIndex > 0) {
          this.currentTotalIndex--
          return
        }

        if (this.currentPageIndex === 0) {
          this.currentChapterIndex--
          this.currentPageIndex = (this.maximumChapterPages - 1)
          if (this.currentPageIndex < 0) {
            this.currentPageIndex = 0
          }
        } else {
          this.currentPageIndex--
        }
      },
      selectChapter(chapter) {
        this.currentChapterIndex = (chapter.index - 1)
        this.currentTotalIndex = chapter.totalIndex
        this.currentPageIndex = 0
      },
      selectPage($event) {
        this.currentChapterIndex = ($event.chapter.index - 1)
        this.currentTotalIndex = $event.page.totalIndex
        this.currentPageIndex = $event.page.index
      },
    },
    components: {
      MobilePagination,
      MobileMenu,
      MenuLayout,
      ContentPage,
      ChapterPage,
    }
  }
</script>

<style lang="scss" scoped>
  .c-display-content {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .c-display-content-aspect-ratio {
      z-index: 1;
      width: 100%;
      height: 100%;
      display: flex;
      padding: 32px 16px;
      justify-content: space-between;
      max-width: calc((100vh - 32px) * 1344 / 992);
      max-height: calc((100vw - 16px) * (992 / 1344));
      background: url('/images/folder-background.svg') no-repeat center;
      background-size: contain;

      &.c-display-content-aspect-ratio--brochure {
        background: none;

        .c-display-page {
          width: 50%;

          @media (max-width: $breakpoint-mobile) {
            width: 100%;
          }
        }
      }

      @media (max-width: $breakpoint-mobile) {
        padding: 16px;
        max-width: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        background-image: none;
        flex-direction: column;
        justify-content: flex-start;
        max-height: calc(100% - (56px + 72px));
      }

      .c-display-page {
        height: 100%;
        background: $white;
        position: relative;
        width: calc(50% - 8px);
        box-shadow: 0px 0px 2px 2px rgba(255, 255, 255, 1);

        @media (max-width: $breakpoint-mobile) {
          width: 100%;
          box-shadow: none;
        }
      }

      .c-display-page-brace {
        z-index: 2;
        height: 100%;
        width: 130px;
        max-height: 33px;
        position: absolute;
        left: calc(50% - 34px);

        &.c-display-page-brace-top {
          top: 35%;
        }

        &.c-display-page-brace-bottom {
          bottom: 35%;
        }
      }
    }
  }
</style>

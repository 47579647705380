<template>
  <div class="c-container-share u-mt--24">
    <template v-if="!isContainerReleased">
      <div class="c-container-share-header">
        <label class="basic label">{{ $t('settings.teams') }}</label>
        <label class="basic label">{{ $t('freigegeben') }}</label>
      </div>

      <div class="c-container-share-list">
        <div
          class="c-share-entry"
          v-for="team in releasableTeams">
          <div class="entry-title">{{ team.name }}</div>
          <div class="action">
            <b-checkbox
              @input="select(team.id, $event)"
              :value="value.includes(team.id)"
            />
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <b-message type="is-danger">{{ $t('mediacenter.unable_to_update_info', { team: originTeamName }) }}</b-message>
    </template>
  </div>
</template>

<script>
  export default {
    props: [
      'teams',
      'value',
      'container'
    ],
    computed: {
      isContainerReleased() {
        if (this.teams) {
          let currentTeam = this.teams.find(item => item.slug === this.$route.params.teamId)
          if (currentTeam) {
            return this.container.team_id !== currentTeam.id
          }
        }
      },
      originTeamName() {
        if (this.teams) {
          let team = this.teams.find(item => item.id === this.container.team_id)
          if (team) {
            return team.name
          }
        }
      },
      releasableTeams() {
        if (this.teams) {
          return this.teams.filter(item => item.slug !== this.$route.params.slug)
        }
      }
    },
    methods: {
      select(id) {
        let tmp = Array.isArray(this.value) ? [].concat(this.value) : this.value ? [this.value] : []
        if (tmp.includes(id)) {
          tmp = tmp.filter(item => item !== id)
        } else {
          tmp.push(id)
        }
        this.$emit('input', tmp)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .c-container-share {
    .c-container-share-header {
      display: flex;
      justify-content: space-between;
    }

    .c-container-share-list {
      .c-share-entry {
        padding: 8px;
        display: flex;
        margin-top: 4px;
        border-radius: 4px;
        background: $light-blue-color;
        justify-content: space-between;

        .entry-title {}

        .action {
          display: flex;
        }
      }
    }
  }
</style>

import Vue from 'vue'
import $store from '../store'
import VueRouter from 'vue-router'
import AuthRoutes from './routes/auth'
import DisplayRoutes from './routes/display'
import SettingRoutes from './routes/settings'
import WorkspaceRoutes from './routes/workspaces'
import FolderDesignerRoutes from './routes/designer'
import MediaCenterRoutes from './routes/mediacenter'
import SignupRoutes from './routes/signup'
import AdminRoutes from './routes/admin'
import VueI18n from '../lang'
import APIFactory from '@/api/factory'

Vue.use(VueRouter)

const routes = []
.concat(AuthRoutes)
.concat(AdminRoutes)
.concat(SignupRoutes)
.concat(DisplayRoutes)
.concat(SettingRoutes)
.concat(WorkspaceRoutes)
.concat(MediaCenterRoutes)
.concat(FolderDesignerRoutes)

const router = new VueRouter({
  routes
})

function loadLanguages() {
  if (!$store.getters['app/translations']) {
    return APIFactory.findTranslations().then((res) => {
      $store.commit('app/setTranslations', res.data.translations)
      $store.commit('app/setAvailableLanguages', res.data.availableLanguages)
      return $store.getters['app/translations']
    })
  }
  return Promise.resolve($store.getters['app/translations'])
}

router.beforeEach((to, from, next) => {
  loadLanguages().then((res) => {
    Object.keys(res).forEach(t => VueI18n.setLocaleMessage(t, res[t]))

    if (to.matched.some(record => record.meta.auth)) {
      if (!$store.getters['auth/token']) {
        next('/auth/login')
        return
      }
    }

    if (to.query.lang !== from.query.lang) {
      to.query.lang = to.query.lang || from.query.lang || 'de'
      next({query: to.query})
      return
    }
    next()
  })
})

export default router

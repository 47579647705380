import Vue from 'vue'

Vue.directive('tooltip', {
	bind(el, binding) {
		el.addEventListener('mouseenter', function () {
			let $tooltip = document.createElement('div')
			let $tooltipDimension = el.getBoundingClientRect()
			$tooltip.setAttribute('class', 'ctk-tooltip')
			$tooltip.setAttribute('id', 'CtkTooltip')
			$tooltip.innerHTML = binding.value
			$tooltip.style.left = $tooltipDimension.left + ($tooltipDimension.width / 2) + 20 + 'px'
			$tooltip.style.top = $tooltipDimension.top - 30 + 'px'
			document.body.appendChild($tooltip)

			setTimeout(() => {
				let elemToRemove = document.getElementById('CtkTooltip')
				if (elemToRemove) {
					elemToRemove.parentNode.removeChild(elemToRemove)
				}
			}, 3000)
		})
		el.addEventListener('mouseleave', function () {
			let elemToRemove = document.getElementById('CtkTooltip')
			if (elemToRemove) {
				elemToRemove.parentNode.removeChild(elemToRemove)
			}
		})
	}
})

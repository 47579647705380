<template>
  <div class="c-tabs">
    <div class="c-tabs-controls">
      <div :class="{
        'c-tab-control': true,
        'c-tab-control--selected': index === currentTabIndex
      }"
         @click.prevent="currentTabIndex = index"
         v-for="(tab, index) in tabs">
        {{ tab }}
      </div>
    </div>
    <div class="c-tabs-content">
      <slot :name="'tab-' + currentTabIndex" />
    </div>
  </div>
</template>

<script>
  export default {
    props: [
        'tabs',
        'activeTabIndex',
    ],
    data() {
      return {
        currentTabIndex: 0,
      }
    },
    created() {
      this.currentTabIndex = this.activeTabIndex || 0
    }
  }
</script>

<style lang="scss" scoped>
  .c-tabs {
    margin-top: 16px;

    .c-tabs-controls {
      display: flex;
      margin-left: -4px;
      margin-right: -4px;
      justify-content: space-between;
      border-bottom: 1px solid $lighter-gray-color;

      .c-tab-control {
        display: flex;
        font-size: 14px;
        cursor: pointer;
        min-height: 32px;
        border-radius: 4px;
        font-family: Roboto;
        align-items: center;
        text-align: center;
        justify-content: center;
        color: $darker-blue-color;
        flex-basis: calc(50% - 8px);
        background: $light-blue-color;
        margin: 0 4px 16px;

        &.c-tab-control--selected {
          background: $primary-color;
          color: $white;
        }
      }
    }
  }
</style>

<template>
  <drop class="c__split-layout" @drop="handleDrop">
    <Sidebar
        v-show="show"
        :title="title"
        :count="count"
        class="c__split-sidebar">
      <template slot="controls">
        <slot name="sidebar-controls"/>
      </template>

      <sidebar-element
        :icon="icon"
        :key="index"
        :element="element"
        :route-param="routeParam"
        @success="$emit('reload')"
        :selected-elements="selectedElements"
        v-for="(element, index) in elements">
        <RackContext
          :element="element"
          v-if="routeParam === 'rackId'"
          @edit="editDialogData = $event"
          @success="$emit('reload')"
          @delete="confirmDialogData = $event"
        />
        <MediaContainerContext
          :element="element"
          @success="$emit('reload')"
          @edit="editDialogData = $event"
          @delete="confirmDialogData = $event"
          v-if="routeParam === 'containerId'"
        />
      </sidebar-element>

      <!-- Modals -->
      <ConfirmModal
        v-if="confirmDialogData"
        :active="!!confirmDialogData"
        :title="confirmDialogData.title"
        :message="confirmDialogData.message"
        :function="confirmDialogData.function"
        @close="confirmDialogData = null"
        @success="success"
      />
      <ContainerModal
        v-if="!!editDialogData && editDialogData.type === 'container'"
        :container="editDialogData.container"
        :activation="!!editDialogData"
        :teams="teams"
        @success="success"
        @reset="reset"
      />
      <RackModal
        v-if="!!editDialogData && editDialogData.type === 'rack'"
        :activation="!!editDialogData"
        :rack="editDialogData.rack"
        @success="success"
        @reset="reset"
      />
    </Sidebar>
    <section class="c__split-content">
      <slot/>
    </section>
  </drop>
</template>

<script>
import Sidebar from './Sidebar'
import SidebarElement from './SidebarElement'
import RackContext from '../workspace/RackContext'
import RackModal from '../workspace/modals/RackModal'
import ConfirmModal from '../global/modals/ConfirmModal'
import ContainerModal from '../mediacenter/modals/ContainerModal'
import MediaContainerContext from '../mediacenter/MediaContainerContext'

export default {
  props: [
    'icon',
    'show',
    'teams',
    'title',
    'count',
    'elements',
    'routeParam',
    'selected-elements'
  ],
  data() {
    return {
      confirmDialogData: null,
      editDialogData: null,
    }
  },
  methods: {
    success() {
      this.editDialogData = null
      this.confirmDialogData = null
      this.$emit('reload')
    },
    reset() {
      this.editDialogData = null
    },
    handleDrop(data, $event) {
      this.$emit('drop', {
        data,
        nativeEvent: $event
      })
    }
  },
  components: {
    MediaContainerContext,
    ContainerModal,
    SidebarElement,
    ConfirmModal,
    RackContext,
    RackModal,
    Sidebar,
  }
}
</script>

<style lang="scss" scoped>
.c__split-layout {
  width: 100%;
  height: 100%;
  display: flex;

  .c__split-sidebar {
    flex: 40%;
    max-width: 560px;
    overflow: visible;
    padding: 48px 56px 0 56px;
    min-width: (560px - 80px);
    background: $light-blue-color;

    @media (max-width: $breakpoint-mobile) {
      padding: 0;
      max-width: 100%;
      min-width: 100%;
    }
  }

  .c__split-content {
    flex: 60%;
    background: $white;
    padding: 48px 56px 0 56px;

    @media (max-width: $breakpoint-mobile) {
      width: 100%;
      max-width: 100%;
      padding: 0 16px;
    }
  }
}
</style>

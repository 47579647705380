import Vue from 'vue'
import Vuex from 'vuex'
import ui from './modules/ui'
import app from './modules/app'
import auth from './modules/auth'
import help from './modules/help'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [
    createPersistedState({
      key: new Date().getDay(),
      paths: [
        'app.width',
        'app.lastURL',
        'app.version',
        'app.forceShowRack',
        'app.forceShowMenu',
        'app.apiURL',
        'app.availableLanguages',
        'auth.firstLogin',
        'auth.superadmin',
        'auth.avatar',
        'auth.userId',
        'auth.token',
        'auth.apps',
        'ui.tooltipDelay'
      ]
    })
  ],
  modules: {
    ui,
    app,
    auth,
    help,
  }
})

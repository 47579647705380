<template>
  <div class="c__service-step">
    <div class="c__service-container">
      <img class="logo" src="images/do-logo.svg">
      <div class="headline">{{ $t('signup.version') }}</div>
      <PaymentSwitch/>
      <ServiceGrid>
        <ServiceGridElement
          :idx="idx"
          :key="element.id"
          :element="element"
          :coupon="validCoupon"
          v-for="(element, idx) in plans"
        />
      </ServiceGrid>
      <div class="c__service-container--spacer"/>
      <div class="c__service-container">
        <ServiceGrid :change-plan-mode="true" v-if="hasCoupon">
          <form class="c-form c-coupon-form">
            <div class="c-form-element">
              <div class="c-form-label">{{ $t('signup.coupon_code') }}</div>
              <input
                v-model="coupon"
                class="c-form-input coupon-field"
                :placeholder="$t('signup.coupon_code_placeholder')"
              />
            </div>
            <IconButton
              class="u-ml--8"
              :disabled="!coupon"
              @click="checkCoupon"
              icon="images/icons/check--white.svg"
              :theme="signupData.coupon ? 'green' : 'primary'"
            />
          </form>
        </ServiceGrid>
        <div
          class="c-coupon-toggle"
          @click="toggleCoupon"
          v-else>
          {{ $t('signup.coupon_exists') }}
        </div>
        <a href="https://digital-folder.com" class="c-poweredBy-container u-mt--32" target="_blank">
          <img src="images/do-logo.png">
          <div class="u-ml--8">Powered by Digital-Folder.com</div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
  import Helpers from '../../helpers'
  import APIFactory from '../../api/factory'
  import ServiceGrid from './service/ServiceGrid'
  import PaymentSwitch from './service/PaymentSwitch'
  import ServiceGridElement from './service/ServiceGridElement'
  import IconButton from '../global/buttons/IconButton'

  export default {
    props: [
      'plans',
      'signup-data'
    ],
    data() {
      return {
        coupon: null,
        hasCoupon: false,
        validCoupon: null,
      }
    },
    created() {
      if (this.signupData && this.signupData.coupon) {
        this.validCoupon = this.signupData.coupon
        this.coupon = this.validCoupon.coupon
      }
    },
    methods: {
      checkCoupon() {
        this.signupData.coupon = null
        this.validCoupon = null
        APIFactory.checkCoupon(this.coupon).then(res => {
          if (res && res.success) {
            Helpers.callToast('is-success', this.$t('signup.coupon_valid'))
            this.signupData.coupon = Object.assign({}, {coupon: this.coupon}, res.data)
            this.validCoupon = res.data
            if (this.validCoupon.free) {
              this.updateQuery({
                users: 1
              })
            }
          }
        })
      },
      updateQuery(q) {
        let query = JSON.parse(JSON.stringify(this.$route.query))
        query = Object.assign({}, query, q)
        this.$router
        .push({query})
        .catch((err) => {
        })
      },
      toggleCoupon() {
        this.hasCoupon = !this.hasCoupon
      }
    },
    components: {
      IconButton,
      ServiceGridElement,
      PaymentSwitch,
      ServiceGrid,
    }
  }
</script>

<style lang="scss" scoped>
  .c__service-step {
    width: 100%;
    height: 100%;
    padding: 16px;
    overflow-y: auto;
    background: $light-blue-color;

    .c__service-container {
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
    }

    .c__service-container--spacer {
      width: 50%;
      height: 1px;
      margin: 24px 0;
      background: $primary-color;
      background-image: linear-gradient(to right, #fff, $primary-color, #fff);

      @media (max-width: $breakpoint-mobile) {
        width: 100%;
      }
    }
  }

  .logo {
    width: 96px;
    height: 96px;
    margin-top: 24px;
  }

  .headline {
    font-size: 24px;
    margin-top: 16px;
    font-weight: 500;
    margin-bottom: 24px;
    font-family: Roboto;
    color: $darker-blue-color;

    @media (max-width: $breakpoint-mobile) {
      text-align: center;
      font-size: 20px;
      padding: 0 8px;
    }
  }

  .c-coupon-form {
    width: 50%;
    display: flex;
    align-items: flex-end;

    .c-form-element {
      width: 100%;
      flex-shrink: 0;
      max-width: 400px;

      @media(max-width: $breakpoint-mobile) {
        max-width: 100%;
      }
    }
  }

  .c-coupon-toggle {
    font-size: 14px;
    cursor: pointer;
    font-weight: 500;
    color: $darker-blue-color;
  }
</style>

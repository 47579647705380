<template>
    <div class="c-references u-mt--16">
        <slot />
        <div v-show="!references || references.length === 0"
            class="c-references--empty u-pb--32 u-pt--32">
            {{ $t('mediacenter.references_empty') }}
        </div>
    </div>
</template>

<script>
    export default {
    	props: [
    		'references'
        ]
    }
</script>

<style lang="scss" scoped>
    .c-references {
        .c-references--empty {
            font-size: 14px;
            font-weight: 500;
            text-align: center;
            font-family: Roboto;
            color: $darker-blue-color;
        }
    }
</style>

<template>
  <SignupSidebar
      :plans="plans"
      :coupon="signupData.coupon"
      :link="'/auth/signup/company'"
      :title="$t('signup.payment_heading')">
    <b-loading :active="loading" :is-full-page="true"/>
    <div class="c-container-wrapper" v-show="!loading">
      <div class="c-container">
        <template v-if="!isMobile">
          <div class="c-headline-1 u-align-center">{{ $t('signup.payment_heading') }}</div>
          <Progress
              :index="3"
          />
        </template>
        <form class="c-form c-form-responsive--mt-48-">
          <Payments
            :stripe="stripe"
            :country="signupData.country"
            @change="changedPaymentSwitch"
            :stripe-elements="stripeElements"
          />
          <div class="c-row c-row-responsive--mt-24-8">
            <div class="c-col c-col-12 modal-controls modal-controls--spacing">
              <Button
                  theme="default"
                  :disable-icon-view-only-on-mobile="true"
                  @click="hrefTo('/auth/signup/company')">
                {{ $t('signup.back') }}
              </Button>
              <Button
                  theme="primary"
                  :disabled="!paymentElement"
                  @click="handlePaymentInformation"
                  :disable-icon-view-only-on-mobile="true">
                {{ $t('signup.continue') }}
              </Button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </SignupSidebar>
</template>

<script>
import SignupSidebar from '../signup/SignupSidebar'
import Button from '../global/buttons/Button'
import APIFactory from '../../api/factory'
import Payments from './payment/Payments'
import Helpers from '../../helpers'
import Progress from './Progress'
import {mapGetters} from 'vuex'

export default {
  props: [
    'plans',
    'signupData',
  ],
  computed: {
    ...mapGetters({
      isMobile: 'app/isMobileMode'
    })
  },
  data() {
    return {
      loading: false,
      stripeElements: null,
      paymentElement: null,
      selectedPaymentType: null,
      setupIntentClientSecret: null,
      stripe: Stripe(process.env.VUE_APP_STRIPE_KEY, {
        locale: this.$i18n.locale
      }),
    }
  },
  created() {
    this.loadSetupIntentClientSecret()
    this.stripeElements = this.stripe.elements()
  },
  methods: {
    loadSetupIntentClientSecret() {
      this.loading = true
      APIFactory.createSetupIntent().then(res => {
        if (res && res.success) {
          this.setupIntentClientSecret = res.data.client_secret
        }
      }).finally(() => this.loading = false)
    },
    changedPaymentSwitch($event) {
      switch ($event.elementType) {
        case 'card':
        case 'iban':
          this.paymentElement = this.stripeElements.getElement($event.elementType)
          this.selectedPaymentType = $event.elementType
          break
        case 'sofort':
          this.paymentElement = {}
          this.selectedPaymentType = $event.elementType
          break
      }
    },
    async handlePaymentInformation() {
      let paymentDetails = {
        billing_details: {
          email: this.signupData.email,
          name: this.signupData.firstname + ' ' + this.signupData.lastname,
          address: {
            city: this.signupData.city,
            line1: this.signupData.street,
            country: this.signupData.country,
            postal_code: this.signupData.postal_code,
          }
        }
      }

      this.loading = true
      let setupIntentPromise = null
      switch (this.selectedPaymentType) {
        case 'card':
          paymentDetails.card = this.paymentElement
          setupIntentPromise = this.stripe.confirmCardSetup(this.setupIntentClientSecret, {
            payment_method: paymentDetails
          })
          break
        case 'iban':
          paymentDetails.sepa_debit = this.paymentElement
          setupIntentPromise = this.stripe.confirmSepaDebitSetup(this.setupIntentClientSecret, {
            payment_method: paymentDetails
          })
          break
        case 'sofort':
          let redirectURL = location.origin
              + '/auth/signup/payment/sofort?json='
              + JSON.stringify(this.signupData)

          // Language
          if (this.$route.query.lang) {
            redirectURL += 'lang=' + this.$route.query.lang
          }

          // Payment Interval
          if (this.$route.query.payment_interval) {
            redirectURL += 'payment_interval=' + this.$route.query.payment_interval
          }

          // Plan
          if (this.$route.query.plan) {
            redirectURL += 'plan=' + this.$route.query.plan
          }

          // Users
          if (this.$route.query.users) {
            redirectURL += 'users=' + this.$route.query.users
          }

          let {error} = await this.stripe.createSource({
            type: 'sofort',
            currency: 'eur',
            redirect: {
              return_url: redirectURL
            }
          })
          console.log(error)
          break
      }

      if (setupIntentPromise) {
        setupIntentPromise.then(res => {
          this.loading = false
          if (res.error) {
            Helpers.callToast('is-danger', res.error.message)
            return
          }

          this.signupData.pm = res.setupIntent.payment_method
          this.signupData.payment_type = this.selectedPaymentType
          this.hrefTo('/auth/signup/summary')
        })
      }
    },
    hrefTo(link) {
      this.$router.push({
        path: link,
        query: this.$route.query
      })
    }
  },
  components: {
    SignupSidebar,
    Payments,
    Progress,
    Button,
  }
}
</script>

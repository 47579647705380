<template>
  <div @click.prevent="$emit('selectPage')"
       :class="{
    	    'u-mt--8': true,
    	    'c-page-menu': true,
    	    'c-page-menu-selected': selected,
    	    'c-page-menu-highlighted': highlighted,
        }">
    <template v-if="folder.order_type && folder.order_type === 'number'">{{ number }}.{{ subNumber }}</template>
    {{ title }}
  </div>
</template>

<script>
export default {
  props: [
    'title',
    'folder',
    'number',
    'selected',
    'sub-number',
    'highlighted',
  ],
}
</script>

<style lang="scss" scoped>
.c-page-menu {
  cursor: pointer;
  font-size: 14px;
  padding: 4px 8px;
  font-family: Roboto;
  color: $darker-blue-color;

  &:hover,
  &.c-page-menu-selected {
    font-weight: Bold;
    color: $primary-color;
  }

  &.c-page-menu-highlighted {
    border-radius: 4px;
    background: $yellow-color;
  }
}
</style>

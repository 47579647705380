<template>
  <div class="c__page-contents">
    <div class="page-title">
      <input
        v-model="page.name"
        class="basic expanded margin-reset is-focusable"
        :placeholder="$t('designer.page_title')"
      />
    </div>
    <div class="item-grid">
      <template v-if="elementCount === 1">
        <div class="item-row item-row--single">
          <div class="item">
            <MediaDropzone
              :key="0"
              :containers="containers"
              :maximum-items="maximumItemCount"
              :maximum-selectable-items="elementCount"
              :hide-context-menu="hideContextMenus"
              :item="findItemByIndex(0)"
              @delete="deleteItem"
              @setAsNew="setAsNew"
              @hide-context-menu="hideContextMenu"
              @place-item="placeItem($event, 0)"
            />
          </div>
        </div>
      </template>
      <template v-if="elementCount === 2">
        <div class="item-row item-row--dual">
          <div class="item">
            <MediaDropzone
              :key="0"
              :containers="containers"
              :maximum-items="maximumItemCount"
              :hide-context-menu="hideContextMenus"
              :maximum-selectable-items="elementCount"
              :item="findItemByIndex(0)"
              @delete="deleteItem"
              @setAsNew="setAsNew"
              @move-item="moveItem($event, 0)"
              @place-item="placeItem($event, 0)"
              @hide-context-menu="hideContextMenu"
            />
          </div>
          <div class="item">
            <MediaDropzone
              :key="1"
              :containers="containers"
              :maximum-items="maximumItemCount"
              :maximum-selectable-items="elementCount"
              :hide-context-menu="hideContextMenus"
              :item="findItemByIndex(1)"
              @delete="deleteItem"
              @setAsNew="setAsNew"
              @move-item="moveItem($event, 1)"
              @place-item="placeItem($event, 1)"
              @hide-context-menu="hideContextMenu"
            />
          </div>
        </div>
      </template>
      <template v-if="elementCount === 4 || elementCount === 6">
        <div
          :key="idx"
          class="item-row"
          v-for="(row, idx) in rowCount">
          <div class="item">
            <MediaDropzone
              :key="(row - 1) + idx"
              :containers="containers"
              :maximum-items="maximumItemCount"
              :hide-context-menu="hideContextMenus"
              :maximum-selectable-items="elementCount"
              :item="findItemByIndex((row - 1) + idx)"
              @delete="deleteItem"
              @setAsNew="setAsNew"
              @move-item="moveItem($event, (row - 1) + idx)"
              @place-item="placeItem($event, (row - 1) + idx)"
              @hide-context-menu="hideContextMenu"
            />
          </div>
          <div class="item">
            <MediaDropzone
              :key="row + idx"
              :containers="containers"
              :maximum-items="maximumItemCount"
              :maximum-selectable-items="elementCount"
              :item="findItemByIndex(row + idx)"
              :hide-context-menu="hideContextMenus"
              @delete="deleteItem"
              @setAsNew="setAsNew"
              @move-item="moveItem($event, row + idx)"
              @place-item="placeItem($event, row + idx)"
              @hide-context-menu="hideContextMenu"
            />
          </div>
        </div>
      </template>
    </div>
    <div class="item-control">
      <Button
        theme="default-error"
        :disable-icon-view-only-on-mobile="true"
        @click="confirmDialog = true">
        {{ $t('designer.remove_page') }}
      </Button>

      <!-- Modals -->
      <ConfirmModal
        :active="confirmDialog"
        :title="$t('designer.remove_page')"
        :message="$t('designer.delete_page_message')"
        @close="confirmDialog = false"
        :function="handleDeletePage"
      />
    </div>
  </div>
</template>

<script>
import ConfirmModal from '@/components/global/modals/ConfirmModal'
import Button from '../../global/buttons/Button'
import MediaDropzone from '../MediaDropzone'
import Helpers from '../../../helpers'

export default {
  props: [
    'page',
    'folder',
    'containers',
    'elementCount'
  ],
  computed: {
    rowCount() {
      return Math.round(this.elementCount / 2)
    },
    maximumItemCount() {
      if (this.page && this.page.objects) {
        return this.elementCount - this.page.objects.length
      }
      return this.elementCount
    }
  },
  data() {
    return {
      hideContextMenus: false,
      confirmDialog: false
    }
  },
  methods: {
    /**
     *  Handles the context menu display
     */
    hideContextMenu($event) {
      this.hideContextMenus = $event
    },
    createUUID() {
      return Helpers.createUUID()
    },
    placeItem($event, index) {
      if (this.page) {
        this.page.objects = this.page.objects.filter(item => item.index !== index)

        let newObject = JSON.parse(JSON.stringify($event.items[0]))
        if (newObject) {
          newObject.index = index
          this.page.objects.push(newObject)
        }

        if ($event.items && $event.items.length > 0) {
          // Freie Indices herausfinden
          let freeIndices = []
          let blockedIndices = this.page.objects.map(item => item.index)
          for (let i = 0; i < this.elementCount; i++) {
            if (!blockedIndices.includes(i)) {
              freeIndices.push(i)
            }
          }

          // Add items on empty index
          for (let i = 1; i < $event.items.length; i++) {
            newObject = JSON.parse(JSON.stringify($event.items[i]))
            if (newObject) {
              newObject.index = freeIndices.shift()
              this.page.objects.push(newObject)
            }
          }
        }

        this.$emit('updatePage', this.page)
      }
    },
    deleteItem($event) {
      if (this.page && this.page.objects) {
        this.page.objects = this.page.objects.filter(item => item.index !== $event.index)
        this.$emit('updatePage', this.page)
      }
    },
    setAsNew($event) {
      if ($event && this.page) {
        this.page.showAsNew = $event
        this.$emit('updatePage', this.page)
      }
    },
    findItemByIndex(index) {
      if (this.page && this.page.objects) {
        return this.page.objects.find(item => item.index === index)
      }
    },
    /**
     * Moves an item on the grid
     * @param $event - Index of source element
     * @param targetIndex - Index of target element
     */
    moveItem($event, targetIndex) {
      if (this.page && this.page.objects && ($event || $event === 0) && (targetIndex || targetIndex === 0)) {
        let targetPage = this.findItemByIndex(targetIndex)
        let sourcePage = this.findItemByIndex($event)
        if (targetPage) {
          targetPage.index = $event
        }
        if (sourcePage) {
          sourcePage.index = targetIndex
        }
        this.$emit('updatePage', this.page)
      }
    },
    handleDeletePage() {
      this.$emit('deletePage', this.page.id)
      return Promise.resolve()
    }
  },
  components: {
    MediaDropzone,
    ConfirmModal,
    Button
  }
}
</script>

<style lang="scss" scoped>
.c__page-contents {
  width: 100%;
  height: 100%;
  padding: 32px 56px;
  border-radius: 8px;
  border: solid 1px $light-blue-color;

  @media (max-width: $breakpoint-mobile) {
    padding: 0;
    border: none;
    overflow-y: auto;
  }

  .page-title {
    @media (max-width: $breakpoint-mobile) {
      margin-top: 20px;
    }
  }

  .item-control {
    display: flex;
    margin-top: 24px;
    justify-content: center;

    @media (max-width: $breakpoint-mobile) {
      display: none;
    }
  }
}
</style>

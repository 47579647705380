<template>
  <div class="c-password-input">
    <input
      required
      :value="value"
      :type="inputType"
      class="c-form-input"
      :placeholder="$t('signup.password_placeholder')"
      @input="$emit('input', $event.target.value)"
    />
    <div
      class="dice"
      @click.prevent="randomizePassword">
      <Icon
        size="24x24"
        src="images/icons/dice.svg"
      />
    </div>
    <div
      class="visibility-switch"
      @click.prevent="switchVisibility">
      <Icon
        size="24x24"
        :src="iconType"
      />
    </div>
  </div>
</template>

<script>
  import Icon from '../Icon'
  import Helpers from '@/helpers'

  export default {
    props: [
        'value'
    ],
    computed: {
      inputType() {
        return this.visible
            ? 'text'
            : 'password'
      },
      iconType() {
        return this.visible
            ? 'images/icons/visible.svg'
            : 'images/icons/invisible.svg'
      }
    },
    data() {
      return {
        visible: false,
      }
    },
    methods: {
      switchVisibility() {
        this.visible = !this.visible
      },
      randomizePassword() {
        let password = Helpers.randomizePassword()
        this.$emit('input', password)
      }
    },
    components: {
      Icon
    }
  }
</script>

<style lang="scss" scoped>
  .c-password-input {
    position: relative;

    .visibility-switch {
      top: 16px;
      right: 16px;
      cursor: pointer;
      position: absolute;
    }

    .dice {
      top: 16px;
      right: 56px;
      cursor: pointer;
      position: absolute;
    }
  }
</style>

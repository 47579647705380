<template>
  <base-modal
    :title="title"
    :active="active"
    @close="$emit('close')">
    <div class="c-note-modal">
      <template v-if="!readOnly">
        <label class="basic">{{ $t('designer.note') }}</label>
        <textarea
          v-model="note"
          class="basic expanded"
          :placeholder="$t('mediacenter.description_placeholder')"
        />
      </template>
      <div
        v-else
        v-html="urlify(note)"></div>
    </div>
    <div
      slot="actions"
      :class="{
        'modal-controls': true,
        'modal-controls--spacing': !readOnly,
        'modal-controls--center': readOnly
      }">
      <template v-if="!readOnly">
        <Button
          :disable-icon-view-only-on-mobile="true"
          @click="$emit('close')"
          theme="default-error">
          {{ $t('general.cancel') }}
        </Button>
        <Button
          :disable-icon-view-only-on-mobile="true"
          @click="confirm"
          theme="primary">
          {{ $t('general.save') }}
        </Button>
      </template>
      <template v-else>
        <Button
          @click="$emit('close')"
          :disable-icon-view-only-on-mobile="true"
          theme="primary">
          {{ $t('general.ok') }}
        </Button>
      </template>
    </div>
  </base-modal>
</template>

<script>
  import BaseModal from '../../global/modals/BaseModal'
  import Button from '../../global/buttons/Button'
  import {mapGetters} from 'vuex'

  export default {
    props: [
      'active',
      'element',
      'readOnly',
    ],
    computed: {
      ...mapGetters({
        isMobile: 'app/isMobileMode'
      }),
      title() {
        if (this.readOnly) {
          return this.$t('designer.note_title_read')
        }
        return this.$t('designer.note_title_write')
      }
    },
    data() {
      return {
        note: null
      }
    },
    created() {
      if (this.element) {
        this.note = this.element.note
      }
    },
    methods: {
      confirm() {
        this.element.note = this.note
        this.$emit('close')
      },
      urlify(text) {
        if (text) {
          let urlRegex = /(https?:\/\/[^\s]+)/g;
          return text.replace(urlRegex, function(url) {
            return '<a target="_blank" style="text-decoration: underline;" href="' + url + '">' + url + '</a>';
          })
        }
        return text
      }
    },
    components: {
      BaseModal,
      Button
    }
  }
</script>

<style lang="scss" scoped>
  .c-note-modal {
    margin-top: 24px;
  }
</style>

<template>
  <div class="c__content-designer">
    <div class="c__content-designer-content">
      <div class="c__page-designer-wrapper">
        <div class="c__page-designer">
          <div class="c__page-designer-column c__page-designer-column--left">
            <IconButton
              v-if="hasPrevious"
              @click="$emit('previous')"
              icon="images/icons/arrow-left.svg"
            />
          </div>
          <div class="c__page-designer-content">
            <slot/>
          </div>
          <div class="c__page-designer-column c__page-designer-column--right">
            <IconButton
              v-if="hasNext"
              @click="$emit('next')"
              icon="images/icons/arrow-right.svg"
            />
          </div>

          <!-- Folder-Background-Control -->
          <FolderBackgroundContext
            @setBackground="setBackground"
            :containers="containers"
            :folder="folder"
          />
        </div>
      </div>
    </div>
    <div class="c__content-designer-sidebar"
         v-show="showSidebar && !isMobile">
      <div class="c__page-grid">
        <PageGridButton
          :elements="1"
          @select="select"
          :selected="element && element.grid === 1"
          :tooltip="$t('designer.change_page_layout_1')"
        />
        <PageGridButton
          :elements="2"
          @select="select"
          :selected="element && element.grid === 2"
          :tooltip="$t('designer.change_page_layout_2')"
        />
        <PageGridButton
          :elements="4"
          @select="select"
          :selected="element && element.grid === 4"
          :tooltip="$t('designer.change_page_layout_4')"
        />
        <PageGridButton
          :elements="6"
          @select="select"
          :selected="element && element.grid === 6"
          :tooltip="$t('designer.change_page_layout_6')"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import IconButton from '../global/buttons/IconButton'
  import PageGridButton from './buttons/PageGridButton'
  import {mapGetters} from 'vuex'
  import FolderBackgroundContext from '@/components/designer/FolderBackgroundContext';

  export default {
    props: [
      'show-sidebar',
      'hasPrevious',
      'containers',
      'hasNext',
      'element',
      'folder'
    ],
    computed: {
      ...mapGetters({
        isMobile: 'app/isMobileMode',
        tooltipDelay: 'ui/tooltipDelay'
      })
    },
    methods: {
      select($event) {
        this.$emit('select-grid', $event)
      },
      setBackground($event) {
        this.$emit('setBackground', $event)
      }
    },
    components: {
      FolderBackgroundContext,
      IconButton,
      PageGridButton
    }
  }
</script>

<style lang="scss" scoped>
  .c__content-designer {
    width: 100%;
    height: 100%;
    display: flex;
    position: relative;
    justify-content: center;

    .c__content-designer-content {
      flex-basis: calc(100% - 80px);
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;

      @media (max-width: $breakpoint-mobile) {
        flex-basis: 100%;
      }
    }

    .c__content-designer-sidebar {
      height: 100%;
      flex-basis: 79px;
      border-left: 1px solid #d8d8d8;
      background: $white;

      .c__page-grid {
        display: flex;
        margin-top: 16px;
        align-items: center;
        flex-direction: column;
      }
    }
  }

  .c__page-designer-wrapper {
    width: 100%;
    height: calc(100% - 65px);

    @media (max-width: $breakpoint-mobile) {
      height: 100%;
      margin: 0;
    }

    .c__page-designer {
      height: 100%;
      display: flex;
      align-content: center;
      justify-content: center;

      .c__page-designer-content {
        display: flex;
        max-width: 608px;
        max-height: 100%;
        align-items: center;
        background: $white;
        border-radius: 4px;
        width: 100%;
        height: 100%;
      }

      .c__page-designer-column {
        width: 56px;
        flex-grow: 0;
        display: flex;
        flex-shrink: 0;
        align-items: center;

        @media (max-width: $breakpoint-mobile) {
          display: none;
        }

        &.c__page-designer-column--left {
          margin-left: 10px;
        }

        &.c__page-designer-column--right {
          justify-content: flex-end;
          margin-right: 10px;
        }
      }
    }
  }
</style>

<template>
  <div class="c-content-page">
    <template v-if="page">
      <div class="c-content-header">
        <div class="c-content-chapter-title-wrapper">
          <div class="c-content-chapter-title">
            <div class="c-content-chapter-title-text">
              <v-clamp autoresize :max-lines="1">
                <template v-if="folder && folder.order_type === 'number'">{{ chapter.index }} - </template>
                {{ chapter.name }}
              </v-clamp>
            </div>
            <div class="c-content-title-highlight"/>
          </div>
          <b-tooltip :label="page.name" :delay="tooltipDelay">
            <div class="c-content-page-title">
              <v-clamp autoresize :max-lines="2">
                <template v-if="folder && folder.order_type === 'number'">{{ chapter.index }}.{{ page.index + 1 }} - </template>
                {{ page.name }}
              </v-clamp>
            </div>
          </b-tooltip>
        </div>
        <div class="c-content-chapter-title-controls">
          <folder-page-context
            :page="page"
            :folder="folder"
          />
        </div>
      </div>
      <div class="item-grid animWhiteOut">
        <template v-if="page.grid === 1">
          <div class="item-row item-row--single">
            <div class="item">
              <MediaDropzone
                :item="findItemByIndex(0)"
                :preview="preview"
                :read-only="true"
                :folder="folder"
                class="ie-fix"
              />
            </div>
          </div>
        </template>
        <template v-if="page.grid === 2">
          <div class="item-row item-row--dual">
            <div class="item">
              <MediaDropzone
                  :item="findItemByIndex(0)"
                  :preview="preview"
                  :read-only="true"
                  :folder="folder"
                  class="ie-fix"
              />
            </div>
            <div class="item">
              <MediaDropzone
                :item="findItemByIndex(1)"
                :preview="preview"
                :read-only="true"
                :folder="folder"
                class="ie-fix"
              />
            </div>
          </div>
        </template>
        <template v-if="page.grid === 4 || page.grid === 6">
          <div
              :key="idx"
              class="item-row"
              v-for="(row, idx) in rowCount">
            <div class="item">
              <MediaDropzone
                :item="findItemByIndex((row - 1) + idx)"
                :preview="preview"
                :read-only="true"
                :folder="folder"
                class="ie-fix"
              />
            </div>
            <div class="item">
              <MediaDropzone
                :item="findItemByIndex(row + idx)"
                :preview="preview"
                :read-only="true"
                :folder="folder"
                class="ie-fix"
              />
            </div>
          </div>
        </template>
      </div>
    </template>

    <IconButton
        v-if="hasPreviousPage && !isMobile"
        icon="images/icons/arrow-left.svg"
        @click="$emit('previousPage')"
        class="c-content-control c-content-control--back"
    />
    <IconButton
        v-if="hasNextPage && !isMobile"
        icon="images/icons/arrow-right.svg"
        @click="$emit('nextPage')"
        class="c-content-control c-content-control--next"
    />
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import VClamp from 'vue-clamp'
import MediaDropzone from '../../designer/MediaDropzone'
import IconButton from '../../global/buttons/IconButton'

export default {
  props: [
    'page',
    'folder',
    'chapter',
    'preview',
    'hasNextPage',
    'hasPreviousPage',
  ],
  computed: {
    ...mapGetters({
      isMobile: 'app/isMobileMode',
      tooltipDelay: 'ui/tooltipDelay'
    }),
    rowCount() {
      return Math.round(this.page.grid / 2)
    },
  },
  data() {
    return {}
  },
  methods: {
    findItemByIndex(index) {
      if (this.page && this.page.objects) {
        return this.page.objects.find(item => item.index === index)
      }
    },
  },
  components: {
    VClamp,
    IconButton,
    MediaDropzone
  }
}
</script>

<style lang="scss" scoped>
.c-content-page {
  padding: 5% 7%;
  position: relative;
  color: $darker-blue-color;

  @media (max-width: $breakpoint-mobile) {
    padding: 0px;
  }

  .c-content-header {
    @apply
      flex
      justify-between;

    .c-content-chapter-title {
      .c-content-chapter-title-text {
        font-size: 1rem;
      }

      .c-content-title-highlight {
        width: 56px;
        height: 2px;
        margin: 8px 0;
        background: $yellow-color;
      }
    }

    .c-content-page-title {
      font-size: 1.5rem;
      //max-height: 70px;
      //overflow: hidden;
      word-break: break-word;
    }
  }

  .c-content-control {
    position: absolute;

    &.c-content-control--next {
      right: 16px;
      top: calc(50% - 20px);
    }

    &.c-content-control--back {
      left: 16px;
      top: calc(50% - 20px);
    }
  }
}

.ie-fix {
  position: absolute;
}
</style>

<template>
  <SidebarLayout
    :key="sidebarKey"
    :title="title">
    <Loader v-if="loading"/>
    <b-columns v-else>
      <b-column :key="renderingKey">
        <b-card
          class="mb-2"
          :key="index"
          v-if="group.entries.length > 0"
          v-for="(group, index) in permissions">
          <PermissionGroup
            :enable-header-controls="true"
            :value="hasAllGroupEntries(group)"
            @input="toggleAllGroupEntries($event, group)"
            :title="$t('permissions.' + group.name)">
            <Permission
              :key="idx"
              @toggle="toggle"
              :permission="permission.name"
              :description="$t(permission.description)"
              v-for="(permission, idx) in group.entries"
              :name="$t('permissions.' + permission.name)"
              :value="role.permissions.includes(permission.name)"
            />
          </PermissionGroup>
        </b-card>
        <b-button
          type="is-primary"
          :loading="loading"
          :disabled="loading"
          @click.prevent="save">
          {{ $t('general.save') }}
        </b-button>
      </b-column>
    </b-columns>
  </SidebarLayout>
</template>

<script>
  import PermissionGroup from '../../components/settings/PermissionGroup'
  import SidebarLayout from '../../components/settings/SidebarLayout'
  import Permission from '../../components/settings/Permission'
  import Loader from '../../components/global/Loader'
  import APIFactory from '../../api/factory'
  import Helper from '../../helpers'
  import {mapGetters} from 'vuex'

  export default {
    computed: {
      ...mapGetters({
        apps: 'auth/apps'
      }),
      groups() {
        return this.permissions
        .map(item => item.group)
        .filter((v, i, a) => a.indexOf(v) === i)
      },
      title() {
        if (this.role) {
          return this.$t('settings.permissions_for')
            + ' '
            + (this.$te('settings.role_' + this.role.name)
              ? this.$t('settings.role_' + this.role.name)
              : this.role.name)
        }
      }
    },
    data() {
      return {
        sidebarKey: Math.random(),
        permissions: [],
        loading: false,
        role: null,
        renderingKey: Math.random()
      }
    },
    created() {
      this.load()
    },
    methods: {
      load() {
        this.loading = true
        Promise.all([
          APIFactory.findRole(this.$route.params.slug, this.$route.params.roleId),
          APIFactory.findPermissions(),
        ]).then(res => {
          if (res) {
            if (res[0] && res[0].success) {
              this.role = res[0].data
            }

            if (res[1] && res[1].success) {
              this.permissions = res[1].data
              this.permissions = JSON.parse(JSON.stringify(this.permissions))
              .map(item => item.group)
              .filter((v, i, a) => a.indexOf(v) === i)
              // .filter(item => {
              //   return item !== 'permissions' || this.role.name !== 'admin'
              // })
              .map(group => {
                return {
                  name: group,
                  entries: this.permissions.filter(permission => {
                    return permission.group === group
                  })
                }
              })
            }
          }
        }).finally(() => this.loading = false)
      },
      toggle(properties) {
        if (properties.allow && !this.role.permissions.includes(properties.permission)) {
          this.role.permissions.push(properties.permission)
        } else {
          this.role.permissions = this.role.permissions.filter(item => item !== properties.permission)
        }
      },
      save() {
        this.loading = true
        APIFactory.updateRolePermissions(
          this.$route.params.slug,
          this.$route.params.roleId,
          this.role.permissions).then(res => {
          this.loading = false
          if (res && res.success) {
            Helper.callToast('is-success', this.$t('settings.updated_role_successful'))
            this.updatePermissions(this.$route.params.roleId, res.data)
            this.sidebarKey = Math.random()
            this.load()
          }
        })
      },
      updatePermissions(roleId, data) {
        this.$store.commit('auth/updatePermissions', {
          app_id: data.app_id,
          roleId: roleId,
          permissions: data.permissions
        })
      },
      hasAllGroupEntries(group) {
        return group.entries.every(entry => this.role.permissions.includes(entry.name))
      },
      toggleAllGroupEntries($event, group) {
        if ($event) {
          group.entries.forEach(entry => {
            if (!this.role.permissions.includes(entry.name)) {
              this.role.permissions.push(entry.name)
            }
          })
        } else {
          group.entries.forEach(entry => {
            if (this.role.permissions.includes(entry.name)) {
              let index = this.role.permissions.findIndex(permission => permission === entry.name)
              this.role.permissions.splice(index, 1)
            }
          })
        }
        this.renderingKey = Math.random()
      }
    },
    watch: {
      '$route'() {
        this.load()
      }
    },
    components: {
      PermissionGroup,
      SidebarLayout,
      Permission,
      Loader,
    }
  }
</script>

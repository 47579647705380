<template>
  <div :class="{
    	'c__service-grid': true,
    	'c__service-grid--marginless': changePlanMode
    }">
    <slot/>
  </div>
</template>

<script>
export default {
  props: [
    'changePlanMode'
  ]
}
</script>

<style lang="scss" scoped>
.c__service-grid {
  width: 100%;
  height: 100%;
  display: flex;
  margin-top: 16px;
  max-width: 1008px;
  font-family: Roboto;

  @media (max-width: $breakpoint-mobile) {
    flex-wrap: wrap;
    margin-top: 0px;
  }

  &.c__service-grid--marginless {
    margin-top: 0;
    justify-content: center;
  }
}
</style>

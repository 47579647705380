var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('drop',{staticClass:"c__media-dropzone-wrapper",on:{"drop":_vm.moveItem}},[_c('div',{class:{
      	'c-media-dropzone': true,
      	'c-media-dropzone--readOnly': _vm.readOnly
      },on:{"click":function($event){$event.preventDefault();return _vm.toggleSidebar.apply(null, arguments)}}},[(!!_vm.item)?[_c('drag',{class:{
        'c-dropzone-preview': true,
        'c-dropzone-video': _vm.isItemAutoPlayable,
        'c-dropzone-preview--no-title': _vm.hideTitle
        },attrs:{"transfer-data":{ index: _vm.item.index, item: _vm.item }}},[(!_vm.readOnly)?_c('img',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.$t('designer.change_object')),expression:"$t('designer.change_object')"}],class:{
              'image--shadow': true,
              'image-mode--cover': _vm.item.image_mode === 'cover',
              'image-mode--contain': _vm.item.image_mode === 'contain',
            },attrs:{"src":_vm.previewImage}}):_vm._e(),(_vm.readOnly)?[(!_vm.isItemAutoPlayable)?_c('img',{class:{
              'image--shadow': true,
              'image-mode--cover': _vm.item.image_mode === 'cover',
              'image-mode--contain': _vm.item.image_mode === 'contain',
            },attrs:{"src":_vm.previewImage},on:{"click":function($event){$event.preventDefault();return _vm.show.apply(null, arguments)}}}):_vm._e(),(_vm.isItemAutoPlayable)?_c('div',{staticClass:"video-wrapper"},[_c('iframe',{attrs:{"width":"560","height":"315","src":_vm.createEmbeddedVideoURL,"frameborder":"0","allow":"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture","allowfullscreen":""}})]):_vm._e()]:_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.hideContextMenu),expression:"!hideContextMenu"}],staticClass:"c-dropzone-context"},[(!_vm.contextType || _vm.contextType === 'page')?_c('ElementContext',{key:_vm.item.id + '_' + _vm.item.index,attrs:{"highlighted":_vm.item.note_highlighted,"context-type":_vm.contextType,"read-only":_vm.readOnly,"preview":_vm.preview,"folder":_vm.folder,"element":_vm.item},on:{"setNoteModalInformation":function($event){_vm.noteModalInformation = $event},"delete":function($event){return _vm.$emit('delete', _vm.item)},"setImageMode":_vm.setImageMode,"setAsNew":_vm.setAsNew}}):_c('ElementChapterContext',{attrs:{"read-only":_vm.readOnly,"folder":_vm.folder,"element":_vm.item},on:{"delete":function($event){return _vm.$emit('delete', _vm.item)}}})],1)],2),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.hideTitle),expression:"!hideTitle"}],staticClass:"c-dropzone-meta"},[_c('div',{staticClass:"c-dropzone-icon"},[_c('img',{attrs:{"src":_vm.icon}}),(_vm.item.showAsNew)?_c('div',{staticClass:"showAsNewMarker"},[_c('img',{attrs:{"src":"images/icons/star--yellow.svg"}})]):_vm._e()]),_c('div',{class:{
          'c-dropzone-title': true,
          'c-dropzone-title--small': _vm.item.note,
          'c-dropzone-title--highlighted': _vm.item.highlighted,
          }},[_c('b-tooltip',{staticStyle:{"width":"100%","word-wrap":"break-word"},attrs:{"delay":_vm.tooltipDelay,"label":_vm.item.name,"multilined":""}},[_c('v-clamp',{attrs:{"autoresize":"","max-lines":2}},[_vm._v(_vm._s(_vm.item.name))])],1)],1),(_vm.item.note)?_c('div',{staticClass:"c-dropzone-icon c-dropzone-icon--note"},[_c('b-tooltip',{attrs:{"delay":_vm.tooltipDelay,"label":_vm.$t('designer.note_tooltip'),"position":"is-top","multilined":""}},[_c('img',{attrs:{"src":"/images/icons/note--added.svg"}})])],1):_vm._e()])]:[_c('div',{class:{
          'c-dropzone-container': true,
          'c-dropzone-container--readOnly': _vm.readOnly
        }},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.readOnly),expression:"!readOnly"}],staticClass:"c-dropzone-empty"},[_c('div',{staticClass:"c-dropzone-icon"},[_c('img',{attrs:{"src":_vm.icon}})]),_c('div',{staticClass:"c-dropzone-title"},[_vm._v(" "+_vm._s(_vm.$t('designer.search_mediacenter'))+" ")])])])]],2),(_vm.active)?_c('MediaDropzoneSidebar',{attrs:{"active":_vm.active,"containers":_vm.containers,"maximum-items":_vm.maximumItems,"maximum-selectable-items":_vm.maximumSelectableItems,"file-extensions":_vm.fileExtensions},on:{"close":_vm.closeSidebar,"place-item":_vm.placeItem}}):_vm._e(),(!!_vm.noteModalInformation)?_c('NoteModal',{attrs:{"active":!!_vm.noteModalInformation,"element":_vm.noteModalInformation.element,"read-only":_vm.readOnly},on:{"close":function($event){_vm.noteModalInformation = null}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <section class="container--centered">
    <div class="c-language-switcher">
      <LanguageSwitcher/>
    </div>
    <div class="wrapper">
      <figure class="mb-2">
        <a href="https://digital-folder.com" target="_blank">
          <img src="images/do-logo.svg">
        </a>
      </figure>
      <Loader
          v-if="loading"
      />
      <Form
          button="auth.login"
          :loading="loading"
          :fields="fields"
          @submit="login"
      />

      <div class="login-controls">
        <router-link
          to="/auth/signup"
          class="c-password-lost">
          {{ $t('signup.signup_now') }}
        </router-link>
        <router-link
            to="/auth/forget"
            class="c-password-lost">
          {{ $t('auth.forget') }}
        </router-link>
      </div>

      <a href="https://digital-folder.com" class="c-poweredBy-container u-mt--32" target="_blank">
        <img src="images/do-logo.png">
        <div class="u-ml--8">{{ $t('display.trial_period') }}</div>
      </a>

      <b-message
          has-icon
          class="u-mt--40"
          type="is-warning"
          v-if="message">
        {{ message }}
      </b-message>
    </div>

    <div class="c-support">
      <Chat />
    </div>
  </section>
</template>

<script>
import Helpers from '../../helpers'
import Form from '../../components/Form'
import APIFactory from '../../api/factory'
import Loader from '../../components/global/Loader'
import Chat from '../../components/signup/support/Chat'
import LanguageSwitcher from '../../components/settings/LanguageSwitcher'

export default {
  data() {
    return {
      message: null,
      loading: false,
      fields: [
        {
          field: 'email',
          label: 'general.email',
          required: true,
          type: 'email',
        },
        {
          field: 'password',
          label: 'general.password',
          type: 'password',
          required: true,
        }
      ],
    }
  },
  methods: {
    load() {
      this.loading = true
      APIFactory.getMessageOfTheDay().then(res => {
        this.message = res.data
      }).finally(() => this.loading = false)
    },
    async login() {
      if (!Helpers.validate(this, this.fields)) {
        return
      }

      this.loading = true
      APIFactory.login(
          Helpers.field('email', this.fields),
          Helpers.field('password', this.fields))
          .then(res => {
            this.loading = false

            this.$i18n.locale = res.data.language
            this.$store.commit('auth/setAvatar', res.data.avatar)
            this.$store.commit('auth/setUserId', res.data.user_id)
            this.$store.commit('auth/setToken', res.data.token)
            this.$store.commit('auth/setApps', res.data.apps)
            this.$store.commit('app/setVersion', res.data.version)
            this.$store.commit('auth/setSuperadmin', !!res.data.superadmin)
            this.$store.commit('auth/setFirstLogin', !!res.data.firstLogin)

            Helpers.callToast('is-success', this.$t('auth.welcome'))
            this.$router.push('/?lang=' + res.data.language)
          })
    },
  },
  created() {
    this.load()
  },
  components: {
    Form,
    Chat,
    Loader,
    LanguageSwitcher
  }
}
</script>

<style lang="scss">
.c-poweredBy-container {
  display: flex;
  font-size: 12px;
  margin-top: 16px;
  font-family: Roboto;
  align-items: center;
  justify-content: center;

  img {
    width: 32px;
    max-height: 32px;
  }
}

.container--centered {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .wrapper {
    width: 500px;
    padding: 10px;
    max-width: 500px;
    background: white;

    @media (max-width: 1152px) {
      width: 400px;
      max-width: 400px;
    }

    figure {
      display: flex;
      justify-content: center;

      img {
        width: 100px;
        height: 100px;
      }
    }
  }

  .c-password-lost {
    display: flex;
    margin-top: 24px;
    justify-content: flex-end;
    text-decoration: underline;
  }
}

.login-controls {
  display: flex;
  justify-content: space-between;
}
</style>

<template>
  <div :class="{
    'c__dropdown': true,
    'c__dropdown--borderless': borderless,
    }">
    <!-- Tooltip -->
    <template v-if="tooltip">
      <b-tooltip
        :label="tooltip"
        position="is-bottom"
        :delay="tooltipDelay">
        <div class="dropdown-value"
             @click.prevent.stop="active = !active">
          <div class="dropdown-icon dropdown-icon--left" v-if="icon">
            <Icon :src="icon"/>
          </div>
          <div class="dropdown-title" v-if="!hideTitle">{{ valueLabel }}</div>
          <div class="dropdown-icon">
            <Icon src="images/icons/arrow-down.svg"/>
          </div>
        </div>
      </b-tooltip>
    </template>
    <template v-else>
      <div class="dropdown-value"
           @click.prevent.stop="active = !active">
        <div class="dropdown-icon dropdown-icon--left" v-if="icon">
          <Icon :src="icon"/>
        </div>
        <div class="dropdown-title">
          <template v-if="showValueAsLabel">{{ value | upperCase }}</template>
          <template v-else>{{ valueLabel }}</template>
        </div>
        <div class="dropdown-icon">
          <Icon src="images/icons/arrow-down.svg"/>
        </div>
      </div>
    </template>
    <div v-show="active"
         :id="id"
         :class="{
           'dropdown-entries': true,
           'dropdown-entries--parent': entriesParentSize,
           'dropdown-mode--openTop': mode === 'open-top',
           'dropdown-entries--small': smallDropdownEntries,
         }">
      <div
        :key="idx"
        class="dropdown-entry"
        v-for="(entry, idx) in values"
        @click.prevent="select(entry)">
        {{ $te(entry.label) ? $t(entry.label) : entry.label }}
      </div>
    </div>
  </div>
</template>

<script>
  import Icon from '../Icon'
  import {mapGetters} from 'vuex'

  export default {
    props: [
      'icon',
      'mode',
      'values',
      'tooltip',
      'borderless',
      'hide-title',
      'default-value',
      'showValueAsLabel',
      'entriesParentSize',
      'smallDropdownEntries',
    ],
    data() {
      return {
        value: null,
        active: false,
        id: 'dropdown-' + parseInt(Math.random() * 1000),
      }
    },
    computed: {
      ...mapGetters({
        tooltipDelay: 'ui/tooltipDelay'
      }),
      valueLabel() {
        if (this.values) {
          let entry = this.values.find(item => item.id === this.value)
          if (entry) {
            return this.$te(entry.label)
              ? this.$t(entry.label)
              : entry.label
          }
        }
      }
    },
    created() {
      window.addEventListener('click', this.close)
      window.addEventListener('toggle-context-menu', this.handleSameComponentToggle)
    },
    beforeDestroy() {
      window.removeEventListener('click', this.close)
      window.removeEventListener('toggle-context-menu', this.handleSameComponentToggle)
    },
    methods: {
      select(entry) {
        this.value = entry.id
        this.$emit('select', entry.id)
        this.active = false
      },
      close($event) {
        if (!this.$el.contains($event.target)) {
          this.active = false
        }
      },
      handleSameComponentToggle($event) {
        if (this.id !== $event.detail) {
          this.active = false
        }
      },
      keydown($event) {
        // Determine which position this element is
        let dropdownEntryIndex = -1
        for (let i = 0; i < this.values.length; i++) {
          let label = this.values[i].label
          if (label) {
            label = label.toLowerCase()
            if (label.startsWith($event.key.toLowerCase())) {
              dropdownEntryIndex = i
              break
            }
          }
        }

        // ScrollTo Element
        if (this.$el && dropdownEntryIndex > -1) {
          let dropdownEntries = this.$el.querySelectorAll('.dropdown-entry')
          this.$scrollTo(dropdownEntries.item(dropdownEntryIndex), 750, {
            container: '#' + this.id,
          })
        }
      }
    },
    watch: {
      values: {
        immediate: true,
        handler() {
          if (this.defaultValue) {
            let entry = this.values.find(item => item.id === this.defaultValue)
            if (entry) {
              this.value = entry.id
            }
          }
        }
      },
      active() {
        this.active
          ? document.addEventListener('keypress', this.keydown)
          : document.removeEventListener('keypress', this.keydown)
      }
    },
    components: {
      Icon,
    }
  }
</script>

<style lang="scss" scoped>
  /*
   *
   *  Dropdown Component
   *
   */
  .c__dropdown {
    min-height: 48px;
    border-radius: 4px;
    position: relative;
    border: solid 1px $light-blue-color;

    /*
     *  This dropdown is displayed with a transparent border
     */
    &.c__dropdown--borderless {
      border: solid 1px transparent;
    }

    /*
     *  This element shows the current/selected value of the
     *  Dropdown. It could contain also a placeholder
     */
    .dropdown-value {
      width: 100%;
      display: flex;
      font-size: 16px;
      cursor: pointer;
      min-height: 48px;
      font-weight: 500;
      padding: 12px 16px;
      font-family: Roboto;
      align-items: center;
      justify-content: space-between;

      .dropdown-title {
        height: 24px;
        display: flex;
        font-size: 14px;
        overflow: hidden;
        max-width: 250px;
        white-space: nowrap;
        margin-right: 8px;
        align-items: center;
        word-break: break-all;
        text-overflow: ellipsis;

        @media (max-width: $breakpoint-mobile) {
          display: none;
        }
      }

      .dropdown-icon {
        width: 16px;
        height: 16px;

        &.dropdown-icon--left {
          margin-right: 8px;
        }
      }
    }

    /*
     * It holds all entries of this dropdown component.
     */
    .dropdown-entries {
      top: 40px;
      right: 0;
      z-index: 6000;
      padding: 8px 0;
      min-width: 250px;
      overflow-y: auto;
      max-height: 250px;
      position: absolute;
      background: $white;
      border-radius: 4px;
      box-shadow: $box-shadow-menu;

      &.dropdown-entries--parent {
        min-width: 100%;
      }

      &.dropdown-entries--small {
        max-height: 150px
      }

      &.dropdown-mode--openTop {
        top: calc(-100% - 60px);
      }

      .dropdown-entry {
        display: flex;
        font-size: 14px;
        font-weight: 500;
        min-height: 40px;
        padding: 12px 16px;
        align-items: center;
        color: $darker-blue-color;

        &:hover {
          cursor: pointer;
          background: $light-blue-color;
        }
      }
    }
  }
</style>

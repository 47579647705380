<template>
  <base-modal
    @close="cancel"
    :active="active"
    :title="$t('workspaces.merge')">

    <!-- Content -->
    <div class="c-merge-folders">
      <input
        v-model="keyword"
        class="input basic"
        :placeholder="$t('workspaces.search')"
      />
      <div
        v-for="folder in filteredFolders"
        @click.prevent="selectedFolder = folder.id"
        :class="{'c-merge-folder': true, 'c-merge-folder--selected': selectedFolder === folder.id}">
        <div class="c-folder-icon">
          <img src="/images/icons/media-center--white.svg">
        </div>
        {{ folder.name }}
      </div>
    </div>

    <!-- Actions -->
    <div
      slot="actions"
      class="modal-controls modal-controls--spacing">
      <Button
        theme="default"
        :loading="loading"
        @click="cancel"
        :disable-icon-view-only-on-mobile="true">
        {{ $t('general.cancel') }}
      </Button>
      <Button
        @click="merge"
        theme="primary"
        :loading="loading"
        :disabled="!selectedFolder"
        :disable-icon-view-only-on-mobile="true">
        {{ $t('workspaces.merge') }}
      </Button>
    </div>
  </base-modal>
</template>

<script>
import APIFactory from '../../../api/factory'
import Button from '../../global/buttons/Button'
import BaseModal from '../../global/modals/BaseModal'
import Helpers from '../../../helpers'

export default {
  props: [
    'folder',
    'folders',
    'activation',
  ],
  computed: {
    filteredFolders() {
      return this.folders
        .filter(folder => folder.id !== this.folder.id)
        .filter(folder => {
          if (this.keyword) {
            return folder.name
              && folder.name.toLowerCase().indexOf(this.keyword.toLowerCase()) > -1
          }
          return true
        })
        .sort((i1, i2) => i1.name > i2.name ? 1 : -1)
    }
  },
  data() {
    return {
      keyword: null,
      active: false,
      loading: false,
      selectedFolder: null,
    }
  },
  methods: {
    merge() {
      APIFactory.mergeFolder(this.$route.params.slug, this.$route.params.teamId, this.folder.id, this.selectedFolder).then(res => {
        if (res && res.success) {
          Helpers.callToast('is-success', this.$t('workspaces.merge_success'))
          this.$emit('success')
        }
      })
    },
    cancel() {
      this.active = false
      this.$emit('success')
    }
  },
  watch: {
    activation: {
      immediate: true,
      handler(value) {
        if (this.active !== value) {
          this.active = value
        }
      }
    },
    active(value) {
      if (this.activation !== value) {
        this.$emit('reset', value)
      }

      if (value) {
        this.$nextTick(() => {
          this.$refs.input.$el.focus()
        })
      }
    }
  },
  components: {
    BaseModal,
    Button,
  }
}
</script>

<style lang="scss" scoped>
  .c-merge-folders {
    margin-top: 32px;
    max-height: 300px;

    .c-merge-folder {
      width: 100%;
      margin: 8px 0;
      display: flex;
      padding: 16px;
      cursor: pointer;
      border-radius: 4px;
      align-items: center;
      font-family: Roboto;
      font-size: 16px;
      font-weight: 500;
      word-break: break-word;
      background: $light-blue-color;
      border: 3px solid transparent;

      &:hover,
      &.c-merge-folder--selected {
        border: 3px solid $lighter-blue-color;
      }

      .c-folder-icon {
        width: 24px;
        height: 24px;
        display: flex;
        padding: 24px;
        margin-right: 16px;
        border-radius: 8px;
        align-items: center;
        justify-content: center;
        background: $primary-color;

        img {
          width: 24px;
          height: 24px;
          flex-shrink: 0;
          max-width: 24px;
          max-height: 24px;
        }
      }
    }
  }
</style>

<template>
  <Layout
    :title="title"
    :loading="loading"
    :show-back-toolbar="!showContainers"
    icon="/images/icons/media-center--active.svg">
    <SplitLayout
      :teams="teams"
      :show="showContainers"
      @reload="loadContainers"
      route-param="containerId"
      :count="sortedContainers.length"
      :elements="sortedContainers"
      :selected-elements="selectedObjects"
      :title="$t('mediacenter.containers')"
      icon="/images/icons/media-center--white.svg"
      @drop="handleFileUpload($event.data, $event.nativeEvent)">

      <!-- Controls -->
      <template slot="sidebar-controls">
        <div class="flex gap-2">
          <ContainerModal
            :container="newContainer"
            :teams="teams"
            @success="success">
            <c-button
              @click="reset"
              theme="primary"
              icon="images/icons/add.svg">
              {{ $t('mediacenter.create_container') }}
            </c-button>
          </ContainerModal>
          <c-button
            :outline="showArchivedItems"
            @click.prevent="toggleArchive"
            icon="images/icons/rework/archive.svg"
            v-tooltip="$t('workspaces.show_archived_elements')">
          </c-button>
        </div>

        <Dropdown
          :values="containerSortMethods"
          :default-value="containerSortMethod"
          :tooltip="$t('mediacenter.sort_containers')"
          @select="setCurrentSortMethod($event, 'container')"
        />
      </template>

      <!-- Content -->
      <LoadingComponent :load="loading">
        <MediaLayout
          v-if="selectedContainer"
          :containers="containers"
          @success="loadContainers"
          @search="keyword = $event"
          @sort="setCurrentSortMethod($event, 'media')"
          :title="selectedContainer.name"
          :selected-objects="selectedObjects"
          :container-id="selectedContainer.id"
          :media-sort-methods="mediaSortMethods">
          <Object
            @select="selectObject"
            @move="moveDialogData = $event"
            @edit="editDialogData = $event"
            @delete="confirmDialogData = $event"
            :key="index + '-' + mediaSortMethod"
            :object="object"
            :enable-context="true"
            :enable-selection="!isMobileMode"
            :is-container-released="isContainerReleased"
            :selected="selectedObjects.includes(object.id)"
            v-for="(object, index) in executeMediaObjects"
          />
          <div class="w-full flex justify-center">
            <c-button
              @click.prevent="loadMoreContainerObjects"
              v-if="selectedContainer
                && selectedContainer.objects
                && selectedContainer.object_count > selectedContainer.objects.length">
              Weitere Mediendateien laden
            </c-button>
          </div>
          <!-- Modals -->
          <MediaModal
            @success="success"
            v-if="!!editDialogData"
            @reset="editDialogData = null"
            :containers="containers"
            :activation="!!editDialogData"
            :object="editDialogData.element"
            :is-container-released="isContainerReleased"
          />
          <ConfirmModal
            v-if="!!confirmDialogData"
            :active="!!confirmDialogData"
            :title="confirmDialogData.title"
            :message="confirmDialogData.message"
            :function="confirmDialogData.function"
            @close="confirmDialogData = null"
            @success="success"
          />
          <MoveModal
            @success="success"
            v-if="!!moveDialogData"
            :containers="containers"
            :active="!!moveDialogData"
            @close="moveDialogData = null"
            :object_ids="moveDialogData.object_ids"
          />
        </MediaLayout>
      </LoadingComponent>
    </SplitLayout>

    <!-- Drag and Drop Upload -->
    <UploadActivityModal v-if="uploadActivity" />
  </Layout>
</template>

<script>
import {mapGetters} from 'vuex'
import Helpers from '../helpers'
import APIFactory from '../api/factory'
import DropdownSorts from '../data/sort'
import Layout from '../components/layout/Layout'
import Button from '../components/global/buttons/Button'
import Object from '../components/mediacenter/Object'
import SplitLayout from '../components/layout/SplitLayout'
import Dropdown from '../components/global/dropdowns/Dropdown'
import MediaLayout from '../components/mediacenter/MediaLayout'
import MoveModal from '../components/mediacenter/modals/MoveModal'
import ConfirmModal from '../components/global/modals/ConfirmModal'
import MediaModal from '../components/mediacenter/modals/MediaModal'
import LoadingComponent from '../components/global/LoadingComponent'
import ContainerModal from '../components/mediacenter/modals/ContainerModal'
import UploadActivityModal from '@/components/mediacenter/modals/UploadActivityModal';

export default {
  computed: {
    ...mapGetters({
      isMobileMode: 'app/isMobileMode',
      forceShowMenu: 'app/forceShowMenu',
      lastSortMethodContainer: 'ui/lastSortMethodContainer',
      lastSortMethodMedia: 'ui/lastSortMethodMedia',
    }),
    showContainers() {
      if (this.isMobileMode) {
        return !this.$route.params.containerId || this.forceShowMenu
      }
      return true
    },
    title() {
      if (this.isMobileMode
          && !this.showContainers
          && this.selectedContainer) {
        return this.selectedContainer.name
      }
      return this.$t('mediacenter.mediacenter')
    },
    selectedContainer() {
      if (this.$route.params.containerId) {
        return this.containers.find(item => item.slug === this.$route.params.containerId)
      }
    },
    sortedContainers() {
      let containers = this.containers.filter(container => (this.showArchivedItems && container.archived) || (!this.showArchivedItems && !container.archived))
      if (this.containerSortMethod) {
        let sortFunction = this.containerSortMethods.find(item => item.id === this.containerSortMethod)
        if (sortFunction) {
          return sortFunction.sort(containers)
        }
      }
      return containers
    },
    executeMediaObjects() {
      if (this.mediaSortMethod && this.selectedContainer && this.selectedContainer.objects) {
        let objects = this.selectedContainer.objects
        if (this.keyword) {
          objects = this.selectedContainer.objects
              .filter(item => {
                let hasName = item.name && item.name.toLowerCase().indexOf(this.keyword.toLowerCase()) > -1
                let hasTag = item.tags && item.tags.find(item => item.toLowerCase().indexOf(this.keyword.toLowerCase()) > -1)
                return hasName || hasTag
              })
        }

        let sortFunction = this.mediaSortMethods.find(item => item.id === this.mediaSortMethod)
        if (sortFunction) {
          objects = sortFunction.sort(objects)
        }
        return objects
      }
    },
    isContainerReleased() {
      if (this.teams && this.selectedContainer) {
        let currentTeam = this.teams.find(item => item.slug === this.$route.params.teamId)
        if (currentTeam) {
          return this.selectedContainer.team_id && this.selectedContainer.team_id !== currentTeam.id
        }
      }
    }
  },
  data() {
    return {
      teams: [],
      limit: 100,
      keyword: null,
      loading: true,
      containers: [],
      newContainer: {},
      selectedObjects: [],
      editDialogData: null,
      moveDialogData: null,
      confirmDialogData: null,
      uploadActivity: false,
      showArchivedItems: false,
      mediaSortMethod: 'alphabetically',
      containerSortMethod: 'alphabetically',
      mediaSortMethods: DropdownSorts.filter(item => item.usable.includes('media')),
      containerSortMethods: DropdownSorts.filter(item => item.usable.includes('container')),
    }
  },
  created() {
    this.updateDefaultRoute().then(res => {
      this.loadContainers()
      this.loadTeams()

      // Set default sorts
      this.mediaSortMethod = this.lastSortMethodMedia
      this.containerSortMethod = this.lastSortMethodContainer
    })
  },
  mounted() {
    document.addEventListener('click', this.clearSelections)
  },
  beforeDestroy() {
    document.removeEventListener('click', this.clearSelections)
  },
  methods: {
    toggleArchive() {
      this.showArchivedItems = !this.showArchivedItems
    },
    clearSelections($event) {
      if (!$event.target.closest('.c__media-grid-controls')
          && !$event.target.closest('.c__media-object')) {
        this.selectedObjects = []
      }
    },
    success() {
      this.newContainer = {}
      this.selectedObjects = []
      this.moveDialogData = null
      this.editDialogData = null
      this.confirmDialogData = null
      this.loadContainers()
    },
    selectObject(objectId) {
      if (!this.isMobileMode) {
        this.selectedObjects = this.selectedObjects.includes(objectId)
            ? this.selectedObjects.filter(item => item !== objectId)
            : this.selectedObjects.concat([objectId])
      }
    },
    reset() {
      this.newContainer = {}
    },
    loadTeams() {
      this.loading = true
      APIFactory.findTeams(this.$route.params.slug).then(res => {
        if (res && res.success) {
          this.teams = res.data
        }
      }).finally(() => this.loading = false)
    },
    loadContainers() {
      this.loading = true
      this.selectedObjects = []
      APIFactory
        .findMediaContainers(this.$route.params.slug, this.$route.params.teamId)
        .then(res => {
          if (res && res.success) {
            this.containers = this
                .mapContainers(res.data)
                .sort((i1, i2) => {
                  if (i1.name < i2.name) {
                    return -1
                  }
                  if (i1.name > i2.name) {
                    return 1
                  }
                  return 0
                })
          }
        })
        .finally(() => this.loading = false)
    },
    mapContainers(containers) {
      return containers.map(this.mapContainer)
    },
    mapContainer(item) {
      let isSharedContainer = item.teams && item.teams.length > 0
      // let currentApp = (this.$store.getters['auth/apps'] || [])
      //     .find(item => item.slug === this.$route.params.slug)
      // if (currentApp && currentApp.teams) {
      //   let team = currentApp.teams.find(item => item.slug === this.$route.params.teamId)
      //   if (team) {
      //     isSharedContainer = item.team_id !== team.id
      //   }
      // }

      return {
        ...item,
        shared: isSharedContainer,
        subtitle: item.object_count + ' ' + this.$tc('mediacenter.objects', item.object_count)
      }
    },
    updateDefaultRoute() {
      let slug = this.$route.params.slug
      let teamId = this.$route.params.teamId

      if (!this.$route.params.slug) {
        let app = this.$store.getters['auth/defaultApp']
        if (app) {
          slug = app.slug
        }
      }

      if (!this.$route.params.teamId) {
        let app = this.$store.getters['auth/defaultApp']
        if (app && app.teams && app.teams[0]) {
          teamId = app.teams[0].slug
        }
      }

      let compileURL = '/' + slug + '/media-center/' + teamId
      if (this.$route.params.containerId) {
        compileURL = compileURL + '/' + this.$route.params.containerId
      }
      return this.$router
          .push(compileURL)
          .catch(err => {
            // Intercepts the error and dismisses it
          })
    },
    setCurrentSortMethod(sortMethod, type) {
      switch (type) {
        case 'container':
          this.$store.commit('ui/setLastSortMethodContainer', sortMethod)
          this.containerSortMethod = sortMethod
          break
        case 'media':
          this.$store.commit('ui/setLastSortMethodMedia', sortMethod)
          this.mediaSortMethod = sortMethod
          break
      }
    },
    handleFileUpload($event, $nativeEvent) {
      if (!this.selectedContainer) {
        Helpers.callToast('is-danger', this.$t('mediacenter.select_container'))
        return
      }

      let hasErrorUploads = false
      if ($nativeEvent.dataTransfer && $nativeEvent.dataTransfer.files) {
        let fileUploadPromises = Array.from($nativeEvent.dataTransfer.files).map(file => {
          return new Promise((resolve, reject) => {
            if (!this.selectedContainer) {
              reject()
            }
            // Create object for upload
            let name = file.name
            if (name && name.indexOf('.') > -1) {
              name = name.split('.')[0]
            }

            let object = {
              data: {
                type: 'file'
              },
              name,
              container_id: this.selectedContainer.id
            }

            // Upload file
            let fileReader = new FileReader()
            fileReader.onload = () => {
              let b64Content = fileReader.result
              if (b64Content) {
                object.data.type = 'file'
                object.data.size = file.size
                object.data.value = file.name
                object.data.mime_type = file.type
                object.data.content = b64Content.split(",")[1]
                object.data.extension = file.name.split('.').pop().toLowerCase()

                if (object.data.size <= 25000000) {
                  resolve(APIFactory.createMediaObject(this.$route.params.slug, this.$route.params.teamId, object))
                } else {
                  hasErrorUploads = true
                  resolve()
                }
              }
            }
            fileReader.readAsDataURL(file)
          })
        })

        if (fileUploadPromises && fileUploadPromises.length > 0) {
          this.uploadActivity = true
          Promise.all(fileUploadPromises).then(res => {
            Helpers.callToast('is-success', this.$tc('mediacenter.save_objects_success_message', fileUploadPromises.length))
            if (hasErrorUploads) {
              Helpers.callToast('is-danger', this.$t('mediacenter.limit_exceeded'))
            }
            this.loadContainers()
          })
          .finally(() => this.uploadActivity = false)
        }
      }
    },
    loadMoreContainerObjects() {
      this.limit += 100
      this.loading = true
      APIFactory.findMediaContainer(
          this.$route.params.slug,
          this.$route.params.teamId,
          this.$route.params.containerId, this.limit).then(res => {
        if (res?.success) {
          this.containers = this.containers.map(container => {
            if (container.slug === this.selectedContainer?.slug) {
              let currentObjects = this.selectedContainer.objects || []
              let currentContainer = this.mapContainer(res.data)
              if (currentContainer) {
                currentContainer.objects = currentContainer.objects.concat(currentObjects)
              }
              return currentContainer
            }
            return container
          })
        }
      }).finally(() => this.loading = false)
    }
  },
  watch: {
    'selectedContainer': {
      deep: true,
      handler() {
        if (this.selectedContainer
          && !this.selectedContainer.objects) {
          this.loading = true
          APIFactory.findMediaContainer(
              this.$route.params.slug,
              this.$route.params.teamId,
              this.$route.params.containerId).then(res => {
            if (res?.success) {
              this.containers = this.containers.filter(c => c.slug !== this.selectedContainer.slug)
              this.containers.push(this.mapContainer(res.data))
            }
          }).finally(() => this.loading = false)
        }
      }
    }
  },
  components: {
    UploadActivityModal,
    LoadingComponent,
    ContainerModal,
    ConfirmModal,
    MediaLayout,
    SplitLayout,
    MediaModal,
    MoveModal,
    Dropdown,
    Object,
    Button,
    Layout
  }
}
</script>

<template>
  <div class="c__page-content">
    <img :src="icon">
    {{ name }}
  </div>
</template>

<script>
export default {
  props: [
    'mimeType',
    'name'
  ],
  computed: {
    icon() {
      switch (this.mimeType) {
        case 'powerpoint':
        case 'excel':
        case 'word':
        case 'image':
          return '/images/icons/datatypes/' + this.mimeType + '.svg'
        case 'link':
          return '/images/icons/datatypes/link.svg'
      }
      return '/images/icons/datatypes/file.svg'
    }
  }
}
</script>

<style lang="scss" scoped>
.c__page-content {
  display: flex;
  flex-basis: 100%;
  margin-top: 8px;
  font-size: 14px;
  margin-left: 64px;
  align-items: center;
  word-break: break-all;
  color: $darker-blue-color;
  font-family: Roboto, sans-serif;

  img {
    margin-right: 8px;
  }
}
</style>

export default {
	namespaced: true,
	state: {
		width: null,
		lastURL: null,
		version: null,
		translations: null,
		forceShowRack: false,
		forceShowMenu: false,
		apiURL: process.env.VUE_APP_API_URL,
		availableLanguages: ['de', 'en', 'cz', 'ru'],
	},
	mutations: {
		setTranslation(state, translation) {
			if (!state.translations[translation.language]) {
				state.translations[translation.language] = {}
			}
			state.translations[translation.language][translation.key] = translation.value
		},
		setTranslations(state, translations) {
			state.translations = translations
		},
		setAvailableLanguages(state, availableLanguages) {
			state.availableLanguages = availableLanguages
		},
		setVersion(state, version) {
			state.version = version
		},
		setLastURL(state, lastURL) {
			state.lastURL = lastURL
		},
		setWidth(state, width) {
			state.width = width
		},
		setForceShowRack(state, forceShowRack) {
			state.forceShowRack = forceShowRack
		},
		setForceShowMenu(state, forceShowMenu) {
			state.forceShowMenu = forceShowMenu
		},
	},
	getters: {
		apiURL: (state) => state.apiURL,
		version: (state) => state.version,
		lastURL: (state) => state.lastURL,
		isMobileMode: (state) => state.width < 1024,
		isCompactMode: (state) => state.width < 1024,
		translations: (state) => state.translations,
		forceShowRack: (state) => state.forceShowRack,
		forceShowMenu: (state) => state.forceShowMenu,
		availableLanguages: (state) => state.availableLanguages,
	}
}

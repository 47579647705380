<template>
  <Layout
    :icon="icon"
    :title="title"
    :subtitle="subtitle"
    :showBackToolbar="!showMenu"
    :back-function="handleBackClick">
    <LoadingComponent :load="loading">
      <template v-if="folder">
        <ChapterSplitView
            :folder="folder"
            :force-content-view="!!currentElement"
            @load="load"
            @save="saveFolder"
            @preview="preview"
            @expand="handleExpandAll"
            @switch-view="viewMode = $event">
          <!-- Sidebar -->
          <template slot="sidebar">
            <ChapterContainer v-if="folder && folder.chapters">
              <Chapter
                  :key="index"
                  :folder="folder"
                  :chapter="chapter"
                  @movePage="movePage"
                  @expand="handleExpand"
                  @select="selectElement"
                  @copyChapter="copyChapter"
                  @addPage="newPage(chapter)"
                  @moveChapter="switchChapters"
                  @deleteChapter="deleteChapter"
                  v-for="(chapter, index) in sortedChapters"
                  :selected="isContentSelected(chapter.id, ['cover', 'chapter'])">
                <Page
                    :key="idx"
                    :page="page"
                    :chapter="chapter"
                    @movePage="switchPage"
                    @deletePage="deletePage"
                    @select="selectElement($event)"
                    :show-contents="showPageContents"
                    v-for="(page, idx) in chapter.pages"
                    @copyPage="copyPage($event, chapter.id)"
                    :selected="isContentSelected(page.id, ['page'])"
                />
              </Chapter>
            </ChapterContainer>
            <div class="c__chapter-x-controls">
              <Button
                  @click="newChapter"
                  icon="/images/icons/add--blue.svg"
                  :disable-icon-view-only-on-mobile="true">
                {{ $t('designer.new_chapter') }}
              </Button>
            </div>
          </template>

          <!-- Content -->
          <ContentDesigner
            @next="next"
            @previous="previous"
            @select-grid="selectGrid"
            @setBackground="setBackground"
            :folder="folder"
            :containers="containers"
            :element="currentElement"
            :has-next="hasNextElement"
            :has-previous="hasPreviousElement"
            :show-sidebar="currentElement && currentElement.type === 'page'">
            <ChapterContentDesigner
              :folder="folder"
              :containers="containers"
              :chapter="currentElement"
              @deleteChapter="deleteChapter"
              v-if="currentElement && currentElement.type === 'chapter'"
            />
            <PageContentDesigner
              :folder="folder"
              :page="currentElement"
              :containers="containers"
              @deletePage="deletePage"
              @updatePage="updatePage"
              :element-count="currentElement.grid"
              v-if="currentElement && currentElement.type === 'page'"
            />
            <CoverDesigner
              :folder="folder"
              :containers="containers"
              v-if="currentElement && currentElement.type === 'cover'"
            />
          </ContentDesigner>
        </ChapterSplitView>
        <!-- Modals -->
        <EditModeModal
            :folder="folder"
            :active="hasRouteChangedRequestedModal"
            @close="closeAutomaticSave"
            @success="successAutomaticSave"
        />
        <AcceptModal
            @success="acceptModal = false"
            @close="acceptModal = false"
            :active="acceptModal"
        />
      </template>
    </LoadingComponent>
  </Layout>
</template>

<script>
import ChapterContentDesigner from '../components/designer/chapters/ChapterContentDesigner'
import PageContentDesigner from '../components/designer/pages/PageContentDesigner'
import ChapterContainer from '../components/designer/chapters/ChapterContainer'
import EditModeModal from '../components/designer/modals/EditModeModal'
import ChapterSplitView from '../components/designer/ChapterSplitView'
import LoadingComponent from '../components/global/LoadingComponent'
import ContentDesigner from '../components/designer/ContentDesigner'
import CoverDesigner from '../components/designer/cover/CoverDesigner'
import AcceptModal from '../components/designer/modals/AcceptModal'
import Chapter from '../components/designer/chapters/Chapter'
import Button from '../components/global/buttons/Button'
import Page from '../components/designer/pages/Page'
import Layout from '../components/layout/Layout'
import APIFactory from '../api/factory'
import Helpers from '../helpers'
import {mapGetters} from 'vuex'

export default {
  computed: {
    ...mapGetters({
      isMobileMode: 'app/isMobileMode',
      forceShowMenu: 'app/forceShowMenu'
    }),
    showMenu() {
      if (this.isMobileMode) {
        return this.forceShowMenu
      }
      return true
    },
    showPageContents() {
      return this.viewMode === 'contents-expanded'
    },
    title() {
      return this.isMobileMode && this.folder
          ? this.folder.name
          : this.$t('designer.designer')
    },
    subtitle() {
      if (this.currentElement) {
        let index = this.currentElement.type !== 'cover'
        && this.currentElement.type !== 'chapter'
            ? (this.currentElement.index + 1) : this.currentElement.index
        return this.isMobileMode && this.currentElement.type !== 'cover'
            ? index + '. ' + (this.currentElement.name ? this.currentElement.name : '')
            : null
      }
    },
    icon() {
      return this.isMobileMode
          ? ''
          : '/images/icons/shelf-icon--active.svg'
    },
    hasPreviousElement() {
      return this.currentElement
          && this.currentElement.totalIndex > 0
    },
    hasNextElement() {
      return this.currentElement
          && this.currentElement.totalIndex < (this.totalElements - 1)
    },
    sortedChapters() {
      return this.folder.chapters.sort((c1, c2) => c1.index - c2.index)
    },
  },
  data() {
    return {
      folder: null,
      loading: false,
      containers: null,
      totalElements: 0,
      acceptModal: false,
      currentElement: null,
      viewMode: 'contents-hidden',
      hasRouteChangedRequested: false,
      hasRouteChangedRequestedModal: false,
    }
  },
  created() {
    this.load()
  },
  methods: {
    load() {
      this.loading = true
      Promise.all([
        APIFactory.findFolder(
            this.$route.params.slug,
            this.$route.params.teamId,
            this.$route.params.folderId),
        APIFactory.findMediaContainers(
            this.$route.params.slug,
            this.$route.params.teamId,
        false)
      ]).then(async (res) =>  {
        this.folder = this.response(res, 0)
        this.containers = this.response(res, 1)
        this.updateIndices()
        this.selectDefaultElement()
        await this.loadContainerContent()
      }).finally(() => this.loading = false)
    },
    response(res, index) {
      if (res && res[index] && res[index].success) {
        return res[index].data
      }
    },
    async loadContainerContent() {
      return new Promise(resolve => {
        Promise.all(this.containers.map(async container => {
          let response = await APIFactory.findMediaContainer(
              this.$route.params.slug,
              this.$route.params.teamId,
              container.slug,
          'all', 'all')
          if (response?.success) {
            return response.data || []
          }
        })).then(res => {
          this.containers = res
          resolve()
        })
      })
    },
    updateCurrentElementByIndex(index) {
      this.folder.chapters.forEach(chapter => {
        if (chapter.totalIndex === index) {
          this.currentElement = chapter
          return
        }

        if (chapter.pages) {
          chapter.pages.forEach(page => {
            if (page.totalIndex === index) {
              this.currentElement = page
              return
            }
          })
        }
      })
    },
    selectDefaultElement() {
      // Expand this view
      if (this.$route.query.pg
          || this.$route.query.cv
          || this.$route.query.ch) {
        this.handleExpandAll(true)
      }

      if (this.$route.query.pg) {
        this.currentElement = this.folder.chapters.flatMap(c => c.pages).find(p => p.id == this.$route.query.pg)
      }
      if (this.$route.query.cv) {
        this.currentElement = this.folder.chapters[0]
      }
      if (this.$route.query.ch) {
        this.currentElement = this.folder.chapters.find(c => c.id == this.$route.query.ch)
      }
    },
    previous() {
      if (this.currentElement) {
        let index = this.currentElement.totalIndex - 1
        this.updateCurrentElementByIndex(index)
      }
    },
    next() {
      if (this.currentElement) {
        let index = 1 + this.currentElement.totalIndex
        this.updateCurrentElementByIndex(index)
      }
    },
    handleExpand($event) {
      let updatedChapters = this.folder.chapters.map(item => {
        if (item.id === $event) {
          item.expanded = !item.expanded
        }
        return item
      })
      this.$set(this.folder, 'chapters', updatedChapters)
    },
    handleExpandAll($event) {
      let updatedChapters = this.folder.chapters
      .map(item => {
        if (item.type === 'chapter') {
          item.expanded = $event
        }
        return item
      })
      this.$set(this.folder, 'chapters', updatedChapters)
    },
    isContentSelected(id, type) {
      return this.currentElement
          && this.currentElement.id === id
          && type.includes(this.currentElement.type)
    },
    handleBackClick() {
      if (this.currentElement) {
        this.currentElement = null
        return
      }
      return this.$router.go(-1)
    },
    updateIndices() {
      let currentIndex = 0
      this.folder.chapters.sort((c1, c2) => c1.index - c2.index).forEach((chapter, index) => {
        chapter.totalIndex = currentIndex
        chapter.index = index
        currentIndex++

        if (chapter.pages) {
          chapter.pages.sort((i1, i2) => i1.index - i2.index).forEach((page, index) => {
            page.totalIndex = currentIndex
            page.index
            currentIndex++
          })
        }
      })
      this.totalElements = currentIndex
    },
    selectGrid($event) {
      if (this.currentElement && this.currentElement.type === 'page') {
        if (this.currentElement.objects) {
          if (this.currentElement.objects.length > $event) {
            this.acceptModal = true
            return
          }

          this.currentElement.objects = this.currentElement.objects
          .sort((i1, i2) => i1.index - i2.index)
          .map((item, idx) => {
            return {
              ...item,
              index: idx
            }
          })
          .filter(item => item.index < $event)
        }
        this.currentElement.grid = $event
        this.setDirty()
      }
    },
    sortIndices() {
      this.folder.chapters.forEach(chapter => {
        if (chapter.pages) {
          chapter.pages = chapter.pages.sort((o1, o2) => o1.index - o2.index)
        }
      })
    },
    movePage($event) {
      if ($event.type === 'page') {
        let sourceChapter = this.folder.chapters.find(item => item.id === $event.source_chapter)
        let targetChapter = this.folder.chapters.find(item => item.id === $event.target_chapter)
        if (sourceChapter && targetChapter && sourceChapter.id !== targetChapter.id) {
          let sourcePage = sourceChapter.pages.find(item => item.id === $event.source)
          if (sourcePage) {
            targetChapter.pages.push(sourcePage)
          }
          sourceChapter.pages = sourceChapter.pages.filter(item => item.id !== $event.source)
          this.updateIndices()
          this.setDirty()
        }
      }
    },
    switchPage($event) {
      if (this.folder && this.folder.chapters) {
        let sourceChapter = this.folder.chapters.find(item => item.id === $event.source_chapter)
        let targetChapter = this.folder.chapters.find(item => item.id === $event.target_chapter)
        if (sourceChapter && targetChapter) {
          let sourcePage = sourceChapter.pages.find(item => item.id === $event.source)
          let targetPage = targetChapter.pages.find(item => item.id === $event.target)
          if (sourcePage && targetPage) {
            if (sourceChapter.id !== targetChapter.id) {
              // Remove source from sourceChapter
              // Add target to sourceChapter
              sourceChapter.pages = sourceChapter.pages.filter(item => item.id !== $event.source)
              sourceChapter.pages.push(targetPage)

              // Remove target from targetChapter
              // Add source to Targetchapter
              targetChapter.pages = targetChapter.pages.filter(item => item.id !== $event.target)
              targetChapter.pages.push(sourcePage)
            }

            // Indices - When chapters are switched
            if (sourceChapter.id !== targetChapter.id) {
              let tmpIndex = sourcePage.index
              sourcePage.index = targetPage.index
              targetPage.index = tmpIndex
            } else {
              // When same Chapters
              if (sourcePage.index < targetPage.index) {
                let shiftElements = sourceChapter.pages.filter(item => item.id !== sourcePage.id
                    && item.index > sourcePage.index
                    && item.index < targetPage.index)
                sourcePage.index = targetPage.index
                targetPage.index--
                shiftElements.forEach(item => {
                  item.index--
                })
              } else if (sourcePage.index > targetPage.index) {
                // Shift every element before this down (-1)
                let shiftElements = sourceChapter.pages.filter(item => item.id !== sourcePage.id
                    && item.index < sourcePage.index
                    && item.index > targetPage.index)
                sourcePage.index = targetPage.index
                targetPage.index++
                shiftElements.forEach(item => {
                  item.index++
                })
              }
            }

            this.sortIndices()
            this.updateIndices()
            this.setDirty()
          }
        }
      }
    },
    switchChapters($event) {
      if (this.folder && this.folder.chapters) {
        let sourceTmp = this.folder.chapters.find(item => item.id === $event.source)
        let targetTmp = this.folder.chapters.find(item => item.id === $event.target)
        if (sourceTmp && targetTmp) {
          // let tmpIndex = sourceTmp.index
          // sourceTmp.index = targetTmp.index
          // targetTmp.index = tmpIndex

          // Shift everything down/up
          if (sourceTmp.index < targetTmp.index) {
            let shiftElements = this.folder.chapters.filter(item => item.id !== sourceTmp.id
                && item.index > sourceTmp.index
                && item.index < targetTmp.index)
            sourceTmp.index = targetTmp.index
            targetTmp.index--
            shiftElements.forEach(item => {
              item.index--
            })
            this.setDirty()
            return
          }

          if (sourceTmp.index > targetTmp.index) {
            // Shift every element before this down (-1)
            let shiftElements = this.folder.chapters.filter(item => item.id !== sourceTmp.id
                && item.index < sourceTmp.index
                && item.index > targetTmp.index)
            sourceTmp.index = targetTmp.index
            targetTmp.index++
            shiftElements.forEach(item => {
              item.index++
            })
          }
          this.setDirty()
          return
        }
      }
    },
    newPage(chapter) {
      if (!chapter.pages) {
        chapter.pages = []
      }

      let newPage = {
        id: Helpers.createUUID(),
        grid: 1,
        type: 'page',
        status: 'active',
        index: chapter.pages.length,
        name: null,
        objects: []
      }

      chapter.pages.push(newPage)
      this.currentElement = newPage
      this.updateIndices()
      this.setDirty()
      this.setFocus()
    },
    newChapter() {
      if (this.folder && this.folder.chapters) {
        let newChapter = {
          index: this.folder.chapters.length,
          id: Helpers.createUUID(),
          status: 'active',
          type: 'chapter',
          name: null,
          pages: []
        }
        this.folder.chapters.push(newChapter)
        this.currentElement = newChapter
        this.updateIndices()
        this.setDirty()
        this.setFocus()
      }
    },
    setFocus() {
      this.$nextTick(() => {
        let element = document.querySelector('.is-focusable')
        if (element) {
          element.focus()
        }
      })
    },
    /**
     *  Sets new element
     */
    selectElement(newElement) {
      this.currentElement = newElement
      this.setFocus()
    },
    /**
     * Deletes the current page within all objects and sets the 'currentElement' to chapter.
     * @param pageId
     */
    deletePage(pageId) {
      if (this.folder && this.folder.chapters) {
        this.folder.chapters.forEach(chapter => {
          if (chapter.pages) {
            let elementSize = chapter.pages
            chapter.pages = chapter.pages.filter(item => item.id !== pageId)
            if (elementSize != chapter.pages.length) {
              this.currentElement = chapter
            }
          }
        })
        this.updateIndices()
        this.setDirty()
      }
    },
    deleteChapter(chapterId) {
      if (this.folder && this.folder.chapters) {
        this.folder.chapters = this.folder.chapters.filter(item => item.id !== chapterId)
        this.currentElement = this.folder.chapters[0]
        this.updateIndices()
        this.setDirty()
      }
    },
    /**
     *  Saves the current state of the folder
     */
    saveFolder() {
      // Fill new chapters and pages
      if (this.folder.chapters) {
        this.folder.chapters = this.folder.chapters.map(chapter => {
          if (!chapter.name) {
            chapter.name = this.$t('designer.new_chapter')
          }

          // Set cover mode - Removes cover image if mode === 'color-mode'
          if (chapter.mode === 'color-mode') {
            chapter.content = null
            chapter.cover_id = null
          }

          if (chapter.pages) {
            chapter.pages = chapter.pages.map(page => {
              if (!page.name) {
                page.name = this.$t('designer.new_page')
              }

              return page
            })
          }
          return chapter
        })
      }

      // this.loading = true
      return APIFactory.saveFolder(
          this.$route.params.slug,
          this.$route.params.teamId,
          this.folder).then(res => {
        if (res && res.success) {
          Helpers.callToast('is-success', this.$t('designer.save_folder_success'))
          this.folder = res.data
          this.updateIndices()
        }
        return this.folder
      })
    },
    preview() {
      // Route with password & force as query param.
      this.saveFolder().then((res) => {
        let route = this.$router.resolve({
          path: '/' + this.$route.params.slug + '/folder/' + this.$route.params.teamId + '/' + res.id + '/preview',
          query: {
            lang: this.$route.query.lang,
          }
        })
        if (route) {
          window.open(route.href, '_blank')
        }
      })
    },
    /**
     *  Copies a chapter and reset indices
     */
    copyChapter($event) {
      if (this.folder && this.folder.chapters) {
        // Update id's of chapter and pages
        $event.id = Helpers.createUUID()
        $event.pages = $event.pages.map(item => {
          return {
            ...item,
            id: Helpers.createUUID(),
            objects: item.objects.map(object => {
              return {
                ...object,
                pivot_id: null,
              }
            })
          }
        })

        this.folder.chapters.push($event)
        this.updateIndices()
        this.setDirty()
      }
    },
    copyPage($event, chapter_id) {
      if (this.folder && this.folder.chapters) {
        $event.id = Helpers.createUUID()
        $event.chapter_id = chapter_id

        let chapter = this.folder.chapters.find(item => item.id === chapter_id)
        if (chapter) {
          $event.objects = $event.objects.map(item => {
            return {
              ...item,
              pivot_id: null,
            }
          })
          chapter.pages.push($event)
        }
        this.setDirty()
      }
    },
    setDirty() {
      this.$set(this.folder, 'dirty', true)
    },
    /**
     *  Handles routing after saving through dialog. If hasRouteChangedRequest is set the route will be passed
     */
    successAutomaticSave() {
      this.saveFolder().then(() => {
        this.hasRouteChangedRequestedModal = false
        this.$router.push({
          path: this.hasRouteChangedRequested.path
        })
      })
    },
    /**
     *  Handles
     */
    closeAutomaticSave() {
      this.folder.dirty = false
      this.hasRouteChangedRequestedModal = false
      this.$router.push({
        path: this.hasRouteChangedRequested.path
      }).then(() => this.hasRouteChangedRequested = null)
    },
    updatePage($page) {
      if (this.folder && this.folder.chapters) {
        let chapter = this.folder.chapters.find(chapter => chapter.id === $page.chapter_id)
        if (chapter && chapter.pages) {
          let updatedPages = chapter.pages.filter(page => page.id !== $page.id)
          if (!updatedPages) {
            updatedPages = []
          }
          updatedPages.push($page)
          chapter.pages = updatedPages
          this.sortIndices()
        }
      }
    },
    setBackground($event) {
      this.folder.background_image = $event.image
      this.folder.background_color = $event.color
    }
  },
  beforeRouteLeave(to, from, next) {
    if (this.folder && this.folder.dirty) {
      if (this.hasRouteChangedRequested) {
        next()
        return
      }
      this.hasRouteChangedRequested = to
      this.hasRouteChangedRequestedModal = true
      return
    }
    next()
  },
  components: {
    AcceptModal,
    Page,
    Layout,
    Button,
    Chapter,
    EditModeModal,
    CoverDesigner,
    ContentDesigner,
    LoadingComponent,
    ChapterContainer,
    ChapterSplitView,
    PageContentDesigner,
    ChapterContentDesigner,
  }
}
</script>

<style lang="scss" scoped>
.c__chapter-x-controls {
  margin-bottom: 16px;
  display: flex;
  justify-content: flex-start;
}
</style>

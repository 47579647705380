<template>
    <div class="c__task-header"
        @click.prevent="$emit('toggle')">
        <div class="c__task-header-icon">
            <img src="/images/icons/clipboard.svg">
        </div>
        <div class="c__task-header-title">{{ $t('designer.comments') }}</div>
        <div class="c__task-header-icon c__task-header-icon--small">
            <img :src="'/images/icons/arrow-' + (active ? 'down' : 'up') + '.svg'">
        </div>
    </div>
</template>

<script>
    export default {
    	props: [
    		'active',
        ]
    }
</script>

<style lang="scss" scoped>
    .c__task-header {
        height: 64px;
        display: flex;
        flex-shrink: 0;
        cursor: pointer;
        align-items: center;
        justify-content: center;

        @media (max-width: $breakpoint-mobile) {

        }

        .c__task-header-icon {
            img {
                width: 16px;
                height: 16px;
            }

            &.c__task-header-icon--small {
                img {
                    width: 12px;
                    height: 12px;
                }
            }
        }

        .c__task-header-title {
            margin: 0 8px;
            font-size: 16px;
            font-weight: 500;
            font-family: Roboto, sans-serif;
        }
    }
</style>

<template>
    <div class="modal__button-wrapper">
        <b-button
            class="mt-2"
            type="is-primary"
            @click.prevent="active = true">
            {{ $t('settings.create_role') }}
        </b-button>
        <Modal
            :active="active"
            @close="active = false"
            :title="$t('settings.create_role')">
            <ErrorMessage
                v-if="error"
                :message="$t('general.invalid_form')"
            />
            <b-columns>
                <b-column>
                    <b-field
                        class="mt-2"
                        :label="$t('general.name')">
                        <b-input v-model="name" />
                    </b-field>

                    <b-button
                        expanded
                        class="mt-2"
                        type="is-primary"
                        :loading="loading"
                        :disabled="loading"
                        @click.prevent="save">
                        {{ $t('general.save') }}
                    </b-button>
                </b-column>
            </b-columns>
        </Modal>
    </div>
</template>

<script>
	import Modal from './Modal'
    import APIFactory from '../../../api/factory'
    import ErrorMessage from '../../ErrorMessage'

	export default {
		data() {
			return {
                loading: false,
				active: false,
                error: false,
                name: null,
			}
		},
        methods: {
			save() {
                if (!this.name) {
                	this.error = true
                    return
                }

                this.error = false
                this.loading = true
                APIFactory.createRole(this.$route.params.slug, this.name).then(res => {
                	this.loading = false
                    if (res && res.success) {
                    	this.active = false
                        this.name = null
                    	this.$emit('success')
                    }
                })
            }
        },
		components: {
			Modal,
            ErrorMessage
		}
	}
</script>

<template>
  <div class="c__chapter-wrapper"
       @click.prevent="select">
    <drop :class="{
      'c__chapter': true,
      'c__chapter--selected': selected || draggedOver,
      'c__chapter--cover': chapter.type === 'cover'
      }"
      @drop="moveChapter"
      @dragover="draggedOver = true"
      @dragleave="draggedOver = false">
      <div class="c__chapter-container">
        <template v-if="chapter.type === 'chapter'">
          <drag :transfer-data="{source: chapter.id, type: 'chapter'}"
                class="c__chapter-icon c__chapter-icon--grab" v-tooltip="$t('designer.drag_chapter')">
            <img src="images/icons/drag-handle.svg">
          </drag>
          <div class="c__chapter-icon">
            <b-tooltip
              :delay="tooltipDelay"
              :label="statusTooltip"
              position="is-right">
              <img :src="statusIcon">
            </b-tooltip>
          </div>
          <div :class="{
           'c__chapter-title': true,
           'c__chapter-title--untouched': null === chapter.name
          }">
            <template v-if="folder && folder.order_type === 'number'">{{ chapter.index }}.</template>
            <template v-if="chapter.name">{{ chapter.name }}</template>
            <template v-else>{{ $t('designer.new_chapter') }}</template>
          </div>
        </template>
        <template v-else>
          <div class="c__chapter-title">{{ $t('designer.cover') }}</div>
        </template>
      </div>
      <div class="c__chapter-container u-mt--4"
           v-if="chapter.type !== 'cover'">
        <div class="c__chapter-button">
          <ChapterContext
            :chapter="chapter"
            @copy="$emit('copyChapter', $event)"
            @delete="$emit('deleteChapter', $event)"
          />
        </div>
        <div class="c__chapter-button"
             @click.prevent.stop="$emit('expand', chapter.id)">
          <img :src="'/images/icons/' + (chapter.expanded ? 'minus' : 'plus') + '.svg'">
        </div>
      </div>
    </drop>
    <div
      v-show="chapter.expanded"
      class="c__chapter-pages">
      <slot/>
      <div class="c__chapter-pages-control">
        <Button
          @click="appendPage"
          icon="/images/icons/add--blue.svg"
          :disable-icon-view-only-on-mobile="true">
          {{ $t('designer.new_page') }}
        </Button>
      </div>
    </div>
  </div>
</template>

<script>
  import Button from '../../global/buttons/Button'
  import ChapterContext from './ChapterContext'
  import {mapGetters} from 'vuex'

  export default {
    props: [
      'folder',
      'chapter',
      'selected',
    ],
    computed: {
      ...mapGetters({
        tooltipDelay: 'ui/tooltipDelay',
      }),
      isChapterMarkedAsNew() {
        return this.chapter
          && this.chapter.pages
          && this.chapter.pages.find(item => item.showAsNew || (item.objects && item.objects.find(object => object.showAsNew)))
      },
      statusIcon() {
        if (this.chapter.status === 'inactive') {
          return 'images/icons/states/chapter-inactive.svg'
        }

        if (this.chapter.showAsNew || this.isChapterMarkedAsNew) {
          return 'images/icons/star--yellow.svg'
        }
        return 'images/icons/states/chapter.svg'
      },
      statusTooltip() {
        if (this.chapter.status === 'inactive') {
          return this.$t('designer.chapter_inactive')
        }

        if (this.chapter.showAsNew || this.isChapterMarkedAsNew) {
          return this.$t('designer.show_as_new')
        }

        return this.$t('designer.chapter_active')
      }
    },
    data() {
      return {
        draggedOver: false
      }
    },
    methods: {
      select() {
        this.$emit('select', this.chapter)
      },
      appendPage($event) {
        $event.stopPropagation()
        this.$emit('addPage')
      },
      moveChapter($event) {
        if ($event) {
          if ($event.source !== this.chapter.id
            && this.chapter.type !== 'cover'
            && $event.type === 'chapter') {
            this.$emit('moveChapter', {
              type: 'chapter',
              source: $event.source,
              target: this.chapter.id,
            })
          }

          if ($event.type === 'page') {
            this.$emit('movePage', {
              type: 'page',
              source: $event.id,
              source_chapter: $event.chapter,
              target_chapter: this.chapter.id,
            })
          }

          this.draggedOver = false
        }
      },
    },
    components: {
      Button,
      ChapterContext
    }
  }
</script>

<style lang="scss" scoped>
  .c__chapter-wrapper {
    margin-bottom: 8px;

    .c__chapter {
      display: flex;
      min-height: 56px;
      background: white;
      padding: 14px 8px;
      border-radius: 8px;
      margin-bottom: 8px;
      border: 3px solid transparent;
      justify-content: space-between;

      .c__chapter-container {
        display: flex;
        min-width: 80px;
        align-items: center;
        align-self: flex-start;
      }

      &:hover,
      &.c__chapter--selected {
        cursor: pointer;
        border: solid 3px rgba($primary-color, .5)
      }

      /**
       *  Design for Cover Image
       */
      &.c__chapter--cover {
        width: 100%;
        text-align: center;

        .c__chapter-container {
          width: 100%;
          align-self: center;
          align-content: center;

          .c__chapter-title {
            width: 100%;
          }
        }
      }
    }

    /**
     *  Container for pages
     */
    .c__chapter-pages {
      margin-left: 40px;

      .c__chapter-pages-control {
        display: flex;
      }
    }
  }

  .c__chapter-icon {
    width: 16px;
    height: 16px;
    display: flex;
    flex-shrink: 0;
    margin: 4px 8px;
    align-self: flex-start;

    &.c__chapter-icon--grab {
      cursor: grab;
    }

    img {
      width: 100%;
      height: 100%;
      max-width: 100%;
      max-height: 100%;
    }
  }

  .c__chapter-title {
    width: 100%;
    font-size: 16px;
    font-weight: 500;
    color: $darker-blue-color;
    font-family: Roboto, 'sans-serif';

    &.c__chapter-title--untouched {
      color: $dark-gray-color;
    }
  }

  .c__chapter-button {
    display: flex;
    margin: 0 8px;
    flex-shrink: 0;
    cursor: pointer;
    align-items: center;
    align-self: center;
  }
</style>

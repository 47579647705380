var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
  'c__dropdown': true,
  'c__dropdown--borderless': _vm.borderless,
  }},[(_vm.tooltip)?[_c('b-tooltip',{attrs:{"label":_vm.tooltip,"position":"is-bottom","delay":_vm.tooltipDelay}},[_c('div',{staticClass:"dropdown-value",on:{"click":function($event){$event.preventDefault();$event.stopPropagation();_vm.active = !_vm.active}}},[(_vm.icon)?_c('div',{staticClass:"dropdown-icon dropdown-icon--left"},[_c('Icon',{attrs:{"src":_vm.icon}})],1):_vm._e(),(!_vm.hideTitle)?_c('div',{staticClass:"dropdown-title"},[_vm._v(_vm._s(_vm.valueLabel))]):_vm._e(),_c('div',{staticClass:"dropdown-icon"},[_c('Icon',{attrs:{"src":"images/icons/arrow-down.svg"}})],1)])])]:[_c('div',{staticClass:"dropdown-value",on:{"click":function($event){$event.preventDefault();$event.stopPropagation();_vm.active = !_vm.active}}},[(_vm.icon)?_c('div',{staticClass:"dropdown-icon dropdown-icon--left"},[_c('Icon',{attrs:{"src":_vm.icon}})],1):_vm._e(),_c('div',{staticClass:"dropdown-title"},[(_vm.showValueAsLabel)?[_vm._v(_vm._s(_vm._f("upperCase")(_vm.value)))]:[_vm._v(_vm._s(_vm.valueLabel))]],2),_c('div',{staticClass:"dropdown-icon"},[_c('Icon',{attrs:{"src":"images/icons/arrow-down.svg"}})],1)])],_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.active),expression:"active"}],class:{
         'dropdown-entries': true,
         'dropdown-entries--parent': _vm.entriesParentSize,
         'dropdown-mode--openTop': _vm.mode === 'open-top',
         'dropdown-entries--small': _vm.smallDropdownEntries,
       },attrs:{"id":_vm.id}},_vm._l((_vm.values),function(entry,idx){return _c('div',{key:idx,staticClass:"dropdown-entry",on:{"click":function($event){$event.preventDefault();return _vm.select(entry)}}},[_vm._v(" "+_vm._s(_vm.$te(entry.label) ? _vm.$t(entry.label) : entry.label)+" ")])}),0)],2)}
var staticRenderFns = []

export { render, staticRenderFns }
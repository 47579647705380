<template>
  <LoadingComponent
    :load="loading"
    class="c__signup-wrapper">
    <div class="c__signup-topbar">
      <div class="c__signup-container">
        <div
          class="c__signup-item"
          v-for="advantage in advantages">
          <div class="c__signup-item-logo">
            <img :src="advantage.icon" />
          </div>
          <div class="c__signup-item-text">{{ advantage.label }}</div>
        </div>
      </div>
      <div class="c__signup-container">
        <div
          class="c__signup-item"
          v-for="paymentType in paymentTypes">
          <div class="c__signup-item-payment">
            <img :src="paymentType" />
          </div>
        </div>
        <div class="c__signup-item">
          <div class="c__signup-item-logo">
            <img src="images/icons/support.png" />
          </div>
          <div class="c__signup-item-text">Tel. <a href="tel: +4989954572860">+49-89-9545728-60</a> - <a href="mailto: support@digital-folder.com">support@digital-folder.com</a></div>
        </div>
      </div>
    </div>
    <div class="c__signup-content">
      <div class="c-language-switcher">
        <LanguageSwitcher/>
      </div>
      <div class="c-support">
        <Chat />
      </div>
      <component
        :plans="plans"
        :is="component"
        @submit="submit"
        :signup-data="signupData"
      />
    </div>
  </LoadingComponent>
</template>

<script>
import Plans from '../../data/plans'
import APIFactory from '../../api/factory'
import Service from '../../components/signup/Service'
import Company from '../../components/signup/Company'
import Payment from '../../components/signup/Payment'
import Personal from '../../components/signup/Personal'
import Summary from '../../components/signup/Summary'
import Chat from '../../components/signup/support/Chat'
import LoadingComponent from '../../components/global/LoadingComponent'
import LanguageSwitcher from '../../components/settings/LanguageSwitcher'

export default {
  computed: {
    /**
     *  Selects component based on url component mapping
     */
    component() {
      let step = 'service'
      if (this.$route.params.step) {
        step = this.$route.params.step
      }
      return this.urlComponentMapping[step]
    },
  },
  created() {
    this.load()
  },
  data() {
    return {
      urlComponentMapping: {
        'service': Service,
        'company': Company,
        'payment': Payment,
        'summary': Summary,
        'personal': Personal,
      },
      signupData: {},
      loading: false,
      plans: Plans.findPlans(),
      advantages: [
        { icon: 'images/icons/check--red.svg', label: this.$t('signup.safe_payment_options') },
        { icon: 'images/icons/locked--red.svg', label: this.$t('signup.secure_ssl') },
        { icon: 'images/icons/support.png', label: this.$t('signup.email_support') },
        { icon: 'images/icons/german.png', label: this.$t('signup.hosting_in_germany') },
      ],
      paymentTypes: [
        'images/icons/payments/amex.png',
        'images/icons/payments/maestro.png',
        'images/icons/payments/sepa.png',
        'images/icons/payments/visa.png'
      ]
    }
  },
  methods: {
    submit($event) {
      this.signupData = Object.assign({}, this.signupData, $event)
    },
    load() {
      if (this.$route.query && this.$route.query.slug) {
        this.loading = true
        APIFactory.findSignupData(this.$route.query.slug).then(res => {
          if (res && res.success && res.data) {
            this.signupData.city = res.data.city
            this.signupData.company = res.data.company
            this.signupData.email = res.data.email
            this.signupData.firstname = res.data.firstname
            this.signupData.lastname = res.data.lastname
            this.signupData.mediation = res.data.mediation
            this.signupData.postal_code = res.data.postcode
            this.signupData.country = res.data.country
            this.signupData.street = res.data.street
            this.signupData.vat_id = res.data.vat_id

            Object.keys(this.signupData).forEach(key => {
              if (this.signupData[key]) {
                if (('' + this.signupData[key]).toLowerCase() === 'missing') {
                  this.signupData[key] = null
                }
              }
            })
          }
        }).finally(() => this.loading = false)
      }
    },
  },
  components: {
    LanguageSwitcher,
    LoadingComponent,
    Chat,
  },
}
</script>

<style lang="scss">
  .c__signup-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .c__signup-topbar {
    z-index: 2;
    width: 100%;
    height: 32px;
    display: flex;
    padding: 0 24px;
    background: $white;
    align-items: center;
    box-shadow: $box-shadow-menu;
    justify-content: space-between;

    @media (max-width: $breakpoint-mobile) {
      display: none;
    }

    .c__signup-container {
      display: flex;

      .c__signup-item {
        display: flex;
        margin: 0 8px;
        font-size: 12px;
        font-weight: bold;
        align-items: center;
        color: $dark-blue-color;

        .c__signup-item-logo {
          margin-right: 6px;

          img {
            max-width: 16px;
            max-height: 16px;
          }
        }

        .c__signup-item-payment {
          margin-top: 4px;

          img {
            max-width: 32px;
            max-height: 32px;
          }
        }
      }
    }
  }

  .c__signup-content {
    width: 100%;
    height: calc(100% - 32px);

    @media (max-width: $breakpoint-mobile) {
      height: 100%;
    }
  }
</style>

<style lang="scss" scoped>
  .c-language-switcher {
    top: 64px;
    right: 32px;
    z-index: 10;
    position: fixed;

    @media (max-width: $breakpoint-mobile) {
      display: none;
    }
  }
</style>

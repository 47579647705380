<template>
  <SidebarLayout :title="$t('settings.notifications')">
    <b-columns>
      <b-column>
        <b-card v-if="!data || data.length === 0">{{ $t('settings.no_notifications') }}</b-card>
        <Notification
          v-else
          :key="index"
          v-for="(notification, index) in data"
          :message="$t('settings.invite_message', { company: notification.data.company, teams: notification.data.teams.join(', ') })">
          <b-button
            type="is-success"
            class="notification-button"
            @click.prevent="accept(notification.data.app_slug)">
            {{ $t('general.yes') }}
          </b-button>
          <b-button
            type="is-danger"
            class="u-ml--8 notification-button"
            @click.prevent="decline(notification.data.app_slug)">
            {{ $t('general.no') }}
          </b-button>
        </Notification>
      </b-column>
    </b-columns>
  </SidebarLayout>
</template>

<script>
  import SidebarLayout from '../../components/settings/SidebarLayout'
  import Notification from '../../components/settings/Notification'
  import APIFactory from '../../api/factory'
  import Table from '../../components/Table'
  import Helpers from '../../helpers'

  export default {
    data() {
      return {
        data: [],
        loading: false,
      }
    },
    created() {
      this.load()
    },
    methods: {
      load() {
        this.loading = true
        return APIFactory.findNotifications(this.$route.params.slug).then(res => {
          if (res && res.success) {
            this.data = res.data
          }
        }).finally(() => this.loading = false)
      },
      accept(slug) {
        APIFactory.acceptInvitation(slug).then(res => {
          if (res && res.success) {
            this.$store.commit('auth/setAvatar', res.data.avatar)
            this.$store.commit('auth/setUserId', res.data.user_id)
            this.$store.commit('auth/setToken', res.data.token)
            this.$store.commit('auth/setApps', res.data.apps)
            this.$store.commit('app/setVersion', res.data.version)
            this.$store.commit('auth/setSuperadmin', !!res.data.superadmin)
            this.$store.commit('auth/setFirstLogin', !!res.data.firstLogin)
            this.load()
          }
        })
      },
      decline(slug) {
        APIFactory.declineInvitation(slug).then(res => {
          if (res && res.success) {
            this.load().then(() => {
              if (this.data.length < 2) {
                this.$router.push('/auth/logout')
              } else {
                this.$router.push('/')
              }
            })
          }
        })
      }
    },
    components: {
      Table,
      Notification,
      SidebarLayout,
    }
  }
</script>

<style lang="scss" scoped>
  .notification-button {
    @apply
      px-4;
  }
</style>

<template>
    <b-message
        type="is-danger">
        <div class="c__error-wrapper">
            <b-icon
                icon="info-circle"
                pack="fas" />
            <div class="c__error-message">{{ message }}</div>
        </div>
    </b-message>
</template>

<script>
    export default {
    	props: [
    		'message'
        ],
    }
</script>

<style lang="scss" scoped>
    .c__error-wrapper {
        display: flex;
        align-items: center;

        .c__error-message {
            margin-left: 20px;
        }
    }
</style>
<template>
  <div class="c__release-modal" v-show="active">
    <div class="c__release-window">
      <ReleaseForm
        :folder="folder"
        @success="$emit('success')"
        @close="$emit('close')"
      />
      <div class="c__release-preview">
        <div class="c__release-preview-wrapper">
          <FolderDisplay
            :big="true"
            :image="cover"
            :folder="folder"
          />
          <div class="c__release-folder-title">{{ folder.name }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import FolderDisplay from '../FolderDisplay'
  import ReleaseForm from '../ReleaseForm'

  export default {
    props: [
      'active',
      'folder',
    ],
    computed: {
      cover() {
        if (this.folder && this.folder.cover) {
          return this.folder.cover.preview
        }
      }
    },
    components: {
      FolderDisplay,
      ReleaseForm
    }
  }
</script>

<style lang="scss" scoped>
  .c__release-modal {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    position: fixed;
    z-index: 20000;
    align-items: center;
    justify-content: center;
    background: $darkest-blue-color;

    .c__release-window {
      width: 100%;
      height: 100%;
      display: flex;
      max-width: 880px;
      max-height: 640px;
      border-radius: 8px;
      background: url('/images/desks/background_seamless_wood_black.jpg');

      @media (max-width: $breakpoint-mobile) {
        max-width: none;
        max-height: none;
      }
    }

    .c__release-preview {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      max-width: calc(100% - 512px);

      @media (max-width: $breakpoint-mobile) {
        display: none;
      }

      .c__release-folder-title {
        color: $white;
        font-size: 24px;
        margin-top: 24px;
        text-align: center;
        font-family: Roboto;
      }
    }
  }
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('drag',{class:{
  	    'c__media-object': true,
          'c__media-object--hidden': _vm.object.invisible
      },attrs:{"transfer-data":_vm.object.id}},[_c('div',{class:{
    'c__media-object-cover': true,
    'c__media-object--selected': _vm.selected,
    'c__media-object-cover--enable-selection': _vm.enableSelection
    },on:{"click":function($event){$event.preventDefault();return _vm.$emit('select', _vm.object.id)}}},[_c('img',{attrs:{"src":_vm.preview}})]),(_vm.hasPendingThumbnail)?_c('IconButton',{staticClass:"c-pending-object-refresh",attrs:{"icon":"images/icons/move.svg"},on:{"click":function($event){return _vm.$router.go()}}}):_vm._e(),_c('div',{staticClass:"c__media-object-meta"},[_c('div',{staticClass:"c__media-object-fileType"},[_c('img',{attrs:{"src":_vm.icon}})]),_c('div',{staticClass:"c__media-object-label"},[_vm._v(_vm._s(_vm.object.name))])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.enableContext),expression:"enableContext"}],staticClass:"c__media-object-context"},[_c('ObjectContext',{attrs:{"object":_vm.object,"disabled":_vm.isContainerReleased},on:{"move":function($event){return _vm.$emit('move', $event)},"edit":function($event){return _vm.$emit('edit', $event)},"delete":function($event){return _vm.$emit('delete', $event)}}})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.enableSelection),expression:"enableSelection"}],class:{
      	    'c__media-object-selection': true,
      	    'c__media-object-selection--selected': _vm.selected
           },on:{"click":function($event){$event.preventDefault();return _vm.$emit('select', _vm.object.id)}}},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.selected),expression:"selected"}],attrs:{"src":"images/icons/check--white.svg"}})])],1)}
var staticRenderFns = []

export { render, staticRenderFns }
import FolderDesigner from '../../views/FolderDesigner'

export default [
	{
		path: '/:slug/workspaces/:teamId/:rackId/:folderId/designer',
		component: FolderDesigner,
		meta: {
			auth: true,
		},
	}
]

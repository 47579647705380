<template>
  <input
      type="text"
      :value="value"
      :required="required"
      class="basic expanded"
      :disabled="disabled"
      @blur="handleBlurEvent"
      :placeholder="placeholder"
      @input="$emit('input', $event.target.value)"
  />
</template>

<script>
export default {
  props: [
    'value',
    'required',
    'placeholder',
    'disabled'
  ],
  methods: {
    handleBlurEvent($event) {
      if (!$event.target.value || $event.target.value.length === 0) {
        $event.target.style.border = '1px solid #ff423d'
        if ($event.target.classList.contains('input-icon--valid')) {
          $event.target.classList.remove('input-icon--valid')
        }
        $event.target.classList.add('input-icon--invalid')
      } else {
        $event.target.style.border = '1px solid #f0f3f4'
        if ($event.target.classList.contains('input-icon--invalid')) {
          $event.target.classList.remove('input-icon--invalid')
        }
        $event.target.classList.add('input-icon--valid')
      }
    }
  },
  watch: {
    value: {
      immediate: true,
      handler() {
        if (this.$el) {
          this.$el.classList.remove('input-icon--valid')
          this.$el.classList.remove('input-icon--invalid')
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.input-icon--valid {
  background: url('/images/icons/check.svg') no-repeat calc(100% - 16px);
}

.input-icon--invalid {
  background: url('/images/icons/times--red.svg') no-repeat calc(100% - 16px);
}
</style>

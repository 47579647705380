<template>
  <SignupSidebar
      :plans="plans"
      :link="'/auth/signup'"
      :coupon="signupData.coupon"
      :title="$t('signup.personal_heading')">
    <div class="c-container-wrapper">
      <div class="c-container">
        <template v-if="!isMobile">
          <div class="c-headline-1 u-align-center">{{ $t('signup.personal_heading') }}</div>
          <Progress
              :index="1"
              :plan="plan"
          />
        </template>
        <form
            class="c-form c-form-responsive--mt-48-8"
            @submit.prevent="submit">
          <div class="c-row">
            <div class="c-col c-col-6">
              <div class="c-form-element">
                <div class="c-form-label">{{ $t('signup.firstname') }}*</div>
                <input
                    required
                    class="c-form-input"
                    v-model="signupData.firstname"
                    :placeholder="$t('signup.firstname_placeholder')"
                />
              </div>
            </div>
            <div class="c-col c-col-6">
              <div class="c-form-element">
                <div class="c-form-label">{{ $t('signup.lastname') }}*</div>
                <input
                    required
                    class="c-form-input"
                    v-model="signupData.lastname"
                    :placeholder="$t('signup.lastname_placeholder')"
                />
              </div>
            </div>
          </div>
          <div class="c-row c-row-responsive--mt-24-8">
            <div class="c-col c-col-12">
              <div class="c-form-element">
                <div class="c-form-label">{{ $t('signup.email_address') }}*</div>
                <input
                    required
                    type="email"
                    class="c-form-input"
                    v-model="signupData.email"
                    :placeholder="$t('signup.email_address_placeholder')"
                />
              </div>
            </div>
          </div>
          <div class="c-row c-row-responsive--mt-24-8">
            <div class="c-col c-col-12">
              <div class="c-form-element">
                <div class="c-form-label">{{ $t('signup.password') }}*</div>
                <password-input v-model="signupData.password"/>
              </div>
            </div>
          </div>
          <div class="c-row u-mt--24">
            <div class="c-col c-col-12 c-font-size--12"
                 v-html="$t('signup.personal_information', {url})"
            />
          </div>
          <div class="c-row u-mt--32">
            <div class="c-col c-col-12 modal-controls modal-controls--spacing">
              <Button
                  theme="default"
                  :disable-icon-view-only-on-mobile="true"
                  @click="back">
                {{ $t('signup.back') }}
              </Button>
              <Button
                  type="submit"
                  theme="primary"
                  :disable-icon-view-only-on-mobile="true">
                {{ $t('signup.continue') }}
              </Button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </SignupSidebar>
</template>

<script>
import PasswordInput from '../global/inputs/PasswordInput'
import SignupSidebar from '../signup/SignupSidebar'
import Button from '../global/buttons/Button'
import Sidebar from '../layout/Sidebar'
import Helpers from '../../helpers'
import Progress from './Progress'
import {mapGetters} from 'vuex'

export default {
  props: [
    'plans',
    'signup-data'
  ],
  computed: {
    ...mapGetters({
      isMobile: 'app/isMobileMode'
    }),
    plan() {
      if (this.plans) {
        return this.plans.find(item => item.id === this.$route.query.plan)
      }
    }
  },
  data() {
    return {
      url: 'https://www.digital-folder.com/wp-content/uploads/2020/06/ADV-digital-optimisation-gross-partner.pdf'
    }
  },
  created() {
    if (this.$route.query
        && this.$route.query.slug
        && this.signupData.lastname
        && this.signupData.firstname) {
      this.hrefTo('/auth/signup/company')
    }
  },
  methods: {
    submit() {
      if (!Helpers.checkPassword(this.signupData.password)) {
        Helpers.callToast('is-danger', this.$t('signup.password_needs_several_chars'))
        return
      }

      return this.hrefTo('/auth/signup/company')
    },
    back() {
      if (this.$route.query && this.$route.query.slug) {
        if (this.$route.query.plan && this.$route.query.plan === 'free') {
          this.hrefTo('/auth/signup/summary')
          return
        }
        this.hrefTo('/' + this.$route.query.slug + '/settings/plans')
        return
      }
      this.hrefTo('/auth/signup/service', true)
    },
    hrefTo(link, resetQuery) {
      let query = this.$route.query
      if (resetQuery) {
        query = {}
      }
      this.$router.push({
        path: link,
        query
      })
    },
  },
  components: {
    PasswordInput,
    SignupSidebar,
    Progress,
    Sidebar,
    Button,
  }
}
</script>

<style lang="scss" scoped>
.highlighted {
  color: $primary-color;
}
</style>

<template>
    <b-loading :active="true" />
</template>

<script>
    import Helpers from '../../helpers'
    import APIFactory from '../../api/factory'

    export default {
    	created() {
		    APIFactory.verify(this.$route.params.code).then(res => {
                if (res && res.success) {
		    	    Helpers.callToast('is-success', this.$t('signup.verified'), 5000)
                	this.$router.push('/auth/login')
                }
            })
	    }
    }
</script>

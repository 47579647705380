<template>
    <div class="modal__button-wrapper">
        <b-tooltip
            type="is-primary"
            :label="$t('tooltips.change_role')">
            <b-button
                class="ml-1"
                icon-pack="fas"
                icon-left="user"
                type="is-primary"
                @click.prevent="active = true"
            />
        </b-tooltip>
        <Modal
            :active="active"
            @close="active = false"
            :title="$t('settings.change_role')">
            <b-columns>
                <b-column>
                    <b-field
                        class="mt-2"
                        :label="$t('general.role')">
                        <b-select
                            expanded
                            v-model="selectedRole">
                            <option
                                :key="role.id"
                                :value="role.id"
                                v-for="role in roles">
                                {{ role.name }}
                            </option>
                        </b-select>
                    </b-field>

                    <b-button
                        expanded
                        class="mt-2"
                        type="is-primary"
                        :loading="loading"
                        :disabled="loading"
                        @click.prevent="save">
                        {{ $t('general.save') }}
                    </b-button>
                </b-column>
            </b-columns>
        </Modal>
    </div>
</template>

<script>
	import Modal from './Modal'
    import APIFactory from '../../../api/factory'

	export default {
		props: [
			'user',
			'roles'
        ],
		data() {
			return {
				active: false,
                loading: false,
                selectedRole: null,
			}
		},
        created() {
			if (this.user && this.roles) {
			    this.selectedRole = this.roles.find(item => item.id === this.user.role_id)
                if (this.selectedRole) {
                	this.selectedRole = this.selectedRole.id
                }
            }
        },
		methods: {
			save() {
				this.loading = true
                APIFactory.changeRole(this.$route.params.slug, this.user.id, this.selectedRole).then(res => {
                	this.loading = false
                    if (res && res.success) {
                        this.$emit('success')
                    	this.active = false
                    }
                })
            }
        },
		components: {
			Modal,
		}
	}
</script>
<template>
  <Layout>
    <div class="sidebar__layout-wrapper">
      <Loader v-if="loading"/>
      <nav
        v-if="!isCompactMode"
        class="ml-2 mt-2 mb-2">
        <b-menu class="contents p-2">
          <b-menu-list
            :key="index"
            :label="$t(group)"
            v-for="(group, index) in groups">
            <b-menu-item
              :key="entryIndex"
              tag="router-link"
              v-if="entry.group === group"
              v-for="(entry, entryIndex) in permissionEntries"
              :to="'/' + $route.params.slug + entry.link"
              :active="$route.path.indexOf(entry.link) > -1"
              :expanded="$route.path.indexOf(entry.link) > -1">
              <template slot="label" slot-scope="props">
                <div class="entry__wrapper">
                  <div class="icon__wrapper">
                    <b-icon
                      size="is-small"
                      :icon="entry.icon"
                      :pack="entry.pack"
                    />
                    <div class="ml-2">{{ $t(entry.label) }}</div>
                  </div>
                  <template v-if="entry.link === '/settings/teams' || entry.link === '/settings/permissions'">
                    <b-icon
                      class="is-pulled-right"
                      :icon="props.expanded ? 'angle-up' : 'angle-down'">
                    </b-icon>
                  </template>
                </div>
              </template>
              <template v-if="entry.link === '/settings/teams'">
                <b-menu-item
                  :key="team.id"
                  tag="router-link"
                  v-for="team in teams"
                  :active="$route.path === '/' + $route.params.slug + '/settings/teams/' + team.id"
                  :to="'/' + $route.params.slug + entry.link + '/' + team.id">
                  <template slot="label">
                    <div class="entry__wrapper">
                      <div class="icon__wrapper">
                        <b-icon
                          size="is-small"
                          icon="users"
                          pack="fas"
                        />
                        <div class="ml-2">{{ team.name }}</div>
                      </div>
                    </div>
                  </template>
                </b-menu-item>
              </template>
              <template v-if="entry.link === '/settings/permissions'">
                <b-menu-item
                  :key="role.id"
                  tag="router-link"
                  v-for="role in roles"
                  :active="$route.path === '/' + $route.params.slug + '/settings/permissions/' + role.id"
                  :to="'/' + $route.params.slug + entry.link + '/' + role.id">
                  <template slot="label">
                    <div class="entry__wrapper">
                      <div class="icon__wrapper">
                        <b-icon
                          size="is-small"
                          icon="users"
                          pack="fas"
                        />
                        <div class="ml-2">{{ role.name }}</div>
                      </div>
                    </div>
                  </template>
                </b-menu-item>
              </template>
            </b-menu-item>
          </b-menu-list>
          <b-menu-list>
            <b-menu-item
              class="mt-3"
              to="/auth/logout"
              tag="router-link">
              <template slot="label">
                <div class="entry__wrapper">
                  <div class="icon__wrapper">
                    <b-icon
                      size="is-small"
                      icon="power-off"
                      pack="fas"
                    />
                    <div class="ml-2">{{ $t('auth.logout') }}</div>
                  </div>
                </div>
              </template>
            </b-menu-item>
          </b-menu-list>
        </b-menu>
        <div class="has-text-centered">
          <small>Version: {{ version }}</small>
        </div>
      </nav>
      <section class="sidebar__content">
        <header v-if="title">{{ $t(title) }}</header>
        <div :class="{'sidebar__content--centered': centered}">
          <slot/>
        </div>
      </section>
    </div>
  </Layout>
</template>

<script>
  import {mapGetters} from 'vuex'
  import APIFactory from '../../api/factory'
  import SidebarEntries from '../../data/sidebar'
  import Loader from '../global/Loader'
  import Layout from '../../components/settings/Layout'
  import ModelMapper from '../../data/mapper'

  export default {
    props: [
      'title',
      'updated-teams',
      'centered',
    ],
    computed: {
      ...mapGetters({
        apps: 'auth/apps',
        version: 'app/version',
        isCompactMode: 'app/isCompactMode'
      }),
      groups() {
        return this.permissionEntries
        .map(item => item.group)
        .filter((v, i, a) => a.indexOf(v) === i)
      },
      permissionEntries() {
        let selectedApp = this.apps.find(item => item.slug === this.$route.params.slug)
        if (selectedApp) {
          return this.entries.filter(item => {
            if (!item.permissions) {
              return true
            }

            let permissions = item.permissions.filter(requiredPermission => {
              return !selectedApp.permissions.includes(requiredPermission)
            })

            return permissions.length === 0
          })
        }
      },
      app() {
        return this.apps.find(app => app.slug === this.$route.params.slug)
      },
      hasManagePermissionsPermission() {
        if (this.app && this.app.permissions) {
          return this.app.permissions.includes('can_manage_permissions')
        }
      },
      hasManageTeamsPermission() {
        if (this.app && this.app.permissions) {
          return this.app.permissions.includes('can_manage_teams')
        }
      }
    },
    data() {
      return {
        entries: SidebarEntries,
        loading: false,
        teams: [],
        roles: [],
      }
    },
    created() {
      this.load()
    },
    methods: {
      load() {
        let promises = []
        this.loading = true
        let hasTeamQuery = false
        // TODO: Check this here!
        if (this.permissionEntries && this.permissionEntries.find(item => item.link === '/settings/teams')
          && this.hasManageTeamsPermission) {
          promises.push(APIFactory.findTeams(this.$route.params.slug))
          hasTeamQuery = true
        }

        if (this.permissionEntries.find(item => item.link === '/settings/permissions')
          && this.hasManagePermissionsPermission) {
          promises.push(APIFactory.findRoles(this.$route.params.slug))
        }

        Promise.all(promises).then(res => {
          this.loading = false
          if (res) {
            let roleIndex = !hasTeamQuery ? 0 : 1

            if (res[0] && res[0].success) {
              this.teams = ModelMapper.map(this, res[0].data, 'Team')
            }

            if (res[roleIndex] && res[roleIndex].success) {
              this.roles = ModelMapper.map(this, res[roleIndex].data, 'Role')
            }
          }
        }).finally(() => this.loading = false)
      }
    },
    watch: {
      updatedTeams() {
        this.teams = this.updatedTeams
      }
    },
    components: {
      Layout,
      Loader
    }
  }
</script>

<style lang="scss" scoped>
  $sidebar-width: 330px;

  .sidebar__layout-wrapper {
    width: 100%;
    height: 100%;
    display: flex;

    nav {
      width: $sidebar-width;
      height: calc(100% - 2rem);

      .contents {
        box-shadow: $box-shadow;
      }

      .entry__wrapper {
        display: flex;
        word-break: break-all;
        justify-content: space-between;

        .icon__wrapper {
          display: flex;
          align-items: center;
        }
      }
    }

    .sidebar__content {
      padding: 1rem;
      overflow-y: auto;
      word-break: break-all;
      width: calc(100% - #{$sidebar-width});

      @media (max-width: $breakpoint) {
        width: 100%;
      }

      header {
        padding: 20px;
        font-size: 22px;
        box-shadow: $box-shadow;
      }

      div {
        margin-top: 1rem;

        &.sidebar__content--centered {
          display: flex;
          align-items: center;
          flex-direction: column;
        }
      }
    }
  }
</style>

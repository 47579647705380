<template>
    <b-tooltip
        :label="tooltip"
        position="is-left"
        :delay="tooltipDelay">
        <div @click.prevent="$emit('select', elements)"
            :class="{
                'c__page-button-wrapper': true,
                'c__page-button-wrapper--selected': selected
             }">
            <div class="c__page-button">
                <template v-if="elements === 1">
                    <div class="c__page-button-element-one"></div>
                </template>

                <template v-if="elements === 2">
                    <div class="c__page-button-element-two">
                        <div class="c__page-button-element-two-sub"></div>
                        <div class="c__page-button-element-two-sub"></div>
                    </div>
                </template>

                <template v-if="elements === 4">
                    <div class="c__page-button-element-four">
                        <div class="c__page-button-element-four-row">
                            <div class="c__page-button-element-four-sub"></div>
                            <div class="c__page-button-element-four-sub"></div>
                        </div>
                        <div class="c__page-button-element-four-row">
                            <div class="c__page-button-element-four-sub"></div>
                            <div class="c__page-button-element-four-sub"></div>
                        </div>
                    </div>
                </template>

                <template v-if="elements === 6">
                    <div class="c__page-button-element-six">
                        <div class="c__page-button-element-six-row">
                            <div class="c__page-button-element-six-sub"></div>
                            <div class="c__page-button-element-six-sub"></div>
                        </div>
                        <div class="c__page-button-element-six-row">
                            <div class="c__page-button-element-six-sub"></div>
                            <div class="c__page-button-element-six-sub"></div>
                        </div>
                        <div class="c__page-button-element-six-row">
                            <div class="c__page-button-element-six-sub"></div>
                            <div class="c__page-button-element-six-sub"></div>
                        </div>
                    </div>
                </template>
            </div>
        </div>
    </b-tooltip>
</template>

<script>
    import {mapGetters} from 'vuex'

    export default {
    	props: [
    		'elements',
            'selected',
            'tooltip',
        ],
        computed: {
    		...mapGetters({
                tooltipDelay: 'ui/tooltipDelay'
            })
        }
    }
</script>

<style lang="scss" scoped>
    .c__page-button-wrapper {
        padding: 5px;
        cursor: pointer;
        margin-top: 24px;
        border-radius: 3px;
        border: solid 1px $lighter-gray-color;

        &:hover {
            padding: 3px;
            border: solid 3px rgba($primary-color, .5);
        }

        &:hover > .c__page-button .c__page-button-element-one,
        &:hover > .c__page-button .c__page-button-element-two .c__page-button-element-two-sub,
        &:hover > .c__page-button .c__page-button-element-four .c__page-button-element-four-sub,
        &:hover > .c__page-button .c__page-button-element-six .c__page-button-element-six-sub {
            background: $primary-color;
        }

        /*
         *
         *  Element is selected
         *
         */
        &.c__page-button-wrapper--selected {
            padding: 3px;
            border: solid 3px rgba($primary-color, .5);

            .c__page-button-element-one,
            .c__page-button-element-two-sub,
            .c__page-button-element-four .c__page-button-element-four-sub,
            .c__page-button-element-six .c__page-button-element-six-sub, {
                background: $primary-color;
            }
        }

        .c__page-button {
            width: 36px;
            height: 36px;
        }
    }

    /*
     *  1 Element
     */
    .c__page-button-element-one {
        width: 100%;
        height: 100%;
        background: $lighter-gray-color;
    }

    /*
     *  2 Elements
     */
    .c__page-button-element-two {
        width: 100%;
        height: 100%;

        .c__page-button-element-two-sub {
            width: 100%;
            height: calc(50% - 1px);
            background: $lighter-gray-color;

            &:first-child {
                margin-bottom: 2px;
            }
        }
    }

    /*
     *  4 Elements
     */
    .c__page-button-element-four {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;

        .c__page-button-element-four-row {
            width: 100%;
            height: 50%;
            display: flex;

            &:first-child {
                margin-bottom: 2px;
            }

            .c__page-button-element-four-sub {
                height: 100%;
                margin-left: 1px;
                margin-right: 1px;
                width: calc(50% - 2px);
                background: $lighter-gray-color;
            }
        }
    }

    /*
     *  6 Elements
     */
    .c__page-button-element-six {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;

        .c__page-button-element-six-row {
            width: 100%;
            height: 50%;
            display: flex;

            &:first-child,
            &:nth-child(2) {
                margin-bottom: 2px;
            }

            .c__page-button-element-six-sub {
                height: 100%;
                margin-left: 1px;
                margin-right: 1px;
                width: calc(50% - 2px);
                background: $lighter-gray-color;
            }
        }
    }
</style>

<template>
  <div
    class="labeled-input-wrapper"
    @click="$emit('click', $event)">
    <label
      class="label"
      v-if="Object.keys($slots).length > 0">
      <slot />
    </label>
    <input
      :type="type"
      class="input"
      :value="value"
      :required="required"
      :placeholder="placeholder"
      v-if="!isCustomInput"
      @change="onChange"
      @input="$emit('input', $event.target.value)"
    />
    <input
      type="file"
      class="input"
      @change="onChange"
      v-if="type === 'file'"
      :placeholder="placeholder"
    />
    <select
      class="input"
      :required="required"
      v-if="type === 'select'"
      :placeholder="placeholder"
      @change="$emit('input', $event.target.value)">
      <option
        :key="v.id"
        :value="v.id"
        :selected="value === v.id"
        v-for="v in values">
        {{ v.label }}
      </option>
    </select>
    <radio-input
      :wrap="wrap"
      :value="value"
      :values="values"
      :required="required"
      :placeholder="placeholder"
      v-if="type === 'radio'"
      @input="$emit('input', $event)"
    />
    <textarea
      :value="value"
      class="textarea"
      :required="required"
      :placeholder="placeholder"
      v-if="type === 'textarea'"
      @input="$emit('input', $event.target.value)">
    </textarea>
  </div>
</template>

<script>
  export default {
    props: [
      'wrap',
      'type',
      'value',
      'values',
      'required',
      'placeholder',
    ],
    computed: {
      isCustomInput() {
        return this.type === 'radio'
          || this.type === 'textarea'
          || this.type === 'select'
          || this.type === 'file'
      }
    },
    methods: {
      onChange($event) {
        if ($event.target.type === 'file') {
          let fileReader = new FileReader()
          fileReader.onload = () => {
            this.$emit('change', {
                filename: $event.target.files[0].name,
                data: fileReader.result.substring(fileReader.result.indexOf('base64,') + 7),
                extension: $event.target.files[0].name.split('.').pop().toLowerCase()
            })
          }
          fileReader.readAsDataURL($event.target.files[0])
          return
        }
        this.$emit('input', $event.target.value)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .labeled-input-wrapper {
    .label {
      @apply
        text-xs
        font-semibold
        text-df-dark-blue;
    }

    .input {
      @apply
        py-1.5
        px-2
        h-10
        border
        rounded
        text-base;
    }

    .textarea {
      @apply
        px-2
        h-40
        py-1.5
        border
        w-full
        rounded;
    }
  }
</style>
<template>
  <div class="c-preview-top">
    <div class="c-preview-sidebar-icon">
      <img :src="icon" @click.prevent="hrefTo">
    </div>
    <div class="c-preview-sidebar-folder-mobile">
      <FolderDisplay
        :folder="folder"
        :image="cover"
      />
      <template>
        <img
          :src="background"
          v-if="hasBackgroundImage"
        />
        <div
          v-else
          class="c-folder-background"
          :style="'background: ' + background"
        />
      </template>
    </div>

    <div class="c-preview-sidebar-container">
      <Button
        @click="open"
        theme="primary"
        class="u-mt--16"
        :expanded="true"
        :loading="loading"
        v-if="isMobile"
        :disable-icon-view-only-on-mobile="true">
        {{ $t('display.open') }}
      </Button>
      <div class="c-preview-sidebar-title u-mt--56">{{ title }}</div>
      <div class="c-preview-sidebar-description u-mt--32">
        {{ $t('display.description_1') }}
        <br>
        <br>
        <template v-if="folder && folder.has_password">{{ $t('display.description_2') }}</template>
        <template v-if="!folder">{{ $t('display.description_3') }}</template>
      </div>
      <form class="u-mt--32 u-mb--32" v-if="folder">
        <div class="form-element"
           v-if="folder.has_password">
          <label class="basic">{{ $t('signup.password') }}*</label>
          <input
            type="password"
            v-model="password"
            class="basic expanded"
            :placeholder="$t('signup.password_placeholder')"
          />
        </div>
        <Button
            @click="open"
            theme="primary"
            class="u-mt--16"
            :expanded="true"
            :loading="loading"
            v-if="!isMobile"
            :disable-icon-view-only-on-mobile="true">
          {{ $t('display.open') }}
        </Button>
        <div class="form-element u-mt--8 mail-to"
             v-if="folder.has_password">
          <a :href="mail">{{ $t('display.request_password') }}</a>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import FolderDisplay from '../../workspace/FolderDisplay'
import Button from '../../global/buttons/Button'
import APIFactory from '../../../api/factory'
import {mapGetters} from 'vuex'

export default {
  props: [
    'folder',
    'title'
  ],
  computed: {
    ...mapGetters({
      apiURL: 'app/apiURL',
      isMobile: 'app/isMobileMode'
    }),
    icon() {
      return this.folder && this.folder.sender && this.folder.sender.icon
          ? process.env.VUE_APP_API_URL + this.folder.sender.icon
          : 'images/do-logo.svg'
    },
    mail() {
      let body = 'mailto:' + this.folder.email + '?subject=' + this.folder.name + ' - ' + this.$t('display.email_subject')
          + '&body=' + this.$t('workspaces.email_message', {link: this.$route.path})
      // Add foldername
      body += '%0D%0A Digitaler Ordner: ' + this.folder.name

      return body
    },
    cover() {
      if (this.folder && this.folder.cover) {
        return this.folder.cover.preview
      }
    },
    background() {
      if (this.hasBackgroundImage) {
        return this.apiURL + '/api/object/' + this.$route.params.code + '/' + this.folder.background_image + '/background'
      }

      if (this.folder.background_color) {
        return this.folder.background_color
      }

      return 'url("images/desks/background_seamless_wood_black.jpg")'
    },
    hasBackgroundImage() {
      return !!this.folder.background_image
    },
    hasBackgroundColor() {
      return !!this.folder.background_color
    }
  },
  data() {
    return {
      loading: false,
      password: null,
    }
  },
  methods: {
    open() {
      this.loading = true
      APIFactory.checkPassword(this.$route.params.code, this.password).then(res => {
        if (res && res.success) {
          this.$emit('open', res.data)
        }
      }).finally(() => this.loading = false)
    },
    hrefTo() {
      if (this.folder
          && this.folder.sender
          && this.folder.sender.website
          && this.folder.sender.icon) {
        window.open(this.folder.sender.website, '_blank')
        return
      }
      window.open('https://digital-folder.com', '_blank')
    }
  },
  components: {
    FolderDisplay,
    Button
  }
}
</script>

<style lang="scss" scoped>
.c-preview-top {
  width: 100%;
  overflow-y: auto;
  height: calc(100% - 66px);
}

.c-preview-sidebar-description {
  font-size: 14px;
  font-family: Roboto;
  color: $darker-blue-color;
}

.c-preview-sidebar-title {
  font-size: 32px;
  font-family: Roboto;
  color: $darker-blue-color;

  @media (max-width: $breakpoint-mobile) {
    font-size: 20px;
    font-weight: 500;
    margin-top: 24px;
  }
}

.c-preview-sidebar-icon {
  height: 48px;
  display: flex;
  flex-shrink: 0;
  justify-content: flex-start;

  img {
    flex-shrink: 0;
    cursor: pointer;
    max-width: 100%;
    max-height: 48px;
  }

  @media (max-width: $breakpoint-mobile) {
    margin: 16px;
  }
}

.c-preview-sidebar-folder-mobile {
  display: none;

  @media (max-width: $breakpoint-mobile) {
    width: 100%;
    height: 325px;
    display: flex;
    margin-top: 25px;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 0;
    //background: url('/images/desks/background_seamless_wood_black.jpg');

    img,
    .c-folder-background {
      position: absolute;
      max-width: 100%;
      max-height: 100%;
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: 0;
    }
  }
}

.c-preview-sidebar-container {
  @media (max-width: $breakpoint-mobile) {
    padding: 0 16px;
  }
}

.mail-to a {
  font-size: 14px;
  color: $darker-blue-color;
}
</style>

<template>
  <div class="modal__button-wrapper mt-2">
    <c-button @click.prevent="active = true">{{ title }}</c-button>
    <Modal
      :active="active"
      @close="active = false"
      :title="title">
      <ErrorMessage
        v-if="error"
        :message="$t('general.invalid_form')"
      />
      <b-columns>
        <b-column>
          <div class="description">
            {{ $t('settings.invite_description') }}
            <br>
            {{ $t('settings.invite_account_status_based') }}
          </div>
          <b-message
            type="is-warning"
            class="u-mt--16">
            {{ $t('settings.invite_support_description') }}
          </b-message>
          <b-field
            class="mt-2"
            :label="$t('general.email')">
            <b-input
              type="email"
              v-model="email"
            />
          </b-field>
          <b-field
            :label="$t('settings.team')">
            <b-select
              expanded
              v-model="selectedTeam">
              <option :value="null">{{ $t('settings.select_team') }}</option>
              <option
                :key="team.id"
                :value="team.id"
                v-for="team in teams">
                {{ team.name }}
              </option>
            </b-select>
          </b-field>

          <b-button
            expanded
            class="mt-2"
            type="is-primary"
            :loading="loading"
            :disabled="loading"
            @click.prevent="invite">
            {{ title }}
          </b-button>
        </b-column>
      </b-columns>
    </Modal>
  </div>
</template>

<script>
  import Modal from './Modal'
  import Helpers from '../../../helpers'
  import APIFactory from '../../../api/factory'
  import ErrorMessage from '../../ErrorMessage'

  export default {
    props: [
      'title',
      'teams',
    ],
    data() {
      return {
        email: null,
        error: false,
        active: false,
        loading: false,
        selectedTeam: null,
      }
    },
    methods: {
      invite() {
        if (!this.email) {
          this.error = true
          return
        }

        this.error = false
        this.loading = true
        APIFactory.invite(this.$route.params.slug, this.email, this.selectedTeam).then(res => {
          if (res && res.success) {
            Helpers.callToast('is-success', this.$t('settings.invited_successful'))
            this.$emit('success')
            this.active = false
          }
          this.loading = false
        })
      }
    },
    components: {
      ErrorMessage,
      Modal,
    }
  }
</script>

<style lang="scss" scoped>
  .description {
    @apply
      text-base
      text-justify
      break-normal;
  }
</style>

<template>
  <div class="c-media-dropzone-icons u-mt--16">
    <media-dropzone-icon
      :key="icon.id"
      :icon="icon.icon"
      :tooltip="$t(icon.tooltip)"
      :selected="selectedFilters.includes(icon.id)"
      v-for="icon in icons"
      @select="selectFilter(icon.id)"
    />
  </div>
</template>

<script>
  import MediaDropzoneIcon from '@/components/designer/MediaDropzoneIcon'

  export default {
    props: [
      'selectedFilters'
    ],
    data() {
      return {
        icons: [
          { id: 'word', icon: 'images/icons/datatypes/word.svg', tooltip: 'designer.media_tooltip_word' },
          { id: 'excel', icon: 'images/icons/datatypes/excel.svg', tooltip: 'designer.media_tooltip_excel' },
          { id: 'powerpoint', icon: 'images/icons/datatypes/powerpoint.svg', tooltip: 'designer.media_tooltip_powerpoint' },
          { id: 'image', icon: 'images/icons/datatypes/image.svg', tooltip: 'designer.media_tooltip_image' },
          { id: 'pdf', icon: 'images/icons/datatypes/file.svg', tooltip: 'designer.media_tooltip_file' },
          { id: 'link', icon: 'images/icons/datatypes/link.svg', tooltip: 'designer.media_tooltip_link' },
          { id: 'others', icon: 'images/icons/datatypes/file.svg', tooltip: 'designer.media_tooltip_others' }
        ]
      }
    },
    methods: {
      selectFilter(id) {
        this.$emit('select', id)
      }
    },
    components: {
      MediaDropzoneIcon
    },
  }
</script>

<style lang="scss" scoped>
  .c-media-dropzone-icons {
    display: flex;
    justify-content: space-between;
  }
</style>

<template>
  <SignupSidebar
    :plans="plans"
    :coupon="signupData.coupon"
    :link="'/auth/signup/personal'"
    :title="$t('signup.company_heading')">
    <div class="c-container-wrapper">
      <div class="c-container">
        <template v-if="!isMobile">
          <div class="c-headline-1 u-align-center">{{ $t('signup.company_heading') }}</div>
          <Progress
            :index="2"
            :plan="plan"
          />
        </template>
        <form
          class="c-form c-form-responsive--mt-48-8"
          @submit.prevent="submit">
          <div class="c-row">
            <div class="c-col c-col-12">
              <div class="c-form-element">
                <div class="c-form-label">{{ $t('signup.company') }} {{
                    $t('signup.company_advice')
                  }}{{ isFormFieldRequired ? '*' : '' }}
                </div>
                <input
                  class="c-form-input"
                  v-model="signupData.company"
                  :required="isFormFieldRequired"
                  :placeholder="$t('signup.company_placeholder')"
                />
              </div>
            </div>
          </div>
          <div class="c-row c-row-responsive--mt-24-8">
            <div class="c-col c-col-12">
              <div class="c-form-element">
                <div class="c-form-label">{{ $t('signup.street') }}*</div>
                <input
                  required
                  class="c-form-input"
                  v-model="signupData.street"
                  :placeholder="$t('signup.street_placeholder')"
                />
              </div>
            </div>
          </div>
          <div class="c-row c-row-responsive--mt-24-8">
            <div class="c-col c-col-6">
              <div class="c-form-element">
                <div class="c-form-label">{{ $t('signup.postal_code') }}*</div>
                <input
                  required
                  class="c-form-input"
                  v-model="signupData.postal_code"
                  :placeholder="$t('signup.postal_code_placeholder')"
                />
              </div>
            </div>
            <div class="c-col c-col-6">
              <div class="c-form-element">
                <div class="c-form-label">{{ $t('signup.city') }}*</div>
                <input
                  required
                  class="c-form-input"
                  v-model="signupData.city"
                  :placeholder="$t('signup.city_placeholder')"
                />
              </div>
            </div>
          </div>
          <div class="c-row c-row-responsive--mt-24-8">
            <div class="c-col c-col-12">
              <div class="c-form-element">
                <div class="c-form-label">
                  {{ $t('signup.country') }}*
                  <b-tooltip
                    :label="$t('signup.countries_soon')"
                    :delay="tooltipDelay"
                    position="is-top"
                    multilined>
                    <img src="images/icons/info.svg" class="info_icon">
                  </b-tooltip>
                </div>
                <select
                  required
                  :expanded="true"
                  v-model="signupData.country"
                  class="u-mt--8 control signup-select"
                  :placeholder="$t('signup.country_placeholder')">
                  <option value="de">{{ $t('app.country_germany') }}</option>
                  <option value="at">{{ $t('app.country_austria') }}</option>
                  <option value="ch">{{ $t('app.country_switzerland') }}</option>
                </select>
              </div>
            </div>
          </div>
          <div class="c-row c-row-responsive--mt-24-8">
            <div class="c-col c-col-12">
              <div class="c-form-element">
                <div class="c-form-label">{{ $t('signup.mediation') }}</div>
                <input
                  class="c-form-input"
                  v-model="signupData.mediation"
                  :placeholder="$t('signup.mediation_placeholder')"
                />
              </div>
            </div>
          </div>
          <div class="c-row c-row-responsive--mt-24-8">
            <div class="c-col c-col-12">
              <div class="c-form-element">
                <div class="c-form-label">{{ $t('signup.vat_id') }}</div>
                <input
                  pattern="DE(-?)([0-9]{9})"
                  class="c-form-input"
                  v-model="signupData.vat_id"
                  :placeholder="$t('signup.vat_id_placeholder')"
                />
              </div>
            </div>
          </div>
          <div class="c-row u-mt--32">
            <div class="c-col c-col-12 modal-controls modal-controls--spacing">
              <Button
                @click="back"
                theme="default"
                :disable-icon-view-only-on-mobile="true">
                {{ $t('signup.back') }}
              </Button>
              <Button
                type="submit"
                theme="primary"
                :disable-icon-view-only-on-mobile="true">
                {{ $t('signup.continue') }}
              </Button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </SignupSidebar>
</template>

<script>
  import SignupSidebar from '../signup/SignupSidebar'
  import Button from '../global/buttons/Button'
  import Progress from './Progress'
  import {mapGetters} from 'vuex'

  export default {
    props: [
      'plans',
      'signupData',
    ],
    computed: {
      ...mapGetters({
        isMobile: 'app/isMobileMode',
        tooltipDelay: 'ui/tooltipDelay'
      }),
      plan() {
        if (this.plans) {
          return this.plans.find(item => item.id === this.$route.query.plan)
        }
      },
      isFormFieldRequired() {
        return this.plan && this.plan.id !== 'free'
      }
    },
    methods: {
      submit() {
        let route = (this.plan && this.plan.id === 'free') || (this.signupData.coupon && this.signupData.coupon.free)
          ? '/auth/signup/summary'
          : '/auth/signup/payment'
        this.hrefTo(route)
      },
      hrefTo(link, resetQuery) {
        let query = this.$route.query
        if (resetQuery) {
          query = {}
        }
        this.$router.push({
          path: link,
          query
        })
      },
      back() {
        if (this.$route.query && this.$route.query.slug) {
          this.hrefTo('/' + this.$route.query.slug + '/settings/plans', true)
          return
        }
        this.hrefTo('/auth/signup/personal')
      }
    },
    components: {
      SignupSidebar,
      Progress,
      Button
    }
  }
</script>

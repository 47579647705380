import Workspace from '../../views/Workspace'

export default [
	{
		path: '/',
		component: Workspace,
		meta: {
			auth: true,
		}
	},
	{
		path: '/:slug',
		component: Workspace,
		meta: {
			auth: true,
		}
	},
	{
		path: '/:slug/workspaces',
		component: Workspace,
		meta: {
			auth: true,
		}
	},
	{
		path: '/:slug/workspaces/:teamId',
		component: Workspace,
		meta: {
			auth: true,
		}
	},
	{
		path: '/:slug/workspaces/:teamId/:rackId',
		component: Workspace,
		meta: {
			auth: true,
		}
	}
]
<template>
  <Context
    :entries="entries"
    :wide-entries="true"
    @select="select">
    <img src="/images/icons/options.svg"/>
  </Context>
</template>

<script>
  import Context from '@/components/global/Context'

  export default {
    props: [
      'isExpanded',
      'hasNewElements',
      'showNewElements',
    ],
    data() {
      return {
        entries: [
          {
            id: 1,
            icon: 'images/icons/plus.svg',
            title: 'designer.expand_all',
            function: 'toggleExpand'
          },
        ]
      }
    },
    created() {
      if (this.hasNewElements) {
        this.entries.push({
          id: 2,
          icon: 'images/icons/star--blue.svg',
          title: 'display.show_new_elements',
          function: 'toggleNewElements'
        })
      }
    },
    methods: {
      select(entry) {
        if (this[entry.function]) {
          this[entry.function]()
        }
      },
      toggleExpand() {
        let entry = this.entries.find(item => item.function === 'toggleExpand')
        if (entry) {
          entry.title = this.isExpanded ? 'designer.collapse_all' : 'designer.expand_all'
          entry.icon = this.isExpanded ? 'images/icons/minus.svg' : 'images/icons/plus.svg'
          this.$emit('toggleExpand')
        }
      },
      toggleNewElements() {
        let entry = this.entries.find(item => item.function === 'toggleNewElements')
        if (entry) {
          entry.title = !this.showNewElements ? 'display.hide_new_elements' : 'display.show_new_elements'
          entry.icon = !this.showNewElements ? 'images/icons/star--yellow.svg' : 'images/icons/star--blue.svg'
          this.$emit('toggleNewElements')
        }
      }
    },
    components: {
      Context
    }
  }
</script>
<template>
  <drag
    :transfer-data="folder.id"
    class="c__folder-wrapper">
    <div
      :class="{
       'c_folder-display-wrapper': true,
       'c_folder-display-wrapper--disabled': folder.status === 'locked'
      }" @click="linkTo">
      <FolderDisplay
        :folder="folder"
        :image="cover"
      />
    </div>
    <FolderContext
      class="folder"
      :index="index"
      :folder="folder"
      @edit="editDialogData = $event"
      @success="$emit('reload')"
      @merge="mergeDialogData = $event"
      @release="showReleaseModal = true"
      @delete="confirmDialogData = $event"
      @qrCode="qrCodeDialogData = $event"
      @statistics="statisticsDialogData = $event"
    />
    <div
      class="template-indicator"
      v-if="folder.template">
      <b-tooltip
        v-if="!isMobile"
        type="is-primary"
        position="is-right"
        :delay="tooltipDelay"
        :label="$t(templateType)">
        <c-icon
          class="text-df-primary"
          src="images/icons/rework/template.svg"
        />
      </b-tooltip>
    </div>
    <div class="c__folder-info">
      <div class="c__folder-text">
        <div class="c__folder-title">{{ folder.name }}</div>
        <div class="c__folder-meta">
          <div class="c__folder-icon">
            <img :src="icon"/>
          </div>
          <div class="c__folder-subtitle">
            {{ $t('workspaces.status_' + folder.status) }} {{ folder.status_date | date }}
          </div>
        </div>
      </div>
    </div>
    <!-- ReleaseModal -->
    <ReleaseModal
      :folder="folder"
      v-if="showReleaseModal"
      :active="showReleaseModal"
      @success="$emit('reload')"
      @close="showReleaseModal = false"
    />
    <FolderModal
      v-if="!!editDialogData"
      :activation="!!editDialogData"
      :folder="editDialogData.element"
      @reset="editDialogData = null"
      :suggestable-tags="suggestableTags"
      @success="$emit('reload')"
    />
    <ConfirmModal
      v-if="confirmDialogData"
      :active="!!confirmDialogData"
      :title="confirmDialogData.title"
      :message="confirmDialogData.message"
      :function="confirmDialogData.function"
      @close="confirmDialogData = null"
      @success="$emit('reload')"
    />
    <QRCodeModal
      :folder="folder"
      v-if="qrCodeDialogData"
      :active="!!qrCodeDialogData"
      @close="qrCodeDialogData = null"
    />
    <MergeModal
      :folder="folder"
      :folders="folders"
      v-if="mergeDialogData"
      :activation="!!mergeDialogData"
      @reset="mergeDialogData = null"
      @success="mergeDialogData = null"
    />
    <StatisticsModal
      :folder="folder"
      v-if="statisticsDialogData"
      :activation="!!statisticsDialogData"
      @reset="statisticsDialogData = null"
      @resetStatistics="confirmDialogData = $event"
    />
  </drag>
</template>

<script>
  import {mapGetters} from 'vuex'
  import PlanMixin from '@/mixins/plan'
  import FolderDisplay from './FolderDisplay'
  import FolderContext from './FolderContext'
  import FolderModal from './modals/FolderModal'
  import ReleaseModal from './modals/ReleaseModal'
  import ConfirmModal from '../global/modals/ConfirmModal'
  import QRCodeModal from './modals/QRCodeModal';
  import MergeModal from "@/components/workspace/modals/MergeModal"
  import StatisticsModal from "@/components/workspace/modals/StatisticsModal"

  export default {
    props: [
      'index',
      'folder',
      'folders',
      'suggestableTags',
    ],
    computed: {
      ...mapGetters({
        isMobile: 'app/isMobileMode',
        tooltipDelay: 'ui/tooltipDelay',
      }),
      icon() {
        if (this.folder) {
          switch (this.folder.status) {
            case 'in_progress':
              return '/images/icons/edit-pen.svg'
            case 'finished':
              return '/images/icons/check.svg'
            case 'released':
              return '/images/icons/release.svg'
            case 'locked':
              return '/images/icons/locked--red.svg'
            case 'archived':
              return '/images/icons/archived.svg'
          }
        }
      },
      cover() {
        if (this.folder && this.folder.cover) {
          return this.folder.cover.preview
        }
      },
      isEditableTemplateFolder() {
        let team = this.currentApp.teams.find(team => team.id == this.folder.team_id)
        return (this.isSuperadmin && this.folder.global_template)
          || !this.folder.template
          || (this.folder.template && team)
      },
      templateType() {
        if (this.folder.template) {
          return 'workspaces.folder_template_' + this.folder.template
        }
        return 'workspaces.folderTemplate'
      }
    },
    data() {
      return {
        editDialogData: null,
        mergeDialogData: null,
        qrCodeDialogData: null,
        confirmDialogData: null,
        showReleaseModal: false,
        statisticsDialogData: null,
      }
    },
    methods: {
      linkTo() {
        if (this.folder.status !== 'locked' && this.isEditableTemplateFolder) {
          this.$router.push('/' + this.$route.params.slug
            + '/workspaces'
            + '/' + this.$route.params.teamId
            + '/' + this.$route.params.rackId
            + '/' + this.folder.id
            + '/designer'
          )
        }
      },
    },
    mixins: [
      PlanMixin
    ],
    components: {
      MergeModal,
      QRCodeModal,
      ConfirmModal,
      FolderModal,
      FolderDisplay,
      FolderContext,
      ReleaseModal,
      StatisticsModal
    }
  }
</script>

<style lang="scss" scoped>
  .template-indicator {
    @apply
    h-8
    w-8
    flex
    top-2
    left-2
    rounded
    absolute
    bg-white
    items-center
    justify-center;
  }

  .c__folder-wrapper {
    flex: 1 0 200px;
    min-width: 200px;
    max-width: 220px;
    margin-left: 12px;
    margin-right: 12px;
    position: relative;
    margin-bottom: 24px;

    .folder {
      top: 8px;
      right: 8px;
      z-index: 2;
      position: absolute;
    }

    @media (max-width: $breakpoint-mobile) {
      margin-top: 0;
      margin-left: 0;
      margin-right: 0;
      flex: 1 0 100%;
      max-width: 100%;
    }

    .c_folder-display-wrapper {
      display: flex;
      height: 182px;
      cursor: pointer;
      border-radius: 8px;
      margin-bottom: 8px;
      align-items: center;
      justify-content: center;
      background: $light-blue-color;

      &.c_folder-display-wrapper--disabled {
        cursor: default;
      }
    }

    .c__folder-info {
      display: flex;
      justify-content: space-between;

      .c__folder-text {
        .c__folder-title {
          width: 100%;
          font-size: 14px;
          font-weight: 500;
          word-wrap: break-word;
          font-family: Roboto, sans-serif;
        }

        .c__folder-meta {
          display: flex;
          align-items: center;

          .c__folder-icon {
          }

          .c__folder-subtitle {
            font-size: 12px;
            margin-left: 8px;
            color: $dark-gray-color;
            font-family: Roboto, sans-serif;
          }
        }
      }

      .c__folder-button {
        width: 40px;
        height: 40px;
        display: flex;
        cursor: pointer;
        border-radius: 4px;
        align-items: center;
        justify-content: center;
        background: $lighter-gray-color;
      }
    }
  }
</style>

<template>
  <simple-button
      :size="size"
      :type="type"
      :theme="theme"
      :loading="loading"
      :disabled="disabled"
      :expanded="expanded"
      @click="$emit('click', $event)"
      :disable-icon-view-only-on-mobile="disableIconViewOnlyOnMobile">
    <img
        v-if="icon || (!isMobile && disableIconViewOnlyOnDesktop)"
        :src="icon"
        :class="{
            	'c__button-icon': true,
            	'c__button-icon--mobile': isMobile,
            }"
    />
    <div v-if="!isMobile || disableIconViewOnlyOnMobile"
         :class="{
        	    'c__button-title': true,
        	    'c__button-title--icon': isMobile && icon
            }">
      <slot/>
    </div>
  </simple-button>
</template>

<script>
import {mapGetters} from 'vuex'
import SimpleButton from './SimpleButton'

export default {
  props: [
    'size',
    'type',
    'icon',
    'theme',
    'loading',
    'disabled',
    'expanded',
    'disable-icon-view-only-on-mobile',
    'disable-icon-view-only-on-desktop',
  ],
  computed: {
    ...mapGetters({
      isMobile: 'app/isMobileMode'
    })
  },
  components: {
    SimpleButton
  }
}
</script>

<style lang="scss" scoped>
.c__button-icon {
  margin-right: 8px;

  &.c__button-icon--mobile {
    margin-right: 0;
  }
}

.c__button-title {
  width: 100%;
  font-size: 13px;
  font-weight: 500;
  text-align: center;
  font-family: Roboto, sans-serif;

  &.c__button-title--icon {
    margin-left: 8px;
  }
}
</style>

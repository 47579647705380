<template>
    <div class="c__switch">
        <div :key="value.id"
            v-for="value in values"
            @click.prevent="select(value.id)"
            :class="{
            	'c__switch-option': true,
            	'c__switch-option--selected': paymentInterval === value.id
            }">
            {{ $t(value.label) }}
        </div>
    </div>
</template>

<script>
    export default {
    	computed: {
    		paymentInterval() {
    			return this.$route.query.payment_interval
                    ? this.$route.query.payment_interval
                    : 'monthly'
            }
        },
    	data() {
    		return {
                values: [
                    {
                    	id: 'monthly',
                        label: 'signup.monthly_payment'
                    },
                    {
                    	id: 'yearly',
                        label: 'signup.yearly_payment'
                    },
                ]
            }
        },
        methods: {
            select(value) {
                this.$router
                    .push({ query: {
                    	payment_interval: value,
                        plan: this.$route.query.plan,
                        lang: this.$route.query.lang,
                    }})
                    .catch(err => {})
            }
        }
    }
</script>

<style lang="scss" scoped>
    .c__switch {
        display: flex;
        font-size: 14px;
        user-select: none;
        background: $white;
        border-radius: 20px;
        font-family: Roboto;
        color: $darker-blue-color;

        @media (max-width: $breakpoint-mobile) {
            width: 100%;
        }

        .c__switch-option {
            width: 160px;
            cursor: pointer;
            text-align: center;
            padding: 10.5px 16px;

            @media (max-width: $breakpoint-mobile) {
                width: 50%;
                padding: 9px 8px;
            }

            &.c__switch-option--selected {
                color: $white;
                font-weight: 500;
                border-radius: 20px;
                background: $primary-color;
            }
        }
    }
</style>

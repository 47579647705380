import base from './base'

export default {
	// AuthAPI
	login(email, password) {
		return base.post('/auth/login', {
			email,
			password
		})
	},
	forget(email, location) {
		return base.post('/auth/forget', {
			email,
			location
		})
	},
	logout() {
		return base.post('/auth/logout')
	},
	verify(code) {
		return base.post('/auth/verify/' + code)
	},

	// UsersAPI
	findProfile(id) {
		return base.get('/users/' + id)
	},
	signup(signupObject) {
		return base.post('/users', signupObject)
	},
	simpleSignup(slug, code, signupObject) {
		return base.post('/' + slug + '/users/' + code, signupObject)
	},
	uploadAvatar(id, filename, data) {
		return base.post('/users/' + id + '/avatar', {
			filename: filename,
			avatar: data
		})
	},
	switchLanguage(id, language) {
		return base.post('/users/' + id + '/language', {
			language
		})
	},
	updateProfile(id, user) {
		return base.post('/users/' + id, user)
	},
	changePassword(id, passwords) {
		return base.post('/users/' + id + '/password', passwords)
	},
	findUsers(slug) {
		return base.get('/' + slug + '/users')
	},
	changeRole(slug, userId, roleId) {
		return base.post('/' + slug + '/users/' + userId + '/role', {
			role_id: roleId
		})
	},
	toggleBlock(slug, userId) {
		return base.post('/' + slug + '/users/' + userId + '/block')
	},
	removeUser(slug, id) {
		return base.post('/' + slug + '/users/' + id + '/remove')
	},

	// DashboardAPI
	findDashboard(slug) {
		return base.get('/' + slug + '/dashboard')
	},

	// InvoicesAPI
	findInvoices(slug) {
		return base.get('/' + slug + '/invoices')
	},

	// NotificationAPI
	findNotifications(slug) {
		return base.get('/' + slug + '/notifications')
	},

	// PaymentsAPI
	createSetupIntent() {
		return base.get('/payments/setup-intent')
	},

	// OrganisationAPI
	findOrganisation(slug) {
		return base.get('/organisations/' + slug)
	},
	updateOrganisation(slug, app) {
		return base.post('/organisations/' + slug, app)
	},
	uploadOrganisationAvatar(slug, filename, fileData) {
		return base.post('/organisations/' + slug + '/icon', {
			filename,
			fileData
		})
	},

	// RolesAPI
	findRoles(slug) {
		return base.get('/' + slug + '/roles')
	},
	createRole(slug, name) {
		return base.post('/' + slug + '/roles', {
			name
		})
	},
	findRole(slug, id) {
		return base.get('/' + slug + '/roles/' + id)
	},
	updateRolePermissions(slug, roleId, permissions) {
		return base.post('/' + slug + '/roles/' + roleId, {
			permissions
		})
	},
	removeRole(slug, roleId) {
		return base.post('/' + slug + '/roles/' + roleId + '/remove')
	},

	// TeamsAPI
	findTeams(slug) {
		return base.get('/' + slug + '/teams')
	},
	createTeam(slug, name) {
		return base.post('/' + slug + '/teams', {
			name
		})
	},
	updateTeam(slug, id, name) {
		return base.post('/' + slug + '/teams/' + id, {
			name
		})
	},
	removeTeam(slug, id) {
		return base.post('/' + slug + '/teams/' + id + '/remove')
	},
	leaveTeam(slug, id) {
		return base.post('/' + slug + '/teams/' + id + '/leave')
	},
	findTeam(slug, id) {
		return base.get('/' + slug + '/teams/' + id)
	},
	kick(slug, teamId, userId) {
		return base.post('/' + slug + '/teams/' + teamId + '/kick', {
			user_id: userId
		})
	},

	// AppsAPI
	invite(slug, email, team_id) {
		return base.post('/' + slug + '/apps/invite', {
			email,
			team_id
		})
	},
	acceptInvitation(slug) {
		return base.post('/' + slug + '/apps/accept')
	},
	declineInvitation(slug) {
		return base.post('/' + slug + '/apps/decline')
	},
	uninvite(slug, email) {
		return base.post('/' + slug + '/apps/uninvite', {
			email
		})
	},
	sendInviteReminder(slug, email) {
		return base.post('/' + slug + '/apps/reminder', {
			email
		})
	},
	findInvite(slug, code) {
		return base.get('/' + slug + '/invites/' + code)
	},
	findSignupData(slug) {
		return base.get('/' + slug + '/apps')
	},
	upgradeApp(slug, signup) {
		return base.post('/' + slug + '/apps/upgrade', signup)
	},

	// PermissionsAPI
	findPermissions() {
		return base.get('/permissions')
	},

	// PaymentsAPI
	findPaymentMethods(slug) {
		return base.get('/' + slug + '/payments')
	},
	setDefaultPaymentMethod(slug, id) {
		return base.post('/' + slug + '/payments/' + id + '/default')
	},
	findBillingDetails(slug) {
		return base.get('/' + slug + '/payments/billingDetails')
	},
	createPaymentMethod(slug, paymentMethodId) {
		return base.post('/' + slug + '/payments', {
			pm: paymentMethodId
		})
	},
	cancelSubscription(slug) {
		return base.post('/' + slug + '/subscriptions/cancel')
	},
    findSubscriptionInfo(slug) {
	    return base.get('/' + slug + '/subscriptions')
    },

	// MediaCenterAPI
	findMediaContainer(slug, teamSlug, id, count, stepSize) {
		count = count || 100
		stepSize = stepSize || 100
		return base.get('/' + slug + '/' + teamSlug + '/containers/' + id + '?limit=' + count + '&stepSize=' + stepSize)
	},
	findMediaContainers(slug, teamSlug, showArchivedContainers = true) {
		return base.get('/' + slug + '/' + teamSlug + '/containers?archived=' + showArchivedContainers)
	},
	createMediaContainer(slug, teamSlug, container) {
		return base.post('/' + slug + '/' + teamSlug + '/containers', container)
	},
	updateMediaContainer(slug, teamSlug, container) {
		return base.post('/' + slug + '/' + teamSlug + '/containers/' + container.id, container)
	},
	removeMediaContainer(slug, teamSlug, containerId) {
		return base.post('/' + slug + '/' + teamSlug + '/containers/' + containerId + '/remove')
	},
	archiveMediaContainer(slug, teamSlug, id) {
		return base.post('/' + slug + '/' + teamSlug + '/containers/' + id + '/archive')
	},

	// Media Object API
	createMediaObject(slug, teamSlug, media, progressHandler) {
		return base.post('/' + slug + '/' + teamSlug + '/object', media, progressHandler)
	},
	updateMediaObject(slug, teamSlug, media, id, progressHandler) {
		return base.post('/' + slug + '/' + teamSlug + '/object/' + id, media, progressHandler)
	},
	removeMediaObject(slug, teamSlug, id) {
		return base.post('/' + slug + '/' + teamSlug + '/object/' + id + '/remove')
	},
	downloadMediaObject(slug, teamSlug, id) {
		return base.download('/' + slug + '/' + teamSlug + '/object/' + id)
	},
	downloadMediaObjectWithCode(code, objectId, password) {
		return base.download('/object/' + code + '/' + password + '/' + objectId + '/download')
	},
	moveMediaObject(slug, teamSlug, id, containerId) {
		return base.post('/' + slug + '/' + teamSlug + '/object/' + id + '/move', {
			container_id: containerId
		})
	},
	moveAllMediaObjects(slug, teamSlug, object_ids, container_id) {
		return base.post('/' + slug + '/' + teamSlug + '/objects/move', {
			object_ids,
			container_id
		})
	},
	removeAllMediaObjects(slug, teamSlug, object_ids) {
		return base.post('/' + slug + '/' + teamSlug + '/objects/remove', {
			object_ids,
		})
	},
	downloadAllMediaObjects(slug, teamSlug, object_ids) {
		return base.postDownload('/' + slug + '/' + teamSlug + '/objects/download', {
			object_ids
		})
	},

	// TemplatesAPI
	findFolderTemplates(slug, teamSlug) {
		return base.get('/' + slug + '/' + teamSlug + '/templates')
	},

	//  RacksAPI
	findRacks(slug, teamSlug) {
		return base.get('/' + slug + '/' + teamSlug + '/racks')
	},
	createRack(slug, teamSlug, rack) {
		return base.post('/' + slug + '/' + teamSlug + '/racks', rack)
	},
	updateRack(slug, teamSlug, rack) {
		return base.post('/' + slug + '/' + teamSlug + '/racks/' + rack.id, rack)
	},
	removeRack(slug, teamSlug, id) {
		return base.post('/' + slug + '/' + teamSlug + '/racks/' + id + '/remove')
	},
	archive(slug, teamSlug, id) {
		return base.post('/' + slug + '/' + teamSlug + '/racks/' + id + '/archive')
	},

	// FoldersAPI
	findFolder(slug, teamSlug, folderId) {
		return base.get('/' + slug + '/' + teamSlug + '/folders/' + folderId)
	},
	createFolder(slug, teamSlug, folder) {
		return base.post('/' + slug + '/' + teamSlug + '/folders', folder)
	},
	updateFolder(slug, teamSlug, folder) {
		return base.post('/' + slug + '/' + teamSlug + '/folders/' + folder.id, folder)
	},
	changeStatus(slug, teamSlug, id, body) {
		return base.post('/' + slug + '/' + teamSlug + '/folders/' + id + '/status', body)
	},
	removeFolder(slug, teamSlug, id) {
		return base.post('/' + slug + '/' + teamSlug + '/folders/' + id + '/remove')
	},
	saveFolder(slug, teamSlug, folder) {
		if (folder.id) {
			return base.post('/' + slug + '/' + teamSlug + '/folders/' + folder.id + '/contents', folder)
		}
		return base.post('/' + slug + '/' + teamSlug + '/folders/new/contents', folder)
	},
	copyFolder(slug, teamSlug, folderId) {
		return base.post('/' + slug + '/' + teamSlug + '/folders/' + folderId + '/copy')
	},
	copyFolderFromTemplate(slug, teamSlug, folderId, rackId, mode) {
		return base.post('/' + slug + '/' + teamSlug + '/folders/' + folderId + '/copyTemplate', {rackId, mode})
	},
	mergeFolder(slug, teamSlug, baseFolderId, mergedFolderId) {
		return base.post('/' + slug + '/' + teamSlug + '/folders/' + baseFolderId + '/merge', {
			folderId: mergedFolderId
		})
	},
	moveFolder(slug, teamSlug, folderId, rackId) {
		return base.post('/' + slug + '/' + teamSlug + '/folders/' + folderId + '/move', {
			rack_id: rackId
		})
	},
	resetStatistics(slug, teamSlug, folderId) {
		return base.post('/' + slug + '/' + teamSlug + '/folders/' + folderId + '/reset-statistics')
	},

	// FolderDisplayAPI
	findFolderByCode(code) {
		return base.get('/folder/' + code)
	},
	checkPassword(code, password) {
		return base.get('/folder/' + code + '/' + password)
	},

	// CommentsAPI
	createComment(slug, teamSlug, content, folder_id) {
		return base.post('/' + slug + '/' + teamSlug + '/comments', {
			content: content,
			folder_id: folder_id
		})
	},
	markAsDone(slug, teamSlug, comment_id) {
		return base.post('/' + slug + '/' + teamSlug + '/comments/' + comment_id + '/mark')
	},

	// SearchesAPI
	search(slug, teamSlug, keyword) {
		return base.post('/' + slug + '/' + teamSlug + '/search', {
			keyword
		})
	},

	// CouponsAPI
	checkCoupon(code) {
		return base.get('/coupons/' + code)
	},

	// Superadmin
	findCustomers() {
		return base.get('/admin/customers');
	},
	findCustomer(id) {
		return base.get('/admin/customers/' + id)
	},
	removeCustomer(id) {
		return base.post('/admin/customers/' + id + '/remove')
	},
	transferCustomer(id, targetCustomer) {
		return base.post('/admin/customers/' + id + '/transfer', {
			customer: targetCustomer
		})
	},
	block(id) {
		return base.post('/admin/customers/' + id + '/block')
	},
	release(id) {
		return base.post('/admin/customers/' + id + '/release')
	},
	createCoupon(coupon) {
		return base.post('/admin/coupons', coupon)
	},
	findCoupons() {
		return base.get('/admin/coupons')
	},
	removeCoupon(id) {
		return base.post('/admin/coupons/' + id + '/remove')
	},
	saveMessageOfTheDay(message) {
		return base.post('/admin/motd', {
			message
		})
	},
	getMessageOfTheDay() {
		return base.get('/admin/motd')
	},

	// Support
	sendSupportRequest(messageObj) {
		return base.post('/support', {
			name: messageObj.name,
			email: messageObj.email,
			message: messageObj.message,
			questionValue: messageObj.questionValue,
			questionValueA: messageObj.questionValueA,
			questionValueB: messageObj.questionValueB,
		})
	},

	// TranslationsAPI
	findTranslations() {
		return base.get('/translations')
	},
	updateTranslations(translations) {
		return base.post('/admin/translations', {translations})
	},
	exportTranslations() {
		return base.download('/admin/translations/export').then(res => {
			if (res) {
				let blob = new Blob([res])
				let element = document.createElement('a')
				if (element) {
					element.href = window.URL.createObjectURL(blob)
					element.download = "translations.xlsx"
					element.click()
					element.remove()
				}
			}
		})
	},
	importTranslations(file) {
		let formData = new FormData()
		formData.append('file', file)
		return base.postFormData('/admin/translations/import', formData)
	},
	toggleLanguage(language) {
		return base.post('/admin/languages/' + language)
	},

	// HelpContents
	storeHelpContent(helpContent) {
		return base.post('/admin/help-contents/' + helpContent.id, {
			...helpContent
		})
	},
	findHelpContents() {
		return base.get('/admin/help-contents')
	},
	findHelpContent(id) {
		return base.get('/admin/help-contents/' + id)
	},
	removeHelpContent(id) {
		return base.post('/admin/help-contents/' + id + '/remove')
	},
	findHelpContentsForUsers(view) {
		return base.get('/help-contents')
	}
}

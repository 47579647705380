<template>
    <div
        class="column"
       :class="classes">
        <slot />
    </div>
</template>

<script>
    export default {
	    props: {
		    size: {
			    type: String,
			    required: false
		    },
		    offset: {
			    type: String,
			    required: false
		    }
	    },
	    computed: {
		    classes() {
			    let stylingObject = {}
			    stylingObject[this.size] = true
			    stylingObject[this.offset] = true

			    return stylingObject
		    }
	    }
    }
</script>
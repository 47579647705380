<template>
  <section class="layout__wrapper">
    <!-- Navigation -->
    <Navigation/>
    <!-- Content Container wide -->
    <section class="content__wrapper">
      <slot/>
    </section>
  </section>
</template>

<script>
  import Navigation from './Navigation'

  export default {
    components: {
      Navigation
    }
  }
</script>

<style lang="scss" scoped>
  .layout__wrapper {
    width: 100%;
    height: calc(100% - 45px);

    .content__wrapper {
      width: 100%;
      height: 100%;
      margin-top: 45px;
    }
  }
</style>

<style lang="scss">
  @import "../../../src/assets/scss/vendor/buefy";
</style>
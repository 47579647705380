<template>
  <menu-taskbar>
    <titled-container
      :title="title">
      <loading-component :load="loading">
        <form
          class="form"
          @submit.prevent="save">
          <card>
            <form-row>
              <labeled-input
                type="text"
                :required="true"
                v-model="content.title">
                Titel
              </labeled-input>
            </form-row>
            <form-row>
              <labeled-input
                type="select"
                :required="true"
                :values="areas"
                v-model="content.area">
                Ort der Anzeige
              </labeled-input>
            </form-row>
            <form-row>
              <labeled-input
                type="text"
                :required="true"
                v-model="content.order">
                Sortierung
              </labeled-input>
            </form-row>
            <form-row>
              <labeled-input
                type="file"
                :value="content.link"
                @change="content.link = $event"
                v-if="!showUploadInput">
                Video-Link
              </labeled-input>
              <labeled-input
                type="text"
                :disabled="true"
                :value="content.link"
                @click.prevent="toggleInput = !toggleInput"
                v-if="showUploadInput">
                Video-Link
              </labeled-input>
            </form-row>
            <form-row>
              <labeled-input
                type="textarea"
                :required="true"
                v-model="content.description">
                Beschreibung
              </labeled-input>
            </form-row>
            <form-row>
              <c-button
                type="submit">
                Speichern
              </c-button>
            </form-row>
          </card>
          <div class="w-full" />
        </form>
      </loading-component>
    </titled-container>
  </menu-taskbar>
</template>

<script>
  import APIFactory from '@/api/factory'
  import Helper from '@/helpers'

  export default {
    computed: {
      title() {
        return this.$route.params.id === 'new'
          ? 'Neue Hilfe (Inhalt) anlegen'
          : 'Hilfe (Inhalt) bearbeiten'
      },
      showUploadInput() {
        return this.content.link
          && !this.toggleInput
      }
    },
    data() {
      return {
        toggleInput: false,
        areas: [
          { id: 'generic', label: 'Allgemein' },
          { id: 'workspace', label: 'Regal-/Ordneransicht' },
          { id: 'mediacenter', label: 'Mediencenter' },
          { id: 'designer', label: 'Designer' },
          { id: 'display', label: 'Ordneranzeige' },
          { id: 'settings', label: 'Einstellungen' },
        ],
        content: {
          area: 'workspace',
          id: this.$route.params.id,
        },
        loading: false,
      }
    },
    created() {
      this.load()
    },
    methods: {
      load() {
        if (this.$route.params.id !== 'new') {
          this.loading = true
          APIFactory.findHelpContent(this.$route.params.id).then(res => {
            if (res && res.success) {
              this.content = res.data
            }
          }).finally(() => this.loading = false)
        }
      },
      save() {
        this.loading = true
        APIFactory.storeHelpContent(this.content).then((res) => {
          if (res && res.success) {
            Helper.callToast('is-success', 'Das Hilfevideo wurde erfolgreich gespeichert')
            this.$router.push('/admin/help-contents')
          }
        }).finally(() => this.loading = false)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .form {
    @apply
      flex
      gap-6;
  }
</style>


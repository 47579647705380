var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"c__chapter-wrapper",on:{"click":function($event){$event.preventDefault();return _vm.select.apply(null, arguments)}}},[_c('drop',{class:{
    'c__chapter': true,
    'c__chapter--selected': _vm.selected || _vm.draggedOver,
    'c__chapter--cover': _vm.chapter.type === 'cover'
    },on:{"drop":_vm.moveChapter,"dragover":function($event){_vm.draggedOver = true},"dragleave":function($event){_vm.draggedOver = false}}},[_c('div',{staticClass:"c__chapter-container"},[(_vm.chapter.type === 'chapter')?[_c('drag',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.$t('designer.drag_chapter')),expression:"$t('designer.drag_chapter')"}],staticClass:"c__chapter-icon c__chapter-icon--grab",attrs:{"transfer-data":{source: _vm.chapter.id, type: 'chapter'}}},[_c('img',{attrs:{"src":"images/icons/drag-handle.svg"}})]),_c('div',{staticClass:"c__chapter-icon"},[_c('b-tooltip',{attrs:{"delay":_vm.tooltipDelay,"label":_vm.statusTooltip,"position":"is-right"}},[_c('img',{attrs:{"src":_vm.statusIcon}})])],1),_c('div',{class:{
         'c__chapter-title': true,
         'c__chapter-title--untouched': null === _vm.chapter.name
        }},[(_vm.folder && _vm.folder.order_type === 'number')?[_vm._v(_vm._s(_vm.chapter.index)+".")]:_vm._e(),(_vm.chapter.name)?[_vm._v(_vm._s(_vm.chapter.name))]:[_vm._v(_vm._s(_vm.$t('designer.new_chapter')))]],2)]:[_c('div',{staticClass:"c__chapter-title"},[_vm._v(_vm._s(_vm.$t('designer.cover')))])]],2),(_vm.chapter.type !== 'cover')?_c('div',{staticClass:"c__chapter-container u-mt--4"},[_c('div',{staticClass:"c__chapter-button"},[_c('ChapterContext',{attrs:{"chapter":_vm.chapter},on:{"copy":function($event){return _vm.$emit('copyChapter', $event)},"delete":function($event){return _vm.$emit('deleteChapter', $event)}}})],1),_c('div',{staticClass:"c__chapter-button",on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.$emit('expand', _vm.chapter.id)}}},[_c('img',{attrs:{"src":'/images/icons/' + (_vm.chapter.expanded ? 'minus' : 'plus') + '.svg'}})])]):_vm._e()]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.chapter.expanded),expression:"chapter.expanded"}],staticClass:"c__chapter-pages"},[_vm._t("default"),_c('div',{staticClass:"c__chapter-pages-control"},[_c('Button',{attrs:{"icon":"/images/icons/add--blue.svg","disable-icon-view-only-on-mobile":true},on:{"click":_vm.appendPage}},[_vm._v(" "+_vm._s(_vm.$t('designer.new_page'))+" ")])],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <button
    @click="click($event)"
    :type="type ? type : 'button'"
    :class="{
    	    'c__button--default-error': theme === 'default-error',
    	    'c__button--lighten': theme === 'light',
    	    'c__button--primary': theme === 'primary',
    	    'c__button--success': theme === 'success',
    	    'c__button--mobile': isMobile && !disableIconViewOnlyOnMobile,
    	    'c__button--disabled': loading || disabled,
    	    'c__button--tiny': size === 'tiny',
    	    'c__button--expanded': expanded,
    	    'c__button': true,
        }">
    <template v-if="loading">
      <div class="c__button-spinner--wrapper">
        <div class="c__button-spinner">
          <div class="c__button-spinner--inner"/>
        </div>
      </div>
    </template>
    <template v-else>
      <slot/>
    </template>
  </button>
</template>

<script>
  import {mapGetters} from 'vuex'

  export default {
    props: [
      'size',
      'type',
      'theme',
      'loading',
      'disabled',
      'expanded',
      'disable-icon-view-only-on-mobile',
    ],
    computed: {
      ...mapGetters({
        isMobile: 'app/isMobileMode'
      })
    },
    methods: {
      click($event) {
        if (!this.loading && !this.disabled) {
          this.$emit('click', $event)
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .c__button {
    font-family: Roboto, sans-serif;
    background: $lighter-gray-color;
    justify-content: center;
    color: $primary-color;
    display: inline-flex;
    align-items: center;
    position: relative;
    padding: 10px 14px;
    border-radius: 4px;
    user-select: none;
    line-height: 1.25;
    font-weight: 500;
    cursor: pointer;
    outline: none;
    height: 40px;
    border: none;

    &:hover {
      background: darken($lighter-gray-color, 7.5%);
    }

    &.c__button--disabled {
      cursor: not-allowed;
      opacity: 0.65;
    }

    &.c__button--expanded {
      width: 100%;
    }

    &.c__button--mobile {
      width: 48px;
      padding: 0;

      img {
        width: 16px;
        height: 16px;
      }
    }

    /*
     *  Default Error Theme
     */
    &.c__button--default-error {
      color: $red-color;
    }

    /*
     *  Primary Theme
     */
    &.c__button--primary {
      color: $white;
      background: $primary-color;

      &:not(.c__button--disabled) {
        &:hover {
          background: darken($primary-color, 7.5%);
        }
      }

      .c__button-spinner {
        border: 2.5px solid darken($primary-color, 15%);

        .c__button-spinner--inner {
          border: 2.5px solid $white;
          border-left-color: transparent;
        }
      }
    }

    &.c__button--success {
      color: $white;
      background: $success-color;

      &:not(.c__button--disabled) {
        &:hover {
          background: darken($success-color, 7.5%);
        }
      }

      .c__button-spinner {
        border: 2.5px solid darken($success-color, 15%);

        .c__button-spinner--inner {
          border: 2.5px solid $white;
          border-left-color: transparent;
        }
      }
    }

    /*
     *  Lighten
     */
    &.c__button--lighten {
      color: $primary-color;
      background: $white;

      &:not(.c__button--disabled) {
        &:hover {
          background: darken($white, 2%);
        }
      }

      .c__button-spinner {
        border: 2.5px solid darken($white, 20%);

        .c__button-spinner--inner {
          border: 2.5px solid $primary-color;
          border-left-color: transparent;
        }
      }
    }

    /*
     *  Size: Tiny
     */
    &.c__button--tiny {
      height: 32px;
      padding: 0 16px;
    }
  }

  /*
   *  Loading animation
   */
  .c__button-spinner--wrapper {
    width: 70px;
    display: flex;
    justify-content: center;
  }

  .c__button-spinner {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: 2.5px solid darken($lighter-gray-color, 10%);;

    .c__button-spinner--inner {
      width: 16px;
      height: 16px;
      margin-left: -2px;
      margin-top: -2.5px;
      border-radius: 50%;
      animation: rotate infinite 1.5s linear;
      border: 2.5px solid darken($lighter-gray-color, 50%);
      border-left-color: transparent;
    }
  }

  @keyframes rotate {
    from {
      transform: rotate(0deg)
    }
    to {
      transform: rotate(360deg)
    }
  }
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{class:{
  	    'c__button--default-error': _vm.theme === 'default-error',
  	    'c__button--lighten': _vm.theme === 'light',
  	    'c__button--primary': _vm.theme === 'primary',
  	    'c__button--success': _vm.theme === 'success',
  	    'c__button--mobile': _vm.isMobile && !_vm.disableIconViewOnlyOnMobile,
  	    'c__button--disabled': _vm.loading || _vm.disabled,
  	    'c__button--tiny': _vm.size === 'tiny',
  	    'c__button--expanded': _vm.expanded,
  	    'c__button': true,
      },attrs:{"type":_vm.type ? _vm.type : 'button'},on:{"click":function($event){return _vm.click($event)}}},[(_vm.loading)?[_vm._m(0)]:[_vm._t("default")]],2)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"c__button-spinner--wrapper"},[_c('div',{staticClass:"c__button-spinner"},[_c('div',{staticClass:"c__button-spinner--inner"})])])}]

export { render, staticRenderFns }
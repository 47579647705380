export default {
	namespaced: true,
	state: {
		firstLogin: false,
		superadmin: false,
		avatar: null,
		userId: null,
		token: null,
		apps: [],
	},
	mutations: {
		setFirstLogin(state, firstLogin) {
			state.firstLogin = firstLogin
		},
		setToken(state, token) {
			state.token = token
		},
		setApps(state, apps) {
			state.apps = apps
		},
		setUserId(state, userId) {
			state.userId = userId
		},
		setAvatar(state, avatar) {
			state.avatar = avatar
		},
		setSuperadmin(state, superadmin) {
			state.superadmin = !!superadmin
		},
		updatePermissions(state, payload) {
			if (state.apps) {
				state.apps = state.apps.map(app => {
					if (app.id === parseInt(payload.app_id) && app.role_id === parseInt(payload.roleId)) {
						app.permissions = payload.permissions
					}
					return app
				})
			}
		},
		updatePlan(state, payload) {
			if (state.apps) {
				state.apps = state.apps.map(app => {
					if (app.slug === payload.slug) {
						app.plan = payload.plan
					}
					return app
				})
			}
		}
	},
	getters: {
		apps: (state) => state.apps,
		token: (state) => state.token,
		userId: (state) => state.userId,
		avatar: (state) => state.avatar,
		defaultApp: (state) => state.apps[0],
		isSuperadmin: (state) => state.superadmin,
		isFirstLogin: (state) => state.firstLogin,
		currentApp: (state) => (appSlug) => state.apps ? state.apps.find(app => app.slug === appSlug) : null,
	}
}

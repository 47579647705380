import moment from 'moment'

export default [
	{
		id: 'last_used',
		label: 'workspaces.sort_last_used',
		sort(data) {
			return data.sort((i1, i2) => {
				return moment(i2.updated_at) - moment(i1.updated_at)
			})
		},
		usable: [
			'media',
			'container',
			'folder',
			'rack'
		]
	},
	{
		id: 'latest',
		label: 'workspaces.sort_latest',
		sort: (data) => {
			return data.sort((i1, i2) => {
				return moment(i2.created_at) - moment(i1.created_at)
			})
		},
		usable: [
			'media',
			'container',
			'folder',
			'rack'
		]
	},
	{
		id: 'alphabetically',
		label: 'workspaces.sort_alphabetically',
		sort: (data) => {
			return data.sort((i1, i2) => {
				return i1.name.toLowerCase() !== i2.name.toLowerCase()
					? i1.name.toLowerCase() < i2.name.toLowerCase()
					? -1 : 1 : 0
			})
		},
		usable: [
			'media',
			'container',
			'folder',
			'rack'
		]
	},
	{
		id: 'anti_alphabetically',
		label: 'workspaces.sort_anti_alphabetically',
		sort: (data) => {
			return data.sort((i1, i2) => {
				return i1.name.toLowerCase() !== i2.name.toLowerCase()
					? i1.name.toLowerCase() < i2.name.toLowerCase()
					? 1 : -1 : 0
			})
		},
		usable: [
			'media',
			'container',
			'folder',
			'rack'
		]
	},
	{
		id: 'most_comments',
		label: 'workspaces.sort_most_comments',
		sort: (data) => {
			return data.sort((i1, i2) => {
				return i1.comment_count - i2.comment_count
			})
		},
		usable: [
			'folder'
		]
	},
	{
		id: 'most_used',
		label: 'workspaces.sort_most_used',
		sort: (data) => {
			return data.sort((i1, i2) => {
				return i1.references.length === i2.references.length ?
					0 : i1.references.length < i2.references.length ? 1 : -1
			})
		},
		usable: [
			'media',
		]
	},
	{
		id: 'less_used',
		label: 'workspaces.sort_less_used',
		sort: (data) => {
			return data.sort((i1, i2) => {
				return i1.references.length === i2.references.length ?
					0 : i1.references.length > i2.references.length ? 1 : -1
			})
		},
		usable: [
			'media',
		]
	},
	{
		id: 'shared_containers',
		label: 'workspaces.sort_shared_containers',
		sort: (data) => {
			return data.sort((i1, i2) => {
				return  (i1.shared === i2.shared) ? 0 : i1.shared ? -1 : 1;
			})
		},
		usable: [
			'container',
		]
	}
]

<template>
  <div class="forget-form">
    <div class="c-language-switcher">
      <LanguageSwitcher/>
    </div>
    <div class="c-container-wrapper">
      <div class="c-container c-container-form">
        <form
            class="c-form"
            @submit.prevent="forget">
          <div class="c-form-logo">
            <img src="/images/do-logo.svg">
          </div>
          <div class="c-form-header u-mt--16">{{ $t('auth.forget') }}</div>
          <div class="c-form-element u-mt--24">{{ $t('auth.forget_text') }}</div>
          <div class="c-form-element u-mt--24">
            <div class="c-form-label">{{ $t('signup.email_address') }}</div>
            <input
                required
                type="email"
                v-model="email"
                class="c-form-input"
                :placeholder="$t('signup.email_address_placeholder')"
            />
          </div>
          <div class="modal-controls modal-controls--center u-mt--24">
            <Button
                type="submit"
                theme="primary"
                :loading="loading">
              {{ $t('auth.forget') }}
            </Button>
          </div>

          <a href="https://digital-folder.com" class="c-poweredBy-container u-mt--56" target="_blank">
            <img src="images/do-logo.png">
            <div class="u-ml--8">{{ $t('display.trial_period') }}n</div>
          </a>
        </form>
      </div>
    </div>

    <div class="c-support">
      <Chat/>
    </div>
  </div>
</template>

<script>
import Helpers from '../../helpers'
import APIFactory from '../../api/factory'
import Chat from '../../components/signup/support/Chat'
import Button from '../../components/global/buttons/Button'
import LanguageSwitcher from '../../components/settings/LanguageSwitcher'

export default {
  data() {
    return {
      email: null,
      loading: false,
    }
  },
  methods: {
    forget() {
      this.loading = true
      let location = window.location.origin || window.location.host
      APIFactory.forget(this.email, location).then(res => {
        if (res && res.success) {
          Helpers.callToast('is-success', this.$t('auth.forgot_success_message'))
          this.$router.push('/auth/login')
        }
      }).finally(() => this.loading = false)
    },
  },
  components: {
    Chat,
    Button,
    LanguageSwitcher
  },
}
</script>

<style lang="scss">
.forget-form {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}
</style>

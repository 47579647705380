<template>
  <div class="c-dropdown-wrapper">
    <div @click.prevent="toggle">
      <slot />
    </div>
    <div
      class="c-dropdown"
      v-if="active">
      <div
        :key="index"
        class="c-dropdown-entry"
        @click.prevent="select(entry.id)"
        v-for="(entry, index) in entries">
        <span
          class="dropdown-icon"
          v-if="entry.selected">
          <img src="images/icons/check.svg">
        </span>
        <span :class="{'ml-3': !entry.selected && isAnyValueSelected}">
          {{ $te(entry.label) ? $t(entry.label) : entry.label }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: [
      'entries'
    ],
    computed: {
      isAnyValueSelected() {
        return this.entries.find(item => item.selected)
      }
    },
    data() {
      return {
        active: false,
      }
    },
    mounted() {
      document.addEventListener('click', this.onClick, false)
    },
    beforeDestroy() {
      document.removeEventListener('click', this.onClick, false)
    },
    methods: {
      toggle() {
        this.active = !this.active
      },
      select(entryId) {
        this.$emit('select', entryId)
        this.active = false
      },
      onClick($event) {
        if (!this.$el.contains($event.target)) {
          this.active = false
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .c-dropdown-wrapper {
    @apply
      relative;

    .c-dropdown {
      @apply
        py-3
        rounded
        shadow
        top-12
        left-0
        absolute
        bg-white
        text-sm
        text-df-dark-blue;

      .c-dropdown-entry {
        @apply
          px-4
          py-3
          my-1
          flex
          items-center
          rounded
          cursor-pointer
          hover:bg-df-lightest-gray;

        .dropdown-icon {
          @apply
            h-4
            w-4
            mr-3;
        }
      }
    }
  }
</style>
<template>
  <base-modal
    :active="active"
    @close="$emit('close')">
    <div class="c__confirm-modal">
      <div class="c__confirm-icon">
        <img src="images/icons/times.svg">
      </div>
      <div class="c__confirm-title">{{ $t('designer.unsaved_content') }}</div>
    </div>
    <div slot="actions"
         class="modal-controls modal-controls--spacing">
      <Button
        @click="discard"
        :icon="closeIcon"
        theme="default-error">
        {{ $t('designer.discard_changes') }}
      </Button>
      <Button
        @click="save"
        theme="primary"
        :icon="confirmIcon">
        {{ $t('designer.save_changes') }}
      </Button>
    </div>
  </base-modal>
</template>

<script>
  import BaseModal from '../../global/modals/BaseModal'
  import Button from '../../global/buttons/Button'
  import {mapGetters} from 'vuex'

  export default {
    props: [
      'active',
      'folder'
    ],
    computed: {
      ...mapGetters({
        isMobile: 'app/isMobileMode'
      }),
      closeIcon() {
        return this.isMobile ? 'images/icons/close.svg' : ''
      },
      confirmIcon() {
        return this.isMobile ? 'images/icons/check--white.svg' : ''
      }
    },
    methods: {
      save() {
        this.$emit('success')
      },
      discard() {
        this.$emit('close')
      }
    },
    components: {
      BaseModal,
      Button
    }
  }
</script>

<style lang="scss" scoped>
  .c__confirm-modal {
    font-weight: 500;
    text-align: center;

    @media (max-width: $breakpoint-mobile) {
      height: 100%;
    }

    .c__confirm-icon {
      img {
        max-width: 100%;
        max-height: 150px;
      }
    }

    .c__confirm-title {
      font-size: 20px;
      margin-top: 24px;
    }

    .c__confirm-message {
      margin-top: 16px;
      margin-bottom: 24px;
    }
  }
</style>

<template>
  <base-modal
    :active="active"
    @close="close">
    <div class="c__confirm-modal">
      <div class="c__confirm-icon">
        <img src="images/icons/times.svg">
      </div>
      <div class="c__confirm-title">{{ title }}</div>
      <div class="c__confirm-message">{{ message }}</div>
    </div>
    <div class="c__confirm-controls" slot="actions">
      <Button
        @click="close"
        :loading="loading"
        :disable-icon-view-only-on-mobile="true">
        {{ $t('general.cancel') }}
      </Button>
      <Button
        theme="primary"
        :loading="loading"
        @click="performConfirm"
        :disable-icon-view-only-on-mobile="true">
        {{ $t('general.confirm') }}
      </Button>
    </div>
  </base-modal>
</template>

<script>
  import BaseModal from '../../global/modals/BaseModal'
  import Button from '../../global/buttons/Button'

  export default {
    props: [
      'title',
      'active',
      'message',
      'function',
    ],
    data() {
      return {
        loading: false,
      }
    },
    methods: {
      performConfirm() {
        if (this.function) {
          this.loading = true
          this.function(this).then(res => {
            this.$emit('success')
          }).finally(() => this.loading = false)
        }
      },
      close() {
        this.$emit('close')
      }
    },
    components: {
      BaseModal,
      Button
    }
  }
</script>

<style lang="scss" scoped>
  .c__confirm-modal {
    font-weight: 500;
    text-align: center;

    @media (max-width: $breakpoint-mobile) {
      height: 100%;
    }

    .c__confirm-icon {
      @apply
      flex
      w-full
      justify-center;

      img {
        max-width: 100%;
        max-height: 150px;
      }
    }

    .c__confirm-title {
      font-size: 20px;
      margin-top: 24px;
    }

    .c__confirm-message {
      margin-top: 16px;
      margin-bottom: 24px;
    }
  }

  .c__confirm-controls {
    display: flex;
    justify-content: space-between;
  }
</style>

import VueI18n from 'vue-i18n'
import Vue from 'vue'
import de from './de'
import en from './en'
import cz from './cz'
import ru from './ru'

Vue.use(VueI18n)

export default new VueI18n({
	locale: 'de',
	fallbackLocale: 'en',
	messages: {
		de,
		en,
		cz,
		ru,
	}
})

<template>
  <Context
    :small="false"
    @select="select"
    :entries="entries"
    :transparent="true">
    <div class="media-context-button">
      <img src="/images/icons/options.svg"/>
    </div>
  </Context>
</template>

<script>
  import Helpers from '../../helpers'
  import Context from '../global/Context'
  import APIFactory from '../../api/factory'

  export default {
    props: [
      'element',
    ],
    data() {
      return {
        entries: [],
        localEntries: [
          {
            icon: '/images/icons/edit.svg',
            title: 'workspaces.edit',
            function: 'edit',
            sort: 1,
          },
          {
            icon: '/images/icons/delete.svg',
            title: 'workspaces.delete',
            function: 'confirmPreflightDelete',
            sort: 3,
          },
        ],
      }
    },
    methods: {
      select($event) {
        if ($event && $event.function && this[$event.function]) {
          this[$event.function]()
        }
      },
      /**
       *  It handles the confirmation preflight for dialog
       *  Required $emit params:
       *  - slug, function, title, message
       */
      confirmPreflightDelete() {
        this.$emit('delete', {
          function: () => {
            return this.delete(this.element.id)
          },
          slug: this.element.slug,
          title: this.$t('mediacenter.remove_container_confirm_title'),
          message: this.$t('mediacenter.remove_container_confirm_message'),
        })
      },
      /**
       * Removes the current selected container
       * It is important to return Promise for confirm
       * @returns {Promise<unknown>}
       */
      delete(id) {
        return APIFactory.removeMediaContainer(
          this.$route.params.slug,
          this.$route.params.teamId,
          id)
        .then(res => {
          if (res && res.success) {
            Helpers.callToast('is-success', this.$t('mediacenter.remove_container_success_message'))
          }
        })
      },
      /**
       *  Handles Preflight for update dialog
       */
      edit() {
        this.$emit('edit', {
          container: this.element,
          type: 'container',
        })
      },
      /**
       * Archives or restores the element
       */
      archive() {
        return APIFactory.archiveMediaContainer(
          this.$route.params.slug,
          this.$route.params.teamId,
          this.element.id)
        .then(res => {
          if (res && res.success) {
            if (this.element.archived) {
              Helpers.callToast('is-success', this.$t('workspaces.restore_container_success_message'))
            } else {
              Helpers.callToast('is-success', this.$t('workspaces.archive_container_success_message'))
            }
            this.$emit('success')
          }
        })
      }
    },
    watch: {
      element: {
        deep: true,
        immediate: true,
        handler() {
          this.entries = JSON.parse(JSON.stringify(this.localEntries))
          if (this.element.archived) {
            this.entries.push({
              icon: '/images/icons/rework/archive.svg',
              title: 'workspaces.context_menu_status_restore',
              function: 'archive',
              sort: 2,
            })
          } else {
            this.entries.push({
              icon: '/images/icons/rework/archive.svg',
              title: 'workspaces.context_menu_status_archive',
              function: 'archive',
              sort: 2,
            })
          }
          this.entries = this.entries.sort((e1, e2) => e1.sort > e2.sort ? 1 : -1)
        }
      }
    },
    components: {
      Context,
    },
  }
</script>

<style lang="scss" scoped>
  .media-context-button {
    width: 40px;
    height: 40px;
    display: flex;
    border-radius: 4px;
    align-items: center;
    justify-content: center;
    background: $lighter-gray-color;

    &:hover {
      background: rgba($lighter-gray-color, 7.5%);
    }
  }
</style>

import Vue from 'vue'
import i18n from './lang'
import Buefy from 'buefy'
import store from './store'
import App from './App.vue'
import router from './router'
import Bootstrap from './bootstrap'
import VueScrollTo from 'vue-scrollto'
import VueDragDrop from 'vue-drag-drop'
import VueAnalytics from 'vue-analytics'


Vue.use(VueScrollTo)
Vue.use(VueDragDrop)
Vue.use(Buefy, {
  defaultIconComponent: 'vue-fontawesome',
  defaultIconPack: 'fas',
})

if (process.env.VUE_APP_GA_UA_ID) {
  Vue.use(VueAnalytics, {
    id: process.env.VUE_APP_GA_UA_ID,
    router
  })
}

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')

<template>
    <span
        class="c__icon"
        :class="{
    	    'c__icon-24x24': size === '24x24',
    	    'c__icon-12x12': size === '12x12',
        }"
    />
</template>

<script>
let cache = new Map()

export default {
  props: [
    'src',
    'size',
  ],
  async mounted() {
    this.update()
  },
  methods: {
    async update() {
      if (!cache.has(this.src)) {
        try {
          cache.set(this.src, fetch(this.src).then(r => r.text()));
        } catch (e) {
          cache.delete(this.src);
        }
      }
      if (cache.has(this.src)) {
        this.$el.innerHTML = await cache.get(this.src);
      }
    }
  },
  watch: {
    src() {
      this.update()
    }
  }
}
</script>

<style lang="scss">
.c__icon {
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;

  &.c__icon-24x24 {
    width: 24px;
    height: 24px;

    svg {
      width: 24px;
      height: 24px;
    }
  }

  &.c__icon-12x12 {
    width: 12px !important;
    height: 12px !important;

    svg {
      width: 12px;
      height: 12px;
    }
  }

  svg {
    width: 16px;
    height: 16px;
  }
}
</style>

<template>
  <SidebarLayout
    :title="$t('settings.teams')"
    :updated-teams="teams">
    <b-columns v-if="teams && teams.length > 0">
      <b-column>
        <b-card>
          <Table
            :data="teams"
            :headers="headers">
            <div class="modal-controls" slot-scope="data">
              <TeamModal
                :slim="true"
                :team="data.row"
                @success="load"
              />
              <ConfirmModal
                icon="times"
                type="is-danger"
                :title="$t('settings.delete_team')"
                :tooltip="$t('settings.delete_team')"
                :message="$t('settings.delete_team_description')"
                @success="removeTeam(data.row.id)"
              />
              <ConfirmModal
                type="is-danger"
                icon="door-open"
                v-if="data.row.inside"
                :tooltip="$t('settings.leave_team')"
                :title="$t('settings.leave_team')"
                :message="$t('settings.leave_team_description')"
                @success="leaveTeam(data.row.id)"
              />
            </div>
          </Table>
        </b-card>
        <div class="modal-controls">
          <TeamModal
            :slim="false"
            @success="load(true)"
          />
        </div>
      </b-column>
    </b-columns>
  </SidebarLayout>
</template>

<script>
  import SidebarLayout from '../../components/settings/SidebarLayout'
  import ConfirmModal from '../../components/settings/modals/ConfirmModal'
  import InviteModal from '../../components/settings/modals/InviteModal'
  import TeamModal from '../../components/settings/modals/TeamModal'
  import Table from '../../components/Table'
  import APIFactory from '../../api/factory'
  import Headers from '../../data/headers'
  import Helpers from '../../helpers'
  import {mapGetters} from 'vuex'

  export default {
    computed: {
      ...mapGetters({
        lastURL: 'app/lastURL'
      })
    },
    data() {
      return {
        teams: [],
        headers: Headers.teams,
      }
    },
    created() {
      this.load()
    },
    methods: {
      load() {
        this.loading = true
        APIFactory.findTeams(this.$route.params.slug).then(res => {
          if (res && res.success) {
            this.teams = res.data
          }
        }).finally(() => this.loading = false)
      },
      removeTeam(id) {
        this.loading = true
        APIFactory.removeTeam(this.$route.params.slug, id).then(res => {
          if (res && res.success) {
            Helpers.callToast('is-success', this.$t('settings.team_removed_successful'))

            // Update assigned teams on local storage
            this.$store.commit('auth/setApps', res.data)

            // Last changed url - switch to default team if team does not exist
            let removedTeam = this.teams.find(item => item.id === id)
            let urlArray = this.lastURL.split('/')
            if (removedTeam && this.lastURL && urlArray[3] === removedTeam.slug) {
              urlArray.splice(3)
              this.$store.commit('app/setLastURL', urlArray.join('/'))
            }

            // Reload teams
            this.load()
          }
        }).finally(() => this.loading = false)
      },
      leaveTeam(id) {
        this.loading = true
        APIFactory.leaveTeam(this.$route.params.slug, id).then(res => {
          if (res && res.success) {
            Helpers.callToast('is-success', this.$t('settings.team_leave_successful'))
            this.load()
          }
        }).finally(() => this.loading = false)
      }
    },
    components: {
      SidebarLayout,
      ConfirmModal,
      InviteModal,
      TeamModal,
      Table,
    }
  }
</script>

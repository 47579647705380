<template>
  <base-modal
    :active="active"
    @close="cancel"
    :title="$t('workspaces.create_folder')">

    <!-- Button -->
    <div
      slot="button"
      class="button-wrapper"
      @click.prevent="active = true">
      <slot/>
    </div>

    <!-- Tabs -->
    <Tabs :tabs="currentTabs">
      <!-- General Tab -->
      <template slot="tab-0">
        <form class="c-form u-mt--8">
          <div class="c-form-element">
            <label class="basic">{{ $t('workspaces.folder_name') }}*</label>
            <text-input
              ref="input"
              v-model="folder.name"
              :placeholder="$t('workspaces.folder_name_placeholder')"
            />
          </div>

          <div class="c-form-element u-mt--16">
            <tag-input
              v-model="folder.tags"
              :suggestable-tags="suggestableTags">
              {{ $t('workspaces.tags') }}
            </tag-input>
          </div>

          <div class="c-form-element u-mt--16">
            <color-selector v-model="folder.color">
              {{ $t('workspaces.cover_color') }}
            </color-selector>
          </div>
        </form>
      </template>
      <!-- Advanced Tab -->
      <template slot="tab-1">
        <form-row>
          <labeled-input
            type="radio"
            :values="orderTypes"
            v-model="folder.order_type">
            {{ $t('workspaces.display_order_numbers') }}
          </labeled-input>
        </form-row>

        <form-row>
          <labeled-input
            type="radio"
            :values="displayTypes"
            v-model="folder.display_type">
            {{ $t('workspaces.display_type') }}
          </labeled-input>
        </form-row>

        <form-row v-if="isSuperadmin || hasPremiumPlan">
          <labeled-input
            :wrap="true"
            type="radio"
            :values="permissionBasedTemplateValues"
            v-model="folder.template">
            {{ $t('workspaces.is_folder_template') }}
          </labeled-input>
        </form-row>
      </template>

      <!-- Partner Tab -->
      <template slot="tab-2">
        <form
          class="partner-controls"
          @submit.prevent="inviteToFolder(currentPartnerMail)">
          <labeled-input
            type="email"
            class="w-full"
            v-model="currentPartnerMail">
            {{ $t('workspaces.folder_partners') }} (E-Mail)
          </labeled-input>
          <c-button
            type="submit"
            class="ml-2 flex-shrink-0"
            icon="images/icons/rework/check.svg"
            :disabled="!currentPartnerMail || currentPartnerMail.length === 0">
          </c-button>
        </form>
        <div
          class="partner-list"
          v-if="folder.partners && folder.partners.length > 0">
          <div class="partner-headline">Partner</div>
          <div
            class="partner"
            :key="partner.id"
            v-for="partner in folder.partners">
            <div class="partner-name">{{ partner.company }} ({{ partner.email }})</div>
            <div class="partner-control">
              <c-button
                color="red"
                class="ml-2 flex-shrink-0"
                icon="images/icons/rework/close.svg"
                @click.prevent="removeFromFolder(partner.email)">
              </c-button>
            </div>
          </div>
        </div>
      </template>
    </Tabs>

    <!-- Actions -->
    <div
      slot="actions"
      class="modal-controls modal-controls--spacing">
      <Button
        theme="default"
        :loading="loading"
        @click="cancel"
        :disable-icon-view-only-on-mobile="true">
        {{ $t('general.cancel') }}
      </Button>
      <Button
        @click="save"
        theme="primary"
        :loading="loading"
        :disable-icon-view-only-on-mobile="true">
        {{ $t('workspaces.save_folder') }}
      </Button>
    </div>
  </base-modal>
</template>

<script>
import APIFactory from '../../../api/factory'
import Button from '../../global/buttons/Button'
import TagInput from '../../global/inputs/TagInput'
import BaseModal from '../../global/modals/BaseModal'
import TextInput from '../../global/inputs/TextInput'
import ColorSelector from '../../global/inputs/ColorSelector'
import MediaCenterInput from '../../global/inputs/MediaCenterInput'
import RadioGroupInput from '@/components/global/inputs/RadioGroupInput'
import Tabs from '../../global/tabs/Tabs'
import {mapGetters} from 'vuex'
import PlanMixin from '@/mixins/plan'

export default {
  props: [
    'folder',
    'activation',
    'suggestableTags',
  ],
  computed: {
    ...mapGetters({
      isSuperadmin: 'auth/isSuperadmin'
    }),
    permissionBasedTemplateValues() {
      let values = [
        { id: null, label: this.$t('workspaces.no_folder_template') }
      ]
      if (this.isSuperadmin) {
        values.push({id: 'global', label: this.$t('workspaces.folder_template_global')})
      }
      values = values.concat(this.templateValues)
      return values
    },
    currentTabs() {
      const tabs = [
        this.$t('workspaces.folder_general_tab'),
        this.$t('workspaces.folder_advanced_tab'),
      ]

      if (this.folder.template === 'partner') {
        tabs.push(this.$t('workspaces.folder_partners'))
      }
      return tabs
    }
  },
  data() {
    return {
      active: false,
      loading: false,
      currentPartnerMail: null,
      displayTypes: [
        {id: 'folder', label: this.$t('workspaces.display_type_folder')},
        {id: 'brochure', label: this.$t('workspaces.display_type_brochure')},
      ],
      orderTypes: [
        {id: 'number', label: this.$t('general.yes')},
        {id: 'none', label: this.$t('general.no')},
      ],
      templateValues: [
        { id: 'app', label: this.$t('workspaces.folder_template_app') },
        { id: 'team', label: this.$t('workspaces.folder_template_team') },
        { id: 'partner', label: this.$t('workspaces.folder_template_partner') },
      ]
    }
  },
  methods: {
    createAPIRequest() {
      return this.folder && this.folder.id
        ? APIFactory.updateFolder(this.$route.params.slug, this.$route.params.teamId, this.folder)
        : APIFactory.createFolder(this.$route.params.slug, this.$route.params.teamId, this.folder)
    },
    save() {
      this.loading = true
      this.createAPIRequest().then(res => {
        if (res && res.success) {
          this.$emit('success')
          this.active = false
        }
      }).finally(() => this.loading = false)
    },
    cancel() {
      this.active = false
      this.$emit('success')
    },
    inviteToFolder(email) {
      if (this.folder.partners) {
        let partner = this.folder.partners.find(partner => partner.email === email)
        if (!partner) {
          this.folder.partners.push({
            email,
            id: -1,
            company: this.$t('workspaces.partner_not_saved'),
          })
        }
      }
      this.currentPartnerMail = null
    },
    removeFromFolder(email) {
      if (this.folder.partners) {
        this.folder.partners = this.folder.partners.filter(p => p.email !== email)
      }
    }
  },
  watch: {
    activation: {
      immediate: true,
      handler(value) {
        if (this.active !== value) {
          this.active = value
        }
      }
    },
    active(value) {
      if (this.activation !== value) {
        this.$emit('reset', value)
      }

      if (value) {
        this.$nextTick(() => {
          this.$refs.input.$el.focus()
        })
      }
    }
  },
  mixins: [
    PlanMixin
  ],
  components: {
    RadioGroupInput,
    MediaCenterInput,
    ColorSelector,
    TextInput,
    BaseModal,
    TagInput,
    Button,
    Tabs
  }
}
</script>

<style lang="scss" scoped>
  .partner-controls {
    @apply
      mt-4
      flex
      w-full
      items-end;
  }

  .partner-list {
    @apply
      w-full;

    .partner-headline {
      @apply
        mt-8
        text-xs
        font-bold
        text-df-dark-blue;
    }

    .partner {
      @apply
        mt-2
        pl-2
        flex
        w-full
        rounded
        text-sm
        items-center
        justify-between
        hover:bg-df-lightest-primary;
    }
  }
</style>

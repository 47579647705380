<template>
  <b-tooltip
    type="is-primary"
    v-if="!isMobile"
    position="is-bottom"
    class="c__content-designer--background"
    :label="$t('designer.change_folder_background')"
    :delay="tooltipDelay">
    <div
      class="c-context-button"
      @click.prevent="backgroundContext = !backgroundContext">
      <img src="images/icons/designer.svg"/>
      <div
        class="c-context-menu"
        v-show="backgroundContext">
        <div
          class="c-context-menu--entry"
          @click.stop="backgroundColorDialog = !backgroundColorDialog">
          <div class="c-context-entry-icon" v-if="folder.background_color">
            <img src="images/icons/check.svg"/>
          </div>
          <div class="c-context-entry-text">{{ $t('designer.select_background_color') }}</div>
        </div>
        <div
          class="c-context-menu--entry"
          @click.stop="mediaCenterImageDialog = !mediaCenterImageDialog">
          <div class="c-context-entry-icon" v-if="folder.background_image">
            <img src="images/icons/check.svg"/>
          </div>
          <div class="c-context-entry-text">{{ $t('designer.select_background_image') }}</div>
        </div>
        <div
          @click.stop="reset"
          class="c-context-menu--entry"
          v-if="folder.background_color || folder.background_image">
          {{ $t('designer.reset_background') }}
        </div>

        <!-- Modals -->
        <BackgroundColorModal
          v-if="backgroundColorDialog"
          :title="$t('designer.select_background_color')"
          :value="folder.background_color"
          :active="backgroundColorDialog"
          @close="backgroundColorDialog = false"
          @success="handleBackgroundColor"
        />
        <MediaCenterImageModal
          v-if="mediaCenterImageDialog"
          :containers="containers"
          :active="mediaCenterImageDialog"
          :value="folder.background_image"
          :title="$t('designer.select_background_image')"
          @close="mediaCenterImageDialog = false"
          @success="handleBackgroundImage"
        />
      </div>
    </div>
  </b-tooltip>
</template>

<script>
  import {mapGetters} from 'vuex'
  import BackgroundColorModal from '@/components/designer/modals/BackgroundColorModal'
  import MediaCenterImageModal from '@/components/designer/modals/MediaCenterImageModal'

  export default {
    props: [
      'folder',
      'containers'
    ],
    computed: {
      ...mapGetters({
        isMobile: 'app/isMobileMode',
        tooltipDelay: 'ui/tooltipDelay'
      })
    },
    data() {
      return {
        backgroundContext: false,
        backgroundColorDialog: false,
        mediaCenterImageDialog: false,
      }
    },
    mounted() {
      document.addEventListener('click', this.handleOutsideMouseClick)
    },
    beforeDestroy() {
      document.removeEventListener('click', this.handleOutsideMouseClick)
    },
    methods: {
      handleOutsideMouseClick($event) {
        if (!$event.target.closest('.c-context-button')) {
          this.backgroundContext = false
        }
      },
      handleBackgroundColor($event) {
        this.backgroundContext = false
        this.backgroundColorDialog = false
        this.$emit('setBackground', {
          color: $event.value,
          image: null
        })
      },
      handleBackgroundImage($event) {
        this.backgroundContext = false
        this.mediaCenterImageDialog = false
        this.$emit('setBackground', {
          color: null,
          image: $event.value,
        })
      },
      reset() {
        this.backgroundContext = false
        this.$emit('setBackground', {
          color: null,
          image: null,
        })
      }
    },
    components: {
      BackgroundColorModal,
      MediaCenterImageModal,
    },
  }
</script>

<style lang="scss" scoped>
  .c__content-designer--background {
    top: 16px;
    width: 32px;
    left: 24px;
    height: 32px;
    background: $white;
    position: absolute;
    border-radius: 4px;
    box-shadow: $box-shadow-context;

    .c-context-button {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &:hover {
      cursor: pointer;
      background: darken($white, 5%);
    }
  }

  .c-context-menu {
    top: 24px;
    left: 24px;
    padding: 8px 0;
    border-radius: 4px;
    background: $white;
    position: absolute;
    box-shadow: $box-shadow;

    .c-context-menu--entry {
      font-size: 12px;
      font-weight: 500;
      min-width: 250px;
      padding: 8px 12px;
      display: flex;
      align-content: center;
      color: $darkest-blue-color-2;

      .c-context-entry-icon {
        margin-right: 8px;
      }

      &:hover {
        border-radius: 4px;
        background: darken($white, 5%);
      }
    }
  }
</style>

<template>
  <div :class="{
    	'c__service-element': true,
    	'c__service-element-card': changePlanMode,
    	'c__service-element--premium': element.id === 'premium',
    }">
    <div :class="{
            'element-header': (!changePlanMode && idx === 2) || (changePlanMode && selected),
            'element-header-spacer': (!changePlanMode && idx !== 2 && !isMobile) || (changePlanMode && !selected)
        }">
      <template v-if="(!changePlanMode && idx === 2)">{{ $t('signup.favourite_option') }}</template>
      <template v-if="(changePlanMode && selected)">{{ $t('signup.current_plan') }}</template>
    </div>
    <div class="element-container">
      <div class="upper-bracket">
        <div class="element-title">{{ $t(element.title) }}</div>
        <div class="element-subtitle">{{ $t(element.subtitle) }}</div>
        <div class="element-checklist">
          <div :key="index"
               class="element-checklist-wrapper"
               v-for="(checkItem, index) in element.checklist">
            <div class="element-checklist-element">
              <c-icon
                :class="iconClass(index, element.id)"
                :src="iconPath(index, element.id)">
              </c-icon>
              {{ translateChecklistItem(checkItem, element.id) }}
<!--              <img src="/images/icons/rework/check.svg"> {{ translateChecklistItem(checkItem, element.id) }}-->
            </div>
            <template v-if="hasCouponDiscount">
              <template v-if="checkItem === 'signup.basic_argument0' || checkItem === 'signup.premium_argument0'">
                <small class="color-green">
                  <template v-if="!coupon.free">{{ $t('signup.additional_month_1', {month: coupon.discount}) }}</template>
                  <template v-if="coupon.free">{{ $t('signup.additional_month_2', {month: coupon.discount}) }}</template>
                </small>
              </template>
              <template v-if="checkItem === 'signup.free_argument3'"><small class="color-white">.</small></template>
            </template>
          </div>
        </div>
      </div>
      <div class="lower-bracket">
        <div class="element-price">
          <template v-if="!coupon || !coupon.free">{{ element['price_' + paymentInterval] | price }}</template>
          <template v-else>{{ 0 | price }}</template>
        </div>
        <div class="element-price-each">
          <template v-if="isFree">{{ $t('signup.constantly_free') }}</template>
          <template v-else>{{ $t('signup.each_user') }}</template>
        </div>
        <div class="element-button">
          <Button
              :disable-icon-view-only-on-mobile="true"
              :disabled="coupon && isFree"
              :theme="buttonTheme"
              @click="handleClick"
              v-if="!changePlanMode">
            {{ $t('signup.signup_now') }}
          </Button>
          <Button
              :disable-icon-view-only-on-mobile="true"
              :theme="buttonTheme"
              @click="handleClick"
              v-if="changePlanMode">
            {{ $t('signup.continue') }}
          </Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import Button from '../../global/buttons/Button'

export default {
  props: [
    'idx',
    'element',
    'selected',
    'coupon',
    'changePlanMode',
    'payment_interval',
    'current_user',
  ],
  computed: {
    ...mapGetters({
      isMobile: 'app/isMobileMode'
    }),
    isFree() {
      return this.element.id === 'free'
    },
    buttonTheme() {
      if (this.element && this.element.id === 'premium') {
        return 'success'
      }
      return this.isFree ? 'default' : 'primary'
    },
    paymentInterval() {
      return this.$route.query.payment_interval
          ? this.$route.query.payment_interval
          : 'monthly'
    },
    hasCouponDiscount() {
      return this.coupon && this.coupon.discount > 0
    }
  },
  methods: {
    iconClass(index, plan) {
      switch (plan) {
        case 'free':
          if (index === 5 || index === 4 || index === 3) {
            return 'icon text-df-red'
          }
          break
        case 'basic':
          if (index === 6 || index === 5) {
            return 'icon text-df-red'
          }
          break
        case 'premium':
          break
      }
      return 'icon text-df-green'
    },
    iconPath(index, plan) {
      switch (plan) {
        case 'free':
          if (index === 5 || index === 4 || index === 3) {
            return '/images/icons/rework/close.svg'
          }
          break
        case 'basic':
          if (index === 6 || index === 5) {
            return '/images/icons/rework/close.svg'
          }
          break
        case 'premium':
          break
      }
      return '/images/icons/rework/check.svg'
    },
    translateChecklistItem(item, plan) {
      let space = 1
      switch (plan) {
        case 'basic':
          space = 10
          break
        case 'premium':
          space = 20
          break
      }

      let users = (this.$route.query.users || 1)
      if (plan !== 'basic' && plan !== 'premium') {
        users = 1
      }

      return this.$t(item, {
        space: (space * users)
      })
    },
    handleClick() {
      let query = Object.assign({}, this.$route.query, {
        payment_interval: this.payment_interval || this.paymentInterval,
        plan: this.element.id,
      })

      if (this.$route.params.slug) {
        query = Object.assign({}, query, {
          slug: this.$route.params.slug,
          users: this.current_user,
        })
      }

      this.$router.push({
        path: '/auth/signup/personal',
        query
      })
    }
  },
  components: {
    Button
  }
}
</script>

<style lang="scss" scoped>
.c__service-element {
  flex-shrink: 0;
  flex-basis: 100%;
  margin-left: 8px;
  margin-right: 8px;
  max-width: 320px;

  &.c__service-element--premium {
    font-weight: 500;
  }

  @media (max-width: $breakpoint-mobile) {
    margin-top: 16px;
    margin-right: 0;
    flex-shrink: 1;
    margin-left: 0;
    max-width: 100%;
  }

  &.c__service-element-card {
    flex-shrink: 1;
    border-radius: 10px;
    box-shadow: $box-shadow;
  }

  .element-header {
    width: 100%;
    color: $white;
    padding: 6px 0;
    font-size: 14px;
    text-align: center;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background: $success-color;
  }

  .element-header-spacer {
    width: 100%;
    height: 33px;
  }
}

.element-container {
  background: white;
  text-align: center;
  padding: 24px 32px;
  border-radius: 8px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-height: calc(100% - 33px);

  .element-title {
    font-size: 24px;
    font-weight: 500;
    color: $darker-blue-color;
  }

  .element-subtitle {
    font-size: 14px;
    margin-top: 8px;
    color: $dark-gray-color;
  }

  .element-checklist {
    margin-top: 16px;

    .element-checklist-wrapper {
      display: flex;
      flex-direction: column;
    }

    .element-checklist-element {
      display: flex;
      margin-top: 8px;
      font-size: 14px;

      .icon {
        margin-left: 12px;
        margin-right: 16px;
      }
    }
  }

  .element-price {
    font-size: 40px;
    margin-top: 24px;
    font-weight: 500;
    color: $darker-blue-color;
  }

  .element-price-each {
    font-size: 14px;
    margin-top: 8px;
    color: $dark-gray-color;
  }

  .element-button {
    margin-top: 24px;
    margin-bottom: 8px;
  }
}


</style>

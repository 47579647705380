<template>
    <b-table
        :data="data"
        :striped="true"
        :hoverable="true">
        <template slot-scope="row">
            <b-table-column
                :key="index"
                :field="header.field"
                :label="$t(header.label)"
                v-if="header.field !== 'actions'"
                v-for="(header, index) in headers">
                <div class="p-1">{{ row.row[header.field] }}</div>
            </b-table-column>
            <b-table-column
                width="200"
                v-if="actions"
                :label="$t('general.actions')"
                field="actions">
                <slot :row="row.row" />
            </b-table-column>
        </template>

        <template slot="empty">
            <div class="has-text-centered">{{ $t(empty) }}</div>
        </template>
    </b-table>
</template>

<script>
    export default {
    	props: {
    		headers: {
    			type: Array,
                required: true,
                default() {
    				return []
                }
            },
            data: {
    			type: Array,
                required: true,
	            default() {
		            return []
	            }
            },
            empty: String,
            actions: {
    			type: Boolean,
                required: false,
                default: true,
            }
        },
    }
</script>
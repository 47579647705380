<template>
  <b-column :size="size">
    <section class="tile__container">
      <template v-if="type === 'default'">
        <div class="tile__title">{{ value }}</div>
        <div class="tile__subtitle">{{ text }}</div>
      </template>
      <template v-if="type === 'slot'">
        <slot/>
      </template>
    </section>
  </b-column>
</template>

<script>
  export default {
    props: {
      text: {
        type: String,
        required: false,
      },
      value: {
        type: [String, Number],
        required: false,
      },
      type: {
        type: String,
        required: false,
        default: 'default'
      }
    },
    computed: {
      size() {
        if (this.type === 'default') {
          return 'is-3'
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .tile__container {
    display: flex;
    padding: 30px;
    height: 100%;
    border-radius: 4px;
    align-items: center;
    word-break: break-word;
    flex-direction: column;
    justify-content: center;
    box-shadow: $box-shadow-outer;

    .tile__title {
      font-size: 48px;
      text-align: center;
    }

    .tile__subtitle {
      font-size: 20px;
      text-align: center;
    }
  }
</style>

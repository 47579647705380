<template>
  <div class="c__input-tag">
    <div class="c__input-tag-title">{{ title }}</div>
    <div class="c__input-tag-button"
         @click.prevent="$emit('remove')">
      <img src="/images/icons/close.svg">
    </div>
  </div>
</template>

<script>
export default {
  props: [
    'title'
  ]
}
</script>

<style lang="scss" scoped>
.c__input-tag {
  margin: 4px;
  padding: 7px 16px;
  border-radius: 4px;
  align-items: center;
  display: inline-flex;
  justify-content: space-between;
  background-color: $light-blue-color;

  .c__input-tag-title {
    font-size: 12px;
    color: $darker-blue-color;
    font-family: Roboto, sans-serif;
  }
}

.c__input-tag-button {
  width: 8px;
  height: 8px;
  display: flex;
  cursor: pointer;
  margin-left: 8px;
  align-items: center;
  justify-content: center;

  img {
    width: 8px;
    height: 8px;
  }
}
</style>

<template></template>
<script>
    import APIFactory from '../../api/factory'

    export default {
    	created() {
            APIFactory
                .logout()
                .then(() => {
                    this.$store.commit('auth/setToken', null)
                    this.$store.commit('auth/setApps', null)
                    window.localStorage.clear()
                    this.$router.push('/auth/login')
                })
	    }
    }
</script>

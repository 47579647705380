<template>
    <section
        class="modal__wrapper"
        v-if="active">
        <div class="modal__window"
            :class="{'modal__window--slim': slim}">
            <div class="modal__title">
                <div class="modal__text">{{ title }}</div>
                <div class="modal__button"
                    @click.prevent="$emit('close')">
                    <b-icon
                        pack="fas"
                        icon="times"
                    />
                </div>
            </div>
            <div class="modal__content">
                <slot />
            </div>
            <div class="modal__footer">
                <slot name="footer" />
            </div>
        </div>
    </section>
</template>

<script>
    export default {
    	props: {
    		title: {
    			type: String,
            },
    		active: {
    			type: Boolean,
                required: true,
            },
            slim: {
    			type: Boolean,
                required: false
            }
        },
    }
</script>

<style lang="scss" scoped>
    .modal__wrapper {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 99;
        position: fixed;
        display: flex;
        align-items: center;
        justify-content: center;
        background: $darkest-blue-color;

        .modal__window {
            padding: 2rem;
            min-width: 40%;
            max-width: 50%;
            background: $white;
            border-radius: 4px;
            box-shadow: $box-shadow;

            &.modal__window--slim {
                min-width: 30%;
                max-width: 40%;
            }

            @media (max-width: 1024px) {
                border-radius: 0px;
                min-width: 100%;
                max-width: 100%;
                height: 100%;
            }

            .modal__title {
                display: flex;
                margin-bottom: 20px;
                align-items: center;
                justify-content: space-between;

                .modal__text {
                    font-size: 20px;
                    font-weight: bold;
                }

                .modal__button {
                    display: flex;
                    cursor: pointer;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
    }
</style>
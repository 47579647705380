<template>
  <SidebarLayout :title="$t('settings.permissions')">
    <b-columns v-if="data && data.length > 0">
      <b-column>
        <b-card>
          <Table
            :data="data"
            :headers="headers">
            <div class="modal-controls" slot-scope="data">
              <b-tooltip
                type="is-primary"
                :label="$t('tooltips.permissions')">
                <b-button
                  :to="'/' + $route.params.slug + '/settings/permissions/' + data.row.id"
                  icon-left="unlock-alt"
                  type="is-primary"
                  class="ml-1"
                  tag="router-link"
                />
              </b-tooltip>
              <ConfirmModal
                icon="times"
                type="is-danger"
                @success="removeRole(data.row.id)"
                :title="$t('settings.delete_role')"
                :tooltip="$t('tooltips.delete_role')"
                :message="$t('settings.delete_role_description')"
              />
            </div>
          </Table>
        </b-card>

        <CreateRoleModal
          @success="load"
        />
      </b-column>
    </b-columns>
  </SidebarLayout>
</template>

<script>
  import CreateRoleModal from '../../components/settings/modals/CreateRoleModal'
  import SidebarLayout from '../../components/settings/SidebarLayout'
  import ConfirmModal from '../../components/settings/modals/ConfirmModal'
  import Permission from '../../components/settings/Permission'
  import ModelMapper from '../../data/mapper'
  import Table from '../../components/Table'
  import APIFactory from '../../api/factory'
  import Headers from '../../data/headers'
  import Helpers from '../../helpers'

  export default {
    data() {
      return {
        data: [],
        loading: false,
        headers: Headers.roles,
      }
    },
    created() {
      this.load()
    },
    methods: {
      load() {
        this.loading = true
        APIFactory.findRoles(this.$route.params.slug).then(res => {
          this.loading = false
          if (res && res.success) {
            this.data = ModelMapper.map(this, res.data, 'Role')
          }
        })
      },
      removeRole(id) {
        this.loading = true
        APIFactory.removeRole(this.$route.params.slug, id).then(res => {
          this.loading = false
          if (res && res.success) {
            Helpers.callToast('is-success', this.$t('settings.role_removed_successful'))
            this.load()
          }
        })
      }
    },
    components: {
      ConfirmModal,
      CreateRoleModal,
      SidebarLayout,
      Permission,
      Table,
    }
  }
</script>

<template>
    <div class="modal__button-wrapper">
        <b-button
            class="mt-2"
            type="is-primary"
            @click.prevent="active = true">
            {{ $t('settings.create_payment_method') }}
        </b-button>
        <Modal
            :active="active"
            @close="active = false"
            :title="$t('settings.create_payment_method')">
            <b-columns>
                <b-column>
                    <ErrorMessage
                        v-if="error"
                        :message="$t('auth.invalid_payment_method')"
                    />
                    <ErrorMessage
                        v-if="responseError"
                        :message="responseError"
                    />
                    <div class="c__payment-wrapper">
                        <PaymentMethod
                            :key="index"
                            @select="select"
                            :payment="payment"
                            v-for="(payment, index) in payments"
                            :selected="payment.type === selectedPayment"
                        />
                        <div
                            v-show="selectedPayment"
                            class="payment-wrapper">
                            <header class="c__account-form-subheader">{{ $t('signup.additional_payment_info') }}</header>
                            <div class="payment-area-wrapper">
                                <div id="payment__area" />
                            </div>
                        </div>
                    </div>

                    <b-button
                        expanded
                        class="mt-2"
                        type="is-primary"
                        :loading="loading"
                        :disabled="loading"
                        @click.prevent="save">
                        {{ $t('general.save') }}
                    </b-button>
                </b-column>
            </b-columns>
        </Modal>
    </div>
</template>

<script>
	import Modal from './Modal'
	import APIFactory from '../../../api/factory'
	import ErrorMessage from '../../ErrorMessage'
    import PaymentMethod from '../../PaymentMethod'

	export default {
		data() {
			return {
				error: false,
				active: false,
                loading: false,
                responseError: null,
                selectedPayment: null,
				billing_details: null,
				stripe: Stripe(process.env.VUE_APP_STRIPE_KEY),
				setupIntentClientSecret: null,
				payments: [
					{ type: 'card', title: 'signup.payment_type_card', subtitle: '' },
					{ type: 'iban', title: 'signup.payment_type_iban', subtitle: '' },
					// { type: 'sofort', title: 'Sofort', subtitle: '' },
				],
			}
		},
		created() {
			this.fetchClientSecret()
		},
        methods: {
	        fetchClientSecret() {
		        this.loading = true
		        Promise.all([
		        	APIFactory.createSetupIntent(),
                    APIFactory.findBillingDetails(this.$route.params.slug)
                ]).then(setupIntentResponse => {
			        this.loading = false
                    if (setupIntentResponse) {
                        if (setupIntentResponse[0] && setupIntentResponse[0].success) {
                            this.setupIntentClientSecret = setupIntentResponse[0].data.client_secret
                        }

                        if (setupIntentResponse[1] && setupIntentResponse[1].success) {
                            this.billing_details = setupIntentResponse[1].data.billing_details
                        }
                    }
                })
	        },
			select(type) {
				let payment = this.payments.find(item => item.type === type)
				if (payment) {
					this.selectedPayment = payment.type
				}
            },
            save() {
	            if (!this.selectedPayment) {
		            this.error = true
		            return
	            }
	            this.error = false
	            this.responseError = null


	            this.loading = true
                let paymentDetails = {
	            	billing_details: this.billing_details
                }
	            let setupIntentPromise = null
	            switch(this.selectedPayment) {
		            case 'card':
			            paymentDetails.card = this.paymentMethod
			            setupIntentPromise = this.stripe.confirmCardSetup(this.setupIntentClientSecret, {
				            payment_method: paymentDetails
			            })
			            break
		            case 'iban':
			            paymentDetails.sepa_debit = this.paymentMethod
			            setupIntentPromise = this.stripe.confirmSepaDebitSetup(this.setupIntentClientSecret, {
				            payment_method: paymentDetails
			            })
			            break
	            }

	            setupIntentPromise.then(res => {
		            this.loading = false
		            if (res.error) {
			            this.responseError = res.error.message
			            return
		            }

		            // Send it to the api
                    APIFactory.createPaymentMethod(
                    	this.$route.params.slug, res.setupIntent.payment_method)
                        .then(paymentResponse => {
                        	if (paymentResponse && paymentResponse.success) {
                                this.active = false
                                this.$emit('success')
                            }
                        })
	            })
            },
	        updatePaymentMethod() {
		        let options = {
			        hidePostalCode: true
		        }
		        if (this.selectedPayment && this.selectedPayment === 'iban') {
			        options.supportedCountries = ['SEPA']
		        }

		        this.stripeElements = this.stripe.elements()
		        this.paymentMethod = this.stripeElements.create(this.selectedPayment, options)
		        this.paymentMethod.mount('#payment__area')
	        }
        },
		watch: {
			selectedPayment() {
				this.updatePaymentMethod()
			}
		},
		components: {
			ErrorMessage,
			PaymentMethod,
			Modal,
		}
	}
</script>

<style lang="scss" scoped>
    .payment-area-wrapper {
        padding: 20px;
        min-height: 40px;
        border-radius: 8px;
        background: $light-blue-color;
    }

    .c__account-form-subheader {
        font-size: 20px;
        font-weight: 500;
        margin-top: 30px;
        margin-bottom: 20px;
        font-family: 'Roboto', sans-serif;
    }
</style>

<style lang="scss">
    .StripeElement {
        box-sizing: border-box;

        height: 40px;

        padding: 10px 12px;

        border: 1px solid transparent;
        border-radius: 4px;
        background-color: white;

        box-shadow: 0 1px 3px 0 #e6ebf1;
        -webkit-transition: box-shadow 150ms ease;
        transition: box-shadow 150ms ease;
    }

    .StripeElement--focus {
        box-shadow: 0 1px 3px 0 #cfd7df;
    }

    .StripeElement--invalid {
        border-color: #fa755a;
    }

    .StripeElement--webkit-autofill {
        background-color: #fefde5 !important;
    }
</style>

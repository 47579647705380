<template>
  <menu-taskbar>
    <loading-component :load="loading">
      <titled-container
        :has-controls="true"
        title="Hilfe (Inhalte)">

        <div class="controls" slot="controls">
          <c-button
            @click.prevent="$router.push('/admin/help-contents/new')">
            Neue Hilfeinhalte
          </c-button>

          <labeled-input
            type="text"
            v-model="keyword"
            placeholder="Suche...">
          </labeled-input>
        </div>
        <table class="table">
          <thead>
            <tr>
              <td>Titel</td>
              <td>Sortierung</td>
              <td>Ort</td>
              <td>Video</td>
              <td></td>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="content in filteredContents">
              <td>{{ content.title }}</td>
              <td>{{ content.order }}</td>
              <td>{{ translateArea(content.area) }}</td>
              <td>{{ content.filename }}</td>
              <td>
                <c-button
                  @click.prevent="$router.push('/admin/help-contents/' + content.id)">
                  <c-icon
                    src="images/icons/rework/edit.svg"
                  />
                </c-button>
                <c-button
                  class="ml-2"
                  @click.prevent="remove(content.id)">
                  <c-icon
                    src="images/icons/rework/delete.svg"
                  />
                </c-button>
              </td>
            </tr>
          </tbody>
        </table>
      </titled-container>
    </loading-component>
  </menu-taskbar>
</template>

<script>
  import APIFactory from '@/api/factory'

  export default {
    computed: {
      filteredContents() {
        if (!this.keyword) {
          return this.contents
        }

        return this.contents.filter(c => ('' + c.title).toLowerCase().includes(('' + this.keyword).toLowerCase()))
      }
    },
    data() {
      return {
        contents: [],
        keyword: null,
        loading: false,
      }
    },
    created() {
      this.load()
    },
    methods: {
      remove(id) {
        this.loading = true
        APIFactory.removeHelpContent(id).then(res => {
          if (res && res.success) {
            this.load()
          }
        })
      },
      load() {
        this.loading = true
        APIFactory.findHelpContents().then(res => {
          if (res && res.success) {
            this.contents = (res.data || []).sort((i1, i2) => {
              return i1.area > i2.area ? 1 : -1
            })
          }
        }).finally(() => this.loading = false)
      },
      translateArea(str) {
        switch (str) {
          case 'generic':
            return 'Allgemein'
          case 'workspace':
            return 'Regal-/Ordneransicht'
          case 'mediacenter':
            return 'Mediencenter'
          case 'display':
            return 'Ordneranzeige'
          case 'settings':
            return 'Einstellungen'
          case 'designer':
            return 'Designer'
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .controls {
    @apply
      flex
      w-full
      justify-between;
  }

  .table {
    @apply
      p-4
      w-full
      shadow
      rounded;

    thead {
      tr {
        td,
        th {
          @apply
            font-bold;
        }
      }
    }
  }
</style>


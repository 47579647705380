<template>
  <div :class="{
    	    'c__page': true,
    	    'c__page--selected': selected || draggedOver
        }"
       @click.prevent.stop="$emit('select', page)">
    <drop
      @drop="movePage"
      @dragover="draggedOver = true"
      @dragleave="draggedOver = false"
      class="c__page-row c__page-row--fixedHeight">
      <div class="c__page-container">
        <drag
          v-tooltip="$t('designer.drag_page')"
          class="c__page-icon c__page-icon--grab"
          :transfer-data="{ id: page.id, index: page.index, chapter: chapter.id, type: 'page' }">
          <img src="/images/icons/drag-handle.svg">
        </drag>
        <div class="c__page-icon">
          <b-tooltip
            :delay="tooltipDelay"
            :label="statusTooltip"
            position="is-right">
            <img :src="statusIcon">
          </b-tooltip>
        </div>
        <div :class="{
         'c__page-title': true,
         'c__page-title--untouched': null === page.name
        }">
          <template v-if="page.name">{{ page.name }}</template>
          <template v-else>{{ $t('designer.new_page') }}</template>
        </div>
      </div>
      <div class="c__page-container">
        <PageContext
          :page="page"
          @copy="$emit('copyPage', $event)"
          @delete="$emit('deletePage', $event)"
        />
      </div>
    </drop>
    <div
      v-show="showContents"
      class="c__page-row c__page-row-items">
      <PageContent
        :key="index"
        :name="file.name"
        :mime-type="file.mime"
        v-for="(file, index) in files"
      />
    </div>
  </div>
</template>

<script>
  import PageContext from './PageContext'
  import PageContent from './PageContent'
  import {mapGetters} from "vuex";

  export default {
    props: [
      'page',
      'chapter',
      'selected',
      'show-contents'
    ],
    computed: {
      ...mapGetters({
        tooltipDelay: 'ui/tooltipDelay',
      }),
      files() {
        if (this.page && this.page.objects) {
          return this.page.objects.map(item => {
            return {
              mime: item.preview_type,
              name: item.data.value,
            }
          })
        }
      },
      isPageMarkedAsNew() {
        return this.page.showAsNew || this.page.objects.find(item => item.showAsNew)
      },
      statusIcon() {
        if (this.page.status === 'inactive') {
          return 'images/icons/states/page-inactive.svg'
        }
        if (this.isPageMarkedAsNew) {
          return 'images/icons/star--yellow.svg'
        }
        return 'images/icons/states/page.svg'
      },
      statusTooltip() {
        if (this.page.status === 'inactive') {
          return this.$t('designer.chapter_inactive')
        }

        if (this.page.showAsNew || this.isPageMarkedAsNew) {
          return this.$t('designer.show_as_new')
        }
        return this.$t('designer.chapter_active')
      }
    },
    data() {
      return {
        draggedOver: false
      }
    },
    methods: {
      /**
       * Moves a page to another page or chapter
       * @param $event represents the dragged object
       */
      movePage($event) {
        if ($event.id !== this.page.id) {
          this.$emit('movePage', {
            type: 'page',
            source: $event.id,
            source_index: $event.index,
            source_chapter: $event.chapter,
            target: this.page.id,
            target_index: this.page.index,
            target_chapter: this.chapter.id,
          })
          this.draggedOver = false
        }
      }
    },
    components: {
      PageContext,
      PageContent,
    }
  }
</script>

<style lang="scss" scoped>
  .c__page {
    min-height: 56px;
    border-radius: 8px;
    margin-bottom: 8px;
    background: $white;
    padding: 16px 8px;
    border: 3px solid transparent;

    &:hover,
    &.c__page--selected {
      cursor: pointer;
      border: solid 3px rgba($primary-color, .5)
    }

    .c__page-row {
      width: 100%;
      display: flex;
      align-content: flex-start;
      justify-content: space-between;

      &.c__page-row--fixedHeight {
        min-height: 18px;

        @media (max-width: $breakpoint-mobile) {
          min-height: 18px;
        }
      }

      &.c__page-row-items {
        flex-wrap: wrap;
        margin-top: 8px;
      }

      .c__page-container {
        display: flex;

        .c__page-icon {
          flex-shrink: 0;
          margin: 0 8px;
          display: flex;
          width: 16px;
          height: 16px;
          align-items: center;

          &.c__page-icon--grab {
            cursor: grab;
          }

          img {
            width: 100%;
            height: 100%;
            max-width: 100%;
            max-height: 100%;
          }
        }

        .c__page-title {
          font-size: 16px;
          font-weight: 500;
          line-height: 1.1;
          word-wrap: break-word;
          color: $darker-blue-color;
          font-family: Roboto, sans-serif;

          &.c__page-title--untouched {
            color: $dark-gray-color;
          }
        }
      }
    }

  }
</style>

<template>
  <span :class="{
    'c-icon': true,
    'c-icon--size-small': size === 'small',
    'c-icon--size-medium': size === 'medium',
    'c-icon--size-large': size === 'large',
    'c-icon--size-xlarge': size === 'xlarge'
  }" />
</template>

<script>
  let cache = new Map()

  export default {
    props: [
      'src',
      'size',
    ],
    async mounted() {
      await this.update()
    },
    methods: {
      async update() {
        if (!cache.has(this.src)) {
          try {
            cache.set(this.src, fetch(this.src).then(r => r.text()));
          } catch (e) {
            cache.delete(this.src);
          }
        }
        if (cache.has(this.src)) {
          this.$el.innerHTML = await cache.get(this.src);
        }
      }
    },
    watch: {
      src() {
        this.update()
      }
    }
  }
</script>

<style lang="scss">
  .c-icon {
    width: 16px;
    height: 16px;

    svg {
      width: 16px;
      height: 16px;
    }

    $small: 16px;
    $medium: 20px;
    $large: 24px;
    $xlarge: 30px;

    &.c-icon--size-small { width: $small; height: $small; svg { width: $small; height: $small; }}
    &.c-icon--size-medium { width: $medium; height: $medium; svg { width: $medium; height: $medium; }}
    &.c-icon--size-large { width: $large; height: $large; svg { width: $large; height: $large; }}
    &.c-icon--size-xlarge { width: $xlarge; height: $xlarge; svg { width: $xlarge; height: $xlarge; }}
  }
</style>

export default {
	"app": {
		"unknown_error": "Stala se nezn\u00e1m\u00e1 chyba. Obra\u0165 se pros\u00edm na support@digital-folder.com.",
		"name": "digit\u00e1ln\u00ed slo\u017eka\u00ae",
		"language_de": "n\u011bmecky",
		"language_en": "anglicky",
		"language_cz": "Czech",
		"language_ru": "Russian",
		"search_objects": "medi\u00e1ln\u00ed objekt",
		"search_containers": "kontejner na m\u00e9dia",
		"search_folders": {},
		"search_racks": "police",
		"country_germany": "N\u011bmecko",
		"country_austria": "Rakousko",
		"country_switzerland": "\u0160v\u00fdcary"
	},
	"auth": {
		"login": "p\u0159ihl\u00e1sit se",
		"logout": "odhl\u00e1sit se",
		"user_has_app": "u\u017eivatel je u\u017e p\u0159ihl\u00e1\u0161en",
		"forget_text": "V p\u0159\u00edpad\u011b zapomenut\u00ed hesla je obnoven\u00ed mo\u017en\u00e9 posl\u00e1n\u00edm e-mailov\u00e9 adresy. Pot\u00e9 my vytvo\u0159\u00edme nov\u00e9 n\u00e1hodn\u00e9 heslo, kter\u00e9 po\u0161leme zp\u011bt.",
		"forget": "obnovit heslo",
		"user_not_found": "pod zadanou e-mailovou adresou neexistuje \u017e\u00e1dn\u00fd u\u017eivatel",
		"wrong_password": "u\u017eivatel neexistuje nebo heslo je \u0161patn\u00e9",
		"welcome": "v\u00edtej zp\u011bt",
		"forgot_success_message": "Tvoje heslo bylo \u00fasp\u011b\u0161n\u011b obnoveno. Dostal jsi od n\u00e1s novou e-mail."
	},
	"signup": {
		"version": "kter\u00e1 verze Ti vyhovuje?",
		"monthly_payment": "m\u011bs\u00ed\u010dn\u00ed platba",
		"yearly_payment": "ro\u010dn\u00ed platba",
		"monthly": "m\u011bs\u00ed\u010dn\u011b",
		"yearly": "ro\u010dn\u011b",
		"favourite_option": "nejobl\u00edben\u011bj\u0161\u00ed mo\u017enost",
		"constantly_free": "trvale zdarma",
		"each_user": "na u\u017eivatele \/ m\u011bs\u00edc",
		"signup_now": "nyn\u00ed se zaregistrovat",
		"upgrade_now": "nyn\u00ed tarif upravit",
		"free": "Free (zdarma)",
		"basic": "Basic (z\u00e1kladn\u011b)",
		"basic-monthly": "z\u00e1kladn\u011b (m\u011bs\u00ed\u010dn\u011b)",
		"basic-yearly": "z\u00e1kladn\u011b (ro\u010dn\u011b)",
		"premium-monthly": "pr\u00e9mium (m\u011bs\u00ed\u010dn\u011b)",
		"premium-yearly": "pr\u00e9mium (ro\u010dn\u011b)",
		"premium-yearly2": "pr\u00e9mium (ro\u010dn\u011b)",
		"coupon-no-cost": "kup\u00f3n (pr\u00e9mium)",
		"premium": "pr\u00e9mium",
		"free_subtitle": "v klidu vyzkou\u0161et",
		"space_each_plan": "{space} GB Cloud \u00falo\u017en\u00fd prostor",
		"free_argument1": "omezeno na jednu slo\u017eku",
		"free_argument3": "v\u010detn\u011b 30denn\u00edho testu pr\u00e9miov\u00e9ho tarifu",
		"basic_subtitle": "v\u0161echny z\u00e1kladn\u00ed funkce",
		"basic_argument0": "12 m\u011bs\u00edc\u016f",
		"basic_argument1": "neomezen\u00fd po\u010det slo\u017eek",
		"basic_argument3": "bez reklamy",
		"premium_subtitle": "kompletn\u00ed bal\u00ed\u010dek",
		"premium_argument0": "12 m\u011bs\u00edc\u016f",
		"premium_argument1": "neomezen\u00fd po\u010det slo\u017eek",
		"premium_argument3": "bez reklamy",
		"additional_month_1": "(+{month} voln\u00e9 m\u011bs\u00edce)",
		"additional_month_2": "({month} voln\u00e9 m\u011bs\u00edce)",
		"selected_plan": "vybran\u00fd tarif",
		"change_plan": "zm\u011bna tarifu",
		"plan": "tarif",
		"payment_type": "zp\u016fsob platby",
		"licenses": "po\u010det u\u017eivatelsk\u00fdch licenc\u00ed",
		"today_summary": "dne\u0161n\u00ed celkov\u00e1 \u010d\u00e1stka",
		"summary": "celkov\u00e1 \u010d\u00e1stka excl. DPH",
		"after_summary": "splatn\u00e1 \u010d\u00e1stka excl. DPH",
		"change_after_time": "na konci 12 m\u011bs\u00edc\u016f bude\u0161 automaticky za\u0159azen na tarif \u201eFREE\u201c",
		"coupon_code": "\u010d\u00edslo kup\u00f3nu",
		"coupon_code_placeholder": "sem zadej \u010d\u00edslo kup\u0144u",
		"coupon_invalid": "k\u00f3d kup\u00f3nu je neplatn\u00fd",
		"coupon_valid": "k\u00f3d kup\u00f3nu je platn\u00fd",
		"personal_heading": "osobn\u00ed informace",
		"firstname": "k\u0159estn\u00ed jm\u00e9no",
		"firstname_placeholder": "sem zadej k\u0159estn\u00ed jm\u00e9no",
		"lastname": "p\u0159\u00edjmen\u00ed",
		"lastname_placeholder": "sem zadej p\u0159\u00edjmen\u00ed",
		"password": "heslo",
		"password_placeholder": "sem zadej heslo",
		"password_confirm": "zopakovat heslo",
		"password_personal": "nastavit osobn\u00ed heslo",
		"password_personal_placeholder": "sem zadej osobn\u00ed heslo",
		"email_address": "e-mailov\u00e1 adresa",
		"email_address_placeholder": "sem zadej e-mailovou adresu",
		"personal_information": {},
		"continue": "d\u00e1le",
		"back": "zp\u011bt",
		"confirm_not_equal": "dv\u011b hesla se neshoduj\u00ed",
		"password_needs_several_chars": "heslo mus\u00ed sest\u00e1vat alespo\u0148 z 6 znak\u016f stejn\u011b jako obsahovat mal\u00e9\/velk\u00e9 p\u00edsmeno a \u010d\u00edslo",
		"company_heading": "informace o spole\u010dnosti",
		"company": "jm\u00e9no spole\u010dnosti",
		"company_placeholder": "sem zadej jm\u00e9no spole\u010dnosti",
		"country": "zem\u011b",
		"country_placeholder": "sem zadej zem",
		"street": "ulice",
		"street_placeholder": "sem zadej ulici",
		"postal_code": "PS\u010c",
		"postal_code_placeholder": "sem zadej PS\u010c",
		"city": "m\u00edsto",
		"vat_id": "DI\u010c",
		"vat_id_placeholder": "sem zadej DI\u010c",
		"city_placeholder": "sem zadej m\u00edsto",
		"mediation": "zprost\u0159edkov\u00e1no",
		"mediation_placeholder": "sem zadej k\u00fdm zprost\u0159edkov\u00e1no",
		"additional_payment_info": "dal\u0161\u00ed informace o platb\u011b",
		"payment_heading": "zp\u016fsob platby",
		"payment_type_card": "kreditn\u00ed karta",
		"payment_type_card_info": "informace o kreditn\u00ed kart\u011b",
		"payment_type_iban": "SEPA inkaso",
		"payment_type_iban_info": "IBAN",
		"payment_type_iban_info_text": "Vyd\u00e1v\u00e1m digit\u00e1ln\u00ed optimalizaci \u2013 gro\u00df & partner SEPA inkaso pro v\u00fd\u0161e uveden\u00fd bankovn\u00ed \u00fa\u010det. Mohu po\u017e\u00e1dat o vr\u00e1cen\u00ed inkasa do 8 t\u00fddn\u016f po odeps\u00e1n\u00ed \u010d\u00e1stky.",
		"summary_heading": "shrnut\u00ed pov\u011b\u0159en\u00ed",
		"price_incl_vat": "cena excl. DPH",
		"cost": "n\u00e1klady",
		"terms_company": "Potvrzuji, \u017ee se registruji jako spole\u010dnost.",
		"terms_of_service": "Souhlas\u00edm s <a href=\"https:\/\/www.digital-folder.com\/terms\" target=\"_blank\" class=\"highlighted\"> obecn\u00fdm ustanoven\u00edm.",
		"order_data_processing": "Souhlas\u00edm s <a href=\"https:\/\/www.digital-folder.com\/wp-content\/uploads\/2019\/10\/ADV-digital-optimisation.pdf\" target=\"_blank\" class=\"highlighted\"> zpracov\u00e1n\u00edm dat objedn\u00e1vek.",
		"signup_success": "\u00dasp\u011b\u0161n\u011b jsi se zaregistroval do digit\u00e1ln\u00ed slo\u017eky\u00ae. Nejprve pros\u00edm potvr\u010f sv\u016fj \u00fa\u010det.",
		"welcome_message": "Byl jsi pozv\u00e1n k registraci pro digit\u00e1ln\u00ed slo\u017eku\u00ae, abys mohl pracovat v t\u00fdmu.",
		"invite_not_found": "pozv\u00e1nka bohu\u017eel vypr\u0161ela",
		"upgrade_success": "Tv\u016fj \u00fa\u010det byl \u00fasp\u011b\u0161n\u011b aktualizov\u00e1n",
		"current_plan": "aktu\u00e1ln\u00ed tarif",
		"coupon_exists": "Je \u010d\u00edslo kup\u00f3nu k dispozici? Klikni zde.",
		"coupon_invalid_date": "k\u00f3d kup\u00f3nu bohu\u017eel vypr\u0161el",
		"coupon_already_used": "k\u00f3d kup\u00f3nu byl ji\u017e pou\u017eit",
		"company_signup_tooltip": "T\u00edmto potvrzuji, \u017ee pou\u017e\u00edv\u00e1m digit\u00e1ln\u00ed slo\u017eku\u00aes\u00ae jako spole\u010dnost a ne jako soukrom\u00e1 osoba.",
		"verified": "\u00dasp\u011b\u0161n\u011b jsi ov\u011b\u0159il sv\u016fj \u00fa\u010det. Nyn\u00ed se m\u016f\u017ee\u0161 p\u0159ihl\u00e1sit.",
		"account_not_verified": "Tv\u016fj \u00fa\u010det nelze ov\u011b\u0159it. Obra\u0165 se pros\u00edm na support@digital-folder.com",
		"not_verified": "Tv\u016fj \u00fa\u010det  nebyl dosud ov\u011b\u0159en. Obdr\u017eel jsi od n\u00e1s e-mail, pomoc\u00ed kter\u00e9ho se m\u016f\u017ee\u0161 ov\u011b\u0159it.",
		"countries_soon": "V sou\u010dasn\u00e9 dob\u011b je k dispozici pouze N\u011bmecko. Brzy v\u0161ak roz\u0161\u00ed\u0159\u00edme na\u0161e podporovan\u00e9 zem\u011b. \u017d\u00e1d\u00e1me T\u011b o trp\u011blivost.",
		"company_advice": "(only if youn sign up as a company)",
		"licenses_info": "Please enter tthe total amout of users",
		"downgrade_disabled": "currently the downgrade option is not activated. Please cancel your curretn plan first and then chose the plan you want. Alternatively contact support@digital-folder.com"
	},
	"general": {
		"help": "pomoc",
		"email": "e-mailov\u00e1 adresa",
		"password": "heslo",
		"password_confirm": "zopakovat heslo",
		"validation_failed": "vypl\u0148 pros\u00edm n\u00e1sleduj\u00edc\u00ed pole:",
		"invalid_form": "povinn\u00e1 pole nebyla v\u0161echna vypln\u011bna",
		"firstname": "k\u0159estn\u00ed jm\u00e9no",
		"lastname": "p\u0159\u00edjmen\u00ed",
		"language": "jazyk",
		"department": "odd\u011blen\u00ed",
		"save": "ukl\u00e1dat",
		"actions": "akce",
		"name": "jm\u00e9no",
		"role": "role",
		"roles": "role\/rol\u00ed",
		"description": "popis",
		"optional": "voliteln\u00e9",
		"status": "stav",
		"cancel": "p\u0159eru\u0161it",
		"confirm": "potvrdit",
		"no_permissions": "k tomu nem\u00e1\u0161 opr\u00e1vn\u011bn\u00ed",
		"yes": "ano",
		"no": "ne",
		"ok": "o.k.",
		"no_app_found": "Tato instance digit\u00e1ln\u00ed slo\u017eky\u00ae ji\u017e neexistuje nebo byla zablokov\u00e1na. Obra\u0165 se pros\u00edm na support@digital-folder.com",
		"app_blocked": "tato aplikace byla spr\u00e1vcem zablokov\u00e1na",
		"no_team_found": "tento t\u00fdm ji\u017e neexistuje nebo u\u017eivatel nen\u00ed p\u0159i\u0159azen k tomuto t\u00fdmu",
		"unauthenticated": "p\u0159ihlas se pros\u00edm znovu",
		"invited_at": "invited at",
		"accepted_at": "invitation accepted at"
	},
	"display": {
		"help": "pomoc",
		"open": "otev\u0159\u00edt slo\u017eku",
		"wrong_password": "heslo sd\u00edlen\u00e9 slo\u017eky nen\u00ed spr\u00e1vn\u00e9",
		"description_1": "vyzval jsi odkaz pro otev\u0159en\u00ed digit\u00e1ln\u00ed slo\u017eky\u00ae",
		"description_2": "chce\u0161-li zobrazit obsah, zadej n\u00ed\u017ee uveden\u00e9 heslo",
		"description_3": "Tato digit\u00e1ln\u00ed slo\u017eka\u00ae bohu\u017eel ji\u017e neexistuje. Vlastn\u00edk ji mo\u017en\u00e1 do\u010dasn\u011b zablokoval.",
		"no_folder_found_headline": "Je n\u00e1m l\u00edto...",
		"request_password": "zapomn\u011bl jsi heslo?",
		"email_subject": "\u017e\u00e1dost o heslo",
		"menu_open": "zobrazit obsah",
		"menu_hide": "skr\u00fdt obsah",
		"close": "close folder",
		"show_new_elements": "Show new elements",
		"hide_new_elements": "Hide new elements",
		"trial_period": "Free 30 days trial",
		"create_link": "Copy link to this page"
	},
	"settings": {
		"account": "\u00fa\u010det",
		"dashboard": "p\u0159ehled",
		"profile": "u\u017eivatelsk\u00fd profil",
		"security": "bezpe\u010dnost",
		"organization": "organizace",
		"company": "spole\u010dnost",
		"users": "u\u017eivatel",
		"teams": "t\u00fdmy",
		"team": "t\u00fdm",
		"website": "webov\u00e1 str\u00e1nka",
		"vat_number": "DI\u010c",
		"permissions": "roles and authorizations",
		"accounting": "z\u00fa\u010dtov\u00e1n\u00ed",
		"plans": "zm\u011bna tarifu",
		"settings": "nastaven\u00ed",
		"superadmin": "superadministrace",
		"payments": "zp\u016fsob platby",
		"invoices": "\u00fa\u010dty",
		"back": "zp\u011bt do digit\u00e1ln\u00ed slo\u017eky\u00ae",
		"password_change": "zm\u011bnit heslo",
		"user_not_found": "u\u017eivatel nebyl nalezen",
		"description": "Zde m\u016f\u017ee\u0161 sv\u00e9 heslo obnovit. Zad\u00e1n\u00edm star\u00e9ho hesla a opakov\u00e1n\u00edm nov\u00e9ho m\u016f\u017ee\u0161 heslo obnovit.",
		"old_password": "aktu\u00e1ln\u00ed heslo",
		"new_password": "nov\u00e9 heslo",
		"confirm": "heslo zopakovat",
		"save_changes": "zm\u011bny ulo\u017eit",
		"members_count": "po\u010det \u010dlen\u016f t\u00fdmu",
		"invite_member": "pozvat \u010dlena t\u00fdmu",
		"invite_user": "pozvat u\u017eivatele",
		"invite_description": "V tomto dialogu mohou b\u00fdt do digit\u00e1ln\u00ed slo\u017eky\u00ae pozv\u00e1ni dal\u0161\u00ed \u010dlenov\u00e9. Zad\u00e1n\u00edm e-mailov\u00e9 adresy je lze tak\u00e9 p\u0159i\u0159adit p\u0159\u00edmo t\u00fdmu. Pokud u\u017eivatel dosud v syst\u00e9mu neexistuje, je e-mailem informov\u00e1n, \u017ee byl pozv\u00e1n.",
		"create_team": "nov\u00fd t\u00fdm",
		"change_team": "zm\u011bnit t\u00fdm",
		"change_role": {},
		"invoice_number": "\u010d\u00edslo \u00fa\u010dtu",
		"invoice_date": "datum \u00fa\u010dtu",
		"invoice_sum": "\u010d\u00e1stka",
		"create_role": {},
		"create_payment_method": "nov\u00fd zp\u016fsob platby",
		"permissions_for": "povolen\u00ed pro",
		"credentials": "informace o \u00fa\u010dtu",
		"default_method": "standardn\u00ed zp\u016fsob platby",
		"change_password_successful": "heslo bylo \u00fasp\u011b\u0161n\u011b zm\u011bn\u011bno",
		"change_avatar_successful": "profilov\u00fd obr\u00e1zek byl \u00fasp\u011b\u0161n\u011b zm\u011bn\u011bn",
		"status_active": "aktivn\u00ed",
		"status_blocked": "blokov\u00e1no",
		"status_invited": "pozvan\u00fd",
		"status_trialing": "zku\u0161ebn\u00ed f\u00e1ze",
		"role_admin": "spr\u00e1vce",
		"role_user": "u\u017eivatel",
		"block_user": "zablokovat u\u017eivatele",
		"unblock_user": "odemknout u\u017eivatele",
		"block_description": "opravdu chce\u0161 tohoto u\u017eivatele zablokovat?",
		"unblock_description": "opravdu chce\u0161 tohoto u\u017eivatele odemknout?",
		"select_team": "vyber t\u00fdm",
		"team_exists": "t\u00fdm s t\u00edmto n\u00e1zvem ji\u017e existuje",
		"delete_team": "smazat t\u00fdm",
		"delete_team_description": "opravdu chce\u0161 tento t\u00fdm smazat?",
		"leave_team": "opustit t\u00fdm",
		"leave_team_description": "opravdu chce\u0161 tento t\u00fdm opustit?",
		"team_cant_delete": "t\u00fdm nelze odstranit, proto\u017ee u\u017eivatel\u00e9 jsou st\u00e1le p\u0159i\u0159azeni",
		"team_has_folders": {},
		"team_not_found": "t\u00fdm nebyl nalezen",
		"team_removed_successful": "t\u00fdm byl \u00fasp\u011b\u0161n\u011b smaz\u00e1n",
		"team_leave_successful": "t\u00fdm byl \u00fasp\u011b\u0161n\u011b opu\u0161t\u011bn",
		"team_members_empty": "t\u00fdm nem\u00e1 \u017e\u00e1dn\u00e9 \u010dleny",
		"role_exists": {},
		"payments_empty": "zat\u00edm nebyl ulo\u017een \u017e\u00e1dn\u00fd zp\u016fsob platby",
		"default_payment_successful": "zp\u016fsob platby byl ulo\u017een jako standardn\u00ed",
		"payment_method_not_found": "zp\u016fsob platby nebyl nalezen",
		"updated_role_successful": "opr\u00e1vn\u011bn\u00ed byla \u00fasp\u011b\u0161n\u011b ulo\u017eena",
		"role_not_found": {},
		"delete_role": {},
		"delete_role_description": {},
		"role_removed_successful": {},
		"role_is_used": {},
		"user_has_been_invited_already": "u\u017eivatel ji\u017e byl pozv\u00e1n",
		"invited_successful": "u\u017eivatel byl \u00fasp\u011b\u0161n\u011b pozv\u00e1n",
		"members_of": "\u010dlenov\u00e9",
		"team_kick_successful": "u\u017eivatel byl \u00fasp\u011b\u0161n\u011b odebr\u00e1n z t\u00fdmu",
		"team_kick_description": "U\u017eivatel je odebr\u00e1n z t\u00fdmu a nem\u00e1 ji\u017e p\u0159\u00edstup k jeho obsahu. Opravdu chce\u0161 u\u017eivatele z t\u00fdmu odebrat?",
		"team_kick": "vyjmout z t\u00fdmu",
		"organisation_saved_successful": "informace o spole\u010dnosti byly \u00fasp\u011b\u0161n\u011b ulo\u017eeny",
		"invoice_status_draft": "ve zpracov\u00e1n\u00ed (ca. 1h)",
		"invoice_status_open": "otev\u0159eno",
		"invoice_status_paid": "zaplaceno",
		"tile_folder": {},
		"tile_objects": "medi\u00e1ln\u00ed objekt | medi\u00e1ln\u00ed objekty",
		"tile_users": "u\u017eivatel",
		"tile_space": "dostupn\u00e9 \u00falo\u017en\u00e9 prostory",
		"tile_user_contingent": "u\u017eivatelsk\u00e1 kv\u00f3ta",
		"tile_user_out_of_max_users": "{users} z {max_users} u\u017eivatel\u016f",
		"tile_space_out_of_space": "{space} z {max_space} GB",
		"tile_plan": "tarif",
		"users_exceeded": "Byl dosa\u017een u\u017eivatelsk\u00fd limit. Rezervuj si pros\u00edm dal\u0161\u00ed u\u017eivatele prost\u0159ednictv\u00edm upgradu.",
		"cancel_subscription": "zru\u0161it p\u0159edplatn\u00e9",
		"cancel_subscription_success_message": {},
		"back_to_digital_folder": "zp\u011bt na p\u0159ehled police \/ p\u0159ehled m\u00e9di\u00ed",
		"help": "pomoc",
		"language_chooser": "v\u00fdb\u011br jazyka",
		"remove_user": "odstranit u\u017eivatele",
		"remove_user_message": "opravdu chce\u0161 u\u017eivatele odstranit?",
		"user_can_not_deleted": {},
		"user_is_app_owner": "U\u017eivatel nem\u016f\u017ee b\u00fdt odstran\u011bn, proto\u017ee je vlastn\u00edkem.",
		"change_default_payment_method": "tento zp\u016fsob platby nastavit jako standardn\u00ed",
		"send_invitation": "znovu poslat pozv\u00e1nku",
		"send_invitation_successful": "pozv\u00e1nka byla znovu odesl\u00e1na",
		"user_can_not_blocked": {},
		"cancel_subscription_message": "opravdu chce\u0161 zru\u0161it p\u0159edplatn\u00e9?",
		"plan_info": "chose one of the plan options to upgrade your digital folder\u00ae plan or to get more users\/cloud space. ",
		"subscription_runtime": "your plan starts {start_date} and ends {end_date} incl. {discount} month (free) (payment cycle: {payment_interval})",
		"subscription_runtime_coupon": "your plan starts {start_date} and ends {end_date} incl. {discount} month (free + paid) (payment cycle: {payment_interval})",
		"subscription_canceled_at": "the plan was canceled on:",
		"status_needs_confirmation": "Invited",
		"notifications": "Notifications",
		"invite_message": "You've been invited by {company} in one or more teams ({teams}). Do you like to join them?",
		"no_notifications": "No notifications found",
		"invoice_info": "",
		"invite_support_description": "Invite the email address support@digital-folder.com to get spport-access by one of our employees",
		"invite_account_status_based": "Hinweis ausstehend!",
	},
	"tooltips": {
		"remove_member": "odstran\u00ed tohoto \u010dlena z t\u00fdmu",
		"leave_team": "Kdy\u017e opust\u00ed\u0161 t\u00fdm, nem\u00e1\u0161 p\u0159\u00edstup k digit\u00e1ln\u00edm slo\u017ek\u00e1m\u00ae a m\u00e9di\u00edm.",
		"change_role": {},
		"edit_user": "upravit u\u017eivatele",
		"pay_now": "te\u010f zaplatit",
		"download": "tuto fakturu st\u00e1hnout",
		"block_user": "tohoto u\u017eivatele blokovat, aby se zabr\u00e1nilo p\u0159\u00edstupu do syst\u00e9mu",
		"unblock_user": "tohoto u\u017eivatele aktivovat, aby m\u011bl p\u0159\u00edstup do syst\u00e9mu",
		"permissions": "roles and authorizations",
		"delete_role": {}
	},
	"designer": {
		"page": "page",
		"chapter": "chapter",
		"designer": "n\u00e1vrh\u00e1\u0159 slo\u017eek",
		"comment_placeholder": "sem zadej slo\u017eku",
		"expand_all": "v\u0161e rozlo\u017eit",
		"collapse_all": "v\u0161e sbalit",
		"new_chapter": "nov\u00e1 kapitola",
		"new_page": "vytvo\u0159it novou str\u00e1nku",
		"page_title": "zan\u00e9st n\u00e1zev str\u00e1nky",
		"chapter_title": "zan\u00e9st n\u00e1zev kapitoly",
		"remove_page": "odstranit str\u00e1nku",
		"remove_chapter": "odstranit kapitolu",
		"background_image": "obr\u00e1zek na pozad\u00ed",
		"search_mediacenter": "prohledat medi\u00e1ln\u00ed centrum",
		"drop_file": {},
		"media_center_search": "vyhledat v\u00fdb\u011br",
		"selected_files": "vybrat soubory dat",
		"append_files": "zaujmout m\u00edsto",
		"folder_not_found": {},
		"all_media_containers": "kontejnery na m\u00e9dia",
		"cover": "kryt slo\u017eky",
		"unnamed_chapter": "nepojmenovan\u00e1 kapitola",
		"unnamed_page": "nepojmenovan\u00e1 str\u00e1nka",
		"comment_save_message": "pozn\u00e1mka byla \u00fasp\u011b\u0161n\u011b ulo\u017eena",
		"comments": "pozn\u00e1mky ke slo\u017ece",
		"clock": "hodiny",
		"comment_not_found": "pozn\u00e1mka nebyla nalezena",
		"done": "hotovo",
		"mark_success_message": "pozn\u00e1mka byla ozna\u010dena jako hotov\u00e1",
		"done_by": "hotovo {user} dne {date}",
		"save_folder_success": {},
		"unsaved_content": "chce\u0161 opustit n\u00e1vrh\u00e1\u0159e slo\u017eek bez ulo\u017een\u00ed zm\u011bn?",
		"discard_changes": "zru\u0161it zm\u011bny",
		"save_changes": "ulo\u017eit zm\u011bny",
		"folder_is_locked": {},
		"preview": "n\u00e1hled",
		"drag_chapter": "pou\u017eit\u00ed funkce drag & drop k p\u0159esunut\u00ed t\u00e9to kapitoly",
		"drag_page": {},
		"back": "zp\u011bt na p\u0159ehled slo\u017eek",
		"more_file_info": "zobrazit dal\u0161\u00ed informace o souboru",
		"less_file_info": "zobrazit m\u00e9n\u011b informac\u00ed o souboru",
		"cover_tooltip": "p\u0159ep\u00edn\u00e1n\u00ed mezi obr\u00e1zkem ob\u00e1lky a barvou ob\u00e1lky",
		"chapter_active": "aktivn\u00ed kapitola",
		"chapter_inactive": "neaktivn\u00ed kapitola",
		"comments_not_allowed": "V tarifu Free & Basic nejsou povoleny \u017e\u00e1dn\u00e9 koment\u00e1\u0159e. Pros\u00edm aktualizuj tarif.",
		"change_page_layout_1": "zm\u011bnit rozvr\u017een\u00ed str\u00e1nky - 1 prvek",
		"change_page_layout_2": "zm\u011bnit rozvr\u017een\u00ed str\u00e1nky \u2013 2 prvky",
		"change_page_layout_4": "zm\u011bnit rozvr\u017een\u00ed str\u00e1nky - 4 prvky",
		"change_page_layout_6": "zm\u011bnit rozvr\u017een\u00ed str\u00e1nky - 6 prvk\u016f",
		"change_object": "kliknout na obr\u00e1zek pro v\u00fdm\u011bnu medi\u00e1ln\u00edho souboru",
		"image_mode": "form\u00e1t zobrazen\u00ed",
		"image_mode_cover": "vyplnit",
		"image_mode_contains": {},
		"page_grid_too_much_items": "Na str\u00e1nce je p\u0159\u00edli\u0161 mnoho medi\u00e1ln\u00edch objekt\u016f, abys mohl zm\u011bnit rozlo\u017een\u00ed str\u00e1nky. Odstra\u0148 proto pros\u00edm p\u0159ebyte\u010dn\u00e9 objekty.",
		"note_read_title": "show comment",
		"note_write_title": "add comment",
		"note": "comment",
		"note_tooltip": "pozn\u00e1mka k dispozici",
		"note_placeholder": "Place your note here",
		"qr_code": "QR-Code",
		"qr_code_download": "Download QR-Code",
		"media_tooltip_word": "Toggles all media objects of type 'MS Word'",
		"media_tooltip_excel": "Toggles all media objects of type 'MS Excel'",
		"media_tooltip_powerpoint": "Toggles all media objects of type 'MS Powerpoint'",
		"media_tooltip_image": "Toggles all media objects of type 'Image'",
		"media_tooltip_file": "Toggles all media objects of type 'PDF'",
		"media_tooltip_link": "Toggles all media objects of type 'Link'",
		"media_tooltip_others": "Toggles all media objects of type 'Others'",
		"delete_chapter_message": "Are you sure to delete all chapters including all pages?",
		"delete_page_message": "Are you sure to delete this page including all contents?",
		"change_folder_background": "Toggles between colored or image background",
		"select_background_color": "Select background color",
		"select_background_image": "Select from media center",
		"reset_background": "Reset background color and -image",
		"show_as_new": "Show as new",
		"hide_as_new": "Hide as new",
	},
	"workspaces": {
		"racks": "police",
		"folderNRacks": "slo\u017eky \/ police",
		"search": "v\u0161e prohledat",
		"recent_used": "naposledy pou\u017eito",
		"create_rack": "vytvo\u0159it novou polici",
		"create_folder": "nov\u00e1 slo\u017eka",
		"copy": "kop\u00edrovat",
		"export": "exportovat",
		"folder": "slo\u017eka",
		"edit": "upravovat",
		"release": "uvolnit",
		"download": "st\u00e1hnout",
		"show": "uk\u00e1zat",
		"visible": "viditeln\u011b",
		"copy_link": "kop\u00edrovat odkaz",
		"hide": "skr\u00fdt",
		"move": "posunout",
		"activate": "aktivovat",
		"deactivate": "deaktivovat",
		"delete": "smazat",
		"tags": "kl\u00ed\u010dov\u00e9 slovo",
		"edit_shelf": "upravit polici",
		"create_shelf": "nov\u00e1 police",
		"tag_placeholder": "zan\u00e9st kl\u00ed\u010dov\u00e1 slova",
		"shelf_name_placeholder": "sem zadej n\u00e1zev police",
		"save_shelf": "ulo\u017eit polici",
		"folder_name": "n\u00e1zev slo\u017eky",
		"folder_name_placeholder": "sem zadej n\u00e1zev slo\u017eky",
		"shelf_name": "n\u00e1zev police",
		"save_folder": "ulo\u017eit slo\u017eku",
		"shelf_color": "barva police",
		"own_hex_value": "HEX hodnota barvy",
		"cover_color": "barva ob\u00e1lky",
		"cover_image": "obr\u00e1zek ob\u00e1lky",
		"designer": "n\u00e1vrh\u00e1\u0159",
		"rack_with_name_exists": "police s n\u00e1zvem ji\u017e existuje",
		"save_rack_success_message": "police byla \u00fasp\u011b\u0161n\u011b ulo\u017eena",
		"remove_rack_confirm_title": "odstranit polici",
		"remove_rack_confirm_message": "opravdu chce\u0161 tuto polici odstranit?",
		"remove_rack_success_message": "police byla \u00fasp\u011b\u0161n\u011b odstran\u011bna",
		"rack_not_found": "police nebyla nalezena",
		"rack_has_folders": "polici nelze odstranit, proto\u017ee st\u00e1le obsahuje digit\u00e1ln\u00ed slo\u017eky",
		"sort_latest": "nejnov\u011bj\u0161\u00ed",
		"sort_last_used": "naposledy pou\u017eito",
		"sort_alphabetically": "A \u2013 Z",
		"sort_anti_alphabetically": "Z \u2013 A",
		"sort_most_comments": "v\u011bt\u0161ina pozn\u00e1mek",
		"sort_shared_containers": "shared media containers",
		"status_in_progress": "v pr\u00e1ci od",
		"status_finished": "dokon\u010deno",
		"status_released": "vyd\u00e1no",
		"status_locked": "zablokov\u00e1no",
		"status_archived": "archivov\u00e1no",
		"folder_not_found": {},
		"folder_with_name_exists": {},
		"change_status": "zm\u011bnit stav",
		"context_menu_status_in_progress": "v pr\u00e1ci",
		"context_menu_status_finish": "dokon\u010dit",
		"context_menu_status_release": "uvolnit",
		"context_menu_status_lock": "zablokovat",
		"context_menu_status_archive": "archivovat",
		"status_changed_successful_message": "stav digit\u00e1ln\u00ed slo\u017eky\u00ae byl \u00fasp\u011b\u0161n\u011b zm\u011bn\u011bn",
		"release_folder": "uvolnit slo\u017eku",
		"release_folder_description": "Chce\u0161-li slo\u017eku sd\u00edlet s ostatn\u00edmi, aktivuj pros\u00edm sd\u00edlen\u00ed slo\u017eek.<br><br> V r\u00e1mci opce m\u00e1\u0161 mo\u017enost aktivovat ochranu heslem pro svou slo\u017eku. Pokud je ochrana heslem aktivov\u00e1na, lze slo\u017eku zobrazit pouze s p\u0159i\u0159azen\u00fdm heslem.",
		"activate_password": "aktivovat ochranu heslem",
		"send_credentials": "e-mailem poslat hypertextov\u00fd odkaz & p\u0159\u00edstup k dat\u00e1m",
		"activate_release": "aktivovat sd\u00edlen\u00ed",
		"email_subject": "digit\u00e1ln\u00ed slo\u017eka\u00ae - sd\u00edlen\u00ed",
		"password_placeholder": "sem zadej heslo",
		"email_message": "odkaz na slo\u017eku: {link}",
		"email_password": "heslo {password}",
		"copied": "odkaz byl kop\u00edrov\u00e1n!",
		"save_password": "zm\u011bnit heslo",
		"remove_folder_confirm_title": "odstranit digit\u00e1ln\u00ed slo\u017eku\u00ae?",
		"remove_folder_confirm_message": "opravdu chce\u0161 odstranit tuto digit\u00e1ln\u00ed slo\u017eku\u00ae?",
		"remove_folder_success_message": {},
		"copy_folder_success_message": {},
		"move_folder_success_message": "jedna nebo v\u00edce digit\u00e1ln\u00edch slo\u017eek\u00ae byla \u00fasp\u011b\u0161n\u011b p\u0159esunuta",
		"search_result": "v\u00fdsledek vyhled\u00e1v\u00e1n\u00ed",
		"search_no_result": "nebylo nalezeno \u017e\u00e1dn\u00e9 {label}",
		"folder_limit_reached": "V r\u00e1mci tarifu Free (zdarma) je mo\u017en\u00e1 pouze jedna slo\u017eka. Aktualizuj pros\u00edm tarif pro dal\u0161\u00ed slo\u017eky. (pouze komer\u010dn\u00ed)",
		"sort_racks": "t\u0159\u00edd\u00ed existuj\u00edc\u00ed police, nap\u0159. abecedn\u00ed",
		"save_release": "Save release",
		"search_shelf": "Search rack",
		"released_message": "Released at {date} by {name}.",
		"display_type_folder": "Folder",
		"display_type_brochure": "Brochure",
		"display_type": "Display type of the digital folder",
		"folder_general_tab": "General",
		"folder_advanced_tab": "Advanced settings",
		"display_order_numbers": "Display numbers in table of contents",
		"merge": "Merge",
		"merge_success": "Both folders were successfully merged",
		"statistics": "Statistics",
		"statistics_reset": "reset statistics",
		"statistics_reset_message": "Do you like to reset statistics?",
		"statistics_reset_success": "The statistics has been successfully reset.",
		"statistics_folder_open_count": "Count of folder openings",
		"statistics_folder_open_first": "First time opened at",
		"statistics_folder_open_last": "Last time opened at",
		"statistics_folder_last_reset": "Last reset at",
		"context_menu_copy_only_template": "Copy only structure",
		"context_menu_copy_with_content": "Copy with cover images",
	},
	"permissions": {
		"global": "glob\u00e1ln\u00ed opr\u00e1vn\u011bn\u00ed",
		"teams": "t\u00fdm",
		"permissions": "{}",
		"plans": "vy\u00fa\u010dtov\u00e1n\u00ed",
		"show_dashboard_ui": "zobrazit p\u0159ehled \/ kokpit",
		"desc_show_dashboard_ui": "umo\u017e\u0148uje u\u017eivateli zobrazit kokpit a p\u0159e\u010d\u00edst si v\u0161echny d\u016fle\u017eit\u00e9 informace o digit\u00e1ln\u00ed slo\u017ece\u00ae",
		"can_manage_organisation": "administrovat informace o spole\u010dnosti",
		"desc_can_manage_organisation": "umo\u017e\u0148uje u\u017eivateli zm\u011bnit logo spole\u010dnosti, webovou str\u00e1nku a adresu",
		"can_manage_users": "administrovat u\u017eivatele",
		"desc_can_manage_users": "umo\u017e\u0148uje u\u017eivateli pozvat dal\u0161\u00ed u\u017eivatele",
		"can_manage_teams": "administrovat t\u00fdmy",
		"desc_can_manage_teams": "umo\u017e\u0148uje u\u017eivateli vytvo\u0159it nov\u00fd t\u00fdm a pozvat nov\u00e9 u\u017eivatele",
		"can_manage_permissions": "administrovat opr\u00e1vn\u011bn\u00ed",
		"desc_can_manage_permissions": {},
		"can_manage_plans": "administrovat faktura\u010dn\u00ed \u00fadaje",
		"desc_can_manage_plans": "umo\u017e\u0148uje u\u017eivateli ur\u010dit jin\u00e9 platebn\u00ed metody, zm\u011bnit tarif a zobrazit \u00fa\u010dty"
	},
	"mediacenter": {
		"search_container": "prohledat kontejner",
		"search_mediacenter": "prohledat medi\u00e1ln\u00ed centrum",
		"mediacenter": "medi\u00e1ln\u00ed centrum",
		"containers": "kontejner na m\u00e9dia",
		"create_container": "nov\u00fd kontejner",
		"create_new_container": "vytvo\u0159it nov\u00fd kontejner",
		"edit_existing_container": "kontejner upravovat",
		"save_container": "kontejner vytvo\u0159it",
		"container_name_placeholder": "sem zadej n\u00e1zev kontejneru",
		"create_object": "nov\u00fd medi\u00e1ln\u00ed objekt",
		"update_object": "medi\u00e1ln\u00ed objekt upravovat",
		"save_object": "vytvo\u0159it objekt",
		"container_name": "n\u00e1zev kontejneru",
		"media_name": "n\u00e1zev m\u00e9dia",
		"media_name_placeholder": "sem zadej n\u00e1zev m\u00e9di\u00ed",
		"container_color": "barva kontejneru",
		"description": "popis",
		"description_placeholder": "sem zadej popis",
		"object_type": "typ objektu",
		"file_upload": "nahr\u00e1t soubor",
		"file": "medi\u00e1ln\u00ed soubor",
		"file_upload_description": "soubor ulo\u017eit nebo prohledat",
		"url": "z.B. https:\/\/google.de",
		"website_url": "odkaz",
		"objects": "objekt | objekty",
		"object_name_placeholder": "sem zadej n\u00e1zev m\u00e9di\u00ed",
		"container_with_name_exists": "kontejner m\u00e9di\u00ed s t\u00edmto n\u00e1zvem ji\u017e existuje",
		"remove_container_confirm_message": "opravdu chce\u0161 odstranit kontejner m\u00e9di\u00ed?",
		"remove_container_confirm_title": "kontejner m\u00e9di\u00ed smazat",
		"container_not_found": "kontejner m\u00e9di\u00ed ji\u017e neexistuje",
		"remove_container_success_message": "kontejner m\u00e9di\u00ed byl \u00fasp\u011b\u0161n\u011b odebr\u00e1n",
		"save_container_success_message": "kontejner m\u00e9di\u00ed byl \u00fasp\u011b\u0161n\u011b ulo\u017een",
		"save_object_success_message": "medi\u00e1ln\u00ed objekt byl \u00fasp\u011b\u0161n\u011b ulo\u017een",
		"save_objects_success_message": "jeden nebo v\u00edce medi\u00e1ln\u00edch objekt\u016f byl \u00fasp\u011b\u0161n\u011b ulo\u017een",
		"remove_object_confirm_message": "opravdu chce\u0161 odstranit medi\u00e1ln\u00ed objekt?",
		"remove_object_confirm_title": "medi\u00e1ln\u00ed objekt odstranit",
		"remove_object_success_message": "jeden nebo v\u00edce medi\u00e1ln\u00edch objekt\u016f byl \u00fasp\u011b\u0161n\u011b odstran\u011bn",
		"media_not_found": "medi\u00e1ln\u00ed objekt nebyl nalezen",
		"not_downloadable": "medi\u00e1ln\u00ed objekt nen\u00ed soubor dat, a proto jej nelze st\u00e1hnout",
		"move_object": "jeden nebo v\u00edce medi\u00e1ln\u00edch objekt\u016f p\u0159esunout",
		"move_object_success_message": "jeden nebo v\u00edce medi\u00e1ln\u00edch objekt\u016f byl \u00fasp\u011b\u0161n\u011b p\u0159esunut",
		"file_radio": "soubor dat",
		"link_radio": "odkaz",
		"object_data": "data objektu",
		"references": "pou\u017eit\u00ed",
		"references_empty": "tento medi\u00e1ln\u00ed objekt nebyl pou\u017eit v \u017e\u00e1dn\u00e9 digit\u00e1ln\u00ed slo\u017ece\u00ae",
		"append_object": "p\u0159i\u0159a\u010f pros\u00edm medi\u00e1ln\u00ed objekt",
		"append_name": "zadej pros\u00edm n\u00e1zev m\u00e9dia",
		"media_in_use": "Medi\u00e1ln\u00ed objekt nelze odstranit. V sou\u010dasn\u00e9 dob\u011b se pou\u017e\u00edv\u00e1 v digit\u00e1ln\u00ed slo\u017ece\u00ae.",
		"size_exceeded": "Bylo dosa\u017eeno limitu \u00falo\u017ei\u0161t\u011b. Pros\u00edm aktualizuj tarif.",
		"media_object_limit_type": "V bezplatn\u00e9m (Free) a z\u00e1kladn\u00edm (Basic) tarifu nejsou povoleny \u017e\u00e1dn\u00e9 odkazy. Pros\u00edm aktualizuj tarif. (pouze komer\u010dn\u00ed)",
		"select_container": "nejprve pros\u00edm vyber kontejner m\u00e9di\u00ed",
		"container_with_media_in_use": "Kontejner m\u00e9di\u00ed nelze odstranit. V digit\u00e1ln\u00ed slo\u017ece\u00ae je pou\u017eit alespo\u0148 jeden medi\u00e1ln\u00ed soubor.",
		"invalid_url": "zadan\u00fd odkaz je neplatn\u00fd nebo jej nelze dos\u00e1hnout",
		"thumbnail_processing": "vytv\u00e1\u0159\u00ed se n\u00e1hled \u2026",
		"sort_containers": "t\u0159\u00edd\u00ed kontejnery medi\u00ed, nap\u0159. abecedn\u00ed",
		"change_media_advice": {},
		"thumbnail": "n\u00e1hled",
		"filetype_mismatch": "typ souboru dat nen\u00ed povolen pro miniatury, obr\u00e1zkov\u00e9 str\u00e1nky nebo fronty slo\u017eek",
		"select_from_mediacenter": "Select from mediacenter",
		"limit_exceeded": "Your file is too big. We only support 25MB each file.",
		"no_media_found": "No media objects found",
		"from_mediacenter": "from media center",
		"container_data": "Containerdaten",
		"share_container": "Share",
		"released": "shared",
		"unable_to_update_released_container": "You are not allowed to edit a shared media container",
		"unable_to_update_info": "This media container has been released by team: {team} and you are not allowed to edit it.",
		"uneditable_shared_media": "Shared media objects can only changed by creators",
		"upload_activity": "Just a moment - your files will be uploaded.",
		"select_from_clipboard": "Paste from clipboard"
	}
}

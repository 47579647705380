<template>
    <SidebarLayout :title="$t('settings.security')">
        <Loader v-if="loading" />
        <b-card>
            <b-columns>
                <b-column size="is-6" offset="is-offset-3">
                    <div class="subtitle">{{ $t('settings.password_change') }}</div>
                    <div class="description">
                        {{ $t('settings.description') }}
                    </div>
                    <b-field
                        class="mt-2"
                        :label="$t('settings.old_password')">
                        <b-input
                            type="password"
                            v-model="data.old_password"
                        />
                    </b-field>
                    <b-field
                        class="mt-2"
                        :label="$t('settings.new_password')">
                        <b-input
                            type="password"
                            v-model="data.password"
                        />
                    </b-field>
                    <b-field
                        class="mt-2"
                        :label="$t('settings.confirm')">
                        <b-input
                            type="password"
                            v-model="data.password_confirm"
                        />
                    </b-field>
                </b-column>
            </b-columns>
        </b-card>
        <b-columns>
            <b-column>
                <b-button
                    class="mt-1"
                    type="is-success"
                    :loading="loading"
                    :disabled="loading"
                    @click.prevent="save">
                    {{ $t('settings.save_changes') }}
                </b-button>
            </b-column>
        </b-columns>
    </SidebarLayout>
</template>

<script>
	import SidebarLayout from '../../components/settings/SidebarLayout'
    import Loader from '../../components/global/Loader'
    import APIFactory from '../../api/factory'
    import {mapGetters} from 'vuex'
	import Helpers from '../../helpers';

	export default {
		computed: {
			...mapGetters({
                userId: 'auth/userId'
            })
        },
		data() {
			return {
				data: {},
                loading: false,
            }
        },
        methods: {
			save() {
                this.loading = true
				APIFactory.changePassword(this.userId, {
                    password_confirm: this.data.password_confirm,
					old_password: this.data.old_password,
                    password: this.data.password,
                }).then(res => {
                	if (res && res.success) {
					    Helpers.callToast('is-success', this.$t('settings.change_password_successful'))
                    }
                }).finally(() => this.loading = false)
            }
        },
		components: {
			SidebarLayout,
			Loader,
		}
	}
</script>

<template>
    <SidebarLayout :title="$t('settings.invoices')">
        <b-columns>
            <b-column>
                <b-message type="is-warning">{{ $t('settings.invoice_info') }}</b-message>
                <b-card>
                    <Table
                        :data="data"
                        :headers="header">
                        <template slot-scope="row">
                            <b-tooltip
                                type="is-primary"
                                v-if="row.row.pdf"
                                :label="$t('tooltips.download')">
                                <b-button
                                    icon-pack="fas"
                                    type="is-primary"
                                    icon-left="download"
                                    @click.prevent="open(row.row.pdf)"
                                />
                            </b-tooltip>
                            <b-tooltip
                                type="is-warning"
                                v-if="row.row.status !== 'draft' && row.row.status !== 'paid'"
                                :label="$t('tooltips.pay_now')">
                                <b-button
                                    class="ml-1"
                                    icon-pack="far"
                                    type="is-warning"
                                    icon-left="credit-card"
                                    @click.prevent="open(row.row.invoice_url)"
                                />
                            </b-tooltip>
                        </template>
                    </Table>
                </b-card>
            </b-column>
        </b-columns>
    </SidebarLayout>
</template>

<script>
	import SidebarLayout from '../../components/settings/SidebarLayout'
    import APIFactory from '../../api/factory'
	import Table from '../../components/Table'
    import Headers from '../../data/headers'

	export default {
		data() {
			return {
				data: [],
        loading: false,
				header: Headers.invoices
            }
        },
        created() {
			this.load()
        },
		methods: {
			load() {
				this.loading = true
				APIFactory.findInvoices(this.$route.params.slug).then(res => {
					if (res && res.success) {
						this.data = res.data.map(item => {
							return {
								...item,
                translatedStatus: this.$t('settings.invoice_status_' + item.status),
                amount: this.$options.filters['price'](item.amount),
                date: this.$options.filters['date'](item.date)
              }
            })
          }
                }).finally(() => this.loading = false)
            },
            open(link) {
				window.open(link, '_blank')
            }
        },
		components: {
			Table,
			SidebarLayout,
		}
	}
</script>

<template>
  <div class="c__qr-code-modal" v-show="active">
    <div class="c__qr-code-window">
      <div class="c-modal-header">
        <div class="c-modal-title">{{ $t('designer.qr_code_download') }}</div>
        <div class="c-modal-close"
             @click.prevent="$emit('close')">
          <Icon src="images/icons/close.svg"/>
        </div>
      </div>
      <div class="c-modal-content">
        <QRCode
          ref="canvas"
          tag="canvas"
          :value="url"
          :options="{ width: 3000, height: 3000 }"
          class="scale-down"
        />
        <a ref="downloader" style="display: none;"/>
      </div>
      <div class="c-modal-buttons">
        <Button
          theme="default"
          :loading="loading"
          @click="$emit('close')"
          :disable-icon-view-only-on-mobile="true">
          {{ $t('general.cancel') }}
        </Button>
        <Button
          @click="downloadQRCode"
          theme="primary"
          :loading="loading"
          :disable-icon-view-only-on-mobile="true">
          {{ $t('designer.qr_code_download') }}
        </Button>
      </div>
    </div>
  </div>
</template>

<script>
  import Icon from '../../global/Icon'
  import QRCode from '@chenfengyuan/vue-qrcode'
  import Button from '../../global/buttons/Button'

  export default {
    props: [
      'active',
      'folder',
    ],
    data() {
      return {
        loading: false,
      }
    },
    computed: {
      url() {
        if (this.folder) {
          return window.location.origin + '/#' + this.folder.url
        }
      },
    },
    methods: {
      downloadQRCode() {
        if (this.$refs.canvas && this.$refs.downloader) {
          let name = this.folder.name
          if (name.indexOf(' ') > -1) {
            while (name.indexOf(' ') > -1) {
              name = name.replace(' ', '_')
            }
          }
          this.$refs.downloader.download = name + ".png";
          this.$refs.downloader.href = this.$refs.canvas.$el.toDataURL("image/png").replace(/^data:image\/[^;]/, 'data:application/octet-stream');
          this.$refs.downloader.click()
        }
      }
    },
    components: {
      Icon,
      QRCode,
      Button
    }
  }
</script>

<style lang="scss" scoped>
  .scale-down {
    transform: scale(.1);
  }

  .c__qr-code-modal {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    position: fixed;
    z-index: 10000000;
    align-items: center;
    justify-content: center;
    background: $darkest-blue-color;

    .c__qr-code-window {
      width: 100%;
      height: 100%;
      display: flex;
      max-width: 880px;
      max-height: 640px;
      border-radius: 8px;
      background: $white;
      flex-direction: column;

      @media (max-width: $breakpoint-mobile) {
        max-width: none;
        max-height: none;
        border-radius: 0;
      }
    }

    .c-modal-header,
    .c-modal-buttons {
      height: 50px;
      display: flex;
      padding: 12px;
      font-size: 20px;
      font-weight: 500;
      word-break: break-all;
      align-content: center;
      justify-content: space-between;
      font-family: Roboto, sans-serif;

      .c-modal-close {
        display: flex;
        cursor: pointer;
        align-items: center;
        justify-content: center;
      }
    }

    .c-modal-buttons {
      height: 60px;
    }

    .c-modal-content {
      height: calc(100% - 110px);
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
</style>

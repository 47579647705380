export default {
    "app": {
        "unknown_error": "Es ist ein unbekannter Fehler aufgetreten. Bitten wenden Sie sich an support@digital-folder.com",
        "name": "digitaler Ordner\u00ae",
        "language_de": "Deutsch",
        "language_en": "Englisch",
        "language_cz": "Tschechisch",
        "language_ru": "Russisch",
        "search_objects": "Medienobjekte",
        "search_containers": "Mediencontainer",
        "search_folders": "Digitale Ordner",
        "search_racks": "Regale",
        "country_germany": "Deutschland",
        "country_austria": "\u00d6sterreich",
        "country_switzerland": "Schweiz"
    },
    "auth": {
        "login": "Anmelden",
        "logout": "Abmelden",
        "user_has_app": "Der Benutzer ist bereits registriert",
        "forget_text": "Falls Du Dein Passwort vergessen hast, kannst du es durch die Angabe der E-Mail Adresse zur\u00fccksetzen lassen. Wir generieren Dir ein zuf\u00e4lliges Passwort und senden es an Deine E-Mail Adresse.",
        "forget": "Passwort zur\u00fccksetzen",
        "user_not_found": "Es existiert kein Benutzer mit der angegebenen E-Mail Adresse",
        "wrong_password": "Der Benutzer existiert nicht oder das Passwort ist falsch",
        "welcome": "Willkommen zur\u00fcck",
        "forgot_success_message": "Dein Passwort wurde erfolgreich zur\u00fcckgesetzt. Wir haben dir eine E-Mail gesendet."
    },
    "signup": {
        "version": "Welche Version passt zu Dir?",
        "monthly_payment": "Monatliche Zahlung",
        "yearly_payment": "J\u00e4hrliche Zahlung",
        "monthly": "monatlich",
        "yearly": "j\u00e4hrlich",
        "favourite_option": "Beliebteste Option",
        "constantly_free": "Dauerhaft kostenlos",
        "each_user": "pro Benutzer \/ Monat",
        "signup_now": "Jetzt registrieren",
        "upgrade_now": "Jetzt Tarif anpassen",
        "free": "Free",
        "basic": "Basic",
        "basic-monthly": "Basic (monatlich)",
        "basic-yearly": "Basic (j\u00e4hrlich)",
        "premium-monthly": "Premium (monatlich)",
        "premium-yearly": "Premium (j\u00e4hrlich)",
        "premium-yearly2": "Premium (j\u00e4hrlich)",
        "coupon-no-cost": "Gutscheintarif (Premium)",
        "premium": "Premium",
        "free_subtitle": "In Ruhe testen",
        "space_each_plan": "{space} GB Cloud-Speicherplatz",
        "free_argument1": "Limitiert auf einen Ordner",
        "free_argument3": "inkl. 30 Tage Premium Tarif-Test",
        "basic_subtitle": "Alle Basisfunktionalit\u00e4ten",
        "basic_argument0": "12 Monate Laufzeit",
        "basic_argument1": "Unbegrenzte Anzahl an Ordnern",
        "basic_argument3": "100% Werbefrei",
        "premium_subtitle": "Das Komplettpaket",
        "premium_argument0": "12 Monate Laufzeit",
        "premium_argument1": "Unbegrenzte Anzahl an Ordnern",
        "premium_argument3": "100% Werbefrei",
        "additional_month_1": "(+{month} kostenfreie Monate)",
        "additional_month_2": "({month} kostenfreie Monate)",
        "selected_plan": "Ihr ausgew\u00e4hlter Tarif",
        "change_plan": "Tarif \u00e4ndern",
        "plan": "Tarif",
        "payment_type": "Zahlungsweise",
        "licenses": "Anzahl der Nutzerlizenzen",
        "today_summary": "Heutiger Gesamtbetrag",
        "summary": "Gesamtbetrag exkl. MwSt.",
        "after_summary": "F\u00e4lliger Betrag exkl. MwSt.",
        "change_after_time": "Nach Ablauf der 12 Monate, werden Sie automatisch in den Tarif \"Free\" zur\u00fcckgestuft.",
        "coupon_code": "Gutscheinnummer",
        "coupon_code_placeholder": "Gutscheinnummer hier eintragen",
        "coupon_invalid": "Der Gutscheincode ist ung\u00fcltig",
        "coupon_valid": "Der Gutscheincode ist g\u00fcltig",
        "personal_heading": "Pers\u00f6nliche Angaben",
        "firstname": "Vorname",
        "fullname": "Vollständiger Name",
        "fullname_placeholder": "Hier deinen vollständigen Namen eintragen",
        "firstname_placeholder": "Hier Vorname eintragen",
        "lastname": "Nachname",
        "lastname_placeholder": "Hier Nachname eintragen",
        "password": "Kennwort",
        "password_placeholder": "Hier Kennwort eintragen",
        "password_confirm": "Kennwort wiederholen",
        "password_personal": "Ihr pers\u00f6nliches Kennwort festlegen",
        "password_personal_placeholder": "Hier Ihr pers\u00f6nliches Kennwort eintragen",
        "email_address": "E-Mail Adresse",
        "email_address_placeholder": "Hier E-Mail Adresse eintragen",
        "personal_information": "Durch Ihre Registrierung stimmen Sie unseren <a class='highlighted' target=\"_blank\" href=\"https:\/\/www.digital-folder.com\/terms\">Nutzungsbedingungen<\/a>\n                            sowie der Speicherung und Verarbeitung Ihrer pers\u00f6nlichen Daten gem\u00e4\u00df unserer\n                            <a class=\"highlighted\" target=\"_blank\" href=\"https:\/\/www.digital-folder.com\/wp-content\/uploads\/2019\/10\/Datenschutzerklaerung.pdf\">Datenschutzrichtlinie<\/a> zu.",
        "continue": "Weiter",
        "back": "Zur\u00fcck",
        "confirm_not_equal": "Die beiden Kennw\u00f6rter stimmen nicht \u00fcber ein",
        "password_needs_several_chars": "Das Passwort muss mindestens 6 Zeichen lang sein, sowie aus einem Klein-\/Gro\u00dfbuchstaben und aus einer Zahl bestehen",
        "company_heading": "Unternehmensinformationen",
        "company": "Name des Unternehmens",
        "company_placeholder": "Hier Namen des Unternehmens eintragen",
        "country": "Land",
        "country_placeholder": "Hier Land eintragen",
        "street": "Stra\u00dfe",
        "street_placeholder": "Hier Stra\u00dfe eintragen",
        "postal_code": "Postleitzahl",
        "postal_code_placeholder": "Hier Postleitzahl eintragen",
        "city": "Ort",
        "vat_id": "USt-ID",
        "vat_id_placeholder": "Hier Umsatzsteuer-ID eintragen",
        "city_placeholder": "Hier Ort eintragen",
        "mediation": "Vermittelt durch",
        "mediation_placeholder": "Hier Vermittlung eintragen",
        "additional_payment_info": "Zus\u00e4tzliche Zahlungsinformationen",
        "payment_heading": "Bezahlmethode",
        "payment_type_card": "Kreditkarte",
        "payment_type_card_info": "Kreditkarteninformationen",
        "payment_type_iban": "SEPA-Lastschrift",
        "payment_type_iban_info": "IBAN",
        "payment_type_iban_info_text": " Ich erteile digital optimisation \u2013 gro\u00df & partner ein SEPA-Lastschriftmandar f\u00fcr obiges Bankkonto. Ich kann die Erstattung einer Lastschrift innerhalb von 8 Wochen verlangen, nachdem der Betrag belastet wurde.",
        "payment_type_sofort": "Sofort",
        "payment_type_sofort_info": "",
        "payment_type_sofort_info_text": "By providing your payment information and confirming this payment, you authorise (A) Christoph Groß and Stripe, our payment service provider, to send instructions to your bank to debit your account and (B) your bank to debit your account in accordance with those instructions. As part of your rights, you are entitled to a refund from your bank under the terms and conditions of your agreement with your bank. A refund must be claimed within 8 weeks starting from the date on which your account was debited. Your rights are explained in a statement that you can obtain from your bank. You agree to receive notifications for future debits up to 2 days before they occur.",
        "summary_heading": "Zusammenfassung der Beauftragung",
        "price_incl_vat": "Preis exkl. MwSt.",
        "cost": "Kosten",
        "terms_company": "Ich best\u00e4tige, dass ich mich als Unternehmen registriere.",
        "terms_of_service": "Ich stimme den <a href=\"https:\/\/www.digital-folder.com\/terms\" target=\"_blank\" class=\"highlighted\">Allgemeinen Gesch\u00e4ftsbedingungen<\/a> zu.",
        "order_data_processing": "Ich stimme der <a href=\"https:\/\/www.digital-folder.com\/wp-content\/uploads\/2019\/10\/ADV-digital-optimisation.pdf\" target=\"_blank\" class=\"highlighted\">Aufragsdatenverarbeitung<\/a> zu.",
        "signup_success": "Du hast Dich erfolgreich f\u00fcr den digitalen Ordner\u00ae registriert. Bitte best\u00e4tige erst dein Konto.",
        "welcome_message": "Du wurdest eingeladen Dich f\u00fcr den digitalen Ordner\u00ae zu registrieren um im Team mitarbeiten zu k\u00f6nnen.",
        "invite_not_found": "Die Einladung ist leider abgelaufen",
        "upgrade_success": "Dein Konto wurde erfolgreich aktualisiert.",
        "current_plan": "Aktueller Tarif",
        "coupon_exists": "Gutscheinnummer vorhanden? Hier klicken",
        "coupon_invalid_date": "Der Gutscheincode ist leider abgelaufen.",
        "coupon_already_used": "Der Gutscheincode wurde bereits benutzt",
        "company_signup_tooltip": "Du best\u00e4tigts hiermit, dass Du die Nutzung des digitalen Ordner\u00aes\u00ae als Unternehmen und nicht als Privatperson vornimmst. Die Nutzung ist nur gewerblich vorgesehen.",
        "verified": "Du hast dein Konto erfolgreich verifiziert. Du kannst dich nun einloggen.",
        "account_not_verified": "Dein Konto konnte nicht verifiziert werden. Wende dich bitte an support@digital-folder.com",
        "not_verified": "Dein Konto ist noch nicht verifiziert. Du hast von uns eine E-Mail erhalten mit der du dich verifizieren kannst.",
        "countries_soon": "Aktuell steht nur Deutschland, \u00d6sterreich und der Schweiz zur Verf\u00fcgung. Wir erweitern bald unsere unterst\u00fctzen L\u00e4nder. Wir bitten dich um etwas Geduld.",
        "company_advice": "(nur bei Anmeldung als Unternehmen)",
        "licenses_info": "Bitte die Gesamtanzahl an Lizenzen eingeben",
        "downgrade_disabled": "Aktuell ist der Downgrade Mechanismus deaktiviert. Bitte k\u00fcndige den Tarif und w\u00e4hle dann den gew\u00fcnschten Tarif. Alternativ kontaktiere bitte support@digital-folder.com",
        "safe_payment_options": "Sichere Zahlungsoptionen",
        "secure_ssl": "SSL Schutz",
        "email_support": "24h E-Mail Support",
        "hosting_in_germany": "Hosting in Deutschland",
    },
    "general": {
        "hello": "Hallo,",
        "greeting": "wie können wir Dir weiterhelfen?",
        "captcha_question": "Lösen Sie bitte die folgende mathematische Aufgabe",
        "captcha_answer": "Bitte tragen Sie hier das Ergebnis ein",
        "thank": "Vielen Dank,",
        "answer_soon": "wir melden uns in Kürze.",
        "send": "Senden",
        "help": "Hilfe",
        "email": "E-Mail",
        "password": "Passwort",
        "password_confirm": "Passwort wiederholen",
        "validation_failed": "Bitte f\u00fcllen Sie die folgenden Felder: ",
        "invalid_form": "Es wurden nicht alle Pflichtfelder ausgef\u00fcllt",
        "firstname": "Vorname",
        "lastname": "Nachname",
        "language": "Sprache",
        "department": "Abteilung",
        "save": "Speichern",
        "actions": "Aktionen",
        "name": "Name",
        "role": "Rolle",
        "roles": "Rollen",
        "description": "Beschreibung",
        "optional": "optional",
        "status": "Status",
        "cancel": "Abbrechen",
        "confirm": "Best\u00e4tigen",
        "no_permissions": "Du hast keine Berechtigung dies zu tun",
        "yes": "Ja",
        "no": "Nein",
        "ok": "o.k.",
        "no_app_found": "Diese Instanz des digitalen Ordner\u00aes existiert nicht mehr oder wurde geblockt. Wende Dich bitte an support@digital-folder.com",
        "app_blocked": "Die Anwendung wurde durch den Administrator gesperrt",
        "no_team_found": "Dieses Team existiert nicht (mehr) oder der Benutzer ist diesem Team nicht zugeordnet.",
        "unauthenticated": "Melden Sie sich bitte erneut an. Gegebenenfalls sind Sie noch an einem anderen Computer angemeldet",
        "invited_at": "Eingeladen am",
        "accepted_at": "Angenommen an",
        "message": "Nachricht",
        "message_placeholder": "Deine Nachricht"
    },
    "display": {
        "help": "Hilfe",
        "open": "Ordner \u00f6ffnen",
        "wrong_password": "Das Kennwort f\u00fcr den freigegebenen Ordner ist nicht korrekt",
        "description_1": "Sie haben einen Link zum \u00d6ffnen eines digitalen Ordner\u00aes aufgerufen.",
        "description_2": "Um den Inhalt zu sehen, gebe bitte nachfolgend das Dir mitgeteilte Kenntwort ein.",
        "description_3": "Leider existiert dieser digitale Ordner nicht (mehr). Der Eigent\u00fcmer hat den digitalen Ordner\u00ae gegebenenfalls kurzfristig gesperrt.",
        "no_folder_found_headline": "Es tut uns leid...",
        "request_password": "Sie haben das Kennwort vergessen?",
        "email_subject": "Kennwortanfrage",
        "menu_open": "Inhaltsverzeichnis anzeigen",
        "menu_hide": "Inhaltsverzeichnis verbergen",
        "close": "Ordner schließen",
        "show_new_elements": "Neuerungen anzeigen",
        "hide_new_elements": "Neuerungen verstecken",
        "trial_period": "Jetzt 30 Tage kostenlos testen",
        "create_link": "Link zur Seite kopieren",
        "create_link_no_release": "Um einen Link zu erstellen, muss der digitale Ordner freigegeben sein.",
    },
    "settings": {
        "account": "Konto",
        "dashboard": "\u00dcberblick",
        "profile": "Benutzerprofil",
        "security": "Sicherheit",
        "organization": "Organisation",
        "company": "Unternehmen",
        "users": "Benutzer",
        "teams": "Teams",
        "team": "Team",
        "website": "Webseite",
        "vat_number": "USt-ID",
        "permissions": "Rollen & Berechtigungen",
        "accounting": "Abrechnung",
        "plans": "Tarifwechsel",
        "settings": "Einstellungen",
        "superadmin": "Superadministration",
        "payments": "Zahlungsmethode",
        "invoices": "Rechnungen",
        "back": "Zur\u00fcck zum digitalen Ordner\u00ae",
        "password_change": "Passwort \u00e4ndern",
        "user_not_found": "Der Benutzer konnte nicht gefunden werden",
        "description": "Hier k\u00f6nnen Sie Ihr Passwort zur\u00fccksetzen. Durch die Eingabe des alten Passworts und die Wiederholung des neuen Passworts \u00e4ndern wir Ihr Passwort.",
        "old_password": "Aktuelles Passwort",
        "new_password": "Neues Passwort",
        "confirm": "Passwort wiederholen",
        "save_changes": "\u00c4nderungen speichern",
        "members_count": "Anzahl der Team Mitglieder",
        "invite_member": "Team Mitglied einladen",
        "invite_user": "Benutzer einladen",
        "invite_description": "In diesem Dialog k\u00f6nnen weitere Mitglieder zum digitalen Ordner\u00ae eingeladen werden. Unter der Angabe der E-Mail Adresse k\u00f6nnen diese auch direkt einem Team zugeordnet werden. Falls der Benutzer noch nicht im System vorhanden ist, wird dieser mit mithilfe einer E-Mail benachrichtigt, dass er eingeladen wurde.",
        "create_team": "Neues Team",
        "change_team": "Team wechseln",
        "change_role": "Rolle wechseln",
        "invoice_number": "Rechnungsnummer",
        "invoice_date": "Rechnungsdatum",
        "invoice_sum": "Betrag",
        "create_role": "Neue Rolle",
        "create_payment_method": "Neue Zahlungsmethode",
        "permissions_for": "Berechtigungen f\u00fcr",
        "credentials": "Kontoinformation",
        "default_method": "Standardzahlmethode",
        "change_password_successful": "Das Passwort wurde erfolgreich ge\u00e4ndert",
        "change_avatar_successful": "Das Profilbild wurde erfolgreich ge\u00e4ndert",
        "status_active": "Aktiv",
        "status_blocked": "Blockiert",
        "status_invited": "Eingeladen",
        "status_trialing": "Testphase",
        "role_admin": "Administrator",
        "role_user": "Benutzer",
        "block_user": "Benutzer sperren",
        "unblock_user": "Benutzer entsperren",
        "block_description": "M\u00f6chten Sie diesen Benutzer wirklich blockieren?",
        "unblock_description": "M\u00f6chten Sie diesen Benutzer wirklich entsperren?",
        "select_team": "W\u00e4hlen Sie ein Team aus",
        "team_exists": "Das Team mit diesem Namen existiert bereits",
        "delete_team": "Team l\u00f6schen",
        "delete_team_description": "M\u00f6chten Sie dieses Team wirklich l\u00f6schen?",
        "leave_team": "Team verlassen",
        "leave_team_description": "M\u00f6chten Sie dieses Team wirklich verlassen?",
        "team_cant_delete": "Das Team kann nicht gel\u00f6scht werden, da noch Benutzer zugeordnet sind",
        "team_has_folders": "Das Team kann nicht gel\u00f6scht werden, da es noch digitale Ordner oder Regale beinhaltet",
        "team_not_found": "Das Team konnte nicht gefunden werden",
        "team_removed_successful": "Das Team wurde erfolgreich gel\u00f6scht",
        "team_leave_successful": "Das Team wurde erfolgreich verlassen",
        "team_members_empty": "Das Team besitzt keine Mitglieder",
        "role_exists": "Die Rolle mit dem angegebenen Namen existiert bereits",
        "payments_empty": "Es wurden noch keine Zahlungsmethode hinterlegt",
        "default_payment_successful": "Die Zahlungsmethode wurde als Standardmethode hinterlegt",
        "payment_method_not_found": "Die Zahlungsmethode wurde nicht gefunden",
        "updated_role_successful": "Die Berechtigungen wurden erfolgreich gespeichert",
        "role_not_found": "Die Rolle konnte nicht gefunden werden",
        "delete_role": "Rolle entfernen",
        "delete_role_description": "M\u00f6chtet Du diese Rolle wirklich l\u00f6schen?",
        "role_removed_successful": "Die Rolle wurde erfolgreich gel\u00f6scht",
        "role_is_used": "Die Rolle ist mindestens einem Benutzer zugeordnet und kann daher nicht gel\u00f6scht werden",
        "user_has_been_invited_already": "Der Benutzer wurde bereits eingeladen",
        "invited_successful": "Der Benutzer wurde erfolgreich eingeladen",
        "members_of": "Mitglieder von",
        "team_kick_successful": "Der Benutzer wurde erfolgreich aus dem Team entfernt",
        "team_kick_description": "Der Benutzer wird aus dem Team entfernt und kann nicht mehr auf die Inhalte des Teams zugreifen. M\u00f6chtest Du den Benutzer wirklich aus dem Team entfernen?",
        "team_kick": "Aus dem Team entfernen",
        "organisation_saved_successful": "Die Unternehmensinformationen wurden erfolgreich gespeichert",
        "invoice_status_draft": "In Bearbeitung (ca. 1h)",
        "invoice_status_open": "Offen",
        "invoice_status_paid": "Bezahlt",
        "tile_folder": "Digitale Ordner",
        "tile_objects": "Medienobjekt | Medienobjekte",
        "tile_users": "Benutzer",
        "tile_space": "Verf\u00fcgbarer Speicherplatz",
        "tile_user_contingent": "Benutzerkontingent",
        "tile_user_out_of_max_users": "{users} von {max_users} Benutzern",
        "tile_space_out_of_space": "{space} von {max_space} GB",
        "tile_plan": "Tarif",
        "users_exceeded": "Das Benutzerlimit ist erreicht. Bitte weitere User per Upgrade hinzubuchen.",
        "cancel_subscription": "Abo k\u00fcndigen",
        "cancel_subscription_success_message": "Die K\u00fcndigung wurde vorgemerkt. Du kannst bis zum Ablauf der Zeit den digitalen Ordner\u00ae noch wie gewohnt nutzen.",
        "back_to_digital_folder": "Zur\u00fcck zur Regal\/Medien\u00fcbersicht",
        "help": "Hilfe",
        "language_chooser": "Sprachauswahl",
        "remove_user": "Benutzer entfernen",
        "remove_user_message": "M\u00f6chtest Du den Benutzer wirklich entfernen?",
        "user_can_not_deleted": "Der Benutzer kann nicht entfernt werden, da es mindestens einen Administrator geben muss.",
        "user_is_app_owner": "Der Benutzer kann nicht entfernt werden, da er der Eigent\u00fcmer ist.",
        "change_default_payment_method": "Diese Zahlmethode zum Standard machen",
        "send_invitation": "Einladung erneut versenden",
        "send_invitation_successful": "Die Einladung wurde erneut versendet",
        "user_can_not_blocked": "Der Benutzer kann nicht blockiert werden, da es mindestens 1 Administrator geben muss",
        "cancel_subscription_message": "M\u00f6chtest du wirklich das Abo k\u00fcndigen?",
        "plan_info": "W\u00e4hlen Sie eine der Tarifoptionen, um ihren digitalen Ordner\u00ae upzugraden oder mehr Benutzer\/Cloudspeicher zu erhalten. ",
        "subscription_runtime": "Das Abonnement l\u00e4uft von {start_date} bis {end_date} inkl. {discount} Monate (kostenlos) (Zahlungsmodell: {payment_interval})",
        "subscription_runtime_coupon": "Das Abonnement l\u00e4uft von {start_date} bis {end_date} inkl. {discount} Monate (kostenlos + kostenpflichtig) (Zahlungsmodell: {payment_interval})",
        "subscription_canceled_at": "Der Vertrag wurde gek\u00fcndigt am: ",
        "status_needs_confirmation": "Eingeladen (Bestätigung ausstehend)",
        "notifications": "Benachrichtigungen",
        "invite_message": "Sie wurden von {company} in ein oder mehrere Teams ({teams}) eingeladen. Möchten Sie die Einladung akzeptieren?",
        "no_notifications": "Keine Benachrichtigungen",
        "invoice_info": "",
        "invite_support_description": "Laden Sie die E-Mail support@digital-folder.com ein um den Support-Zugriff auf Ihr System zu gewähren.",
        "invite_account_status_based": "Lade beliebige externe Benutzer ein um bei Euch im Team mitzuarbeiten. Einfach E-Mail erfassen. Der/die Eingeladene erhält dann einen Einladungslink per Mail.\n" +
          "Sollte der Benutzer bereits einen Premium Konto haben, so wird dessen User Lizenz herangezogen. Sollte dieser keine haben, wird die User Lizenz von Deinem Kontingent abgezogen. (also ggf. Aufstockung notwendig)\n" +
          "(nur mit einem Premium Konto möglich)",
    },
    "tooltips": {
        "remove_member": "Entfernt dieses Mitglied aus dem Team",
        "leave_team": "Du verl\u00e4\u00dft das Team und hast damit keinen Zugriff mehr auf die digitalen Ordner\u00ae und Medien",
        "change_role": "Hier kannst Du die Rolle des Benutzers festlegen",
        "edit_user": "Benutzer bearbeiten",
        "pay_now": "Jetzt bezahlen",
        "download": "Diese Rechnung jetzt herunterladen",
        "block_user": "Diesen Benutzer blockieren, damit wird der Zugang zum System verwehrt",
        "unblock_user": "Aktiviert diesen Benutzer, damit er Zugang zum System erh\u00e4lt",
        "permissions": "\u00c4ndert die Berechtigung f\u00fcr diese Rolle",
        "delete_role": "Entfernt diese Rolle"
    },
    "designer": {
        "page": "Seite",
        "chapter": "Kapitel",
        "designer": "Ordnerdesigner",
        "comment_placeholder": "Ordnernotiz hier eintragen",
        "expand_all": "Alle aufklappen",
        "collapse_all": "Alle zuklappen",
        "new_chapter": "Neues Kapitel",
        "new_page": "Neue Seite anlegen",
        "page_title": "Seitennamen eintragen",
        "chapter_title": "Kapitelname eintragen",
        "remove_page": "Seite l\u00f6schen",
        "remove_chapter": "Kapitel l\u00f6schen",
        "background_image": "Hintergrundbild",
        "search_mediacenter": "Mediencenter durchsuchen",
        "drop_file": "Datei ablegen oder",
        "media_center_search": "Auswahl durchsuchen",
        "selected_files": "Dateien ausgew\u00e4hlt",
        "append_files": "Platzieren",
        "folder_not_found": "Der digitale Ordner konnte nicht gefunden werden",
        "all_media_containers": "Alle Mediencontainer",
        "cover": "Ordnercover",
        "unnamed_chapter": "Unbenanntes Kapitel",
        "unnamed_page": "Unbenannte Seite",
        "comment_save_message": "Die Notiz wurde erfolgreich gespeichert",
        "comments": "Ordnernotizen",
        "clock": "Uhr",
        "comment_not_found": "Die Notiz konnte nicht gefunden werden",
        "done": "Erledigt",
        "mark_success_message": "Die Notiz wurde als erledigt gekennzeichnet",
        "done_by": "Erledigt durch {user} am {date}",
        "save_folder_success": "Der digitale Ordner wurde erfolgreich gespeichert",
        "unsaved_content": "M\u00f6chten Sie den Ordnerdesigner verlassen ohne Ihre \u00c4nderungen zu speichern?",
        "discard_changes": "\u00c4nderungen verwerfen",
        "save_changes": "\u00c4nderungen speichern",
        "folder_is_locked": "Der digitale Ordner ist gesperrt und es k\u00f6nnen keine \u00c4nderungen gespeichert werden",
        "preview": "Vorschau",
        "drag_chapter": "Drag- & Dropfunktion nutzen um dieses Kapitel zu verschieben",
        "drag_page": "Dieses Element \u00fcber ein Kapitel oder Seite ziehen, um Sie zu verschieben",
        "back": "Zur\u00fcck zur Ordner\u00fcbersicht",
        "more_file_info": "Mehr Dateiinformationen anzeigen",
        "less_file_info": "Weniger Dateiinformationen anzeigen",
        "cover_tooltip": "Wechsel zwischen Coverbild und Coverfarbe",
        "chapter_active": "Aktives Kapitel",
        "chapter_inactive": "Inaktives Kapitel",
        "comments_not_allowed": "Im Free- & Basis-Tarif sind keine Kommentare erlaubt. Bitte upgrade den Tarif",
        "change_page_layout_1": "Seitenlayout \u00e4ndern - 1 Element",
        "change_page_layout_2": "Seitenlayout \u00e4ndern - 2 Elemente",
        "change_page_layout_4": "Seitenlayout \u00e4ndern - 4 Elemente",
        "change_page_layout_6": "Seitenlayout \u00e4ndern - 6 Elemente",
        "change_object": "Bild anklicken um Mediendatei zu tauschen",
        "image_mode": "Anzeigeformat",
        "image_mode_cover": "Ausf\u00fcllen",
        "image_mode_contains": "B\u00fcndig",
        "page_grid_too_much_items": "Sie haben zu viele Medienobjekte auf der Seite, um das Seitenlayout zu \u00e4ndern. Bitte l\u00f6schen Sie die \u00fcberz\u00e4hligen Objekte.",
        "note_tooltip": "Notiz vorhanden",
        "note_title_write": "Notiz hinzufügen",
        "note_title_read": "Notiz anzeigen",
        "note": "Notiz",
        "note_placeholder": "Hier Notiz eintragen",
        "qr_code": "QR-Code",
        "qr_code_download": "QR-Code herunterladen",
        "media_tooltip_word": "Blendet alle Medienobjekte des Typs 'MS Word' ein/aus",
        "media_tooltip_excel": "Blendet alle Medienobjekte des Typs 'MS Excel' ein/aus",
        "media_tooltip_powerpoint": "Blendet alle Medienobjekte des Typs 'MS Powerpoint' ein/aus",
        "media_tooltip_image": "Blendet alle Medienobjekte des Typs 'Bilder' ein/aus",
        "media_tooltip_file": "Blendet alle Medienobjekte des Typs 'PDF' ein/aus",
        "media_tooltip_link": "Blendet alle Medienobjekte des Typs 'Link' ein/aus",
        "media_tooltip_others": "Blendet alle Medienobjekte des Typs 'Sonstiges' ein/aus",
        "delete_chapter_message": "Möchtest du wirklich dieses Kapitel einschließlich aller Seiten löschen?",
        "delete_page_message": "Möchtest du wirklich diese Seite einschließlich aller Inhalte löschen?",
        "change_folder_background": "Farb- oder Bildhintergrund wechseln",
        "select_background_color": "Hintergrundfarbe auswählen",
        "select_background_image": "Aus dem Mediencenter auswählen",
        "reset_background": "Hintergrundfarbe und -bild zurücksetzen",
        "show_as_new": "Als Neu anzeigen",
        "hide_as_new": "Als Neu ausblenden",
    },
    "workspaces": {
        "racks": "Regale",
        "folderNRacks": "Ordner \/ Regale",
        "search": "Alles durchsuchen",
        "recent_used": "Zuletzt verwendet",
        "create_rack": "Neues Regal anlegen",
        "create_folder": "Neuer Ordner",
        "copy": "Kopieren",
        "export": "Exportieren",
        "folder": "Ordner",
        "edit": "Bearbeiten",
        "release": "Freigeben",
        "download": "Herunterladen",
        "show": "Anzeigen",
        "visible": "Sichtbar",
        "copy_link": "Link kopieren",
        "hide": "Verstecken",
        "move": "Verschieben",
        "activate": "Aktivieren",
        "deactivate": "Deaktivieren",
        "delete": "L\u00f6schen",
        "tags": "Schlagw\u00f6rter",
        "edit_shelf": "Regal bearbeiten",
        "create_shelf": "Neues Regal",
        "tag_placeholder": "Schlagw\u00f6rter eintragen",
        "shelf_name_placeholder": "Hier Regalnamen eintragen",
        "save_shelf": "Regal speichern",
        "folder_name": "Ordnername",
        "folder_name_placeholder": "Hier Ordnername eintragen",
        "shelf_name": "Regalname",
        "save_folder": "Ordner speichern",
        "shelf_color": "Regal Farbe",
        "own_hex_value": "HEX Wert der Farbe",
        "cover_color": "Cover Farbe",
        "cover_image": "Cover Bild",
        "designer": "Designer",
        "rack_with_name_exists": "Das Regal mit dem Namen besteht bereits",
        "save_rack_success_message": "Das Regal wurde erfolgreich gespeichert",
        "remove_rack_confirm_title": "Regal l\u00f6schen",
        "remove_rack_confirm_message": "M\u00f6chten Sie wirklich dieses Regal l\u00f6schen?",
        "remove_rack_success_message": "Das Regal wurde erfolgreich gel\u00f6scht",
        "rack_not_found": "Das Regal konnte nicht gefunden werden",
        "rack_has_folders": "Das Regal kann nicht gel\u00f6scht werden, da dieser noch digitale Ordner enth\u00e4lt",
        "sort_latest": "Neuste",
        "sort_last_used": "Zuletzt verwendet",
        "sort_alphabetically": "A-Z",
        "sort_anti_alphabetically": "Z-A",
        "sort_most_comments": "Meiste Notizen",
        "sort_less_used": "Am wenigsten verwendet",
        "sort_most_used": "Am meisten verwendet",
        "sort_shared_containers": "Freigegebene Mediencontainer",
        "status_in_progress": "In Arbeit seit",
        "status_finished": "Fertig gestellt am",
        "status_released": "Freigegeben am",
        "status_locked": "Gesperrt am",
        "status_archived": "Archiviert am",
        "folder_not_found": "Der digitale Ordner konnte nicht gefunden werden",
        "folder_with_name_exists": "Der digitale Ordner mit dem Namen existiert bereits",
        "change_status": "Status \u00e4ndern",
        "context_menu_status_in_progress": "In Arbeit",
        "context_menu_status_finish": "Fertigstellen",
        "context_menu_status_release": "Freigeben",
        "context_menu_status_lock": "Sperren",
        "context_menu_status_archive": "Archivieren",
        "status_changed_successful_message": "Der Status des digitalen Ordner\u00aes wurde erfolgreich ge\u00e4ndert",
        "release_folder": "Ordner freigeben",
        "release_folder_description": "Um Deinen Ordner f\u00fcr andere freizugeben, aktiviere  bitte die Ordnerfreigabe.<br>Optional hast Du die M\u00f6glichkeit einen Passwortschutz f\u00fcr Deinen Ordner zu aktivieren. Bei aktiviertem Passwortschutz kann der Ordner nur mit dem vergebenen Passwort angezeigt werden.",
        "activate_password": "Passwortschutz aktivieren",
        "send_credentials": "Hyperlink & Zugangsdaten per E-Mail senden",
        "activate_release": "Freigabe aktivieren",
        "email_subject": "digitaler Ordner\u00ae - Freigabe",
        "password_placeholder": "Hier Passwort eintragen",
        "email_message": "Link zum Ordner: {link}\n",
        "email_password": "Passwort: {password}",
        "copied": "Link kopiert!",
        "save_password": "Passwort \u00e4ndern",
        "remove_folder_confirm_title": "digitaler Ordner\u00ae l\u00f6schen?",
        "remove_folder_confirm_message": "M\u00f6chten Sie diesen digitalen Ordner\u00ae wirklich l\u00f6schen?",
        "remove_folder_success_message": "Der digitale Ordner wurde erfolgreich gel\u00f6scht",
        "copy_folder_success_message": "Der digitale Ordner wurde erfolgreich kopiert",
        "move_folder_success_message": "einer oder mehrere digitale Ordner wurden erfolgreich verschoben",
        "search_result": "Suchergebnis",
        "search_no_result": "Es wurden keine {label} gefunden",
        "folder_limit_reached": "Im Free-Tarif ist nur ein Ordner m\u00f6glich. Bitte upgrade den Tarif f\u00fcr weitere Ordner. (nur gewerblich)",
        "sort_racks": "Sortiert die vorhandenen Regale z.B. alphabetisch",
        "save_release": "Freigabe speichern",
        "search_shelf": "Regal durchsuchen",
        "released_message": "Zuletzt freigegeben am {date} von {name}.",
        "display_type_folder": "Ordner",
        "display_type_brochure": "Broschüre",
        "display_type": "Anzeigeart des digitalen Ordners",
        "folder_general_tab": "Allgemein",
        "folder_advanced_tab": "Erweiterte Einstellungen",
        "display_order_numbers": "Nummerierung im Inhaltsverzeichnis anzeigen",
        "merge": "Merge",
        "merge_success": "Die beiden digitalen Ordner wurden erfolgreich zusammengeführt",
        "statistics": "Statistik",
        "statistics_reset": "Statistik zurücksetzen",
        "statistics_reset_message": "Möchten Sie die Statistik wirklich zurücksetzen?",
        "statistics_reset_success": "Die Statistik wurde erfolgreich zurückgesetzt",
        "statistics_folder_open_count": "Öffnungen",
        "statistics_folder_open_first": "Erstmalig geöffnet am",
        "statistics_folder_open_last": "Zuletzt geöffnet am",
        "statistics_folder_last_reset": "Zuletzt zurückgesetzt",
        "is_folder_template": "Ordner als Vorlage nutzbar?",
        "copyFromTemplate": "Ordnervorlage nutzen",
        "folderTemplates": "Ordnervorlagen",
        "folderTemplate": "Ordnervorlage",
        "context_menu_copy_only_template": "Nur Struktur kopieren",
        "context_menu_copy_with_content": "Mit Cover kopieren",
        "context_menu_copy_with_full_content": "Synchronisierter Ordner",
        "folder_template_global": "Globale Vorlage",
        "folder_template_team": "teaminterne Vorlage",
        "folder_template_app": "teamübergreifende Vorlage",
        "no_folder_template": "Nicht als Vorlage nutzen"
    },
    "permissions": {
        "global": "Globale Berechtigungen",
        "teams": "Team",
        "permissions": "Rollen & Berechtigungen",
        "plans": "Abrechnung",
        "show_dashboard_ui": "\u00dcberblick\/Cockpit anzeigen",
        "desc_show_dashboard_ui": "Erlaubt es dem Benutzer das Cockpit anzuzeigen und alle wichtigen Informationen \u00fcber den digitalen Ordner\u00ae abzulesen",
        "can_manage_organisation": "Unternehmensinformationen administrieren",
        "desc_can_manage_organisation": "Erlaubt es dem Benutzer, das Logo, die Webseite und Addresse des Unterenehmens zu \u00e4ndern",
        "can_manage_users": "Benutzer verwalten",
        "desc_can_manage_users": "Erlaubt es dem Benutzer weitere Benutzer einzuladen",
        "can_manage_teams": "Teams verwalten",
        "desc_can_manage_teams": "Erlaubt es dem Benutzer ein neues Team zu erstellen und neue Benutzer einzuladen",
        "can_manage_permissions": "Berechtigungen verwalten",
        "desc_can_manage_permissions": "Erlaubt es dem Benutzer, die Berechtigungen f\u00fcr Rollen zu verwalten. Au\u00dferdem darf der Benutzer neue Rollen erstellen",
        "can_manage_plans": "Abrechnungsdetails verwalten",
        "desc_can_manage_plans": "Erlaubt es dem Benutzer, weitere Zahlungsmethoden anzugeben, den Tarif zu wechseln und die Rechnungen anzusehen"
    },
    "mediacenter": {
        "search_container": "Container durchsuchen",
        "search_mediacenter": "Mediencenter durchsuchen",
        "mediacenter": "Mediencenter",
        "containers": "Mediencontainer",
        "create_container": "Neuen Container",
        "create_new_container": "Neuen Container erstellen",
        "edit_existing_container": "Container bearbeiten",
        "save_container": "Container erstellen",
        "container_name_placeholder": "Hier Containername eintragen",
        "create_object": "Neues Medienobjekt",
        "update_object": "Medienobjekt bearbeiten",
        "save_object": "Objekt anlegen",
        "container_name": "Containername",
        "container_data": "Containerdaten",
        "media_name": "Medienname",
        "media_name_placeholder": "Hier Medienname eintragen",
        "container_color": "Container Farbe",
        "description": "Beschreibung",
        "description_placeholder": "Beschreibung hier eintragen",
        "object_type": "Objekttyp",
        "file_upload": "Datei Upload",
        "file": "Mediendatei",
        "file_upload_description": "Datei ablegen oder durchsuchen",
        "url": "z.B. https:\/\/google.de",
        "website_url": "Link",
        "objects": "Objekt | Objekte",
        "object_name_placeholder": "Hier Medienname eintragen",
        "container_with_name_exists": "Ein Mediencontainer mit diesem Namen existiert bereits",
        "remove_container_confirm_message": "M\u00f6chtest Du den Mediencontainer wirklich l\u00f6schen?",
        "remove_container_confirm_title": "Mediencontainer l\u00f6schen",
        "container_not_found": "Der Mediencontainer existiert nicht (mehr)",
        "remove_container_success_message": "Der Mediencontainer wurde erfolgreich entfernt",
        "save_container_success_message": "Der Mediencontainer wurde erfolgreich gespeichert",
        "save_object_success_message": "Das Medienobjekt wurde erfolgreich gespeichert",
        "save_objects_success_message": "Ein oder mehr Medienobjekte wurden erfolgreich gespeichert",
        "remove_object_confirm_message": "M\u00f6chten Sie das Medienobjekt wirklich l\u00f6schen?",
        "remove_object_confirm_title": "Medienobjekt l\u00f6schen",
        "remove_object_success_message": "Ein oder mehr Medienobjekte wurden erfolgreich gel\u00f6scht",
        "media_not_found": "Das Medienobjekt konnte nicht gefunden werden",
        "not_downloadable": "Es handelt sich bei dem Medienobjekt nicht um eine Datei und ist somit nicht herunterladbar",
        "move_object": "ein oder mehrere Medienobjekte verschieben",
        "move_object_success_message": "Ein odere mehrere Medienobjete wurden erfolgreich verschoben",
        "file_radio": "Datei",
        "link_radio": "Link",
        "object_data": "Objektdaten",
        "references": "Verwendung",
        "references_empty": "Dieses Medienobjekt wurde in keinem digitalen Ordner\u00ae verwendet",
        "append_object": "Bitte Medienobjekt zuordnen",
        "append_name": "Bitte Medienname eintragen",
        "media_in_use": "Das Medienobjekt konnte nicht gel\u00f6scht werden. Es wird zur Zeit in einem digitalen Ordner\u00ae verwendet.",
        "size_exceeded": "Das Speicherlimit wurde erreicht. Bitte upgrade den Tarif.",
        "media_object_limit_type": "Im Free- & Basis-Tarif sind keine Links erlaubt. Bitte upgrade den Tarif. (nur gewerblich)",
        "select_container": "Bitte erst einen Mediencontainer ausw\u00e4hlen",
        "container_with_media_in_use": "Der Mediencontainer konnte nicht gel\u00f6scht werden. Mindestens eine Mediendatei wird in einem digitalen Ordner\u00ae verwendet.",
        "invalid_url": "Der angegebene Link ist ung\u00fcltig oder nicht erreichbar",
        "thumbnail_processing": "Vorschau wird erzeugt...",
        "sort_containers": "Sortiert die Mediencontainer z.B. alphabetisch",
        "change_media_advice": "Hinweis: Dieses Medienobjekt ist schon mit einem digitalen Ordner\u00ae verkn\u00fcpft. Wenn Sie den Inhalt tauschen, tauschen Sie diesen in ALLEN digitalen Ordner\u00aen aus!",
        "thumbnail": "Vorschaubild",
        "filetype_mismatch": "Der Dateityp ist nicht zul\u00e4ssig f\u00fcr Vorschaubilder, Bildseiten oder Ordnerfronten",
        "select_from_mediacenter": "Aus einem Mediencontainer auswählen",
        "limit_exceeded": "Die Datei ist zu groß. Wir unterstützen je Datei maximal 25MB",
        "no_media_found": "Keine Medienobjekte gefunden",
        "from_mediacenter": "aus dem Mediencenter",
        "share_container": "Freigabe",
        "released": "freigeben",
        "unable_to_update_released_container": "Du darfst keinen für dich freigegebenen Mediencontainer bearbeiten",
        "unable_to_update_info": "Dieser Mediencontainer wurde von dem Team \"{team}\" freigegeben und kann nicht bearbeitet werden.",
        "uneditable_shared_media": "Freigegebene Mediendateien können nur vom Ersteller bearbeitet werden!",
        "upload_activity": "Einen Moment bitte - Deine Dateien werden gerade hochgeladen.",
        "select_from_clipboard": "Aus dem Zwischenspeicher einfügen (STRG + V)"
    }
}

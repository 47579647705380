<template>
  <div class="c__url-wrapper">
    <div class="c__url-icon"><img src="images/icons/add--blue.svg"></div>
    <div class="c__url">
      <input
        placeholder="https://digital-folder.com/sdhfaiasdiasdjoa"
        v-model="url"
        ref="target"
        disabled
      />
    </div>
    <div class="c__url-button">
      <Button
        theme="default"
        @click="copyToClipboard">
        {{ $t('workspaces.copy') }}
      </Button>
    </div>
  </div>
</template>

<script>
  import Button from '../buttons/Button'
  import Helpers from '../../../helpers'

  export default {
    props: [
      'url'
    ],
    methods: {
      copyToClipboard() {
        Helpers.copyToClipboard(this.url, this.$t('workspaces.copied'))
      }
    },
    components: {
      Button
    }
  }
</script>

<style lang="scss" scoped>
  .c__url-wrapper {
    width: 100%;
    padding: 8px;
    display: flex;
    margin-top: 8px;
    border-radius: 4px;
    justify-content: space-between;
    border: solid 1px $light-blue-color;

    .c__url {
      width: calc(100% - 160px);

      input {
        padding: 9px 0;
        outline: none;
        border: none;
        height: 40px;
        width: 100%;

        &::placeholder {
          color: $dark-gray-color;
        }

        &:disabled {
          background: transparent;
        }
      }
    }

    .c__url-icon {
      display: flex;
      flex-shrink: 0;
      max-width: 16px;
      margin: 0 9px 0 9px;
      width: calc(100% - 16px);
    }

    .c__url-button {
      width: 100%;
      flex-shrink: 0;
      max-width: 96px;
    }
  }
</style>

<template>
  <section
    ref="folder"
    :class="{
        'c__folder-display': true,
        'c__folder-display--big': big,
        'c__folder-display--huge': huge,
        'c__folder-display--preview': isMobile && $route.name === 'ext-folder-prev',
        'c__folder-display--brochure': folder && folder.display_type === 'brochure'
      }" :style="style">
    <img class="c__folder-image"
         :src="image"
         v-if="image"
         @error="trackError">
    <template v-if="folder && folder.display_type === 'brochure'">
      <div class="c__folder-fold"/>
    </template>
    <template v-if="folder && folder.display_type !== 'brochure'">
      <div class="c__folder-front"/>
      <div class="c__folder-sheet"/>
      <div class="c__folder-sheet c__folder-sheet--wide"/>
      <div class="c__folder-hanger c__folder-hanger-top"/>
      <div class="c__folder-hanger c__folder-hanger-bottom"/>
    </template>
  </section>
</template>

<script>
  import Helpers from '@/helpers';
  import i18n from '@/lang';
  import {mapGetters} from "vuex";

  export default {
    props: [
      'big',
      'huge',
      'image',
      'folder',
    ],
    computed: {
      ...mapGetters({
        isMobile: 'app/isMobileMode'
      }),
      style() {
        return 'background-color: ' + this.folder.color + ';'
      },
    },
    mounted() {
      if (this.huge) {
        window.addEventListener('resize', this.resizeFolder, false)
        window.dispatchEvent(new Event('resize'))
      }
    },
    beforeDestroy() {
      if (this.huge) {
        window.removeEventListener('resize', this.resizeFolder, false)
      }
    },
    methods: {
      trackError($event) {
        // Check if the url is a thumbnail url
        if ($event
          && $event.target
          && $event.target.currentSrc
          && $event.target.currentSrc.indexOf('/thumbnail?') > -1) {

          // Handle/Do logout
          Helpers.callToast('is-danger', this.$t('general.unauthenticated'), 5000, true)
          this.$router.push('/auth/logout')
        }
      },
      resizeFolder() {
        if (this.$refs.folder && this.$refs.folder.style) {
          this.$refs.folder.style.background = this.folder.color
          //this.$refs.folder.style['max-height'] = (this.$refs.folder.clientWidth * 1.18) + 'px';
          //this.$refs.folder.style['max-width'] = (this.$refs.folder.clientHeight * 0.84) + 'px';

          let scaleX = this.$refs.folder.clientWidth / 580
          let scaleY = this.$refs.folder.clientHeight / 684
          if (scaleX && scaleY) {
            this.$refs.folder.style.transform = 'scale(' + (scaleX * (scaleY * scaleY)) + ',' + (scaleY * (scaleX * scaleX)) + ')'

            let elements = document.querySelectorAll('.c__folder-sheet')
            if (elements) {
              for (let i = 0; i < elements.length; i++) {
                let element = elements.item(i)
                let elementHeight = [227, 145]
                let elementTop = [0, 225]
                if (element) {
                  element.style.right = element.clientWidth
                  element.style.height = (elementHeight[i] * scaleX) + 'px'
                  element.style.top = (elementTop[i] * scaleX) + 'px'
                }
              }
            }
          }
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .c__folder-display {
    z-index: 0;
    width: 128px;
    height: 150px;
    margin: auto;
    border-radius: 4px;
    position: relative;
    background: $lila-color;

    @media (max-width: $breakpoint-mobile) {
      z-index: 1;
      transform: scale(1);
    }

    &.c__folder-display--preview {
      @media (max-width: $breakpoint-mobile) {
        z-index: 1;
        transform: scale(1.75);
      }
    }

    .c__folder-image {
      width: 100%;
      height: 100%;
      border-radius: 2px;
      border-top-right-radius: 0;
      object-fit: cover;
      position: absolute;

      &:after {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 4px;
        background: black;
        position: absolute;
      }
    }

    .c__folder-front {
      left: 2px;
      width: 3px;
      height: 100%;
      filter: blur(1px);
      position: absolute;
      background: $darkest-white;
    }

    .c__folder-sheet {
      z-index: 4;
      width: 8px;
      right: -8px;
      height: 32px;
      position: absolute;
      background: rgba(#DEDEDE, 1);
      border-top-right-radius: 4px;
      box-shadow: $box-shadow-folder;
      border-bottom-right-radius: 4px;

      &.c__folder-sheet--wide {
        top: 28px;
        z-index: 3;
        height: 50px;
      }
    }

    .c__folder-hanger {
      left: 8px;
      width: 20px;
      height: 5px;
      border-radius: 2px;
      position: absolute;
      background: #f0f0f0;

      &.c__folder-hanger-top {
        top: 55px;
      }

      &.c__folder-hanger-bottom {
        bottom: 55px;
      }
    }

    /*
     *
     *  Huge Folder
     *
     */
    &.c__folder-display--huge {
      width: 100%;
      height: 100%;
      max-width: 580px;
      max-height: 680px;

      .c__folder-front {
        left: 9px;
        width: 14px;
        filter: blur(4.5px);
      }

      .c__folder-sheet {
        top: 0;
        width: 25px;
        right: -25px !important;
        height: 227px;
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;

        &.c__folder-sheet--wide {
          top: 225px;
          width: 25px;
          height: 145px;
        }
      }

      .c__folder-hanger {
        width: 100%;
        height: 100%;
        max-width: 84px;
        max-height: 20px;
        border-radius: 9px;
        border: 4px solid $white;
        background: url('/images/desks/background_seamless_wood_black.jpg');

        &.c__folder-hanger-top {
          top: 38%;
          left: 32px;
        }

        &.c__folder-hanger-bottom {
          top: 60%;
          left: 32px;
        }
      }

      &.c__folder-display--brochure {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;

        .c__folder-fold {
          width: 12px;
          height: 100%;
          margin-left: 20px;
          background: transparent;
          box-shadow: 2px 0px 5px 0px rgba(0, 0, 0, 0.5) inset;
        }
      }
    }

    /*
     *
     *  Big Folder
     *
     */
    &.c__folder-display--big {
      width: 245px;
      height: 287px;

      .c__folder-front {
        width: 6px;
      }

      .c__folder-sheet {
        top: 2px;
        width: 8px;
        height: 95px;
        border-top-right-radius: 16px;
        border-bottom-right-radius: 16px;

        &.c__folder-sheet--wide {
          top: 96px;
          width: 8px;
          height: 61px;
        }
      }

      .c__folder-hanger {
        width: 38px;
        height: 10px;
        border-radius: 4px;
        border: 2px solid $white;
        background: url('/images/desks/background_seamless_wood_black.jpg');

        &.c__folder-hanger-top {
          top: 104px;
          left: 13px;
        }

        &.c__folder-hanger-bottom {
          top: 172px;
          left: 13px;
        }
      }

      &.c__folder-display--brochure {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;

        .c__folder-fold {
          width: 8px;
          height: 100%;
          margin-left: 12px;
          background: transparent;
          box-shadow: 2px 0px 5px 0px rgba(0, 0, 0, 0.5) inset;
        }
      }
    }

    &.c__folder-display--brochure {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;

      .c__folder-image {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }

      .c__folder-fold {
        width: 4px;
        height: 100%;
        margin-left: 4px;
        position: absolute;
        background: transparent;
        box-shadow: 2px 0px 5px 0px rgba(0, 0, 0, 0.5) inset;
      }
    }
  }
</style>

<template>
  <MenuTaskBar>
    <LoadingComponent :load="loading">
      <template v-if="customer">
        <TitledContainer
          :has-controls="true"
          :title="customer.company || customer.owner">
          <div
            slot="controls"
            class="flex gap-1">
            <c-button
              @click="toggleStatus">
              <template v-if="customer.blocked">Kunde entsperren</template>
              <template v-if="!customer.blocked">Kunde sperren</template>
            </c-button>

            <c-button
              @click="confirmDelete = !confirmDelete">
              Kunde löschen?
            </c-button>

            <c-button
              @click.prevent="confirmTransfer = !confirmTransfer">
              Konto übertragen
            </c-button>
          </div>

          <!-- Content -->
          <div
            class="c-block"
            v-if="confirmDelete">
            <div class="c-block-title">Unwiderrufliche Löschung des Kundens bestätigen</div>
            <div class="c-block-content">
              <c-button
                color="red"
                @click.prevent="removeCustomer">
                Kunde unwiderruflich löschen
              </c-button>
              <c-button
                class="u-ml--8"
                :outline="true"
                @click.prevent="confirmDelete = false">
                Kunde nicht löschen
              </c-button>
            </div>
          </div>
          <div
            class="c-block"
            v-if="confirmTransfer">
            <div class="c-block-title">Daten zu einem anderen Konto transferieren</div>
            <div class="flex gap-2 w-full" :style="{'align-items': 'flex-end'}">
              <labeled-input
                type="select"
                v-model="tCustomer"
                :values="customers">
                Zielkonto
              </labeled-input>
              <c-button
                @click.prevent="transferCustomer">
                Jetzt Konto übertragen
              </c-button>
              <c-button
                :outline="true"
                @click.prevent="confirmTransfer = false">
                Abbrechen
              </c-button>
            </div>
          </div>
          <div class="c-block">
            <div class="c-block-title">Basisinformationen</div>
            <div class="c-block-content">
              <div class="c-row">
                <div class="c-col c-col-4">
                  <div :key="index"
                       class="c-row key-value-pair"
                       v-for="(entry, index) in basic">
                    <div class="c-col c-col-4 key">{{ entry.key }}</div>
                    <div class="c-col c-col-6 value">{{ customer[entry.value] }}</div>
                  </div>
                </div>
                <div class="c-col c-col-4">
                  <div :key="index"
                       class="c-row key-value-pair"
                       v-for="(entry, index) in plan">
                    <div class="c-col c-col-4 key">{{ entry.key }}</div>
                    <div class="c-col c-col-6 value">
                      <template v-if="filter(entry)">{{ filter(entry) }}</template>
                      <template v-else>-</template>
                    </div>
                  </div>
                </div>
                <div class="c-col c-col-3">
                  <div :key="index"
                       class="c-row key-value-pair"
                       v-for="(entry, index) in misc">
                    <div class="c-col c-col-4 key">{{ entry.key }}</div>
                    <div class="c-col c-col-6 value">
                      <template v-if="customer[entry.value]">{{ customer[entry.value] }}</template>
                      <template v-else>-</template>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="c-block u-mt--16">
            <div class="c-block-title">Gutscheine</div>
            <div class="c-block-content">
              <table class="small-table">
                <thead>
                <tr>
                  <th>#</th>
                  <th>Eigentümer</th>
                  <th>Firmenname</th>
                  <th>Status</th>
                  <th>Lizenzart</th>
                  <th>Lizenzen</th>
                  <th>Aktionen</th>
                </tr>
                </thead>
              </table>
            </div>
          </div>

          <div class="c-block u-mt--16">
            <div class="c-block-title">Statistik</div>
            <div class="c-block-content">
              <div class="c-row">
                <div class="c-col c-col-4">
                  <div :key="index"
                       class="c-row key-value-pair"
                       v-for="(entry, index) in statisticFolder">
                    <div class="c-col c-col-4 key">{{ entry.key }}</div>
                    <div class="c-col c-col-6 value">{{ customer[entry.value] }}</div>
                  </div>
                </div>
                <div class="c-col c-col-4">
                  <div :key="index"
                       class="c-row key-value-pair"
                       v-for="(entry, index) in statisticFiles">
                    <div class="c-col c-col-4 key">{{ entry.key }}</div>
                    <div class="c-col c-col-6 value">{{ customer[entry.value] }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="c-block u-mt--16">
            <div class="c-block-title">Rechnungen</div>
            <div class="c-block-content">
              <table class="small-table">
                <thead>
                <tr>
                  <th>#</th>
                  <th>Rechnungsnummer</th>
                  <th>Rechnungsdatum</th>
                  <th>Status</th>
                  <th>Betrag</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(invoice, index) in customer.invoices">
                  <td>{{ index + 1 }}</td>
                  <td>{{ invoice.number }}</td>
                  <td>{{ invoice.date | date }}</td>
                  <td>{{ invoice.status }}</td>
                  <td>{{ invoice.amount | price }}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </TitledContainer>
      </template>
    </LoadingComponent>
  </MenuTaskBar>
</template>

<script>
  import Helpers from '../../helpers'
  import APIFactory from '../../api/factory'
  import Button from '../../components/global/buttons/Button'
  import MenuTaskBar from '../../components/admin/menu/MenuTaskbar'
  import IconButton from '../../components/global/buttons/IconButton'
  import TitledContainer from '../../components/admin/TitledContainer'
  import LoadingComponent from '../../components/global/LoadingComponent';

  export default {
    data() {
      return {
        confirmDelete: false,
        confirmTransfer: false,
        loading: false,
        customer: null,
        tCustomer: null,
        customers: [],
        basic: [
          {key: 'Unternehmen', value: 'company'},
          {key: 'Name', value: 'owner'},
          {key: 'Straße', value: 'street'},
          {key: 'PLZ', value: 'postcode'},
          {key: 'Ort', value: 'city'},
        ],
        plan: [
          {key: 'Tarif', value: 'plan_id', translate: 'signup.'},
          {key: 'Benutzer', value: 'quantity'},
          {key: 'Status', value: 'status', translate: 'settings.status_'},
          {key: 'Kundennummer', value: 'customer_id'},
          {key: 'Erster Login am', value: 'first_login_at'},
          {key: 'Letzter Login am', value: 'last_login_at'},
          {key: 'Gesperrt am', value: 'blocked_at'},
        ],
        misc: [
          {key: 'E-Mail', value: 'email'},
          {key: 'Vermittlung durch', value: 'mediation'},
          {key: 'Registriert am', value: 'created_at'},
          {key: '30 Tage Premium endet am', value: 'trial_ends_at'},
          {key: 'Gekündigt am', value: 'canceled_at'},
          {key: '90 Tage nach der Kündigung', value: 'deletion_at'}
        ],
        statisticFolder: [
          {key: 'Regale', value: 'shelves'},
          {key: 'Digitale Ordner', value: 'folder'},
          {key: 'Kapitel', value: 'chapters'},
          {key: 'Seiten', value: 'pages'},
        ],
        statisticFiles: [
          {key: 'Mediendateien', value: 'objects'},
          {key: 'Mediencontainer', value: 'containers'},
          {key: 'Eingeladene Benutzer', value: 'invited_users'},
          {key: 'Benutzter Speicherplatz', value: 'used_space'},
        ]
      }
    },
    async created() {
      await this.loadCustomer()
      await this.loadCustomers()
    },
    methods: {
      filter(entry) {
        if (entry.translate) {
          return this.$t(entry.translate + this.customer[entry.value])
        }
        return this.customer[entry.value]
      },
      loadCustomer() {
        this.loading = true
        APIFactory.findCustomer(this.$route.params.id).then(res => {
          if (res && res.success) {
            this.customer = res.data
          }
        }).finally(() => this.loading = false)
      },
      loadCustomers() {
        this.loading = true
        APIFactory.findCustomers().then(res => {
          if (res && res.success) {
            this.customers = (res.data || [])
              .filter(customer => customer.id !== this.$route.params.id)
              .map(customer => {
                return {
                  id: customer.id,
                  label: customer.email
                }
              })
              .sort((c1, c2) => c1.label > c2.label ? 1 : -1)
          }
        }).finally(() => this.loading = false)
      },
      createAPIRequest() {
        return this.customer.blocked
          ? APIFactory.release(this.customer.id)
          : APIFactory.block(this.customer.id)
      },
      transferCustomer() {
        this.loading = true
        console.log(this.tCustomer)
        APIFactory.transferCustomer(this.$route.params.id, this.tCustomer).then(res => {
          if (res && res.success) {
            Helpers.callToast('is-success', 'Der Kunde wurde erfolgreich übertragen')
          }
        }).finally(() => this.loading = false)
      },
      toggleStatus() {
        this.loading = true
        this.createAPIRequest().then(res => {
          if (res && res.success) {
            Helpers.callToast('is-success', this.customer.blocked
              ? 'Der Kunde wurde entsperrt' : 'Der Kunde wurde gesperrt')
            this.loadCustomer()
          }
        }).finally(() => this.loading = false)
      },
      async removeCustomer() {
        this.loading = true
        let response = await APIFactory.removeCustomer(this.$route.params.id)
        if (response) {
          console.log(response)
        }
        this.loading = false
        return this.$router.push('/admin/customers')
      }
    },
    components: {
      LoadingComponent,
      Button,
      IconButton,
      MenuTaskBar,
      TitledContainer
    }
  }
</script>

<style lang="scss" scoped>
  .c-block {
    width: 100%;
    padding: 16px;
    margin: 8px auto;
    max-width: 1920px;
    background: $white;
    border-radius: 4px;
    box-shadow: $box-shadow-folder;
    border: 1px solid darken($light-blue-color, 5%);

    .c-block-title {
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 8px;
      color: $darker-blue-color;
    }
  }

  .key-value-pair {
    .key {
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 8px;
      color: $darker-blue-color;
    }
  }

  .small-table {
    width: 100%;
    padding: 0 8px;
    border-radius: 4px;
    border-collapse: collapse;

    thead,
    tbody {
      border-radius: 4px;
    }

    tr {
      text-indent: 8px;
      border-radius: 4px;
      line-height: 40px;

      &:hover {
        background-color: $light-blue-color;
      }

      th {
        height: 50px;
        line-height: 50px;
      }
    }

    .icon-container {
      display: flex;
    }
  }
</style>

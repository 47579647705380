<template>
  <div class="c__chapter-contents">
    <div class="chapter-title">
      <input
        v-model="chapter.name"
        class="basic expanded margin-reset is-focusable"
        :placeholder="$t('designer.chapter_title')"
      />
    </div>
    <div class="c__chapter-container">
      <div class="c__chapter-container--header">{{ $t('designer.background_image') }}</div>
      <div class="c__chapter-container--content">
        <color-designer
          :folder="folder"
          :chapter-id="chapter.id"
          v-if="mode === 'color-mode'"
        />
        <MediaDropzone
          :hide-title="true"
          :maximum-items="1"
          context-type="chapter"
          @delete="deleteCover"
          @place-item="placeItem"
          :item="chapter.content"
          :containers="containers"
          v-if="mode === 'cover-mode'"
          :maximum-selectable-items="1"
          :file-extensions="['jpg', 'jpeg', 'png', 'gif', 'bmp']"
        />

        <b-tooltip
          size="is-small"
          :delay="tooltipDelay"
          class="c__chapter-background-toggle"
          :label="$t('designer.cover_tooltip')">
          <div @click="toggleCover">
            <img src="images/icons/designer.svg">
          </div>
        </b-tooltip>
      </div>
    </div>
    <div class="c__chapter-controls">
      <Button
        theme="default-error"
        :disable-icon-view-only-on-mobile="true"
        @click="confirmDialog = true">
        {{ $t('designer.remove_chapter') }}
      </Button>
    </div>

    <!-- Modals -->
    <ConfirmModal
      :active="confirmDialog"
      :title="$t('designer.remove_chapter')"
      :message="$t('designer.delete_chapter_message')"
      @close="confirmDialog = false"
      :function="handleDeleteChapter"
    />
  </div>
</template>

<script>
  import ConfirmModal from '@/components/global/modals/ConfirmModal'
  import Button from '../../global/buttons/Button'
  import MediaDropzone from '../MediaDropzone'
  import {mapGetters} from 'vuex'

  export default {
    props: [
      'containers',
      'chapter',
      'folder',
    ],
    computed: {
      ...mapGetters({
        tooltipDelay: 'ui/tooltipDelay'
      }),
    },
    data() {
      return {
        mode: null,
        confirmDialog: false
      }
    },
    created() {
      if (this.chapter && this.chapter.content) {
        this.mode = 'cover-mode'
      } else {
        this.mode = 'color-mode'
      }
    },
    methods: {
      placeItem($event) {
        if (this.chapter) {
          this.$set(this.folder, 'dirty', true)
          this.$set(this.chapter, 'content', $event.items[0])
          this.$set(this.chapter, 'cover_id', $event.items[0].id)
        }
      },
      handleDeleteChapter() {
        this.$emit('deleteChapter', this.chapter.id)
        return Promise.resolve()
      },
      deleteCover() {
        this.$set(this.chapter, 'content', null)
        this.$set(this.chapter, 'cover_id', null)
      },
      toggleCover() {
        this.mode = this.mode === 'cover-mode'
          ? 'color-mode'
          : 'cover-mode'
        this.$set(this.chapter, 'mode', this.mode)
      }
    },
    components: {
      ConfirmModal,
      MediaDropzone,
      Button,
    }
  }
</script>

<style lang="scss" scoped>
  .c__chapter-contents {
    width: 100%;
    height: 100%;
    padding: 32px 56px;
    border-radius: 8px;
    border: solid 1px $light-blue-color;

    @media (max-width: $breakpoint-mobile) {
      padding: 0;
      border: none;
    }

    .chapter-title {
      @media (max-width: $breakpoint-mobile) {
        margin-top: 20px;
      }
    }

    .c__chapter-controls {
      display: flex;
      margin-top: 44px;
      justify-content: center;

      @media (max-width: $breakpoint-mobile) {
        display: none;
      }
    }

    .c__chapter-container {
      width: 100%;
      margin-top: 30px;
      height: calc(100% - 72px - 79px);

      @media (max-width: $breakpoint-mobile) {
        margin-top: 20px;
        height: calc(100% - 90px);
      }

      .c__chapter-container--header {
        font-size: 12px;
        font-weight: 500;
        margin-bottom: 8px;
        color: $darker-blue-color;
        font-family: 'Roboto', sans-serif;
      }

      .c__chapter-container--content {
        @apply
          w-full
          relative
          rounded-sm;
        height: calc(100% - 24px);

        .c__chapter-background-toggle {
          @apply
            p-2.5
            top-2
            right-2
            rounded
            absolute
            bg-white
            shadow-lg
            cursor-pointer
            hover:bg-gray-200;
        }
      }
    }
  }
</style>

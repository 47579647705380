<template>
    <div class="c-progress-container u-mt--16">
        <div :class="{
        	'c-progress-circle': true,
        	'c-progress-circle--selected': selected(idx) }"
             :key="idx"
             v-for="(circle, idx) in circles"
        />
    </div>
</template>

<script>
    export default {
    	props: [
    		'index',
            'plan',
        ],
        computed: {
    		circles() {
    			return this.plan && this.plan.id === 'free' ? 2 : 4
            },
        },
        methods: {
	        selected(idx) {
	        	if (this.plan && this.plan.id === 'free') {
			        return this.index === (idx + 1) || (this.index === 4 && idx === 1)
                }
		        return this.index === (idx + 1)
	        }
        }
    }
</script>

<template>
    <iframe
        :src="src"
        ref="iframe"
        class="c-viewer">
    </iframe>
</template>

<script>
    import {mapGetters} from 'vuex'

    export default {
    	computed: {
            ...mapGetters({
                token: 'auth/token',
                apiURL: 'app/apiURL'
            }),
            src() {
            	let exposeURL = null
                // Authenticated / Preview view
                if (this.$route.params.slug && this.$route.params.teamId) {
                    exposeURL = this.apiURL + '/api/' + this.$route.params.slug
                        + '/' + this.$route.params.teamId
                        + '/object/' + this.$route.params.id
                        + '/expose'
                        + '?token=' + this.token
                }

                // Public free access (only with password)
                if (this.$route.params.code && this.$route.params.password) {
                	exposeURL = this.apiURL + '/api/object/'
                        + this.$route.params.code + '/'
                        + this.$route.params.password + '/'
		                + this.$route.params.id + '/download'
                }

                if (exposeURL) {
            	    return 'https://docs.google.com/gview?url=' + exposeURL + '&embedded=true'
                }
            }
	    },
        mounted() {
    		setTimeout(() => {
    			let iframe = this.$refs.iframe
                if (iframe
                    && iframe.contentWindow
                    && iframe.contentWindow.document
                    && iframe.contentWindow.document.body) {
                	location.reload()
                }
            }, 1000)
        }
    }
</script>

<style lang="scss" scoped>
    .c-viewer {
        width: 100%;
        height: 100%;
    }
</style>

<template>
  <b-navbar
    :shadow="true"
    :spaced="false"
    class="sticky-navbar">
    <template slot="brand">
      <router-link to="/">
        <b-navbar-item>
          <img src="images/do-logo.svg">
          <div class="logo ml-1">
            <strong>{{ $t('app.name') }}</strong>
          </div>
        </b-navbar-item>
      </router-link>
    </template>

    <template slot="burger">
      <a @click.prevent="toggle"
         class="navbar-burger burger"
         :class="{'is-active': menu}">
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </a>
      <LanguageSwitcher v-if="isCompactMode"/>
    </template>

    <template slot="end">
      <!-- Zuruck -->
      <b-tooltip
        position="is-bottom"
        :delay="tooltipDelay"
        :label="$t('settings.back_to_digital_folder')">
        <b-navbar-item
          :to="back"
          tag="router-link"
          :class="{'icon icon--green': !isCompactMode}">
          <template v-if="!isCompactMode">
            <b-icon
              pack="fas"
              icon="angle-left"
              size="is-small"
            />
          </template>
          <template v-else>{{ $t('settings.back') }}</template>
        </b-navbar-item>
      </b-tooltip>

      <!-- App select -->
      <b-navbar-dropdown :right="true"
                         v-if="!isCompactMode && apps && apps.length > 1">
        <template slot="label">
          <small>{{ appName }}</small>
        </template>

        <b-navbar-item
          :key="index"
          v-for="(app, index) in apps"
          @click.prevent="switchApplication(app.slug)">
          {{ app.label }}
        </b-navbar-item>
      </b-navbar-dropdown>

      <!-- Language Switcher -->
      <LanguageSwitcher v-if="!isCompactMode"/>

      <!-- Sidebar Entries -->
      <b-navbar-item
        :key="index"
        tag="router-link"
        v-if="isCompactMode"
        v-for="(entry, index) in entries"
        :to="'/' + $route.params.slug + entry.link">
        {{ $t(entry.label) }}
      </b-navbar-item>

      <b-tooltip
        :delay="tooltipDelay"
        position="is-bottom"
        :label="$t('settings.help')">
        <b-navbar-item :class="{'icon': !isCompactMode}">
          <a target="_blank" href="https://www.digital-folder.com/creator-help">
            <template v-if="!isCompactMode">
              <b-icon
                pack="far"
                icon="question-circle"
                size="is-small"
              />
            </template>
            <template v-else>
              {{ $t('general.help') }}
            </template>
          </a>
        </b-navbar-item>
      </b-tooltip>
      <b-navbar-item>
          <chat :navi-mode="true" />
      </b-navbar-item>
      <b-navbar-item
        tag="router-link"
        to="/auth/logout"
        :class="{'icon icon--red icon--power-off': !isCompactMode}">
        <template v-if="!isCompactMode">
          <b-icon
            pack="fas"
            size="is-small"
            icon="power-off"
          />
        </template>
        <template v-else>
          {{ $t('auth.logout') }}
        </template>
      </b-navbar-item>
    </template>
  </b-navbar>
</template>

<script>
  import {mapGetters} from 'vuex'
  import SidebarEntries from '../../data/sidebar'
  import LanguageSwitcher from './LanguageSwitcher'

  export default {
    computed: {
      ...mapGetters({
        apps: 'auth/apps',
        lastURL: 'app/lastURL',
        tooltipDelay: 'ui/tooltipDelay',
        isCompactMode: 'app/isCompactMode'
      }),
      appName() {
        if (this.apps) {
          let app = this.apps.find(item => item.slug === this.$route.params.slug)
          if (app) {
            return app.label
          }
        }
      },
      back() {
        if (this.lastURL) {
          return this.lastURL
        }
        return '/' + this.$route.params.slug + '/workspaces'
      }
    },
    data() {
      return {
        entries: SidebarEntries,
        menu: false,
      }
    },
    methods: {
      switchApplication(slug) {
        if (this.$route.params.slug !== slug) {
          this.$router.push('/' + slug + '/settings/profile')
        }
      },
      toggle() {
        this.menu = !this.menu
        let element = document.querySelector('.navbar-menu')
        if (element) {
          if (this.menu) {
            element.classList.add('is-active')
          } else {
            element.classList.remove('is-active')
          }
        }
      }
    },
    components: {
      LanguageSwitcher
    },
  }
</script>

<style lang="scss" scoped>
  .logo {
    display: flex;
    min-width: 200px;
  }

  .icon {
    width: 45px;
    height: 45px;

    &.icon--power-off {
      color: $white;
      background: $dark-blue-color;
    }
  }

  .sticky-navbar {
    position: fixed;
    width: 100%;
    height: 45px;
    left: 0;
    top: 0;
  }
</style>

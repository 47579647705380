<template>
    <b-card>
        <div class="c-notification">
            <div class="notification-message">{{ message }}</div>
            <div class="notification-buttons">
                <slot />
            </div>
        </div>
    </b-card>
</template>

<script>
    export default {
    	props: ['message'],
    }
</script>

<style lang="scss" scoped>
    .c-notification {
        display: flex;
        justify-content: space-between;

        .notification-message,
        .notification-buttons {
            display: flex;
            align-items: center;
        }

        .notification-buttons {
            margin-left: 16px;
            word-wrap: break-word;
        }
    }
</style>

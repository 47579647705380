<template>
  <base-modal
    :active="active"
    :title="title"
    @close="$emit('close')">
    <div class="u-mt--16">
      <color-selector v-model="color" />
    </div>
    <div
      slot="actions"
      class="modal-controls modal-controls--center">
      <Button
        @click="accept"
        theme="primary"
        :icon="confirmIcon">
        {{ $t('general.save') }}
      </Button>
    </div>
  </base-modal>
</template>

<script>
import ColorSelector from '@/components/global/inputs/ColorSelector'
import BaseModal from '../../global/modals/BaseModal'
import Button from '../../global/buttons/Button'
import {mapGetters} from 'vuex'

export default {
  props: [
    'title',
    'value',
    'active',
  ],
  computed: {
    ...mapGetters({
      isMobile: 'app/isMobileMode'
    }),
    closeIcon() {
      return this.isMobile ? 'images/icons/close.svg' : ''
    },
    confirmIcon() {
      return this.isMobile ? 'images/icons/check--white.svg' : ''
    }
  },
  data() {
    return {
      color: null,
    }
  },
  created() {
    this.color = this.value
  },
  methods: {
    accept() {
      this.$emit('success', {
        value: this.color
      })
    },
  },
  watch: {
    value() {
      this.color = this.value
    }
  },
  components: {
    ColorSelector,
    BaseModal,
    Button
  }
}
</script>

<style lang="scss" scoped>

</style>

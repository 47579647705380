import Login from '../../views/auth/Login'
import Forget from '../../views/auth/Forget'
import Logout from '../../views/auth/Logout'
import Verify from '../../views/auth/Verify'
import QuickSignup from '@/views/auth/QuickSignup'

export default [
	{
		path: '/auth/login',
		component: Login,
	},
	{
		path: '/auth/forget',
		component: Forget
	},
	{
		path: '/auth/logout',
		component: Logout,
	},
	{
		path: '/auth/verify/:code',
		component: Verify
	},
	{
		path: '/auth/quick-signup',
		component: QuickSignup
	}

]

<template>
  <div class="c-preview">
    <div class="c-preview-sidebar">
      <PreviewHeader
        :title="title"
        :folder="folder"
        @open="$emit('open', $event)"
      />
      <PreviewBottom/>
    </div>
    <div class="c-preview-display animWhiteOut">
      <template v-if="!isMobile">
        <img
          :src="background"
          v-if="hasBackgroundImage"
        />
        <div
          v-else
          class="c-folder-background"
          :style="'background: ' + background"
        />
      </template>
      <FolderDisplay
        :folder="folder"
        :image="cover"
        v-if="folder"
        :huge="true"
        class="animYAxis"
      />
    </div>
  </div>
</template>

<script>
  import {mapGetters} from 'vuex'
  import PreviewHeader from './preview/PreviewHeader'
  import PreviewBottom from './preview/PreviewBottom'
  import FolderDisplay from '../workspace/FolderDisplay'

  export default {
    props: [
      'folder'
    ],
    computed: {
      ...mapGetters({
        apiURL: 'app/apiURL',
        isMobile: 'app/isMobileMode'
      }),
      title() {
        return this.folder
          ? this.folder.name
          : this.$t('display.no_folder_found_headline')
      },
      cover() {
        if (this.folder && this.folder.cover) {
          return this.folder.cover.preview
        }
      },
      background() {
        if (this.hasBackgroundImage) {
          return this.apiURL + '/api/object/' + (this.folder.code || this.$route.params.code) + '/' + this.folder.background_image + '/background'
        }

        if (this.folder.background_color) {
          return this.folder.background_color
        }

        return 'url("images/desks/background_seamless_wood_black.jpg")'
      },
      hasBackgroundImage() {
        return !!this.folder.background_image
      },
      hasBackgroundColor() {
        return !!this.folder.background_color
      }
    },
    components: {
      FolderDisplay,
      PreviewHeader,
      PreviewBottom,
    }
  }
</script>

<style lang="scss" scoped>
  .c-preview {
    width: 100%;
    height: 100%;
    display: flex;

    .c-preview-sidebar {
      flex: 40%;
      width: 100%;
      height: 100%;
      padding: 56px;
      display: flex;
      min-width: 480px;
      max-width: 560px;
      flex-direction: column;
      justify-content: space-between;

      @media (max-width: $breakpoint-mobile) {
        padding: 0px;
        min-width: auto;
        max-width: 100%;
      }
    }

    .c-preview-display {
      height: 100%;
      display: flex;
      padding: 56px;
      flex-shrink: 0;
      position: relative;
      align-items: center;
      flex: calc(60% - 152px);
      justify-content: center;

      img,
      .c-folder-background {
        position: absolute;
        max-width: 100%;
        max-height: 100%;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      @media (max-width: $breakpoint-mobile) {
        display: none;
      }
    }
  }
</style>

export default {
	"app": {
		"unknown_error": "an unknown error has occurred. Please contact support@digital-folder.com",
		"name": "digital folder\u00ae",
		"language_de": "German",
		"language_en": "English",
		"language_cz": "Czech",
		"language_ru": "Russian",
		"search_objects": "media objects",
		"search_containers": "media container ",
		"search_folders": "digital folder\u00aes",
		"search_racks": "shelves",
		"country_germany": "Germany",
		"country_austria": "Austria",
		"country_switzerland": "Switzerland"
	},
	"auth": {
		"login": "sign in",
		"logout": "sign out",
		"user_has_app": "this user alread exists",
		"forget_text": "if you have forgotten your password, you can reset it by entering your e-mail address. We will generate a random password and send it to your e-mail address.",
		"forget": "reset password",
		"user_not_found": "a user with this e-mail does not exist",
		"wrong_password": "the user does not exist or the password is not correct",
		"welcome": "welcome back",
		"forgot_success_message": "your password was successfully reset. we have sent you an according e-mail"
	},
	"signup": {
		"version": "which plan works best for you?",
		"monthly_payment": "monthly payment",
		"yearly_payment": "yearly payment",
		"monthly": "monthly  ",
		"yearly": "yearly  ",
		"favourite_option": "most popular",
		"constantly_free": "no cost involved",
		"each_user": "per user and month",
		"signup_now": "register now",
		"upgrade_now": "change plan now",
		"free": "free",
		"basic": "basic",
		"basic-monthly": "basic (monthly)",
		"basic-yearly": "basic (yearly)",
		"premium-monthly": "premium (monthly)",
		"premium-yearly": "premium (yearly)",
		"premium-yearly2": "premium (yearly)",
		"coupon-no-cost": "voucher plan (yearly)",
		"premium": "premium",
		"free_subtitle": "no hastle testing",
		"space_each_plan": "{space} GB cloud space",
		"free_argument1": "limited to one folder",
		"free_argument3": "incl. 30 day premium plan for free",
		"basic_subtitle": "all basic functions",
		"basic_argument0": "12 monthy plan",
		"basic_argument1": "unlimited number of folders",
		"basic_argument3": "no advertising",
		"premium_subtitle": "the complete package",
		"premium_argument0": "12 month plan",
		"premium_argument1": "unlimited number of folders",
		"premium_argument3": "no advertising",
		"additional_month_1": "(+{month} month free of charge)",
		"additional_month_2": "({month} month free of charge)",
		"selected_plan": "your chosen plan",
		"change_plan": "change plan",
		"plan": "plan",
		"payment_type": "payment method",
		"licenses": "number of users",
		"today_summary": "today\u00b4s total amount",
		"summary": "total excl. VAT",
		"after_summary": "total incl. VAT",
		"change_after_time": "at the end of the 12 month term you will be automatically downgraded to the free plan",
		"coupon_code": "voucher number",
		"coupon_code_placeholder": "enter voucher number",
		"coupon_invalid": "voucher number is invalid",
		"coupon_valid": "voucher number is valid",
		"personal_heading": "personal information",
		"firstname": "first name",
		"fullname": "Fullname",
		"fullname_placeholder": "enter your fullname here",
		"firstname_placeholder": "enter your first name here",
		"lastname": "last name",
		"lastname_placeholder": "enter your last name here",
		"password": "password",
		"password_placeholder": "enter your password here",
		"password_confirm": "enter passwort again",
		"password_personal": "define your personal password",
		"password_personal_placeholder": "enter your personal password here",
		"email_address": "e-mail",
		"email_address_placeholder": "enter your e-mail here",
		"personal_information": "by registering your agree to our <a class=\"highlighted\" target=\"_blank\" href=\"https:\/\/www.digital-folder.com\/terms\">user terms<\/a>\n                            as well as saving and processing your personal information based on our\n                            <a class=\"highlighted\" target=\"_blank\" href=\"https:\/\/www.digital-folder.com\/wp-content\/uploads\/2019\/10\/Datenschutzerklaerung.pdf\">data security regularions<\/a> zu.",
		"continue": "forward",
		"back": "back",
		"confirm_not_equal": "the passwords do not match",
		"password_needs_several_chars": "the password requires 6 digits as well as small an capital letters and a number",
		"company_heading": "company information",
		"company": "name of the company",
		"company_placeholder": "enter the name of the company here",
		"country": "country",
		"country_placeholder": "enter the country here",
		"street": "street",
		"street_placeholder": "enter the street here",
		"postal_code": "zip code",
		"postal_code_placeholder": "enter the zip code here",
		"city": "city",
		"vat_id": "VAT number",
		"vat_id_placeholder": "enter VAT number here",
		"city_placeholder": "enter city here",
		"mediation": "recommended by ",
		"mediation_placeholder": "enter the recommender here",
		"additional_payment_info": "additional payment information",
		"payment_heading": "payment method",
		"payment_type_card": "credit card",
		"payment_type_card_info": "credit card informationen",
		"payment_type_iban": "SEPA ",
		"payment_type_iban_info": "IBAN",
		"payment_type_iban_info_text": "im am authorizing digital optimisation - gross & partner to use the SEPA method in order to process the payment for this purchase. I am aware that I can decline this again and request a refund within 8 weeks after the payment has been processed.",
		"payment_type_sofort": "Sofort",
		"payment_type_sofort_info": "",
		"payment_type_sofort_info_text": "By providing your payment information and confirming this payment, you authorise (A) Christoph Groß and Stripe, our payment service provider, to send instructions to your bank to debit your account and (B) your bank to debit your account in accordance with those instructions. As part of your rights, you are entitled to a refund from your bank under the terms and conditions of your agreement with your bank. A refund must be claimed within 8 weeks starting from the date on which your account was debited. Your rights are explained in a statement that you can obtain from your bank. You agree to receive notifications for future debits up to 2 days before they occur.",
		"summary_heading": "overview of your order",
		"price_incl_vat": "price excl. VAT",
		"cost": "cost",
		"terms_company": "i confirm that i am registering as a company",
		"terms_of_service": "i agree to the  <a href=\"https:\/\/www.digital-folder.com\/terms\" target=\"_blank\" class=\"highlighted\">general terms and conditions<\/a> zu.",
		"order_data_processing": " ",
		"signup_success": "you have successfully registered your digital folder\u00ae account. please confirm your account first.",
		"welcome_message": "you have been invited to registister yourself for a digital folder\u00ae in order to join a online team",
		"invite_not_found": null,
		"upgrade_success": "your account has been successfully updated",
		"current_plan": "currentl plan",
		"coupon_exists": "voucher number available? click here",
		"coupon_invalid_date": "the voucher number has expired",
		"coupon_already_used": "this voucher code has already been used",
		"company_signup_tooltip": "you are confirming the you will use the digital folder\u00ae as a company and not as a private person. the usage is currently only allowed for companies.",
		"verified": "you have successfully verified your account. you can now log in.",
		"account_not_verified": "you account could not be verified. please contact support@digital-folder.com",
		"not_verified": "your account has not been verified yet. we have sent you an e-mail for this verification. ",
		"countries_soon": "Currently the platform is only available in Germany, Austria and Switzerland. We will soon expand to other countries. Please be a bit patient. ",
		"company_advice": "(only if youn sign up as a company)",
		"licenses_info": "Please enter tthe total amout of users",
		"downgrade_disabled": "currently the downgrade option is not activated. Please cancel your curretn plan first and then chose the plan you want. Alternatively contact support@digital-folder.com",
		"safe_payment_options": "Safe payment options",
		"secure_ssl": "SSL Protection",
		"email_support": "24h E-Mail Support",
		"hosting_in_germany": "Hosting in Germany",
	},
	"general": {
		"hello": "Hello,",
		"greeting": "how can we help you?",
		"thank": "Thank you,",
		"answer_soon": "we will be contacting you soon",
		"send": "send",
		"help": "help",
		"email": "e-mail",
		"password": "password",
		"password_confirm": "repeat password",
		"validation_failed": "please fill in the following fields",
		"invalid_form": "not all fields were filled in correctly",
		"firstname": "first name",
		"lastname": "last name",
		"language": "language",
		"department": "department",
		"save": "save",
		"actions": "activities",
		"name": "name",
		"role": "role",
		"roles": "roles",
		"description": "description",
		"optional": "optional",
		"status": "status",
		"cancel": "cancel",
		"confirm": "confirm",
		"no_permissions": "you have no authorization for this activity",
		"yes": "yes",
		"no": "no",
		"ok": "o.k.",
		"no_app_found": "this instance of the digial folder does not exist anymore or has been blocked. please contact support@digital-folder.com",
		"app_blocked": "this ",
		"no_team_found": "this team does not exist (anymore) or the user has not been assigned to this team.",
		"unauthenticated": "please sign in again",
		"invited_at": "invited at",
		"accepted_at": "invitation accepted at",
		"message": "Message",
		"message_placeholder": "Your message here"
	},
	"display": {
		"help": "help",
		"open": "open folder",
		"wrong_password": "the password for this folder is not correct",
		"description_1": "you have received a link to open a digital folder\u00ae",
		"description_2": "to see the contenct, please enter the received password here",
		"description_3": "the digital folder\u00ae does not exist or does not exist anymore. the sender has possilby blocked the access.",
		"no_folder_found_headline": "we are sorry\u2026",
		"request_password": "have you forgotten your password?",
		"email_subject": "password request",
		"menu_open": "show table of content",
		"menu_hide": "hide table of content",
		"close": "close folder",
		"show_new_elements": "Show new elements",
		"hide_new_elements": "Hide new elements",
		"trial_period": "Free 30 days trial",
		"create_link": "Copy link to this page",
		"create_link_no_release": "The folder has been released to create a link to this page"
	},
	"settings": {
		"account": "account",
		"dashboard": "overview  ",
		"profile": "user profile",
		"security": "security",
		"organization": "organisation",
		"company": "company  ",
		"users": "user  ",
		"teams": "teams",
		"team": "team",
		"website": "website",
		"vat_number": "VAT number",
		"permissions": "roles and authorizations",
		"accounting": "invoicing",
		"plans": "change plan",
		"settings": "setup",
		"superadmin": "super setup",
		"payments": "payment method",
		"invoices": "invoices",
		"back": "back to the digital folder\u00ae",
		"password_change": "change passwort",
		"user_not_found": "the user could not be found",
		"description": "you can reset your password here. enter your current passwort, then the new passwort and repeat the entry.",
		"old_password": "current password",
		"new_password": "new password",
		"confirm": "repeat password",
		"save_changes": "save changes",
		"members_count": "number of team members",
		"invite_member": "invite team member",
		"invite_user": "invite user",
		"invite_description": "with this function you can invite internal and external users to participate in your digital folder\u00ae team by using the e-mail adress. if the user has not been registered yet, we will send out an e-mail with an invitation.",
		"create_team": "new team",
		"change_team": "change team",
		"change_role": "change role",
		"invoice_number": "invoice total",
		"invoice_date": "invoice total",
		"invoice_sum": "invoice total",
		"create_role": "new role",
		"create_payment_method": "new payment method",
		"permissions_for": "authorization for",
		"credentials": "account information",
		"default_method": "standard payment method",
		"change_password_successful": "the password was changed successfully",
		"change_avatar_successful": "the profile picture was changed successfully",
		"status_active": "active",
		"status_blocked": "blocked",
		"status_invited": "invited",
		"status_trialing": "test phase",
		"role_admin": "administrator",
		"role_user": "user",
		"block_user": "block user",
		"unblock_user": "unblock user",
		"block_description": "do you really want to block this user?",
		"unblock_description": "do you really want to unblock this user?",
		"select_team": "choose a team",
		"team_exists": "a team with this name already exists",
		"delete_team": "delete team",
		"delete_team_description": "do you really want to delete this team?",
		"leave_team": "leave team",
		"leave_team_description": "do you really want to leave this team?",
		"team_cant_delete": "team cannot be deleted as long as team members exist",
		"team_has_folders": "team cannot be deleted as long as associated folders or shelves exist",
		"team_not_found": "team could not be found",
		"team_removed_successful": "team successfully deleted",
		"team_leave_successful": "you left the team successfully",
		"team_members_empty": "this team has not members",
		"role_exists": "a role with this name does not exist",
		"payments_empty": "no payment methods have been defined yet",
		"default_payment_successful": "the payment method has been set to standard",
		"payment_method_not_found": "the payment method was not found",
		"updated_role_successful": "the authorizations have been saved successfully",
		"role_not_found": "the role could not be found",
		"delete_role": "delete role",
		"delete_role_description": "do you really want to delete this role?",
		"role_removed_successful": "the role has been successfully deleted",
		"role_is_used": "the role cannot be deleted as long as it is associated with a user",
		"user_has_been_invited_already": "the user has already been invited",
		"invited_successful": "the user has been successfully invited",
		"members_of": "member of",
		"team_kick_successful": "the team member has been successfully deleted from the list of team members",
		"team_kick_description": "the user will be deleted as team member and can then not access the team content anymore. do you really want to delete this user from the list of team members?",
		"team_kick": "delete from the team",
		"organisation_saved_successful": "the company information have been saved successfully",
		"invoice_status_draft": "in progress. (takes about 1 hour to process)",
		"invoice_status_open": "open  ",
		"invoice_status_paid": "payment received",
		"tile_folder": "digital folder\u00ae",
		"tile_objects": "media object - media objects",
		"tile_users": "user",
		"tile_space": "available space",
		"tile_user_contingent": "total users",
		"tile_user_out_of_max_users": "{users} of {max_users} user",
		"tile_space_out_of_space": "{space} of {max_space} GB",
		"tile_plan": "plan",
		"users_exceeded": "user limit has been reached. please upgrade your plan",
		"cancel_subscription": "cancel subscription",
		"cancel_subscription_success_message": "your cancellation has been logged. uou can use your digital folder\u00ae account untile the subscription ends.",
		"back_to_digital_folder": "bach to the shelf and media menu",
		"help": "help",
		"language_chooser": "select language",
		"remove_user": "remove user",
		"remove_user_message": "do you really want to remove this user?",
		"user_can_not_deleted": "the user cannot be removed. at least one adminstrator type user needs to be defined",
		"user_is_app_owner": "the user cannot be removed. it is the account owner.",
		"change_default_payment_method": "make this the standard payment method",
		"send_invitation": "send invitation again",
		"send_invitation_successful": "the invitation has been sent again",
		"user_can_not_blocked": "the user cannot be blocked since at least one admistration is required.",
		"cancel_subscription_message": "do your really want to cancel your plan?",
		"plan_info": "chose one of the plan options to upgrade your digital folder\u00ae plan or to get more users\/cloud space. ",
		"subscription_runtime": "your plan starts {start_date} and ends {end_date} incl. {discount} month (free) (payment cycle: {payment_interval})",
		"subscription_runtime_coupon": "your plan starts {start_date} and ends {end_date} incl. {discount} month (free + paid) (payment cycle: {payment_interval})",
		"subscription_canceled_at": "the plan was canceled on:",
		"status_needs_confirmation": "invited (confirmation required)",
		"notifications": "Notifications",
		"invite_message": "You've been invited by {company} in one or more teams ({teams}). Do you like to join them?",
		"no_notifications": "No notifications found",
		"invoice_info": "",
		"invite_support_description": "Invite the email address support@digital-folder.com to get support-access by one of our employees",
		"invite_account_status_based": "Invite any external user to work with you in a team. Just enter their e-mail. The invited will receive an invitation link via e-mail.\n" +
			"Should the user already have a valid premium account, then this license will be used. Should he not have one, then user license will be taken from your account. (you might have to purchase additional licenses) (only available with a premium account)",
	},
	"tooltips": {
		"remove_member": "remove the user as team member",
		"leave_team": "if you leave the team you will no longer have access to the folders and media of this team",
		"change_role": "you can defined the role of the users here",
		"edit_user": "edit user",
		"pay_now": "process payment",
		"download": "download this invoice now",
		"block_user": "block user to prevent the login to the system",
		"unblock_user": "activate the user to grant acces to the system",
		"permissions": "changes the authorizations for this role",
		"delete_role": "removes this role"
	},
	"designer": {
		"page": "page",
		"chapter": "chapter",
		"designer": "folder designer",
		"comment_placeholder": "enter folder comment here",
		"expand_all": "expand all",
		"collapse_all": "collapse all",
		"new_chapter": "new chapter",
		"new_page": "new page",
		"page_title": "page title",
		"chapter_title": "chapter title",
		"remove_page": "delete page",
		"remove_chapter": "delete chapter",
		"background_image": "background picture",
		"search_mediacenter": "search media center",
		"drop_file": "drop file or",
		"media_center_search": "search current selection",
		"selected_files": "selected files",
		"append_files": "place selected files",
		"folder_not_found": "the digital folder\u00ae could not be found",
		"all_media_containers": "all media containers",
		"cover": "folder cover page",
		"unnamed_chapter": "unknown chapter",
		"unnamed_page": "unknown page",
		"comment_save_message": "the comment was successfully saved",
		"comments": "folder comments",
		"clock": "clock",
		"comment_not_found": "the comment could not be found",
		"done": "done  ",
		"mark_success_message": "the comment was completed",
		"done_by": "completed by {user} at {date}",
		"save_folder_success": "the digital folder\u00ae was saved successfully",
		"unsaved_content": "do you want to leave the folder designer without saving your work?",
		"discard_changes": "discard changes",
		"save_changes": "save changes",
		"folder_is_locked": "the digital folder\u00ae has been frozen. changes cannot be saved.",
		"preview": "folder preview",
		"drag_chapter": "drag & drop to move the chapter to a different position",
		"drag_page": "move this element over another chapter or page to move it there",
		"back": "back to the folder menu",
		"more_file_info": "show more details",
		"less_file_info": "show less details",
		"cover_tooltip": "change between using a cover picture or applying a cover color here",
		"chapter_active": "active chapter",
		"chapter_inactive": "inactive chapter",
		"comments_not_allowed": "no comments allowed in the free plan, pleas upgrade to use comments",
		"change_page_layout_1": "change page layout - 1 element",
		"change_page_layout_2": "change page layout - 2 elements",
		"change_page_layout_4": "change page layout - 4 elements",
		"change_page_layout_6": "change page layout - 6 elements",
		"change_object": "click on picture to change media file",
		"image_mode": "display format",
		"image_mode_cover": "fill in",
		"image_mode_contains": "allign borders",
		"page_grid_too_much_items": "you have too many media objects on this page. in order to change the layout, please delete media objects to reach the desired layout format.",
		"note_tooltip": "comment available",
		"note_title_write": "add note",
		"note_title_read": "show comment",
		"note": "note",
		"note_placeholder": "Place your note here",
		"qr_code": "QR-Code",
		"qr_code_download": "Download QR-Code",
		"media_tooltip_word": "Toggles all media objects of type 'MS Word'",
		"media_tooltip_excel": "Toggles all media objects of type 'MS Excel'",
		"media_tooltip_powerpoint": "Toggles all media objects of type 'MS Powerpoint'",
		"media_tooltip_image": "Toggles all media objects of type 'Image'",
		"media_tooltip_file": "Toggles all media objects of type 'PDF'",
		"media_tooltip_link": "Toggles all media objects of type 'Link'",
		"media_tooltip_others": "Toggles all media objects of type 'Others'",
		"delete_chapter_message": "Are you sure to delete all chapters including all pages?",
		"delete_page_message": "Are you sure to delete this page including all contents?",
		"change_folder_background": "Toggles between colored or image background",
		"select_background_color": "Select background color",
		"select_background_image": "Select from media center",
		"reset_background": "Reset background color and -image",
		"show_as_new": "Show as new",
		"hide_as_new": "Hide as new",
	},
	"workspaces": {
		"racks": "shelves",
		"folderNRacks": "folder\/shelves",
		"search": "search site",
		"recent_used": "last used",
		"create_rack": "create new shelf",
		"create_folder": "new folder",
		"copy": "copy  ",
		"export": "export",
		"folder": "folder",
		"edit": "edit",
		"release": "release",
		"download": "download  ",
		"show": "display  ",
		"visible": "visible",
		"copy_link": "copy link",
		"hide": "hide",
		"move": "move  ",
		"activate": "activate",
		"deactivate": "deaktivate",
		"delete": "delete",
		"tags": "tags",
		"edit_shelf": "edit shelf",
		"create_shelf": "new shelf",
		"tag_placeholder": "enter tags",
		"shelf_name_placeholder": "enter shelf name here",
		"save_shelf": "save shelf",
		"folder_name": "folder name",
		"folder_name_placeholder": "enter folder name here",
		"shelf_name": "shelf name",
		"save_folder": "save folder",
		"shelf_color": "shelf color",
		"own_hex_value": "color HEX value",
		"cover_color": "cover color",
		"cover_image": "cover picture",
		"designer": "designer",
		"rack_with_name_exists": "a shelf with this name already exists",
		"save_rack_success_message": "the shelf was successfully saved",
		"remove_rack_confirm_title": "delete shelf",
		"remove_rack_confirm_message": "do you really want to delete this shelf?",
		"remove_rack_success_message": "the shelf was successfully deleted",
		"rack_not_found": "the shelf could not be found",
		"rack_has_folders": "the shelf cannot be deleted since there are still folders in the shelf",
		"sort_latest": "newest",
		"sort_last_used": "used last",
		"sort_alphabetically": "a-z",
		"sort_anti_alphabetically": "z-a",
		"sort_most_comments": "most comments",
		"sort_shared_containers": "shared media containers",
		"status_in_progress": "create date",
		"status_finished": "completed date",
		"status_released": "release date",
		"status_locked": "locked date",
		"status_archived": "archived date",
		"folder_not_found": "the digital folder\u00ae could not be found",
		"folder_with_name_exists": "a digital folder\u00ae with this name already exists",
		"change_status": "change status",
		"context_menu_status_in_progress": "in progress",
		"context_menu_status_finish": "complete",
		"context_menu_status_release": "released",
		"context_menu_status_lock": "block",
		"context_menu_status_archive": "archived  ",
		"status_changed_successful_message": "the status of the digital folder\u00ae has been changed successfully",
		"release_folder": "release folder",
		"release_folder_description": "in order to release your digital folder\u00ae for others to access, please activate it with the \"release order\" function.<br><br>\n you can optionally protect the access with a password. once activated, the recipients can only access the date after entering the correct password.",
		"activate_password": "activate password protection",
		"send_credentials": "send hyperlink and access data by e-mail",
		"activate_release": "activate release",
		"email_subject": "digital folder\u00ae - release",
		"password_placeholder": "enter pasword here",
		"email_message": "link to folder: {link}\n",
		"email_password": "password: {password}",
		"copied": "link has been copied",
		"save_password": "change password",
		"remove_folder_confirm_title": "delete digital folder\u00ae?",
		"remove_folder_confirm_message": "do you really want to delete this digital folder\u00ae?",
		"remove_folder_success_message": "the digital folder\u00ae has been deleted successfully",
		"copy_folder_success_message": "the digital folder\u00ae has been copied successfully",
		"move_folder_success_message": "one or more digital folder\u00aes have been moved successfully",
		"search_result": "search results",
		"search_no_result": "no {label} were found",
		"folder_limit_reached": "you can only add one folder in the free plan. Please upgrade in order to add more folders (only commerical use)",
		"sort_racks": "sorts the shelves new, e.g. alphabetically",
		"save_release": "Save release",
		"search_shelf": "Search rack",
		"released_message": "Released at {date} by {name}.",
		"display_type_folder": "Folder",
		"display_type_brochure": "Brochure",
		"display_type": "Display type of the digital folder",
		"folder_general_tab": "General",
		"folder_advanced_tab": "Advanced settings",
		"display_order_numbers": "Display numbers in table of contents",
		"merge": "Merge",
		"merge_success": "Both folders were successfully merged",
		"statistics": "Statistics",
		"statistics_reset": "reset statistics",
		"statistics_reset_message": "Do you like to reset statistics?",
		"statistics_reset_success": "The statistics has been successfully reset.",
		"statistics_folder_open_count": "Count of folder openings",
		"statistics_folder_open_first": "First time opened at",
		"statistics_folder_open_last": "Last time opened at",
		"statistics_folder_last_reset": "Last reset at",
		"is_folder_template": "Use folder as template?",
		"folderTemplates": 'folder templates',
		"context_menu_copy_only_template": "Copy only structure",
		"context_menu_copy_with_content": "Copy with cover images",
		"context_menu_copy_with_full_content": "Synchronize contents",
		"folder_template_global": "global template",
		"folder_template_team": "team-internal template",
		"folder_template_app": "team-external template",
		"no_folder_template": "dont use as template"
	},
	"permissions": {
		"global": "global authorizations",
		"teams": "team",
		"permissions": "roles and authorizations",
		"plans": "Invoicing",
		"show_dashboard_ui": "show overview\/cockpit",
		"desc_show_dashboard_ui": "allows the user to acces the overview\/cockpit in order to view all relevant statistics",
		"can_manage_organisation": "administer company information",
		"desc_can_manage_organisation": "allows the user to change the company logo, website and address of the company",
		"can_manage_users": "manage user",
		"desc_can_manage_users": "allows the user to invite or delete additional users",
		"can_manage_teams": "manage teams",
		"desc_can_manage_teams": "allows the user to add or delete teams or to invite users into a team",
		"can_manage_permissions": "manage authorizations",
		"desc_can_manage_permissions": "allows the user to change the authorizations for the roles or add new roles with authorizations.",
		"can_manage_plans": "manage payement details",
		"desc_can_manage_plans": "allows the user to change the payment methods, change the plan or view the invoices"
	},
	"mediacenter": {
		"search_container": "search in container",
		"search_mediacenter": "search in media center",
		"mediacenter": "media center",
		"containers": "media container ",
		"create_container": "new container",
		"create_new_container": "create new container",
		"edit_existing_container": "edit container",
		"save_container": "create container",
		"container_name_placeholder": "enter container name here",
		"create_object": "new media object",
		"update_object": "edit media object",
		"save_object": "create media object",
		"container_name": "container name",
		"media_name": "media name",
		"media_name_placeholder": "enter media name here",
		"container_color": "container color",
		"description": "description",
		"description_placeholder": "enter description here",
		"object_type": "object type",
		"file_upload": "file upload",
		"file": "media file",
		"file_upload_description": "drop file here or search",
		"url": "e.g. http:\/\/google.de",
		"website_url": "link",
		"objects": "object - objects",
		"object_name_placeholder": "enter media name here",
		"container_with_name_exists": "a media container with this name already exists",
		"remove_container_confirm_message": "do you really want to delet this media container?",
		"remove_container_confirm_title": "delete media container",
		"container_not_found": "the media container does not exist (anymore)",
		"remove_container_success_message": "the media container was successfully removed",
		"save_container_success_message": "the media container was successfuly saved",
		"save_object_success_message": "the media object was successfully saved",
		"save_objects_success_message": "one or more media objects were successfully saved",
		"remove_object_confirm_message": "do you really want to delete this media object?",
		"remove_object_confirm_title": "delete media object",
		"remove_object_success_message": "one or more media objects were successfully deleted",
		"media_not_found": "the media object could not be found",
		"not_downloadable": "the media object is not a file and thus cannot be downloaded",
		"move_object": "move one or more media objects",
		"move_object_success_message": "one or more media objects were moved successfully",
		"file_radio": "file  ",
		"link_radio": "link  ",
		"object_data": "object data",
		"references": "used in",
		"references_empty": "this media object is not used in any folder",
		"append_object": "please associate media object",
		"append_name": "please enter media name",
		"media_in_use": "the media object could not be deleted. it is already in use in a dgital folder",
		"size_exceeded": "space exceeded. please upgrade you plan in order to store more files.",
		"media_object_limit_type": "no links allowed in the free plan. please upgrade your plan in order to use hyperlinks. (only commercial use)",
		"select_container": "please select a media container first",
		"container_with_media_in_use": "the media container cannot be deleted. at lease on media file is already used in a digital folder\u00ae.",
		"invalid_url": "the associated link cannot be reached or is invalid",
		"thumbnail_processing": "preview is beeing created\u2026",
		"sort_containers": "sorts the media container e.g. alphabetically",
		"change_media_advice": "please note: the media object is already associated with a digital folder\u00ae. if you want to change the media content it will be changed is ALL associated folders!",
		"thumbnail": "thumbnnail",
		"filetype_mismatch": "this file type cannot be used to thumbnails, picture pages or folder covers",
		"select_from_mediacenter": "Select from mediacenter",
		"limit_exceeded": "Your file is too big. We only support 25MB each file.",
		"no_media_found": "No media objects found",
		"from_mediacenter": "from media center",
		"container_data": "Containerdaten",
		"share_container": "Share",
		"released": "shared",
		"unable_to_update_released_container": "You are not allowed to edit a shared media container",
		"unable_to_update_info": "This media container has been released by team: {team} and you are not allowed to edit it.",
		"uneditable_shared_media": "Shared media objects can only changed by their creators",
		"upload_activity": "Just a moment - your files will be uploaded.",
		"select_from_clipboard": "Paste from clipboard (CTRL + V)"
	}
}

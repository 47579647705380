export default [
	{
		group: 'settings.account',
		label: 'settings.dashboard',
		link: '/settings/dashboard',
		icon: 'chart-line',
		pack: 'fas',
		permissions: [
			'show_dashboard_ui'
		]
	},
	{
		group: 'settings.account',
		label: 'settings.notifications',
		link: '/settings/notifications',
		icon: 'bell',
		pack: 'far',
	},
	{
		group: 'settings.organization',
		label: 'settings.company',
		link: '/settings/organization',
		icon: 'building',
		pack: 'far',
		permissions: [
			'can_manage_organisation'
		]
	},
	{
		group: 'settings.account',
		label: 'settings.profile',
		link: '/settings/profile',
		icon: 'user',
		pack: 'fas',
	},
	{
		group: 'settings.account',
		label: 'settings.security',
		link: '/settings/security',
		icon: 'key',
		pack: 'fas',
	},
	{
		group: 'settings.organization',
		label: 'settings.users',
		link: '/settings/users',
		icon: 'user',
		pack: 'fas',
		permissions: [
			'can_manage_teams',
		]
	},
	{
		group: 'settings.organization',
		label: 'settings.teams',
		link: '/settings/teams',
		icon: 'users',
		pack: 'fas',
		permissions: [
			'can_manage_teams',
		]
	},
	{
		group: 'settings.organization',
		label: 'settings.permissions',
		link: '/settings/permissions',
		icon: 'unlock-alt',
		pack: 'fas',
		permissions: [
			'can_manage_permissions'
		]
	},
	{
		group: 'settings.accounting',
		label: 'settings.plans',
		link: '/settings/plans',
		icon: 'redo',
		pack: 'fas',
		permissions: [
			'can_manage_plans'
		]
	},
	{
		group: 'settings.accounting',
		label: 'settings.payments',
		link: '/settings/payments',
		icon: 'credit-card',
		pack: 'far',
		permissions: [
			'can_manage_plans'
		]
	},
	{
		group: 'settings.accounting',
		label: 'settings.invoices',
		link: '/settings/invoices',
		icon: 'file-invoice-dollar',
		pack: 'fas',
		permissions: [
			'can_manage_plans'
		]
	},

]

<template>
  <div class="c-search-input-wrapper">
    <input
      v-model="keyword"
      class="search__bar--small"
      :placeholder="placeholder"
      @input="$emit('input', keyword)"
    />
    <IconButton
      v-show="keyword"
      class="c-search-input-button"
      theme="primary"
      @click="reset"
      icon="images/icons/close--white.svg"
    />
  </div>
</template>

<script>
  import IconButton from '../buttons/IconButton'

  export default {
    props: [
      'placeholder'
    ],
    data() {
      return {
        keyword: null,
      }
    },
    methods: {
      reset() {
        this.keyword = null
        this.$emit('input', this.keyword)
      }
    },
    components: {
      IconButton
    }
  }
</script>

<style lang="scss" scoped>
  .c-search-input-wrapper {
    display: flex;

    .c-search-input-button {
      margin-left: 8px;
      flex-shrink: 0;
      width: 40px;
      height: 40px;

      img {
        max-width: 12px;
        max-height: 12px;
      }
    }
  }
</style>

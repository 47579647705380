<template>
  <SidebarLayout :title="$t('settings.profile')">
    <Loader v-if="loading"/>
    <b-columns>
      <b-column>
        <Avatar
          :path="data.avatar_path"
          :rounded="true"
          :id="data.id"
          type="user"
          @success="load"
        />
      </b-column>
    </b-columns>
    <b-card>
      <b-columns>
        <b-column>
          <b-field
            :label="$t('general.lastname')">
            <b-input
              v-model="data.lastname"
            />
          </b-field>
          <b-field
            :label="$t('general.firstname')">
            <b-input
              v-model="data.firstname"
            />
          </b-field>
          <b-field
            :label="$t('general.department')">
            <b-input
              v-model="data.department"
            />
          </b-field>
        </b-column>
        <b-column>
          <b-field
            :label="$t('general.email')">
            <b-input
              disabled
              v-model="data.email"
            />
          </b-field>
          <b-field
            :label="$t('general.language')">
            <b-select
              expanded
              v-model="data.language">
              <option
                :key="index"
                :value="language"
                v-for="(language, index) in availableLanguages">
                {{ $t('app.language_' + language) }}
              </option>
            </b-select>
          </b-field>
        </b-column>
      </b-columns>
    </b-card>
    <b-columns>
      <b-column>
        <b-button
          class="mt-1"
          type="is-success"
          :loading="loading"
          :disabled="loading"
          @click.prevent="save">
          {{ $t('general.save') }}
        </b-button>
      </b-column>
    </b-columns>
  </SidebarLayout>
</template>

<script>
  import SidebarLayout from '../../components/settings/SidebarLayout'
  import Avatar from '../../components/settings/Avatar'
  import Loader from '../../components/global/Loader'
  import APIFactory from '../../api/factory'
  import {mapGetters} from 'vuex'

  export default {
    computed: {
      ...mapGetters({
        userId: 'auth/userId',
        availableLanguages: 'app/availableLanguages',
      }),
    },
    data() {
      return {
        data: {},
        loading: false,
      }
    },
    created() {
      this.load()
    },
    methods: {
      load() {
        this.loading = true
        APIFactory.findProfile(this.userId)
        .then(res => {
          this.data = res.data
          this.loading = false
        })
      },
      save() {
        this.loading = true
        APIFactory.updateProfile(this.userId, this.data)
        .then(res => {
          this.loading = false
        })
      },
    },
    watch: {
      data: {
        deep: true,
        handler(newValue) {
          if (newValue && newValue.language) {
            this.$i18n.locale = newValue.language
            this.$router.push({
              query: {
                lang: newValue.language,
              },
            })
            .catch(err => {
            })
          }
        },
      },
    },
    components: {
      Avatar,
      Loader,
      SidebarLayout,
    },
  }
</script>

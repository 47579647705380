<template>
  <Layout
    :loading="loading"
    :title="$t('workspaces.racks')"
    icon="/images/icons/shelf-icon--active.svg"
    :show-back-toolbar="!showRacks && isMobileMode">
    <SplitLayout
      :title="title"
      :show="showRacks"
      @reload="loadRacks"
      route-param="rackId"
      :elements="sortedRacks"
      icon="/images/icons/shelf-icon--white.svg">
      <!-- Controls -->
      <template slot="sidebar-controls">
        <div class="flex gap-2">
          <RackModal
            :rack="newRack"
            @success="success"
            @reset="newRack = {}">
            <c-button
              theme="primary"
              icon="images/icons/add.svg">
              {{ $t('workspaces.create_shelf') }}
            </c-button>
          </RackModal>
          <c-button
            :outline="showArchivedItems"
            @click.prevent="toggleArchive"
            icon="images/icons/rework/archive.svg"
            v-tooltip="$t('workspaces.show_archived_elements')">
          </c-button>
        </div>

        <Dropdown
          :values="rackSortMethods"
          :default-value="rackSortMethod"
          @select="setCurrentSortMethod($event, 'rack')"
          :tooltip="$t('workspaces.sort_racks')"
        />
      </template>
      <!-- Content -->
      <LoadingComponent :load="loading">
        <FolderLayout
          @reload="loadRacks"
          @success="success"
          v-if="selectedRack"
          :rack="selectedRack"
          :templates="templates"
          @search="keyword = $event"
          :show-templates="showTemplates"
          :suggestable-tags="suggestableTags"
          @showTemplates="showTemplates = $event"
          @sort="setCurrentSortMethod($event, 'folder')"
          :folder-sort-methods="folderSortMethods">
          <Folder
            :index="index"
            :folder="folder"
            @reload="success"
            :folders="sortedFolders"
            :suggestable-tags="suggestableTags"
            v-for="(folder, index) in sortedFolders"
            :key="folder.id + '-' + folderSortMethod"
          />
        </FolderLayout>
      </LoadingComponent>
    </SplitLayout>
  </Layout>
</template>

<script>
  import LoadingComponent from '../components/global/LoadingComponent'
  import RackModal from '../components/workspace/modals/RackModal'
  import FolderLayout from '../components/workspace/FolderLayout'
  import Dropdown from '../components/global/dropdowns/Dropdown'
  import SplitLayout from '../components/layout/SplitLayout'
  import Button from '../components/global/buttons/Button'
  import Folder from '../components/workspace/Folder'
  import Layout from '../components/layout/Layout'
  import DropdownSorts from '../data/sort'
  import APIFactory from '../api/factory'
  import {mapGetters} from 'vuex'
  import PlanMixin from '@/mixins/plan'

  export default {
    computed: {
      ...mapGetters({
        isSuperadmin: 'auth/isSuperadmin',
        isMobileMode: 'app/isMobileMode',
        isFirstLogin: 'auth/isFirstLogin',
        forceShowRack: 'app/forceShowRack',
        lastSortMethodRack: 'ui/lastSortMethodRack',
        lastSortMethodFolder: 'ui/lastSortMethodFolder',
      }),
      showRacks() {
        if (this.isMobileMode) {
          return !this.$route.params.rackId || this.forceShowRack
        }
        return true
      },
      title() {
        return this.$t('workspaces.racks') + ' (' + (this.sortedRacks ? this.sortedRacks.length : 0) + ')'
      },
      selectedRack() {
        if (this.$route.params.rackId) {
          return this.racks.find(item => item.slug === this.$route.params.rackId)
        }
      },
      sortedRacks() {
        let racks = this.racks.filter(rack => (this.showArchivedItems && rack.archived) || (!this.showArchivedItems && !rack.archived))
        if (this.rackSortMethod) {
          let sortFunction = this.rackSortMethods.find(item => item.id === this.rackSortMethod)
          if (sortFunction) {
            return sortFunction.sort(racks)
          }
        }
        return racks
      },
      hasGlobalTemplatePermission() {
        let permissions = this.currentApp?.permissions
        if (permissions) {
          return permissions.includes('can_use_global_templates')
        }
      },
      sortedFolders() {
        if (this.folderSortMethod && this.selectedRack) {
          let folders = this.selectedRack.folders
          if (this.showTemplates) {
            folders = this.templates
          }
          let sortFunction = this.folderSortMethods.find(item => item.id === this.folderSortMethod)
          if (sortFunction) {
            folders = sortFunction.sort(folders)
          }

          if (this.keyword) {
            folders = folders.filter(item => {
              let hasName = item.name && item.name.toLowerCase().indexOf(this.keyword.toLowerCase()) > -1
              let hasTag = item.tags && item.tags.find(item => item.toLowerCase().indexOf(this.keyword.toLowerCase()) > -1)
              return hasName || hasTag
            })
          }
          return folders
        }
      },
      suggestableTags() {
        if (this.racks) {
          return this.racks
            .flatMap(r => r.folders)
            .map(f => f.tags)
            .filter(t => t && t.length > 0)
            .reduce((prev, acc) => prev.concat(acc), [])
        }
      }
    },
    created() {
      this.loading = true
      this.defaultRoute().then(() => {
        this.loadRacks()
        this.loadTemplates()

        // Set default sorts
        this.rackSortMethod = this.lastSortMethodRack
        this.folderSortMethod = this.lastSortMethodFolder
      }).finally(() => this.loading = false)
    },
    data() {
      return {
        racks: [],
        newRack: {},
        templates: [],
        keyword: null,
        loading: false,
        showTemplates: false,
        showArchivedItems: false,
        rackSortMethod: 'alphabetically',
        folderSortMethod: 'alphabetically',
        rackSortMethods: DropdownSorts.filter(item => item.usable.includes('rack')),
        folderSortMethods: DropdownSorts.filter(item => item.usable.includes('folder')),
      }
    },
    methods: {
      toggleArchive() {
        this.showArchivedItems = !this.showArchivedItems
      },
      success() {
        this.newRack = {}
        this.loadRacks()
        this.loadTemplates()
        this.showTemplates = false
      },
      loadTemplates() {
        if (this.hasPremiumPlan || this.isSuperadmin) {
          this.loading = true
          APIFactory.findFolderTemplates(
            this.$route.params.slug,
            this.$route.params.teamId)
          .then(res => {
            if (res && res.success) {
              this.templates = (res.data || []).filter(folder => {
                return folder.template !== 'global' || (folder.template === 'global' && this.hasGlobalTemplatePermission)
              })
            }
          })
          .finally(() => this.loading = false)
        }
      },
      loadRacks() {
        this.loading = true
        APIFactory.findRacks(
          this.$route.params.slug,
          this.$route.params.teamId)
        .then(res => {
          if (res && res.success) {
            this.racks = this.mapRacks(res.data)

            let rackId = this.sortedRacks && this.sortedRacks.length > 0
              ? this.sortedRacks[0].slug
              : null
            this.defaultRoute(rackId)
          }
        })
        .finally(() => this.loading = false)
      },
      mapRacks(racks) {
        return racks.map(item => {
          return {
            ...item,
            subtitle: item.folder_count + ' ' + this.$t('workspaces.folder')
          }
        })
      },
      defaultRoute(rackId) {
        let slug = this.$route.params.slug
        let teamId = this.$route.params.teamId

        if (!this.$route.params.slug) {
          let app = this.$store.getters['auth/defaultApp']
          if (app) {
            slug = app.slug
          }
        }

        if (!this.$route.params.teamId) {
          let app = this.$store.getters['auth/defaultApp']
          if (app && app.teams && app.teams[0]) {
            teamId = app.teams[0].slug
          }
        }

        let compiledRoute = '/' + slug + '/workspaces/' + teamId
        if (!this.isMobileMode) {
          if (rackId && (!this.$route.params.rackId || this.$route.params.rackId === 'undefined')) {
            compiledRoute += '/' + rackId
          } else {
            compiledRoute += '/' + this.$route.params.rackId
          }
        }

        if (this.isFirstLogin) {
          this.showTemplates = true
        }

        return this.$router
        .push(compiledRoute)
        .catch(err => {
          // Intercepts the error and dismisses it
        })
      },
      setCurrentSortMethod(sortMethod, type) {
        switch (type) {
          case 'folder':
            this.$store.commit('ui/setLastSortMethodFolder', sortMethod)
            this.folderSortMethod = sortMethod
            break
          case 'rack':
            this.$store.commit('ui/setLastSortMethodRack', sortMethod)
            this.rackSortMethod = sortMethod
            break
        }
      }
    },
    mixins: [
      PlanMixin,
    ],
    components: {
      LoadingComponent,
      FolderLayout,
      SplitLayout,
      RackModal,
      Dropdown,
      Button,
      Layout,
      Folder,
    }
  }
</script>

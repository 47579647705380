<template>
  <drop class="c__media-dropzone-wrapper" @drop="moveItem">
    <div :class="{
        	'c-media-dropzone': true,
        	'c-media-dropzone--readOnly': readOnly
        }" @click.prevent="toggleSidebar">
      <!-- Preview -->
      <template v-if="!!item">
        <drag :class="{
          'c-dropzone-preview': true,
          'c-dropzone-video': isItemAutoPlayable,
          'c-dropzone-preview--no-title': hideTitle
          }"
              :transfer-data="{ index: item.index, item }">
          <!-- Better solution in further versions -->
          <img
            :src="previewImage"
            v-if="!readOnly"
            v-tooltip="$t('designer.change_object')"
            :class="{
                'image--shadow': true,
                'image-mode--cover': item.image_mode === 'cover',
                'image-mode--contain': item.image_mode === 'contain',
              }">
          <template v-if="readOnly">
            <img
              :src="previewImage"
              :class="{
                'image--shadow': true,
                'image-mode--cover': item.image_mode === 'cover',
                'image-mode--contain': item.image_mode === 'contain',
              }" v-if="!isItemAutoPlayable"
              @click.prevent="show"
            />
            <div
              class="video-wrapper"
              v-if="isItemAutoPlayable">
              <iframe width="560" height="315" :src="createEmbeddedVideoURL" frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen></iframe>
            </div>
          </template>
          <!-- Context Menu -->
          <div class="c-dropzone-context" v-show="!hideContextMenu">
            <ElementContext
              v-if="!contextType || contextType === 'page'"
              @setNoteModalInformation="noteModalInformation = $event"
              @delete="$emit('delete', item)"
              @setImageMode="setImageMode"
              @setAsNew="setAsNew"
              :highlighted="item.note_highlighted"
              :key="item.id + '_' + item.index"
              :context-type="contextType"
              :read-only="readOnly"
              :preview="preview"
              :folder="folder"
              :element="item"
            />
            <ElementChapterContext
              @delete="$emit('delete', item)"
              :read-only="readOnly"
              :folder="folder"
              :element="item"
              v-else
            />
          </div>
        </drag>
        <div class="c-dropzone-meta" v-show="!hideTitle">
          <div class="c-dropzone-icon">
            <img :src="icon">
            <!-- Show as new marker -->
            <div
              class="showAsNewMarker"
              v-if="item.showAsNew">
              <img src="images/icons/star--yellow.svg"/>
            </div>
          </div>
          <div :class="{
            'c-dropzone-title': true,
            'c-dropzone-title--small': item.note,
            'c-dropzone-title--highlighted': item.highlighted,
            }">
            <b-tooltip
              :delay="tooltipDelay"
              :label="item.name"
              multilined
              style="width: 100%; word-wrap: break-word">
              <v-clamp autoresize :max-lines="2">{{ item.name }}</v-clamp>
            </b-tooltip>
          </div>
          <div class="c-dropzone-icon c-dropzone-icon--note" v-if="item.note">
            <b-tooltip
              :delay="tooltipDelay"
              :label="$t('designer.note_tooltip')"
              position="is-top"
              multilined>
              <img src="/images/icons/note--added.svg">
            </b-tooltip>
          </div>
        </div>
      </template>

      <!-- No preview -->
      <template v-else>
        <div :class="{
            'c-dropzone-container': true,
            'c-dropzone-container--readOnly': readOnly
          }">
          <div class="c-dropzone-empty" v-show="!readOnly">
            <div class="c-dropzone-icon">
              <img :src="icon"/>
            </div>
            <div class="c-dropzone-title">
              {{ $t('designer.search_mediacenter') }}
            </div>
          </div>
        </div>
      </template>
    </div>
    <MediaDropzoneSidebar
      v-if="active"
      :active="active"
      :containers="containers"
      :maximum-items="maximumItems"
      :maximum-selectable-items="maximumSelectableItems"
      :file-extensions="fileExtensions"
      @close="closeSidebar"
      @place-item="placeItem"
    />
    <NoteModal
      v-if="!!noteModalInformation"
      :active="!!noteModalInformation"
      :element="noteModalInformation.element"
      :read-only="readOnly"
      @close="noteModalInformation = null"
    />
  </drop>
</template>

<script>
  import VClamp from 'vue-clamp'
  import {mapGetters} from 'vuex'
  import Icon from '../global/Icon'
  import ElementContext from './ElementContext'
  import MediaDropzoneSidebar from './MediaDropzoneSidebar'
  import NoteModal from "./modals/NoteModal";
  import ChapterContext from '@/components/designer/chapters/ChapterContext';
  import ElementChapterContext from '@/components/designer/ElementChapterContext';

  export default {
    props: [
      'maximum-selectable-items',
      'hide-context-menu',
      'file-extensions',
      'maximum-items',
      'context-type',
      'containers',
      'hide-title',
      'read-only',
      'preview',
      'folder',
      'item'
    ],
    computed: {
      ...mapGetters({
        tooltipDelay: 'ui/tooltipDelay'
      }),
      icon() {
        if (this.item) {
          switch (this.item.preview_type) {
            case 'powerpoint':
            case 'excel':
            case 'word':
            case 'image':
              return '/images/icons/datatypes/' + this.item.preview_type + '.svg'
            case 'link':
              return '/images/icons/datatypes/link.svg'
          }
        }
        return '/images/icons/datatypes/file.svg'
      },
      previewImage() {
        switch (this.item.preview_type) {
          case 'powerpoint':
          case 'excel':
          case 'word':
          case 'link':
            if (this.item.preview) {
              return this.item.preview
            }
            return '/images/objects/' + this.item.preview_type + '.svg'
          case 'pdf':
            if (this.item.preview) {
              return this.item.preview
            }
            return '/images/objects/file.svg'
          case 'image':
            return this.item.preview
        }

        if ('custom' === this.item.preview_type && this.item.preview) {
          return this.item.preview
        }

        return '/images/objects/file.svg'
      },
      isItemAutoPlayable() {
        return this.item.preview_type === 'link'
          && this.item.data
          && (this.item.data.subtype === 'vi' || this.item.data.subtype === 'yt')
      },
      createEmbeddedVideoURL() {
        switch (this.item.data.subtype) {
          case 'yt':
            return 'https://youtube.com/embed/' + this.item.data.video_id
          case 'vi':
            return 'https://player.vimeo.com/video/' + this.item.data.video_id
        }
      }
    },
    data() {
      return {
        active: false,
        noteModalInformation: null,
      }
    },
    methods: {
      placeItem($event) {
        this.$emit('place-item', $event)
        this.$emit('hide-context-menu', false)
        this.active = false
      },
      moveItem($event) {
        this.$emit('move-item', $event.index)
      },
      toggleSidebar() {
        if (!this.readOnly) {
          this.active = true
          this.$emit('hide-context-menu', true)
        }
      },
      closeSidebar() {
        this.active = false
        this.$emit('hide-context-menu', false)
      },
      setImageMode($event) {
        this.$set(this.item, 'image_mode', $event)
      },
      setAsNew($event) {
        this.$set(this.item, 'showAsNew', $event)
        this.$forceUpdate()
        this.$emit('setAsNew', $event)
      },
      /**
       * Previews the file
       */
      show() {
        let element = document.createElement('a')
        if (this.item.preview_type === 'link') {
          let url = this.item.data.value
          if (!url.startsWith('http://') && !url.startsWith('https://')) {
            url = 'http://' + url
          }
          element.setAttribute('href', url)
          element.setAttribute('target', '_blank')
          element.click()
          element.remove()
        }

        if (this.item.preview_type === 'image') {
          let w = window.open('about:blank')
          if (w) {
            let image = new Image()
            image.src = this.item.preview
            setTimeout(() => {
              w.document.write(image.outerHTML)
            }, 0)
          }
        }
      },
    },
    components: {
      ElementChapterContext,
      ChapterContext,
      NoteModal,
      Icon,
      VClamp,
      ElementContext,
      MediaDropzoneSidebar,
    }
  }
</script>

<style lang="scss" scoped>
  .c__media-dropzone-wrapper {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    position: relative;
    align-content: center;
    flex-direction: column;
    justify-content: center;
  }

  /*
   *  Dropzone-Container
   */
  .c-dropzone-container {
    width: 100%;
    height: 100%;
    display: flex;
    cursor: pointer;
    border-radius: 8px;
    align-items: center;
    justify-content: center;
    border: 1px dashed $dark-gray-color;

    &.c-dropzone-container--readOnly {
      border: none;
      cursor: default;
    }

    .c-dropzone-empty {
      .c-dropzone-icon,
      .c-dropzone-title {
        @apply
          flex
          justify-center;
        font-size: 14px;
        font-weight: 500;
        text-align: center;
        font-family: Roboto;
        color: $primary-color;
        text-decoration: underline;

        img {
          width: 24px;
          height: 24px;
        }
      }
    }
  }

  .c-media-dropzone {
    width: 100%;
    height: 100%;
    cursor: pointer;

    &.c-media-dropzone--readOnly {
      cursor: default;
    }

    .c-dropzone-video {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .c-dropzone-preview {
      width: 100%;
      height: calc(100% - 32px);
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;

      &.c-dropzone-preview--no-title {
        height: 100%;
      }

      img {
        object-fit: cover;
        border-radius: 8px;
        width: 100%;
        max-height: 100%;

        &.image-mode--cover {
          object-fit: cover;
          width: 100%;
          height: 100%;
        }

        &.image-mode--contain {
          object-fit: contain;
        }

        &.image--shadow {
          box-shadow: 2px 2px 5px 0 rgba(166, 166, 166, 1);
        }
      }

      .c-dropzone-context {
        top: 8px;
        right: 8px;
        position: absolute;
      }
    }

    .c-dropzone-meta {
      width: 100%;
      display: flex;
      margin-top: 8px;

      .c-dropzone-icon {
        flex-shrink: 0;
        margin-right: 8px;

        &.c-dropzone-icon--note {
          margin-right: 0;
          margin-left: 8px;
        }

        .showAsNewMarker {
          width: 16px;
          height: 16px;
          display: flex;
          align-content: center;
          justify-content: center;
        }
      }

      .c-dropzone-title {
        color: $darker-blue-color;
        font-family: Roboto;
        font-weight: 500;
        width: calc(100% - 24px);
        min-height: 24px;
        font-size: 14px;
        padding: 0 4px;

        &.c-dropzone-title--small {
          width: calc(100% - 48px);
        }

        &.c-dropzone-title--highlighted {
          background: $yellow-color;
          border-radius: 4px;
        }
      }
    }
  }

  .video-wrapper {
    @apply
      flex
      w-full
      h-full
      absolute
      items-center;
    //padding-bottom: 56.25%; /* 16:9 */

    iframe {
      //position: absolute;
      top: 0;
      left: 0;
      max-width: 100%;
      max-height: 100%;
      border-radius: 8px;
      box-shadow: 2px 2px 5px 0 rgba(166, 166, 166, 1);
    }
  }
</style>

<template>
  <main id="app">
    <router-view />

    <!-- Help -->
    <help-bubble
      :loading="loading"
      v-if="viewBasedHelpContents && viewBasedHelpContents.length">
      <help-content
        :key="index"
        :link="content.link"
        :description="content.description"
        v-for="(content, index) in viewBasedHelpContents"
        :class="{'mb-2': index != (helpContents.length - 1)}">
        {{ $t(content.title) }}
      </help-content>
    </help-bubble>
  </main>
</template>

<script>
  import APIFactory from '@/api/factory'
  import {mapGetters} from 'vuex'

  export default {
    computed: {
      ...mapGetters({
        genericView: 'help/genericView',
        helpContents: 'help/helpContents'
      }),
      viewBasedHelpContents() {
        let view = null
        if (this.$route.path.includes('media-center')) {
          view = 'mediacenter'
        } else if (this.$route.path.includes('designer')) {
          view = 'designer'
        } else if (this.$route.path.includes('folder/')) {
          view = 'display'
        } else if (this.$route.path.includes('workspace')) {
          view = 'workspace'
        } else if (this.$route.path.includes('settings')) {
          view = 'settings'
        }

        if (this.genericView) {
          view = 'generic'
        }

        return this.helpContents.filter(c => {
          return c.area === view
        }).sort((c1, c2) => c1.order - c2.order)
      }
    },
    data() {
      return {
        loading: false,
      }
    },
    created() {
      window.addEventListener('resize', this.handleResizeEvent, false)
      window.dispatchEvent(new Event('resize'))
      this.handleLanguageCode()
      this.loadHelpContents()
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.handleResizeEvent, false)
    },
    methods: {
      handleResizeEvent() {
        this.$store.commit('app/setWidth', window.innerWidth)
      },
      handleLanguageCode() {
        let language = 'de'
        if (this.$route.query && this.$route.query.lang) {
          language = this.$route.query.lang
        }
        this.$i18n.locale = language
      },
      loadHelpContents() {
        this.loading = true
        APIFactory.findHelpContentsForUsers().then(res => {
          if (res && res.success) {
            this.$store.commit('help/setHelpContents', res.data)
          }
        }).finally(() => this.loading = false)
      },
    },
    watch: {
      '$route'() {
        this.handleLanguageCode()
      }
    }
  }
</script>

import moment from 'moment'

export default {
	mapUser(self, user, index) {
		user.index = index + 1
		if (user.email === 'support@digital-folder.com') {
			user.role_name = 'Support'
			user.index = null
		}

		if (user.invited_at) {
			user.invited_at = moment(user.invited_at).format('DD.MM.YYYY HH:mm')
		}

		if (user.accepted_at) {
			user.accepted_at = moment(user.accepted_at).format('DD.MM.YYYY HH:mm')
		}

		return {
			id: user.id,
			index: user.index,
			email: user.email,
			role_id: user.role_id,
			lastname: user.lastname,
			firstname: user.firstname,
			blocked: user.status === 'blocked',
			status: self.$t('settings.status_' + user.status),
			role_name: self.$te('settings.role_' + user.role_name)
				? self.$t('settings.role_' + user.role_name)
				: user.role_name,
			invited_at: user.invited_at,
			accepted_at: user.accepted_at,
		}
	},
	mapRole(self, role) {
		return {
			id: role.id,
			app_id: role.app_id,
			name: self.$te('settings.role_' + role.name) ? self.$t('settings.role_' + role.name) : role.name,
		}
	},
	mapTeam(self, model) {
		return {
			id: model.id,
			name: model.name,
			app_id: model.app_id,
			inside: model.inside
		}
	},
	mapPaymentMethod(self, model) {
		return {
			id: model.id,
			default: model.default,
			last4: '********' + model.last4,
			type: self.$t('signup.payment_type_' + model.type),
			default_text: model.default ? self.$t('general.yes') : self.$t('general.no')
		}
	},
	map(self, model, type) {
		if (Array.isArray(model)) {
			return model.map((item, index) => {
				return this._map(self, item, type, index)
			})
		}
		return this._map(self, model, type, 0)
	},
	_map(self, model, type, index) {
		switch (type) {
			case 'User':
				return this.mapUser(self, model, index)
			case 'Role':
				return this.mapRole(self, model)
			case 'Team':
				return this.mapTeam(self, model)
			case 'PaymentMethod':
				return this.mapPaymentMethod(self, model)
			default:
				return model
		}
	}
}

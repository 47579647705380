import { ToastProgrammatic as Toast} from 'buefy'
import $i18n from '@/lang'

export default {
	copyToClipboard(value, successMessage) {
		let el = document.createElement('textarea')
		if (el) {
			el.value = value
			document.body.appendChild(el)
			el.select()
			document.execCommand('copy')
			document.body.removeChild(el)
		}
		this.callToast('is-success', successMessage)
	},
	randomPassword() {
		return Math.random().toString(36).substring(2, 15)
	},
	randomizePassword() {
		let password = '';
		let requiredSigns = [
			'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
			'0123456789',
		]
		let caseSwitch = Math.random() > .5

		for (let i = 0; i < 6; i++) {
			let char = Math.floor(Math.random() * requiredSigns[0].length + 1)
			let casedChar = requiredSigns[0].charAt(char)
			if (caseSwitch) {
				casedChar = casedChar.toLowerCase()
			}
			password += casedChar
			caseSwitch = !caseSwitch
		}

		for (let i = 0; i < 3; i++) {
			let char = Math.floor(Math.random() * requiredSigns[1].length + 1)
			let casedChar = requiredSigns[1].charAt(char)
			password += casedChar
		}
		this.visible = true
		return password
	},
	createUUID() {
		return [
			Math.random().toString(16).slice(2, 10),
			Math.random().toString(16).slice(2, 6),

			// Set the four most significant bits (bits 12 through 15) of the
			// time_hi_and_version field to the 4-bit version number from Section
			// 4.1.3
			(Math.random() * .0625 /* 0x.1 */ + .25 /* 0x.4 */).toString(16).slice(2, 6),

			// Set the two most significant bits (bits 6 and 7) of the
			// clock_seq_hi_and_reserved to zero and one, respectively
			(Math.random() * .25 /* 0x.4 */ + .5 /* 0x.8 */).toString(16).slice(2, 6),

			Math.random().toString(16).slice(2, 14)]
		.join('-');
	},
	validate(context, fields) {
		return fields
			.filter(item => item.required)
			.filter(item => context[item]).length === 0
	},
	field(field, fields) {
		let formField = fields.find(item => item.field === field)
		if (formField) {
			return formField.value
		}
	},
	callToast(type, message, duration, queue) {
		duration = duration || 3000
		Toast.open({
			duration: duration,
			queue: queue,
			message,
			type,
		})
	},
	/**
	 * Creates all workspaces app comprehensive. E.g.: Team #1 (Appname)
	 * @param apps
	 * @returns {*}
	 */
	createWorkspaces(apps) {
		if (apps) {
			return apps.flatMap(app => {
				return app.teams.map(team => {
					return {
						id: team.slug,
						app: app.slug,
						label: team.name + ' (' + app.label + ')'
					}
				})
			})
		}
	},
	isDigit(char) {
		let regExp = /^\d$/
		if (regExp) {
			return regExp.test(char)
		}
	},
	/**
	 *  Returns true, if the password passes all tests
	 */
	checkPassword(password) {
		let passwordHasLengthOfSix = password && password.length > 5
		let passwordHasUppercaseChar = false
		let passwordHasLowercaseChar = false
		let passwordHasNumber = false

		for (let i = 0; i < password.length; i++) {
			let char = password[i]
			if (char) {
				let isCharUpperCase = char === char.toUpperCase()
				if (isCharUpperCase && this.isLetter(char)) {
					passwordHasUppercaseChar = true
				}
				let isCharLowerCase = char === char.toLowerCase()
				if (isCharLowerCase && this.isLetter(char)) {
					passwordHasLowercaseChar = true
				}

				let isCharNumber = this.isDigit(char)
				if (isCharNumber) {
					passwordHasNumber = true
				}
			}
		}

		return passwordHasLengthOfSix
			&& passwordHasUppercaseChar
			&& passwordHasLowercaseChar
			&& passwordHasNumber
	},
	isLetter(str) {
		return str.length === 1 && str.match(/[a-z]/i)
	},
}

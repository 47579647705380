<template>
    <div class="c__task">
        <div class="c__task-icon">
            <img :src="icon">
        </div>
        <div class="c__task-details">
            <div class="c__task-user">
                <div class="c__task-username">{{ task.user }}</div>
                <div class="c__task-date">{{ task.created_at | datetime }} {{ $t('designer.clock') }}</div>
            </div>
            <div class="c__task-message">{{ task.content }}</div>
            <div class="c__task-actions">
                <Button
                    size="tiny"
                    theme="light"
                    @click="mark"
                    :loading="loading"
                    v-if="!task.done_at"
                    :disable-icon-view-only-on-mobile="true">
                    {{ $t('designer.done') }}
                </Button>
                <div
                    v-if="task.done_at"
                    class="c__task-message-container">
                    <img src="/images/icons/check.svg">
                    <div class="c__task-solved-message">{{ $t('designer.done_by', translationObject) }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Button from '../../global/buttons/Button'
    import APIFactory from '../../../api/factory'
    import Helpers from '../../../helpers'
    import moment from 'moment'

    export default {
    	props: [
    		'task'
        ],
        computed: {
    		translationObject() {
    			return {
    				user: this.task.done_by,
                    date: moment(this.task.done_at).format('DD.MM.YYYY HH:mm') + ' ' + this.$t('designer.clock')
                }
            },
            icon() {
	            return this.task && this.task.avatar ? this.task.avatar : 'images/do-logo.svg'
            }
        },
    	data() {
    		return {
                loading: false,
            }
        },
        methods: {
            mark($event) {
            	$event.stopPropagation()
	        	this.loading = true
                APIFactory.markAsDone(this.$route.params.slug, this.$route.params.teamId, this.task.id).then(res => {
                	if (res && res.success) {
                		Helpers.callToast('is-success', this.$t('designer.mark_success_message'))
                        this.$emit('success', res.data.comments)
                    }
                }).finally(() => this.loading = false)
            }
        },
    	components: {
		    Button
        }
    }
</script>

<style lang="scss" scoped>
    .c__task {
        display: flex;
        padding: 16px 24px;
        border-radius: 4px;
        background: $light-blue-color;

        &:not(:first-child) {
            margin-top: 16px;
        }

        .c__task-message-container {
            display: flex;

            .c__task-solved-message {
                font-size: 14px;
                margin-left: 8px;
                color: $dark-gray-color;
                font-family: Roboto, 'sans-serif';
            }
        }

        .c__task-details {
            width: 100%;
            margin-left: 8px;

            .c__task-user {
                display: flex;
                font-size: 14px;
                justify-content: space-between;
                font-family: Roboto, 'sans-serif';

                .c__task-date { color: $dark-gray-color; }
                .c__task-username { color: $primary-color; }
            }
            .c__task-message {
                margin: 8px 0;
                font-size: 14px;
                word-break: break-all;
                color: $darker-blue-color;
                font-family: Roboto, 'sans-serif';
            }
        }

        .c__task-icon {
            width: 30px;
            flex-shrink: 0;

            img {
                width: 30px;
                height: 30px;
                object-fit: cover;
                border-radius: 50%;
            }
        }
    }
</style>

import Preview from '../../views/viewer/Preview'
import Display from '../../views/viewer/Display.vue'
import MediaViewer from '../../views/viewer/MediaViewer'
import Maintenance from '../../views/Maintenance'

export default [
	{
		path: '/:slug/folder/:teamId/:id/preview',
		component: Preview,
		meta: {
			auth: true,
		}
	},
	{
		name: 'ext-folder-prev',
		path: '/folder/:code',
		component: Display,
	},
	{
		path: '/:slug/object-viewer/:teamId/:id',
		component: MediaViewer
	},
	{
		path: '/:code/object-viewer/:password/:id/public',
		component: MediaViewer
	},
	{
		path: '/maintenance',
		component: Maintenance
	}
]

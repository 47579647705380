import Dashboard from '../../views/admin/Dashboard'
import Customers from '../../views/admin/Customers'
import Customer from '../../views/admin/Customer'
import Coupons from '../../views/admin/Coupons'
import Coupon from '../../views/admin/Coupon'
import HelpContents from '@/views/admin/HelpContents'
import HelpContent from '@/views/admin/HelpContent'
import Languages from '@/views/admin/Languages'

export default [
	{
		path: '/admin/info',
		component: Dashboard,
		meta: {
			auth: true
		}
	},
	{
		path: '/admin/customers',
		component: Customers,
		meta: {
			auth: true
		}
	},
	{
		path: '/admin/customers/:id',
		component: Customer,
		meta: {
			auth: true
		}
	},
	{
		path: '/admin/coupons',
		component: Coupons,
		meta: {
			auth: true,
		}
	},
	{
		path: '/admin/coupons/:id',
		component: Coupon,
		meta: {
			auth: true,
		}
	},
	{
		path: '/admin/languages',
		component: Languages,
		meta: {
			auth: true,
		}
	},
	{
		path: '/admin/help-contents',
		component: HelpContents,
		meta: {
			auth: true,
		}
	},
	{
		path: '/admin/help-contents/:id',
		component: HelpContent,
		meta: {
			auth: true,
		}
	}
]

<template>
  <Context
      :small="false"
      @select="select"
      :entries="entries"
      :transparent="true">
    <img src="/images/icons/options.svg"/>
  </Context>
</template>

<script>
import Helpers from '../../helpers'
import Context from '../global/Context'
import APIFactory from '../../api/factory'

export default {
  props: [
    'object',
    'disabled'
  ],
  data() {
    return {
      entries: []
    }
  },
  created() {
    this.entries = [
      {icon: '/images/icons/edit.svg', title: 'workspaces.edit', function: 'edit', sort: 0},
      {icon: '/images/icons/move.svg', title: 'workspaces.move', function: 'move', sort: 2},
      {icon: '/images/icons/delete.svg', title: 'workspaces.delete', function: 'confirmPreflightDelete', sort: 3, disabled: this.disabled}
    ]

    if (this.object && this.object.data && this.object.data.type) {
      switch (this.object.data.type) {
        case 'file':
          this.entries = this.entries.concat([{
            icon: '/images/icons/download.svg',
            title: 'workspaces.download',
            function: 'download',
            sort: 1
          }])

          if (this.object.preview_type === 'pdf'
              || this.object.preview_type === 'image'
              || this.object.preview_type === 'text'
              || this.object.preview_type === 'word'
              || this.object.preview_type === 'excel'
              || this.object.preview_type === 'powerpoint') {
            this.entries = this.entries.concat([{
              icon: '/images/icons/preview.svg',
              title: 'workspaces.show',
              function: 'preview',
              sort: 1
            }])
          }
          break
        case 'link':
          this.entries = this.entries.concat([{
            icon: '/images/icons/preview.svg',
            title: 'workspaces.show',
            function: 'show',
            sort: 1
          }])
          this.entries = this.entries.concat([{
            icon: '/images/icons/datatypes/link.svg',
            title: 'workspaces.copy_link',
            function: 'copyLink',
            sort: 1
          }])
          break
      }
      this.entries = this.entries.sort((i1, i2) => i1.sort - i2.sort)
    }
  },
  methods: {
    select($event) {
      if ($event && $event.function && this[$event.function]) {
        this[$event.function]()
      }
    },
    /**
     *  Edits the current media object.
     */
    edit() {
      this.$emit('edit', {
        element: this.object
      })
    },
    /**
     *  Preflight the current Delete Request. After confirming the function
     *  will be performed and the object will be deleted.
     */
    confirmPreflightDelete() {
      this.$emit('delete', {
        function: () => {
          return this.delete(this.object.id)
        },
        title: this.$t('mediacenter.remove_object_confirm_title'),
        message: this.$t('mediacenter.remove_object_confirm_message'),
      })
    },
    /**
     *  Needs to return a promise
     */
    delete(id) {
      return APIFactory.removeMediaObject(this.$route.params.slug, this.$route.params.teamId, id)
          .then(res => {
            if (res && res.success) {
              Helpers.callToast('is-success', this.$tc('mediacenter.remove_object_success_message'), 1)
            }
          })
    },
    /**
     *  Shows the web page in another tab. It prepend https/http protocol in front if it does not exists
     */
    show() {
      let element = document.createElement('a')
      if (element) {
        let url = this.object.data.value
        if (!url.startsWith('http://') && !url.startsWith('https://')) {
          url = 'http://' + url
        }
        element.setAttribute('href', url)
        element.setAttribute('target', '_blank')
        element.click()
        element.remove()
      }
    },
    /**
     *  Downloads a file which is in storage path / public of the app
     */
    download() {
      APIFactory.downloadMediaObject(this.$route.params.slug, this.$route.params.teamId, this.object.id)
          .then(res => {
            let blob = new Blob([res])
            let link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = this.object.data.value
            link.click()
          })
    },
    /**
     *  Shows an image as preview in a new tab
     */
    preview() {
      let w = null
      switch (this.object.preview_type.toLowerCase()) {
        case 'image':
          w = window.open('about:blank')
          let image = new Image()
          image.src = this.object.preview
          setTimeout(() => {
            w.document.write(image.outerHTML)
          }, 0)
          break
        case 'powerpoint':
        case 'excel':
        case 'word':
        case 'text':
        case 'pdf':
          let viewerURL = window.location.origin
              + '/#/' + this.$route.params.slug
              + '/object-viewer'
              + '/' + this.$route.params.teamId
              + '/' + this.object.id
          w = window.open(viewerURL)
          break
      }
    },
    /**
     *  Move
     */
    move() {
      this.$emit('move', {
        object_ids: [this.object.id]
      })
    },
    /**
     *   Copies link into clipboard
     */
    copyLink() {
      let el = document.createElement('textarea')
      if (el) {
        el.value = this.object.data.value
        document.body.appendChild(el)
        el.select()
        document.execCommand('copy')
        document.body.removeChild(el)
      }
    }
  },
  components: {
    Context
  }
}
</script>

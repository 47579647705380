<template>
  <div class="modal__button-wrapper">
    <b-tooltip
      :delay="tooltipDelay"
      :label="$t('workspaces.edit')">
      <template v-if="slim">
        <b-button
          class="ml-1"
          icon-pack="fas"
          type="is-primary"
          icon-left="pencil-alt"
          @click.prevent="active = true"
        />
      </template>
      <template v-else>
        <b-button
          class="mt-2"
          type="is-primary"
          @click.prevent="active = true">
          {{ $t('settings.create_team') }}
        </b-button>
      </template>
    </b-tooltip>
    <Modal
      :active="active"
      @close="active = false"
      :title="$t('settings.create_team')">
      <ErrorMessage
        v-if="error"
        :message="$t('general.invalid_form')"
      />
      <b-columns>
        <b-column>
          <b-field
            class="mt-2"
            :label="$t('general.name')">
            <b-input
              v-model="name"
            />
          </b-field>

          <b-button
            expanded
            class="mt-2"
            type="is-primary"
            :loading="loading"
            :disabled="loading"
            @click.prevent="save">
            {{ $t('general.save') }}
          </b-button>
        </b-column>
      </b-columns>
    </Modal>
  </div>
</template>

<script>
  import Modal from './Modal'
  import APIFactory from '../../../api/factory'
  import ErrorMessage from '../../ErrorMessage'
  import {mapGetters} from "vuex";

  export default {
    props: [
      'slim',
      'team',
    ],
    computed: {
      ...mapGetters({
        tooltipDelay: 'ui/tooltipDelay',
      })
    },
    data() {
      return {
        name: null,
        error: false,
        active: false,
        loading: false,
      }
    },
    created() {
      if (this.team) {
        this.name = this.team.name
      }
    },
    methods: {
      save() {
        if (!this.name) {
          this.error = true
          return
        }

        this.error = false
        this.loading = true
        this.$emit('success')

        let p = this.team
          ? APIFactory.updateTeam(this.$route.params.slug, this.team.id, this.name)
          : APIFactory.createTeam(this.$route.params.slug, this.name)
        p.then(res => {
          this.loading = false
          if (res && res.success) {
            if (!this.team) {
              this.name = null
            }
            this.active = false
            this.$emit('success')
          }
        })
      }
    },
    components: {
      ErrorMessage,
      Modal,
    }
  }
</script>

<template>
  <base-modal
    :title="title"
    :active="active"
    @close="$emit('close')">
    <div class="mt-4">
      <Dropdown
        :values="dropdowns"
        :entries-parent-size="true"
        :default-value="currentDropdown"
        @select="selectedContainer = $event"
      />
      <input
        @click.stop=""
        v-model="keyword"
        class="search__bar u-fw mt-4"
        :placeholder="$t('designer.media_center_search')"
      />

      <div class="media-sidebar-contents mt-4">
        <Object
          class="m-0"
          :key="object.id"
          :object="object"
          :enable-selection="true"
          @select="selectedMediaObject = $event"
          v-for="object in selectedContainerObjects"
          :selected="selectedMediaObject == object.id"
        />
      </div>
    </div>
    <div slot="actions" class="modal-controls modal-controls--center">
      <Button
        @click="accept"
        theme="primary"
        :icon="confirmIcon"
        :disabled="!selectedMediaObject">
        {{ $t('general.save') }}
      </Button>
    </div>
  </base-modal>
</template>

<script>
  import Dropdown from '@/components/global/dropdowns/Dropdown'
  import BaseModal from '../../global/modals/BaseModal'
  import Object from '@/components/mediacenter/Object'
  import Button from '../../global/buttons/Button'
  import {mapGetters} from 'vuex'

  export default {
    props: [
      'title',
      'value',
      'active',
      'containers'
    ],
    computed: {
      ...mapGetters({
        isMobile: 'app/isMobileMode'
      }),
      closeIcon() {
        return this.isMobile ? 'images/icons/close.svg' : ''
      },
      confirmIcon() {
        return this.isMobile ? 'images/icons/check--white.svg' : ''
      },
      dropdowns() {
        if (this.containers) {
          return []
          .concat([this.defaultContainer])
          .concat(this.containers.map(item => {
            return {
              id: item.id,
              label: item.name
            }
          }))
        }
        return []
      },
      selectedContainerObjects() {
        let objects = (this.containers || [])
        .filter(item => {
          return this.selectedContainer === -1 || item.id === this.selectedContainer
        })
        .flatMap(item => item.objects)
        .filter(item => item.preview_type === 'image')

        if (this.keyword) {
          return objects.filter(item => {
            return item.name && item.name.toLowerCase().indexOf(this.keyword.toLowerCase()) > -1
          })
        }
        return objects
      },
    },
    data() {
      return {
        keyword: null,
        selectedMediaObject: null,
        selectedContainer: -1,
        currentDropdown: -1,
        defaultContainer: {
          id: -1,
          label: this.$t('designer.all_media_containers')
        },
      }
    },
    created() {
      this.selectedMediaObject = this.value
    },
    methods: {
      accept() {
        this.$emit('success', {
          value: this.selectedMediaObject
        })
      },
    },
    watch: {
      value() {
        this.selectedMediaObject = this.value
      }
    },
    components: {
      BaseModal,
      Dropdown,
      Button,
      Object
    }
  }
</script>

<style lang="scss" scoped>
  .media-sidebar-contents {
    @apply
      flex
      gap-3
      h-full
      flex-wrap
      overflow-y-auto;
    max-height: 400px;
  }
</style>

<template>
  <Context
      :middle="true"
      :entries="entries"
      :transparent="false"
      @select="select">
    <img src="/images/icons/options.svg"/>
  </Context>
</template>

<script>
import Context from '../global/Context'
import ContextMixin from '../../mixins/context'

export default {
  props: [
    'folder',
    'element',
    'preview',
    'read-only',
    'highlighted',
    'context-type',
  ],
  data() {
    return {
      entries: [
        {
          id: 1,
          icon: 'images/icons/delete.svg',
          title: 'workspaces.delete',
          function: 'delete'
        },
        // {
        //   id: 2,
        //   icon: 'images/icons/designer.svg',
        //   title: 'designer.image_mode',
        //   subEntries: [
        //     {id: 21, title: 'designer.image_mode_cover', function: 'setImageMode', mode: 'cover'},
        //     {id: 22, title: 'designer.image_mode_contains', function: 'setImageMode', mode: 'contain'},
        //   ]
        // },
      ]
    }
  },
  methods: {
    /**
     * Deletes this element from media slot
     */
    delete() {
      this.$emit('delete')
    },
  },
  mixins: [
    ContextMixin
  ],
  components: {
    Context
  }
}
</script>

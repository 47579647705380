<template>
    <div class="c__payment"
        :class="{'c__payment--selected': selected}"
        @click.prevent="$emit('select', payment.type)">
        <div class="c__payment-icon-wrapper">
            <div class="c__payment-icon">
                <b-icon
                    pack="far"
                    size="is-large"
                    icon="credit-card"
                />
            </div>
        </div>
        <div class="c__payment-title">
            <div class="c__payment-text">{{ $t(payment.title) }}</div>
            <div class="c__payment-subtext">{{ $t(payment.subtitle) }}</div>
        </div>
    </div>
</template>

<script>
    export default {
    	props: {
		    payment: {
                type: Object,
                required: true,
            },
            selected: {
		    	type: Boolean,
                required: true,
            }
        }
    }
</script>

<style lang="scss" scoped>
    .c__payment {
        display: flex;
        min-height: 88px;
        margin-bottom: 8px;
        border-radius: 8px;
        background: $light-blue-color;

        &.c__payment--selected,
        &:hover {
            cursor: pointer;
            background: darken($light-blue-color, 5%);
        }

        .c__payment-icon-wrapper {
            padding: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .c__payment-title {
            display: flex;
            flex-direction: column;
            justify-content: center;
            font-family: Roboto, 'sans-serif';

            .c__payment-text {
                font-size: 16px;
                font-weight: 500;
                color: $darker-blue-color;
            }

            .c__payment-subtext {
                font-size: 13px;
                color: $dark-gray-color;
                font-family: Roboto, sans-serif;
            }
        }
    }
</style>
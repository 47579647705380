<template>
  <div
    class="c__menu"
    v-show="forceShowMenu">
    <div class="c__menu-logo">
      <img src="images/do-logo.svg">
    </div>
    <div class="c__menu-entries">
      <menu-entry
        :key="index"
        :link="entry.link"
        :title="entry.title"
        :external="entry.external"
        :function="entry.function"
        :icon="'/images/icons/' + entry.icon"
        v-for="(entry, index) in sortedEntries"
      />
    </div>
    <a href="https://digital-folder.com" target="_blank" class="c-menu-footer">
      Powered by
      <img src="images/do-logo.svg">
    </a>
  </div>
</template>

<script>
  import MenuEntry from './MenuEntry'
  import {mapGetters} from 'vuex'

  export default {
    computed: {
      ...mapGetters({
        isSuperadmin: 'auth/isSuperadmin',
        forceShowMenu: 'app/forceShowMenu'
      }),
      sortedEntries() {
        if (this.entries) {
          return this.entries.sort((i1, i2) => i1.index - i2.index)
        }
      }
    },
    data() {
      return {
        entries: []
      }
    },
    created() {
      this.entries.push({
        link: '/' + this.$route.params.slug + '/workspaces/' + this.$route.params.teamId,
        title: this.$t('workspaces.folderNRacks'),
        icon: 'shelf-icon.svg',
        index: 0,
      },
      {
        index: 1,
        link: '/' + this.$route.params.slug + '/media-center/' + this.$route.params.teamId,
        icon: 'media-center.svg',
        title: this.$t('mediacenter.mediacenter')
      },
      {
        link: '/' + this.$route.params.slug + '/settings/profile',
        title: this.$t('settings.settings'),
        icon: 'settings.svg',
        index: 2,
      },
      {
        index: 5,
        icon: '/rework/info.svg',
        title: this.$t('settings.help'),
        function: () => {
          this.$store.commit('help/setGenericView', true)
          this.$store.commit('help/setToggleBubble', true)
        },
      },
      {
        index: 100,
        link: '/auth/logout',
        icon: 'power-off.svg',
        title: this.$t('auth.logout')
      })

      if (this.isSuperadmin) {
        this.entries.push({
          link: '/admin/customers',
          title: this.$t('settings.superadmin'),
          icon: 'settings.svg',
          index: 3,
        })
      }
      window.addEventListener('click', this.toggle, false)
    },
    beforeDestroy() {
      window.removeEventListener('click', this.toggle, false)
    },
    methods: {
      toggle($event) {
        let isMenuClick = $event.target.classList.contains('layout__button-wrapper')
          || $event.target.closest('.layout__button-wrapper')
          || $event.target.closest('.c__menu')

        if (this.forceShowMenu && !isMenuClick) {
          this.$store.commit('app/setForceShowMenu', false)
        }
      }
    },
    components: {
      MenuEntry
    }
  }
</script>

<style lang="scss" scoped>
  .c__menu-entries {
    width: 100%;
    margin-top: 8px;
    height: calc(100% - 212px);
  }

  .c__menu {
    box-shadow: 2px 0 4px 0 rgba(0, 0, 0, 0.15);
    background: $white;
    position: fixed;
    z-index: 5200;
    height: 100%;
    width: 320px;
    left: 0;
    top: 0;

    @media (max-width: $breakpoint-mobile) {
      height: 100%;
      width: 100%;
    }

    .c__menu-logo {
      margin: 24px;
      height: 112px;
      width: calc(100% - 48px);
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 64px;
        height: 64px;
      }
    }

    .c-menu-footer {
      display: flex;
      font-size: 12px;
      font-family: Roboto;
      align-items: center;
      justify-content: center;
      color: $darker-blue-color;

      img {
        width: 32px;
        height: 32px;
        margin-left: 8px;
      }
    }
  }
</style>

<template>
    <div class="c__task-list"
        v-show="tasks && tasks.length > 0">
        <Task
            :task="task"
            :key="task.id"
            v-for="task in sortedTasks"
            @success="$emit('success', $event)"
        />
    </div>
</template>

<script>
    import Task from './Task'
    import moment from 'moment';

    export default {
    	props: [
    		'tasks'
        ],
        computed: {
    		sortedTasks() {
    			if (this.tasks) {
                    return this.tasks.sort((t1, t2) => {
                        return moment(t2.created_at) - moment(t1.created_at)
                    })
                }
    			return []
            }
        },
        components: {
	        Task
        }
    }
</script>

<style lang="scss" scoped>
    .c__task-list {
        overflow-y: auto;
        padding: 0 16px 24px;
    }
</style>

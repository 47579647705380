<template>
  <div class="c__signup-sidebar-wrapper">
    <div class="c__signup-sidebar">
      <div class="c__signup-sidebar-header">
        <img src="images/do-logo.svg">
        <div class="header-container">
          <div class="header-title">{{ $t('signup.selected_plan') }}</div>
          <div class="header-action"
               @click.prevent="redirect">
            {{ $t('signup.change_plan') }}
          </div>
        </div>
      </div>
      <div class="c__signup-sidebar-plan" v-if="plan">
        <div class="sidebar-plan-header">{{ $t(plan.title) }} {{ $t('signup.plan') }}</div>
        <div class="sidebar-plan-subheader">{{ $t(plan.subtitle) }}</div>
        <div class="sidebar-plan-checklist">
          <div class="sidebar-plan-check-element"
             v-for="(checkElement, index) in plan.checklist">
            <c-icon
              :class="iconClass(index, plan.id)"
              :src="iconPath(index, plan.id)">
            </c-icon>
            <div class="check-element">{{ translateChecklistItem(checkElement) }}</div>
            <div class="u-ml--8">
              <template v-if="checkElement === 'signup.basic_argument0' || checkElement === 'signup.premium_argument0'">
                <small class="color-green" v-if="coupon">
                  <template v-if="!isCouponFree">{{
                      $t('signup.additional_month_1', {month: coupon.discount})
                    }}
                  </template>
                  <template v-if="isCouponFree">{{
                      $t('signup.additional_month_2', {month: coupon.discount})
                    }}
                  </template>
                </small>
              </template>
            </div>
          </div>
        </div>

        <div class="sidebar-plan-interval">
          <label>{{ $t('signup.payment_type') }}</label>
          <radio-group-input
              :value="paymentInterval"
              :values="paymentIntervalsFiltered"
              @input="changePaymentInterval($event)"
          />
        </div>

        <div class="sidebar-plan-licenses">
          <label>{{ $t('signup.licenses') }}</label>
          <b-tooltip
              :label="$t('signup.licenses_info')"
              :delay="tooltipDelay">
            <img src="/images/icons/info.svg" style="width: 16px; height: 16px; margin-left: 8px;">
          </b-tooltip>
          <div class="license-wrapper">
            <IconButton
                v-if="plan.id !== 'free' && !isCouponFree"
                @click="addUsers(-1)"
                icon="/images/icons/minus--without-border.svg"
            />
            <input
                min="1"
                type="number"
                :value="users"
                :disabled="!!(plan.id === 'free' || isCouponFree)"
                :class="{'plan--free': plan.id === 'free'}"
                @input="addUsers($event.target.value, true)"
            />
            <IconButton
                v-if="plan.id !== 'free' && !isCouponFree"
                @click="addUsers(1)"
                icon="/images/icons/add--blue.svg"
            />
          </div>
        </div>

        <div class="sidebar-plan-summary">
          <template v-if="!$route.query || !$route.query.slug">
            <!--                        <div class="sidebar-plan-today">-->
            <!--                            <div class="sidebar-plan-title">{{ $t('signup.today_summary') }}</div>-->
            <!--                            <div class="sidebar-plan-value">0.00 €</div>-->
            <!--                        </div>-->
            <div class="sidebar-plan-after">
              <div class="sidebar-plan-title">{{ $t('signup.after_summary') }}</div>
              <div class="sidebar-plan-value">
                <div>{{ priceMonthly | price }} ({{ $t('signup.monthly') }})</div>
                <div>{{ priceYearly | price }} ({{ $t('signup.yearly') }})</div>
              </div>
            </div>
            <div class="sidebar-plan-info" v-if="isCouponFree">{{ $t('signup.change_after_time') }}</div>
          </template>
          <template v-else>
            <div class="sidebar-plan-today">
              <div class="sidebar-plan-title">{{ $t('signup.summary') }}</div>
              <div class="sidebar-plan-value">
                <div>{{ priceMonthly | price }} ({{ $t('signup.monthly') }})</div>
                <div>{{ priceYearly | price }} ({{ $t('signup.yearly') }})</div>
              </div>
            </div>
          </template>
        </div>
      </div>
      <a href="https://digital-folder.com" class="c-poweredBy-container u-mt--24" target="_blank">
        <img src="images/do-logo.png">
        <div class="u-ml--8">Powered by Digital-Folder.com</div>
      </a>
    </div>
    <!-- Content -->
    <div class="c__signup-sidebar-content">
      <MobileHeader
          @addUsers="addUsers($event.value, $event.replace)"
          @redirect="redirect"
          v-if="isMobile"
          :coupon="coupon"
          :users="users"
          :title="title"
          :price-monthly="priceMonthly"
          :price-yearly="priceYearly"
          :link="link"
          :plan="plan"
      />
      <slot/>
    </div>
  </div>
</template>

<script>
import RadioGroupInput from '../global/inputs/RadioGroupInput'
import IconButton from '../global/buttons/IconButton'
import MobileHeader from '../signup/MobileHeader'
import {mapGetters} from 'vuex'

export default {
  props: [
    'link',
    'plans',
    'title',
    'coupon'
  ],
  computed: {
    ...mapGetters({
      isMobile: 'app/isMobileMode',
      tooltipDelay: 'ui/tooltipDelay',
    }),
    plan() {
      if (this.plans) {
        return this.plans.find(item => item.id === this.$route.query.plan)
      }
    },
    paymentInterval() {
      return this.$route.query.payment_interval
          ? this.$route.query.payment_interval
          : 'monthly'
    },
    users() {
      return this.$route.query.users && this.plan.id !== 'free'
          ? this.$route.query.users
          : 1
    },
    priceMonthly() {
      if (this.coupon && this.coupon.free) {
        return 0
      }
      if (this.plan && this.users) {
        return this.plan['price_' + this.paymentInterval] * this.users
      }
    },
    priceYearly() {
      if (this.coupon && this.coupon.free) {
        return 0
      }
      if (this.plan && this.users) {
        return this.plan['price_' + this.paymentInterval] * this.users * 12
      }
    },
    isCouponFree() {
      return this.coupon && this.coupon.free
    },
    paymentIntervalsFiltered() {
      if (this.$route.query.slug) {
        return this.paymentIntervals.filter(item => item.id === this.$route.query.payment_interval)
      }
      return this.paymentIntervals
    },
  },
  data() {
    return {
      paymentIntervals: [
        {id: 'monthly', label: 'signup.monthly_payment'},
        {id: 'yearly', label: 'signup.yearly_payment'},
      ]
    }
  },
  created() {
    if (!this.$route.query.users) {
      this.updateQuery({users: 1})
    }
  },
  methods: {
    translateChecklistItem(item) {
      let space = 1
      switch (this.$route.query.plan) {
        case 'basic':
          space = 10 * (this.$route.query.users || 1)
          break
        case 'premium':
          space = 20 * (this.$route.query.users || 1)
          break
      }

      return this.$t(item, {space})
    },
    addUsers(value, replace) {
      let userCount = replace
          ? value
          : parseInt(this.users) + parseInt(value)
      if (userCount < 1 || this.plan.id === 'free') {
        userCount = 1
      }
      this.updateQuery({users: userCount})
    },
    changePaymentInterval($event) {
      this.updateQuery({payment_interval: $event})
    },
    updateQuery(q) {
      let query = JSON.parse(JSON.stringify(this.$route.query))
      query = Object.assign({}, query, q)
      this.$router
          .push({query})
          .catch((err) => {
          })
    },
    redirect() {
      if (this.$route.query && this.$route.query.slug) {
        this.$router.push({
          path: '/' + this.$route.query.slug + '/settings/plans',
          query: {}
        })
        return
      }
      let newQuery = Object.assign({}, this.$route.query)
      newQuery.users = 1

      this.$router.push({
        path: '/auth/signup/service',
        query: newQuery
      })
    },
    iconClass(index, plan) {
      console.log(index, plan)
      switch (plan) {
        case 'free':
          if (index === 5 || index === 4 || index === 3) {
            return 'icon text-df-red'
          }
          break
        case 'basic':
          if (index === 6 || index === 5) {
            return 'icon text-df-red'
          }
          break
        case 'premium':
          break
      }
      return 'icon text-df-green'
    },
    iconPath(index, plan) {
      switch (plan) {
        case 'free':
          if (index === 5 || index === 4 || index === 3) {
            return '/images/icons/rework/close.svg'
          }
          break
        case 'basic':
          if (index === 6 || index === 5) {
            return '/images/icons/rework/close.svg'
          }
          break
        case 'premium':
          break
      }
      return '/images/icons/rework/check.svg'
    },
  },
  components: {
    RadioGroupInput,
    MobileHeader,
    IconButton,
  },
}
</script>

<style lang="scss" scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/**
 *  Sidebar
 */
.c__signup-sidebar-plan {
  padding: 32px;
  margin-top: 16px;
  background: $white;
  border-radius: 8px;

  .sidebar-plan-header {
    font-size: 24px;
    font-weight: 500;
    color: $darker-blue-color;
  }

  .sidebar-plan-subheader {
    font-size: 14px;
    margin-top: 8px;
    color: $dark-gray-color;
  }

  .sidebar-plan-checklist {
    margin-top: 24px;

    .sidebar-plan-check-element {
      display: flex;
      margin-top: 8px;
      font-size: 14px;

      .check-element {
        color: $darker-blue-color;
        margin-left: 16px;

      }
    }
  }

  .sidebar-plan-licenses,
  .sidebar-plan-interval {
    margin-top: 19px;

    label {
      font-size: 12px;
      font-weight: 500;
      color: $darker-blue-color;
    }

    .license-wrapper {
      display: flex;
      margin: 16px 0;

      input {
        width: 48px;
        height: 40px;
        margin: 0 8px;
        text-align: center;
        border-radius: 4px;
        border: 1px solid $lighter-gray-color;

        &.plan--free {
          margin: 0;
        }
      }
    }
  }

  .sidebar-plan-summary {
    .sidebar-plan-today,
    .sidebar-plan-after {
      display: flex;
      font-size: 16px;
      margin-top: 16px;
      font-weight: 500;
      color: $darker-blue-color;
      justify-content: space-between;

      .sidebar-plan-value {
        color: $primary-color;
        flex-basis: 50%;
        flex-shrink: 0;
        text-align: right;
      }
    }

    .sidebar-plan-info {
      font-size: 14px;
      margin-top: 16px;
      color: $dark-gray-color;
    }
  }
}

/**
 *  Sidebar Header
 */
.c__signup-sidebar-header {
  display: flex;
  align-items: center;
  flex-direction: column;

  img {
    width: 96px;
    height: 96px;
  }

  .header-container {
    width: 100%;
    display: flex;
    margin-top: 16px;
    align-items: center;
    justify-content: space-between;

    .header-title {
      font-size: 18px;
      font-weight: 500;
      color: $darker-blue-color;
    }

    .header-action {
      cursor: pointer;
      font-size: 14px;
      font-weight: 500;
      color: $primary-color;
      text-decoration: underline;
    }
  }
}

/**
 *  Sidebar Wrapper
 */
.c__signup-sidebar-wrapper {
  width: 100%;
  height: 100%;
  display: flex;

  .c__signup-sidebar {
    width: 100%;
    padding: 32px 48px;
    max-width: 560px;
    min-width: 480px;
    overflow-y: auto;
    background: $light-blue-color;

    @media (max-width: $breakpoint-mobile) {
      display: none;
    }
  }

  .c__signup-sidebar-content {
    width: 100%;
    height: 100%;
    display: flex;
    overflow-y: auto;
    justify-content: center;

    @media (max-width: $breakpoint-mobile) {
      display: block;
    }
  }
}
</style>

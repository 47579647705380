<template>
  <base-modal
      :title="title"
      :active="active"
      @close="reset">

    <!-- Button -->
    <div
        slot="button"
        class="button-wrapper"
        @click.prevent="active = true">
      <slot/>
    </div>

    <div class="c-media-tabs u-mt--32 u-pb--16">
      <div :class="{
        'u-mb--16': true,
        'c-tab-button': true,
        'c-tab-button--selected': tabIndex === 0,
      }" @click.prevent="selectTab(0)">
        {{ $t('mediacenter.object_data') }}
      </div>
      <div :class="{
      'u-mb--16': true,
      'c-tab-button': true,
      'c-tab-button--selected': tabIndex === 1,
      }" @click.prevent="selectTab(1)">
        {{ $t('mediacenter.thumbnail') }}
      </div>
      <template v-if="object.id">
        <div :class="{
            'u-mb--16': true,
            'c-tab-button': true,
            'c-tab-button--selected': tabIndex === 2,
        }" @click.prevent="selectTab(2)">
          {{ $t('mediacenter.references') }}
        </div>
      </template>
    </div>
    <!-- Content -->
    <form class="u-mt--16">
      <!-- Data -->
      <template v-if="tabIndex === 0">
        <b-message class="warn-message mt--16" type="is-danger" v-if="isContainerReleased">
          {{ $t('mediacenter.uneditable_shared_media') }}
        </b-message>

        <div class="form-element">
          <label class="basic">{{ $t('mediacenter.media_name') }}*</label>
          <text-input
              v-model="object.name"
              :disabled="isContainerReleased"
              :placeholder="$t('mediacenter.object_name_placeholder')"
          />
        </div>

        <div class="form-element mt--16">
          <label class="basic ">{{ $t('designer.note') }}</label>
          <textarea
              class="basic expanded"
              v-model="object.description"
              :disabled="isContainerReleased"
              :placeholder="$t('designer.note_placeholder')"
          />
        </div>

        <div class="form-element mt--8">
          <tag-input v-model="object.tags" :disabled="isContainerReleased">
            {{ $t('workspaces.tags') }}
          </tag-input>
        </div>

        <div class="form-element mt--16">
          <object-type-input
            @changedName="$emit('changedName', $event)"
            @typeChanged="typeChanged"
            v-model="object.data"
            :object="object"
            :advice="advice"
            :disabled="isContainerReleased"
          />
        </div>

        <b-progress
            v-if="uploadPercentage > 0"
            :value="uploadPercentage"
            format="percent"
            class="u-mt--16"
            type="is-primary"
            show-value
        />
      </template>

      <!-- Cover Image -->
      <template v-if="tabIndex === 1">
        <div class="form-element u-mt--16">
          <thumbnail-input
            :disabled="isContainerReleased"
            :containers="containers"
            v-model="object.thumbnail"
          />
        </div>
      </template>

      <!-- References -->
      <template v-if="tabIndex === 2">
        <div class="form-element">
          <input
              v-model="keyword"
              class="search__bar--small basic expanded"
              :placeholder="$t('designer.media_center_search')"
          />
        </div>
        <References :references="searchReferences">
          <Reference
            :key="index"
            :rack="reference.rack"
            :rack-id="reference.rackId"
            :folder="reference.folder"
            :folder-id="reference.folderId"
            :reference-id="reference.referenceId"
            :reference-type="reference.referenceType"
            :reference-element="reference.referenceElement"
            v-for="(reference, index) in searchReferences"
          />
        </References>
      </template>
    </form>

    <!-- Actions -->
    <section
        slot="actions"
        class="modal-controls modal-controls--spacing">
      <Button
          :disable-icon-view-only-on-mobile="true"
          :loading="loading"
          @click="reset">
        {{ $t('general.cancel') }}
      </Button>
      <Button
          @click="save"
          theme="primary"
          :loading="loading"
          :disable-icon-view-only-on-mobile="true"
          :disabled="isContainerReleased">
        <template v-if="object && object.id">{{ $t('general.save') }}</template>
        <template v-else>{{ $t('mediacenter.save_object') }}</template>
      </Button>
    </section>
  </base-modal>
</template>

<script>
import Helpers from '../../../helpers'
import APIFactory from '../../../api/factory'
import Button from '../../global/buttons/Button'
import ObjectTypeInput from '../ObjectTypeInput'
import Reference from '../../mediacenter/Reference'
import TagInput from '../../global/inputs/TagInput'
import BaseModal from '../../global/modals/BaseModal'
import TextInput from '../../global/inputs/TextInput'
import ColorSelector from '../../global/inputs/ColorSelector'
import MediaCenterInput from '../../global/inputs/MediaCenterInput'
import References from '../References';
import ThumbnailInput from '@/components/global/inputs/ThumbnailInput';

export default {
  props: [
    'object',
    'activation',
    'containers',
    'isContainerReleased'
  ],
  computed: {
    title() {
      return this.object && this.object.id
          ? this.$t('mediacenter.update_object')
          : this.$t('mediacenter.create_object')
    },
    searchReferences() {
      return this.object.references.filter(item => {
        return !this.keyword
            || item.rack.toLowerCase().indexOf(this.keyword.toLowerCase()) > -1
            || item.page.toLowerCase().indexOf(this.keyword.toLowerCase()) > -1
            || item.folder.toLowerCase().indexOf(this.keyword.toLowerCase()) > -1
      })
    },
    advice() {
      return this.object
      && this.object.references
      && this.object.references.length > 0
          ? this.$t('mediacenter.change_media_advice')
          : null
    },
    allowedMimeTypes() {
      return [
        'image/png',
        'image/jpeg',
        'image/gif',
        'image/bmp',
        'image/x-bmp',
        'image/x-ms-bmp',
      ]
    }
  },
  data() {
    return {
      tabIndex: 0,
      keyword: null,
      active: false,
      loading: false,
      uploadPercentage: 0,
    }
  },
  mounted() {
    document.addEventListener('paste', this.handlePasteEvent, false)
  },
  beforeDestroy() {
    document.removeEventListener('paste', this.handlePasteEvent, false)
  },
  methods: {
    typeChanged($event) {
      this.object.type = $event
    },
    handlePasteEvent($event) {
      if (this.active) {
        let items = ($event.clipboardData || $event.originalEvent.clipboardData).items
        if (items) {
          for (let index in items) {
            let item = items[index]
            if (item.kind === 'file') {
              let blob = item.getAsFile();
              let reader = new FileReader();
              reader.onload = (event) => {
                let b64Content = reader.result
                if (b64Content) {
                  // Upload file
                  if (this.tabIndex === 0) {
                    this.$set(this.object, 'data', {
                      extension: blob.name.split('.').pop().toLowerCase(),
                      content: b64Content.split(",")[1],
                      value: blob.name,
                      size: blob.size,
                      type: 'file'
                    })
                  }

                  // Thumbnail
                  if (this.tabIndex === 1) {
                    this.$set(this.object, 'thumbnail', {
                      extension: blob.name.split('.').pop().toLowerCase(),
                      content: b64Content.split(",")[1],
                      value: blob.name,
                      size: blob.size,
                      type: 'file'
                    })
                  }
                }

              };
              reader.readAsDataURL(blob);
            }
          }
        }
      }
    },
    createAPIRequest(progressHandler) {
      return this.object && this.object.id
          ? APIFactory.updateMediaObject(this.$route.params.slug, this.$route.params.teamId, this.object, this.object.id, progressHandler)
          : APIFactory.createMediaObject(this.$route.params.slug, this.$route.params.teamId, this.object, progressHandler)
    },
    save() {
      if (this.object.data.size > 25000000) {
        Helpers.callToast('is-danger', this.$t('mediacenter.limit_exceeded'))
        return
      }

      if (!this.object.name) {
        Helpers.callToast('is-danger', this.$t('mediacenter.append_name'))
        return
      }

      if (!this.object.data || (this.object.data && !this.object.data.value)) {
        Helpers.callToast('is-danger', this.$t('mediacenter.append_object'))
        return
      }

      this.loading = true
      this.createAPIRequest(this.handleUploadState)
          .then(res => {
            if (res && res.success) {
              Helpers.callToast('is-success', this.$t('mediacenter.save_object_success_message'))
              this.$emit('success')
            }
          })
          .finally(() => this.loading = false)
    },
    reset() {
      this.$emit('reset')
      this.active = false
      this.$router.go(0)
    },
    selectTab($event) {
      this.tabIndex = $event
    },
    handleUploadState($event) {
      if (this.object.data && this.object.data.type === 'file') {
        this.uploadPercentage = 0
        if ($event && $event.percent) {
          this.uploadPercentage = $event.percent
        }
      }
    },
  },
  watch: {
    activation: {
      immediate: true,
      handler(value) {
        if (this.active !== value) {
          this.active = value
        }
      }
    },
    active(value) {
      if (this.activation !== value) {
        this.$emit('reset', value)
      }
    },
  },
  components: {
    ThumbnailInput,
    References,
    MediaCenterInput,
    ObjectTypeInput,
    ColorSelector,
    Reference,
    TextInput,
    BaseModal,
    TagInput,
    Button
  }
}
</script>

<style lang="scss">
.c-media-tabs {
  display: flex;
  margin-left: -4px;
  margin-right: -4px;
  border-bottom: 1px solid $lighter-gray-color;
  justify-content: space-between;

  .c-tab-button {
    display: flex;
    margin: 0 4px;
    font-size: 14px;
    cursor: pointer;
    min-height: 32px;
    border-radius: 4px;
    font-family: Roboto;
    align-items: center;
    justify-content: center;
    color: $darker-blue-color;
    flex-basis: calc(50% - 8px);
    background: $light-blue-color;

    &.c-tab-button--selected {
      background: $primary-color;
      color: $white;
    }
  }
}
</style>

<style lang="scss">
  .warn-message {
    font-size: 14px;
  }
</style>

<template>
    <div
        class="c-search-item"
        @click.prevent="handleLink">
        <div class="item-container">
            <div class="icon">
                <img :src="icon">
            </div>
            <div class="text">
                <div class="text-title">{{ title }}</div>
                <div class="text-subtitle">{{ subtitle }}</div>
            </div>
        </div>
        <div class="control">
            <img src="images/icons/arrow-right.svg">
        </div>
    </div>
</template>

<script>
    export default {
    	props: [
    		'link',
    		'icon',
            'title',
            'subtitle'
        ],
        methods: {
	        handleLink() {
	            this.$emit('reset')
                this.$router
                    .push(this.link)
                    .catch(err => {})
            }
        }
    }
</script>

<style lang="scss" scoped>
    .c-search-item {
        padding: 12px;
        display: flex;
        margin-top: 4px;
        border-radius: 4px;
        overflow-x: hidden;
        background: $light-blue-color;
        justify-content: space-between;

        &:hover {
            cursor: pointer;
            background: darken($light-blue-color, 5%);
        }

        .item-container {
            display: flex;
            align-items: center;
            width: calc(100% - 32px);

            .icon {
                width: 48px;
                flex-shrink: 0;
                border-radius: 4px;

                img {
                    width: 24px;
                    height: 24px;
                }
            }

            .text {
                flex-grow: 0;
                flex-shrink: 0;
                margin-left: 16px;
                width: calc(100% - 72px);

                .text-title {
                    font-size: 16px;
                    font-weight: 500;
                    word-break: break-all;
                    color: $darker-blue-color;
                }

                .text-subtitle {
                    font-size: 14px;
                    word-break: break-all;
                    color: $dark-gray-color;
                }
            }
        }

        .control {
            width: 32px;
            display: flex;
            flex-shrink: 0;
            align-items: center;
        }
    }
</style>

<template>
  <div :class="{
    'c-searchbar-wrapper': true,
    'c-searchbar-wrapper--full-width': fullWidth
  }">
    <input
      @input="input"
      :placeholder="$t('workspaces.search')"
      class="basic c-searchbar"
      :value="value"
    />
    <div class="c-searchbar-close"
         @click.prevent="reset"
         v-show="!!value">
      <img src="images/icons/close.svg">
    </div>
  </div>
</template>

<script>
export default {
  props: [
    'full-width'
  ],
  data() {
    return {
      value: null,
    }
  },
  methods: {
    reset() {
      this.value = null
      this.$emit('change', null)
    },
    input($event) {
      this.value = $event.target.value
      this.$emit('change', $event.target.value)
    }
  }
}
</script>

<style lang="scss" scoped>
.c-searchbar-wrapper {
  position: relative;

  &.c-searchbar-wrapper--full-width {
    width: 100%;
  }

  .c-searchbar-close {
    top: 9px;
    right: 8px;
    cursor: pointer;
    position: absolute;
  }
}

.c-searchbar {
  width: 100%;
  max-width: 275px;
  height: calc(100% - 6px);
  padding: 12px 24px 12px 12px;

  &.basic {
    margin-top: 0;

    @media (max-width: $breakpoint-mobile) {
      max-width: 100%;
      margin-bottom: 8px;
    }
  }
}
</style>

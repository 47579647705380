export default {
	teams: [
		{ field: 'name', label: 'general.name' },
		{ field: 'members_count', label: 'settings.members_count' },
		{ field: 'actions', label: 'general.actions' },
	],
	members: [
		{ field: 'email', label: 'general.email' },
		{ field: 'lastname', label: 'general.lastname' },
		{ field: 'firstname', label: 'general.firstname' },
		{ field: 'department', label: 'general.department' },
	],
	users: [
		{ field: 'index', label: '#' },
		{ field: 'lastname', label: 'general.lastname' },
		{ field: 'firstname', label: 'general.firstname' },
		{ field: 'email', label: 'general.email' },
		{ field: 'status', label: 'general.status' },
		{ field: 'invited_at', label: 'general.invited_at' },
		{ field: 'accepted_at', label: 'general.accepted_at' },
		{ field: 'role_name', label: 'general.role' },
	],
	invoices: [
		{ field: 'number', label: 'settings.invoice_number' },
		{ field: 'date', label: 'settings.invoice_date' },
		{ field: 'amount', label: 'settings.invoice_sum' },
		{ field: 'translatedStatus', label: 'general.status' },
	],
	payments: [
		{ field: 'type', label: 'general.name' },
		{ field: 'last4', label: 'settings.credentials' },
		{ field: 'default_text', label: 'settings.default_method' }
	],
	roles: [
		{ field: 'name', label: 'general.role' },
	]
}

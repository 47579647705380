<template>
  <b-tooltip
    position="is-right"
    type="is-primary"
    :label="tooltip"
    :delay="500">
    <div
      :class="{
        'c-media-dropzone-icon': true,
        'c-media-dropzone-icon--selected': selected
      }"
      @click.prevent="$emit('select')">
      <img :src="icon">
    </div>
  </b-tooltip>
</template>

<script>
  export default {
    props: [
      'icon',
      'tooltip',
      'selected',
    ]
  }
</script>

<style lang="scss" scoped>
  .c-media-dropzone-icon {
    width: 48px;
    height: 48px;
    display: flex;
    cursor: pointer;
    border-radius: 8px;
    align-items: center;
    justify-content: center;
    background: transparentize($white, .75);
    border: 2px $gray solid;

    &:hover,
    &.c-media-dropzone-icon--selected {
      background: transparentize($primary-color, .75);
      border: 2px $primary-color solid;
    }
  }
</style>

<template>
  <LoadingComponent :load="loading">
    <FolderContent
      :folder="folder"
      :preview="true"
    />
  </LoadingComponent>
</template>

<script>
  import APIFactory from '../../api/factory'
  import FolderContent from '../../components/display/FolderContent'
  import LoadingComponent from '../../components/global/LoadingComponent'

  export default {
    data() {
      return {
        loading: false,
        folder: null,
      }
    },
    created() {
      this.loadFolder()
    },
    methods: {
      loadFolder() {
        this.loading = true
        APIFactory.findFolder(
          this.$route.params.slug,
          this.$route.params.teamId,
          this.$route.params.id).then((res) => {
          if (res && res.success) {
            this.folder = this.updateFolder(res.data)
          }
        }).finally(() => this.loading = false)
      },
      updateFolder(folder) {
        let totalIndex = 0
        if (folder.chapters) {
          folder.chapters = folder.chapters
          .filter(chapter => chapter.status === 'active')
          .sort((i1, i2) => i1.index - i2.index)
          .map((chapter, chapterIndex) => {
            if (!chapter.pages) {
              chapter.index = (chapterIndex + 1)
              chapter.totalIndex = ++totalIndex
              return chapter
            }
            chapter.pages = chapter.pages.sort((i1, i2) => i1.index - i2.index)

            let pageNum = 0
            return {
              ...chapter,
              index: (chapterIndex + 1),
              totalIndex: ++totalIndex,
              pages: chapter.pages
                .filter(page => page.status === 'active')
                .map(page => {
                  return {
                    ...page,
                    index: pageNum++,
                    totalIndex: ++totalIndex
                  }
                })
            }
          })
        }
        folder.totalElements = totalIndex
        return folder
      },
    },
    components: {
      LoadingComponent,
      FolderContent
    }
  }
</script>

<template>
    <b-column>
        <b-card>
            <div class="c-plan-title">{{ $t(plan.title) }}</div>
            <div class="c-plan-subtitle">{{ $t(plan.subtitle) }}</div>
            <div class="c-plan-checklist">
                <div class="checklist-entry"
                     :key="index"
                    v-for="(entry, index) in plan.checklist">
                    {{ entry }}
                </div>
            </div>
        </b-card>
    </b-column>
</template>

<script>
    export default {
    	props: [
    		'plan'
        ]
    }
</script>

export default {
	namespaced: true,
	state: {
		tooltipDelay:process.env.VUE_APP_TOOLTIP_DELAY,
		lastSelectedMediaCenterDropdown: -1,
		lastSortMethodMedia: 'alphabetically',
		lastSortMethodFolder: 'alphabetically',
		lastSortMethodRack: 'alphabetically',
		lastSortMethodContainer: 'alphabetically',
	},
	mutations: {
		setLastSelectedMediaCenterDropdown(state, dropdown) {
			state.lastSelectedMediaCenterDropdown = dropdown
		},
		setLastSortMethodMedia(state, sortMethod) {
			state.lastSortMethodMedia = sortMethod
		},
		setLastSortMethodFolder(state, sortMethod) {
			state.lastSortMethodFolder = sortMethod
		},
		setLastSortMethodRack(state, sortMethod) {
			state.lastSortMethodRack = sortMethod
		},
		setLastSortMethodContainer(state, sortMethod) {
			state.lastSortMethodContainer = sortMethod
		}
	},
	getters: {
		tooltipDelay: (state) => parseInt(state.tooltipDelay),
		lastSelectedMediaCenterDropdown: (state) => state.lastSelectedMediaCenterDropdown,
		lastSortMethodContainer: (state) => state.lastSortMethodContainer,
		lastSortMethodMedia: (state) => state.lastSortMethodMedia,
		lastSortMethodFolder: (state) => state.lastSortMethodFolder,
		lastSortMethodRack: (state) => state.lastSortMethodRack,
	}
}

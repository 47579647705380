<template>
  <base-dialog>
    <dialog-header @close="$emit('close')">
      Neue Sprache hinzufügen
    </dialog-header>
    <dialog-content>
      <labeled-input
        type="text"
        class="mt-4"
        v-model="language">
        Sprache als Abkürzung (ISO-Format)
      </labeled-input>
    </dialog-content>
    <dialog-footer>
      <c-button
        @click.prevent="$emit('close')">
        Abbrechen
      </c-button>
      <c-button
        @click.prevent="$emit('success', language)">
        Hinzufügen
      </c-button>
    </dialog-footer>
  </base-dialog>
</template>

<script>
  export default {
    data() {
      return {
        language: null,
      }
    }
  }
</script>
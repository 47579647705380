<template>
    <MenuTaskBar>
        <TitledContainer title="Dashboard">
            <div class="c-block">
                <div class="c-block-title">Basisinformationen</div>
                <div class="c-block-content">
                    <div class="c-row">
                        <div class="c-col c-col-12">
                            <div class="c-row key-value-pair">
                                <div class="c-col c-col-2 key">Login-Nachricht</div>
                                <div class="c-col c-col-10 value">
                                    <input class="basic input" v-model="message" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Button
                :loading="loading"
                theme="primary"
                @click="save">
                Speichern
            </Button>
        </TitledContainer>
    </MenuTaskBar>
</template>

<script>
    import APIFactory from '../../api/factory'
    import Button from '../../components/global/buttons/Button'
    import MenuTaskBar from '../../components/admin/menu/MenuTaskbar'
    import TitledContainer from '../../components/admin/TitledContainer'

    export default {
    	data() {
    		return {
    			message: null,
                loading: false,
            }
        },
        created() {
    		this.load()
        },
	    methods: {
    		load() {
    			this.loading = true
                APIFactory.getMessageOfTheDay().then(res => {
                	this.message = res.data
                }).finally(() => this.loading = false)
            },
    		save() {
    			this.loading = true
			    APIFactory.saveMessageOfTheDay(this.message).then(res => {

                }).finally(() => this.loading = false)
            }
        },
    	components: {
		    Button,
    		MenuTaskBar,
		    TitledContainer
        }
    }
</script>

<style lang="scss" scoped>
    .c-block {
        width: 100%;
        padding: 16px;
        margin: 8px auto;
        max-width: 1920px;
        background: $white;
        border-radius: 4px;
        box-shadow: $box-shadow-folder;
        border: 1px solid darken($light-blue-color, 5%);

        .c-block-title {
            font-size: 16px;
            font-weight: 500;
            margin-bottom: 8px;
            color: $darker-blue-color;
        }
    }

    .key-value-pair {
        .key {
            font-size: 14px;
            font-weight: 500;
            margin-bottom: 8px;
            color: $darker-blue-color;
        }
    }
</style>

<template>
  <div class="c__color-input-wrapper">
    <label class="basic">
      <slot/>
    </label>
    <div class="c__color-input-container"
         :class="{ 'c__color-input-container--borderless': borderless }">
      <div class="c__color-input-presets">
        <div
            @click.prevent.stop="selectPreset(preset.hex)"
            :class="{'c__color-input-preset-selector--selected': selectedPreset === preset.hex }"
            class="c__color-input-preset-selector"
            v-for="preset in presets"
            :key="preset.class">
          <div class="c__color-input-preset"
               :class="presetClass(preset)"
          />
        </div>
      </div>
      <div class="c__color-input-individual">
        <div class="c__color-input-individual-text">
          <label class="basic">{{ $t('workspaces.own_hex_value') }}</label>
        </div>
        <div class="c__color-input-individual-input">
          <color-input
              :disabled="disabled"
              :value="selectedColor"
              @select="selectIndividual($event)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ColorInput from './ColorInput'

export default {
  props: [
    'value',
    'folder',
    'disabled',
    'borderless',
  ],
  data() {
    return {
      selectedColor: null,
      selectedPreset: null,
      presets: [
        {'class': 'c__color-input-preset--blue', 'hex': '#55a3d9'},
        {'class': 'c__color-input-preset--light-green', 'hex': '#61b426'},
        {'class': 'c__color-input-preset--grey', 'hex': '#8f9fa5'},
        {'class': 'c__color-input-preset--purple', 'hex': '#945ecf'},
        {'class': 'c__color-input-preset--pink', 'hex': '#f3708f'},
        {'class': 'c__color-input-preset--yellow', 'hex': '#f3b11b'},
        {'class': 'c__color-input-preset--orange', 'hex': '#fb8049'},
        {'class': 'c__color-input-preset--red', 'hex': '#ff423d'},
        {'class': 'c__color-input-preset--green', 'hex': '#02be89'},
        {'class': 'c__color-input-preset--light-blue', 'hex': '#7ac4e5'},
        {'class': 'c__color-input-preset--dark-blue', 'hex': '#7080d4'},
        {'class': ' c__color-input-preset--light-grey', 'hex': '#b8bdbf'},
      ]
    }
  },
  methods: {
    selectIndividual(hex) {
      if (this.disabled) {
        return
      }
      if (hex) {
        this.selectedPreset = null
        this.selectedColor = hex
        if (this.folder) {
          this.$set(this.folder, 'dirty', true)
        }
        this.$emit('input', this.selectedColor)
      }
    },
    selectPreset(hex) {
      if (this.disabled) {
        return
      }
      if (hex) {
        this.selectedColor = null
        this.selectedPreset = hex
        if (this.folder) {
          this.$set(this.folder, 'dirty', true)
        }
        this.$emit('input', this.selectedPreset)
      }
    },
    presetClass(preset) {
      let presetClass = {}
      presetClass[preset.class] = true
      return presetClass
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(value) {
        this.selectedPreset = null
        this.selectedColor = null

        let preset = this.presets.find(item => item.hex === value)
        if (preset) {
          this.selectedPreset = preset.hex
          return
        }
        this.selectedColor = value
      }
    }
  },
  components: {
    ColorInput
  }
}
</script>

<style lang="scss" scoped>
.c__color-input-wrapper {
  .c__color-input-container {
    margin-top: 8px;
    border-radius: 4px;
    border: solid 1px $light-blue-color;

    &.c__color-input-container--borderless {
      border: none;
    }

    .c__color-input-individual {
      display: flex;
      margin-bottom: 8px;
      margin-left: 16px;
      margin-right: 17px;
      align-items: center;
      justify-content: space-between;

      .c__color-input-individual-text {
        margin-top: 5px;
      }
    }

    .c__color-input-presets {
      padding: 12px;
      display: flex;
      flex-wrap: wrap;
      border-bottom: solid 1px $light-blue-color;

      .c__color-input-preset-selector {
        padding: 1px;
        background: white;
        border-radius: 4px;
        border: 2px solid transparent;

        @media (max-width: $breakpoint-mobile) {
          padding: 2px;
          margin: 0 1px;
        }

        &:hover,
        &.c__color-input-preset-selector--selected {
          cursor: pointer;
          border: 2px solid $primary-color;
        }
      }

      .c__color-input-preset {
        width: 24px;
        height: 24px;
        border-radius: 4px;

        @media (max-width: $breakpoint-mobile) {
          width: 32px;
          height: 32px;
        }

        &.c__color-input-preset--blue {
          background: #55a3d9;
        }

        &.c__color-input-preset--light-green {
          background: #61b426;
        }

        &.c__color-input-preset--grey {
          background: #8f9fa5;
        }

        &.c__color-input-preset--purple {
          background: #945ecf;
        }

        &.c__color-input-preset--pink {
          background: #f3708f;
        }

        &.c__color-input-preset--yellow {
          background: #f3b11b;
        }

        &.c__color-input-preset--orange {
          background: #fb8049;
        }

        &.c__color-input-preset--red {
          background: #ff423d;
        }

        &.c__color-input-preset--green {
          background: #02be89;
        }

        &.c__color-input-preset--light-blue {
          background: #7ac4e5;
        }

        &.c__color-input-preset--dark-blue {
          background: #7080d4;
        }

        &.c__color-input-preset--light-grey {
          background: #b8bdbf;
        }
      }
    }
  }
}
</style>

<template>
  <div class="c-mobile-navigation">
    <IconButton
      v-if="hasPreviousPage"
      icon="images/icons/arrow-left.svg"
      @click="$emit('previousPage')"
      class="c-content-control c-content-control--back"
    />
    <IconButton
      v-if="hasNextPage"
      icon="images/icons/arrow-right.svg"
      @click="$emit('nextPage')"
      class="c-content-control c-content-control--next"
    />
  </div>
</template>

<script>
import IconButton from '../../global/buttons/IconButton'

export default {
  props: [
    'hasPreviousPage',
    'hasNextPage'
  ],
  components: {
    IconButton
  }
}
</script>

<style lang="scss" scoped>
.c-mobile-navigation {
  left: 0;
  bottom: 0;
  z-index: 2;
  width: 100%;
  height: 72px;
  display: flex;
  padding: 16px;
  flex-shrink: 0;
  position: fixed;
  background: $white;
  justify-content: space-between;
  border-top: 1px solid $light-blue-color;
}

.c-content-control {
  position: absolute;

  &.c-content-control--next {
    right: 16px;
    top: calc(50% - 20px);
  }

  &.c-content-control--back {
    left: 16px;
    top: calc(50% - 20px);
  }
}
</style>

<template>
  <Context
    :entries="entries"
    @select="select">
    <div class="u-box-align-center">
      <img src="/images/icons/options.svg"/>
    </div>
  </Context>
</template>

<script>
  import Helpers from '../../helpers'
  import Context from '../global/Context'
  import APIFactory from '../../api/factory'

  export default {
    props: [
      'element',
    ],
    data() {
      return {
        localEntries: [
          {icon: '/images/icons/edit.svg', title: 'workspaces.edit', function: 'edit', sort: 1},
          {icon: '/images/icons/delete.svg', title: 'workspaces.delete', function: 'confirmPreflightDelete', sort: 3},
        ],
        entries: [],
      }
    },
    methods: {
      select($event) {
        if ($event && $event.function && this[$event.function]) {
          this[$event.function]()
        }
      },
      /**
       *  Opens the confirm dialog. After the confirmation the function will be called (it must be a promise)
       */
      confirmPreflightDelete() {
        this.$emit('delete', {
          function: () => {
            return this.delete(this.element.id)
          },
          title: this.$t('workspaces.remove_rack_confirm_title'),
          message: this.$t('workspaces.remove_rack_confirm_message'),
        })
      },
      /**
       *  Deletes the current rack - only if there are not contents
       */
      delete(id) {
        return APIFactory.removeRack(
          this.$route.params.slug,
          this.$route.params.teamId,
          id)
        .then(res => {
          if (res && res.success) {
            Helpers.callToast('is-success', this.$t('workspaces.remove_rack_success_message'))
          }
        })
      },
      /**
       *  Opens the edit dialog for the rack.
       */
      edit() {
        this.$emit('edit', {
          rack: this.element,
          type: 'rack',
        })
      },
      /**
       * Archives or restores the element
       */
      archive() {
        return APIFactory.archive(
          this.$route.params.slug,
          this.$route.params.teamId,
          this.element.id)
        .then(res => {
          if (res && res.success) {
            if (this.element.archived) {
              Helpers.callToast('is-success', this.$t('workspaces.restore_rack_success_message'))
            } else {
              Helpers.callToast('is-success', this.$t('workspaces.archive_rack_success_message'))
            }
            this.$emit('success')
          }
        })
      }
    },
    watch: {
      element: {
        deep: true,
        immediate: true,
        handler() {
          this.entries = JSON.parse(JSON.stringify(this.localEntries))
          if (this.element.archived) {
            this.entries.push({
              icon: '/images/icons/rework/archive.svg',
              title: 'workspaces.context_menu_status_restore',
              function: 'archive',
              sort: 2,
            },)
          } else {
            this.entries.push({
              icon: '/images/icons/rework/archive.svg',
              title: 'workspaces.context_menu_status_archive',
              function: 'archive',
              sort: 2,
            })
          }
          this.entries = this.entries.sort((e1, e2) => e1.sort > e2.sort ? 1 : -1)
        }
      }
    },
    components: {
      Context,
    },
  }
</script>

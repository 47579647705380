<template>
  <SidebarLayout :title="$t('settings.users')">
    <Loader v-if="loading"/>
    <b-columns v-else-if="data && data.length > 0">
      <b-column>
        <b-card>
          <Table
              :data="data"
              :headers="header">
            <div class="modal-controls" slot-scope="data">
              <RoleModal
                  :roles="roles"
                  @success="load"
                  :user="data.row"
                  v-if="data.row.id > 0 && hasManagePermissionsPermission"
              />
              <ConfirmModal
                  v-if="data.row.id > 0"
                  @success="toggleBlock(data.row.id)"
                  :icon="data.row.blocked ? 'check' : 'ban'"
                  :type="data.row.blocked ? 'is-success' : 'is-danger'"
                  :title="data.row.blocked ? $t('settings.unblock_user') : $t('settings.block_user')"
                  :tooltip="data.row.blocked ? $t('tooltips.unblock_user') : $t('tooltips.block_user')"
                  :message="data.row.blocked ? $t('settings.unblock_description') : $t('settings.block_description')"
              />
              <ConfirmModal
                  :message="$t('settings.remove_user_message')"
                  :tooltip="$t('settings.remove_user')"
                  :title="$t('settings.remove_user')"
                  @success="toggleDelete(data.row.id)"
                  v-if="data.row.id > 0"
                  type="is-danger"
                  icon="times"
              />
              <b-tooltip
                  :delay="tooltipDelay"
                  :label="$t('settings.send_invitation')">
                <b-button
                    icon-pack="fas"
                    class="u-ml--8"
                    type="is-primary"
                    icon-left="envelope"
                    v-if="data.row.id === -1"
                    @click="sendInviteReminder(data.row.email)">
                </b-button>
              </b-tooltip>
              <ConfirmModal
                  :message="$t('settings.remove_user_message')"
                  :tooltip="$t('settings.remove_user')"
                  :title="$t('settings.remove_user')"
                  @success="uninvite(data.row.email)"
                  v-if="data.row.id === -1"
                  type="is-danger"
                  icon="times"
              />
            </div>
          </Table>
        </b-card>
        <InviteModal
            :title="$t('settings.invite_user')"
            @success="load"
            :teams="teams"
        />
      </b-column>
    </b-columns>
  </SidebarLayout>
</template>

<script>
import SidebarLayout from '../../components/settings/SidebarLayout'
import ConfirmModal from '../../components/settings/modals/ConfirmModal'
import InviteModal from '../../components/settings/modals/InviteModal'
import RoleModal from '../../components/settings/modals/RoleModal'
import Loader from '../../components/global/Loader'
import ModelMapper from '../../data/mapper'
import APIFactory from '../../api/factory'
import Table from '../../components/Table'
import Headers from '../../data/headers'
import Helpers from '../../helpers'
import {mapGetters} from 'vuex'

export default {
  computed: {
    ...mapGetters({
      apps: 'auth/apps',
      tooltipDelay: 'ui/tooltipDelay'
    }),
    app() {
      return this.apps.find(app => app.slug === this.$route.params.slug)
    },
    hasManagePermissionsPermission() {
      if (this.app && this.app.permissions) {
        return this.app.permissions.includes('can_manage_permissions')
      }
    }
  },
  data() {
    return {
      header: Headers.users,
      loading: false,
      roles: [],
      teams: [],
      data: []
    }
  },
  created() {
    this.load()
  },
  methods: {
    load() {
      this.loading = true
      Promise.all([
        APIFactory.findUsers(this.$route.params.slug),
        APIFactory.findRoles(this.$route.params.slug),
        APIFactory.findTeams(this.$route.params.slug),
      ]).then(res => {
        if (res) {
          if (res[0] && res[0].success) {
            let item = res[0].data.find(item => item.email === 'support@digital-folder.com')
            let data = res[0].data.filter(item => item.email !== 'support@digital-folder.com')
            if (item) {
              data.push(item)
            }

            this.data = ModelMapper.map(this, data, 'User')
          }

          if (res[1] && res[1].success) {
            this.roles = ModelMapper.map(this, res[1].data, 'Role')
          }

          if (res[2] && res[2].success) {
            this.teams = ModelMapper.map(this, res[2].data, 'Team')
          }
        }
        this.loading = false
      })
    },
    toggleBlock(userId) {
      this.loading = true
      APIFactory.toggleBlock(this.$route.params.slug, userId).then(res => {
        this.load()
      }).finally(() => this.loading = false)
    },
    toggleDelete(userId) {
      this.loading = true
      APIFactory.removeUser(this.$route.params.slug, userId).then(res => {
        if (res && res.success) {
          this.load()
        }
      }).finally(() => this.loading = false)
    },
    uninvite(email) {
      this.loading = true
      APIFactory.uninvite(this.$route.params.slug, email).then(res => {
        if (res && res.success) {
          this.load()
        }
      }).finally(() => this.loading = false)
    },
    sendInviteReminder(email) {
      this.loading = true
      APIFactory.sendInviteReminder(this.$route.params.slug, email).then(res => {
        if (res && res.success) {
          Helpers.callToast('is-success', this.$t('settings.send_invitation_successful'))
          this.load()
        }
      }).finally(() => this.loading = false)
    }
  },
  components: {
    SidebarLayout,
    ConfirmModal,
    InviteModal,
    RoleModal,
    Loader,
    Table,
  }
}
</script>

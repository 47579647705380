<template>
  <menu-taskbar>
    <loading-component :load="loading">
      <!-- Modals -->
      <new-language-modal
        v-if="newLanguageModal"
        @success="addNewLanguage"
        @close="newLanguageModal = false">
      </new-language-modal>
      <titled-container
        :has-controls="true"
        title="Übersetzungen">
        <div class="flex gap-2" slot="controls">
          <c-button
            @click.prevent="saveTranslations">
            {{ $t('general.save') }}
          </c-button>
          <c-button
            @click.prevent="newLanguageModal = true">
            Neue Sprache hinzufügen
          </c-button>
          <c-button
            @click.prevent="exportLanguages">
            Sprachen exportieren
          </c-button>
          <c-button
            @click.prevent="$refs.fileImport.click()">
            Sprachen importieren
          </c-button>
          <c-button
            @click.prevent="showEmptyTranslations = !showEmptyTranslations">
            <template v-if="!showEmptyTranslations">Fehlende Übersetzung anzeigen</template>
            <template v-if="showEmptyTranslations">Alle Übersetzungen anzeigen</template>
          </c-button>
          <input
            type="file"
            class="hidden"
            accept=".xlsx"
            ref="fileImport"
            @change="importLanguages"
          />
        </div>
        <table class="table">
          <thead>
          <tr>
            <td>Key</td>
            <td
              :key="language"
              v-for="language in availableLanguages">
              <div class="w-full flex justify-between items-center">
                <div>{{ language }}</div>
                <c-button
                  @click.prevent="toggleLanguage(language)"
                  :icon="toggleIconPath(language)"
                  :key="toggleIconPath(language)">
                </c-button>
              </div>
            </td>
          </tr>
          </thead>
          <tbody>
          <tr
            :key="key"
            v-for="key in keys">
            <td>{{ key }}</td>
            <td
              :key="language"
              v-for="language in availableLanguages">
              <input
                :key="language + '.' + key"
                :value="valueOf(language, key)"
                class="border rounded px-2 py-1.5 w-full"
                @input="valueSet($event, language, key)"
              />
            </td>
          </tr>
          </tbody>
        </table>
      </titled-container>
    </loading-component>
  </menu-taskbar>
</template>

<script>
  import Helper from '@/helpers'
  import APIFactory from '../../api/factory'
  import {mapGetters} from 'vuex'

  export default {
    data() {
      return {
        loading: true,
        newLanguageModal: false,
        showEmptyTranslations: false,
      }
    },
    computed: {
      ...mapGetters({
        enabledLanguages: 'app/availableLanguages',
      }),
      availableLanguages() {
        return Object.keys(this.$store.getters['app/translations'])
        .filter((t, index, self) => self.indexOf(t) === index && t)
      },
      keys() {
        return Object.keys(this.$store.getters['app/translations'])
          .flatMap(t => this.$store.getters['app/translations'][t])
          .flatMap(t => Object.keys(t))
          .filter((t, index, self) => self.indexOf(t) === index && t)
          .filter(t => {
            if (this.showEmptyTranslations) {
              return !Array.from(this.availableLanguages)
              .every(language => this.$store.getters['app/translations']
                && this.$store.getters['app/translations'][language]
                && this.$store.getters['app/translations'][language][t])
            }
            return true
          })
        .sort()
      },
    },
    mounted() {
      this.loading = false
    },
    methods: {
      toggleIconPath(language) {
        let enabledLanguages = Array.from(JSON.parse(JSON.stringify(this.enabledLanguages))).map(language => `${language}`.toLowerCase())
        return enabledLanguages
        && enabledLanguages.includes(language.toLowerCase())
            ? 'images/icons/rework/close.svg'
            : 'images/icons/rework/check.svg'
      },
      toggleLanguage($event) {
        this.loading = true
        APIFactory.toggleLanguage($event)
        .then(res => {
          if (res && res.success) {
            Helper.callToast('is-success', 'Die Übersetzungen wurden erfolgreich de-/aktiviert.')
            this.$store.commit('app/setTranslations', res.data.translations)
            this.$store.commit('app/setAvailableLanguages', res.data.availableLanguages)
          }
        })
        .finally(() => this.loading = false)
      },
      exportLanguages() {
        this.loading = true
        APIFactory.exportTranslations()
        .then(res => {
          if (res?.success) {
            Helper.callToast('is-success', 'Die Übersetzungen wurden erfolgreich exportiert.')
          }
        })
        .finally(() => this.loading = false)
      },
      importLanguages($event) {
        this.loading = true
        APIFactory.importTranslations($event.target.files[0]).then(res => {
          if (res?.success) {
            Helper.callToast('is-success', 'Die Übersetzungen wurden erfolgreich importiert.')
            window.location.reload()
          }
        })
        .finally(() => this.loading = false)
      },
      async addNewLanguage($event) {
        this.$store.commit('app/setTranslation', {
          language: $event,
          key: 'app.country_austria',
          value: '1',
        })
        this.newLanguageModal = false
        await this.saveTranslations()
        window.location.reload()
      },
      valueOf(language, key) {
        return this.$store.getters['app/translations'][language][key]
      },
      valueSet($event, language, key) {
        this.$store.commit('app/setTranslation', {
          key,
          language,
          value: $event.target.value,
        })
      },
      saveTranslations() {
        this.loading = true
        APIFactory.updateTranslations(this.$store.getters['app/translations'])
        .then(res => {
          if (res && res.success) {
            Helper.callToast('is-success', 'Die Übersetzungen wurden erfolgreich gespeichert')
            this.$store.commit('app/setTranslations', res.data.translations)
          }
        })
        .finally(() => this.loading = false)
      },
    },
  }
</script>

<style lang="scss" scoped>
  .table {
    @apply
    w-full;

    thead {
      tr {
        td,
        th {
          @apply
          font-bold;
        }
      }
    }

    tbody {
      tr {
        td {
          min-width: 320px;
        }
      }
    }
  }
</style>

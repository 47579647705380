<template>
  <drop class="c__media-layout"
        @drop="handleDrop">
    <b-tooltip
        :key="title"
        :delay="500"
        :label="title"
        position="is-bottom"
        multilined>
      <div class="c__media-title">{{ title }}</div>
    </b-tooltip>
    <div class="c__media-controls">
      <MediaModal
        @changedName="newObject.name = $event"
        @success="$emit('success')"
        @reset="reset"
        :is-container-released="false"
        :containers="containers"
        :object="newObject">
        <Button
          theme="primary"
          icon="images/icons/add.svg">
          {{ $t('mediacenter.create_object') }}
        </Button>
      </MediaModal>
      <div class="c__action-bar--right">
        <Dropdown
          :borderless="true"
          :values="mediaSortMethods"
          :default-value="lastSortMethodMedia"
          @select="$emit('sort', $event)"
        />
        <SearchInput
          @input="$emit('search', $event)"
          :placeholder="$t('mediacenter.search_container')"
        />
      </div>
    </div>
    <div :class="{
        	'c__media-grid': true,
        	'c__media-grid--controls': selectedObjects && selectedObjects.length > 0
        }">
      <slot/>
    </div>
    <MediaGridControls
        :containers="containers"
        :selected-items="selectedObjects"
        @success="$emit('success')"
    />
  </drop>
</template>

<script>
import {mapGetters} from 'vuex'
import Button from '../global/buttons/Button'
import MediaModal from './modals/MediaModal'
import SearchInput from '../global/inputs/SearchInput'
import Dropdown from '@/components/global/dropdowns/Dropdown'
import MediaGridControls from '../mediacenter/MediaGridControls'

export default {
  props: [
    'title',
    'containers',
    'container-id',
    'selected-objects',
    'media-sort-methods'
  ],
  computed: {
    ...mapGetters({
      lastSortMethodMedia: 'ui/lastSortMethodMedia',
    })
  },
  data() {
    return {
      newObject: {container_id: this.containerId}
    }
  },
  methods: {
    reset() {
      this.newObject = {
        container_id: this.containerId
      }
    },
    handleDrop(data, $event) {
      this.$emit('drop', {
        data,
        nativeEvent: $event
      })
    }
  },
  components: {
    Button,
    Dropdown,
    MediaModal,
    SearchInput,
    MediaGridControls,
  }
}
</script>

<style lang="scss" scoped>
.c__media-layout {
  width: 100%;
  height: 100%;
  position: relative;

  .c__media-title {
    width: 100%;
    height: 100%;
    font-size: 24px;
    font-weight: 500;
    max-height: 72px;
    overflow-y: hidden;
    word-break: break-all;
    font-family: Roboto, sans-serif;

    @media (max-width: $breakpoint-mobile) {
      display: none;
    }
  }

  .c__media-controls {
    display: flex;
    margin-top: 24px;
    justify-content: space-between;
  }

  .c__media-grid {
    display: flex;
    margin-top: 24px;
    overflow-y: auto;
    margin-left: -9px;
    margin-right: -9px;
    flex-flow: row wrap;
    align-content: flex-start;
    height: calc(100% - 160px);

    @media (max-width: $breakpoint-mobile) {
      height: calc(100% - 88px);
      margin-right: -4px;
      margin-left: -4px;
    }

    &.c__media-grid--controls {
      height: calc(100% - 254px);
    }
  }

  .c__action-bar--right {
    display: flex;
    align-items: center;
  }
}
</style>

<template>
  <section class="layout__container">
    <div class="layout__header">
      <!-- Left Bar -->
      <div class="layout__header--left">
        <!-- Menu-Button -->
        <div class="layout__button-wrapper">
          <div
            class="layout__button"
            v-if="!showBackToolbar"
            @click.prevent="toggle">
            <c-icon
              size="small"
              v-if="!loading"
              src="images/icons/rework/menu.svg">
            </c-icon>
            <c-icon
              size="small"
              v-if="loading"
              src="images/icons/rework/loading.svg">
            </c-icon>
          </div>
          <div
            class="layout__button"
            v-if="showBackToolbar"
            @click.prevent="handleBackClick">
            <c-icon src="images/icons/rework/chevron-left.svg" size="small"></c-icon>
          </div>
        </div>
        <div class="layout__title">
          <div class="layout__icon-wrapper" v-if="icon">
            <img :src="icon">
          </div>
          <template v-if="title && !subtitle">
            <div class="layout__text">{{ title }}</div>
          </template>
          <template v-if="title && subtitle">
            <div class="layout__text-container">
              <div class="layout__text layout__text-title">{{ title }}</div>
              <div class="layout__text layout__text-subtitle">{{ subtitle }}</div>
            </div>
          </template>
        </div>
      </div>

      <!-- Middle Bar -->
      <div class="layout__header--middle">
        <input
          v-model="keyword"
          class="search__bar"
          :placeholder="$t('workspaces.search')"
        />
      </div>

      <!-- Right Bar -->
      <div class="layout__header--right">
        <template v-if="!showBackToolbar || !isMobile">
          <Dropdown
            v-if="!loading"
            :tooltip="$t('settings.change_team')"
            icon="images/icons/users--blue.svg"
            :entries-parent-size="true"
            :default-value="workspace"
            @select="switchWorkspace"
            :hide-title="isMobile"
            :values="workspaces"
          />

          <div class="avatar__wrapper avatar__support"
               v-if="isMinimumBasic">
            <Chat :navi-mode="true"/>
          </div>
          <div class="avatar__wrapper"
               @click.prevent="callBackend">
            <img :src="userAvatar"/>
          </div>
        </template>
      </div>
    </div>
    <div class="layout__content">
      <slot/>

      <!-- Search -->
      <SearchOverlay
        :keyword="keyword"
        @reset="keyword = null"
      />
    </div>

    <!-- Left-Menu -->
    <Menu
      v-if="!loading"
    />
  </section>
</template>

<script>
  import SearchOverlay from '../global/inputs/search/SearchOverlay'
  import Dropdown from '../global/dropdowns/Dropdown'
  import Chat from '../signup/support/Chat'
  import APIFactory from '@/api/factory'
  import Helpers from '../../helpers'
  import Menu from '../global/Menu'
  import {mapGetters} from 'vuex'

  export default {
    props: [
      'icon',
      'title',
      'loading',
      'subtitle',
      'backFunction',
      'showBackToolbar',
    ],
    computed: {
      ...mapGetters({
        apps: 'auth/apps',
        avatar: 'auth/avatar',
        isMobile: 'app/isMobileMode',
      }),
      workspaces() {
        return Helpers.createWorkspaces(this.apps)
      },
      workspace() {
        return this.$route.params.teamId || 1
      },
      userAvatar() {
        return this.avatar
          ? this.avatar
          : 'images/do-logo.svg'
      },
      currentApp() {
        if (this.apps) {
          return this.apps.find(app => app.slug === this.$route.params.slug)
        }
      },
      isMinimumBasic() {
        return this.currentApp && this.currentApp.plan !== 'free'
      }
    },
    data() {
      return {
        keyword: null,
      }
    },
    methods: {
      toggle() {
        if (!this.loading) {
          this.$store.commit('app/setForceShowMenu', true)
        }
      },
      handleBackClick() {
        if (this.backFunction) {
          return this.backFunction()
        }
        this.$router.go(-1)
      },
      switchWorkspace($event) {
        let workspace = this.workspaces.find(item => item.id === $event)
        if (workspace) {
          let area = this.$route.fullPath
          && this.$route.fullPath.indexOf('media-center') > -1
            ? 'media-center' : 'workspaces'

          this.$router
          .push('/' + workspace.app + '/' + area + '/' + workspace.id)
          .catch(() => {
          })
          .finally(() => this.$router.go())
        }
      },
      callBackend() {
        this.$store.commit('app/setLastURL', this.$route.path)
        this.$router.push('/' + this.$route.params.slug + '/settings/profile').catch((err) => {
        })
      }
    },
    components: {
      SearchOverlay,
      Dropdown,
      Menu,
      Chat
    }
  }
</script>

<style lang="scss" scoped>
  .layout__container {
    width: 100%;
    height: 100%;
    user-select: none;

    .layout__header {
      justify-content: space-between;
      box-shadow: $box-shadow-menu;
      background: $white;
      position: relative;
      z-index: 5200;
      display: flex;
      height: 64px;
      width: 100%;

      @media (max-width: $breakpoint-mobile) {
        height: 56px;
      }

      .layout__header--left {
        display: flex;
        justify-content: space-between;

        .layout__button-wrapper {
          padding: 24px;

          @media (max-width: $breakpoint-mobile) {
            padding: 20px 16px 16px 24px;
          }

          .layout__button {
            height: 16px;
            cursor: pointer;
          }
        }

        .layout__title {
          height: 100%;
          display: flex;
          align-items: center;
          color: $darker-blue-color;

          .layout__icon-wrapper {
            width: 16px;
            height: 100%;
            display: flex;
            margin-left: 8px;
            margin-right: 8px;
            align-items: center;
          }

          .layout__text {
            height: 100%;
            font-size: 16px;
            font-weight: 500;
            line-height: 64px;
            font-family: 'Roboto', sans-serif;

            @media (max-width: $breakpoint-mobile) {
              line-height: 56px;
            }

            &.layout__text-title {
              line-height: 1.2;
              font-size: 12px;
              font-family: Roboto;
            }

            &.layout__text-subtitle {
              font-family: Roboto;
              font-size: 16px;
              font-weight: 500;
              line-height: 1.2;
            }
          }

          .layout__text-container {
            display: flex;
            flex-direction: column;
          }
        }
      }

      .layout__header--middle {
        height: 100%;
        display: flex;
        align-items: center;

        @media (max-width: $breakpoint-mobile) {
          display: none;
        }

        .search__bar {
          text-indent: 30px;
          background-size: 16px;
          width: calc(100vw * .35);
          background: $light-blue-color url('/images/icons/search.svg') no-repeat 24px 16px;

          &::placeholder {
            font-size: 16px;
            color: $light-gray-color;
          }
        }
      }

      .layout__header--right {
        display: flex;
        align-items: center;
        padding-right: 26px;

        @media (max-width: $breakpoint-mobile) {
          padding-right: 16px;
        }

        .avatar__wrapper {
          flex-shrink: 0;
          display: flex;
          cursor: pointer;
          padding-left: 15px;
          align-items: center;
          justify-content: center;

          &.avatar__support {
            @apply
            hidden
            lg:block;

            img {
              width: 32px;
              height: 32px;
            }
          }

          img {
            width: 40px;
            height: 40px;
            object-fit: cover;
            border-radius: 50%;
          }
        }
      }
    }

    .layout__content {
      width: 100%;
      position: relative;
      height: calc(100% - 64px);

      @media (max-width: $breakpoint-mobile) {
        height: calc(100% - 56px);
      }

      &.layout__content--transparent {
        background: rgba($primary-color, .5);
      }
    }

    .layout__menu-wrapper {
      box-shadow: $box-shadow-menu;
      background: $white;
      position: fixed;
      display: block;
      height: 100%;
      width: 320px;
      left: 0;
      top: 0;
    }
  }
</style>

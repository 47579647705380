<template>
  <!-- Language switch -->
  <b-dropdown position="is-bottom-left">
    <b-tooltip
      slot="trigger"
      position="is-bottom"
      :delay="tooltipDelay"
      :label="$t('settings.language_chooser')">
      <a class="navbar-burger burger mobile-language-icon--centered">
        {{ languageCode | upperCase }}
      </a>
    </b-tooltip>

    <b-dropdown-item
      :key="language"
      @click="switchLanguage(language)"
      v-for="language in availableLanguages">
      <div class="mobile-language-icon--centered">
        <span class="ml-1">{{ $t('app.language_' + language) }} ({{ language }})</span>
      </div>
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
  import APIFactory from '../../api/factory'
  import {mapGetters} from 'vuex'

  export default {
    computed: {
      ...mapGetters({
        token: 'auth/token',
        tooltipDelay: 'ui/tooltipDelay',
        availableLanguages: 'app/availableLanguages',
      }),
      languageCode() {
        if (!this.$route.query.lang || this.$route.query.lang === 'de') {
          return 'de'
        }
        return this.$route.query.lang
      },
    },
    methods: {
      switchLanguage(language) {
        let promises = []
        if (this.$route.path && this.$route.path.startsWith('/' + this.$route.params.slug + '/settings')) {
          promises.push(APIFactory.switchLanguage(1, language))
        }

        promises.push(this.$router.push({query: Object.assign({}, this.$route.query, {lang: language})})
        .catch((err) => {
        }))
        Promise.all(promises)
      },
    },
  }
</script>

<style lang="scss" scoped>
  .language__icon {
    max-width: 30px;
    max-height: 100%;
  }

  .mobile-language-icon--centered {
    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"c__context-wrapper",style:(_vm.setIndex)},[_c('div',{class:{
         'c__context-button--transparent': _vm.transparent,
         'c__context-button--middle': _vm.middle,
         'c__context-button--small': _vm.small,
         'c__context-button': true,
        },on:{"click":function($event){$event.stopPropagation();return _vm.toggle.apply(null, arguments)}}},[_vm._t("default")],2),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.active),expression:"active"}],staticClass:"c__context-menu"},_vm._l((_vm.sortedEntries),function(entry,index){return _c('div',{key:index,class:{
       'c__context-entry': true,
       'c__context-entry--wide': _vm.wideEntries,
       'c__context-entry--disabled': entry.disabled,
       'c__context-entry--highlighted': entry.highlighted,
       'c__context-entry--submenu-open': entry.subEntries && _vm.showSubmenu
      },on:{"click":function($event){$event.stopPropagation();return _vm.select(entry)}}},[_c('div',{staticClass:"c__context-entry-wrapper"},[_c('div',{staticClass:"c__context-icon"},[_c('img',{attrs:{"src":entry.icon}})]),_c('div',{class:{
          'c__context-title--red': entry.title === 'workspaces.delete',
          'c__context-title': true,
        }},[_vm._v(" "+_vm._s(_vm.$t(entry.title))+" ")])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(entry.subEntries && _vm.showSubmenu === entry.id),expression:"entry.subEntries && showSubmenu === entry.id"}],staticClass:"c-submenu-entries"},_vm._l((entry.subEntries),function(subEntry){return _c('div',{class:{
            'c-submenu-entry': true,
            'c-submenu-entry--selected': false,
          },on:{"click":function($event){$event.preventDefault();return _vm.selectSubmenu(subEntry)}}},[(subEntry.icon)?_c('div',{staticClass:"c-submenu-entry-icon"},[_c('img',{attrs:{"src":subEntry.icon}})]):_vm._e(),(_vm.showIconGap(entry, subEntry))?_c('div',{staticClass:"u-ml--24"}):_vm._e(),_c('div',{staticClass:"c-submenu-entry-title"},[_vm._v(_vm._s(_vm.$t(subEntry.title)))])])}),0)])}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }
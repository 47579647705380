<template>
  <div :class="{
    	'c__rack': true,
        'c__rack--selected': selected,
    }" @click.prevent="$emit('select')">
    <div class="c__rack-icon" :style="style">
      <img src="/images/icons/media-center--white.svg">
    </div>
    <div class="c__rack-description">
      <div class="c__rack-title">{{ container.name }}</div>
      <div class="c__rack-subtitle">
        {{ container.object_count }} {{ $tc('mediacenter.objects', container.object_count) }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: [
    'container',
    'selected'
  ],
  computed: {
    style() {
      return 'background-color: ' + (this.container && this.container.color
        ? this.container.color
        : '#55a2d9') + ';'
    }
  }
}
</script>

<style lang="scss" scoped>
.c__rack {
  display: flex;
  padding: 16px;
  cursor: pointer;
  margin-bottom: 8px;
  border-radius: 8px;
  border: 4px solid transparent;
  background: $light-blue-color;

  &:hover,
  &.c__rack--selected {
    border: 4px solid $lighter-blue-color;
  }

  .c__rack-icon {
    width: 24px;
    height: 24px;
    display: flex;
    padding: 24px;
    border-radius: 8px;
    align-items: center;
    justify-content: center;

    img {
      width: 24px;
      height: 24px;
      flex-shrink: 0;
      max-width: 24px;
      max-height: 24px;
    }
  }

  .c__rack-description {
    display: flex;
    margin-left: 16px;
    font-family: Roboto;
    flex-direction: column;
    justify-content: center;

    .c__rack-title {
      font-size: 16px;
      font-weight: 500;
      word-break: break-word;
    }

    .c__rack-subtitle {
      font-size: 14px;

    }
  }
}
</style>

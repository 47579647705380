<template>
    <div class="c-mobile-header--wrapper">
        <div class="c-mobile-bar">
            <div class="mobile-bar-button"
                @click="hrefTo(link)">
                <img src="images/icons/arrow-left.svg">
            </div>
            <div class="mobile-bar-title">{{ title }}</div>
            <div class="mobile-language-switcher">
                <LanguageSwitcher />
            </div>
        </div>
        <div class="c-mobile-plan-wrapper">
            <div class="c-mobile-plan">
                <div class="mobile-header">
                    <div class="header-plan">{{ $t(plan.title) }}</div>
                    <div class="header-button"
                        @click.prevent="$emit('redirect')">
                        {{ $t('signup.change_plan') }}
                    </div>
                </div>

                <div class="sidebar-plan-licenses">
                    <label>{{ $t('signup.licenses') }}</label>
                    <div class="license-wrapper">
                        <IconButton
                            v-if="plan.id !== 'free'"
                            :disabled="plan.id === 'free'"
                            @click="$emit('addUsers', {value: -1})"
                            icon="/images/icons/minus--without-border.svg"
                        />
                        <input
                            min="1"
                            type="number"
                            :value="users"
                            :disabled="plan.id === 'free'"
                            :class="{'plan--free': plan.id === 'free'}"
                            @input="$emit('addUsers', {value: $event.target.value, replace: true})"
                        />
                        <IconButton
                            v-if="plan.id !== 'free'"
                            :disabled="plan.id === 'free'"
                            icon="/images/icons/add--blue.svg"
                            @click="$emit('addUsers', {value: 1})"
                        />
                    </div>
                </div>
                <div class="sidebar-plan-summary">
                    <template v-if="!$route.query || !$route.query.slug">
                        <div class="sidebar-plan-after">
                            <div class="sidebar-plan-title">{{ $t('signup.after_summary') }}</div>
                            <div class="sidebar-plan-value">
                                <div>{{ priceMonthly | price }} ({{ $t('signup.monthly') }})</div>
                                <div>{{ priceYearly | price }} ({{ $t('signup.yearly')}})</div>
                            </div>
                        </div>
                        <div class="sidebar-plan-info" v-if="isCouponFree">{{ $t('signup.change_after_time') }}</div>
                    </template>
                    <template v-else>
                        <div class="sidebar-plan-today">
                            <div>{{ priceMonthly | price }} ({{ $t('signup.monthly') }})</div>
                            <div>{{ priceYearly | price }} ({{ $t('signup.yearly')}})</div>
                        </div>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import IconButton from '../global/buttons/IconButton'
    import LanguageSwitcher from '../settings/LanguageSwitcher';

    export default {
    	props: [
    		'coupon',
    		'title',
            'users',
            'priceMonthly',
            'priceYearly',
            'link',
            'plan',
        ],
        computed: {
	        isCouponFree() {
		        return this.coupon && this.coupon.free
	        }
        },
    	methods: {
		    hrefTo(link) {
			    this.$router.push({
				    path: link,
				    query: this.$route.query
			    })
		    },
        },
        components: {
	        LanguageSwitcher,
	        IconButton
        }
    }
</script>

<style lang="scss" scoped>
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield;
    }

    .sidebar-plan-summary {
        .sidebar-plan-today,
        .sidebar-plan-after {
            display: flex;
            font-size: 14px;
            margin-top: 16px;
            font-weight: 500;
            color: $darker-blue-color;
            justify-content: space-between;

            .sidebar-plan-value {
                flex-basis: 50%;
                flex-shrink: 0;
                text-align: right;
                color: $primary-color;
            }
        }

        .sidebar-plan-info {
            margin-top: 16px;
            font-size: 14px;
            color: $dark-gray-color;
        }
    }

    .sidebar-plan-licenses {
        margin-top: 8px;

        label {
            font-size: 12px;
            font-weight: 500;
            color: $darker-blue-color;
        }

        .license-wrapper {
            display: flex;
            margin: 8px 0;

            input {
                width: 48px;
                height: 40px;
                margin: 0 8px;
                text-align: center;
                border-radius: 4px;
                border: 1px solid $lighter-gray-color;

                &.plan--free {
                    margin: 0;
                }
            }
        }
    }

    .mobile-header {
        display: flex;
        font-size: 20px;
        font-weight: 500;
        font-family: Roboto;
        color: $darker-blue-color;
        align-items: center;
        justify-content: space-between;

        .header-button {
            font-size: 14px;
            color: $primary-color;
            text-decoration: underline;
        }
    }

    .c-mobile-header--wrapper {
        .c-mobile-bar {
            top: 0;
            z-index: 10;
            width: 100%;
            height: 56px;
            display: flex;
            position: fixed;
            background: $white;
            box-shadow: $box-shadow-folder;

            .mobile-bar-button {
                width: 56px;
                height: 56px;
                display: flex;
                flex-shrink: 0;
                align-items: center;
                justify-content: center;
            }

            .mobile-bar-title {
                display: flex;
                color: $darker-blue-color;
                justify-content: center;
                align-items: center;
                font-family: Roboto;
                font-size: 16px;
                font-weight: 500;
                width: calc(100% - 112px);
            }

            .mobile-language-switcher {
                margin-top: 4px;
            }
        }

        .c-mobile-plan-wrapper {
            width: 100%;
            height: 330px;
            margin-top: 56px;
            max-height: 220px;
            background: $light-blue-color;

            .c-mobile-plan {
                width: 100%;
                padding: 16px;
                background: $white;
                height: calc(100% - 8px);
            }
        }
    }
</style>

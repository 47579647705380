<template>
  <div class="modal__button-wrapper ml-1">
    <template v-if="tooltip">
      <b-tooltip
        :type="type"
        :label="tooltip">
        <b-button
          :type="type"
          icon-pack="fas"
          :icon-left="icon"
          @click.prevent="active = true"
        />
      </b-tooltip>
    </template>
    <template v-else>
      <b-button
        :type="type"
        icon-pack="fas"
        :icon-left="icon"
        @click.prevent="active = true"
      />
    </template>
    <Modal
      :slim="true"
      :title="title"
      :active="active"
      @close="active = false">
      <b-columns>
        <b-column>
          <section class="has-text-left">{{ message }}</section>
          <section class="modal-controls modal-controls--end">
            <b-button
              class="mt-2"
              type="is-primary"
              @click.prevent="success">
              {{ title }}
            </b-button>
            <b-button
              class="mt-2 ml-1"
              type="is-danger"
              @click.prevent="active = false">
              {{ $t('general.cancel') }}
            </b-button>
          </section>
        </b-column>
      </b-columns>
    </Modal>
  </div>
</template>

<script>
  import Modal from './Modal'

  export default {
    props: [
      'icon',
      'type',
      'title',
      'tooltip',
      'message',
    ],
    data() {
      return {
        active: false,
      }
    },
    methods: {
      success() {
        this.active = false
        this.$emit('success')
      }
    },
    components: {
      Modal,
    }
  }
</script>

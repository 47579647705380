<template>
    <section
        ref="tasks"
        class="c__tasks"
        :style="styleElementHeight">
        <TasksHeader
            @toggle="toggle"
            :active="active"
        />
        <template v-if="active">
            <TaskEditor @success="$emit('update', $event)" />
            <TaskList
                :tasks="tasks"
                @success="$emit('update', $event)"
            />
        </template>
    </section>
</template>

<script>
    import TaskList from './TaskList'
    import TaskEditor from './TaskEditor'
    import TasksHeader from './TasksHeader'

    export default {
    	props: [
    		'tasks'
        ],
    	computed: {
    		styleElementHeight() {
    			return 'bottom: ' + this.elementHeight + 'px;'
            }
        },
    	data() {
    		return {
    			active: false,
                elementHeight: 0,
            }
        },
        created() {
	        window.addEventListener('click', this.close, false)
        },
	    beforeDestroy() {
		    window.removeEventListener('click', this.close, false)
	    },
	    methods: {
    		close($event) {
			    if (!this.$el.contains($event.target)) {
			    	this.active = false
                    this.elementHeight = 0
			    }
            },
    		toggle() {
    			this.active = !this.active
                this.$nextTick(this.updateElementHeight)
            },
            updateElementHeight() {
                this.elementHeight = this.active ? (this.$refs.tasks.clientHeight - 64) : 0
            },
        },
        watch: {
    		tasks() {
    			this.$nextTick(this.updateElementHeight)
            }
        },
    	components: {
		    TaskList,
    		TaskEditor,
		    TasksHeader,
        }
    }
</script>

<style lang="scss" scoped>
    .c__tasks {
        left: -32px;
        height: auto;
        display: flex;
        margin-top: 24px;
        max-height: 100%;
        background: $white;
        position: relative;
        flex-direction: column;
        width: calc(100% + 64px);
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        box-shadow: 0 -1px 2px 0 rgba(0, 0, 0, 0.15);

        @media (max-width: $breakpoint-mobile) {
            left: 0;
            width: 100%;
            margin-top: 0;
        }
    }
</style>

<template>
  <Context
    :middle="true"
    :entries="entries"
    :transparent="false"
    @select="select">
    <img src="/images/icons/options.svg"/>
  </Context>
</template>

<script>
import Context from '../global/Context'
import APIFactory from '../../api/factory'
import ContextMixin from '../../mixins/context'
import PlanMixin from "@/mixins/plan";

export default {
  props: [
    'folder',
    'element',
    'preview',
    'read-only',
    'highlighted',
  ],
  data() {
    return {
      entries: [
        {
          id: 1,
          icon: 'images/icons/delete.svg',
          title: 'workspaces.delete',
          function: 'delete'
        },
        {
          id: 2,
          icon: 'images/icons/designer.svg',
          title: 'designer.image_mode',
          subEntries: [
            {id: 21, title: 'designer.image_mode_cover', function: 'setImageMode', mode: 'cover'},
            {id: 22, title: 'designer.image_mode_contains', function: 'setImageMode', mode: 'contain'},
          ]
        },
        {
          id: 3,
          icon: 'images/icons/note.svg',
          title: 'designer.note',
          function: 'preflightNoteModal',
        },
      ]
    }
  },
  created() {
    if (this.hasPremiumPlan) {
      this.entries = this.entries.concat([{
        id: 4,
        icon: this.element.showAsNew ? '/images/icons/star--yellow.svg' : '/images/icons/star--blue.svg',
        title: this.element.showAsNew ? 'designer.hide_as_new' : 'designer.show_as_new',
        function: 'toggleAsNew',
      }])
    }

    if (this.readOnly) {
      this.entries = []
      if (this.element.preview_type === 'link') {
        this.entries.push({
          id: 1,
          icon: 'images/icons/preview.svg',
          title: 'workspaces.show',
          function: 'show'
        })
      } else {
        if (!this.folder.template) {
          this.entries.push({
            id: 1,
            icon: 'images/icons/download.svg',
            title: 'workspaces.download',
            function: 'download'
          })
        }
      }

      if (this.element.preview_type === 'pdf'
          || this.element.preview_type === 'image'
          || this.element.preview_type === 'word'
          || this.element.preview_type === 'excel'
          || this.element.preview_type === 'powerpoint') {
        this.entries = this.entries.concat([{
          id: 1,
          icon: '/images/icons/preview.svg',
          title: 'workspaces.show',
          function: 'previewViewer',
          sort: 1
        }])
      }

      if (this.element.note) {
        this.entries = this.entries.concat([{
          id: 3,
          icon: 'images/icons/note--blue.svg',
          title: 'designer.note',
          function: 'preflightNoteModal',
          highlighted: this.element.note_highlighted
        }])
      }
    }
  },
  methods: {
    /**
     * Deletes this element from media slot
     */
    delete() {
      this.$emit('delete')
    },
    /**
     * Previews the file
     */
    show() {
      let element = document.createElement('a')
      if (this.element.preview_type === 'link') {
        let url = this.element.data.value
        if (!url.startsWith('http://') && !url.startsWith('https://')) {
          url = 'http://' + url
        }
        element.setAttribute('href', url)
        element.setAttribute('target', '_blank')
        element.click()
        element.remove()
      }

      if (this.element.preview_type === 'image') {
        let w = window.open('about:blank')
        if (w) {
          let image = new Image()
          image.src = this.element.preview
          setTimeout(() => {
            w.document.write(image.outerHTML)
          }, 0)
        }
      }
    },
    download() {
      (this.preview
          ? APIFactory.downloadMediaObject(this.$route.params.slug, this.$route.params.teamId, this.element.id)
          : APIFactory.downloadMediaObjectWithCode(this.$route.params.code, this.element.id, this.folder.password)).then(res => {
        if (res) {
          let blob = new Blob([res])
          let link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = this.element.data.value
          link.click()
        }
      })
    },
    /**
     *  Shows an image as preview in a new tab
     */
    previewViewer() {
      let w = null
      switch (this.element.preview_type.toLowerCase()) {
        case 'image':
          w = window.open('about:blank')
          let image = new Image()
          image.src = this.element.preview
          setTimeout(() => {
            w.document.write(image.outerHTML)
          }, 0)
          break
        case 'powerpoint':
        case 'excel':
        case 'word':
        case 'pdf':
          let viewerURL = null
          if (this.preview) {
            viewerURL = window.location.origin
                + '/#/' + this.$route.params.slug
                + '/object-viewer'
                + '/' + this.$route.params.teamId
                + '/' + this.element.id
          }
          if (!this.preview) {
            viewerURL = window.location.origin
                + '/#/' + this.$route.params.code
                + '/object-viewer'
                + '/' + this.folder.password
                + '/' + this.element.id + '/public'
          }
          if (viewerURL) {
            w = window.open(viewerURL)
          }
          break
      }
    },
    /**
     *  Shows the note modal with given note
     */
    preflightNoteModal() {
      this.$emit('setNoteModalInformation', {
        element: this.element
      })
    },
    toggleAsNew() {
      this.element.showAsNew = !this.element.showAsNew
      this.$emit('setAsNew', this.element.showAsNew)
      this.checkShowAsNewState()
    },
    checkShowAsNewState() {
      let entry = this.entries.find(item => item.function === 'toggleAsNew')
      if (entry) {
        entry.title = this.element.showAsNew
          ? 'designer.hide_as_new'
          : 'designer.show_as_new'
        entry.icon = this.element.showAsNew
          ? '/images/icons/star--yellow.svg'
          : '/images/icons/star--blue.svg'
      }
    }
  },
  watch: {
    element: {
      deep: true,
      handler(val) {
        if (val.note) {
          this.entries = this.entries.filter(item => {
            if (item.id === 3) {
              item.highlighted = val.note_highlighted
            }
            return true
          })
        }
      }
    }
  },
  mixins: [
    PlanMixin,
    ContextMixin
  ],
  components: {
    Context
  }
}
</script>

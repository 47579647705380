<template>
  <drop
      v-show="active"
      @drop="attachToPage"
      class="c__media-center-window">
    <div class="c__media-center-sidebar">
      <div class="c__media-center-sidebar--padding">
        <div class="media-sidebar-header">
          <div class="media-sidebar-title">{{ $t('mediacenter.mediacenter') }}</div>
          <div class="media-sidebar-close"
               @click.stop.prevent="$emit('close')">
            <Icon src="images/icons/close.svg"/>
          </div>
        </div>

        <!-- Controls -->
        <div class="media-sidebar-inputs">
          <Dropdown
              :values="dropdowns"
              :entries-parent-size="true"
              :default-value="currentDropdown"
              @select="selectContainer($event)"
          />
          <input
              v-model="keyword"
              class="search__bar"
              :placeholder="$t('designer.media_center_search')"
          />

          <MediaDropzoneIconBar
              v-if="!fileExtensions"
              @select="selectFilter"
              :selectedFilters="selectedFilters"
          />
        </div>

        <!-- Contents -->
        <div
            :class="{
            'media-sidebar-contents': true ,
            'media-sidebar-contents--with-filter': !fileExtensions
          }">
          <Object
              :key="object.id"
              :object="object"
              @select="toggleObject"
              :enable-selection="true"
              v-for="object in selectedContainerObjects"
              :selected="selectedObjects.includes(object.id)"
          />
        </div>
      </div>
      <div class="media-sidebar-control">
        <div class="file-count">
          <div class="circle">{{ selectedObjects.length }}</div>
          {{ $t('designer.selected_files') }}
        </div>
        <Button
            theme="primary"
            @click="append"
            :disable-icon-view-only-on-mobile="true"
            :disabled="disableButton">
          {{ $t('designer.append_files') }}
        </Button>
      </div>
    </div>
  </drop>
</template>

<script>
import {mapGetters} from 'vuex'
import Icon from '../global/Icon'
import Object from '../mediacenter/Object'
import Button from '../global/buttons/Button'
import Dropdown from '../global/dropdowns/Dropdown'
import MediaDropzoneIconBar from '@/components/designer/MediaDropzoneIconBar'

export default {
  props: [
    'active',
    'containers',
    'maximum-items',
    'file-extensions',
    'maximum-selectable-items',
  ],
  computed: {
    ...mapGetters({
      lastSelectedMediaCenterDropdown: 'ui/lastSelectedMediaCenterDropdown'
    }),
    currentDropdown() {
      return this.lastSelectedMediaCenterDropdown
    },
    disableButton() {
      return !this.selectedObjects
          || this.selectedObjects.length === 0
          || this.selectedObjects.length > (this.maximumItems || 1)
    },
    selectedContainerObjects() {
      let objects = (this.containers || [])
      .filter(item => {
        return this.selectedContainer === -1 || item.id === this.selectedContainer
      })
      .flatMap(item => item.objects)
      .filter(item => {
        // Filter fileExtensions
        if (this.fileExtensions) {
          let itemFileExtension = item
          && item.data
          && item.data.value
              ? item.data.value.substr(item.data.value.lastIndexOf('.') + 1)
              : null
          if (itemFileExtension) {
            return this.fileExtensions.includes(itemFileExtension.toLowerCase())
          }
          return false
        }

        // Filter by type
        let isNotUsualType = !['word', 'excel', 'powerpoint', 'image', 'pdf', 'link'].includes(item.preview_type)

        return item.data && this.selectedFilters.includes(item.preview_type)
            || (this.selectedFilters.includes('others') && isNotUsualType)
      })

      if (this.keyword) {
        return objects.filter(item => {
          return item.name && item.name.toLowerCase().indexOf(this.keyword.toLowerCase()) > -1
        })
      }
      return objects
    },
    dropdowns() {
      if (this.containers) {
        return []
        .concat([this.defaultContainer])
        .concat(this.containers.map(item => {
          return {
            id: item.id,
            label: item.name
          }
        }).sort((i1, i2) => {
          return `${i1.label}`.toLowerCase() > `${i2.label}`.toLowerCase() ? 1 : -1
        }))
      }
      return []
    }
  },
  created() {
    this.selectedContainer = this.lastSelectedMediaCenterDropdown
  },
  data() {
    return {
      keyword: null,
      selectedObjects: [],
      selectedContainer: -1,
      selectedFilters: ['word', 'excel', 'powerpoint', 'image', 'pdf', 'link', 'others'],
      defaultContainer: {
        id: -1,
        label: this.$t('designer.all_media_containers')
      },
    }
  },
  methods: {
    selectContainer($event) {
      this.selectedContainer = $event
      this.$store.commit('ui/setLastSelectedMediaCenterDropdown', $event)
    },
    append() {
      let items = this.selectedContainerObjects.filter(item => this.selectedObjects.includes(item.id))
      if (items && items.length > 0) {
        this.$emit('place-item', {
          items: items
        })
      }
    },
    toggleObject($event) {
      if (this.selectedObjects.includes($event)) {
        this.selectedObjects = this.selectedObjects.filter(item => item !== $event)
      } else {
        // Check current count of selectable items is it bigger than maximum selectable items
        if (this.selectedObjects.length >= this.maximumSelectableItems) {
          return
        }
        this.selectedObjects.push($event)
      }
    },
    attachToPage(objectId) {
      let items = this.selectedContainerObjects.filter(item => item.id === objectId)
      if (items && items.length > 0) {
        this.$emit('place-item', {
          items: items
        })
      }
    },
    selectFilter(id) {
      if (this.selectedFilters.includes(id)) {
        this.selectedFilters = this.selectedFilters.filter(item => item !== id)
      } else {
        this.selectedFilters.push(id)
      }
    }
  },
  watch: {
    active() {
      if (this.active) {
        this.selectedObjects = []
      }
    }
  },
  components: {
    MediaDropzoneIconBar,
    Icon,
    Object,
    Button,
    Dropdown
  }
}
</script>

<style lang="scss" scoped>
.media-sidebar-header {
  display: flex;
  align-content: center;
  justify-content: space-between;

  .media-sidebar-title {
    font-family: Roboto;
    font-size: 24px;
    font-weight: 500;

    @media (max-width: $breakpoint-mobile) {
      margin-left: 8px;
    }
  }

  .media-sidebar-close {
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
  }
}

.media-sidebar-inputs {
  width: 100%;
  margin-top: 24px;

  @media (max-width: $breakpoint-mobile) {
    margin-top: 16px;
  }

  .search__bar {
    width: 100%;
    margin-top: 16px;
    text-indent: 30px;
    background-size: 16px;
    background: $light-blue-color url('/images/icons/search.svg') no-repeat 24px 16px;

    &::placeholder {
      font-size: 16px;
      color: $light-gray-color;
    }
  }
}

.media-sidebar-contents {
  display: flex;
  flex-wrap: wrap;
  overflow-y: auto;
  margin: 16px -2.5px 0;
  width: calc(100% + 22px);
  align-content: flex-start;
  height: calc(100% - 232px);

  @media (max-width: $breakpoint-mobile) {
    margin: 16px -5px 0;
    height: calc(100% - 256px);
  }

  &.media-sidebar-contents--with-filter {
    height: calc(100% - 296px);

    @media (max-width: $breakpoint-mobile) {
      height: calc(100% - 326px);
    }
  }
}

.media-sidebar-control {
  bottom: 0;
  width: 100%;
  display: flex;
  position: absolute;
  padding: 28px 56px;
  background: $white;
  justify-content: space-between;
  box-shadow: $box-shadow-media-center-control;

  @media (max-width: $breakpoint-mobile) {
    padding: 20px 16px;
    width: 100%;
  }

  .file-count {
    display: flex;
    font-size: 14px;
    align-items: center;
    font-family: Roboto;
    color: $darker-blue-color;

    .circle {
      width: 32px;
      height: 32px;
      color: white;
      line-height: 34px;
      border-radius: 50%;
      text-align: center;
      background: $primary-color;
      font-family: Roboto;
      font-size: 16px;
      font-weight: 500;
      margin-right: 8px;

      &.circle--empty {
        background: $dark-gray-color;
      }
    }
  }
}

.c__media-center-window {
  top: 0;
  left: 0;
  width: 100%;
  z-index: 20;
  height: 100%;
  position: fixed;

  .c__media-center-sidebar {
    flex: 40%;
    max-width: 560px;
    min-width: 400px;
    margin-top: 60px;
    background: $white;
    position: relative;
    height: calc(100% - 60px);
    box-shadow: $box-shadow-media-center-control;

    @media (max-width: $breakpoint-mobile) {
      min-width: unset;
      max-width: unset;
      width: 100%;
    }

    .c__media-center-sidebar--padding {
      width: 100%;
      height: 100%;
      padding: 56px;

      @media (max-width: $breakpoint-mobile) {
        padding: 24px 16px 0;
      }
    }
  }
}
</style>

<template>
    <section class="c__sidebar">
        <header
            v-if="title"
            class="c__sidebar-header">
            {{ title }}
            <template v-if="count || count === 0"> ({{ count }})</template>
        </header>
        <nav class="c__sidebar-controls">
            <slot name="controls" />
        </nav>
        <section class="c__sidebar-content">
            <section class="c__sidebar-scroll-wrapper">
                <slot />
            </section>
        </section>
    </section>
</template>

<script>
    export default {
    	props: [
    		'title',
            'count'
        ],
    }
</script>

<style lang="scss" scoped>
    .c__sidebar {
        .c__sidebar-header {
            font-size: 24px;
            font-weight: 500;
            color: $darker-blue-color;
            font-family: Roboto, sans-serif;

            @media (max-width: $breakpoint-mobile) {
                display: none;
            }
        }

        .c__sidebar-controls {
            display: flex;
            margin: 19px 0 24px;
            align-items: center;
            justify-content: space-between;

            @media (max-width: $breakpoint-mobile) {
                padding: 0 16px;
            }
        }

        .c__sidebar-content {
            overflow-y: auto;
            overflow-x: hidden;
            width: calc(100% + 17px);
            height: calc(100% - 128px);

            @media (max-width: $breakpoint-mobile) {
                height: calc(100% - 88px);
                width: 100%;
            }

            .c__sidebar-scroll-wrapper {
                overflow: visible;
                width: calc(100% - 24px);
                height: calc(100% - 124px);

                @media (max-width: $breakpoint-mobile) {
                    width: 100%;
                }
            }
        }
    }
</style>

<template>
    <div class="c-mobile-menu">
        <div class="c-mobile-back"
            @click.prevent="$emit('back')">
            <img src="images/icons/arrow-left.svg">
        </div>
        <div class="c-mobile-title">{{ title }}</div>
    </div>
</template>

<script>
    export default {
    	props: [
    		'title',
        ]
    }
</script>

<style lang="scss" scoped>
    .c-mobile-menu {
        top: 0;
        left: 0;
        width: 100%;
        height: 56px;
        display: flex;
        position: fixed;
        align-items: center;
        box-shadow: $box-shadow-folder;

        .c-mobile-back {
            width: 56px;
            height: 56px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .c-mobile-title {
            font-size: 16px;
            font-weight: 500;
            font-family: Roboto;
            color: $darker-blue-color;
        }
    }
</style>

<template>
  <div class="c-titled-container">
    <div class="container-title">{{ title }}</div>
    <div class="container-controls" v-if="hasControls">
      <slot name="controls"/>
    </div>
    <div :class="{
     'container-content': true,
     'container-content--has-controls': hasControls
    }">
      <slot/>
    </div>
  </div>
</template>

<script>
  export default {
    props: [
      'title',
      'hasControls'
    ]
  }
</script>

<style lang="scss" scoped>
  .c-titled-container {
    @apply
    p-10
    pb-0
    w-full
    h-full;
    background: $light-blue-color;

    .container-title {
      height: 70px;
      display: flex;
      font-size: 32px;
      font-weight: 500;
      font-family: Roboto;
      align-items: center;
    }

    .container-controls {
      height: 40px;
      margin-bottom: 40px;
      width: calc(100% - 40px);
    }

    .container-content {
      overflow-y: auto;
      padding-right: 20px;
      height: calc(100% - (70px + 0px));

      &.container-content--has-controls {
        height: calc(100% - (70px + 80px));
      }
    }
  }
</style>

/*
 *
 *      Bootstrapper - Icons
 *
 */
import Vue from 'vue'
import moment from 'moment'
import Tooltip from './directives/tooltip'
import { library } from '@fortawesome/fontawesome-svg-core'
// internal icons
import { faCheck, faCheckCircle, faInfoCircle, faExclamationTriangle, faExclamationCircle, faBars, faPlus, faUserFriends,
	faArrowUp, faAngleRight, faAngleLeft, faAngleDown, faSearch, faPowerOff, faUser, faChartLine, faKey, faUsers,
	faUnlockAlt, faRedo, faFileInvoiceDollar, faFileUpload, faAngleUp, faPencilAlt, faDoorOpen, faTimes, faDownload,
	faEyeSlash, faCaretDown, faCaretUp, faUpload, faBan, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import {faQuestionCircle, faCreditCard, faMoneyBillAlt, faBuilding, faEye, faBell} from '@fortawesome/free-regular-svg-icons'

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(faCheck, faCheckCircle, faInfoCircle, faExclamationTriangle, faExclamationCircle, faPlus,
	faArrowUp, faAngleRight, faAngleLeft, faAngleDown, faSearch, faPowerOff, faQuestionCircle, faBuilding,
	faUser, faChartLine, faKey, faUsers, faFileUpload, faAngleUp, faPencilAlt, faDoorOpen, faBars, faEye,
	faUnlockAlt, faRedo, faCreditCard, faFileInvoiceDollar, faTimes, faDownload, faMoneyBillAlt, faUserFriends,
	faEye, faEyeSlash, faCaretDown, faCaretUp, faUpload, faBan, faEnvelope, faBell);
Vue.component('vue-fontawesome', FontAwesomeIcon);

/*
 *
 *      Bootstrapper - Custom Buefy Contents
 *
 */
import BCard from './components/buefy/BCard'
import BColumn from './components/buefy/BColumn'
import BColumns from './components/buefy/BColumns'
import BContainer from './components/buefy/BContainer'

Vue.component('b-card', BCard)
Vue.component('b-column', BColumn)
Vue.component('b-columns', BColumns)
Vue.component('b-container', BContainer)

/*
 *
 *      Vue Filters
 *
 */
Vue.filter('titleCase', (val) => {
	if (val) {
		return val.charAt(0).toUpperCase() + val.slice(1)
	}
})

Vue.filter('price', (val) => {
	let price = parseFloat(val)
	return price.toLocaleString(undefined, {
		style: 'currency',
		currency: 'eur'
	})
})

Vue.filter('gb', (val) => {
	let mb = parseFloat(val)
	return (mb / 1000).toFixed(2)
})

Vue.filter('int', (val) => {
	if (val || val === '0') {
		return parseInt(val)
	}
})

Vue.filter('date', (val) => {
	if (val) {
		return moment(val).format('DD.MM.YYYY')
	}
})

Vue.filter('datetime', (val) => {
	if (val) {
		return moment(val).format('DD.MM.YYYY HH:mm')
	}
})

Vue.filter('upperCase', (val) => {
	if (val) {
		return new String(val).toUpperCase()
	}
})

/**
 *  Custom Components
 */
import FormRow from '@/components/rework/forms/FormRow'
import LabeledInput from '@/components/rework/forms/LabeledInput'
import RadioInput from '@/components/rework/forms/inputs/RadioInput'
import CIcon from '@/components/rework/global/CIcon'
import CButton from "@/components/rework/global/buttons/CButton"
import Chat from '@/components/signup/support/Chat'
import Context from '@/components/global/Context'
import FolderPageContext from '@/components/display/FolderPageContext'
import TitledContainer from '@/components/admin/TitledContainer'
import MenuTaskBar from '@/components/admin/menu/MenuTaskbar'
import LoadingComponent from '@/components/global/LoadingComponent'
import Card from '@/components/rework/global/Card'
import HelpBubble from '@/components/rework/help/HelpBubble'
import HelpContent from '@/components/rework/help/HelpContent'
import ColorDesigner from '@/components/designer/cover/ColorDesigner'
import BaseDialog from '@/components/rework/global/dialogs/BaseDialog'
import NewLanguageModal from '@/components/rework/admin/translations/NewLanguageModal'
import DialogHeader from '@/components/rework/global/dialogs/DialogHeader'
import DialogFooter from '@/components/rework/global/dialogs/DialogFooter'
import DialogContent from '@/components/rework/global/dialogs/DialogContent'

Vue.component('c-icon', CIcon)
Vue.component('form-row', FormRow)
Vue.component('c-button', CButton)
Vue.component('radio-input', RadioInput)
Vue.component('labeled-input', LabeledInput)
Vue.component('chat', Chat)
Vue.component('context', Context)
Vue.component('folder-page-context', FolderPageContext)
Vue.component('titled-container', TitledContainer)
Vue.component('menu-taskbar', MenuTaskBar)
Vue.component('loading-component', LoadingComponent)
Vue.component('card', Card)
Vue.component('help-bubble', HelpBubble)
Vue.component('help-content', HelpContent)
Vue.component('color-designer', ColorDesigner)
Vue.component('base-dialog', BaseDialog)
Vue.component('new-language-modal', NewLanguageModal)
Vue.component('dialog-header', DialogHeader)
Vue.component('dialog-content', DialogContent)
Vue.component('dialog-footer', DialogFooter)
<template>
  <div class="c-thumbnail-container">
    <div class="c-thumbnail-input-label">{{ $t('mediacenter.thumbnail') }}</div>
    <div class="c-thumbnail-input u-mt--8" v-if="isThumbnailSelected">
      <div class="c__media-input-file">
        <div class="file-preview">
          <img src="/images/objects/file.svg">
        </div>
        <div class="file-information">
          <div class="filename">{{ selectedThumbnailTitle }}</div>
          <div class="filesize">{{ selectedThumbnailSubTitle }}</div>
        </div>
        <div class="file-actions"
             @click.prevent="reset">
          <img src="images/icons/delete.svg">
        </div>
      </div>
    </div>
    <div class="c-thumbnail-input u-mt--8"
         v-if="!isThumbnailSelected">
      <div class="c-thumbnail-wrapper"
           @click="handleUploadClick">
        <div class="c-thumbnail-icon">
          <img src="images/icons/copy.svg" />
        </div>
        <div class="c-thumbnail-description">{{ $t('mediacenter.file_upload_description') }}</div>
        <input
          type="file"
          ref="upload"
          @change="uploadData"
          :accept="allowedMimeTypes"
        />
      </div>
      <div
        :class="{
          'c-thumbnail-wrapper': true,
          'c-thumbnail-wrapper--selected': mediaCenterOpen
        }"
        @click.prevent="openMediaCenterDialog">
        <div class="c-thumbnail-icon">
          <img src="images/icons/media-center.svg" />
        </div>
        <div class="c-thumbnail-description">{{ $t('mediacenter.select_from_mediacenter') }}</div>
      </div>
      <div class="c-thumbnail-wrapper c-thumbnail-disabled">
        <div class="c-thumbnail-icon">
          <img src="images/icons/clipboard.svg" />
        </div>
        <div class="c-thumbnail-description">{{ $t('mediacenter.select_from_clipboard') }}</div>
        <input
            type="file"
            ref="upload"
            @change="uploadData"
            :accept="allowedMimeTypes"
        />
      </div>
    </div>
    <div class="c-thumbnail-media" v-if="isMediaCenterOpen">
      <form class="c-form">
        <div class="c-form-element">
          <div class="c-form-label u-mb--8">{{ $t('app.search_containers') }}</div>
          <Dropdown
              :small-dropdown-entries="true"
              :entries-parent-size="true"
              :values="dropdowns"
              :default-value="-1"
              @select="selectedContainer = $event"
          />
        </div>

        <input
          class="search__bar u-mt--8"
          style="width: 100%;"
          v-model="keyword"
          :placeholder="$t('designer.media_center_search')"
        />

        <div
          class="media-sidebar-contents u-mt--16"
          v-if="selectedContainerObjects && selectedContainerObjects.length > 0">
          <Object
            :key="object.id"
            :object="object"
            :enable-selection="true"
            @select="selectMediaCenterThumbnail"
            v-for="object in selectedContainerObjects"
            :selected="selectedMediaObject === object.id"
          />
        </div>

        <div class="media-sidebar-contents media-sidebar-empty-contents" v-else>
          {{ $t('mediacenter.no_media_found') }}
        </div>
      </form>
    </div>
  </div>
</template>

<script>
  import LoadingComponent from '@/components/global/LoadingComponent'
  import Dropdown from '@/components/global/dropdowns/Dropdown'
  import Object from '@/components/mediacenter/Object'

  export default {
    props: [
      'value',
      'disabled',
      'containers',
    ],
    computed: {
      selectedThumbnailTitle() {
        // MediaCenter
        if (this.selectedMediaObject) {
          let object = this.containers
            .flatMap(item => item.objects)
            .find(item => item.id === this.selectedMediaObject)
          if (object) {
            return object.name
          }
        }

        // FileUpload
        if (this.selectedFileUpload) {
          return this.selectedFileUpload.value
        }
      },
      selectedThumbnailSubTitle() {
        // MediaCenter
        if (this.selectedMediaObject) {
          return this.$t('mediacenter.from_mediacenter')
        }

        // FileUpload
        if (this.selectedFileUpload) {
          return parseInt(this.selectedFileUpload.size / 1000) + ' kb'
        }
      },
      isThumbnailSelected() {
        return this.selectedMediaObject || this.selectedFileUpload
      },
      isMediaCenterOpen() {
        return this.mediaCenterOpen
      },
      selectedContainerObjects() {
        let objects = []
        if (!this.selectedContainer || this.selectedContainer === -1) {
          objects = this.containers.flatMap(item => item.objects)
        } else {
          objects = this.containers
            .filter(item => item.id === this.selectedContainer)
            .flatMap(item => item.objects)
        }

        return objects.filter(item => {
          if (this.keyword) {
            return item.name.toLowerCase().indexOf(this.keyword.toLowerCase()) > -1
          }

          return item
              && item.data
              && item.data.mime_type
              && this.allowedMimeTypes.includes(item.data.mime_type)
        })
      },
      dropdowns() {
        let containers = [{
          id: -1,
          label: this.$t('designer.all_media_containers')
        }]
        let loadedContainers = this.containers.map(item => {
          return {
            id: item.id,
            label: item.name
          }
        })
        return containers.concat(loadedContainers)
      }
    },
    created() {

    },
    data() {
      return {
        mediaCenterOpen: false,
        allowedMimeTypes: [
          'image/png',
          'image/jpeg',
          'image/gif',
          'image/bmp',
          'image/x-bmp',
          'image/x-ms-bmp',
        ],
        objects: [],
        loading: false,
        keyword: null,
        contentLoaded: false,
        selectedContainer: null,
        selectedFileUpload: null,
        selectedMediaObject: null,
      }
    },
    methods: {
      reset() {
        if (!this.disabled) {
          this.selectedMediaObject = null
          this.selectedFileUpload = null
          this.$emit('input', {
            reset: true
          })
        }
      },
      uploadData($event) {
        let fileReader = new FileReader()
        fileReader.onload = () => {
          let b64Content = fileReader.result
          if (b64Content) {
            this.selectedFileUpload = {
              type: 'file',
              mime_type: $event.target.files[0].type,
              value: $event.target.files[0].name,
              size: $event.target.files[0].size,
              content: b64Content.split(",")[1],
              extension: $event.target.files[0].name.split('.').pop().toLowerCase()
            }
            this.$emit('input', this.selectedFileUpload)
          }
        }
        fileReader.readAsDataURL($event.target.files[0])
      },
      handleUploadClick() {
        if (!this.disabled) {
          this.mediaCenterOpen = false
          this.contentLoaded = false
          this.$refs.upload.click()
        }
      },
      selectMediaCenterThumbnail($event) {
        if (!this.disabled) {
          this.selectedMediaObject = $event
          this.mediaCenterOpen = false

          this.$emit('input', {
            type: 'media-center',
            value: $event,
          })
        }
      },
      openMediaCenterDialog() {
        if (!this.disabled) {
          this.mediaCenterOpen = !this.mediaCenterOpen
        }
      }
    },
    watch: {
      value: {
        deep: true,
        immediate: true,
        handler() {
          if (this.value) {
            switch (this.value.type) {
              case 'file':
                this.selectedFileUpload = JSON.parse(JSON.stringify(this.value))
                break
              case 'media-center':
                this.selectedMediaObject = JSON.parse(JSON.stringify(this.value))
                this.selectedMediaObject = this.selectedMediaObject.value
                break
            }
          }
        }
      }
    },
    components: {
      LoadingComponent,
      Dropdown,
      Object
    }
  }
</script>

<style lang="scss" scoped>
  input[type="file"] {
    display: none;
  }

  .c-thumbnail-input-label {
    font-size: 12px;
    font-weight: 500;
    color: $darkest-blue-color-2;
  }

  .c-thumbnail-input {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .c-thumbnail-wrapper {
      font-size: 12px;
      flex-basis: 100%;
      font-weight: 500;
      padding: 12px 15px;
      border-radius: 4px;
      margin-bottom: 4px;
      color: $primary-color;
      text-align: center;
      display: flex;
      align-items: center;
      background: $light-blue-color;

      .c-thumbnail-icon {
        width: 32px;
        height: 32px;
        display: flex;
        border-radius: 4px;
        margin-right: 16px;
        align-items: center;
        justify-content: center;
        background: $darkest-white-2;

        img {
          width: 20px;
          height: 20px;
        }
      }

      &.c-thumbnail-disabled {
        cursor: not-allowed;

        &:hover {
          cursor: not-allowed;
        }
      }

      &.c-thumbnail-wrapper--selected {
        background: $primary-color;
        color: $white;
      }

      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }

  .c-thumbnail-media {
    width: 100%;
    margin-top: 24px;
  }

  .media-sidebar-contents {
    display: flex;
    flex-wrap: wrap;
  }

  .media-sidebar-empty-contents {
    padding: 40px 20px;
    font-weight: 500;
    font-size: 12px;
    justify-content: center;
    color: $primary-color;
  }

  .c-loader {
    text-align: center;
    font-weight: 500;
    font-size: 12px;
    color: $primary-color;
  }

  .c__media-input-file {
    width: 100%;
    display: flex;
    margin-top: 8px;
    padding: 14px 16px;
    border-radius: 4px;
    background: $light-blue-color;
    justify-content: space-between;

    .file-preview {
      width: 40px;
      height: 40px;

      img {
        width: 40px;
        height: 40px;
        object-fit: cover;
        border-radius: 4px;
      }
    }

    .file-information {
      min-height: 40px;
      width: calc(100% - 76px);

      .filename {
        font-size: 14px;
        font-weight: 500;
        word-break: break-all;
        color: $darker-blue-color;
        font-family: Roboto, 'sans-serif';
      }

      .filesize {
        font-size: 12px;
        font-family: Roboto, 'sans-serif';
      }
    }

    .file-actions {
      width: 20px;
      height: 40px;
      display: flex;
      cursor: pointer;
      align-items: center;
      justify-content: center;
    }
  }
</style>

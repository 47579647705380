<template>
  <button
    :class="{
      'button': true,
      'button--outline': outline,
      'button--square': isIconOnly,
      'button--disabled': disabled,
      'button--red': color === 'red',
    }"
    :disabled="disabled"
    @click="$emit('click', $event)">
    <c-icon
      :src="icon"
      v-if="icon"
      :class="{'button-icon': Object.keys($slots).length > 0}"
    />
    <span class="button-title">
      <slot />
    </span>
  </button>
</template>

<script>
  export default {
    props: [
      'icon',
      'color',
      'outline',
      'disabled',
    ],
    computed: {
      isIconOnly() {
        return Object.keys(this.$slots).length === 0
      }
    }
  }
</script>

<style lang="scss" scoped>
  .button {
    @apply
      py-5
      px-4
      text-sm
      text-white
      font-medium
      bg-df-primary
      border
      hover:bg-df-primary-dark;

    .button-title {
      @apply
        ml-0;
    }

    .button-icon {
      @apply
        mr-2;
    }

    &.button--outline {
      @apply
        bg-white
        border
        text-df-primary
        border-df-primary;
    }

    &.button--disabled {
      @apply
        bg-gray-300
        text-gray-400;
    }

    &.button--red {
      @apply
        bg-df-red;

      &.button--outline {
        @apply
          text-df-red
          border-df-red;
      }
    }

    &.button--square {
      @apply
        p-0
        w-10
        h-10;
    }
  }
</style>
<template>
  <SidebarLayout :title="$t('settings.dashboard')">
    <template v-if="data">
      <b-columns>
        <Tile
          :key="index"
          :value="tile.value"
          v-for="(tile, index) in data.tiles"
          :text="$tc('settings.tile_' + tile.id, tile.value)"
        />
      </b-columns>
      <b-columns>
        <Tile type="slot">
          <div class="progress__container">
            <div class="progress__title">{{ $t('settings.tile_space') }}</div>
            <b-progress
              :value="(data.space / data.max_space) * 100"
              type="is-primary"
            />
            <div class="progress__value">{{
                $t('settings.tile_space_out_of_space',
                  {space: data.space, max_space: data.max_space})
              }}
            </div>
          </div>
        </Tile>
        <Tile type="slot">
          <div class="progress__container">
            <div class="progress__title">{{ $t('settings.tile_user_contingent') }}</div>
            <b-progress
              :value="(users / data.max_users) * 100"
              type="is-primary"
            />
            <div class="progress__value">{{
                $t('settings.tile_user_out_of_max_users',
                  {users: users, max_users: data.max_users})
              }}
            </div>
          </div>
        </Tile>
      </b-columns>
    </template>
  </SidebarLayout>
</template>

<script>
  import SidebarLayout from '../../components/settings/SidebarLayout'
  import Tile from '../../components/settings/Tile'
  import APIFactory from '../../api/factory'

  export default {
    computed: {
      users() {
        if (this.data && this.data.tiles) {
          let item = this.data.userContingent
          if (item) {
            return item
          }
        }
      },
    },
    data() {
      return {
        loading: false,
        data: null,
      }
    },
    created() {
      this.load()
    },
    methods: {
      load() {
        this.loading = true
        APIFactory.findDashboard(this.$route.params.slug).then((res) => {
          if (res && res.success) {
            this.data = res.data
            this.data.tiles = res.data.tiles.map(tile => {
              return {
                ...tile,
                value: this.$te(tile.value) ? this.$t(tile.value) : tile.value
              }
            })
          }
        }).finally(() => this.loading = false)
      }
    },
    components: {
      SidebarLayout,
      Tile
    }
  }
</script>

<style lang="scss" scoped>
  .progress__container {
    width: 80%;

    .progress__title {
      margin-bottom: 20px;
      text-align: center;
      font-weight: bold;
      font-size: 20px;
    }

    .progress__value {
      text-align: center;
      font-size: 20px;
    }
  }
</style>

<template>
  <div class="c-payment-wrapper">
    <div class="c-payment">
      <RadioInput
        :value="payment"
        :name="radioName"
        :current-value="currentValue"
        @select="$emit('select', $event)">
        <div :class="{
          'c-payment-title': true,
          'c-payment--selected': currentValue === payment
        }">
          {{ paymentLabel }}
        </div>
      </RadioInput>
      <div class="c-payment-logo"></div>
    </div>

    <!-- Creditcard -->
    <div class="payment-area-wrapper"
         v-if="currentValue === payment && payment === 'card'">
      <label class="c-font-size--12 label">{{ $t('signup.payment_type_card_info') }}</label>
      <div class="payment-area"></div>
    </div>

    <!-- SEPA -->
    <div class="payment-area-wrapper"
         v-if="currentValue === payment && payment === 'iban'">
      <label class="c-font-size--12 label">{{ $t('signup.payment_type_iban_info') }}</label>
      <div class="payment-area"></div>
      <div class="payment-info">{{ $t('signup.payment_type_iban_info_text') }}</div>
    </div>

    <!-- Sofort -->
    <div
      class="payment-area-wrapper"
      v-if="currentValue === payment && payment === 'sofort'">
      <label class="c-font-size--12 label">{{ $t('signup.payment_type_sofort_info') }}</label>
      <div class="payment-area"></div>
      <div class="payment-info">{{ $t('signup.payment_type_sofort_info_text') }}</div>
    </div>
  </div>
</template>

<script>
import RadioInput from '../../global/inputs/RadioInput'

export default {
  props: [
    'stripe',
    'payment',
    'radio-name',
    'current-value',
    'payment-label',
    'stripe-elements',
  ],
  data() {
    return {
      stripePayment: null,
      paymentElement: null,
    }
  },
  created() {
    this.createStripeElement()
  },
  methods: {
    createStripeElement() {
      let options = {}
      switch (this.payment) {
        case 'card':
          options.hidePostalCode = true
          this.stripePayment = this.stripeElements.create(this.payment, options)
          break
        case 'iban':
          options.supportedCountries = ['SEPA']
          if (!this.stripeElements.getElement('iban')) {
            this.stripeElements.create('iban', options)
          }
          this.stripePayment = this.stripeElements.getElement('iban')
          break
        case 'sofort':
          // No actions here
          break
      }
    },
    updateAdditionalPaymentInformation() {
      let element = document.querySelector('.payment-area')
      if (element) {
        element.innerHTML = ''
      }

      if (this.payment === 'sofort') {
        this.$emit('change', {
          elementType: this.payment
        })
        return
      }

      if (this.stripePayment) {
        this.stripePayment.mount('.payment-area')
        this.stripePayment.on('change', ($event) => {
          this.$emit('change', $event)
        })
      }
    }
  },
  watch: {
    currentValue(value) {
      if (value === this.payment) {
        this.$nextTick(this.updateAdditionalPaymentInformation)
      }
    }
  },
  components: {
    RadioInput
  }
}
</script>

<style lang="scss" scoped>
.label {
  margin-left: 40px;
  color: $darker-blue-color;

  @media (max-width: $breakpoint-mobile) {
    margin-left: 0px;
  }
}

.c-payment-wrapper {
  border-bottom: 1px solid #d8d8d8;
}

.payment-info {
  font-size: 12px;
  margin-left: 40px;
  margin-bottom: 24px;
  color: $darker-blue-color;

  @media (max-width: $breakpoint-mobile) {
    margin-left: 0px;
  }
}

.c-payment {
  display: flex;
  padding: 24px 0;
  justify-content: space-between;

  .c-payment-title {
    font-size: 16px;
    font-weight: 500;
    margin-left: 24px;
    line-height: 15px;
    color: $darker-blue-color;

    &.c-payment--selected {
      color: $primary-color;
    }
  }
}

.payment-area {
  margin-left: 40px;
  margin-bottom: 24px;

  @media (max-width: $breakpoint-mobile) {
    margin-left: 0;
    margin-bottom: 8px;
  }
}
</style>

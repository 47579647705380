<template>
  <div :class="{
    	'c-taskbar-entry': true,
    	'c-taskbar-entry--selected': selected
    }" @click.prevent="hrefTo">
    <c-icon
      :src="icon"
      size="large"
      class="text-white"
    />
  </div>
</template>

<script>
  export default {
    props: [
      'icon',
      'link',
    ],
    computed: {
      selected() {
        return false
      }
    },
    methods: {
      hrefTo() {
        this.$router
        .push(this.link)
        .catch(() => {
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  .c-taskbar-entry {
    @apply
      flex
      w-full
      items-center
      cursor-pointer
      justify-center;
    height: 100px;

    img {
      max-width: 36px;
      max-height: 36px;
    }

    &:hover {
      background: $darker-blue-color;
    }
  }
</style>

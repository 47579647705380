<template>
  <div class="c__input-wrapper">
    <label class="basic">
      <slot/>
    </label>
    <div class="c__input-container">
      <div
        class="c__input-tags"
        v-if="tags && tags.length > 0">
        <tag
          :key="tag"
          :title="tag"
          v-for="tag in tags"
          @remove="remove(tag)"
        />
      </div>
      <div class="c__input-text-wrapper">
<!--        <input-->
<!--          v-model="tag"-->
<!--          :disabled="disabled"-->
<!--          @keypress.13="submit"-->
<!--          class="basic expanded"-->
<!--          :placeholder=""-->
<!--        />-->
        <VueTagInput
          v-model="tags"
          :quick-mode="false"
          @add="addNewTag"
          :suggestions="suggestableTags"
          :placeholder="$t('workspaces.tag_placeholder')">
        </VueTagInput>
      </div>
    </div>
  </div>
</template>

<script>
  import Tag from './Tag'
  import VueTagInput from 'vue-tag-autocomplete'

  export default {
    props: [
      'value',
      'disabled',
      'suggestableTags'
    ],
    data() {
      return {
        tag: null,
        tags: []
      }
    },
    created() {
      this.tags = this.value ? this.value : []
    },
    methods: {
      remove(id) {
        if (!this.disabled) {
          this.tags = this.tags.filter(item => item !== id)
          this.$emit('input', this.tags)
        }
      },
      submit() {
        if (!this.tags) {
          this.tags = []
        }

        if (this.tag
          && this.tags
          && !this.tags.find(item => item === this.tag)) {
          this.tags.push(this.tag)
        }
        this.tag = null
        this.$emit('input', this.tags)
      },
      addNewTag($event) {
        if (!this.tags) {
          this.tags = []
        }

        let value = typeof $event === 'string'
          ? $event
          : $event.text

        if (!this.tags.includes(value)) {
          this.tags.push(value)
        }
        this.$emit('input', this.tags)
      },
    },
    watch: {
      value: {
        deep: true,
        immediate: true,
        handler(val) {
          this.tags = val
        }
      }
    },
    components: {
      Tag,
      VueTagInput
    }
  }
</script>

<style lang="scss" scoped>
  .c__input-wrapper {
    .c__input-container {
      .c__input-tags {
        width: 100%;
        margin-left: -4px;
        margin-right: -4px;
      }
    }

    .c__input-text-wrapper {

    }
  }
</style>

<style lang="scss">
  .TagInput-tag {
    display: none !important;
  }

  .TagInput-search {
    @apply
      m-0
      p-0;
    margin: 0 !important;
    padding: 0 !important;
  }

  .TagInput {
    box-shadow: none !important;
    border: 1px solid rgb(240, 243, 244) !important;
    padding: 6px 16px !important;
  }

  .TagInput-input {
    font-size: 14px !important;
    font-family: 'Roboto', sans-serif !important;
  }

  .TagInput-suggestions {
    top: auto !important;
    left: auto !important;
    z-index: 20001 !important;
    position: fixed !important;
    width: 100% !important;
    max-width: 380px !important;
    max-height: 250px !important;

    .TagInput-suggestionItem {
      font-size: 14px !important;
      font-family: 'Roboto', sans-serif !important;
    }
  }
</style>

<template>
  <Context
    @select="select"
    :middle="true"
    :index="index"
    :transparent="true"
    :entries="entries"
    :hide-submenu-icon-ids="hiddenSubmenuIconIds">
    <div class="flex items-center">
      <img src="/images/icons/options.svg"/>
    </div>
  </Context>
</template>

<script>
  import {mapGetters} from 'vuex'
  import Helpers from '../../helpers'
  import Context from '../global/Context'
  import APIFactory from '../../api/factory'

  export default {
    props: [
      'index',
      'folder',
    ],
    computed: {
      ...mapGetters({
        isSuperadmin: 'auth/isSuperadmin'
      }),
      currentApp() {
        return this.$store.getters['auth/currentApp'](this.$route.params.slug)
      },
      isEditableTemplateFolder() {
        let team = this.currentApp.teams.find(team => team.id == this.folder.team_id)
        // isSuperAdmin && globalTemplate
        // is not a Template
        // isTemplate && isInSameTeam
        return (this.isSuperadmin && this.folder.template === 'global')
          || !this.folder.template
          || ((this.folder.template === 'app' || this.folder.template === 'team') && team)
          || (this.folder.template === 'partner' && this.folder.app_id == this.currentApp.id)
      }
    },
    data() {
      return {
        entries: [],
        statusIdMapping: {
          'in_progress': 61,
          'finished': 62,
          'released': 63,
          'locked': 64,
          'archived': 65,
        },
        hiddenSubmenuIconIds: [4.1],
      }
    },
    created() {
      this.entries = []

      const isPremiumOrInFreeTrial = this.currentApp.plan.startsWith('premium') || (this.currentApp.plan === 'free' && this.currentApp.trial)
      if (this.isEditableTemplateFolder) {
        this.entries.push({id: 1, icon: '/images/icons/designer.svg', title: 'workspaces.designer', function: 'designer'})
        this.entries.push({id: 3, icon: '/images/icons/edit.svg', title: 'workspaces.edit', function: 'edit'})
        if (!this.folder.template && !this.folder.global_template) {
          this.entries.push({id: 5, icon: '/images/icons/merge.svg', title: 'workspaces.merge', function: 'merge'})
          this.entries.push({
            id: 6, icon: '/images/icons/activate.svg', title: 'workspaces.change_status',
            subEntries: [
              {
                id: 61,
                title: 'workspaces.context_menu_status_in_progress',
                function: 'changeStatus',
                status: 'in_progress'
              },
              {id: 62, title: 'workspaces.context_menu_status_finish', function: 'changeStatus', status: 'finished'},
              {id: 63, title: 'workspaces.context_menu_status_release', function: 'changeStatus', status: 'released'},
              {id: 64, title: 'workspaces.context_menu_status_lock', function: 'changeStatus', status: 'locked'},
              {id: 65, title: 'workspaces.context_menu_status_archive', function: 'changeStatus', status: 'archived'},
            ]
          })
        }
        if ((this.isEditableTemplateFolder && this.folder.template) || !this.folder.template) {
          this.entries.push({id: 7, icon: '/images/icons/delete.svg', title: 'workspaces.delete', function: 'confirmPreflightDelete'})
        }

        let entry = this.entries.find(item => item.title === 'workspaces.change_status')
        if (entry) {
          let subEntry = entry.subEntries.find(item => item.id === this.statusIdMapping[this.folder.status])
          if (subEntry) {
            subEntry.icon = '/images/icons/check.svg'
          }
        }

        // Disable entry if state === LOCKED
        entry = this.entries.find(item => item.title === 'workspaces.designer')
        if (entry) {
          entry.disabled = this.folder.status === 'locked'
        }

        // Show QRCode Button if state === RELEASED
        if (this.folder.status === 'released') {
          this.entries.push({id: 4, icon: '/images/icons/qr-code.svg', title: 'designer.qr_code', function: 'qrCode'},)
        }

        if (isPremiumOrInFreeTrial) {
          this.entries.push({
            id: 6,
            icon: '/images/icons/chart--blue.svg',
            title: 'workspaces.statistics',
            function: 'statistics'
          })
        }
      }

      if (this.folder.has_contents || this.folder.template) {
        this.entries.push({id: 2, icon: '/images/icons/preview.svg', title: 'designer.preview', function: 'preview'})
      }

      // Copy - If it is no template OR it is a template and you are using a premium account
      if (!this.folder.template || isPremiumOrInFreeTrial) {
        this.entries.push({id: 4, icon: '/images/icons/copy.svg', title: 'workspaces.copy', function: 'copy'})
      }

      // Add if app is premium
      if (isPremiumOrInFreeTrial && this.folder.template) {
        this.entries.push({
          id: 4.1,
          icon: '/images/icons/copy.svg',
          title: 'workspaces.copyFromTemplate',
          // function: 'copyFromTemplate',
          subEntries: [
            { id: 4.11, title: 'workspaces.context_menu_copy_only_template', function: 'copyFromTemplate', mode: 'only_template' },
            { id: 4.12, title: 'workspaces.context_menu_copy_with_content', function: 'copyFromTemplate', mode: 'with_contents'},
            { id: 4.13, title: 'workspaces.context_menu_copy_with_full_content', function: 'copyFromTemplate', mode: 'with_full_contents'},
          ]
        })
      }

      this.entries = this.entries.sort((i1, i2) => i1.id - i2.id)
    },
    methods: {
      select($event) {
        if (this[$event.function]) {
          this[$event.function]($event)
        }
      },
      designer() {
        if (this.isEditableTemplateFolder) {
          this.$router.push(
              '/' + this.$route.params.slug + '/workspaces/'
              + this.$route.params.teamId + '/'
              + this.$route.params.rackId + '/'
              + this.folder.id + '/designer'
          )
        }
      },
      preview() {
        let route = this.$router.resolve({
          path: '/' + this.$route.params.slug + '/folder/' + this.$route.params.teamId + '/' + this.folder.id + '/preview',
          query: {
            lang: this.$route.query.lang,
          }
        })
        if (route) {
          window.open(route.href, '_blank')
        }
      },
      edit() {
        this.$emit('edit', {
          element: this.folder
        })
      },
      merge() {
        this.$emit('merge', this.folder)
      },
      changeStatus($event) {
        APIFactory.changeStatus(
            this.$route.params.slug,
            this.$route.params.teamId,
            this.folder.id,
            {status: $event.status}).then(res => {
          if (res && res.success) {
            Helpers.callToast('is-success', this.$t('workspaces.status_changed_successful_message'))
            if ($event.status !== 'released') {
              this.$emit('success')
              return
            }

            this.$emit('release')
            this.folder.password = res.data.password
            this.folder.url = res.data.url
            this.$set(this, 'folder', this.folder)
          }
        })
      },
      /**
       *  Preflight the current Delete Request. After confirming the function
       *  will be performed and the object will be deleted.
       */
      confirmPreflightDelete() {
        this.$emit('delete', {
          function: () => {
            return this.delete(this.folder.id)
          },
          title: this.$t('workspaces.remove_folder_confirm_title'),
          message: this.$t('workspaces.remove_folder_confirm_message'),
        })
      },
      /**
       *  Needs to return a promise
       */
      delete(id) {
        return APIFactory.removeFolder(
            this.$route.params.slug,
            this.$route.params.teamId,
            id).then(res => {
          if (res && res.success) {
            Helpers.callToast('is-success', this.$t('workspaces.remove_folder_success_message'))
            this.$emit('success')
          }
        })
      },
      /**
       *  Copies existing folder within all elements
       */
      copy() {
        if (this.folder.template) {
          return APIFactory.copyFolderFromTemplate(
            this.$route.params.slug,
            this.$route.params.teamId,
            this.folder.id,
            this.$route.params.rackId,
            'complete_template').then(res => {
            if (res && res.success) {
              Helpers.callToast('is-success', this.$t('workspaces.copy_folder_success_message'))
              this.$emit('success')
            }
          })
        }

        return APIFactory.copyFolder(
            this.$route.params.slug,
            this.$route.params.teamId,
            this.folder.id).then(res => {
          if (res && res.success) {
            Helpers.callToast('is-success', this.$t('workspaces.copy_folder_success_message'))
            this.$emit('success')
          }
        })
      },
      qrCode() {
        this.$emit('qrCode', {})
      },
      statistics() {
        this.$emit('statistics', {})
      },
      copyFromTemplate($event) {
        return APIFactory.copyFolderFromTemplate(
          this.$route.params.slug,
          this.$route.params.teamId,
          this.folder.id,
          this.$route.params.rackId,
          $event.mode).then(res => {
          if (res && res.success) {
            Helpers.callToast('is-success', this.$t('workspaces.copy_folder_success_message'))
            this.$emit('success')
          }
        })
      }
    },
    components: {
      Context
    }
  }
</script>

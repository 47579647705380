<template>
  <SidebarLayout :title="$t('settings.company')">
    <Loader v-if="loading"/>
    <b-columns v-else-if="app">
      <b-column>
        <avatar
          v-if="isPaidPlan"
          type="organisation"
          :path="app.icon"
          :id="app.slug"
          @success="load"
        />
        <b-card class="u-mt--16">
          <b-columns>
            <b-column>
              <b-field :label="$t('settings.company')">
                <b-input v-model="app.company"/>
              </b-field>
              <b-field :label="$t('settings.website')">
                <b-input v-model="app.website"/>
              </b-field>
              <b-field :label="$t('settings.vat_number')">
                <b-input v-model="app.vat_id"/>
              </b-field>
            </b-column>
            <b-column>
              <b-field :label="$t('signup.street')">
                <b-input v-model="app.street"/>
              </b-field>
              <b-field :label="$t('signup.postal_code')">
                <b-input v-model="app.postcode"/>
              </b-field>
              <b-field :label="$t('signup.city')">
                <b-input v-model="app.city"/>
              </b-field>
            </b-column>
          </b-columns>
        </b-card>
      </b-column>
    </b-columns>
    <b-columns v-if="app">
      <b-column>
        <b-button
          type="is-success"
          :loading="loading"
          :disabled="loading"
          @click.prevent="save">
          {{ $t('general.save') }}
        </b-button>
      </b-column>
    </b-columns>
  </SidebarLayout>
</template>

<script>
  import PaymentMethodModal from '../../components/settings/modals/PaymentMethodModal'
  import SidebarLayout from '../../components/settings/SidebarLayout'
  import Loader from '../../components/global/Loader'
  import Avatar from '../../components/settings/Avatar'
  import Table from '../../components/Table'
  import APIFactory from '../../api/factory'
  import Helpers from '../../helpers'
  import {mapGetters} from 'vuex'

  export default {
    computed: {
      ...mapGetters({
        apps: 'auth/apps'
      }),
      isPaidPlan() {
        if (this.apps) {
          let app = this.apps.find(app => app.slug === this.$route.params.slug)
          if (app) {
            return app.plan !== 'free'
          }
        }
      }
    },
    data() {
      return {
        loading: false,
        app: null,
      }
    },
    created() {
      this.load()
    },
    methods: {
      load() {
        this.loading = true
        APIFactory.findOrganisation(this.$route.params.slug).then(res => {
          if (res && res.success) {
            this.app = res.data

            Object.keys(this.app).forEach(key => {
              if (this.app[key]) {
                if (('' + this.app[key]).toLowerCase() === 'missing') {
                  this.app[key] = null
                }
              }
            })
          }
        }).finally(() => this.loading = false)
      },
      save() {
        this.loading = true
        APIFactory.updateOrganisation(this.$route.params.slug, this.app).then(res => {
          if (res && res.success) {
            Helpers.callToast('is-success', this.$t('settings.organisation_saved_successful'))
          }
        }).finally(() => this.loading = false)
      }
    },
    components: {
      Avatar,
      PaymentMethodModal,
      SidebarLayout,
      Loader,
      Table
    }
  }
</script>

<template>
  <base-modal
    @close="cancel"
    :active="active"
    :title="$t('workspaces.statistics')">

    <!-- Content -->
    <div class="c-statistics">
      <table>
        <tr
          :class="{'column-even': index % 2 === 0}"
          v-for="(column, index) in columns">
          <td class="column">{{ column.label }}</td>
          <td class="values">
            <template v-if="column.id === 'last_opening' || column.id === 'first_opening' || column.id === 'last_reset'">
              <template v-if="statistics[column.id]">{{ statistics[column.id] | datetime }} {{ $t('designer.clock') }}</template>
              <template v-else>-</template>
            </template>
            <template v-else>
              {{ statistics[column.id] }}
            </template>
          </td>
        </tr>
      </table>
    </div>

    <!-- Actions -->
    <div
      slot="actions"
      class="modal-controls modal-controls--center">
      <c-button @click.prevent="reset">{{ $t('workspaces.statistics_reset') }}</c-button>
      <c-button
        class="ml-2"
        @click.prevent="cancel">
        {{ $t('general.ok') }}
      </c-button>
    </div>
  </base-modal>
</template>

<script>
  import BaseModal from '../../global/modals/BaseModal'
  import Helpers from '../../../helpers'
  import APIFactory from '@/api/factory'

  export default {
    props: [
      'folder',
      'activation',
    ],
    computed: {
      statistics() {
        return this.folder
          && this.folder.statistics
          ? this.folder.statistics
          : null
      }
    },
    data() {
      return {
        active: false,
        loading: false,
        selectedFolder: null,
        columns: [
          { id: 'openings', label: this.$t('workspaces.statistics_folder_open_count') },
          { id: 'last_opening', label: this.$t('workspaces.statistics_folder_open_last') },
          { id: 'first_opening', label: this.$t('workspaces.statistics_folder_open_first') },
          { id: 'last_reset', label: this.$t('workspaces.statistics_folder_last_reset') },
        ]
      }
    },
    methods: {
      cancel() {
        this.active = false
        this.$emit('reset')
      },
      reset() {
        this.active = false
        this.$emit('reset')
        this.$emit('resetStatistics', {
          title: this.$t('workspaces.statistics_reset'),
          message: this.$t('workspaces.statistics_reset_message'),
          function: (self) => {
            return APIFactory.resetStatistics(this.$route.params.slug, this.$route.params.teamId, this.folder.id).then(res => {
              if (res && res.success) {
                Helpers.callToast('is-success', this.$t('workspaces.statistics_reset_success'))
                this.active = false
                self.$emit('success')
              }
            })
          }
        })
      }
    },
    watch: {
      activation: {
        immediate: true,
        handler(value) {
          if (this.active !== value) {
            this.active = value
          }
        }
      },
      active(value) {
        if (this.activation !== value) {
          this.$emit('reset', value)
        }
      }
    },
    components: {
      BaseModal,
    }
  }
</script>

<style lang="scss" scoped>
  .c-statistics {
    table {
      width: 100%;
      margin-top: 24px;

      td,
      .column {
        font-size: 14px;
        padding: .75rem;
        font-weight: 600;
      }

      .values {

        font-weight: 500;
        align-items: center;
      }

      .column-even {
        background: $light-blue-color;
      }
    }
  }
</style>

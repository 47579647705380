<template>
  <context
    @select="select"
    :entries="entries">
    <div class="flex items-center">
      <img src="/images/icons/options.svg"/>
    </div>
  </context>
</template>

<script>
  import Helper from '@/helpers'

  export default {
    props: [
      'page',
      'folder',
    ],
    computed: {
      entries() {
        return [
          {
            id: 1,
            icon: 'images/icons/rework/link.svg',
            title: 'display.create_link',
            function: 'createLink'
          },
        ]
      }
    },
    methods: {
      select($event) {
        if (this[$event.function]) {
          this[$event.function]($event)
        }
      },
      createLink() {
        if (this.folder.status !== 'released') {
          Helper.callToast('is-danger', this.$t('display.create_link_no_release'))
          return
        }

        let link = window.location.origin + '/#' + this.folder.url + '?p=' + this.page.id
        Helper.copyToClipboard(link, this.$t('workspaces.copied'))
      }
    }
  }
</script>
import { render, staticRenderFns } from "./FolderPreview.vue?vue&type=template&id=944a94b2&scoped=true&"
import script from "./FolderPreview.vue?vue&type=script&lang=js&"
export * from "./FolderPreview.vue?vue&type=script&lang=js&"
import style0 from "./FolderPreview.vue?vue&type=style&index=0&id=944a94b2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "944a94b2",
  null
  
)

export default component.exports
<template></template>

<script>
  import Helpers from '@/helpers'
  import APIFactory from '@/api/factory'

  export default {
    created() {
      if (!this.$route.query.email || !this.$route.query.lastname || !this.$route.query.firstname) {
        return Helpers.callToast('is-danger', 'E-Mail, First or Lastname is missing')
      }
      let payload = {
        password: Helpers.randomizePassword(),
        email: this.$route.query.email,
        firstname:  this.$route.query.firstname,
        lastname: this.$route.query.lastname,
        language: this.$i18n.locale || 'de',
        street: 'missing',
        postal_code: 'missing',
        city: 'missing',
        plan: 'free',
        users: 1,
        signOn: true
      }
      APIFactory.signup(payload).then(res => {
        if (res && res.success) {
          this.$i18n.locale = res.data.language
          this.$store.commit('auth/setAvatar', res.data.avatar)
          this.$store.commit('auth/setUserId', res.data.user_id)
          this.$store.commit('auth/setToken', res.data.token)
          this.$store.commit('auth/setApps', res.data.apps)
          this.$store.commit('app/setVersion', res.data.version)
          this.$store.commit('auth/setSuperadmin', !!res.data.superadmin)

          Helpers.callToast('is-success', this.$t('auth.welcome'))
          return this.$router.push('/' + res.data.apps[0].slug + '?lang=' + res.data.language)
        }

        if (!res) {
          this.$router.push('/auth/login')
        }
      })
    }
  }
</script>
import Signup from '../../views/auth/Signup'
import Sofort from '../../views/auth/payments/Sofort'
import SimpleSignup from '../../views/auth/SimpleSignup'

export default [
	{
		path: '/auth/signup',
		component: Signup
	},
	{
		path: '/auth/signup/:step',
		component: Signup
	},
	{
		path: '/auth/signup/payment/sofort',
		component: Sofort
	},
	{
		path: '/:slug/signup/:code',
		component: SimpleSignup
	},
]

export default {
	data() {
		return {
			imageModeIdMapping: {
				'cover': 21,
				'contain': 22,
			},
		}
	},
	methods: {
		updateImageModeContextMenu() {
			let entry = this.entries.find(item => item.id === 2)
			if (entry) {
				entry.subEntries = entry.subEntries.map(item => {
					item.icon = null
					return item
				})
				let subEntry = entry.subEntries.find(item => item.id === this.imageModeIdMapping[this.element.image_mode])
				if (subEntry) {
					subEntry.icon = '/images/icons/check.svg'
				}
			}
		},
		select($event) {
			if (this[$event.function]) {
				this[$event.function]($event)
			}
		},
		/**
		 *  Sets the image mode of the display.
		 *  It it the way how the image is displayed e.g. cover/contains object fit.
		 */
		setImageMode($event) {
			if (this.element && $event) {
				this.$emit('setImageMode', $event.mode)
				this.updateImageModeContextMenu()
			}
		},
	},
	created() {
		this.updateImageModeContextMenu()
	}
}

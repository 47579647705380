<template>
  <div class="permission-group">
    <div class="header-container">
      <div class="header">{{ title }}</div>
      <div
        class="header-controls"
        v-if="enableHeaderControls">
        <b-switch
          @input="select"
          :value="value"
        />
      </div>
    </div>
    <slot/>
  </div>
</template>

<script>
  export default {
    props: [
      'title',
      'value',
      'enableHeaderControls',
    ],
    methods: {
      select() {
        this.$emit('input', !this.value)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .permission-group {
    @apply
      flex
      flex-col;

    .header-container {
      @apply
        flex
        mr-4
        justify-between;

      .header {
        @apply
          mb-2.5
          font-semibold;
      }
    }
  }
</style>
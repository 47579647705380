import Dashboard from '../../views/settings/Dashboard'
import Profile from '../../views/settings/Profile'
import Security from '../../views/settings/Security'

import Users from '../../views/settings/Users'
import Team from '../../views/settings/Team'
import Teams from '../../views/settings/Teams'
import Roles from '../../views/settings/Roles'
import Permissions from '../../views/settings/Permissions'
import Organisation from '../../views/settings/Organisation'

import Plans from '../../views/settings/Plans'
import Payments from '../../views/settings/Payments'
import Invoices from '../../views/settings/Invoices'
import Notifications from '../../views/settings/Notifications'

export default [
	{
		path: '/:slug/settings',
		redirect: '/:slug/settings/profile'
	},
	{
		path: '/:slug/settings/dashboard',
		component: Dashboard,
		meta: {
			auth: true,
		}
	},
	{
		path: '/:slug/settings/profile',
		component: Profile,
		meta: {
			auth: true,
		}
	},
	{
		path: '/:slug/settings/security',
		component: Security,
		meta: {
			auth: true,
		}
	},
	{
		path: '/:slug/settings/users',
		component: Users,
		meta: {
			auth: true,
		}
	},
	{
		path: '/:slug/settings/organization',
		component: Organisation,
		meta: {
			auth: true,
		}
	},
	{
		path: '/:slug/settings/teams',
		component: Teams,
		meta: {
			auth: true,
		}
	},
	{
		path: '/:slug/settings/teams/:teamSlug',
		component: Team,
		meta: {
			auth: true,
		}
	},
	{
		path: '/:slug/settings/permissions',
		component: Roles,
		meta: {
			auth: true,
		}
	},
	{
		path: '/:slug/settings/permissions/:roleId',
		component: Permissions,
		meta: {
			auth: true,
		}
	},
	{
		path: '/:slug/settings/plans',
		component: Plans,
		meta: {
			auth: true,
		}
	},
	{
		path: '/:slug/settings/payments',
		component: Payments,
		meta: {
			auth: true,
		}
	},
	{
		path: '/:slug/settings/invoices',
		component: Invoices,
		meta: {
			auth: true,
		}
	},
	{
		path: '/:slug/settings/notifications',
		component: Notifications,
		meta: {
			auth: true,
		}
	}
]

<template>
    <div class="card">
        <div class="card-header">
            <div
                v-if="icon"
                class="card-header-icon">
            </div>
            <div v-if="title"
                class="card-header-title">
                {{ title }}
            </div>
        </div>
        <div class="card-content card-word-wrap">
            <slot />
        </div>
    </div>
</template>

<script>
    export default {
    	props: {
    		title: {
    			type: String,
            },
            icon: {
    			type: String,
            }
        }
    }
</script>

<style lang="scss" scoped>
    .card-word-wrap {
        word-break: break-all;
    }
</style>

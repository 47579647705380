<template>
    <div class="c-search-category">
        <div class="c-search-category-title">
            <div class="c-search-category-text">{{ title }}</div>
        </div>
        <div class="c-search-category-container">
           <slot />
        </div>
    </div>
</template>

<script>
    export default {
    	props: [
    		'icon',
    		'title',
        ]
    }
</script>

<style lang="scss" scoped>
    .c-search-category {
        .c-search-category-title {
            padding: 8px;
            display: flex;
            border-radius: 4px;
            background: $primary-color;
            //background: $light-blue-color;

            /*.c-search-category-icon {*/
            /*    width: 32px;*/
            /*    height: 32px;*/
            /*    display: flex;*/
            /*    align-items: center;*/
            /*    justify-content: center;*/

            /*    img {*/
            /*        width: 20px;*/
            /*        height: 20px;*/
            /*    }*/
            /*}*/

            .c-search-category-text {
                display: flex;
                font-size: 16px;
                margin-left: 8px;
                font-weight: 500;
                align-items: center;
                font-family: Roboto;
                //color: $darker-blue-color;
                color: $white;

            }
        }

        .c-search-category-container {
            margin-bottom: 24px;
        }
    }
</style>

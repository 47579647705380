<template>
  <div class="c-preview-bottom">
    <div class="c-preview-bottom-container">
      <div class="c-preview-bottom-icon">
        <img src="images/do-logo.svg">
      </div>
      <div class="u-ml--8">
        <router-link to="/auth/signup" class="highlighted" target="_blank">
          {{ $t('display.trial_period') }}
        </router-link>
      </div>
    </div>
    <div class="c-preview-bottom-container"></div>
    <div class="c-preview-bottom-container">
      <Dropdown
          :default-value="$i18n.locale"
          :show-value-as-label="true"
          @select="selectLanguage"
          :values="languages"
          mode="open-top"
      />
    </div>
  </div>
</template>

<script>
import Dropdown from '../../global/dropdowns/Dropdown'
import {mapGetters} from 'vuex'

export default {
  computed: {
    ...mapGetters({
      isMobileMode: 'app/isMobileMode',
      availableLanguages: 'app/availableLanguages'
    }),
    languages() {
      return this.availableLanguages.map(item => {
        return {
          id: item,
          label: this.$t('app.language_' + item) + ' (' + item.toUpperCase() + ')'
        }
      })
    }
  },
  methods: {
    selectLanguage($event) {
      this.$router.push({
        query: {
          lang: $event
        }
      }).catch(err => {
      })
    }
  },
  components: {
    Dropdown
  }
}
</script>

<style lang="scss" scoped>
.c-preview-bottom {
  display: flex;
  font-size: 12px;
  font-family: Roboto;
  justify-content: space-between;

  @media (max-width: $breakpoint-mobile) {
    height: 66px;
    padding: 0 16px;
  }

  .c-preview-bottom-container {
    display: flex;
    align-items: center;

    .c-preview-bottom-icon {
      img {
        width: 32px;
        max-height: 32px;
      }
    }
  }
}
</style>

<template>
  <SidebarLayout :title="$t('settings.payments')">
    <Loader v-if="loading"/>
    <b-columns>
      <b-column>
        <b-card>
          <Table
              :data="data"
              :headers="headers"
              :empty="$t('settings.payments_empty')">
            <template slot-scope="data">
              <b-tooltip
                  :delay="tooltipDelay"
                  :label="$t('settings.change_default_payment_method')">
                <b-button
                    @click.prevent="setAsDefault(data.row.id)"
                    :disabled="data.row.default"
                    icon-left="money-bill-alt"
                    type="is-primary"
                    icon-pack="far"
                />
              </b-tooltip>
            </template>
          </Table>
        </b-card>
        <PaymentMethodModal @success="load"/>
      </b-column>
    </b-columns>
  </SidebarLayout>
</template>

<script>
import PaymentMethodModal from '../../components/settings/modals/PaymentMethodModal'
import SidebarLayout from '../../components/settings/SidebarLayout'
import Loader from '../../components/global/Loader'
import ModelMapper from '../../data/mapper'
import Table from '../../components/Table'
import APIFactory from '../../api/factory'
import Headers from '../../data/headers'
import Helpers from '../../helpers'
import {mapGetters} from 'vuex'

export default {
  computed: {
    ...mapGetters({
      tooltipDelay: 'ui/tooltipDelay'
    })
  },
  data() {
    return {
      headers: Headers.payments,
      loading: false,
      data: []
    }
  },
  created() {
    this.load()
  },
  methods: {
    load() {
      this.loading = true
      APIFactory.findPaymentMethods(this.$route.params.slug).then(res => {
        this.loading = false
        if (res && res.success) {
          this.data = ModelMapper.map(this, res.data, 'PaymentMethod')
        }
      })
    },
    setAsDefault(id) {
      this.loading = true
      APIFactory
      .setDefaultPaymentMethod(this.$route.params.slug, id)
      .then(res => {
        this.loading = false
        if (res && res.success) {
          Helpers.callToast('is-success', this.$t('settings.default_payment_successful'))
          this.load()
        }
      })
    },
  },
  components: {
    PaymentMethodModal,
    SidebarLayout,
    Loader,
    Table
  }
}
</script>

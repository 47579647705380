<template>
  <div class="c-receiver-display">
    <LoadingComponent :load="loading">
      <FolderPreview
        :folder="folder"
        v-if="step === 0"
        @open="updateFolderAndStepUp"
      />
      <FolderContent
        :folder="folder"
        v-if="step === 1"
      />
    </LoadingComponent>
  </div>
</template>

<script>
import Helpers from '../../helpers'
import APIFactory from '../../api/factory'
import FolderPreview from '../../components/display/FolderPreview'
import FolderContent from '../../components/display/FolderContent'
import LoadingComponent from '../../components/global/LoadingComponent'
import {mapGetters} from 'vuex'

export default {
  computed: {
    ...mapGetters({
      isMobileMode: 'app/isMobileMode'
    }),
  },
  data() {
    return {
      loading: false,
      folder: null,
      step: 0,
    }
  },
  created() {
    this.loadFolder()
  },
  methods: {
    createAPIRequest() {
      return this.$route.query
      && this.$route.query.password
        ? APIFactory.checkPassword(this.$route.params.code, this.$route.query.password)
        : APIFactory.findFolderByCode(this.$route.params.code)
    },
    loadFolder() {
      this.loading = true
      this.createAPIRequest()
      .then(res => {
        if (res && res.success) {
          if (this.$route.query && this.$route.query.password) {
            this.updateFolderAndStepUp(res.data)
            return
          }
          this.folder = res.data
        }
      }).finally(() => this.loading = false)
    },
    updateFolderAndStepUp($event) {
      this.folder = $event
      let totalIndex = 0
      if (this.folder.chapters) {
        this.folder.chapters = this.folder.chapters
        .filter(chapter => chapter.status === 'active')
        .sort((i1, i2) => i1.index - i2.index)
        .map((chapter, chapterIndex) => {
          if (!chapter.pages) {
            chapter.index = (chapterIndex + 1)
            chapter.totalIndex = ++totalIndex
            return chapter
          }

          chapter.pages = chapter.pages.sort((i1, i2) => i1.index - i2.index)

          let pageNum = 0
          return {
            ...chapter,
            index: (chapterIndex + 1),
            totalIndex: ++totalIndex,
            pages: chapter.pages
            .filter(page => page.status === 'active')
            .map(page => {
              return {
                ...page,
                index: pageNum++,
                totalIndex: ++totalIndex
              }
            })
          }
        })
      }
      this.folder.totalElements = totalIndex
      this.step++
    },
  },
  components: {
    LoadingComponent,
    FolderPreview,
    FolderContent
  }
}
</script>

<style lang="scss" scoped>
.c-receiver-display {
  width: 100%;
  height: 100%;
}
</style>

<template>
  <base-modal
    :title="title"
    :active="active"
    @close="active = false">

    <!-- Button -->
    <div
      slot="button"
      class="button-wrapper"
      @click.prevent="active = true">
      <slot/>
    </div>

    <!-- Content -->
    <div class="c-media-tabs u-mt--32 u-pb--16">
      <div :class="{
        'u-mb--16': true,
        'c-tab-button': true,
        'c-tab-button--selected': tabIndex === 0,
      }" @click.prevent="selectTab(0)">{{ $t('mediacenter.container_data') }}</div>
      <div :class="{
        'u-mb--16': true,
        'c-tab-button': true,
        'c-tab-button--selected': tabIndex === 1,
      }" @click.prevent="selectTab(1)">{{ $t('mediacenter.share_container') }}</div>
    </div>

    <!-- General tab -->
    <template v-if="tabIndex === 0">
      <form class="u-mt--24">
        <b-message class="warn-message mt--16" type="is-danger" v-if="isContainerReleased">
          {{ $t('mediacenter.uneditable_shared_media') }}
        </b-message>

        <div class="form-element">
          <label class="basic">{{ $t('mediacenter.container_name') }}*</label>
          <text-input
              v-model="container.name"
              :disabled="isContainerReleased"
              :placeholder="$t('mediacenter.container_name_placeholder')"
          />
        </div>

        <div class="form-element mt--16">
          <tag-input v-model="container.tags" :disabled="isContainerReleased">
            {{ $t('workspaces.tags') }}
          </tag-input>
        </div>

        <div class="form-element mt--16">
          <color-selector v-model="container.color" :disabled="isContainerReleased">
            {{ $t('mediacenter.container_color') }}
          </color-selector>
        </div>
      </form>
    </template>

    <!-- Share Tab -->
    <template v-if="tabIndex === 1">
      <TeamShareDialog
        :teams="teams"
        :container="container"
        v-model="container.teams"
      />
    </template>

    <!-- Actions -->
    <div
        slot="actions"
        class="modal-controls modal-controls--spacing">
      <Button
        :disable-icon-view-only-on-mobile="true"
        @click="cancel"
        :loading="loading"
        theme="default">
        {{ $t('general.cancel') }}
      </Button>
      <Button
        :disable-icon-view-only-on-mobile="true"
        :loading="loading"
        theme="primary"
        @click="save">
        <template v-if="container && container.id">{{ $t('general.save') }}</template>
        <template v-else>{{ $t('mediacenter.save_container') }}</template>
      </Button>
    </div>
  </base-modal>
</template>

<script>
import Helpers from '../../../helpers'
import APIFactory from '../../../api/factory'
import Button from '../../global/buttons/Button'
import TagInput from '../../global/inputs/TagInput'
import BaseModal from '../../global/modals/BaseModal'
import TextInput from '../../global/inputs/TextInput'
import ColorSelector from '../../global/inputs/ColorSelector'
import TeamShareDialog from '@/components/mediacenter/TeamShareDialog';

export default {
  props: [
    'activation',
    'container',
    'teams'
  ],
  computed: {
    title() {
      return this.container && this.container.id
          ? this.$t('mediacenter.edit_existing_container')
          : this.$t('mediacenter.create_new_container')
    },
    isContainerReleased() {
      if (this.teams) {
        let currentTeam = this.teams.find(item => item.slug === this.$route.params.teamId)
        if (currentTeam) {
          return this.container.team_id && this.container.team_id !== currentTeam.id
        }
      }
    },
  },
  data() {
    return {
      tabIndex: 0,
      active: false,
      loading: false,
    }
  },
  methods: {
    selectTab(index) {
      this.tabIndex = index
    },
    createAPIRequest() {
      return this.container && this.container.id
          ? APIFactory.updateMediaContainer(this.$route.params.slug, this.$route.params.teamId, this.container)
          : APIFactory.createMediaContainer(this.$route.params.slug, this.$route.params.teamId, this.container)
    },
    save() {
      this.loading = true
      this.createAPIRequest().then(res => {
        if (res && res.success) {
          Helpers.callToast('is-success', this.$t('mediacenter.save_container_success_message'))
          this.active = false
          this.$emit('success')

          this.$router
              .push({
                path: '/' + this.$route.params.slug
                    + '/media-center/'
                    + this.$route.params.teamId
                    + '/' + res.data.slug
              })
              .catch(err => {
              })
        }
      }).finally(() => {
        this.loading = false
      })
    },
    cancel() {
      this.active = false
      this.$emit('success');
    },
  },
  watch: {
    activation: {
      immediate: true,
      handler(value) {
        if (this.active !== value) {
          this.active = value
        }
      }
    },
    active(value) {
      if (this.activation !== value) {
        this.$emit('reset', value)
      }
    }
  },
  components: {
    TeamShareDialog,
    ColorSelector,
    BaseModal,
    TextInput,
    TagInput,
    Button
  }
}
</script>

<template>
  <div class="release-form">
    <div class="release-form-container">
      <img src="images/icons/close.svg"
       class="release-form--close"
       @click.prevent="$emit('close')"/>
      <div class="form-title">{{ $t('workspaces.release_folder') }}</div>
      <div class="form-wrapper">
        <div
          class="form-description"
          v-html="$t('workspaces.release_folder_description')"
        />
        <div class="form-password-protection">
          <div class="form-password-protection-wrapper">
            <div class="form-password-title">{{ $t('workspaces.activate_password') }}</div>
            <b-switch v-model="passwordProtection"/>
          </div>
          <div class="form-password-input" v-show="passwordProtection">
            <input
              class="basic expanded"
              v-model="folder.password"
              :placeholder="$t('workspaces.password_placeholder')"
            />
          </div>
        </div>
        <div class="form-url-copy">
          <div class="form-url-label">URL</div>
          <URLInput :url="url"/>
        </div>
        <div class="form-mail">
          <a :href="mail">{{ $t('workspaces.send_credentials') }}</a>
        </div>
        <div class="form-releasedDescription" v-if="folder.released_by_user && folder.released_at">
          {{  $t('workspaces.released_message', { name: folder.released_by_user, date: releasedAt }) }}
        </div>
      </div>
    </div>

    <div class="form-control">
      <Button
        theme="default"
        @click="$emit('close')"
        :disable-icon-view-only-on-mobile="true">
        {{ $t('general.cancel') }}
      </Button>
      <Button
        @click="submit"
        theme="primary"
        :disable-icon-view-only-on-mobile="true">
        {{ $t('workspaces.save_release') }}
      </Button>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import Helpers from '../../helpers'
import APIFactory from '../../api/factory'
import Button from '../global/buttons/Button'
import URLInput from '../global/inputs/URLInput'

export default {
  props: [
    'folder',
  ],
  computed: {
    url() {
      if (this.folder) {
        return window.location.origin + '/#' + this.folder.url
      }
    },
    mail() {
      let body = 'mailto:?subject=' + this.$t('workspaces.email_subject')
          + '&body=' + this.$t('workspaces.email_message', {link: this.url})

      if (this.folder.password || this.password) {
        body += '%0D%0A ' + this.$t('workspaces.email_password', {
          password: this.folder.password || this.password
        })
      }
      return body
    },
    releasedAt() {
      return moment(this.folder.released_at).format('DD.MM.YYYY - HH:mm')
    }
  },
  data() {
    return {
      loading: false,
      passwordProtection: !!this.password,
    }
  },
  methods: {
    submit() {
      this.loading = true
      APIFactory.changeStatus(
          this.$route.params.slug,
          this.$route.params.teamId,
          this.folder.id, {
            force: true,
            status: 'released',
            password: this.folder.password,
          }).then(res => {
        if (res && res.success) {
          this.$set(this.folder, 'url', res.data.url)
          this.$set(this.folder, 'password', res.data.password)
          Helpers.callToast('is-success', this.$t('workspaces.status_changed_successful_message'))
          this.$emit('success')
        }
      }).finally(() => this.loading = false)
    }
  },
  watch: {
    folder: {
      deep: true,
      immediate: true,
      handler() {
        this.passwordProtection = !!this.folder.password
      }
    },
    passwordProtection(val) {
      this.folder.password = val
          ? this.folder.password
          : null

      // PasswordProtection Toggle is set to true
      if (val) {
        let password = this.folder.password
        if (!password) {
          password = Helpers.randomPassword()
        }
        this.folder.password = password
      }
    }
  },
  components: {
    Button,
    URLInput,
  }
}
</script>

<style lang="scss" scoped>
.release-form {
  width: 100%;
  height: 100%;
  max-width: 512px;
  border-radius: 8px;
  background: $white;
  position: relative;
  padding: 48px 56px 30px;
  color: $darker-blue-color;
  font-family: Roboto, sans-serif;

  @media (max-width: $breakpoint-mobile) {
    padding: 0;
    max-width: none;
    border-radius: 0;
  }

  .release-form-container {
    height: calc(100% - 48px);
    overflow-y: auto;
    width: 100%;
  }

  .release-form--close {
    top: 32px;
    right: 32px;
    cursor: pointer;
    position: absolute;

    @media (max-width: $breakpoint-mobile) {
      top: 24px;
      right: 24px;
    }
  }

  .form-title {
    font-size: 24px;
    font-weight: 500;

    @media (max-width: $breakpoint-mobile) {
      padding: 0 16px;
      margin-top: 48px;
      font-size: 20px;
    }
  }

  .form-wrapper {
    @media (max-width: $breakpoint-mobile) {
      width: 100%;
      padding: 0 16px;
      overflow-y: auto;
      height: calc(100% - 136px);
    }
  }

  .form-description {
    font-size: 14px;
    margin-top: 16px;
    line-height: 1.71;

    @media (max-width: $breakpoint-mobile) {
      margin-top: 24px;
    }
  }

  .form-releasedDescription {
    font-size: 13px;
    margin-top: 16px;
    line-height: 1.71;
    font-style: italic;

    @media (max-width: $breakpoint-mobile) {
      margin-top: 24px;
    }
  }

  .form-password-protection {
    margin-top: 16px;
    padding: 16px 0 16px;
    border-top: 1px solid $light-blue-color;
    border-bottom: 1px solid $light-blue-color;

    @media (max-width: $breakpoint-mobile) {
      //margin: 16px;
    }

    .form-password-title {
      font-size: 14px;
      font-weight: 500;
    }

    .form-password-protection-wrapper {
      display: flex;
      align-items: center;
      margin-bottom: 8px;
      justify-content: space-between;

      @media (max-width: $breakpoint-mobile) {
        margin-bottom: 0;
      }
    }
  }

  .form-url-label {
    font-size: 12px;
    font-weight: 500;
    margin-top: 16px;
  }

  .form-mail {
    margin-top: 8px;

    @media (max-width: $breakpoint-mobile) {
      padding: 0 0px 32px;
    }

    a {
      font-size: 14px;
      color: $primary-color;
    }
  }

  .form-control {
    bottom: 30px;
    display: flex;
    margin-top: 90px;
    position: absolute;
    width: calc(100% - 112px);
    justify-content: space-between;

    @media (max-width: $breakpoint-mobile) {
      width: 100%;
      bottom: 16px;
      display: flex;
      padding: 0 16px;
    }
  }
}
</style>

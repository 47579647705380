<template>
  <drag :transfer-data="object.id"
        :class="{
    	    'c__media-object': true,
            'c__media-object--hidden': object.invisible
        }">
    <div @click.prevent="$emit('select', object.id)"
     :class="{
      'c__media-object-cover': true,
      'c__media-object--selected': selected,
      'c__media-object-cover--enable-selection': enableSelection
      }">
      <img :src="preview">
    </div>
    <IconButton
        @click="$router.go()"
        v-if="hasPendingThumbnail"
        icon="images/icons/move.svg"
        class="c-pending-object-refresh"
    />
    <div class="c__media-object-meta">
      <div class="c__media-object-fileType">
        <img :src="icon">
      </div>
      <div class="c__media-object-label">{{ object.name }}</div>
    </div>
    <div v-show="enableContext"
         class="c__media-object-context">
      <ObjectContext
          :object="object"
          :disabled="isContainerReleased"
          @move="$emit('move', $event)"
          @edit="$emit('edit', $event)"
          @delete="$emit('delete', $event)"
      />
    </div>
    <div v-show="enableSelection"
         @click.prevent="$emit('select', object.id)"
         :class="{
        	    'c__media-object-selection': true,
        	    'c__media-object-selection--selected': selected
             }">
      <img
          src="images/icons/check--white.svg"
          v-show="selected"
      />
    </div>
  </drag>
</template>

<script>
import ProcessingThumbnailPreview from './ProcessingThumbnailPreview'
import ObjectContext from './ObjectContext'
import Icon from '../global/Icon'
import IconButton from '../global/buttons/IconButton';

export default {
  props: [
    'object',
    'selected',
    'read-only',
    'enableContext',
    'enableSelection',
    'isContainerReleased'
  ],
  computed: {
    icon() {
      switch (this.object.preview_type) {
        case 'powerpoint':
        case 'excel':
        case 'word':
        case 'image':
          return '/images/icons/datatypes/' + this.object.preview_type + '.svg'
        case 'link':
          return '/images/icons/datatypes/link.svg'
      }
      return '/images/icons/datatypes/file.svg'
    },
    preview() {
      if (this.object.preview) {
        return this.object.preview
      }

      switch (this.object.preview_type) {
        case 'powerpoint':
        case 'excel':
        case 'word':
        case 'link':
          return '/images/objects/' + this.object.preview_type + '.svg'
        case 'image':
          return this.object.preview
      }
      return '/images/objects/file.svg'
    },
    hasPendingThumbnail() {
      return this.object
          && this.object.thumbnail
          && this.object.thumbnail.value === 'pending'
    },
  },
  components: {
    IconButton,
    ProcessingThumbnailPreview,
    ObjectContext,
    Icon
  }
}
</script>

<style lang="scss" scoped>
.c__media-object {
  width: 100%;
  flex: 1 0 180px;
  margin-left: 8px;
  max-width: 200px;
  user-select: none;
  margin-right: 8px;
  position: relative;
  margin-bottom: 24px;
  align-self: flex-start;

  @media (max-width: $breakpoint-mobile) {
    flex: 1 0 140px;
    max-width: 200px;
    margin-left: 3.75px;
    margin-right: 3.75px;
  }

  &.c__media-object--hidden {
    visibility: hidden;
  }

  /*&.c__media-object--small {
      width: 140px;
      height: auto;
      margin-left: 5.25px;
      margin-right: 5.25px;

      .c__media-object-cover {
          width: 140px;
          border-radius: 8px;

          &.c__media-object--selected {
              border: 5px solid rgba($primary-color, .5);

              img {
                  width: 130px;
                  height: 140px;
                  border-radius: 4px;
              }
          }
      }
  }*/

  .c__media-object-cover {
    height: 150px;
    max-width: 100%;
    background: $white;
    border-radius: 8px;
    border: 1px solid $lighter-gray-color;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 8px;
    }

    &.c__media-object-cover--enable-selection {
      cursor: pointer;
    }

    &.c__media-object--selected {
      border: 5px solid rgba($primary-color, .5);

      img {
        max-width: 100%;
        max-height: 140px;
        border-radius: 4px;
      }
    }
  }

  .c__media-object-meta {
    display: flex;
    margin-top: 8px;

    .c__media-object-label {
      font-size: 14px;
      margin-left: 8px;
      font-weight: 500;
      word-wrap: break-word;
      width: calc(100% - 24px);
      color: $darker-blue-color;
      font-family: Roboto, sans-serif;
    }

    .c__media-object-fileType {
      width: 16px;
      flex-shrink: 0;

      img {
        width: 16px;
        height: 16px;
      }
    }
  }

  .c__media-object-context {
    position: absolute;
    top: 8px;
    right: 8px;
  }

  .c__media-object-selection {
    position: absolute;
    top: 16px;
    left: 16px;
    width: 20px;
    height: 20px;
    display: flex;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    background: rgba($white, .75);
    border: solid 1px $dark-gray-color;

    img {
      width: 12px;
      height: 12px;
    }

    &.c__media-object-selection--selected {
      background: $primary-color;
      border-color: $primary-color;
    }
  }
}

.c-pending-object-refresh {
  position: absolute;
  bottom: 0;
  top: 60px;
  left: 80px;
  right: 0;
}
</style>

export default {
	"app": {
		"unknown_error": "\u041f\u0440\u043e\u0438\u0437\u043e\u0448\u043b\u0430 \u043d\u0435\u0438\u0437\u0432\u0435\u0441\u0442\u043d\u0430\u044f \u043e\u0448\u0438\u0431\u043a\u0430 . \u041f\u043e\u0436\u0430\u043b\u0443\u0439\u0441\u0442\u0430 \u0441\u0432\u044f\u0436\u0438\u0442\u0435\u0441\u044c \u0441 support @digital-folder.com",
		"name": "digital folder\u00ae",
		"language_de": "\u041d\u0435\u043c\u0435\u0446\u043a\u0438\u0439",
		"language_en": "\u0410\u043d\u0433\u043b\u0438\u0439\u0441\u043a\u0438\u0439",
		"language_cz": "Czech",
		"language_ru": "Russian",
		"search_objects": "\u041c\u0435\u0434\u0438\u044f \u043e\u0431\u044c\u0435\u043a\u0442\u044b",
		"search_containers": "\u041c\u0435\u0434\u0438\u044f  \u043a\u043e\u043d\u0442\u0435\u0439\u043d\u0435\u0440",
		"search_folders": "\u0426\u0438\u0444\u0440\u043e\u0432\u044b\u0435 \u043f\u0430\u043f\u043a\u0438",
		"search_racks": "\u041f\u043e\u043b\u043a\u0438",
		"country_germany": "\u0412\u043e\u0439\u0442\u0438 \u0432 \u0441\u0435\u0441\u0442\u0435\u043c\u0443",
		"country_austria": "Austria",
		"country_switzerland": "Switzerland"
	}, "auth": {
		"login": "sign in",
		"logout": "\u0412\u044b\u0439\u0442\u0438 \u0438\u0437 \u0441\u0438\u0441\u0442\u0435\u043c\u044b",
		"user_has_app": "\u041f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u044c \u0443\u0436\u0435 \u0437\u0430\u0440\u0435\u0433\u0438\u0441\u0442\u0440\u0438\u0440\u043e\u0432\u0430\u043d",
		"forget_text": "\u0415\u0441\u043b\u0438 \u0442\u044b \u0437\u0430\u0431\u044b\u043b \u0441\u0432\u043e\u0439 \u043f\u0430\u0440\u043e\u043b\u044c , \u043c\u043e\u0436\u0435\u0448\u044c \u0441 \u043f\u043e\u043c\u043e\u0449\u044c\u044e \u0432\u0432\u043e\u0434\u0430 \u0441\u0432\u043e\u0435\u0439 \u044d\u043b\u0435\u0435\u043a\u0442\u0440\u043e\u043d\u043d\u043e\u0439 \u043f\u043e\u0447\u0442\u044b \u043f\u043e\u043b\u0443\u0447\u0438\u0442\u044c \u043d\u043e\u0432\u044b\u0439.",
		"forget": "C\u0431\u0440\u043e\u0441 \u043f\u0430\u0440\u043e\u043b\u044f",
		"user_not_found": "\u041f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u044f \u0441 \u0442\u0430\u043a\u043e\u0439 \u044d\u043b\u0435\u043a\u0442\u0440\u043e\u043d\u043d\u043e\u0439 \u043f\u043e\u0447\u0442\u043e\u0439 \u043d\u0435 \u0441\u0443\u0449\u0435\u0441\u0442\u0432\u0443\u0435\u0442",
		"wrong_password": "\u041f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u044f \u043d\u0435 \u0441\u0443\u0449\u0435\u0441\u0442\u0432\u0443\u0435\u0442 \u0438\u043b\u0438 \u043d\u0435\u043f\u0440\u0430\u0432\u0438\u043b\u044c\u043d\u043e \u0440\u0430\u0431\u0440\u0430\u043d \u043f\u0430\u0440\u043e\u043b\u044c",
		"welcome": "\u0414\u043e\u0431\u0440\u043e \u043f\u043e\u0436\u0430\u043b\u043e\u0432\u0430\u0442\u044c \u043d\u0430\u0437\u0430\u0434",
		"forgot_success_message": "\u0422\u0432\u043e\u0439 \u043f\u0430\u0440\u043e\u043b\u044c \u0431\u044b\u043b \u0443\u0441\u043f\u0435\u0448\u043d\u043e \u0438\u0441\u043f\u0440\u0432\u0432\u043b\u0435\u043d. \u041c\u044b \u043e\u0442\u043f\u0440\u0430\u0432\u0438\u043b\u0438 \u043d\u0430 \u0442\u0432\u043e\u0439 \u044d\u043b\u0435\u043a\u0442\u0440\u043e\u043d\u043d\u044b\u0439 \u0430\u0434\u0440\u0435\u0441."
	}, "signup": {
		"version": "\u041a\u0430\u043a\u0430\u044f \u0438\u0435\u0440\u0441\u0438\u044f \u0442\u0435\u0431\u0435 \u043f\u043e\u0434\u0445\u043e\u0434\u0438\u0442?",
		"monthly_payment": "\u041c\u0435\u0441\u044f\u0447\u043d\u0430\u044f \u043f\u043b\u0430\u0442\u0430",
		"yearly_payment": "\u0413\u043e\u0434\u043e\u0432\u0430\u044f \u043e\u043f\u043b\u0430\u0442\u0430",
		"monthly": "\u0412 \u043c\u0435\u0441\u044f\u0446",
		"yearly": "\u0412 \u0433\u043e\u0434",
		"favourite_option": "\u0421\u0430\u043c\u044b\u0439 \u043f\u043e\u043f\u0443\u043b\u044f\u0440\u043d\u044b\u0439 \u0432\u0430\u0440\u0438\u0430\u043d\u0442",
		"constantly_free": "\u041f\u043e\u0441\u0442\u043e\u044f\u043d\u043d\u043e \u0431\u0435\u0441\u043f\u043b\u0430\u0442\u043d\u043e",
		"each_user": "\u0417\u0430 \u043e\u0434\u043d\u043e\u0433\u043e \u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u044f \/\u0432 \u043c\u0435\u0441\u044f\u0446",
		"signup_now": "\u041f\u0440\u0438\u0441\u043e\u0435\u0434\u0435\u043d\u044f\u0439\u0442\u0435\u0441\u044c",
		"upgrade_now": "\u0421\u043a\u043e\u0440\u0435\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u0442\u044c \u0442\u0430\u0440\u0438\u0444",
		"free": "\u0421\u0432\u043e\u0431\u043e\u0434\u043d\u044b\u0439",
		"basic": "\u0411a\u0437\u043e\u0432\u044b\u0439",
		"basic-monthly": "\u0411\u0430\u0437\u043e\u0432\u044b\u0439 ( \u0412 \u043c\u0435\u0441\u044f\u0446 )",
		"basic-yearly": "\u0411\u0430\u0437\u043e\u0432\u044b\u0439 ( \u0412 \u0433\u043e\u0434  )",
		"premium-monthly": "\u041f\u0440\u0435\u043c\u0438\u0430\u043b\u044c\u043d\u044b\u0439  \u0432 \u043c\u0435\u0441\u044f\u0446",
		"premium-yearly": "\u041f\u0440\u0435\u043c\u0438\u0430\u043b\u044c\u043d\u044b\u0439  \u0432 \u0433\u043e\u0434",
		"premium-yearly2": "\u041f\u0440\u0435\u043c\u0438\u0430\u043b\u044c\u043d\u044b\u0439  \u0432 \u0433\u043e\u0434",
		"coupon-no-cost": "\u0412\u0430\u0443\u0447\u0435\u0440 \u0442\u0430\u0440\u0438\u0444 \u043f\u0440\u0435\u043c\u0438\u0430\u043b\u044c\u043d\u044b\u0439",
		"premium": "\u041f\u0440\u0435\u043c\u0438\u044f",
		"free_subtitle": "\u0422\u044d\u0441\u0442\u043e\u0432\u043e\u0435 \u0437\u0430\u0434\u0430\u043d\u0438\u0435",
		"space_each_plan": "{space} \u0413\u0411 \u043e\u0431\u043b\u0430\u0447\u043d\u043e\u0433\u043e \u0445\u0440\u0430\u043d\u0438\u043b\u0438\u0449\u0430",
		"free_argument1": "\u041e\u0433\u0440\u0430\u043d\u0438\u0447\u0435\u043d\u043e \u043e\u0434\u043d\u043e\u0439 \u043f\u0430\u043f\u043a\u043e\u0439",
		"free_argument3": "\u0432 \u0442\u043e\u043c \u0447\u0438\u0441\u043b\u0435 30-\u0434\u043d\u0435\u0432\u043d\u044b\u0439 \u043f\u0440\u0435\u043c\u0438\u0430\u043b\u044c\u043d\u044b\u0439 \u0442\u0430\u0440\u0438\u0444\u043d\u044b\u0439 \u0442\u0435\u0441\u0442",
		"basic_subtitle": "\u0432\u0441\u0435 \u043e\u0441\u043d\u0430\u0432\u043d\u044b\u0435 \u0444\u0443\u043d\u043a\u0446\u0438\u0438",
		"basic_argument0": "\u041f\u0440\u043e\u0434\u0430\u043b\u0436\u0438\u0442\u0435\u043b\u044c\u043d\u043e\u0441\u0442\u044c 12 \u043c\u0435\u0441\u044f\u0446\u0435\u0432",
		"basic_argument1": "\u043d\u0435\u043e\u0433\u0440\u0430\u043d\u0438\u0447\u0435\u043d\u043e\u0435 \u0447\u0438\u0441\u043b\u043e \u043f\u0430\u043f\u0430\u043a",
		"basic_argument3": "100 % \u0431\u0435\u0437 \u0440\u0435\u043a\u043b\u0430\u043c\u044b                                                                                                   ",
		"premium_subtitle": "\u043f\u043e\u043b\u043d\u044b\u0439 \u043f\u0430\u043a\u0435\u0442",
		"premium_argument0": "\u041f\u0440\u043e\u0434\u0430\u043b\u0436\u0438\u0442\u0435\u043b\u044c\u043d\u043e\u0441\u0442\u044c 12 \u043c\u0435\u0441\u044f\u0446\u0435\u0432",
		"premium_argument1": "\u041d\u0435\u043e\u0433\u0440\u0430\u043d\u0438\u0447\u0435\u043d\u043e\u0435 \u0447\u0438\u0441\u043b\u043e \u043f\u0430\u043f\u043e\u043a",
		"premium_argument3": "100 % \u0431\u0435\u0437 \u0440\u0435\u043a\u043b\u0430\u043c\u044b                                                                                                   ",
		"additional_month_1": "(+{month} \u0431\u0435\u0441\u043f\u043b\u0430\u0442\u043d\u044b\u0435 \u043c\u0435\u0441\u044f\u0446\u044b)",
		"additional_month_2": "(+{month} \u0431\u0435\u0441\u043f\u043b\u0430\u0442\u043d\u044b\u0435 \u043c\u0435\u0441\u044f\u0446\u044b)",
		"selected_plan": "\u0432\u0430\u0448 \u0432\u044b\u0431\u0440\u0430\u043d\u044b\u0439 \u0442\u0430\u0440\u0438\u0444",
		"change_plan": "\u0438\u0437\u043c\u0435\u043d\u0438\u0442\u044c \u0442\u0430\u0440\u0438\u0444",
		"plan": "\u0442\u0430\u0440\u0438\u0444",
		"payment_type": "\u043f\u043e\u0434\u0442\u0432\u0435\u0440\u0436\u0434\u0435\u043d\u0438\u0435 \u043e\u043f\u043b\u0430\u0442\u044b",
		"licenses": "\u0447\u0438\u0441\u043b\u043e \u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u044c\u0441\u043a\u0438\u0445 \u043b\u0438\u0446\u0435\u043d\u0437\u0438\u0439",
		"today_summary": "\u0432\u0441\u0435\u0433\u043e \u0441\u0435\u0433\u043e\u0434\u043d\u044f",
		"summary": "\u043f\u043e\u043b\u043d\u0430\u044f \u0441\u0443\u043c\u043c\u0430 \u043f\u043b\u044e\u0441 \u043d\u0430\u043b\u043e\u0433",
		"after_summary": "\u043d\u0435\u0434\u043e\u0441\u0442\u0430\u044e\u0449\u0430\u044f \u0441\u0443\u043c\u043c\u0430 \u0440\u043b\u044e\u0441 \u043d\u0430\u043b\u043e\u0433",
		"change_after_time": "\u043f\u043e \u0438\u0441\u0442\u0435\u0447\u0435\u043d\u0438\u044e 12 \u043c\u0435\u0441\u044f\u0446\u0435\u0432 . \u0412\u044b \u0430\u0432\u0442\u043e\u043c\u0430\u0442\u0438\u0447\u0435\u0441\u043a\u0438 \u043f\u043e\u043f\u0430\u0434\u0430\u0435\u0442\u0435 \u0432 \u0431\u0435\u0441\u043f\u043b\u0430\u0442\u043d\u044b\u0439 \u0442\u0430\u0440\u0438\u0444.",
		"coupon_code": "\u041d\u043e\u043c\u0435\u0440 \u0432\u0430\u0443\u0447\u0435\u0440\u0430",
		"coupon_code_placeholder": "\u0432\u0432\u0435\u0434\u0438\u0442\u0435 \u043d\u043e\u043c\u0435\u0440 \u0432\u0430\u0443\u0447\u0435\u0440\u0430",
		"coupon_invalid": "\u043d\u043e\u043c\u0435\u0440 \u0432\u0430\u0443\u0447\u0435\u0440\u0430 \u043d\u0435\u0434\u0435\u0439\u0441\u0442\u0432\u0438\u0442\u0435\u043b\u0435\u043d",
		"coupon_valid": "\u043d\u043e\u043c\u0435\u0440 \u0432\u0430\u0443\u0447\u0435\u0440\u0430 \u0434\u0435\u0439\u0441\u0442\u0432\u0438\u0442\u0435\u043b\u0435\u043d",
		"personal_heading": "\u041f\u0435\u0440\u0441\u043e\u043d\u0430\u043b\u044c\u043d\u044b\u0435 \u0434\u0430\u043d\u043d\u044b\u0435",
		"firstname": "\u0418\u043c\u044f",
		"firstname_placeholder": "\u0432\u0432\u0435\u0434\u0438\u0442\u0435 \u0438\u043c\u044f",
		"lastname": "\u0424\u0430\u043c\u0438\u043b\u0438\u044f",
		"lastname_placeholder": "\u0432\u0432\u0435\u0434\u0438\u0442\u0435 \u0441\u0432\u043e\u044e \u0444\u0430\u043c\u0438\u043b\u0438\u044e",
		"password": "\u043f\u0430\u0440\u043e\u043b\u044c",
		"password_placeholder": "\u0432\u0432\u0435\u0434\u0438\u0442\u0435 \u043f\u0430\u0440\u043e\u043b\u044c",
		"password_confirm": "\u043f\u043e\u0432\u0442\u0430\u0440\u0438\u0442\u0435 \u043f\u0430\u0440\u043e\u043b\u044c",
		"password_personal": "\u0412\u0430\u0448 \u043f\u0435\u0440\u0441\u043e\u043d\u0430\u043b\u044c\u043d\u044b\u0439 \u043f\u0430\u0440\u043e\u043b\u044c \u0437\u0430\u0444\u0435\u043a\u0441\u0438\u0440\u043e\u0432\u0430\u043d",
		"password_personal_placeholder": "\u0437\u0434\u0435\u0441\u044c \u0432\u0432\u0435\u0434\u0438\u0442\u0435 \u043f\u0435\u0440\u0441\u043e\u043d\u0430\u043b\u044c\u043d\u044b\u0439 \u043f\u0430\u0440\u043e\u043b\u044c",
		"email_address": "\u0430\u0434\u0440\u0435\u0441 \u044d\u043b\u0435\u043a\u0442\u0440\u043e\u043d\u043d\u043e\u0439 \u043f\u043e\u0447\u0442\u044b",
		"email_address_placeholder": "\u0432\u0432\u0435\u0434\u0438\u0442\u0435 \u0430\u0434\u0440\u0435\u0441 \u044d\u043b\u0435\u043b\u0442\u0440\u043e\u043d\u043d\u043e\u0439 \u043f\u043e\u0447\u0442\u044b",
		"personal_information": "\u0420\u0435\u0433\u0438\u0441\u0442\u0440\u0438\u0440\u0443\u044f\u0441\u044c, \u0432\u044b \u0441\u043e\u0433\u043b\u0430\u0448\u0430\u0435\u0442\u0435\u0441\u044c \u0441 \u043d\u0430\u0448\u0438\u043c\u0438 <a class=\"highlighted\" target=\"_blank\" href=\"https:\/\/www.digital-folder.com\/terms\">\u0423\u0441\u043b\u043e\u0432\u0438\u044f \u044d\u043a\u0441\u043f\u043b\u0443\u0430\u0442\u0430\u0446\u0438\u0438<\/a> \u0430 \u0442\u0430\u043a\u0436\u0435 \u0445\u0440\u0430\u043d\u0435\u043d\u0438\u0435 \u0438 \u043e\u0431\u0440\u0430\u0431\u043e\u0442\u043a\u0430 \u0432\u0430\u0448\u0438\u0445 \u043b\u0438\u0447\u043d\u044b\u0445 \u0434\u0430\u043d\u043d\u044b\u0445 \u0432 \u0441\u043e\u043e\u0442\u0432\u0435\u0442\u0441\u0442\u0432\u0438\u0438 \u0441 \u043d\u0430\u0448\u0438\u043c\u0438 <a class=\"highlighted\" target=\"_blank\" href=\"https:\/\/www.digital-folder.com\/wp-content\/uploads\/2019\/10\/Datenschutzerklaerung.pdf\">\u041f\u043e\u043b\u0438\u0442\u0438\u043a\u0430 \u043a\u043e\u043d\u0444\u0438\u0434\u0435\u043d\u0446\u0438\u0430\u043b\u044c\u043d\u043e\u0441\u0442\u0438<\/a> zu.",
		"continue": "\u0434\u0430\u043b\u044c\u0448\u0435",
		"back": "\u043d\u0430\u0437\u0430\u0434",
		"confirm_not_equal": "\u043e\u0431\u0430 \u043f\u0430\u0440\u043e\u043b\u044f \u043d\u0435\u0441\u043e\u043e\u0442\u0432\u0435\u0442\u0441\u0442\u0432\u0443\u044e\u0442 \u0434\u0440\u0443\u0433 \u0434\u0440\u0443\u0433\u0443",
		"password_needs_several_chars": "\u043f\u0430\u0440\u043e\u043b\u044c \u0434\u043e\u043b\u0436\u0435\u043d \u0431\u044b\u0442\u044c \u0438\u0437 \u043c\u0438\u043d\u0438\u043c\u0443\u043c 6 \u0447\u0438\u0441\u0435\u043b. \u0410 \u0442\u0430\u043a\u0436\u0435 \u043c\u0430\u043b\u0435\u043d\u044c\u043a\u0430\u044f \u0438 \u0431\u043e\u043b\u044c\u0448\u0430\u044f \u0431\u0443\u043a\u0432\u0430. \u0418 \u0434\u043e\u043f\u0430\u043b\u043d\u0438\u0442\u0435\u043b\u044c\u043d\u043e\u0435 \u0447\u0438\u0441\u043b\u043e.",
		"company_heading": "\u0418\u043d\u0444\u043e\u0440\u043c\u0430\u0446\u0438\u044f \u043e \u043a\u043e\u043c\u043f\u0430\u043d\u0438\u0438",
		"company": "\u041d\u0430\u0437\u0432\u0430\u043d\u0438\u0435 \u043a\u043e\u043c\u043f\u0430\u043d\u0438\u0438",
		"company_placeholder": "\u0432\u0432\u0435\u0434\u0438\u0442\u0435 \u043d\u0430\u0437\u0432\u0430\u043d\u0438\u0435 \u043a\u043e\u043c\u043f\u0430\u043d\u0438\u0438",
		"country": "\u0441\u0442\u0440\u0430\u043d\u0430 \u043f\u0440\u043e\u0436\u0438\u0432\u0430\u043d\u0438\u044f",
		"country_placeholder": "\u0412\u0432\u0435\u0434\u0438\u0442\u0435 \u043d\u0430\u0437\u0432\u0430\u043d\u0438\u0435 \u0441\u0442\u0440\u0430\u043d\u044b",
		"street": "\u0423\u043b\u0438\u0446\u0430",
		"street_placeholder": "\u0412\u0432\u0435\u0434\u0438\u0442\u0435 \u043d\u0430\u0437\u0432\u0430\u043d\u0438\u0435 \u0443\u043b\u0438\u0446\u044b",
		"postal_code": "\u0438\u043d\u0434\u0435\u043a\u0441",
		"postal_code_placeholder": "\u0412\u0432\u0435\u0434\u0438\u0442\u0435 \u0438\u043d\u0434\u0435\u043a\u0441",
		"city": "\u0413\u043e\u0440\u043e\u0434",
		"vat_id": "\u043a\u043e\u0434 \u043f\u043b\u0430\u0442\u0435\u043b\u044c\u0449\u0438\u043a\u0430 \u041d\u0414\u0421",
		"vat_id_placeholder": "\u0432\u0432\u0435\u0434\u0438\u0442\u0435 \u043d\u0430\u043b\u043e\u0433\u043e\u0432\u044b\u0439 \u0438\u043d\u0434\u0435\u0444\u0438\u043a\u0430\u0442\u043e\u0440",
		"city_placeholder": "\u0432\u0432\u0435\u0434\u0438\u0442\u0435 \u0433\u043e\u0440\u043e\u0434",
		"mediation": "\u043e\u043f\u043e\u0441\u0440\u0435\u0434\u043e\u0432\u0430\u043d\u043d\u043e \u0447\u0435\u0440\u0435\u0437",
		"mediation_placeholder": "\u0432\u0432\u0435\u0434\u0438\u0442\u0435 \u043e\u043f\u043e\u0441\u0440\u0435\u0434\u043e\u0432\u0430\u0442\u0435\u043b\u044f",
		"additional_payment_info": "\u0434\u043e\u043f\u043e\u043b\u043d\u0438\u0442\u0435\u043b\u044c\u043d\u0430\u044f \u0438\u043d\u0444\u043e\u0440\u043c\u0430\u0446\u0438\u044f \u043e\u0431 \u043e\u043f\u043b\u0430\u0442\u0435",
		"payment_heading": "\u0441\u043f\u043e\u0441\u043e\u0431 \u043e\u043f\u043b\u0430\u0442\u044b",
		"payment_type_card": "\u043a\u0440\u0435\u0434\u0438\u0442\u043d\u0430\u044f \u043a\u0430\u0440\u0442\u0430",
		"payment_type_card_info": "\u0438\u043d\u0444\u043e\u0440\u043c\u0430\u0446\u0438\u044f \u043e \u043a\u0440\u0435\u0434\u0438\u0442\u043d\u043e\u0439 \u043a\u0430\u0440\u0442\u0435",
		"payment_type_iban": "\u043f\u0440\u044f\u043c\u043e\u0439 \u0434\u0435\u0431\u0435\u0442 SEPA",
		"payment_type_iban_info": "IBAN",
		"payment_type_iban_info_text": "\u042f \u0434\u0430\u044e \u0446\u0438\u0444\u0440\u043e\u0432\u0443\u044e \u043e\u043f\u0442\u0438\u043c\u0438\u0437\u0430\u0446\u0438\u044e \u2013 \u0433\u0440\u043e\u0441 \u0438 \u043f\u0430\u0440\u0442\u043d\u0435\u0440 SEPA \u043f\u0440\u044f\u043c\u043e\u0439 \u0434\u0435\u0431\u0435\u0442 \u043c\u0430\u043d\u0434\u0430\u0442 \u0434\u043b\u044f \u0432\u044b\u0448\u0435 \u0443\u043a\u0430\u0437\u0430\u043d\u043d\u043e\u0433\u043e \u0431\u0430\u043d\u043a\u043e\u0432\u0441\u043a\u043e\u0433\u043e \u0441\u0447\u0435\u0442\u0430. \u042f \u043c\u043e\u0433\u0443 \u0432\u0435\u0440\u043d\u0443\u0442\u044c \u043f\u0440\u044f\u043c\u043e\u0439 \u0434\u0435\u0431\u0435\u0442 \u0432 \u0442\u0435\u0447\u0435\u043d\u0438\u0438 8 \u043d\u0435\u0434\u0435\u043b\u044c, \u041f\u043e\u0441\u043b\u0435 \u0441\u043f\u0438\u0441\u0430\u043d\u0438\u044f \u0441\u0443\u043c\u043c\u044b.",
		"summary_heading": "\u041a\u0440\u0432\u0442\u043a\u043e\u0435 \u0438\u0437\u043b\u043e\u0436\u0435\u043d\u0438\u0435 \u0437\u0430\u043a\u0430\u0437\u0430",
		"price_incl_vat": "\u0446\u0435\u043d\u0430 \u043f\u043b\u044e\u0441 \u043d\u0430\u043b\u043e\u0433",
		"cost": "\u0446\u0435\u043d\u0430  ",
		"terms_company": "\u044f \u043f\u043e\u0434\u0442\u0432\u0435\u0440\u0436\u0434\u0430\u044e , \u0447\u0442\u043e \u044f \u043a\u0430\u043a \u043f\u0440\u0435\u0434\u043f\u0440\u0438\u043d\u0438\u043c\u0430\u0442\u0435\u043b\u044c \u0440\u0435\u0433\u0435\u0441\u0442\u0440\u0438\u0440\u0443\u044e\u0441\u044c.",
		"terms_of_service": "\n\u042f \u043f\u0440\u0438\u043d\u0438\u043c\u0430\u044e <a href=\"https:\/\/www.digital-folder.com\/terms\" target=\"_blank\" class=\"highlighted\">\u043e\u0431\u0449\u0438\u0435 \u043f\u043e\u043b\u043e\u0436\u0435\u043d\u0438\u044f \u0438 \u0443\u0441\u043b\u043e\u0432\u0438\u044f<\/a>.",
		"order_data_processing": "\u042f \u0441\u043e\u0433\u043b\u0430\u0441\u0435\u043d \u043d\u0430 <a href=\"https:\/\/www.digital-folder.com\/wp-content\/uploads\/2019\/10\/ADV-digital-optimisation.pdf\" target=\"_blank\" class=\"highlighted\">\u043e\u0431\u0440\u0430\u0431\u043e\u0442\u043a\u0443 \u0434\u0430\u043d\u043d\u044b\u0445 \u0437\u0430\u043a\u0430\u0437\u0430<\/a>.",
		"signup_success": " \u0422\u044b \u0443\u0434\u0430\u0447\u043d\u043e \u0437\u0430\u0440\u0435\u0433\u0438\u0441\u0442\u0440\u0438\u0440\u043e\u0432\u0430\u043b\u0441\u044f \u043d\u0430 \u0441\u0442\u0440\u0430\u043d\u0438\u0446\u0435 digital folder\u00ae. \u041f\u043e\u0436\u0430\u043b\u0443\u0439\u0441\u0442\u0430 \u043f\u043e\u0434\u0442\u0432\u0435\u0440\u0434\u0438 \u0441\u0432\u043e\u0439 \u0441\u0447\u0435\u0442.",
		"welcome_message": "\u0422\u044b \u043f\u0440\u0438\u0433\u043b\u0432\u0448\u0435\u043d \u043d\u0430 \u0441\u0442\u0440\u0430\u043d\u0438\u0446\u0443 digital folder\u00ae, \u0434\u043b\u044f \u0440\u0430\u0431\u043e\u0442\u044b \u0432 \u043a\u043e\u043c\u0430\u043d\u0434\u0435.",
		"invite_not_found": "\u043a \u0441\u043e\u0436\u0430\u043b\u0435\u043d\u0438\u044e \u043f\u0440\u0438\u0433\u043b\u0430\u0448\u0435\u043d\u0438\u0435 \u043f\u0440\u043e\u0441\u0440\u043e\u0447\u0435\u043d\u043e",
		"upgrade_success": "\u0442\u0432\u043e\u0439 \u0441\u0447\u0435\u0442 \u0443\u0434\u0430\u0447\u043d\u043e\u043e\u0431\u043d\u0430\u0432\u043b\u0435\u043d",
		"current_plan": "\u0410\u043a\u0442\u0443\u0430\u043b\u044c\u043d\u044b\u0439 \u0442\u0430\u0440\u0438\u0444",
		"coupon_exists": "\u0438\u043c\u0435\u0435\u0442\u0435 \u0432\u0430\u0443\u0447\u0435\u0440? \u041d\u0430\u0436\u043c\u0438\u0442\u0435 \u0437\u0434\u0435\u0441\u044c",
		"coupon_invalid_date": "\u043a \u0441\u043e\u0436\u0430\u043b\u0435\u043d\u0438\u044e \u0432\u0430\u0443\u0447\u0435\u0440 \u0438\u0441\u0442\u0435\u043a",
		"coupon_already_used": "\u0442\u0432\u043e\u0439 \u0432\u0430\u0443\u0447\u0435\u0440 \u0443\u0436\u0435 \u0438\u0441\u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u043d",
		"company_signup_tooltip": "\u0422\u044b \u043f\u043e\u0434\u0442\u0432\u0435\u0440\u0436\u0434\u0430\u0435\u0448\u044c , \u0447\u0442\u043e \u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u043d\u0438\u0435 digital folder\u00aes  \u0438\u0441\u043f\u043e\u043b\u044c\u0437\u0443\u0435\u0448\u044c \u0434\u043b\u044f \u043f\u0440\u0435\u0434\u043f\u0440\u0438\u044f\u0442\u0438\u044f , \u0430 \u043d\u0435 \u0434\u043b\u044f \u0441\u0435\u0431\u044f \u043b\u0438\u0447\u043d\u043e. \u041f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u043d\u0438\u0435 \u043f\u0440\u0435\u0434\u043d\u0430\u0437\u043d\u0430\u0447\u0435\u043d\u043e \u0442\u043e\u043b\u044c\u043a\u043e \u0434\u043b\u044f \u043f\u0440\u0435\u0434\u043f\u0440\u0438\u044f\u0442\u0438\u044f .",
		"verified": "\u0432\u0430\u0448 \u0430\u043a\u043a\u0430\u0443\u043d\u0442 \u0443\u0434\u0430\u0447\u043d\u043e \u043f\u043e\u0434\u0442\u0432\u0435\u0440\u0436\u0434\u0435\u043d. \u0422\u044b \u043c\u043e\u0436\u0435\u0448\u044c \u0430\u0432\u0442\u043e\u0440\u0438\u0437\u043e\u0432\u0430\u0442\u044c\u0441\u044f.",
		"account_not_verified": "\u0442\u0432\u043e\u0439 \u0430\u043a\u043a\u0430\u0443\u043d\u0442 \u043d\u0435 \u043c\u043e\u0436\u0435\u0442 \u0430\u0432\u0442\u043e\u0440\u0438\u0437\u043e\u0432\u0430\u0442\u044c\u0441\u044f. \u041e\u0431\u0440\u0430\u0442\u0438\u0441\u044c \u043f\u043e\u0436\u0430\u043b\u0443\u0439\u0441\u0442\u0430 supportq*@digital-folder.com",
		"not_verified": "\u0442\u0432\u043e\u0439 \u0430\u043a\u043a\u0430\u0443\u043d\u0442 \u043d\u0435 \u0430\u0432\u0442\u043e\u0440\u0438\u0437\u043e\u0432\u0430\u043d. \u0422\u044b \u043f\u043e\u043b\u0443\u0447\u0438\u043b \u043e\u0442 \u043d\u0430\u0441 \u043f\u0438\u0441\u044c\u043c\u043e \u043d\u0430 \u0437\u043b\u0435\u043a\u0442\u0440\u043e\u043d\u043d\u044b\u0439 \u0430\u0434\u0440\u0435\u0441. \u0421 \u043f\u043e\u043c\u043e\u0449\u044c\u044e \u043d\u0435\u0433\u043e \u0442\u044b \u0441\u043c\u043e\u0436\u0435\u0448\u044c \u0430\u0432\u0442\u043e\u0440\u0438\u0437\u043e\u0432\u0430\u0442\u044c\u0441\u044f.",
		"countries_soon": "Currently the platform is only available in Germany, Austria and Switzerland. We will soon expand to other countries. Please be a bit patient. ",
		"company_advice": "(только если вы зарегистрируетесь как компания)",
		"licenses_info": "Please enter tthe total amout of users",
		"downgrade_disabled": "currently the downgrade option is not activated. Please cancel your curretn plan first and then chose the plan you want. Alternatively contact support@digital-folder.com"
	}, "general": {
		"help": "\u043f\u043e\u043c\u043e\u0449\u044c",
		"email": "\u044d\u043b\u0435\u043a\u0442\u0440\u043e\u043d\u043d\u044b\u0439 \u0430\u0434\u0440\u0435\u0441",
		"password": "\u043f\u0430\u0440\u043e\u043b\u044c",
		"password_confirm": "\u043f\u043e\u0432\u0442\u0430\u0440\u0438\u0442\u0435 \u043f\u0430\u0440\u043e\u043b\u044c",
		"validation_failed": "\u043f\u043e\u0436\u0430\u043b\u0443\u0439\u0441\u0442\u0430 \u0437\u0430\u043f\u043e\u043b\u043d\u0438\u0442\u0435 \u0441\u043b\u0435\u0434\u0443\u044e\u0449\u0438\u0435 \u043f\u043e\u043b\u044f",
		"invalid_form": "\u043d\u0435 \u0432\u0441\u0435 \u043e\u0431\u044f\u0437\u0430\u0442\u0435\u043b\u044c\u043d\u044b\u0435 \u043f\u043e\u043b\u044f \u0437\u0430\u043f\u043e\u043b\u043d\u0435\u043d\u044b",
		"firstname": "\u0438\u043c\u044f",
		"lastname": "\u0444\u0430\u043c\u0438\u043b\u0438\u044f",
		"language": "\u042f\u0437\u044b\u043a",
		"department": "\u043e\u0442\u0434\u0435\u043b",
		"save": "\u0417\u0430\u043f\u0438\u0441\u0430\u0442\u044c",
		"actions": "\u0430\u043a\u0446\u0438\u0438",
		"name": "\u041d\u0430\u0437\u0432\u0430\u043d\u0438\u0435",
		"role": "\u0440\u043e\u043b\u044c",
		"roles": "\u0440\u043e\u043b\u0438",
		"description": "\u043e\u043f\u0438\u0441\u0430\u043d\u0438\u0435",
		"optional": "\u043d\u0435\u043e\u0431\u044f\u0437\u0430\u0442\u0435\u043b\u044c\u043d\u044b\u0439",
		"status": "\u0441\u0442\u0430\u0442\u0443\u0441",
		"cancel": "\u0440\u0430\u0441\u0447\u0435\u0442",
		"confirm": "\u043f\u043e\u0434\u0442\u0432\u0435\u0440\u0436\u0434\u0435\u043d\u0438\u0435   ",
		"no_permissions": "\u0443 \u0442\u0435\u0431\u044f \u043d\u0435\u0442 \u043f\u0440\u0430\u0432\u0430 \u044d\u0442\u043e \u0434\u0435\u043b\u0430\u0442\u044c",
		"yes": "\u0434\u0430  ",
		"no": "\u043d\u0435\u0442",
		"ok": "\u0445\u043e\u0440\u043e\u0448\u043e",
		"no_app_found": "\u042d\u0442\u043e\u0442 \u044d\u043a\u0437\u0435\u043c\u043f\u043b\u044f\u0440 digital folder\u00ae \u0431\u043e\u043b\u044c\u0448\u0435 \u043d\u0435 \u0441\u0443\u0449\u0435\u0441\u0442\u0432\u0443\u0435\u0442 \u0438\u043b\u0438 \u0431\u044b\u043b \u0437\u0430\u0431\u043b\u043e\u043a\u0438\u0440\u043e\u0432\u0430\u043d. \u041e\u0431\u0440\u0430\u0442\u0438\u0441\u044c \u043f\u043e\u0436\u0430\u043b\u0443\u0439\u0441\u0442\u0430 support@digital-folder.com",
		"app_blocked": "\u042d\u0442\u043e \u043f\u0440\u0438\u043c\u0435\u043d\u0435\u043d\u0438\u0435 \u0437\u0430\u0431\u043b\u043e\u043a\u0438\u0440\u043e\u0432\u0430\u043d\u043d\u043e \u0430\u0434\u043c\u0438\u043d\u0438\u0441\u0442\u0440\u0430\u0442\u043e\u0440\u043e\u043c.",
		"no_team_found": "\u042d\u0442\u0430 \u043a\u0430\u043c\u0430\u043d\u0434\u0430 \u0431\u043e\u043b\u044c\u0448\u0435 \u043d\u0435 \u0441\u0443\u0449\u0435\u0441\u0442\u0432\u0443\u0435\u0442 \u0438\u043b\u0438 \u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u044c \u044d\u0442\u043e\u0439 \u043e\u043c\u0430\u043d\u0434\u044b \u043d\u0435\u043d\u0430\u0437\u043d\u0430\u0447\u0435\u043d.",
		"unauthenticated": "\u043f\u043e\u0436\u0430\u043b\u0443\u0439\u0441\u0442\u0430 \u0437\u0430\u0440\u0435\u0433\u0438\u0441\u0442\u0440\u0438\u0440\u0443\u0439\u0442\u0435\u0441\u044c \u0441\u043d\u043e\u0432\u0430",
		"invited_at": "invited at",
		"accepted_at": "invitation accepted at"
	}, "display": {
		"help": "\u043f\u043e\u043c\u043e\u0449\u044c",
		"open": "\u0442\u043a\u0440\u044b\u0442\u044c \u043f\u0430\u043f\u043a\u0443",
		"wrong_password": "\u043d\u0430\u0431\u0440\u0430\u043d\u043d\u044b\u0439 \u043f\u0430\u0440\u043e\u043b\u044c \u043e\u0441\u0432\u043e\u0431\u0430\u0436\u0434\u0435\u043d\u043d\u043e\u0439 \u043f\u0430\u043f\u043a\u0438 \u043d\u0435 \u0434\u0435\u0439\u0441\u0442\u0432\u0438\u0442\u0435\u043b\u0435\u043d.",
		"description_1": "\u0432\u044b \u0432\u044b\u0437\u0432\u0430\u043b\u0438  \u0441\u0441\u044b\u043b\u043a\u0443 digital folder\u00ae",
		"description_2": "\u0447\u0442\u043e\u0431\u044b \u0443\u0432\u0438\u0434\u0435\u0442\u044c \u0441\u043e\u0434\u0435\u0440\u0436\u0430\u043d\u0438\u0435, \u043d\u0438\u0436\u0435 \u043d\u0430\u0431\u0435\u0440\u0438 \u0443\u043f\u043e\u043c\u044f\u043d\u0443\u0442\u044b\u0439 \u043f\u0430\u0440\u043e\u043b\u044c.",
		"description_3": "\u041a \u0441\u043e\u0436\u0430\u043b\u0435\u043d\u0438\u044e \u0446\u0438\u0444\u0440\u043e\u0432\u043e\u0439 \u043f\u0430\u043f\u043a\u0438 \u0431\u043e\u043b\u044c\u0448\u0435 \u043d\u0435 \u0441\u0443\u0449\u0435\u0441\u0442\u0432\u0443\u0435\u0442.\u0412\u043b\u0430\u0434\u0435\u043b\u0435\u0446 digital folder\u00ae \u0432\u0440\u0435\u043c\u0435\u043d\u043d\u043e \u0437\u0431\u043b\u043e\u043a\u0438\u0440\u043e\u0432\u0430\u043b.",
		"no_folder_found_headline": "\u043f\u043e\u0436\u0430\u043b\u0443\u0439\u0441\u0442\u0430 \u0438\u0437\u0432\u0435\u043d\u0438\u0442\u0435",
		"request_password": "\u0432\u044b \u0437\u0430\u0431\u044b\u043b\u0438 \u043f\u0430\u0440\u043e\u043b\u044c?",
		"email_subject": "\u0437\u0430\u043f\u0440\u043e\u0441 \u043f\u0430\u0440\u043e\u043b\u044f",
		"menu_open": "\u043f\u043e\u043a\u0430\u0437\u0430\u0442\u044c \u043e\u0433\u043b\u0430\u0432\u043b\u0435\u043d\u0438\u0435",
		"menu_hide": "\u0441\u043a\u0440\u044b\u0442\u044c \u043e\u0433\u043b\u0430\u0432\u043b\u0435\u043d\u0438\u0435",
		"close": "close folder",
		"show_new_elements": "Show new elements",
		"hide_new_elements": "Hide new elements",
		"trial_period": "Free 30 days trial",
		"create_link": "Copy link to this page"
	}, "settings": {
		"account": "\u0441\u0447\u0435\u0442",
		"dashboard": "\u043e\u0431\u0437\u043e\u0440",
		"profile": "\u043f\u0440\u043e\u0444\u0438\u043b\u044c \u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u044f",
		"security": "\u0431\u0435\u0437\u043e\u043f\u0430\u0441\u043d\u043e\u0441\u0442\u044c",
		"organization": "\u043e\u0440\u0433\u0430\u043d\u0438\u0437\u0430\u0446\u0438\u044f",
		"company": "\u043f\u0440\u043e\u0438\u0437\u0432\u043e\u0434\u0441\u0442\u0432\u043e",
		"users": "\u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u044c   ",
		"teams": "\u043a\u043e\u043c\u0430\u043d\u0434\u044b",
		"team": "\u043a\u043e\u043c\u0430\u043d\u0434\u0430",
		"website": "\u0412\u0435\u0431-\u0441\u0430\u0439\u0442",
		"vat_number": "\u041d\u0414\u0421",
		"permissions": "\u0440\u043e\u043b\u0438 \u0438 \u0440\u0430\u0437\u0440\u0435\u0448\u0435\u043d\u0438\u044f",
		"accounting": "\u0440\u0430\u0441\u0447\u0435\u0442",
		"plans": "\u0438\u0437\u043c\u0435\u043d\u0435\u043d\u0438\u0435 \u0442\u0430\u0440\u0438\u0444\u0430",
		"settings": "\u043d\u0430\u0441\u0442\u0440\u043e\u0439\u043a\u0438",
		"superadmin": "\u0441\u0443\u043f\u0435\u0440 \u0430\u0434\u043c\u0438\u043d\u0438\u0441\u0442\u0440\u0430\u0446\u0438\u044f",
		"payments": "\u0441\u043f\u043e\u0441\u043e\u0431 \u043e\u043f\u043b\u0430\u0442\u044b",
		"invoices": "\u0441\u0447\u0435\u0442\u0430",
		"back": "\u0432\u0435\u0440\u043d\u0443\u0442\u044c\u0441\u044f \u0432 digital folder\u00ae",
		"password_change": "\u0438\u0437\u043c\u0435\u043d\u0438\u0442\u044c \u043f\u0430\u0440\u043e\u043b\u044c",
		"user_not_found": "\u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u044c \u043d\u0435 \u043d\u0430\u0439\u0434\u0435\u043d",
		"description": "\u0437\u0434\u0435\u0441\u044c \u0432\u044b \u043c\u043e\u0436\u0435\u0442\u0435 \u0438\u0437\u043c\u0435\u043d\u0438\u0442\u044c \u043f\u0430\u0440\u043e\u043b\u044c. \u0421\u043f\u043e\u043c\u043e\u0449\u044c\u044e \u0432\u0432\u0435\u0434\u0435\u043d\u0438\u044f \u0441\u0442\u0430\u0440\u043e\u0433\u043e \u043f\u0430\u0440\u043e\u043b\u044f \u0438 \u043f\u043e\u0432\u0442\u0430\u0440\u0435\u043d\u0438\u044f \u043d\u043e\u0432\u043e\u0433\u043e \u0437\u0430\u043c\u0435\u043d\u0438\u043c \u0432\u0430\u0448 \u043f\u0430\u0440\u043e\u043b\u044c.",
		"old_password": "\u0442\u0435\u043f\u0435\u0440\u0435\u0448\u043d\u0438\u0439 \u043f\u0430\u0440\u043e\u043b\u044c",
		"new_password": "\u043d\u043e\u0432\u044b\u0439 \u043f\u0430\u0440\u043e\u043b\u044c",
		"confirm": "\u043f\u043e\u0432\u0442\u0430\u0440\u0438\u0442\u044c \u043f\u0430\u0440\u043e\u043b\u044c",
		"save_changes": "\u0437\u0430\u043f\u0438\u0441\u0430\u0442\u044c \u0438\u0437\u043c\u0435\u043d\u0435\u043d\u0438\u044f",
		"members_count": "\u043a\u043e\u043b\u043b\u0438\u0447\u0435\u0441\u0442\u0432\u043e \u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u0435\u0439",
		"invite_member": "\u043f\u0440\u0438\u0433\u043b\u0430\u0441\u0438\u0442\u044c \u043a\u043e\u043c\u0430\u043d\u0434\u0443 \u043f\u043e\u043b\u0431\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u0435\u0439",
		"invite_user": "\u043f\u0440\u0438\u0433\u043b\u0430\u0441\u0438\u0442\u044c \u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u044f",
		"invite_description": "\u0412 \u044d\u0442\u043e\u043c \u0434\u0438\u0430\u043b\u043e\u0433\u0435 \u0432 digital folder\u00ae \u043c\u043e\u0433\u0443\u0442 \u0431\u044b\u0442\u044c \u043f\u0440\u0438\u0433\u043b\u0430\u0448\u0435\u043d\u044b \u0438 \u0434\u0440\u0443\u0433\u0438\u0435 \u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u0438.\u0447\u0435\u0440\u0435\u0437 \u0443\u043a\u0430\u0437\u0430\u043d\u0438\u0435 \u044d\u043b\u0435\u043a\u0442\u0440\u043e\u043d\u043d\u043e\u0439 \u043f\u043e\u0447\u0442\u044b. \u041c\u043e\u0433\u0443\u0442 \u043f\u043e\u043f\u0430\u0441\u0442\u044c \u043f\u0440\u044f\u0438\u043e \u0432 \u043a\u043e\u043c\u0430\u043d\u0434\u0443. \u0415\u0441\u043b\u0438 \u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u044c \u0435\u0449\u0435 \u043d\u0435 \u0432 \u0441\u0438\u0441\u0442\u0435\u043c\u0435 . \u041e\u0442\u043f\u0440\u0430\u0432\u0438\u0442\u044c\u0441\u044f \u043f\u0438\u0441\u044c\u043c\u043e \u043d\u0430 \u044d\u043b\u0435\u043a\u0442\u0440\u043e\u043d\u043d\u0443\u044e \u043f\u043e\u0447\u0442\u0443, \u0447\u0442\u043e \u043e\u043d \u043f\u0440\u0438\u0433\u043b\u0430\u0448\u0435\u043d.",
		"create_team": "\u043d\u043e\u0432\u0430\u044f \u0433\u0440\u0443\u043f\u043f\u0430",
		"change_team": "\u043f\u043e\u043c\u0435\u043d\u044f\u0442\u044c \u0433\u0440\u0443\u043f\u043f\u0443",
		"change_role": "\u0438\u0437\u043c\u0435\u043d\u0438\u0442\u044c \u0440\u043e\u043b\u044c",
		"invoice_number": "\u043d\u043e\u043c\u0435\u0440 \u0441\u0447\u0435\u0442\u0430",
		"invoice_date": "\u0434\u0430\u0442\u0430 \u0441\u0447\u0435\u0442\u0430",
		"invoice_sum": "\u043a\u043e\u043b\u043b\u0438\u0447\u0435\u0441\u0442\u0432\u043e    ",
		"create_role": "\u043d\u043e\u0432\u0430\u044f \u0440\u043e\u043b\u044c",
		"create_payment_method": "\u043d\u043e\u0432\u0430\u044f \u0432\u043e\u0437\u043c\u043e\u0436\u043d\u043e\u0441\u0442\u044c \u043e\u043f\u043b\u0430\u0442\u044b",
		"permissions_for": "\u0440\u0430\u0437\u0440\u0435\u0448\u0435\u043d\u0438\u0435 \u043d\u0430",
		"credentials": "\u0438\u043d\u0444\u043e\u0440\u043c\u0430\u0446\u0438\u044f \u0441\u0447\u0435\u0442\u0430",
		"default_method": "\u0441\u0442\u0430\u043d\u0434\u0430\u0440\u0442\u043d\u044b\u0439 \u043c\u0435\u0442\u043e\u0434 \u043e\u043f\u043b\u0430\u0442\u044b",
		"change_password_successful": "\u043f\u0430\u0440\u043e\u043b\u044c \u0443\u0434\u0430\u0447\u043d\u043e \u0438\u0437\u043c\u0435\u043d\u0435\u043d",
		"change_avatar_successful": "\u0432\u0430\u0448 \u043f\u0440\u043e\u0444\u0438\u043b\u044c \u0443\u0434\u0430\u0447\u043d\u043e \u0438\u0437\u043c\u0435\u043d\u0435\u043d",
		"status_active": "\u0430\u043a\u0442\u0438\u0432\u043d\u044b\u0439",
		"status_blocked": "\u0437\u0430\u0431\u043b\u043e\u043a\u0438\u0440\u043e\u0432\u0430\u043d",
		"status_invited": "\u043f\u0440\u0438\u0433\u043b\u0430\u0448\u0435\u043d",
		"status_trialing": "\u0442\u0435\u0441\u0442\u043e\u0432\u044b\u0439 \u044d\u0442\u0430\u043f",
		"role_admin": "\u0430\u0434\u043c\u0438\u043d\u0438\u0441\u0442\u0440\u0430\u0442\u043e\u0440",
		"role_user": "\u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u044c   ",
		"block_user": "\u0437\u0430\u0431\u043b\u043e\u043a\u0438\u0440\u043e\u0432\u0430\u0442\u044c \u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u044f",
		"unblock_user": "\u0440\u0430\u0437\u0431\u043b\u043e\u043a\u0438\u0440\u043e\u0432\u0430\u0442\u044c \u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u044f",
		"block_description": "\u0445\u043e\u0442\u0438\u0442\u0435 \u0434\u0435\u0439\u0441\u0442\u0432\u0438\u0442\u0435\u043b\u044c\u043d\u043e \u0437\u0430\u0431\u043b\u043e\u043a\u0438\u0440\u043e\u0432\u0430\u0442\u044c \u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u044f?",
		"unblock_description": "\u0445\u043e\u0442\u0438\u0442\u0435 \u0434\u0435\u0439\u0441\u0442\u0432\u0438\u0442\u0435\u043b\u044c\u043d\u043e \u0440\u0430\u0437\u0431\u043b\u043e\u043a\u0438\u0440\u043e\u0432\u0430\u0442\u044c \u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u044f?",
		"select_team": "\u0432\u044b\u0431\u0435\u0440\u0438\u0442\u0435 \u0433\u0440\u0443\u043f\u043f\u0443",
		"team_exists": "\u0433\u0440\u0443\u043f\u043f\u0430 \u043f\u043e\u0434 \u044d\u0442\u0438\u043c \u043d\u0430\u0437\u0432\u0430\u043d\u0438\u0435\u043c \u0443\u0436\u0435 \u0441\u0443\u0449\u0435\u0441\u0442\u0432\u0443\u0435\u0442",
		"delete_team": "\u0441\u0442\u0435\u0440\u0435\u0442\u044c \u0433\u0440\u0443\u043f\u043f\u0443",
		"delete_team_description": "\u0434\u0435\u0439\u0441\u0442\u0432\u0438\u0442\u0435\u043b\u044c\u043d\u043e \u0445\u043e\u0442\u0438\u0442\u0435 \u0441\u0442\u0435\u0440\u0435\u0442\u044c \u0433\u0440\u0443\u043f\u043f\u0443?",
		"leave_team": "\u043e\u0441\u0442\u0430\u0432\u0438\u0442\u044c \u0433\u0440\u0443\u043f\u043f\u0443",
		"leave_team_description": "\u0434\u0435\u0439\u0441\u0442\u0432\u0438\u0442\u0435\u043b\u044c\u043d\u043e \u0445\u043e\u0442\u0438\u0442\u0435 \u043e\u0441\u0442\u0430\u0432\u0438\u0442\u044c \u0433\u0440\u0443\u043f\u043f\u0443?",
		"team_cant_delete": "\u0433\u0440\u0443\u043f\u043f\u0430 \u043d\u0435 \u043c\u043e\u0436\u0435\u0442 \u0431\u044b\u0442\u044c \u0441\u0442\u0435\u0440\u0442\u0430 \u0442.\u043a \u0432 \u043d\u0435\u0439 \u0435\u0449\u0435 \u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u0438",
		"team_has_folders": "\u0433\u0440\u0443\u043f\u043f\u0430 \u043d\u0435 \u043c\u043e\u0436\u0435\u0442 \u0431\u044b\u0442\u044c \u0441\u0442\u0435\u0440\u0442\u0430 \u0442.\u043a \u0432 \u043d\u0435\u0439 \u0446\u0438\u0444\u0440\u043e\u0432\u044b\u0435 \u043f\u0430\u043f\u043a\u0438 \u0438\u043b\u0438 \u043f\u043e\u043b\u043a\u0438 \u0432\u043a\u043b\u044e\u0447\u0435\u043d\u044b",
		"team_not_found": "\u0433\u0440\u0443\u043f\u043f\u0430 \u043d\u0435 \u043d\u0430\u0439\u0434\u0435\u043d\u0430",
		"team_removed_successful": "\u0433\u0440\u0443\u043f\u043f\u0430 \u0443\u0434\u0430\u0447\u043d\u043e \u0441\u0442\u0435\u0440\u0442\u0430",
		"team_leave_successful": "\u0433\u0440\u0443\u043f\u043f\u0430 \u0443\u0441\u043f\u0435\u0448\u043d\u043e \u043e\u0441\u0442\u0430\u0432\u043b\u0435\u043d\u0430",
		"team_members_empty": "\u0443 \u0433\u0440\u0443\u043f\u043f\u044b \u043d\u0435\u0442 \u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u0435\u0439",
		"role_exists": "\u0440\u043e\u043b\u044c \u0434\u0430\u043d\u043d\u043e\u0433\u043e \u0438\u043c\u0435\u043d\u0438 \u0443\u0436\u0435 \u0435\u0441\u0442\u044c \u0432 \u0441\u0438\u0441\u0442\u0435\u043c\u0435",
		"payments_empty": "\u0441\u043f\u043e\u0441\u043e\u0431 \u043e\u043f\u043b\u0430\u0442\u044b \u0435\u0449\u0435 \u043d\u0435\u043e\u043f\u0440\u0435\u0434\u0435\u043b\u0435\u043d",
		"default_payment_successful": "\u0441\u043f\u043e\u0441\u043e\u0431 \u043e\u043f\u043b\u0430\u0442\u044b \u043e\u043f\u0440\u0435\u0434\u0435\u043b\u0435\u043d \u0441\u0442\u0430\u043d\u0434\u0430\u0440\u0442\u043d\u044b\u043c \u043c\u0435\u0442\u043e\u0434\u043e\u043c",
		"payment_method_not_found": "\u0441\u043f\u043e\u0441\u043e\u0431 \u043e\u043f\u043b\u0430\u0442\u044b \u043d\u0435 \u043d\u0430\u0439\u0434\u0435\u043d",
		"updated_role_successful": "\u0440\u0430\u0437\u0440\u0435\u0448\u0435\u043d\u0438\u0435 \u0443\u0434\u0430\u0447\u043d\u043e \u0437\u0430\u043f\u0438\u0441\u0430\u043d\u043e",
		"role_not_found": "\u0440\u043e\u043b\u044c \u043d\u0435 \u043d\u0430\u0439\u0434\u0435\u043d\u0430",
		"delete_role": "\u0443\u0434\u0430\u043b\u0438\u0442\u044c \u0440\u043e\u043b\u044c",
		"delete_role_description": "\u0445\u043e\u0442\u0438\u0442\u0435 \u0434\u0435\u0439\u0441\u0442\u0432\u0438\u0442\u0435\u043b\u044c\u043d\u043e \u0443\u0434\u0430\u043b\u0438\u0442\u044c \u0440\u043e\u043b\u044c?",
		"role_removed_successful": "\u0440\u043e\u043b\u044c \u0443\u0434\u0430\u0447\u043d\u043e \u0443\u0434\u0430\u043b\u0435\u043d\u0430",
		"role_is_used": "\u0440\u043e\u043b\u044c \u043d\u0430\u0437\u043d\u0430\u0447\u0435\u043d\u0430 \u043c\u0438\u043d\u0438\u043c \u043e\u0434\u043d\u043e\u043c\u0443 \u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u044e, \u043f\u043e\u044d\u0442\u043e\u043c\u0443 \u0443\u0434\u0430\u043b\u0438\u0442\u044c \u043d\u0435\u0432\u043e\u0437\u043c\u043e\u0436\u043d\u043e",
		"user_has_been_invited_already": "\u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u044c \u0443\u0436\u0435 \u043f\u0440\u0438\u0433\u043b\u0430\u0448\u0435\u043d",
		"invited_successful": "\u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u044c \u0443\u0434\u0430\u0447\u043d\u043e \u043f\u0440\u0438\u0433\u043b\u0430\u0448\u0435\u043d",
		"members_of": "\u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u0438",
		"team_kick_successful": "\u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u044c \u0443\u0434\u0430\u0447\u043d\u043e \u0438\u0437 \u0433\u0440\u0443\u043f\u043f\u044b \u0443\u0434\u0430\u043b\u0435\u043d",
		"team_kick_description": "\u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u044c \u0443\u0434\u0430\u043b\u0435\u043d \u0431\u043e\u043b\u044c\u0448\u0435 \u043d\u0435 \u043c\u043e\u0436\u0435\u0442 \u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u044c\u0441\u044f \u0441\u043e\u0434\u0435\u0440\u0436\u0430\u043d\u0438\u0435\u043c \u0433\u0440\u0443\u043f\u044b . \u0422\u044b \u0442\u043e\u0447\u043d\u043e \u0445\u043e\u0447\u0435\u0448\u044c \u0443\u0434\u0430\u043b\u0438\u0442\u044c \u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u044f \u0438\u0437 \u0433\u0440\u0443\u043f\u043f\u044b?",
		"team_kick": "\u0443\u0434\u0430\u043b\u0438\u0442\u044c \u0438\u0437 \u0433\u0440\u0443\u043f\u043f\u044b",
		"organisation_saved_successful": "\u0438\u043d\u0444\u043e\u0440\u043c\u0430\u0446\u0438\u044f \u043e \u043a\u043e\u043c\u043f\u0430\u043d\u0438\u0438 \u0443\u0434\u0430\u0447\u043d\u043e \u0437\u0430\u043f\u0438\u0441\u0430\u043d\u0430",
		"invoice_status_draft": "\u0443\u0434\u0430\u043b\u0435\u043d\u0438\u0435",
		"invoice_status_open": "\u043e\u0442\u043a\u0440\u044b\u0442\u044c",
		"invoice_status_paid": "\u043e\u043f\u043b\u0430\u0447\u0435\u043d\u043e",
		"tile_folder": "\u0446\u0438\u0444\u0440\u043e\u0432\u044b\u0435 \u043f\u0430\u043f\u043a\u0438",
		"tile_objects": "\u041c\u0435\u0434\u0438\u0430-\u043e\u0431\u044c\u0435\u043a\u0442 \/\u041c\u0435\u0434\u0438\u0430-\u043e\u0431\u044c\u0435\u043a\u0442\u044b",
		"tile_users": "\u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u044c   ",
		"tile_space": "\u0434\u043e\u0441\u0442\u0443\u043f\u043d\u0430\u044f \u043f\u0430\u043c\u044f\u0442\u044c",
		"tile_user_contingent": "\u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u044c\u043d\u0430\u044f \u043a\u0432\u043e\u0442\u0430",
		"tile_user_out_of_max_users": "{users} \u043e\u0442 {max_users} \u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u0435\u0439",
		"tile_space_out_of_space": "{space} \u043e\u0442 {max_space} \u0413\u0411",
		"tile_plan": "\u0442\u0430\u0440\u0438\u0444",
		"users_exceeded": "\u043f\u0440\u0435\u0434\u0435\u043b \u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u044f \u0434\u043e\u0441\u0442\u0438\u0433\u043d\u0443\u0442",
		"cancel_subscription": "\u043e\u0442\u043c\u0435\u043d\u0438\u0442\u044c \u043f\u043e\u0434\u043f\u0438\u0441\u043a\u0443",
		"cancel_subscription_success_message": "\u0443\u0432\u0435\u0434\u043e\u043c\u043b\u0435\u043d\u0438\u0435 \u043e \u043f\u0440\u0435\u043a\u0440\u0430\u0449\u0435\u043d\u0438\u0438 \u0431\u044b\u043b\u043e \u043e\u0442\u043c\u0435\u0447\u0435\u043d\u043e. \u0422\u044b \u043c\u043e\u0436\u0435\u0448\u044c \u0434\u043e \u043e\u043a\u043e\u043d\u0447\u0430\u043d\u0438\u044f digital folder\u00ae \u0435\u0449\u0435 \u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u044c\u0441\u044f",
		"back_to_digital_folder": "\u043e\u0431\u0440\u0430\u0442\u043d\u043e \u043d\u0430 \u043f\u043e\u043b\u043a\u0443\/ \u043e\u0431\u0437\u043e\u0440 \u0421\u041c\u0418",
		"help": "\u043f\u043e\u043c\u043e\u0449\u044c",
		"language_chooser": "\u0432\u044b\u0431\u043e\u0440 \u044f\u0437\u044b\u043a\u0430",
		"remove_user": "\u0434\u0430\u043b\u0438\u0442\u044c \u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u044f",
		"remove_user_message": "\u0445\u043e\u0447\u0435\u0448\u044c \u0434\u0435\u0439\u0441\u0442\u0432\u0438\u0442\u0435\u043b\u044c\u043d\u043e \u0443\u0434\u0430\u043b\u0438\u0442\u044c \u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u044f?",
		"user_can_not_deleted": "\u041f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u044c \u043d\u0435 \u043c\u043e\u0436\u0435\u0442 \u0431\u044b\u0442\u044c \u0443\u0434\u0430\u043b\u0435\u043d,\u043f\u043e\u0442\u043e\u043c\u0443 \u0447\u0442\u043e \u0434\u043e\u043b\u0436\u0435\u043d \u0431\u044b\u0442\u044c \u0445\u043e\u0442\u044f\u0431\u044b \u043e\u0434\u0438\u043d \u0430\u0434\u043c\u0438\u043d\u0438\u0441\u0442\u0440\u0430\u0442\u043e\u0440.",
		"user_is_app_owner": "\u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u044c \u043d\u0435 \u043c\u043e\u0436\u0435\u0442 \u0431\u044b\u0442\u044c \u0443\u0434\u0430\u043b\u0435\u043d , \u0442\u0430\u043a \u043a\u0430\u043a \u043e\u043d \u0432\u043b\u0430\u0434\u0435\u043b\u0435\u0446.",
		"change_default_payment_method": "\u044d\u0442\u0443 \u0432\u043e\u0437\u043c\u043e\u0436\u043d\u043e\u0441\u0442\u044c \u043e\u043f\u043b\u0430\u0442\u044b \u0437\u0434\u0435\u043b\u0430\u0442\u044c \u0441\u0442\u0430\u043d\u0434\u0430\u0440\u0442\u043d\u043e\u0439",
		"send_invitation": "\u0435\u0449\u0435 \u0440\u0430\u0437 \u043f\u0440\u0438\u0441\u043b\u0430\u0442\u044c \u043f\u0440\u0438\u0433\u043b\u0430\u0448\u0435\u043d\u0438\u0435",
		"send_invitation_successful": "\u043f\u0440\u0438\u0433\u043b\u0430\u0448\u0435\u043d\u0438\u0435 \u043f\u043e\u0441\u043b\u0430\u043d\u043d\u043e \u043f\u043e\u0432\u0442\u043e\u0440\u043d\u043e",
		"user_can_not_blocked": "\u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u044f \u043d\u0435\u043b\u044c\u0437\u044f \u0437\u0430\u0431\u043b\u043e\u043a\u0438\u0440\u043e\u0432\u0430\u0442\u044c , \u043f\u043e\u0442\u043e\u043c\u0443 \u0447\u0442\u043e \u0434\u043e\u043b\u0436\u0435\u043d \u0431\u044b\u0442\u044c \u043c\u0438\u043d\u0438\u043c\u0443\u043c 1 \u0430\u0434\u043c\u0438\u043d\u0438\u0441\u0442\u0440\u0430\u0442\u043e\u0440.",
		"cancel_subscription_message": "do your really want to cancel your plan?",
		"plan_info": "chose one of the plan options to upgrade your digital folder\u00ae plan or to get more users\/cloud space. ",
		"subscription_runtime": "your plan starts {start_date} and ends {end_date} incl. {discount} month (free) (payment cycle: {payment_interval})",
		"subscription_runtime_coupon": "your plan starts {start_date} and ends {end_date} incl. {discount} month (free + paid) (payment cycle: {payment_interval})",
		"subscription_canceled_at": "the plan was canceled on:",
		"status_needs_confirmation": "Invited",
		"notifications": "Notifications",
		"invite_message": "You've been invited by {company} in one or more teams ({teams}). Do you like to join them?",
		"no_notifications": "No notifications found",
		"invoice_info": "",
		"invite_support_description": "Invite the email address support@digital-folder.com to get support-access by one of our employees",
		"invite_account_status_based": "Hinweis ausstehend!",
	}, "tooltips": {
		"remove_member": "\u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u044c \u0438\u0437 \u0433\u0440\u0443\u043f\u043f\u044b \u0443\u0434\u0430\u043b\u0435\u043d",
		"leave_team": "\u0443\u0445\u043e\u0434\u043e\u043c \u0438\u0437 \u0433\u0440\u0443\u043f\u043f\u044b \u043d\u0435\u0438\u043c\u0435\u0435\u0448\u044c \u0431\u043e\u043b\u044c\u0448\u0435 \u0432\u043e\u0437\u043c\u043e\u0436\u043d\u043e\u0441\u0442\u0438 \u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u044c\u0441\u044f digital folder\u00ae \u0438 \u0421\u041c\u0418",
		"change_role": "\u0437\u0434\u0435\u0441\u044c \u0432\u044b \u043c\u043e\u0436\u0435\u0442\u0435 \u043e\u043f\u0440\u0435\u0434\u0435\u043b\u0438\u0442\u044c \u0440\u043e\u043b\u044c \u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u044f",
		"edit_user": "\u0440\u0435\u0434\u0430\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u0442\u044c \u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u044f",
		"pay_now": "\u043e\u043f\u043b\u0430\u0442\u0438\u0442\u044c \u0441\u0435\u0439\u0447\u0430\u0441",
		"download": "\u0441\u043a\u0430\u0447\u0430\u0442\u044c \u044d\u0442\u043e\u0442 \u0441\u0447\u0435\u0442 \u0441\u0435\u0439\u0447\u0430\u0441",
		"block_user": "\u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u044f \u0437\u0430\u0431\u043b\u043e\u043a\u0438\u0440\u043e\u0432\u0430\u0442\u044c \u0434\u043b\u044f \u0442\u043e\u0433\u043e \u0447\u0442\u043e\u0431\u044b \u0434\u043e\u0441\u0442\u0443\u043f \u043a \u0441\u0438\u0441\u0442\u0435\u043c\u0435 \u0431\u044b\u043b \u043f\u0435\u0440\u0435\u0440\u0430\u0431\u043e\u0442\u0430\u043d",
		"unblock_user": "\u043f\u043e\u0437\u0432\u043e\u043b\u044f\u0435\u0442 \u044d\u0442\u043e\u043c\u0443 \u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u044e \u043f\u043e\u043b\u0443\u0447\u0438\u0442\u044c \u0434\u043e\u0441\u0442\u0443\u043f \u043a \u0441\u0438\u0441\u0442\u0435\u043c\u0435",
		"permissions": "\u0437\u0430\u043a\u0430\u043d\u0447\u0438\u0432\u0430\u0435\u0442 \u043f\u0440\u0430\u0432\u043e \u043d\u0430 \u044d\u0442\u0443 \u0440\u043e\u043b\u044c",
		"delete_role": "\u0443\u0434\u0430\u043b\u044f\u0435\u0442 \u0440\u043e\u043b\u044c"
	}, "designer": {
		"page": "page",
		"chapter": "chapter",
		"designer": "\u0434\u0435\u0437\u0430\u0439\u043d\u0435\u0440 \u043f\u0430\u043f\u043e\u043a",
		"comment_placeholder": "\u0432\u0432\u0435\u0434\u0438\u0442\u0435 \u0437\u0434\u0435\u0441\u044c \u043f\u0440\u0438\u043c\u0435\u0447\u0430\u043d\u0438\u0435 \u043a \u043f\u0430\u043f\u043a\u0430\u043c",
		"expand_all": "\u043e\u0442\u043a\u0440\u044b\u0442\u044c \u0432\u0441\u0435",
		"collapse_all": "\u0437\u0430\u043a\u0440\u044b\u0442\u044c \u0432\u0441\u0435",
		"new_chapter": "\u043d\u043e\u0432\u0430\u044f \u0433\u043b\u0430\u0432\u0430",
		"new_page": "\u0441\u043e\u0437\u0434\u0430\u0442\u044c \u043d\u043e\u0432\u0443\u044e \u0441\u0442\u0440\u0430\u043d\u0438\u0446\u0443",
		"page_title": "\u0432\u043d\u0435\u0441\u0442\u0438 \u043d\u0430\u0437\u0432\u0430\u043d\u0438\u0435 \u0441\u0442\u0440\u0430\u043d\u0438\u0446\u044b",
		"chapter_title": "\u0432\u043d\u0435\u0441\u0442\u0438 \u043d\u0430\u0437\u0432\u0430\u043d\u0438\u0435 \u0433\u043b\u0430\u0432\u044b",
		"remove_page": "\u0443\u0434\u0430\u043b\u0438\u0442\u044c \u0441\u0442\u0440\u0430\u043d\u0438\u0446\u0443",
		"remove_chapter": "\u0441\u0442\u0435\u0440\u0435\u0442\u044c \u0433\u043b\u0430\u0432\u0443",
		"background_image": "\u0444\u043e\u043d\u043e\u0432\u0430\u044f \u043a\u0430\u0440\u0442\u0438\u043d\u043a\u0430",
		"search_mediacenter": "\u043f\u043e\u0438\u0441\u043a \u043c\u0435\u0434\u0438\u0430\u0446\u0435\u043d\u0442\u0440\u0430",
		"drop_file": "\u0441\u043e\u0445\u0440\u0430\u043d\u0438\u0442\u044c \u0444\u0430\u0438\u043b \u0438\u043b\u0438",
		"media_center_search": "\u043e\u0431\u0437\u043e\u0440 \u0432\u044b\u0431\u043e\u0440\u0430",
		"selected_files": "\u0444\u0430\u0438\u043b \u0432\u044b\u0431\u0440\u0430\u043d",
		"append_files": "\u0440\u0430\u0437\u043c\u0435\u0441\u0442\u0438\u0442\u044c",
		"folder_not_found": "\u0446\u0438\u0444\u0440\u043e\u0432\u0430\u044f \u043f\u0430\u043f\u043a\u0430 \u043d\u0435 \u043d\u0430\u0439\u0434\u0435\u043d\u0430",
		"all_media_containers": "\u043c\u0435\u0434\u0438\u0430 \u043a\u043e\u043d\u0442\u0435\u0439\u043d\u0435\u0440",
		"cover": "\u043e\u0431\u043b\u043e\u0436\u043a\u0430 \u043f\u0430\u043f\u043a\u0438",
		"unnamed_chapter": "\u043d\u0435\u043d\u0430\u0437\u0432\u0430\u043d\u043d\u0430\u044f \u0433\u043b\u0430\u0432\u0430",
		"unnamed_page": "\u043d\u0435\u043d\u0430\u0437\u0432\u0430\u043d\u043d\u0430\u044f \u0441\u0442\u0440\u0430\u043d\u0438\u0446\u0430",
		"comment_save_message": "\u0437\u0430\u043f\u0438\u0441\u0438 \u0443\u0434\u0430\u0447\u043d\u043e \u0437\u0430\u043f\u0438\u0441\u0430\u043d\u044b",
		"comments": "\u0437\u0430\u043c\u0435\u0442\u043a\u0438 \u043f\u0430\u043f\u043a\u0438",
		"clock": "\u0432\u0440\u0435\u043c\u044f",
		"comment_not_found": "\u0437\u0430\u043f\u0438\u0441\u0438 \u043d\u0435 \u043c\u043e\u0433\u0443\u0442 \u0431\u044b\u0442\u044c \u043d\u0430\u0439\u0434\u0435\u043d\u044b",
		"done": "\u0437\u0434\u0435\u043b\u0430\u043d\u043d\u044b\u0439",
		"mark_success_message": "\u0437\u0430\u043f\u0438\u0441\u0438 \u043e\u0431\u0430\u0437\u043d\u0430\u0447\u0435\u043d\u044b \u0432\u044b\u043f\u043e\u043b\u043d\u0435\u043d\u044b",
		"done_by": "\u0441\u0434\u0435\u043b\u0430\u043d\u043e {user} \u0432\u043e {date}",
		"save_folder_success": "digital folder\u00ae \u0443\u0434\u0430\u0447\u043d\u043e \u0437\u0430\u043f\u0438\u0441\u0430\u043d\u044b",
		"unsaved_content": "\u0445\u043e\u0442\u0438\u0442\u0435 \u0432\u044b\u0439\u0442\u0438 \u0438\u0437 \u0434\u0438\u0437\u0430\u0438\u043d\u0435\u0440 \u043f\u0430\u043f\u043e\u043a \u0431\u0435\u0437 \u0441\u043e\u0445\u0440\u0430\u043d\u0435\u043d\u0438\u044f \u0432\u0430\u0448\u0438\u0445 \u0438\u0437\u043c\u0435\u043d\u0435\u043d\u0438\u0439",
		"discard_changes": "\u0423\u0434\u0430\u043b\u0438\u0442\u044c \u0438\u0437\u043c\u0435\u043d\u0435\u043d\u0438\u044f",
		"save_changes": "\u0437\u0430\u043f\u0438\u0441\u0430\u0442\u044c \u0438\u0437\u043c\u0435\u043d\u0435\u043d\u0438\u044f",
		"folder_is_locked": "\u0446\u0438\u0444\u0440\u043e\u0432\u044b\u0435 \u043f\u0430\u043f\u043a\u0438 \u0437\u0430\u0431\u043b\u043e\u043a\u0438\u0440\u043e\u0432\u0430\u043d\u044b , \u0438\u0437\u043c\u0435\u043d\u0435\u043d\u0438\u044f \u043d\u0435 \u043c\u043e\u0433\u0443\u0442 \u0437\u0430\u043f\u0438\u0441\u0430\u0442\u044c\u0441\u044f",
		"preview": "\u043e\u0431\u0437\u043e\u0440",
		"drag_chapter": "\u0438\u0441\u043f\u043e\u043b\u044c\u0437\u0443\u0439\u0442\u0435 \u0444\u0443\u043d\u043a\u0446\u0438\u044e \u043f\u0435\u0440\u0435\u0442\u0430\u0441\u043a\u0438\u0432\u0430\u043d\u0438\u044f , \u0447\u0442\u043e\u0431\u044b \u043f\u0435\u0440\u0435\u043c\u0435\u0441\u0442\u0438\u0442\u044c \u044d\u0442\u0438 \u0433\u043b\u0430\u0432\u044b.",
		"drag_page": "\u043f\u0435\u0440\u0435\u0442\u0430\u0449\u0438\u0442\u0435 \u044d\u0442\u043e\u0442 \u044d\u043b\u0435\u043c\u0435\u043d\u0442 \u043d\u0430 \u0433\u043b\u0430\u0432\u0443 \u0438\u043b\u0438 \u0441\u0442\u0440\u0430\u043d\u0438\u0446\u0443, \u0447\u0442\u043e\u0431\u044b \u043f\u0435\u0440\u0435\u043c\u0435\u0441\u0442\u0438\u0442\u044c \u0435\u0433\u043e.",
		"back": "\u0432\u0435\u0440\u043d\u0443\u0442\u044c\u0441\u044f \u043a \u043e\u0431\u0437\u043e\u0440\u0443 \u043f\u0430\u043f\u043e\u043a",
		"more_file_info": "\u043f\u043e\u043a\u0430\u0437\u0430\u0442\u044c \u0431\u043e\u043b\u044c\u0448\u0435 \u0438\u043d\u0444\u043e\u0440\u043c\u0430\u0446\u0438\u0438 \u043e \u0444\u0430\u0439\u043b\u0430\u0445",
		"less_file_info": "\u043f\u043e\u043a\u0430\u0437\u0430\u0442\u044c \u043c\u0435\u043d\u044c\u0448\u0435 \u0438\u043d\u0444\u043e\u0440\u043c\u0430\u0446\u0438\u0438 \u043e \u0444\u0430\u0439\u043b\u0430\u0445",
		"cover_tooltip": "\u043f\u0435\u0440\u0435\u043a\u043b\u044e\u0447\u0430\u0442\u044c\u0441\u044f \u043c\u0435\u0436\u0434\u0443 \u0438\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u0435\u043c \u043e\u0431\u043b\u043e\u0436\u043a\u0438 \u0438 \u0446\u0432\u0435\u0442\u043e\u043c \u043e\u0431\u043b\u043e\u0436\u043a\u0438.",
		"chapter_active": "\u0430\u043a\u0442\u0438\u0432\u043d\u0430\u044f \u0433\u043b\u0430\u0432\u0430",
		"chapter_inactive": "\u043d\u0435\u0430\u043a\u0442\u0438\u0432\u043d\u0430\u044f \u0433\u043b\u0430\u0432\u0430",
		"comments_not_allowed": "\u0432 \u0431\u0435\u0441\u043f\u043b\u0432\u0442\u043d\u043e\u043c \u0438 \u0431\u0430\u0437\u043e\u0432\u043e\u043c \u0442\u0430\u0440\u0438\u0444\u0435 , \u043a\u043e\u043c\u0435\u043d\u0442\u0430\u0440\u0438\u0438 \u043d\u0435 \u0440\u0430\u0437\u0440\u0435\u0448\u0435\u043d\u044b",
		"change_page_layout_1": "\u0418\u0437\u043c\u0435\u043d\u0438\u0442\u044c \u043c\u0430\u043a\u0435\u0442 \u0441\u0442\u0440\u0430\u043d\u0438\u0446\u044b 1-\u044d\u043b\u0435\u043c\u0435\u043d\u0442",
		"change_page_layout_2": "\u0438\u0437\u043c\u0435\u043d\u0438\u0442\u044c \u043c\u0430\u043a\u0435\u0442 \u0441\u0442\u0440\u0430\u043d\u0438\u0446\u044b 2-\u044d\u043b\u0435\u043c\u0435\u043d\u0442",
		"change_page_layout_4": "\u0438\u0437\u043c\u0435\u043d\u0438\u0442\u044c \u043c\u0430\u043a\u0435\u0442 \u0441\u0442\u0440\u0430\u043d\u0438\u0446\u044b 4-\u044d\u043b\u0435\u043c\u0435\u043d\u0442",
		"change_page_layout_6": "\u0438\u0437\u043c\u0435\u043d\u0438\u0442\u044c \u043c\u0430\u043a\u0435\u0442 \u0441\u0442\u0440\u0430\u043d\u0438\u0446\u044b 6-\u044d\u043b\u0435\u043c\u0435\u043d\u0442",
		"change_object": "\u043d\u0430\u0436\u043c\u0438\u0442\u0435 \u043d\u0430 \u0438\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u0435, \u0447\u0442\u043e\u0431\u044b \u043e\u0431\u043c\u0435\u043d\u044f\u0442\u044c \u043c\u0435\u0434\u0438\u0430-\u0444\u0430\u0439\u043b",
		"image_mode": "\u0444\u043e\u0440\u043c\u0430\u0442 \u043e\u0442\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u044f",
		"image_mode_cover": "\u0437\u0430\u043f\u043e\u043b\u043d\u0438\u0442\u044c",
		"image_mode_contains": "\u043a\u0440\u0430\u0442\u043a\u043e",
		"page_grid_too_much_items": "\u0443 \u0432\u0430\u0441 \u043e\u0447\u0435\u043d\u044c \u043c\u043d\u043e\u0433\u043e \u043c\u0435\u0434\u0438\u0430 \u043e\u0431\u044c\u0435\u043a\u0442\u043e\u0432 \u043d\u0430 \u0441\u0442\u0440\u0430\u043d\u0438\u0446\u0435, \u0447\u0442\u043e\u0431\u044b \u0438\u0437\u043c\u0435\u043d\u0438\u0442\u044c \u043c\u0430\u043a\u0435\u0442 \u0441\u0442\u0440\u0430\u043d\u0438\u0446\u044b. \u041f\u043e\u0436\u0430\u043b\u0443\u0439\u0441\u0442\u0430 \u0443\u0434\u0430\u043b\u0438\u0442\u0435 \u043d\u0435\u043d\u0443\u0436\u043d\u044b\u0435 \u043e\u0431\u044c\u0435\u043a\u0442\u044b.",
		"note_read_title": "show comment",
		"note_write_title": "add comment",
		"note": "comment",
		"note_tooltip": "comment available",
		"note_placeholder": "Place your note here",
		"qr_code": "QR-Code",
		"qr_code_download": "Download QR-Code",
		"media_tooltip_word": "Toggles all media objects of type 'MS Word'",
		"media_tooltip_excel": "Toggles all media objects of type 'MS Excel'",
		"media_tooltip_powerpoint": "Toggles all media objects of type 'MS Powerpoint'",
		"media_tooltip_image": "Toggles all media objects of type 'Image'",
		"media_tooltip_file": "Toggles all media objects of type 'PDF'",
		"media_tooltip_link": "Toggles all media objects of type 'Link'",
		"media_tooltip_others": "Toggles all media objects of type 'Others'",
		"delete_chapter_message": "Are you sure to delete all chapters including all pages?",
		"delete_page_message": "Are you sure to delete this page including all contents?",
		"change_folder_background": "Toggles between colored or image background",
		"select_background_color": "Select background color",
		"select_background_image": "Select from media center",
		"reset_background": "Reset background color and -image",
		"show_as_new": "Show as new",
		"hide_as_new": "Hide as new",
	}, "workspaces": {
		"racks": "\u043f\u043e\u043b\u043a\u0438",
		"folderNRacks": "\u043f\u0430\u043f\u043a\u0438\/\u043f\u043e\u043b\u043a\u0438",
		"search": "\u043e\u0431\u044b\u0441\u043a\u0430\u0442\u044c \u0432\u0441\u0435",
		"recent_used": "\u0431\u044b\u043b \u0438\u0441\u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u043d \u043f\u043e\u0441\u043b\u0435\u0434\u043d\u0438\u0439 \u0440\u0430\u0437",
		"create_rack": "\u0441\u043e\u0437\u0434\u0430\u0442\u044c \u043d\u043e\u0432\u0443\u044e \u043f\u043e\u043b\u043a\u0443",
		"create_folder": "\u043d\u043e\u0432\u0430\u044f \u043f\u0430\u043f\u043a\u0430",
		"copy": "\u043a\u043e\u043f\u0438\u0440\u043e\u0432\u0430\u0442\u044c",
		"export": "\u044d\u043a\u0441\u043f\u043e\u0440\u0442\u0438\u0440\u043e\u0432\u0430\u0442\u044c",
		"folder": "\u043f\u0430\u043f\u043a\u0438",
		"edit": "\u0440\u0435\u0434\u0430\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u0442\u044c   ",
		"release": "\u0434\u0435\u0431\u043b\u043e\u043a\u0438\u0440\u043e\u0432\u0430\u0442\u044c",
		"download": "\u0441\u043a\u0430\u0447\u0430\u0442\u044c   ",
		"show": "\u043f\u043e\u043a\u0430\u0437\u0430\u0442\u044c   ",
		"visible": "\u0432\u0438\u0434\u0438\u043c\u044b\u0439",
		"copy_link": "\u043a\u043e\u043f\u0438\u0440\u043e\u0432\u0430\u0442\u044c \u0441\u0441\u044b\u043b\u043a\u0443",
		"hide": "\u0441\u043a\u0440\u044b\u0442\u044c   ",
		"move": "\u0441\u043c\u0435\u0441\u0442\u0438\u0442\u044c",
		"activate": "\u0430\u043a\u0442\u0438\u0432\u0438\u0440\u043e\u0432\u0430\u0442\u044c",
		"deactivate": "\u0434\u0435\u0430\u043a\u0442\u0438\u0432\u0438\u0440\u043e\u0432\u0430\u0442\u044c",
		"delete": "\u0443\u0434\u0430\u043b\u0438\u0442\u044c   ",
		"tags": "\u043a\u043b\u044e\u0447\u0435\u0432\u043e\u0435 \u0441\u043b\u043e\u0432\u043e",
		"edit_shelf": "\u0440\u0435\u0434\u0430\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u0442\u044c \u043f\u043e\u043b\u043a\u0443",
		"create_shelf": "\u043d\u043e\u0432\u0430\u044f \u043f\u043e\u043b\u043a\u0430",
		"tag_placeholder": "\u0432\u043d\u0435\u0441\u0442\u0438 \u043a\u043b\u044e\u0447\u0435\u0432\u044b\u0435 \u0441\u043b\u043e\u0432\u0430",
		"shelf_name_placeholder": "\u0432\u043d\u0435\u0441\u0442\u0438 \u043d\u0430\u0437\u0432\u0430\u043d\u0438\u0435 \u043f\u0430\u043f\u043a\u0438",
		"save_shelf": "\u0441\u043e\u0445\u0440\u0430\u043d\u0438\u0442\u044c \u043f\u043e\u043b\u043a\u0443",
		"folder_name": "\u043d\u0430\u0437\u0432\u0430\u043d\u0438\u0435 \u043f\u043e\u043b\u043a\u0438",
		"folder_name_placeholder": "\u0432\u043d\u0435\u0441\u0438\u0442\u0435 \u043d\u0430\u0437\u0432\u0430\u043d\u0438\u0435 \u043f\u043e\u043b\u043a\u0438",
		"shelf_name": "\u043d\u0430\u0437\u0432\u0430\u043d\u0438\u0435 \u043f\u043e\u043b\u043a\u0438",
		"save_folder": "\u0441\u043e\u0445\u0440\u0430\u043d\u0438\u0442\u044c \u043f\u0430\u043f\u043a\u0443",
		"shelf_color": "\u0446\u0432\u0435\u0442 \u043f\u043e\u043b\u043a\u0438",
		"own_hex_value": "\u0428\u0435\u0441\u0442\u043d\u0430\u0434\u0446\u0430\u0442\u0435\u0440\u0438\u0447\u043d\u043e\u0435 \u0437\u043d\u0430\u0447\u0435\u043d\u0438\u0435 \u0446\u0432\u0435\u0442\u0430",
		"cover_color": "\u0446\u0432\u0435\u0442 \u043e\u0431\u043b\u043e\u0436\u043a\u0438",
		"cover_image": "\u0444\u043e\u0442\u043e \u043d\u0430 \u043e\u0431\u043b\u043e\u0436\u043a\u0435",
		"designer": "\u0434\u0435\u0437\u0430\u0439\u043d",
		"rack_with_name_exists": "\u043f\u043e\u043b\u043a\u0430 \u0441\u0442\u0430\u043a\u0438\u043c \u043d\u0430\u0437\u0432\u0430\u043d\u0438\u0435\u043c \u0443\u0436\u0435 \u0435\u0441\u0442\u044c",
		"save_rack_success_message": "\u043f\u0430\u043f\u043a\u0430 \u0443\u0434\u0430\u0447\u043d\u043e \u0441\u043e\u0445\u0440\u0430\u043d\u0435\u043d\u0430",
		"remove_rack_confirm_title": "\u0443\u0434\u0430\u043b\u0438\u0442\u044c \u043f\u043e\u043b\u043a\u0443",
		"remove_rack_confirm_message": "\u0434\u0435\u0439\u0441\u0442\u0432\u0438\u0442\u0435\u043b\u044c\u043d\u043e \u043b\u0438 \u0445\u043e\u0442\u0438\u0442\u0435 \u0443\u0434\u0430\u043b\u0438\u0442\u044c \u043f\u043e\u043b\u043a\u0443?",
		"remove_rack_success_message": "\u043f\u043e\u043b\u043a\u0430 \u0443\u0434\u0430\u0447\u043d\u043e \u0443\u0434\u0430\u043b\u0435\u043d\u0430",
		"rack_not_found": "\u0432\u0430\u0448\u0430 \u043f\u043e\u043b\u043a\u0430 \u043d\u0435 \u043d\u0430\u0439\u0434\u0435\u043d\u0430",
		"rack_has_folders": "\u0432\u0430\u0448\u0430 \u043f\u043e\u043b\u043a\u0430 \u043d\u0435 \u043c\u043e\u0436\u0435\u0442 \u0431\u044b\u0442\u044c \u0443\u0434\u0430\u043b\u0435\u043d\u0430, \u0442.\u043a \u0432 \u043d\u0435\u0439 \u0435\u0449\u0435 \u0435\u0441\u0442\u044c \u0446\u0438\u0444\u0440\u043e\u0432\u044b\u0435 \u043f\u0430\u043f\u043a\u0438",
		"sort_latest": "\u0441\u0430\u043c\u044b\u0439 \u043d\u043e\u0432\u044b\u0439",
		"sort_last_used": "\u043f\u043e\u0441\u043b\u0435\u0434\u043d\u0438\u0439 \u0440\u0430\u0437 \u0431\u044b\u043b \u0438\u0441\u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u043d",
		"sort_alphabetically": "\u0410 -\u042f",
		"sort_anti_alphabetically": "\u0410 -\u042f",
		"sort_most_comments": "\u0431\u043e\u043b\u044c\u0448\u0435\u043d\u0441\u0442\u0432\u043e \u0437\u0430\u043c\u0435\u0442\u043e\u043a",
		"sort_shared_containers": "shared media containers",
		"status_in_progress": "\u0432 \u0440\u0430\u0431\u043e\u0442\u0435 \u0441",
		"status_finished": "\u0437\u0430\u0432\u0435\u0440\u0448\u0435\u043d\u043e \u043d\u0430",
		"status_released": "\u0432\u044b\u043f\u0443\u0449\u0435\u043d",
		"status_locked": "\u0437\u0430\u0431\u043b\u043e\u043a\u0438\u0440\u043e\u0432\u0430\u043d \u0441",
		"status_archived": "\u0430\u043a\u0442\u0438\u0432\u043d\u044b\u0439 \u0441",
		"folder_not_found": "\u0446\u0438\u0444\u0440\u043e\u0432\u044b\u0435 \u043f\u0430\u043f\u043a\u0438 \u043d\u0435 \u043d\u0430\u0439\u0434\u0435\u043d\u044b",
		"folder_with_name_exists": "\u0446\u0438\u0444\u0440\u043e\u0432\u044b\u0435 \u043f\u0430\u043f\u043a\u0438 \u0441 \u0442\u0430\u043a\u0438\u043c \u0438\u043c\u0435\u043d\u0435\u043c \u0443\u0436\u0435 \u0435\u0441\u0442\u044c",
		"change_status": "\u0438\u0437\u043c\u0435\u043d\u0438\u0442\u044c \u0441\u0442\u0430\u0442\u0443\u0441",
		"context_menu_status_in_progress": "\u0432 \u0440\u0430\u0431\u043e\u0442\u0435  ",
		"context_menu_status_finish": "\u0437\u0430\u0432\u0435\u0440\u0448\u0438\u0442\u044c",
		"context_menu_status_release": "\u0434\u0435\u0431\u043b\u043e\u043a\u0438\u0440\u043e\u0432\u0430\u0442\u044c",
		"context_menu_status_lock": "\u0437\u0430\u0431\u043b\u043e\u043a\u0438\u0440\u043e\u0432\u0430\u0442\u044c    ",
		"context_menu_status_archive": "\u043e\u0442\u043f\u0440\u0430\u0432\u0438\u0442\u044c \u0432 \u0430\u0440\u0445\u0438\u0432",
		"status_changed_successful_message": "\u0421\u0442\u0430\u0443\u0441 digital folder\u00ae \u0443\u0434\u0430\u0447\u043d\u043e \u0438\u0437\u043c\u0435\u043d\u0435\u043d",
		"release_folder": "\u043e\u0441\u0432\u043e\u0431\u0430\u0434\u0438\u0442\u044c \u043f\u0430\u043f\u043a\u0443",
		"release_folder_description": "\u0447\u0442\u043e\u0431\u044b \u043f\u043e\u0434\u0435\u043b\u0438\u0442\u044c\u0441\u044f \u0441\u0432\u043e\u0435\u0439 \u043f\u0430\u043f\u043a\u043e\u0439 \u0441 \u0434\u0440\u0443\u0433\u0438\u043c\u0438, \u043f\u043e\u0436\u0430\u043b\u0443\u0439\u0441\u0442\u0430 \u0430\u043a\u0442\u0438\u0432\u0438\u0440\u0443\u0439\u0442\u0435. ( \u0431\u0440 ) (\u0431\u0440)",
		"activate_password": "\u043f\u043e \u0436\u0435\u043b\u0430\u043d\u0438\u044e \u0443 \u0432\u0430\u0441 \u0435\u0441\u0442\u044c \u0432\u043e\u0437\u043c\u043e\u0436\u043d\u043e\u0441\u0442\u044c \u0430\u043a\u0442\u0438\u0432\u0438\u0440\u043e\u0432\u0442\u044c \u0437\u0449\u0438\u0442\u0443 \u043f\u0430\u0440\u043e\u043b\u044f \u0434\u043b\u044f \u0432\u0430\u0448\u0435\u0439 \u043f\u0430\u043f\u043a\u0438.\u0415\u0441\u043b\u0438 \u0430\u043a\u0442\u0438\u0432\u0438\u0440\u043e\u0432\u0430\u043d\u0430 \u0437\u0430\u0449\u0438\u0442\u0430 \u043f\u0430\u0440\u043e\u043b\u044f , \u043f\u0430\u043f\u043a\u0430 \u043c\u043e\u0436\u0435\u0442 \u043e\u0442\u043e\u0431\u0440\u0430\u0436\u0430\u0442\u044c\u0441\u044f \u0442\u043e\u043b\u044c\u043a\u043e \u0441 \u043d\u0430\u0437\u043d\u0430\u0447\u0435\u043d\u044b\u043c \u043f\u0430\u0440\u043e\u043b\u0435\u043c.",
		"send_credentials": "\u043e\u0442\u043f\u0440\u0430\u0432\u0438\u0442\u044c \u0433\u0438\u043f\u0435\u0440\u0441\u0441\u044b\u043b\u043a\u0443 \u0438 \u043f\u043e\u043b\u0443\u0447\u0438\u0442\u044c \u0434\u043e\u0441\u0442\u0443\u043f \u043a \u0434\u0430\u043d\u043d\u044b\u043c \u043f\u043e \u044d\u043b\u0435\u043a\u0442\u0440\u043e\u043d\u043d\u043e\u0439 \u043f\u043e\u0447\u0442\u0435.",
		"activate_release": "\u0430\u043a\u0442\u0438\u0432\u0438\u0440\u043e\u0432\u0430\u0442\u044c \u0431\u043c\u0435\u043d",
		"email_subject": "\u0410\u043a\u0442\u0438\u0432\u0438\u0440\u043e\u0432\u0430\u0442\u044c digital folder\u00ae",
		"password_placeholder": "\u0432\u0432\u0435\u0434\u0438\u0442\u0435 \u043f\u0430\u0440\u043e\u043b\u044c",
		"email_message": "\u0441\u0441\u044b\u043b\u043a\u0430 \u0432 \u043f\u0430\u043f\u043a\u0443 : {link}",
		"email_password": "\u041f\u0430\u0440\u043e\u043b\u044c : {password}",
		"copied": "\u0441\u044b\u043b\u043a\u0430 \u043a\u043e\u043f\u0438\u0440\u043e\u0432\u0430\u043d\u0430!",
		"save_password": "\u0438\u0437\u043c\u0435\u043d\u0438\u0442\u044c \u043f\u0430\u0440\u043e\u043b\u044c",
		"remove_folder_confirm_title": "\u0423\u0434\u0430\u043b\u0438\u0442\u044c digital folder\u00ae ?",
		"remove_folder_confirm_message": "\u0445\u043e\u0442\u0438\u0442\u0435 \u0434\u0435\u0439\u0441\u0442\u0432\u0438\u0442\u0435\u043b\u044c\u043d\u043e \u0443\u0434\u0430\u043b\u0438\u0442\u044c digital folder\u00ae ?",
		"remove_folder_success_message": ",,\u0446\u0438\u0444\u0440\u043e\u0432\u044b\u0435 \u043f\u0430\u043f\u043a\u0438,, \u0443\u0434\u0430\u0447\u043d\u043e \u0443\u0434\u0430\u043b\u0435\u043d\u044b",
		"copy_folder_success_message": ",,\u0446\u0438\u0444\u0440\u043e\u0432\u044b\u0435 \u043f\u0430\u043f\u043a\u0438 \u0443\u0434\u0430\u0447\u043d\u043e \u043a\u043e\u043f\u0438\u0440\u043e\u0432\u0430\u043d\u044b",
		"move_folder_success_message": "\u043e\u0434\u043d\u0430 \u0438\u043b\u0438 \u043d\u0435\u0441\u043a\u043e\u043b\u044c\u043a\u043e \u043f\u0430\u043f\u043e\u043a  \u0443\u0434\u0430\u0447\u043d\u043e \u043f\u0435\u0440\u0435\u043c\u0435\u0449\u0435\u043d\u044b",
		"search_result": "\u0440\u0435\u0437\u0443\u043b\u044c\u0442\u0430\u0442\u044b \u043f\u043e\u0438\u0441\u043a\u0430",
		"search_no_result": "\u044f\u0440\u043b\u044b\u043a {label} \u043d\u0435 \u043d\u0430\u0439\u0434\u0435\u043d",
		"folder_limit_reached": "\u0432 \u0431\u0435\u0441\u043f\u043b\u0430\u0442\u043d\u043e\u043c \u0442\u0430\u0440\u0438\u0444\u0435 \u0432\u043e\u0437\u043c\u043e\u0436\u043d\u0430 \u0442\u043e\u043b\u044c\u043a\u043e \u043e\u0434\u043d\u0430 \u043f\u0430\u043f\u043a\u0430.( \u0442\u043e\u043b\u044c\u043a\u043e \u043a\u043e\u043c\u043c\u0435\u0440\u0447\u0435\u0441\u043a\u0438\u0439)",
		"sort_racks": "\u0440\u0430\u0441\u043e\u0440\u0442\u0435\u0440\u0443\u0439\u0442\u0435 \u0441\u0443\u0449\u0435\u0441\u0442\u0432\u0443\u044e\u0449\u0438\u0435 \u043f\u043e\u043b\u043a\u0438 \u043f\u043e \u0430\u043b\u0444\u0430\u0432\u0438\u0442\u0443.",
		"save_release": "Save release",
		"search_shelf": "Search rack",
		"released_message": "Released at {date} by {name}.",
		"display_type_folder": "Folder",
		"display_type_brochure": "Brochure",
		"display_type": "Display type of the digital folder",
		"folder_general_tab": "General",
		"folder_advanced_tab": "Advanced settings",
		"display_order_numbers": "Display numbers in table of contents",
		"merge": "Merge",
		"merge_success": "Both folders were successfully merged",
		"statistics": "Statistics",
		"statistics_reset": "reset statistics",
		"statistics_reset_message": "Do you like to reset statistics?",
		"statistics_reset_success": "The statistics has been successfully reset.",
		"statistics_folder_open_count": "Count of folder openings",
		"statistics_folder_open_first": "First time opened at",
		"statistics_folder_open_last": "Last time opened at",
		"statistics_folder_last_reset": "Last reset at",
	}, "permissions": {
		"global": "\u0433\u043b\u043e\u0431\u0430\u043b\u044c\u043d\u0430\u044f \u0430\u0432\u0442\u043e\u0440\u0438\u0437\u0430\u0446\u0438\u044f",
		"teams": "\u0433\u0440\u0443\u043f\u043f\u0430   ",
		"permissions": "\u0440\u043e\u043b\u043b\u0438 \u0438 \u0430\u0432\u0442\u043e\u0440\u0438\u0442\u0435\u0442",
		"plans": "\u0440\u0430\u0441\u0447\u0435\u0442",
		"show_dashboard_ui": "\u043f\u043e\u043a\u0430\u0437\u0430\u0442\u044c \u043e\u0431\u0437\u043e\u0440 \u043a\u0430\u0431\u0438\u043d\u044b",
		"desc_show_dashboard_ui": "\u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u044f \u043a\u0430\u0431\u0438\u043d\u044b \u0440\u0430\u0437\u0440\u0435\u0448\u0435\u043d\u043e \u043f\u043e\u043a\u0430\u0437\u0430\u0442\u044c \u0438 \u0432\u0441\u044e \u0432\u0430\u0436\u043d\u0443\u044e \u0438\u043d\u0444\u043e\u0440\u043c\u0430\u0446\u0438\u044e digital folder\u00ae \u043f\u0440\u043e\u0447\u0438\u0442\u0430\u0442\u044c",
		"can_manage_organisation": "\u0423\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u0438\u0435 \u0438\u043d\u0444\u043e\u0440\u043c\u0430\u0446\u0438\u0435\u0439 \u043e \u043a\u043e\u043c\u043f\u0430\u043d\u0438\u0438",
		"desc_can_manage_organisation": "\u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u044e \u0440\u0430\u0437\u0440\u0435\u0448\u0435\u043d\u043e, \u043b\u043e\u0433\u043e\u0442\u0438\u043f, \u0441\u0430\u0439\u0442 \u0438\u0430\u0434\u0440\u0435\u0441 \u0444\u0438\u0440\u043c\u044b \u0438\u0437\u043c\u0435\u043d\u0438\u0442\u044c",
		"can_manage_users": "\u0443\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u0438\u0435 \u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u044f\u043c\u0438",
		"desc_can_manage_users": "\u043f\u043e\u0437\u0432\u0430\u043b\u044f\u0435\u0442 \u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u044e \u043f\u0440\u0438\u0433\u043b\u0430\u0448\u0430\u0442\u044c \u0434\u0440\u0443\u0433\u0438\u0445 \u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u0435\u0439",
		"can_manage_teams": "\u0443\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u0438\u0435 \u043a\u043e\u043c\u0430\u043d\u0434\u0430\u043c\u0438",
		"desc_can_manage_teams": "\u043f\u043e\u0437\u0432\u043e\u043b\u044f\u0435\u0442 \u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u044e \u0441\u043e\u0437\u0434\u0430\u0442\u044c \u043d\u043e\u0432\u0443\u044e \u043a\u043e\u043c\u0430\u043d\u0434\u0443 \u0438 \u043f\u0440\u0438\u0433\u043b\u0430\u0441\u0438\u0442\u044c \u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u044f",
		"can_manage_permissions": "\u041f\u043e\u0437\u0432\u043e\u043b\u044f\u0435\u0442 \u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u044e \u0443\u043f\u0440\u0430\u0432\u043b\u044f\u0442\u044c \u0440\u0430\u0437\u0440\u0435\u0448\u0435\u043d\u0438\u044f\u043c\u0438 \u0434\u043b\u044f \u0440\u043e\u043b\u0435\u0439. \u041f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u044c \u0442\u0430\u043a\u0436\u0435 \u043c\u043e\u0436\u0435\u0442 \u0441\u043e\u0437\u0434\u0430\u0432\u0430\u0442\u044c \u043d\u043e\u0432\u044b\u0435 \u0440\u043e\u043b\u0438",
		"desc_can_manage_permissions": "\u041f\u043e\u0437\u0432\u043e\u043b\u044f\u0435\u0442 \u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u044e \u0443\u043f\u0440\u0430\u0432\u043b\u044f\u0442\u044c \u0440\u0430\u0437\u0440\u0435\u0448\u0435\u043d\u0438\u044f\u043c\u0438 \u0434\u043b\u044f \u0440\u043e\u043b\u0435\u0439. \u041f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u044c \u0442\u0430\u043a\u0436\u0435 \u043c\u043e\u0436\u0435\u0442 \u0441\u043e\u0437\u0434\u0430\u0432\u0430\u0442\u044c \u043d\u043e\u0432\u044b\u0435 \u0440\u043e\u043b\u0438",
		"can_manage_plans": "\u0423\u043f\u0440\u0430\u0432\u043b\u0435\u043d\u0438\u0435 \u043f\u043b\u0430\u0442\u0435\u0436\u043d\u044b\u043c\u0438 \u0434\u0430\u043d\u043d\u044b\u043c\u0438",
		"desc_can_manage_plans": "\u041f\u043e\u0437\u0432\u043e\u043b\u044f\u0435\u0442 \u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u0442\u0435\u043b\u044e \u0443\u043a\u0430\u0437\u0430\u0442\u044c \u0434\u0440\u0443\u0433\u0438\u0435 \u0441\u043f\u043e\u0441\u043e\u0431\u044b \u043e\u043f\u043b\u0430\u0442\u044b, \u0438\u0437\u043c\u0435\u043d\u0438\u0442\u044c \u0442\u0430\u0440\u0438\u0444 \u0438 \u043f\u0440\u043e\u0441\u043c\u043e\u0442\u0440\u0435\u0442\u044c \u0441\u0447\u0435\u0442\u0430",
		"context_menu_copy_only_template": "Copy only structure",
		"context_menu_copy_with_content": "Copy with cover images",
	}, "mediacenter": {
		"search_container": "\u041f\u043e\u0438\u0441\u043a \u043a\u043e\u043d\u0442\u0435\u0439\u043d\u0435\u0440\u0430",
		"search_mediacenter": "\u041f\u043e\u0438\u0441\u043a \u043c\u0435\u0434\u0438\u0430\u0446\u0435\u043d\u0442\u0440\u0430",
		"mediacenter": "\u043c\u0435\u0434\u0438\u0430\u0446\u0435\u043d\u0442\u0440\u0430",
		"containers": "\u041c\u0435\u0434\u0438\u0430 \u043a\u043e\u043d\u0442\u0435\u0439\u043d\u0435\u0440",
		"create_container": "\u041d\u043e\u0432\u044b\u0439 \u043a\u043e\u043d\u0442\u0435\u0439\u043d\u0435\u0440",
		"create_new_container": "\u0421\u043e\u0437\u0434\u0430\u0442\u044c \u043d\u043e\u0432\u044b\u0439 \u043a\u043e\u043d\u0442\u0435\u0439\u043d\u0435\u0440",
		"edit_existing_container": "\u0420\u0435\u0434\u0430\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u0442\u044c \u043a\u043e\u043d\u0442\u0435\u0439\u043d\u0435\u0440",
		"save_container": "\u0421\u043e\u0437\u0434\u0430\u0442\u044c \u043a\u043e\u043d\u0442\u0435\u0439\u043d\u0435\u0440\u044b",
		"container_name_placeholder": "\u0412\u0432\u0435\u0434\u0438\u0442\u0435 \u0438\u043c\u044f \u043a\u043e\u043d\u0442\u0435\u0439\u043d\u0435\u0440\u0430 \u0437\u0434\u0435\u0441\u044c",
		"create_object": "\u041d\u043e\u0432\u044b\u0439 \u043c\u0435\u0434\u0438\u0430-\u043e\u0431\u044a\u0435\u043a\u0442",
		"update_object": "\u0420\u0435\u0434\u0430\u043a\u0442\u0438\u0440\u043e\u0432\u0430\u0442\u044c \u043c\u0435\u0434\u0438\u0430-\u043e\u0431\u044a\u0435\u043a\u0442",
		"save_object": "\u0421\u043e\u0437\u0434\u0430\u0442\u044c \u043e\u0431\u044a\u0435\u043a\u0442",
		"container_name": "\u041d\u0430\u0437\u0432\u0430\u043d\u0438\u0435 \u043a\u043e\u043d\u0442\u0435\u0439\u043d\u0435\u0440\u0430",
		"media_name": "\u041d\u0430\u0437\u0432\u0430\u043d\u0438\u0435 \u0421\u041c\u0418",
		"media_name_placeholder": "\u0412\u0432\u0435\u0434\u0438\u0442\u0435 \u043d\u0430\u0437\u0432\u0430\u043d\u0438\u0435 \u0421\u041c\u0418 \u0437\u0434\u0435\u0441\u044c",
		"container_color": "\u0426\u0432\u0435\u0442 \u043a\u043e\u043d\u0442\u0435\u0439\u043d\u0435\u0440\u0430",
		"description": "\u043e\u043f\u0438\u0441\u0430\u043d\u0438\u0435",
		"description_placeholder": "\u0412\u0432\u0435\u0434\u0438\u0442\u0435 \u043e\u043f\u0438\u0441\u0430\u043d\u0438\u0435 \u0437\u0434\u0435\u0441\u044c",
		"object_type": "\u0422\u0438\u043f \u043e\u0431\u044a\u0435\u043a\u0442\u0430",
		"file_upload": "\u0417\u0430\u0433\u0440\u0443\u0437\u0438\u0442\u044c \u0444\u0430\u0439\u043b",
		"file": "\u041c\u0435\u0434\u0438\u0430 \u0444\u0430\u0439\u043b",
		"file_upload_description": "\u0425\u0440\u0430\u043d\u0438\u0442\u044c \u0438\u043b\u0438 \u0438\u0441\u043a\u0430\u0442\u044c \u0444\u0430\u0439\u043b",
		"url": "\u043d\u0430\u043f\u0440\u0438\u043c\u0435\u0440 https:\/\/google.de",
		"website_url": "\u0441\u0441\u044b\u043b\u043a\u0430",
		"objects": "\u041e\u0431\u044a\u0435\u043a\u0442 | \u041e\u0431\u044a\u0435\u043a\u0442\u044b",
		"object_name_placeholder": "\u0412\u0432\u0435\u0434\u0438\u0442\u0435 \u043d\u0430\u0437\u0432\u0430\u043d\u0438\u0435 \u0421\u041c\u0418 \u0437\u0434\u0435\u0441\u044c",
		"container_with_name_exists": "\u041c\u0435\u0434\u0438\u0430 \u043a\u043e\u043d\u0442\u0435\u0439\u043d\u0435\u0440 \u0441 \u0442\u0430\u043a\u0438\u043c \u0438\u043c\u0435\u043d\u0435\u043c \u0443\u0436\u0435 \u0441\u0443\u0449\u0435\u0441\u0442\u0432\u0443\u0435\u0442",
		"remove_container_confirm_message": "\u0412\u044b \u0443\u0432\u0435\u0440\u0435\u043d\u044b, \u0447\u0442\u043e \u0445\u043e\u0442\u0438\u0442\u0435 \u0443\u0434\u0430\u043b\u0438\u0442\u044c \u043c\u0435\u0434\u0438\u0430-\u043a\u043e\u043d\u0442\u0435\u0439\u043d\u0435\u0440?",
		"remove_container_confirm_title": "\u0423\u0434\u0430\u043b\u0438\u0442\u044c \u043c\u0435\u0434\u0438\u0430 \u043a\u043e\u043d\u0442\u0435\u0439\u043d\u0435\u0440",
		"container_not_found": "\u041a\u043e\u043d\u0442\u0435\u0439\u043d\u0435\u0440 \u043c\u0435\u0434\u0438\u0430 \u0431\u043e\u043b\u044c\u0448\u0435 \u043d\u0435 \u0441\u0443\u0449\u0435\u0441\u0442\u0432\u0443\u0435\u0442",
		"remove_container_success_message": "\u041a\u043e\u043d\u0442\u0435\u0439\u043d\u0435\u0440 \u043c\u0435\u0434\u0438\u0430 \u0431\u044b\u043b \u0443\u0441\u043f\u0435\u0448\u043d\u043e \u0443\u0434\u0430\u043b\u0435\u043d",
		"save_container_success_message": "\u041a\u043e\u043d\u0442\u0435\u0439\u043d\u0435\u0440 \u043c\u0435\u0434\u0438\u0430 \u0431\u044b\u043b \u0443\u0441\u043f\u0435\u0448\u043d\u043e \u0441\u043e\u0445\u0440\u0430\u043d\u0435\u043d",
		"save_object_success_message": "\u041c\u0435\u0434\u0438\u0430-\u043e\u0431\u044a\u0435\u043a\u0442 \u0431\u044b\u043b \u0443\u0441\u043f\u0435\u0448\u043d\u043e \u0441\u043e\u0445\u0440\u0430\u043d\u0435\u043d",
		"save_objects_success_message": "\u041e\u0434\u0438\u043d \u0438\u043b\u0438 \u043d\u0435\u0441\u043a\u043e\u043b\u044c\u043a\u043e \u043e\u0431\u044a\u0435\u043a\u0442\u043e\u0432 \u043c\u0443\u043b\u044c\u0442\u0438\u043c\u0435\u0434\u0438\u0430 \u0431\u044b\u043b\u0438 \u0443\u0441\u043f\u0435\u0448\u043d\u043e \u0441\u043e\u0445\u0440\u0430\u043d\u0435\u043d\u044b",
		"remove_object_confirm_message": "\u0412\u044b \u0443\u0432\u0435\u0440\u0435\u043d\u044b, \u0447\u0442\u043e \u0445\u043e\u0442\u0438\u0442\u0435 \u0443\u0434\u0430\u043b\u0438\u0442\u044c \u043e\u0431\u044a\u0435\u043a\u0442 \u043c\u0443\u043b\u044c\u0442\u0438\u043c\u0435\u0434\u0438\u0430?",
		"remove_object_confirm_title": "\u0423\u0434\u0430\u043b\u0438\u0442\u044c \u043c\u0435\u0434\u0438\u0430-\u043e\u0431\u044a\u0435\u043a\u0442",
		"remove_object_success_message": "\u041e\u0434\u0438\u043d \u0438\u043b\u0438 \u043d\u0435\u0441\u043a\u043e\u043b\u044c\u043a\u043e \u043c\u0435\u0434\u0438\u0430-\u043e\u0431\u044a\u0435\u043a\u0442\u043e\u0432 \u0431\u044b\u043b\u0438 \u0443\u0441\u043f\u0435\u0448\u043d\u043e \u0443\u0434\u0430\u043b\u0435\u043d\u044b",
		"media_not_found": "\u041c\u0435\u0434\u0438\u0430-\u043e\u0431\u044a\u0435\u043a\u0442 \u043d\u0435 \u043d\u0430\u0439\u0434\u0435\u043d",
		"not_downloadable": "\u041c\u0435\u0434\u0438\u0430-\u043e\u0431\u044a\u0435\u043a\u0442 \u043d\u0435 \u044f\u0432\u043b\u044f\u0435\u0442\u0441\u044f \u0444\u0430\u0439\u043b\u043e\u043c \u0438 \u043f\u043e\u044d\u0442\u043e\u043c\u0443 \u043d\u0435 \u043c\u043e\u0436\u0435\u0442 \u0431\u044b\u0442\u044c \u0437\u0430\u0433\u0440\u0443\u0436\u0435\u043d",
		"move_object": "\u043f\u0435\u0440\u0435\u043c\u0435\u0441\u0442\u0438\u0442\u044c \u043e\u0434\u0438\u043d \u0438\u043b\u0438 \u043d\u0435\u0441\u043a\u043e\u043b\u044c\u043a\u043e \u043e\u0431\u044a\u0435\u043a\u0442\u043e\u0432 \u043c\u0443\u043b\u044c\u0442\u0438\u043c\u0435\u0434\u0438\u0430",
		"move_object_success_message": "\u041e\u0434\u0438\u043d \u0438\u043b\u0438 \u043d\u0435\u0441\u043a\u043e\u043b\u044c\u043a\u043e \u043e\u0431\u044a\u0435\u043a\u0442\u043e\u0432 \u043c\u0443\u043b\u044c\u0442\u0438\u043c\u0435\u0434\u0438\u0430 \u0431\u044b\u043b\u0438 \u0443\u0441\u043f\u0435\u0448\u043d\u043e \u043f\u0435\u0440\u0435\u043c\u0435\u0449\u0435\u043d\u044b",
		"file_radio": "\u0444\u0430\u0439\u043b",
		"link_radio": "\u0441\u0441\u044b\u043b\u043a\u0430",
		"object_data": "\u0414\u0430\u043d\u043d\u044b\u0435 \u043e\u0431\u044a\u0435\u043a\u0442\u0430",
		"references": "\u0438\u0441\u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u043d\u0438\u0435",
		"references_empty": "\u042d\u0442\u043e\u0442 \u043c\u0435\u0434\u0438\u0430-\u043e\u0431\u044a\u0435\u043a\u0442 \u043d\u0435 \u0438\u0441\u043f\u043e\u043b\u044c\u0437\u043e\u0432\u0430\u043b\u0441\u044f \u043d\u0438 \u0432 \u043e\u0434\u043d\u043e\u0439 digital folder\u00ae",
		"append_object": "\u041f\u043e\u0436\u0430\u043b\u0443\u0439\u0441\u0442\u0430, \u043d\u0430\u0437\u043d\u0430\u0447\u044c\u0442\u0435 \u043c\u0435\u0434\u0438\u0430 \u043e\u0431\u044a\u0435\u043a\u0442",
		"append_name": "\u041f\u043e\u0436\u0430\u043b\u0443\u0439\u0441\u0442\u0430, \u0432\u0432\u0435\u0434\u0438\u0442\u0435 \u043d\u0430\u0437\u0432\u0430\u043d\u0438\u0435 \u0421\u041c\u0418",
		"media_in_use": "\u041c\u0435\u0434\u0438\u0430-\u043e\u0431\u044a\u0435\u043a\u0442 \u043d\u0435 \u043c\u043e\u0436\u0435\u0442 \u0431\u044b\u0442\u044c \u0443\u0434\u0430\u043b\u0435\u043d. \u0412 \u043d\u0430\u0441\u0442\u043e\u044f\u0449\u0435\u0435 \u0432\u0440\u0435\u043c\u044f \u043e\u043d \u0438\u0441\u043f\u043e\u043b\u044c\u0437\u0443\u0435\u0442\u0441\u044f \u0432 digital folder\u00ae.",
		"size_exceeded": "\u041f\u0440\u0435\u0434\u0435\u043b \u0445\u0440\u0430\u043d\u0435\u043d\u0438\u044f \u0434\u043e\u0441\u0442\u0438\u0433\u043d\u0443\u0442. \u041f\u043e\u0436\u0430\u043b\u0443\u0439\u0441\u0442\u0430, \u043e\u0431\u043d\u043e\u0432\u0438\u0442\u0435 \u0442\u0430\u0440\u0438\u0444.",
		"media_object_limit_type": "\u0412 \u0431\u0435\u0441\u043f\u043b\u0430\u0442\u043d\u043e\u043c \u0438 \u0431\u0430\u0437\u043e\u0432\u043e\u043c \u0442\u0430\u0440\u0438\u0444\u0435 \u0441\u0441\u044b\u043b\u043a\u0438 \u0437\u0430\u043f\u0440\u0435\u0449\u0435\u043d\u044b. \u041f\u043e\u0436\u0430\u043b\u0443\u0439\u0441\u0442\u0430, \u043e\u0431\u043d\u043e\u0432\u0438\u0442\u0435 \u0442\u0430\u0440\u0438\u0444. (\u0442\u043e\u043b\u044c\u043a\u043e \u043a\u043e\u043c\u043c\u0435\u0440\u0447\u0435\u0441\u043a\u0438\u0439)",
		"select_container": "\u041f\u043e\u0436\u0430\u043b\u0443\u0439\u0441\u0442\u0430, \u0441\u043d\u0430\u0447\u0430\u043b\u0430 \u0432\u044b\u0431\u0435\u0440\u0438\u0442\u0435 \u043c\u0435\u0434\u0438\u0430-\u043a\u043e\u043d\u0442\u0435\u0439\u043d\u0435\u0440",
		"container_with_media_in_use": "\u041d\u0435 \u0443\u0434\u0430\u043b\u043e\u0441\u044c \u0443\u0434\u0430\u043b\u0438\u0442\u044c \u043c\u0435\u0434\u0438\u0430-\u043a\u043e\u043d\u0442\u0435\u0439\u043d\u0435\u0440. \u041f\u043e \u043a\u0440\u0430\u0439\u043d\u0435\u0439 \u043c\u0435\u0440\u0435 \u043e\u0434\u0438\u043d \u043c\u0435\u0434\u0438\u0430-\u0444\u0430\u0439\u043b \u0438\u0441\u043f\u043e\u043b\u044c\u0437\u0443\u0435\u0442\u0441\u044f \u0432 digital folder\u00ae.",
		"invalid_url": "\u0423\u043a\u0430\u0437\u0430\u043d\u043d\u0430\u044f \u0441\u0441\u044b\u043b\u043a\u0430 \u043d\u0435\u0434\u0435\u0439\u0441\u0442\u0432\u0438\u0442\u0435\u043b\u044c\u043d\u0430 \u0438\u043b\u0438 \u043d\u0435\u0434\u043e\u0441\u0442\u0443\u043f\u043d\u0430",
		"thumbnail_processing": "\u0413\u0435\u043d\u0435\u0440\u0430\u0446\u0438\u044f \u043f\u0440\u0435\u0434\u0432\u0430\u0440\u0438\u0442\u0435\u043b\u044c\u043d\u043e\u0433\u043e \u043f\u0440\u043e\u0441\u043c\u043e\u0442\u0440\u0430 \u2026",
		"sort_containers": "\u0421\u043e\u0440\u0442\u0438\u0440\u0443\u0435\u0442 \u043c\u0435\u0434\u0438\u0430 \u043a\u043e\u043d\u0442\u0435\u0439\u043d\u0435\u0440\u044b \u043d\u0430\u043f\u0440. \u041f\u043e \u0430\u043b\u0444\u0430\u0432\u0438\u0442\u0443",
		"change_media_advice": "\u041f\u0440\u0438\u043c\u0435\u0447\u0430\u043d\u0438\u0435. \u042d\u0442\u043e\u0442 \u043c\u0443\u043b\u044c\u0442\u0438\u043c\u0435\u0434\u0438\u0439\u043d\u044b\u0439 \u043e\u0431\u044a\u0435\u043a\u0442 \u0443\u0436\u0435 \u0441\u0432\u044f\u0437\u0430\u043d \u0441 digital folder\u00ae. \u0415\u0441\u043b\u0438 \u0432\u044b \u043c\u0435\u043d\u044f\u0435\u0442\u0435 \u0441\u043e\u0434\u0435\u0440\u0436\u0438\u043c\u043e\u0435, \u043c\u0435\u043d\u044f\u0439\u0442\u0435 \u0435\u0433\u043e \u0432\u043e \u0412\u0421\u0415\u0425 digital folder\u00ae!",
		"thumbnail": "\n\u0437\u0430\u0441\u0442\u0430\u0432\u043a\u0430\n",
		"filetype_mismatch": "\u0422\u0438\u043f \u0444\u0430\u0439\u043b\u0430 \u043d\u0435 \u0440\u0430\u0437\u0440\u0435\u0448\u0435\u043d \u0434\u043b\u044f \u0437\u0430\u0441\u0442\u0430\u0432\u043a\u0430\n, \u0441\u0442\u0440\u0430\u043d\u0438\u0446 \u0438\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u0439 \u0438\u043b\u0438 \u0444\u0440\u043e\u043d\u0442\u043e\u0432 \u043f\u0430\u043f\u043e\u043a.",
		"select_from_mediacenter": "Select from mediacenter",
		"limit_exceeded": "Your file is too big. We only support 25MB each file.",
		"no_media_found": "No media objects found",
		"from_mediacenter": "from media center",
		"container_data": "Containerdaten",
		"share_container": "Share",
		"released": "shared",
		"unable_to_update_released_container": "You are not allowed to edit a shared media container",
		"unable_to_update_info": "This media container has been released by team: {team} and you are not allowed to edit it.",
		"uneditable_shared_media": "Shared media objects can only changed by creators",
		"upload_activity": "Just a moment - your files will be uploaded.",
		"select_from_clipboard": "Paste from clipboard"
	}
}

<template>
  <SidebarLayout :title="title">
    <Loader v-if="loading"/>
    <b-columns>
      <b-column>
        <b-card>
          <Table
            :headers="headers"
            :data="team.members"
            :empty="$t('settings.team_members_empty')">
            <div class="modal-controls" slot-scope="data">
              <ConfirmModal
                icon="times"
                type="is-danger"
                @success="kick(data.row.id)"
                :title="$t('settings.team_kick')"
                :tooltip="$t('tooltips.remove_member')"
                :message="$t('settings.team_kick_description')"
              />
              <ConfirmModal
                type="is-danger"
                icon="door-open"
                @success="leaveTeam"
                v-if="data.row.id === userId"
                :title="$t('settings.leave_team')"
                :tooltip="$t('settings.leave_team')"
                :message="$t('settings.leave_team_description')"
              />
            </div>
          </Table>
        </b-card>
        <InviteModal
          class="mt-1"
          :teams="teams"
          @success="load"
          :title="$t('settings.invite_member')"
        />
      </b-column>
    </b-columns>
  </SidebarLayout>
</template>

<script>
  import SidebarLayout from '../../components/settings/SidebarLayout'
  import ConfirmModal from '../../components/settings/modals/ConfirmModal'
  import InviteModal from '../../components/settings/modals/InviteModal'
  import Loader from '../../components/global/Loader'
  import APIFactory from '../../api/factory'
  import Table from '../../components/Table'
  import Headers from '../../data/headers'
  import Helpers from '../../helpers'
  import {mapGetters} from 'vuex'

  export default {
    computed: {
      ...mapGetters({
        userId: 'auth/userId'
      }),
      title() {
        return this.team
          ? this.$t('settings.members_of') + ' ' + this.team.name
          : ''
      }
    },
    data() {
      return {
        team: {},
        teams: {},
        loading: false,
        headers: Headers.members
      }
    },
    created() {
      this.load()
    },
    methods: {
      load() {
        this.loading = true
        Promise.all([
          APIFactory.findTeam(this.$route.params.slug, this.$route.params.teamSlug),
          APIFactory.findTeams(this.$route.params.slug)
        ]).then(res => {
          this.loading = false
          if (res) {
            if (res[0] && res[0].success) {
              this.team = res[0].data
            }

            if (res[1] && res[1].success) {
              this.teams = res[1].data
            }
          }
        })
      },
      leaveTeam() {
        this.loading = true
        APIFactory.leaveTeam(this.$route.params.slug, this.$route.params.teamSlug).then(res => {
          this.loading = false
          if (res && res.success) {
            Helpers.callToast('is-success', this.$t('settings.team_leave_successful'))
            this.$store.commit('auth/setAvatar', res.data.avatar)
            this.$store.commit('auth/setUserId', res.data.user_id)
            this.$store.commit('auth/setToken', res.data.token)
            this.$store.commit('auth/setApps', res.data.apps)
            this.$store.commit('app/setVersion', res.data.version)
            this.$store.commit('auth/setSuperadmin', !!res.data.superadmin)
            this.$store.commit('auth/setFirstLogin', !!res.data.firstLogin)
            this.load()
          }
        })
      },
      kick(id) {
        this.loading = true
        APIFactory.kick(this.$route.params.slug, this.$route.params.teamSlug, id).then(res => {
          this.loading = false
          if (res && res.success) {
            Helpers.callToast('is-success', this.$t('settings.team_kick_successful'))
            this.$store.commit('auth/setAvatar', res.data.avatar)
            this.$store.commit('auth/setUserId', res.data.user_id)
            this.$store.commit('auth/setToken', res.data.token)
            this.$store.commit('auth/setApps', res.data.apps)
            this.$store.commit('app/setVersion', res.data.version)
            this.$store.commit('auth/setSuperadmin', !!res.data.superadmin)
            this.$store.commit('auth/setFirstLogin', !!res.data.firstLogin)
            this.load()
          }
        })
      },
    },
    watch: {
      '$route'() {
        this.load()
      },
    },
    components: {
      Table,
      Loader,
      InviteModal,
      ConfirmModal,
      SidebarLayout,
    }
  }
</script>

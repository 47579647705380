<template>
  <div class="c-display-layout">
    <div :class="{
       'c-display-menu': true,
       'animXAxisToggleIn': !open && resolvedFirstClick,
       'c-display-menu--open animXAxisToggleOut': open || isMobileMode,
      }">
      <div class="c-display-menu-header" v-show="!isMobileMode">
        <b-tooltip
          position="is-right"
          :label="menuTooltip"
          :delay="tooltipDelay">
          <div
            class="c-display-menu-button"
            @click.prevent="toggle">
            <img :src="icon">
          </div>
        </b-tooltip>
      </div>
      <div
        class="c-display-menu-header c-display-menu-middle"
        v-show="!isMobileMode && !open && !preview && hasNewElements">
        <b-tooltip
          position="is-right"
          :label="$t('display.show_new_elements')"
          :delay="tooltipDelay">
          <div
            class="c-display-menu-button"
            @click.prevent="toggleNewElementsMenu">
            <img src="/images/icons/star--yellow.svg">
          </div>
        </b-tooltip>
      </div>
      <div
        class="c-display-menu-header"
        v-show="!isMobileMode && !open && !preview">
        <b-tooltip
          position="is-right"
          :label="$t('display.close')"
          :delay="tooltipDelay">
          <div
            class="c-display-menu-button"
            @click.prevent="$router.go()">
            <img src="/images/icons/power-off.svg">
          </div>
        </b-tooltip>
      </div>
      <div class="c-display-menu-container" v-show="open || isMobileMode">
        <div class="c-display-menu-header-logo">
          <img :src="companyIcon" @click.prevent="hrefTo">
        </div>
        <b-tooltip
          multilined
          position="is-top"
          :label="folder.name"
          :delay="tooltipDelay">
          <v-clamp
            autoresize
            :max-lines="2"
            class="c-display-menu-title u-mt--16">
            {{ folder.name }}
          </v-clamp>
        </b-tooltip>
        <div class="c-display-menu-controls u-mt--24">
          <!-- Add Button here for search -->
          <SearchBar
            :full-width="true"
            @change="$emit('search', $event)"
          />
          <MenuContext
            class="ml-1"
            :is-expanded="isExpanded"
            @toggleExpand="toggleExpandedAll"
            :has-new-elements="hasNewElements"
            :show-new-elements="showNewElements"
            @toggleNewElements="toggleNewElements"
          />
        </div>
        <ChapterMenu
          :key="index"
          :folder="folder"
          :title="chapter.name"
          :number="chapter.index"
          :expanded="chapter.expanded"
          :highlighted="chapter.highlighted"
          :selected="currentChapterIndex === (chapter.index - 1)"
          :has-expandable="chapter.pages && chapter.pages.length > 0"
          @expanded="toggleExpanded(chapter)"
          @selectChapter="selectChapter(chapter)"
          v-for="(chapter, index) in chapters">
          <PageMenu
            :key="idx"
            :folder="folder"
            :title="page.name"
            :number="chapter.index"
            :sub-number="page.index + 1"
            :highlighted="page.highlighted"
            :selected="currentPageIndex === page.index && (chapter.index - 1) === currentChapterIndex"
            v-for="(page, idx) in filteredPages(chapter.pages)"
            @selectPage="selectPage(page, chapter)"
          />
        </ChapterMenu>
      </div>
      <div class="c-display-menu-footer" v-show="open || isMobileMode">
        <div class="c-display-menu-footer-container">
          <div class="w-full" />
          <Dropdown
            :show-value-as-label="true"
            :default-value="$i18n.locale"
            @select="selectLanguage"
            :values="languages"
            mode="open-top"
          />
        </div>
        <div class="c-display-menu-footer-container c-display-menu-footer-container--center u-mt--8">
          <img src="images/do-logo.svg">
          <div>
            <a href="#/auth/signup" class="highlighted highlighted--link">{{ $t('display.trial_period') }}</a>
          </div>
        </div>
      </div>
    </div>
    <div class="c-display-container"
         v-show="!isMobileMode || forceShowContent">
      <template v-if="!isMobileMode">
        <img
          :src="background"
          v-if="hasBackgroundImage"
        />
        <div
          v-else
          class="c-folder-background"
          :style="'background: ' + background"
        />
      </template>
      <slot/>
    </div>
  </div>
</template>

<script>
  import MenuContext from "../../../components/display/content/MenuContext"
  import Dropdown from '../../global/dropdowns/Dropdown'
  import Button from '../../global/buttons/Button'
  import ChapterMenu from '../content/ChapterMenu'
  import SearchBar from '../../global/inputs/SearchBar'
  import PageMenu from '../content/PageMenu'
  import {mapGetters} from 'vuex'
  import VClamp from 'vue-clamp'

  export default {
    props: [
      'folder',
      'keyword',
      'preview',
      'forceShowContent',
      'current-page-index',
      'current-chapter-index'
    ],
    computed: {
      ...mapGetters({
        apiURL: 'app/apiURL',
        tooltipDelay: 'ui/tooltipDelay',
        isMobileMode: 'app/isMobileMode',
        availableLanguages: 'app/availableLanguages'
      }),
      menuTooltip() {
        return this.open
          ? this.$t('display.menu_hide')
          : this.$t('display.menu_open')
      },
      languages() {
        return this.availableLanguages.map(item => {
          return {
            id: item,
            label: this.$t('app.language_' + item) + ' (' + item.toUpperCase() + ')'
          }
        })
      },
      icon() {
        return this.open
          ? 'images/icons/close.svg'
          : 'images/icons/main-navigation.svg'
      },
      companyIcon() {
        return this.folder && this.folder.sender && this.folder.sender.icon
          ? process.env.VUE_APP_API_URL + this.folder.sender.icon
          : 'images/do-logo.svg'
      },
      chapters() {
        if (this.folder && this.folder.chapters) {
          return this.folder.chapters
          .filter(item => item.type === 'chapter')
          .map(item => {
            return {
              ...item,
              highlighted: this.isChapterHighlighted(item),
              pages: item.pages.map(page => {
                return {
                  ...page,
                  showAsNew: page.showAsNew || (!!page.objects.find(object => object.showAsNew)),
                  highlighted: this.isPageHighlighted(page)
                }
              }),
              showAsNew: item.showAsNew || (!!item.pages.find(page => page.showAsNew))
            }
          })
          .filter(item => (this.showNewElements && item.showAsNew) || !this.showNewElements)
        }
      },
      hasNewElements() {
        return this.folder.chapters.filter(chapter => {
          return chapter.showAsNew || chapter.pages.filter(page => {
            return page.showAsNew || page.objects.filter(object => {
              return object.showAsNew
            }).length > 0
          }).length > 0
        }).length > 0
      },
      isExpanded() {
        return this.folder
          && this.folder.chapters
          && this.folder.chapters.filter(item => item.expanded).length < (this.folder.chapters.length / 2)
      },
      background() {
        if (this.hasBackgroundImage) {
          return this.apiURL + '/api/object/' + (this.folder.code || this.$route.params.code) + '/' + this.folder.background_image + '/background'
        }

        if (this.folder.background_color) {
          return this.folder.background_color
        }

        return 'url("images/desks/background_seamless_wood_black.jpg")'
      },
      hasBackgroundImage() {
        return !!this.folder.background_image
      },
      hasBackgroundColor() {
        return !!this.folder.background_color
      }
    },
    created() {
      window.addEventListener('click', this.close, false)
    },
    beforeDestroy() {
      window.removeEventListener('click', this.close, false)
    },
    data() {
      return {
        open: false,
        showNewElements: false,
        resolvedFirstClick: false,
      }
    },
    methods: {
      toggle() {
        this.resolvedFirstClick = true
        this.open = !this.open
      },
      filteredPages(pages) {
        return pages
          ? pages.filter(page => {
            return (this.showNewElements && (page.showAsNew || page.objects.find(object => object.showAsNew)))
              || !this.showNewElements
          })
          : []
      },
      isPageHighlighted(page) {
        if (!this.keyword) {
          return false
        }

        let hasPageObjectWithKeyword = page.objects
        .filter(object => {
          return (object.name
            && object.name.toLowerCase().indexOf(this.keyword.toLowerCase()) > -1)
            || (object.tags.filter(tag => {
              return tag.toLowerCase().indexOf(this.keyword.toLowerCase()) > -1
            }).length > 0)
            || (object.note
              && object.note.toLowerCase().indexOf(this.keyword.toLowerCase()) > -1)
        }).length > 0
        return page.name && page.name.toLowerCase().indexOf(this.keyword.toLowerCase()) > -1 || hasPageObjectWithKeyword
      },
      isChapterHighlighted(chapter) {
        if (!this.keyword) {
          return false
        }
        return (chapter.name && chapter.name.toLowerCase().indexOf(this.keyword.toLowerCase()) > -1)
          || (chapter.pages.filter(page => this.isPageHighlighted(page)).length > 0)
      },
      toggleNewElements() {
        this.showNewElements = !this.showNewElements
      },
      toggleNewElementsMenu() {
        this.showNewElements = true
        this.open = true
      },
      close($event) {
        let containerElement = document.querySelector('.c-display-container')
        if (containerElement && containerElement.contains($event.target)) {
          this.open = false
        }
      },
      toggleExpanded(chapter) {
        let updatedChapters = this.folder.chapters
        .map(item => {
          if (item.type === 'chapter' && item.id === chapter.id) {
            item.expanded = !chapter.expanded
          }
          return item
        })
        this.$set(this.folder, 'chapters', updatedChapters)
      },
      toggleExpandedAll() {
        let updatedChapters = this.folder.chapters
        .map(item => {
          if (item.type === 'chapter') {
            item.expanded = this.isExpanded
          }
          return item
        })
        this.$set(this.folder, 'chapters', updatedChapters)
      },
      selectLanguage($event) {
        this.$router.push({
          query: {
            lang: $event
          }
        }).catch(err => {
        })
      },
      selectChapter(chapter) {
        this.$emit('setMobileMenu', true)
        this.$emit('selectChapter', chapter)
      },
      selectPage(page, chapter) {
        this.$emit('setMobileMenu', true)
        this.$emit('selectPage', {
          chapter,
          page
        })
      },
      hrefTo() {
        if (this.folder
          && this.folder.sender
          && this.folder.sender.website
          && this.folder.sender.icon) {
          window.open(this.folder.sender.website, '_blank')
        }
        window.open('https://digital-folder.com', '_blank')
      }
    },
    components: {
      VClamp,
      Button,
      PageMenu,
      Dropdown,
      SearchBar,
      ChapterMenu,
      MenuContext,
    }
  }
</script>

<style lang="scss" scoped>
  .c-display-layout {
    width: 100%;
    height: 100%;
    display: flex;
    user-select: none;

    .c-display-menu {
      width: 64px;
      height: 100%;
      z-index: 2;
      max-width: 440px;
      position: absolute;
      background: $white;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      color: $darker-blue-color;
      border-right: 1px solid $gray;

      &.c-display-menu--open {
        width: 100%;

        @media (max-width: $breakpoint-mobile) {
          max-width: 100%;
          border-right: none;
        }
      }

      .c-display-menu-header {
        width: 100%;
        height: 64px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        border-bottom: 1px solid $gray;

        .c-display-menu-button {
          width: 64px;
          height: 64px;
          display: flex;
          cursor: pointer;
          align-items: center;
          justify-content: center;

          img {
            width: 24px;
            height: 24px;
          }
        }

        &.c-display-menu-middle {
          width: 100%;
          display: flex;
          align-items: flex-start;
          height: calc(100% - 64px);
        }
      }

      .c-display-menu-container {
        width: 100%;
        overflow-y: auto;
        padding: 24px 40px;
        height: calc(100% - 164px);

        @media (max-width: $breakpoint-mobile) {
          padding: 24px;
          height: calc(100% - 116px);
        }

        .c-display-menu-header-logo {
          display: flex;

          img {
            max-width: 100%;
            max-height: 100px;
          }
        }

        .c-display-menu-title {
          width: 100%;
          font-size: 32px;
          font-family: Roboto;

          @media (max-width: $breakpoint-mobile) {
            font-size: 20px;
          }
        }

        .c-display-menu-controls {
          display: flex;
          justify-content: space-between;
        }
      }

      .c-display-menu-footer {
        width: 100%;
        height: 128px;
        padding: 16px 40px;
        border-top: 1px solid $gray;

        @media (max-width: $breakpoint-mobile) {
          padding: 0 24px;
        }

        .c-display-menu-footer-container {
          display: flex;
          align-content: center;
          justify-content: space-between;

          @media (max-width: $breakpoint-mobile) {
            padding-top: 16px;
          }

          &.c-display-menu-footer-container--center {
            justify-content: center;
            align-items: center;
            margin-bottom: 8px;
            font-size: 12px;
          }

          img {
            width: 20px;
            height: 20px;
            margin-right: 8px;
          }

          .c-help-container {
            display: flex;
            cursor: pointer;
            font-size: 16px;
            font-weight: 500;
            align-items: center;
            font-family: Roboto;
          }
        }
      }
    }

    .c-display-container {
      //background: url('/images/desks/background_seamless_wood_black.jpg') center;
      width: calc(100% - 64px);
      margin-left: 64px;
      height: 100%;

      img,
      .c-folder-background {
        position: absolute;
        max-width: 100%;
        max-height: 100%;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      @media(max-width: $breakpoint-mobile) {
        background: $white;
        margin-left: 0;
        height: 100%;
        width: 100%;
        padding: 0;
        z-index: 4;
      }
    }
  }
</style>

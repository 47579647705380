<template>
  <div class="c-search" v-show="keyword">
    <div class="c-search-window">
      <div class="search-button"
           @click.prevent="$emit('reset')">
        <img src="images/icons/close.svg">
      </div>
      <div class="search-header">{{ $t('workspaces.search_result') }}</div>
      <div class="search-content">
        <LoadingComponent :load="loading">
          <div class="search-result-wrapper">
            <SearchCategory
              :key="index"
              :title="category.label"
              v-for="(category, index) in categories">
              <SearchItem
                :key="idx"
                :title="item.name"
                :icon="category.item_icon"
                @reset="$emit('reset')"
                v-for="(item, idx) in result[category.id]"
                :link="createLinkByType(category.id, item.slug)"
                :subtitle="createSubtitleByType(category.id, item)"
                v-if="result[category.id] && result[category.id].length > 0"
              />
              <div v-if="!result[category.id] || result[category.id].length === 0"
                   class="c-search-item--empty">
                {{ $t('workspaces.search_no_result', {label: category.label}) }}
              </div>
            </SearchCategory>
          </div>
        </LoadingComponent>
      </div>
    </div>
  </div>
</template>

<script>
  import SearchItem from './SearchItem'
  import SearchCategory from './SearchCategory'
  import APIFactory from '../../../../api/factory'
  import LoadingComponent from '../../LoadingComponent'

  export default {
    props: [
      'keyword'
    ],
    data() {
      return {
        result: [],
        loading: false,
        categories: [
          {
            id: 'objects',
            label: this.$t('app.search_objects'),
            item_icon: 'images/icons/states/page.svg',
          },
          {
            id: 'containers',
            label: this.$t('app.search_containers'),
            item_icon: 'images/icons/media-center.svg',
          },
          {
            id: 'folders',
            label: this.$t('app.search_folders'),
            item_icon: 'images/icons/shelf-icon.svg',
          },
          {
            id: 'racks',
            label: this.$t('app.search_racks'),
            item_icon: 'images/icons/shelf-icon.svg',
          }
        ]
      }
    },
    methods: {
      throttle(func, limit) {
        if (!this.timeout) {
          this.timeout = setTimeout(func, limit)
        } else {
          clearTimeout(this.timeout)
          this.timeout = setTimeout(func, limit)
        }
      },
      createLinkByType(type, area) {
        switch (type) {
          case 'objects':
          case 'containers':
            return '/' + this.$route.params.slug + '/media-center/' + this.$route.params.teamId + '/' + area
          case 'folders':
          case 'racks':
            return '/' + this.$route.params.slug + '/workspaces/' + this.$route.params.teamId + '/' + area
        }
      },
      createSubtitleByType(type, item) {
        switch (type) {
          case 'containers':
            return item.sub + ' ' + this.$tc('mediacenter.objects', item.sub)
          case 'racks':
            return item.sub + ' ' + this.$tc('workspaces.folder', item.sub)
          default:
            return item.sub
        }
      }
    },
    watch: {
      keyword() {
        this.throttle(() => {
          if (this.keyword) {
            this.loading = true
            APIFactory.search(this.$route.params.slug, this.$route.params.teamId, this.keyword).then(res => {
              if (res && res.success) {
                this.result = res.data
              }
            }).finally(() => this.loading = false)
          }
        }, 500)
      }
    },
    components: {
      LoadingComponent,
      SearchCategory,
      SearchItem,
    }
  }
</script>

<style lang="scss" scoped>
  .search-content {
    margin-top: 24px;
    overflow-y: auto;
    height: calc(100% - 104px);

    .search-result-wrapper {
      width: 100%;
      height: 100%;
      flex-shrink: 0;
    }
  }

  .search-header {
    font-size: 32px;
    margin-top: 32px;
    font-weight: 500;
    font-family: Roboto;
    word-break: break-all;
    color: $darker-blue-color;
  }

  .search-button {
    top: 24px;
    right: 24px;
    cursor: pointer;
    position: absolute;

    img {
      width: 24px;
      height: 24px;
    }
  }

  .c-search {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 5200;
    display: flex;
    position: absolute;
    align-items: center;
    justify-content: center;
    background: rgba($primary-color, .5);

    .c-search-window {
      width: 100%;
      height: 100%;
      padding: 32px;
      max-height: 95%;
      max-width: 640px;
      background: $white;
      border-radius: 4px;
      position: relative;
    }
  }

  .c-search-item--empty {
    padding: 16px;
    font-size: 14px;
    margin-top: 8px;
    font-weight: 500;
    border-radius: 4px;
    text-align: center;
    background: $light-blue-color;
  }
</style>

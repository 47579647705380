<template>
  <simple-button
    :disabled="!!disabled"
    :class="{
        	'c-icon-button': true,
        	'c-icon-button--theme-green': theme === 'green',
        	'c-icon-button--theme-primary': theme === 'primary'
        }"
    @click="$emit('click')">
    <img :src="icon">
  </simple-button>
</template>

<script>
  import Icon from '../Icon'
  import SimpleButton from './SimpleButton';

  export default {
    props: [
      'icon',
      'theme',
      'disabled'
    ],
    components: {
      SimpleButton,
      Icon
    }
  }
</script>

<style lang="scss" scoped>
  .c-icon-button {
    width: 40px;
    height: 40px;
    border: none;
    outline: none;
    display: flex;
    cursor: pointer;
    flex-shrink: 0;
    user-select: none;
    border-radius: 4px;
    align-items: center;
    color: $primary-color;
    justify-content: center;
    background: $lighter-gray-color;

    &:hover {
      background: darken($lighter-gray-color, 7.5%);
    }

    &.c-icon-button--theme-green {
      background: $success-color;

      &:hover {
        background: darken($success-color, 7.5%);
      }
    }

    &.c-icon-button--theme-primary {
      background: $primary-color;

      &:hover {
        background: darken($primary-color, 7.5%);
      }
    }
  }
</style>

<template>
  <Context
    :wide-entries="true"
    :transparent="true"
    :entries="entries"
    @select="select"
    :small="true">
    <img src="/images/icons/options.svg"/>
  </Context>
</template>

<script>
  import Context from '../../global/Context'
  import PlanMixin from '../../../mixins/plan'

  export default {
    props: [
      'chapter',
    ],
    data() {
      return {
        entries: []
      }
    },
    created() {
      this.entries = [
        {
          index: 0,
          icon: '/images/icons/copy.svg',
          title: 'workspaces.copy',
          function: 'copy'
        },
        {
          index: 3,
          icon: '/images/icons/delete.svg',
          title: 'workspaces.delete',
          function: 'delete'
        },
      ]

      if (this.chapter.status === 'inactive') {
        this.entries.push({
          index: 1,
          icon: '/images/icons/activate.svg',
          title: 'workspaces.activate',
          function: 'toggleState'
        })
      } else {
        this.entries.push({
          index: 1,
          icon: '/images/icons/activate.svg',
          title: 'workspaces.deactivate',
          function: 'toggleState'
        })
      }

      if (this.hasPremiumPlan) {
        this.entries = this.entries.concat([{
          index: 2,
          icon: '/images/icons/star--blue.svg',
          title: 'designer.show_as_new',
          function: 'toggleAsNew',
        }])
      }

      this.entries = this.entries.sort((i1, i2) => i1.index > i2.index ? 1 : -1)
    },
    methods: {
      select($event) {
        if (this[$event.function]) {
          this[$event.function]()
        }
      },
      copy() {
        let copiedChapter = JSON.parse(JSON.stringify(this.chapter))
        this.$emit('copy', copiedChapter)
      },
      toggleAsNew() {
        let entry = this.entries.find(item => item.function === 'toggleAsNew')
        if (entry) {
          this.chapter.showAsNew = !this.isChapterMarkedAsNew()
          if (!this.chapter.showAsNew) {
            this.chapter.pages = this.chapter.pages.map(page => {
              return {
                ...page,
                showAsNew: false,
                objects: page.objects.map(object => {
                  return {
                    ...object,
                    showAsNew: false,
                  }
                })
              }
            })
          }
          this.$set(this, 'chapter', this.chapter)
          this.checkShowAsNewState()
        }
      },
      toggleState() {
        let entry = this.entries.find(item => item.function === 'toggleState')
        if (entry) {
          entry.title = entry.title === 'workspaces.activate'
            ? 'workspaces.deactivate'
            : 'workspaces.activate'

          if (this.chapter.status === 'active') {
            this.$set(this.chapter, 'status', 'inactive')
          } else {
            this.$set(this.chapter, 'status', 'active')
          }
        }
      },
      delete() {
        this.$emit('delete', this.chapter.id)
      },
      isChapterMarkedAsNew() {
        return this.chapter.showAsNew || this.chapter.pages.find(page => page.showAsNew
          || page.objects.find(object => object.showAsNew))
      },
      checkShowAsNewState() {
        let entry = this.entries.find(item => item.function === 'toggleAsNew')
        if (entry) {
          entry.title = this.isChapterMarkedAsNew()
            ? 'designer.hide_as_new'
            : 'designer.show_as_new'
        }
      }
    },
    mixins: [
      PlanMixin
    ],
    watch: {
      chapter: {
        deep: true,
        handler() {
          this.checkShowAsNewState()
        }
      }
    },
    components: {
      Context
    }
  }
</script>

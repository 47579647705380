<template>
  <div
    :style="setIndex"
    class="c__context-wrapper">
    <div @click.stop="toggle"
         :class="{
           'c__context-button--transparent': transparent,
           'c__context-button--middle': middle,
           'c__context-button--small': small,
           'c__context-button': true,
          }">
      <slot/>
    </div>
    <div v-show="active"
         class="c__context-menu">
      <div
        :key="index"
        :class="{
         'c__context-entry': true,
         'c__context-entry--wide': wideEntries,
         'c__context-entry--disabled': entry.disabled,
         'c__context-entry--highlighted': entry.highlighted,
         'c__context-entry--submenu-open': entry.subEntries && showSubmenu
        }"
        @click.stop="select(entry)"
        v-for="(entry, index) in sortedEntries">
        <div class="c__context-entry-wrapper">
          <div class="c__context-icon">
            <img :src="entry.icon"/>
          </div>
          <div :class="{
            'c__context-title--red': entry.title === 'workspaces.delete',
            'c__context-title': true,
          }">
            {{ $t(entry.title) }}
          </div>
        </div>
        <div
          class="c-submenu-entries"
          v-show="entry.subEntries && showSubmenu === entry.id">
          <div :class="{
              'c-submenu-entry': true,
              'c-submenu-entry--selected': false,
            }" v-for="subEntry in entry.subEntries"
               @click.prevent="selectSubmenu(subEntry)">
            <div
              v-if="subEntry.icon"
              class="c-submenu-entry-icon">
              <img :src="subEntry.icon">
            </div>
            <div class="u-ml--24" v-if="showIconGap(entry, subEntry)"/>
            <div class="c-submenu-entry-title">{{ $t(subEntry.title) }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Helpers from '../../helpers'

  export default {
    props: [
      'small',
      'middle',
      'entries',
      'index',
      'transparent',
      'wide-entries',
      'hideSubmenuIconIds'
    ],
    computed: {
      sortedEntries() {
        return this.entries.sort((i1, i2) => i1.id - i2.id)
      },
      setIndex() {
        if (this.index || this.index === 0) {
          return 'z-index: ' + (5000 - this.index) + ';'
        }
      },
    },
    data() {
      return {
        active: false,
        showSubmenu: null,
        id: Helpers.createUUID(),
      }
    },
    created() {
      window.addEventListener('click', this.close)
      window.addEventListener('toggle-context-menu', this.handleSameComponentToggle)
    },
    beforeDestroy() {
      window.removeEventListener('click', this.close)
      window.removeEventListener('toggle-context-menu', this.handleSameComponentToggle)
    },
    methods: {
      select($event) {
        if (!$event.subEntries) {
          if (!$event.disabled) {
            this.active = false
            this.$emit('select', $event)
          }
        } else {
          this.showSubmenu = $event.id
        }
      },
      selectSubmenu($event) {
        this.active = false
        this.$emit('select', $event)
      },
      toggle() {
        this.active = !this.active
        this.showSubmenu = null
        window.dispatchEvent(new CustomEvent('toggle-context-menu', {detail: this.id}))
      },
      handleSameComponentToggle($event) {
        if (this.id !== $event.detail) {
          this.active = false
        }
      },
      close($event) {
        if (!this.$el.contains($event.target)) {
          this.active = false
        }
      },
      hideSubmenuIcon(iconMenuId) {
        return this.hideSubmenuIconIds
          && this.hideSubmenuIconIds.indexOf(iconMenuId) > -1
      },
      showIconGap(entry, subEntry) {
        if (this.hideSubmenuIcon(entry.id)) {
          return false
        }

        if (subEntry.icon) {
          return false
        }

        return true
      }
    }
  }
</script>

<style lang="scss" scoped>
  .c__context-wrapper {
    position: relative;

    .c__context-button {
      width: 40px;
      z-index: 10;
      height: 40px;
      display: flex;
      cursor: pointer;
      border-radius: 4px;
      align-items: center;
      justify-content: center;
      background: $lighter-gray-color;

      &:hover {
        background: darken($lighter-gray-color, 5%);
      }

      &.c__context-button--transparent {
        background: rgba($white, .7);

        &:hover {
          background: darken($white, 1%);
        }
      }

      &.c__context-button--middle {
        width: 32px;
        height: 32px;
      }

      &.c__context-button--small {
        width: 32px;
        height: 16px;
      }
    }

    .c__context-menu {
      right: 0;
      top: 35px;
      z-index: 20;
      padding: 8px 0;
      background: white;
      position: absolute;
      border-radius: 4px;
      box-shadow: $box-shadow-context;

      .c__context-entry {
        width: 160px;
        cursor: pointer;
        padding: 10px 16px;

        &.c__context-entry--wide {
          width: 220px;
        }

        &.c__context-entry--submenu-open {
          background: $darker-white;
        }

        &.c__context-entry--highlighted {
          background: yellow;
        }

        &.c__context-entry--disabled {
          background: $darker-white;
          filter: brightness(95%) opacity(50%);
        }

        .c__context-entry-wrapper {
          display: flex;
        }

        &:hover {
          background: $darker-white;
        }

        .c__context-icon {
          width: 16px;
          height: 16px;
          margin-right: 16px;

          img {
            width: 100%;
            height: 100%;
            max-width: 100%;
            max-height: 100%;
          }
        }

        .c__context-title {
          font-size: 14px;
          font-weight: 500;
          color: $lighter-black-color;
          font-family: Roboto, sans-serif;

          &.c__context-title--red {
            color: $red-color;
          }
        }
      }
    }
  }

  .c-submenu-entries {
    width: 100%;
    margin-top: 8px;
    background: $darker-white;

    .c-submenu-entry {
      width: 100%;
      display: flex;
      font-size: 12px;
      font-weight: 500;
      padding: 10px 16px;
      margin-top: 2px;
      color: $lighter-black-color;
      font-family: Roboto, sans-serif;

      &:hover,
      &.c-submenu-entry--selected {
        border-radius: 4px;
        background: darken($darker-white, 5%);
      }

      .c-submenu-entry-icon {
        margin-right: 8px;
      }
    }
  }
</style>

<template>
    <span class="c__modal-wrapper">
        <slot name="button"/>
        <section
          @click.stop=""
          class="c__modal"
          v-show="active">
            <section class="c__modal-window">
                <section class="c__modal-header">
                    <div class="c__modal-title">{{ title }}</div>
                    <div class="c__modal-close"
                         @click.prevent.stop="$emit('close')">
                        <img src="/images/icons/close.svg">
                    </div>
                </section>
                <section class="c__modal-content--outer">
                    <div class="c__modal-content">
                        <slot/>
                    </div>
                </section>
                <section class="c__modal-actions">
                    <slot name="actions"/>
                </section>
            </section>
        </section>
    </span>
</template>

<script>
  export default {
    props: [
      'active',
      'title'
    ],
  }
</script>

<style lang="scss" scoped>
  .c__modal-wrapper {
    position: relative;
  }

  .c__modal {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    position: fixed;
    z-index: 9999;
    align-items: center;
    justify-content: center;
    background: $darkest-blue-color;

    .c__modal-window {
      width: 512px;
      display: flex;
      max-height: 90%;
      background: $white;
      border-radius: 8px;
      padding: 32px 56px;
      position: relative;
      flex-direction: column;

      @media (max-width: $breakpoint-mobile) {
        width: 100%;
        height: 100%;
        max-height: 100%;
        border-radius: 0;
        padding: 16px 24px;
      }

      .c__modal-actions {
        width: 100%;
        height: 40px;
        margin-top: 24px;
      }

      .c__modal-content--outer {
        height: 100%;
        max-height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        margin-top: 16px;
        width: calc(100% + 20px);

        @media (max-width: $breakpoint-mobile) {
          width: 100%;
        }

        .c__modal-content {
          width: 400px;
          overflow-x: hidden;

          @media (max-width: $breakpoint-mobile) {
            width: 100%;
          }
        }
      }
    }
  }

  .c__modal-header {
    width: 100%;
    height: 36px;

    @media (max-width: $breakpoint-mobile) {
      margin-top: 32px;
    }

    .c__modal-title {
      font-size: 24px;
      margin-top: 16px;
      font-weight: 500;
      color: $darkest-blue-color;
      font-family: Roboto, sans-serif;

      @media (max-width: $breakpoint-mobile) {
        font-size: 20px;
      }
    }

    .c__modal-close {
      top: 32px;
      right: 32px;
      cursor: pointer;
      position: absolute;
    }
  }
</style>

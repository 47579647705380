<template>
  <base-modal
    :title="title"
    :active="active"
    @close="cancel">

    <!-- Button -->
    <div
      slot="button"
      class="button-wrapper"
      @click.prevent="active = true">
      <slot/>
    </div>

    <!-- Content -->
    <form class="u-mt--40">
      <div class="form-element">
        <label class="basic">{{ $t('workspaces.shelf_name') }}*</label>
        <text-input
          v-model="rack.name"
          :placeholder="$t('workspaces.shelf_name_placeholder')"
        />
      </div>

      <div class="form-element u-mt--16">
        <tag-input v-model="rack.tags">
          {{ $t('workspaces.tags') }}
        </tag-input>
      </div>

      <div class="form-element u-mt--16">
        <color-selector v-model="rack.color">
          {{ $t('workspaces.shelf_color') }}
        </color-selector>
      </div>
    </form>

    <!-- Actions -->
    <section
      slot="actions"
      class="modal-controls modal-controls--spacing">
      <Button
        theme="default"
        :loading="loading"
        @click="cancel"
        :disable-icon-view-only-on-mobile="true">
        {{ $t('general.cancel') }}
      </Button>
      <Button
        @click="save"
        theme="primary"
        :loading="loading"
        :disable-icon-view-only-on-mobile="true">
        {{ $t('workspaces.save_shelf') }}
      </Button>
    </section>
  </base-modal>
</template>

<script>
  import Vue from 'vue'
  import Helpers from '../../../helpers'
  import APIFactory from '../../../api/factory'
  import Button from '../../global/buttons/Button'
  import TagInput from '../../global/inputs/TagInput'
  import BaseModal from '../../global/modals/BaseModal'
  import TextInput from '../../global/inputs/TextInput'
  import ColorSelector from '../../global/inputs/ColorSelector'

  export default {
    props: [
      'activation',
      'rack'
    ],
    computed: {
      title() {
        return this.rack && this.rack.id
          ? this.$t('workspaces.edit_shelf')
          : this.$t('workspaces.create_shelf')
      }
    },
    data() {
      return {
        active: false,
        loading: false,
      }
    },
    methods: {
      createAPIRequest() {
        return this.rack && this.rack.id
          ? APIFactory.updateRack(this.$route.params.slug, this.$route.params.teamId, this.rack)
          : APIFactory.createRack(this.$route.params.slug, this.$route.params.teamId, this.rack)
      },
      save() {
        this.loading = true
        this.createAPIRequest().then(res => {
          if (res && res.success) {
            Helpers.callToast('is-success', this.$t('workspaces.save_rack_success_message'))
            this.$emit('success')
            this.$router
            .push({
              path: '/' + this.$route.params.slug
                + '/workspaces/'
                + this.$route.params.teamId
                + '/' + res.data.slug
            })
            .catch(err => {
            })
            this.active = false
          }
        }).finally(() => this.loading = false)
      },
      cancel() {
        this.active = false
        this.$emit('success')
      }
    },
    watch: {
      activation: {
        immediate: true,
        handler(value) {
          if (this.active !== value) {
            this.active = value
          }
        }
      },
      active(value) {
        if (this.activation !== value) {
          this.$emit('reset', value)
        }
      }
    },
    components: {
      ColorSelector,
      TextInput,
      BaseModal,
      TagInput,
      Button,
    }
  }
</script>

<template>
  <drop @drop="drop">
    <div
      @click.prevent="select"
      :class="{
        'c__element-wrapper--selected': selected,
        'c__element-wrapper': true,
      }">
      <div class="c__element-container">
        <!-- Icon -->
        <div
          :style="color"
          class="c__element-icon">
          <img :src="icon">
        </div>

        <!-- Description / Title -->
        <div class="c__element-text">
          <div class="c__element-title">{{ element.name }}</div>
          <div class="c__element-subtitle">{{ element.subtitle }}</div>
        </div>
      </div>

      <!-- Actions -->
      <div class="c__element-actions">
        <b-tooltip
          position="is-bottom"
          :delay="tooltipDelay"
          :label="$t('mediacenter.released')">
          <div class="c__element-state" v-if="element.shared">
            <img src="images/icons/release.svg">
          </div>
        </b-tooltip>
        <slot/>
      </div>
    </div>
  </drop>
</template>

<script>
  import RackContext from '../workspace/RackContext'
  import APIFactory from '../../api/factory'
  import Helpers from '../../helpers'
  import {mapGetters} from 'vuex'

  export default {
    props: [
      'selected-elements',
      'routeParam',
      'element',
      'icon'
    ],
    computed: {
      ...mapGetters({
        tooltipDelay: 'ui/tooltipDelay'
      }),
      color() {
        return 'background-color: '
          + (this.element && this.element.color ? this.element.color : '#55a3d9;')
      },
      selected() {
        return this.element
          ? this.element.slug === this.$route.params[this.routeParam]
          : false
      }
    },
    data() {
      return {
        routeParamMapping: {
          rackId: 'workspaces',
          containerId: 'media-center'
        }
      }
    },
    methods: {
      select() {
        let compiledRoute =
          '/' + this.$route.params.slug +
          '/' + this.routeParamMapping[this.routeParam]

        switch (this.routeParam) {
          case 'rackId':
          case 'containerId':
            compiledRoute = compiledRoute + '/' + this.$route.params.teamId
            break
        }

        compiledRoute = compiledRoute + '/' + this.element.slug
        if (this.$route.path !== compiledRoute) {
          this.$router.push(compiledRoute)
        }
      },
      createMoveAPIRequest(objectIds) {
        switch (this.routeParam) {
          case 'rackId':
            return APIFactory.moveFolder(
              this.$route.params.slug,
              this.$route.params.teamId,
              objectIds,
              this.element.id)
          case 'containerId':
            return APIFactory.moveAllMediaObjects(
              this.$route.params.slug,
              this.$route.params.teamId,
              objectIds,
              this.element.id)
        }
        return Promise.resolve()
      },
      drop($event) {
        if (this.routeParam === 'containerId') {
          $event = this.selectedElements && this.selectedElements.length > 0
            ? this.selectedElements
            : [$event]
        }

        this.createMoveAPIRequest($event).then(res => {
          if (res && res.success) {
            switch (this.routeParam) {
              case 'rackId':
                Helpers.callToast('is-success', this.$tc('workspaces.move_folder_success_message', 1))
                break
              case 'containerId':
                Helpers.callToast('is-success', this.$tc('mediacenter.move_object_success_message', 1))
                break
            }
            this.$emit('success')
          }
        })
      }
    },
    components: {
      RackContext
    }
  }
</script>

<style lang="scss" scoped>
  .c__element-wrapper {
    display: flex;
    width: 100%;
    cursor: pointer;
    user-select: none;
    margin-bottom: 8px;
    border-radius: 8px;
    background: $darker-white;
    border: 3px solid transparent;
    justify-content: space-between;

    @media (max-width: $breakpoint-mobile) {
      border: none;
    }

    &.c__element-wrapper--selected {
      border: 3px solid $lighter-blue-color;
      margin-left: 24px;
      margin-right: -12px;

      @media (max-width: $breakpoint-mobile) {
        border: none;
        margin-left: 0;
        margin-right: 0;
      }
    }

    &:hover {
      border: 3px solid $lighter-blue-color;

      @media (max-width: $breakpoint-mobile) {
        border: none;
      }
    }

    .c__element-container {
      display: flex;

      .c__element-icon {
        width: 48px;
        height: 48px;
        display: flex;
        flex-shrink: 0;
        margin: 20px 16px;
        border-radius: 4px;
        align-items: center;
        justify-content: center;
        @apply
          relative;

        img {
          width: 24px;
          height: 24px;
        }

        .subitem {
          @apply
            -right-1
            -bottom-1
            absolute
            text-df-primary;
        }
      }

      .c__element-text {
        display: flex;
        padding: 20px 0;
        flex-direction: column;
        justify-content: center;

        .c__element-title {
          width: 100%;
          font-size: 16px;
          font-weight: 500;
          word-wrap: break-word;
          color: $darker-blue-color;
          font-family: Roboto, sans-serif;
        }

        .c__element-subtitle {
          font-size: 14px;
          color: $dark-gray-color;
          font-family: Roboto, sans-serif;
        }
      }
    }

    .c__element-actions {
      width: 72px;
      height: 48px;
      display: flex;
      margin: 20px 16px;
      align-items: center;
      justify-content: center;

      .c__element-state {
        flex-shrink: 0;
        margin-right: 16px;

        img {
          width: 16px;
          height: 16px;
        }
      }
    }
  }
</style>

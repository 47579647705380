<template>
  <div class="c__radio-input"
       :class="{'c__radio-input--not-selected': !selected}"
       @click.prevent="select">
    <input type="radio" :name="name" :disabled="disabled"/>
    <div class="c__radio-input-outer">
      <div class="c__radio-input-inner"/>
    </div>
    <div class="c__radio-input-label" v-if="label">{{ label }}</div>
    <div>
      <slot/>
    </div>
  </div>
</template>

<script>
export default {
  props: [
    'name',
    'label',
    'value',
    'currentValue',
    'disabled'
  ],
  computed: {
    selected() {
      return this.currentValue === this.value
    }
  },
  methods: {
    select() {
      if (!this.disabled) {
        this.$emit('select', this.value)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.c__radio-input {
  cursor: pointer;
  display: flex;

  input[type="radio"] {
    appearance: none;
    display: none;
  }

  .c__radio-input-outer {
    border: 1px solid $primary-color;
    border-radius: 50%;
    flex-shrink: 0;
    height: 20px;
    width: 20px;

    .c__radio-input-inner {
      background: $primary-color;
      border-radius: 50%;
      margin-left: 2px;
      margin-top: 2px;
      flex-shrink: 0;
      height: 14px;
      width: 14px;
    }
  }

  .c__radio-input-label {
    font-size: 14px;
    margin-left: 8px;
    color: $primary-color;
    font-family: Roboto, sans-serif;
  }

  &.c__radio-input--not-selected {
    .c__radio-input-outer {
      border-color: $lighter-gray-color;
      background: $lighter-gray-color;

      .c__radio-input-inner {
        display: none;
      }
    }

    .c__radio-input-label {
      color: $darker-blue-color;
    }
  }
}
</style>

import {mapGetters} from 'vuex'

export default {
  computed: {
    ...mapGetters({
      apps: 'auth/apps'
    }),
    currentApp() {
      if (this.apps) {
        return this.apps.find(app => app.slug === this.$route.params.slug)
      }
    },
    hasPremiumPlan() {
      return this.currentApp
        && ((this.currentApp.plan
        && this.currentApp.plan.toLowerCase().indexOf('premium') > -1)
        || this.currentApp.trial)
    }
  }
}
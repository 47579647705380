<template>
  <section class="c__chapter-splitview">
    <section class="c__chapter-sidebar" v-if="!isMobileMode || !forceContentView">
      <div class="c__chapter-header"
           v-if="!isMobileMode">
        <div class="c__chapter-subtitle">{{ folder.rack.name }}/</div>
        <div class="c__chapter-title">{{ folder.name }}</div>
      </div>
      <div class="c__chapter-controls"
           :class="{'c__chapter-controls--non-mobile': !isMobileMode}">
        <div class="c__chapter-controls-wrapper">
          <b-tooltip
            :label="$t('designer.back')"
            position="is-right"
            :delay="500">
            <IconButton
              @click="back"
              theme="default"
              icon="images/icons/arrow-left.svg"
            />
          </b-tooltip>
          <b-tooltip
            :label="$t('designer.preview')"
            position="is-right"
            :delay="500">
            <IconButton
              class="u-ml--8"
              theme="default"
              @click="preview"
              icon="images/icons/preview--blue.svg"
              v-if="folder && folder.chapters.length > 1"
              :disabled="folder && folder.chapters.length < 2"
            />
          </b-tooltip>
        </div>
        <div class="c__chapter-controls-wrapper">
          <c-dropdown
            @select="changeStatus"
            :entries="preparedStates">
            <b-tooltip
              position="is-bottom"
              :delay="tooltipDelay"
              :label="$t('workspaces.change_status')">
              <IconButton
                theme="default"
                class="ml-1 mr-1"
                icon="images/icons/activate--blue.svg"
              />
            </b-tooltip>
          </c-dropdown>
          <b-tooltip
            :label="isExpanded ? $t('designer.expand_all') : $t('designer.collapse_all')"
            position="is-bottom"
            :delay="tooltipDelay">
            <Button
              :disable-icon-view-only-on-desktop="true"
              :icon="isExpanded ? 'images/icons/plus.svg' : 'images/icons/minus.svg'"
              @click="$emit('expand', isExpanded)">
              <template v-if="isExpanded">{{ $t('designer.expand_all') }}</template>
              <template v-else>{{ $t('designer.collapse_all') }}</template>
            </Button>
          </b-tooltip>
          <b-tooltip
            :label="fileInformationTooltip"
            position="is-left"
            :delay="tooltipDelay">
            <IconButton
              class="u-ml--8"
              :icon="showViewToggleIcon"
              @click="toggleChapterViewMode"
            />
          </b-tooltip>
          <b-tooltip
            :label="$t('designer.save_changes')"
            position="is-left"
            :delay="tooltipDelay">
            <IconButton
              class="u-ml--8"
              @click="saveFolder"
              :theme="buttonTheme"
              :icon="buttonIcon"
            />
          </b-tooltip>
        </div>
      </div>
      <div :class="{
            	'c__chapter-sidebar-content': true,
            	'c__chapter-sidebar-content--comments-enabled': !isRestricted
            }">
        <div class="c__chapter-sidebar-content-inner">
          <slot name="sidebar"/>
        </div>
      </div>
      <Tasks
        v-if="!isRestricted"
        :tasks="folder.comments"
        @update="updateComments"
      />
    </section>
    <section
      class="c__chapter-content-wrapper"
      v-if="!isMobileMode || forceContentView">
      <slot/>
    </section>
  </section>
</template>

<script>
  import CDropdown from '@/components/global/dropdowns/CDropdown'
  import IconButton from '../global/buttons/IconButton'
  import Button from '../global/buttons/Button'
  import APIFactory from '@/api/factory'
  import Tasks from './tasks/Tasks'
  import {mapGetters} from 'vuex'
  import Helpers from '@/helpers'

  export default {
    props: [
      'folder',
      'forceContentView',
    ],
    computed: {
      ...mapGetters({
        apps: 'auth/apps',
        isMobileMode: 'app/isMobileMode',
        tooltipDelay: 'ui/tooltipDelay'
      }),
      showViewToggleIcon() {
        return this.chapterViewMode === 'contents-hidden'
          ? 'images/icons/list.svg'
          : 'images/icons/list-expanded.svg'
      },
      fileInformationTooltip() {
        return this.chapterViewMode === 'contents-hidden'
          ? this.$t('designer.more_file_info')
          : this.$t('designer.less_file_info')
      },
      isExpanded() {
        return this.folder
          && this.folder.chapters
          && this.folder.chapters.filter(item => item.expanded).length < (this.folder.chapters.length / 2)
      },
      buttonTheme() {
        // return this.folder
        //     && this.folder.dirty ? 'primary' : 'default'
        return 'primary'
      },
      buttonIcon() {
        // return this.folder
        //     && this.folder.dirty
        //     ? 'images/icons/save.svg'
        //     : 'images/icons/save--blue.svg'
        return 'images/icons/save.svg'
      },
      isRestricted() {
        if (this.apps) {
          let app = this.apps.find(app => app.slug === this.$route.params.slug)
          if (app &&
            ((!app.plan.toLowerCase().startsWith('free') && !app.plan.toLowerCase().startsWith('basic'))
              && !app.trial)) {
            return false
          }

          if (app && (app.plan.toLowerCase().startsWith('free') || app.plan.toLowerCase().startsWith('basic')) && app.trial) {
            return false
          }
        }
        return true
      },
      preparedStates() {
        return this.states.map(item => {
          return {
            ...item,
            selected: this.folder.status === item.id
          }
        })
      }
    },
    data() {
      return {
        chapterViewMode: 'contents-hidden',
        states: [
          { id: 'in_progress', label: 'workspaces.context_menu_status_in_progress' },
          { id: 'finished', label: 'workspaces.context_menu_status_finish' },
          { id: 'released', label: 'workspaces.context_menu_status_release' },
          { id: 'locked', label: 'workspaces.context_menu_status_lock' },
          { id: 'archived', label: 'workspaces.context_menu_status_archive' },
        ],
      }
    },
    methods: {
      back() {
        this.$router.push({
          path: '/' + this.$route.params.slug + '/workspaces/'
            + this.$route.params.teamId + '/'
            + this.$route.params.rackId
        })
      },
      toggleChapterViewMode() {
        if (this.chapterViewMode === 'contents-hidden') {
          this.chapterViewMode = 'contents-expanded'
        } else if (this.chapterViewMode === 'contents-expanded') {
          this.chapterViewMode = 'contents-hidden'
        }
        this.$emit('switch-view', this.chapterViewMode)
      },
      updateComments($event) {
        this.$set(this.folder, 'comments', $event)
      },
      saveFolder() {
        this.$emit('save')
      },
      preview() {
        this.$emit('preview')
      },
      changeStatus(id) {
        APIFactory.changeStatus(
          this.$route.params.slug,
          this.$route.params.teamId,
          this.$route.params.folderId, {
            status: id
          }).then(res => {
            if (res && res.success) {
              Helpers.callToast('is-success', this.$t('workspaces.status_changed_successful_message'))
              this.$emit('load')
            }
        })
      }
    },
    components: {
      CDropdown,
      IconButton,
      Tasks,
      Button
    }
  }
</script>

<style lang="scss" scoped>
  .c__chapter-splitview {
    width: 100%;
    height: 100%;
    display: flex;
    background: $light-blue-color;

    .c__chapter-sidebar {
      flex: 40%;
      max-width: 560px;
      overflow: visible;
      padding: 48px 56px 0 56px;
      //min-width: (560px - 80px);
      min-width: 445px;
      background: $light-blue-color;

      @media (max-width: $breakpoint-mobile) {
        padding: 0;
        max-width: 100%;
        min-width: 100%;
      }
    }

    .c__chapter-content-wrapper {
      flex: 60%;
      background: $white;

      @media (max-width: $breakpoint-mobile) {
        width: 100%;
        padding: 0 16px;
      }
    }
  }

  .c__chapter-sidebar-content {
    margin-top: 24px;
    overflow-y: auto;
    padding-right: 17px;
    width: calc(100% + 17px);
    height: calc(100% - 172px);

    @media (max-width: $breakpoint-mobile) {
      width: 100%;
      padding: 0 16px;
      margin-top: 16px;
      height: calc(100% - 72px);
    }

    &.c__chapter-sidebar-content--comments-enabled {
      height: calc(100% - 248px);

      @media (max-width: $breakpoint-mobile) {
        height: calc(100% - 135px);
      }
    }

    .c__chapter-sidebar-content-inner {
      width: 100%;

      @media (max-width: $breakpoint-mobile) {
        max-width: 100%;
      }
    }
  }

  .c__chapter-header {
    color: $darkest-blue-color-2;
    font-family: Roboto, sans-serif;

    .c__chapter-subtitle {
      font-size: 16px;
    }

    .c__chapter-title {
      width: 100%;
      font-size: 32px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  .c__chapter-controls {
    display: flex;
    justify-content: space-between;

    @media (max-width: $breakpoint-mobile) {
      padding: 16px 16px 0;
    }

    .c__chapter-controls-wrapper {
      display: flex;
    }

    &.c__chapter-controls--non-mobile {
      margin-top: 24px;
    }
  }
</style>

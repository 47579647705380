<template>
  <Context
    :transparent="true"
    :entries="entries"
    @select="select"
    :small="true">
    <img src="/images/icons/options.svg">
  </Context>
</template>

<script>
  import Context from '../../global/Context'
  import PlanMixin from '../../../mixins/plan'

  export default {
    props: [
      'page'
    ],
    data() {
      return {
        entries: []
      }
    },
    created() {
      this.entries = [
        {
          index: 0,
          icon: '/images/icons/copy.svg',
          title: 'workspaces.copy',
          function: 'copy'
        },
        {
          index: 3,
          icon: '/images/icons/delete.svg',
          title: 'workspaces.delete',
          function: 'delete'
        },
      ]

      if (this.page.status === 'inactive') {
        this.entries.push({
          index: 1,
          icon: '/images/icons/activate.svg',
          title: 'workspaces.activate',
          function: 'toggleState'
        })
      } else {
        this.entries.push({
          index: 1,
          icon: '/images/icons/activate.svg',
          title: 'workspaces.deactivate',
          function: 'toggleState'
        })
      }

      if (this.hasPremiumPlan) {
        this.entries = this.entries.concat([{
          index: 2,
          icon: '/images/icons/star--blue.svg',
          title: 'designer.show_as_new',
          function: 'toggleAsNew',
        }])
      }

      this.entries = this.entries.sort((i1, i2) => i1.index > i2.index ? 1 : -1)
    },
    methods: {
      select($event) {
        if (this[$event.function]) {
          this[$event.function]()
        }
      },
      toggleAsNew() {
        let entry = this.entries.find(item => item.function === 'toggleAsNew')
        if (entry) {
          this.page.showAsNew = !this.isPageMarkedAsNew()
          if (!this.page.showAsNew) {
            this.page.objects = this.page.objects.map(object => {
              return {
                ...object,
                showAsNew: false,
              }
            })
          }
          this.$set(this, 'page', this.page)
          this.checkShowAsNewState()
        }
      },
      copy() {
        let copiedPage = JSON.parse(JSON.stringify(this.page))
        this.$emit('copy', copiedPage)
      },
      toggleState() {
        let stateEntry = this.entries.find(item => item.title === 'workspaces.deactivate'
          || item.title === 'workspaces.activate')
        if (stateEntry) {
          if (stateEntry.title === 'workspaces.activate') {
            stateEntry.title = 'workspaces.deactivate'
          } else {
            stateEntry.title = 'workspaces.activate'
          }
        }

        if (this.page.status === 'active') {
          this.$set(this.page, 'status', 'inactive')
        } else {
          this.$set(this.page, 'status', 'active')
        }
      },
      delete() {
        this.$emit('delete', this.page.id)
      },
      isPageMarkedAsNew() {
        return this.page.showAsNew || this.page.objects.find(object => object.showAsNew)
      },
      checkShowAsNewState() {
        let entry = this.entries.find(item => item.function === 'toggleAsNew')
        if (entry) {
          entry.title = this.isPageMarkedAsNew()
            ? 'designer.hide_as_new'
            : 'designer.show_as_new'
          this.$forceUpdate()
        }
      }
    },
    watch: {
      page: {
        deep: true,
        handler() {
          this.checkShowAsNewState()
        }
      }
    },
    mixins: [
      PlanMixin
    ],
    components: {
      Context
    }
  }
</script>

import Mediacenter from '../../views/Mediacenter'

export default [
	{
		path: '/:slug/media-center',
		component: Mediacenter,
		meta: {
			auth: true
		}
	},
	{
		path: '/:slug/media-center/:teamId',
		component: Mediacenter,
		meta: {
			auth: true
		}
	},
	{
		path: '/:slug/media-center/:teamId/:containerId',
		component: Mediacenter,
		meta: {
			auth: true
		}
	}
]

<template>
  <div
    class="c-color-designer"
    :style="style">
    <div class="c-color-wrapper">
      <ColorSelector
        v-model="cover.color"
        :borderless="true"
        :folder="folder"
      />
    </div>
  </div>
</template>

<script>
  import ColorSelector from '../../global/inputs/ColorSelector'

  export default {
    props: [
      'folder',
      'chapterId',
    ],
    computed: {
      cover() {
        if (this.folder && this.folder.chapters) {
          if (this.chapterId === 'cover') {
            return this.folder.chapters.find(item => item.type === this.chapterId)
          }
          return this.folder.chapters.find(item => item.id === this.chapterId)
        }
      },
      style() {
        if (this.cover) {
          return 'background: ' + this.cover.color
        }
      }
    },
    components: {
      ColorSelector
    },
  }
</script>

<style lang="scss" scoped>
  .c-color-designer {
    width: 100%;
    height: 100%;
    display: flex;
    border-radius: 8px;
    align-items: flex-end;
    //border: 1px solid $light-gray-color;

    .c-color-wrapper {
      margin: 24px;
      flex-shrink: 0;
      background: $white;
      border-radius: 8px;
      width: calc(100% - 48px);
      box-shadow: $box-shadow-menu;
    }
  }
</style>

<template>
  <div class="c-row u-mt--32">
    <div class="c-col c-col-12">
      <PaymentMethod
        payment="card"
        :stripe="stripe"
        :radio-name="name"
        :current-value="selectedValue"
        :stripe-elements="stripeElements"
        :paymentLabel="$t('signup.payment_type_card')"
        @select="selectedValue = $event"
        @change="$emit('change', $event)"
      />

      <PaymentMethod
        payment="iban"
        :stripe="stripe"
        :radio-name="name"
        :current-value="selectedValue"
        :stripe-elements="stripeElements"
        :paymentLabel="$t('signup.payment_type_iban')"
        @select="selectedValue = $event"
        @change="$emit('change', $event)"
        v-if="country && country.toLowerCase() === 'de'"
      />

<!--      <PaymentMethod-->
<!--        payment="sofort"-->
<!--        :stripe="stripe"-->
<!--        :radio-name="name"-->
<!--        :current-value="selectedValue"-->
<!--        :stripe-elements="stripeElements"-->
<!--        :paymentLabel="$t('signup.payment_type_sofort')"-->
<!--        @select="selectedValue = $event"-->
<!--        @change="$emit('change', $event)"-->
<!--        v-if="country && country.toLowerCase() === 'de'"-->
<!--      />-->
    </div>
  </div>
</template>
<script>
import PaymentMethod from '../payment/PaymentMethod'

export default {
  props: [
    'stripe',
    'country',
    'stripe-elements'
  ],
  data() {
    return {
      name: Math.random(),
      selectedValue: null,
    }
  },
  components: {
    PaymentMethod
  }
}
</script>

import Vue from 'vue'

export default {
	findPlans() {
		return [
			{
				id: 'free',
				title: 'signup.free',
				subtitle: 'signup.free_subtitle',
				checklist: [
					'signup.space_each_plan',
					'signup.free_argument0',
					'signup.free_argument1',
					'signup.free_argument2',
					'signup.free_argument3',
					'signup.free_argument4',
				],
				price_monthly: 0.00,
				price_yearly: 0.00,
			},
			{
				id: 'basic',
				title: 'signup.basic',
				subtitle: 'signup.basic_subtitle',
				checklist: [
					'signup.space_each_plan',
					'signup.basic_argument0',
					'signup.basic_argument1',
					'signup.basic_argument2',
					'signup.basic_argument3',
					'signup.basic_argument4',
					'signup.basic_argument5',
				],
				price_monthly: 9.95,
				price_yearly: 8.955,
			},
			{
				id: 'premium',
				title: 'signup.premium',
				subtitle: 'signup.premium_subtitle',
				checklist: [
					'signup.space_each_plan',
					'signup.premium_argument0',
					'signup.premium_argument1',
					'signup.premium_argument2',
					'signup.premium_argument3',
					'signup.premium_argument4',
					'signup.premium_argument5',
					'signup.premium_argument6',
				],
				price_monthly: 15.95,
				price_yearly: 14.355,
			}
		]
	}
}

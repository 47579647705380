<template>
  <div class="permission__container"
       @click.self="select">
    <div class="permission__text"
         @click.self="select">
      <div class="permission__name"
           @click.self="select">{{ name }}
      </div>
      <div class="permission__description"
           @click.self="select">{{ description }}
      </div>
    </div>
    <div class="permission__action"
         @click.self="select">
      <b-switch
        @input="select"
        v-model="selected"
      />
    </div>
  </div>
</template>

<script>
  import APIFactory from '../../api/factory'
  import Helpers from '../../helpers'

  export default {
    props: [
      'name',
      'value',
      'permission',
      'description',
    ],
    data() {
      return {
        selected: false,
      }
    },
    created() {
      this.selected = this.value
    },
    methods: {
      select(input) {
        this.selected = !this.selected
        if (!(input instanceof MouseEvent)) {
          this.selected = input
        }
        this.$emit('toggle', {
          permission: this.permission,
          allow: this.selected
        })
      }
    },
  }
</script>

<style lang="scss" scoped>
  .permission__container {
    display: flex;
    padding: 1rem;
    user-select: none;
    justify-content: space-between;

    &:hover {
      cursor: pointer;
      background: darken(#fff, 2%);
      border-radius: 4px;
    }

    .permission__text {
      .permission__name {
        font-size: 15px;
        font-weight: bold;
      }

      .permission__description {
        font-size: 14px;
        font-style: italic;
      }
    }

    .permission__action {
      margin-left: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
</style>

<template>
  <SignupSidebar
      :link="plan && plan.id === 'free' ? '/auth/signup/personal' : '/auth/signup/payment'"
      :plans="plans"
      :coupon="signupData.coupon"
      :title="$t('signup.summary_heading')">
    <b-loading :active="loading" :is-full-page="true"/>
    <div class="c-container-wrapper">
      <div class="c-container">
        <template v-if="!isMobile">
          <div class="c-headline-1 u-align-center">{{ $t('signup.summary_heading') }}</div>
          <Progress
              :index="4"
              :plan="plan"
          />
        </template>
        <form class="c-form c-form-responsive--mt-48-8">
          <div class="c-row">
            <div class="c-col c-col-12 c-font-size--14 u-font-color-darker-blue">
              <div class="c-headline-2">{{ $t('signup.personal_heading') }}</div>
              <div class="u-mt--8">{{ signupData.firstname }} {{ signupData.lastname }}</div>

              <template v-if="plan.id !== 'free'">
                <div class="c-headline-2 u-mt--32">{{ $t('signup.company_heading') }}</div>
                <div class="u-mt--8">{{ signupData.company }}</div>
                <div class="u-mt--8">{{ signupData.vat_id }}</div>
              </template>
              <div class="u-mt--8">{{ signupData.street }}</div>
              <div class="u-mt--8">{{ signupData.postal_code }} {{ signupData.city }}</div>
              <div class="u-mt--8">{{ country }}</div>
              <div class="u-mt--8" v-show="signupData.mediation">{{ signupData.mediation }}</div>

              <template v-if="signupData.payment_type">
                <div class="c-headline-2 u-mt--32">
                  {{ $t('signup.payment_heading') }}
                </div>
                <div class="u-mt--8">{{ $t('signup.payment_type_' + signupData.payment_type) }}</div>
              </template>

              <div class="c-headline-2 u-mt--32">{{ $t('signup.cost') }}</div>
              <div class="u-mt--8">{{ $t('signup.price_incl_vat') }}: {{ price.monthly | price }}
                ({{ $t('signup.monthly') }})
              </div>
              <div class="u-mt--8">{{ $t('signup.price_incl_vat') }}: {{ price.yearly | price }} ({{
                  $t('signup.yearly')
                }})
              </div>
              <template v-if="signupData && signupData.coupon">
                <div class="u-mt--8">{{ $t('signup.coupon_code') }}: {{ signupData.coupon.coupon }}</div>
              </template>

              <div class="u-mt--40 u-box-align-center">
                <b-checkbox v-model="accept_order"/>
                <div v-html="escapeChars($t('signup.order_data_processing', {url}))"/>
              </div>
              <div class="u-mt--8 u-box-align-center">
                <b-checkbox v-model="accept_terms"/>
                <div v-html="escapeChars($t('signup.terms_of_service', {url}))"/>
              </div>
              <div class="u-mt--8 u-box-align-center" v-if="plan.id !== 'free'">
                <b-tooltip
                    :label="$t('signup.company_signup_tooltip')"
                    :delay="tooltipDelay"
                    position="is-top"
                    multilined>
                  <b-checkbox v-model="accept_company"/>
                  <div v-html="escapeChars($t('signup.terms_company'))"/>
                  <img src="images/icons/info.svg" class="info_icon">
                </b-tooltip>
              </div>
            </div>
          </div>
          <div class="c-row u-mt--32 u-mb--16">
            <div class="c-col c-col-12 modal-controls modal-controls--spacing">
              <Button
                  :disable-icon-view-only-on-mobile="true"
                  theme="default"
                  @click="back">
                {{ $t('signup.back') }}
              </Button>
              <Button
                  theme="primary"
                  @click="submit"
                  :disable-icon-view-only-on-mobile="true"
                  :disabled="disableSignup">
                <template v-if="$route.query && $route.query.slug">{{ $t('signup.upgrade_now') }}</template>
                <template v-else>{{ $t('signup.signup_now') }}</template>
              </Button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </SignupSidebar>
</template>

<script>
import SignupSidebar from '../signup/SignupSidebar'
import Button from '../global/buttons/Button'
import APIFactory from '../../api/factory'
import Helpers from '../../helpers'
import Progress from './Progress'
import {mapGetters} from 'vuex'

export default {
  props: [
    'plans',
    'signup-data',
  ],
  computed: {
    ...mapGetters({
      isMobile: 'app/isMobileMode',
      tooltipDelay: 'ui/tooltipDelay'
    }),
    plan() {
      if (this.plans) {
        return this.plans.find(item => item.id === this.$route.query.plan)
      }
    },
    price() {
      if (this.signupData
          && this.signupData.coupon
          && this.signupData.coupon.free) {
        return {
          monthly: 0,
          yearly: 0
        }
      }

      if (this.plan && this.$route.query.users) {
        let payment_interval = 'monthly'
        if (this.$route.query.payment_interval) {
          payment_interval = this.$route.query.payment_interval
        }
        return {
          monthly: this.plan['price_' + payment_interval] * this.$route.query.users,
          yearly: this.plan['price_' + payment_interval] * this.$route.query.users * 12
        }
      }
    },
    country() {
      // TODO Add more languages
      if (this.signupData.country_short_code || this.signupData.country) {
        switch ((this.signupData.country_short_code || this.signupData.country).toLowerCase()) {
          case 'de':
            return this.$t('app.country_germany')
          case 'at':
            return this.$t('app.country_austria')
          case 'ch':
            return this.$t('app.country_switzerland')
        }
      }
    },
    disableSignup() {
      return !this.accept_terms || !this.accept_order || (!this.accept_company && this.plan.id !== 'free')
    }
  },
  data() {
    return {
      loading: false,
      accept_terms: false,
      accept_order: false,
      accept_company: false,
      url: 'https://www.digital-folder.com/wp-content/uploads/2020/06/ADV-digital-optimisation-gross-partner.pdf',
    }
  },
  created() {
    this.readFromQuery()
    this.validateSignupRequest()
  },
  methods: {
    escapeChars(value) {
      return value.replaceAll('\\', '')
    },
    readFromQuery() {
      if (this.$route.query && this.$route.query.json) {
        this.$emit('submit', JSON.parse(decodeURIComponent(this.$route.query.json)))
      }
    },
    validateSignupRequest() {
      this.$nextTick(() => {
        let signupValidation = {}
        if (!this.plan || (this.plan && this.plan.id !== 'free')) {
          if (!this.signupData.coupon) {
            signupValidation['/auth/signup/payment'] = ['pm', 'payment_type']
          }
          signupValidation['/auth/signup/company'] = ['company', 'street', 'postal_code', 'city']
        }

        signupValidation['/auth/signup/personal'] =
            this.$route.query && this.$route.query.slug
                ? ['firstname', 'lastname', 'email']
                : ['firstname', 'lastname', 'email', 'password']

        for (let route in signupValidation) {
          let arr = signupValidation[route]
          if (arr && arr.filter(item => this.signupData[item]).length !== arr.length) {
            this.$router.push({path: route, query: this.$route.query})
          }
        }
      })
    },
    submit() {
      this.signupData.country_short_code = JSON.parse(JSON.stringify(this.signupData.country))
      this.signupData.country = this.country
      this.signupData.plan = this.$route.query.plan
      this.signupData.users = this.$route.query.users || 1
      this.signupData.language = this.$route.query.lang || 'de'
      this.signupData.payment_interval = this.$route.query.payment_interval
          ? this.$route.query.payment_interval
          : 'monthly'

      this.loading = true

      // Handle Upgrade/Downgrade
      if (this.$route.query && this.$route.query.slug) {
        APIFactory.upgradeApp(this.$route.query.slug, this.signupData).then(res => {
          if (res && res.success) {
            Helpers.callToast('is-success', this.$t('signup.upgrade_success'))
            this.$store.commit('auth/updatePlan', {
              slug: this.$route.query.slug,
              plan: res.data.plan
            })
            this.$router.push('/' + this.$route.query.slug + '/settings/plans')
          }
        }).finally(() => this.loading = false)
        return
      }

      // Handle signup
      APIFactory.signup(this.signupData).then(res => {
        if (res && res.success) {
          Helpers.callToast('is-success', this.$t('signup.signup_success'))
          this.$router.push('/auth/login')
        }
      }).finally(() => this.loading = false)
    },
    back() {
      return this.plan && this.plan.id === 'free'
          ? this.hrefTo('/auth/signup/personal')
          : this.hrefTo('/auth/signup/payment')
    },
    hrefTo(link) {
      this.$router
          .push({
            path: link,
            query: this.$route.query
          })
          .catch(() => {
          })
    }
  },
  components: {
    SignupSidebar,
    Progress,
    Button
  }
}
</script>

<style lang="scss" scoped>
.u-font-color-darker-blue {
  color: $darker-blue-color;
}
</style>

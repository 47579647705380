<template>
  <div class="c__object-type-wrapper">
    <label class="basic">
      {{ $t('mediacenter.object_type') }}
    </label>
    <radio-group-input
      :value="type"
      :values="values"
      :disabled="disabled"
      @input="handleRadioInput"
    />
    <div class="advice u-mt--8" v-if="advice">{{ advice }}</div>
    <div class="c__object-type-content">
      <section v-if="type === 'file'">
        <media-center-input
          :object="object"
          v-model="object.data"
          :disabled="disabled"
          :title="$t('mediacenter.file_upload')"
          :label="$t('mediacenter.file_upload_description')"
          @changedName="$emit('changedName', $event)"
          @reset="data = {}"
        />
      </section>
      <section v-if="type === 'link'">
        <label class="basic expanded">{{ $t('mediacenter.website_url') }}</label>
        <input
            v-model="object.data.value"
            class="basic expanded"
            :placeholder="$t('mediacenter.url')"
        />
      </section>
    </div>
  </div>
</template>

<script>
import RadioGroupInput from '../global/inputs/RadioGroupInput'
import MediaCenterInput from '../global/inputs/MediaCenterInput'

export default {
  props: [
    'value',
    'object',
    'advice',
    'disabled'
  ],
  data() {
    return {
      oldData: {},
      values: [
        {id: 'file', label: this.$t('mediacenter.file_radio')},
        {id: 'link', label: this.$t('mediacenter.link_radio')}
      ],
      type: 'file',
      data: {},
    }
  },
  created() {
    if (this.value) {
      this.type = this.value.type
      this.data = {
        mime_type: this.value.mime_type,
        value: this.value.value,
        size: this.value.size,
      }
    }
  },
  methods: {
    handleRadioInput($event) {
      this.type = $event
      // Switch contents from old to new
      let temporaryData = JSON.parse(JSON.stringify(this.oldData))
      this.oldData = JSON.parse(JSON.stringify(this.data))
      this.data = temporaryData ? temporaryData : {}
      this.$emit('typeChanged', $event)
    }
  },
  watch: {
    data: {
      deep: true,
      immediate: true,
      handler() {
        let newData = {
          extension: this.data.extension,
          content: this.data.content,
          value: this.data.value,
          size: this.data.size,
          path: this.data.path,
          type: this.type,
        }
        this.$emit('input', newData)
        this.$emit('typeChanged', this.type)
      }
    },
    type: {
      immediate: true,
      handler() {
        this.$emit('typeChanged', this.type)
      }
    }
  },
  components: {
    MediaCenterInput,
    RadioGroupInput
  }
}
</script>

<style lang="scss" scoped>
.c__object-type-wrapper {
  .c__object-type-content {
    margin-top: 16px;
  }
}

.advice {
  color: $red-color;
  font-size: 12px;
  font-weight: 500;
  font-family: Roboto, sans-serif;
}
</style>

<template>
  <div class="c__color-input">
    <input
        class="basic"
        :disabled="disabled"
        placeholder="#CDCDCD"
        v-model="selectedColor"
        @click.prevent.stop=""
    />
    <div
        class="c__color-input-preview"
        :style="background"
    />
  </div>
</template>

<script>
export default {
  props: [
    'value',
    'disabled'
  ],
  computed: {
    background() {
      let color = 'transparent'
      if (this.selectedColor) {
        color = this.selectedColor.charAt(0) !== '#'
            ? '#' + this.selectedColor
            : this.selectedColor
      }
      return 'background-color: ' + color + ';'
    }
  },
  data() {
    return {
      selectedColor: null
    }
  },
  watch: {
    value: {
      immediate: true,
      handler() {
        this.selectedColor = this.value
      }
    },
    selectedColor() {
      let color = this.selectedColor
      if (color && color.charAt(0) === '#') {
        color = color.substring(1)
      }
      this.$emit('select', color)
    }
  }
}
</script>

<style lang="scss" scoped>
.c__color-input {
  position: relative;

  .c__color-input-preview {
    top: 16px;
    right: 8px;
    width: 24px;
    height: 24px;
    background: blue;
    border-radius: 4px;
    position: absolute;
    border: 1px solid $light-blue-color;
  }
}
</style>

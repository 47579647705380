<template>
  <div
    class="c__menu-entry"
    @click.prevent="selectEntry">
    <div :class="{
      'c__menu-indicator': true,
      'c__menu-indicator--active': selected}"
    />
    <div class="c__menu-icon">
      <img :src="iconPath"/>
    </div>
    <div class="c__menu-title"
         :class="{'c__menu-title--active': selected}">
      {{ title }}
    </div>
  </div>
</template>

<script>
  export default {
    props: [
      'icon',
      'link',
      'title',
      'external',
      'function',
    ],
    computed: {
      iconPath() {
        return this.selected
          ? this.icon.split('.').join('--active.')
          : this.icon
      },
      selected() {
        return this.$route.path.indexOf(this.link) > -1
      }
    },
    methods: {
      selectEntry() {
        this.$store.commit('app/setForceShowMenu', false)

        if (this.function) {
          return this.function()
        }

        if (this.external) {
          window.open(this.link, '_blank')
          return
        }
        this.$store.commit('app/setLastURL', this.$route.path)
        this.$router.push({
          path: this.link,
          query: Object.assign(this.$route.query, {
            lang: this.$i18n.locale
          })
        })
        .catch(err => {})
      }
    }
  }
</script>

<style lang="scss" scoped>
  .c__menu-entry {
    display: flex;
    cursor: pointer;
    min-height: 56px;
    margin-bottom: 8px;
    align-items: center;

    &:hover {
      .c__menu-indicator {
        background: $primary-color;
      }
    }

    .c__menu-indicator {
      width: 7px;
      min-height: 56px;
      background: $white;
      border-top-right-radius: 96px;
      border-bottom-right-radius: 96px;

      &.c__menu-indicator--active {
        background: $primary-color;
      }
    }

    .c__menu-icon {
      margin-left: 16px;

      img {
        width: 24px;
        height: 24px;
      }
    }

    .c__menu-title {
      opacity: 0.9;
      font-size: 16px;
      font-weight: 500;
      margin-left: 24px;
      color: $darker-blue-color;
      font-family: Roboto, 'sans-serif';

      &.c__menu-title--active {
        color: $primary-color;
      }
    }
  }
</style>

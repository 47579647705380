<template>
  <div
    :class="{
      'c-support-chat-wrapper': true,
      'c-support-chat-wrapper--navi-mode': naviMode
    }"
    @click.stop="visible = !visible">
    <img :src="icon"/>
    <div
      class="c-support-chat"
      v-show="visible"
      @click.stop="">
      <div class="c-support-chat-header">
        <div class="icon">
          <img src="images/headset.jpg">
        </div>
        <template v-if="!sent">
          <div class="title">{{ $t('general.hello') }}</div>
          <div class="subtitle">{{ $t('general.greeting') }}</div>
        </template>
        <template v-else>
          <div class="title">{{ $t('general.thank') }}</div>
          <div class="subtitle">{{ $t('general.answer_soon') }}</div>
        </template>
      </div>
      <form
        v-if="!sent"
        @submit.prevent="submit"
        class="c-support-chat-content">
        <label class="label basic">{{ $t('signup.fullname') }}*</label>
        <input
          required
          type="text"
          class="basic u-fw"
          v-model="supportMessage.name"
          :placeholder="$t('signup.fullname_placeholder')"
        />
        <label class="label basic u-mt--16">{{ $t('signup.email_address') }}*</label>
        <input
          required
          type="email"
          class="basic u-fw"
          v-model="supportMessage.email"
          :placeholder="$t('signup.email_address_placeholder')"
        />

        <label class="label basic u-mt--16">{{ $t('general.message') }}*</label>
        <textarea
          required
          class="basic u-fw"
          v-model="supportMessage.message"
          :placeholder="$t('general.message_placeholder')"
        />

        <label class="label basic u-mt--4">{{ $t('general.captcha_question', {a: questionValueA, b: questionValueB}) }}*</label>
        <input
          required
          class="basic u-fw"
          v-model="questionValue"
          :placeholder="$t('general.captcha_answer')"
        />

        <Button
          :disable-icon-view-only-on-mobile="true"
          class="u-mt--8 u-fw"
          :loading="loading"
          theme="primary"
          type="submit">
          {{ $t('general.send') }}
        </Button>
      </form>
    </div>
  </div>
</template>

<script>
  import Button from '@/components/global/buttons/Button'
  import APIFactory from '../../../api/factory'
  import Helpers from '../../../helpers'

  export default {
    props: [
      'navi-mode'
    ],
    computed: {
      icon() {
        if (this.naviMode) {
          return !this.visible
            ? 'images/icons/support--blue.png'
            : 'images/icons/close--blue.svg'
        }

        return !this.visible
          ? 'images/icons/support--white.png'
          : 'images/icons/close--white.svg'
      }
    },
    data() {
      return {
        sent: false,
        visible: false,
        loading: false,
        questionValue: null,
        supportMessage: {},
        questionValueA: null,
        questionValueB: null,
      }
    },
    created() {
      this.questionValueA = (Math.random() * 100).toFixed(0)
      this.questionValueB = (Math.random() * 100).toFixed(0)
    },
    mounted() {
      window.addEventListener('click', this.closeDialog)
    },
    beforeDestroy() {
      window.removeEventListener('click', this.closeDialog)
    },
    methods: {
      closeDialog() {
        this.visible = false
      },
      submit() {
        console.log(this.questionValueA + this.questionValueB)
        if ((this.questionValueA + this.questionValueB) !== this.questionValue) {
          Helpers.callToast('is-danger', this.$t('general.captcha_answer_wrong'))
        }
        this.loading = true
        this.supportMessage.questionValue = this.questionValue
        this.supportMessage.questionValueA = this.questionValueA
        this.supportMessage.questionValueB = this.questionValueB

        APIFactory.sendSupportRequest(this.supportMessage).then(res => {
          if (res && res.success) {
            this.sent = true
          }
        }).finally(() => this.loading = false)
      }
    },
    components: {
      Button
    }
  }
</script>

<style lang="scss" scoped>
  .c-support-chat-wrapper {
    width: 64px;
    height: 64px;
    cursor: pointer;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    user-select: none;
    background: $primary-color;
    box-shadow: $box-shadow-outer;

    &.c-support-chat-wrapper--navi-mode {
      background: $white;
      box-shadow: none;
      height: 40px;
      width: 40px;

      img {
        max-width: 28px;
        max-height: 28px;
      }
    }

    img {
      max-width: 32px;
      max-height: 32px;
    }

    .c-support-chat {
      right: 32px;
      bottom: 104px;
      width: 350px;
      max-width: 100%;
      position: fixed;
      background: white;
      border-radius: 8px;
      box-shadow: $box-shadow-outer;
      border: 2px solid $primary-color;

      .c-support-chat-content {
        padding: 16px;

        input,
        textarea {
          margin-top: 0 !important;
        }
      }

      .c-support-chat-header {
        padding: 16px;
        color: $white;
        padding-top: 56px;
        position: relative;
        background: $primary-color;

        .title,
        .subtitle {
          color: $white;
        }

        .icon {
          left: 0;
          right: 0;
          top: -48px;
          width: 96px;
          height: 96px;
          margin: auto;
          border-radius: 50%;
          position: absolute;
          background-color: $white;
          border: 2px solid $primary-color;

          img {
            max-width: 100%;
            max-height: 100%;
            border-radius: 50%;
          }
        }
      }
    }
  }
</style>

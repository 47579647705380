export default {
  namespaced: true,
  state: {
    helpContents: [],
    genericView: false,
    toggleBubble: false,
  },
  mutations: {
    setHelpContents(state, helpContents) {
      state.helpContents = helpContents
    },
    setGenericView(state, genericView) {
      state.genericView = genericView
    },
    setToggleBubble(state, toggleBubble) {
      state.toggleBubble = toggleBubble
    }
  },
  getters: {
    toggleBubble: (state) => state.toggleBubble,
    helpContents: (state) => state.helpContents,
    genericView: (state) => state.genericView,
  }
}
<template>
  <div class="help-content">
    <div class="title">
      <slot />
    </div>
    <div
      class="description"
      v-if="parsedDescription"
      v-html="$t(parsedDescription)">
    </div>
    <video
      controls
      v-if="link"
      :src="videoSource">
      Your browser does not support the video tag.
    </video>
  </div>
</template>

<script>
  export default {
    props: [
      'link',
      'description',
    ],
    computed: {
      videoSource() {
        return process.env.VUE_APP_API_URL + this.link
      },
      parsedDescription() {
        return this.$t(this.description).replaceAll('\n', '<br>')
      }
    }
  }
</script>

<style lang="scss" scoped>
  .help-content {
    @apply
      p-3
      rounded-lg
      bg-df-lightest-primary;

    .title {
      @apply
        mb-0
        text-base;
    }

    .description {
      @apply
        py-2
        text-sm;
    }

    video {
      @apply
        w-full
        h-full
        rounded-lg
        max-w-full
        max-h-full;
    }
  }
</style>
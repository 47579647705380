import i18n from '@/lang'
import $store from '@/store'
import $router from '@/router'
import $http from 'superagent'
import Helpers from '../helpers'

export default {
	get(url) {
		return $http
			.get(this.prepare(url))
			.set('Accept', 'application/json')
			.set('api-language', this.language())
			.set('api-token', $store.getters['auth/token'])
			.then(res => {
				return res.body
			})
			.catch(error => {
				this.handleErrorResponse(error)
			})
	},
	postFormData(url, formData) {
		let request = $http
			.post(this.prepare(url))
			.set('Accept', 'application/json')
			.set('api-language', this.language())
			.set('api-token', $store.getters['auth/token'])
			.send(formData)

		return request.then(res => {
			return res.body
		}).catch(error => {
			this.handleErrorResponse(error)
		})
	},
	post(url, body, progressHandler) {
		let request = $http
			.post(this.prepare(url))
			.set('Accept', 'application/json')
			.set('api-language', this.language())
			.set('Content-Type', 'application/json')
			.set('api-token', $store.getters['auth/token'])
			.send(body)

		if (progressHandler) {
			request = request.on('progress', progressHandler)
		}

		return request.then(res => {
			return res.body
		})
		.catch(error => {
			this.handleErrorResponse(error)
		})
	},
	download(url) {
		return $http
			.get(this.prepare(url))
			.responseType('arraybuffer')
			.set('api-language', this.language())
			.set('api-token', $store.getters['auth/token'])
			.then(res => {
				return res.body
			})
			.catch(error => {
				this.handleErrorResponse(error)
			})
	},
	postDownload(url, body) {
		return $http
			.post(this.prepare(url))
			.responseType('arraybuffer')
			.set('Accept', 'application/json')
			.set('Content-Type', 'application/json')
			.set('api-language', this.language())
			.set('api-token', $store.getters['auth/token'])
			.send(body)
			.then(res => {
				if (res && res.body) {
					let blob = new Blob([res.body])
					let element = document.createElement('a')
					if (element) {
						element.href = window.URL.createObjectURL(blob)
						element.download = "mediacenter-download.zip"
						element.click()
						element.remove()
					}
				}
			})
			.catch(error => {
				this.handleErrorResponse(error)
			})
	},
	handleErrorResponse(error) {
		if (error.response
			&& error.response.body) {

			let message = error.response.body.error
			if (!message && error.response.body.message) {
				message = i18n.t('app.unknown_error')
			}
			Helpers.callToast('is-danger', i18n.t(message), 3000)
		}

		if (error.response && 401 === error.response.statusCode) {
			$router
				.push({path: '/auth/login'})
				.catch(err => console.log(err))
			return
		}

		if (error.response
			&& error.response.body
			&& error.response.body.message
			&& error.response.body.message === 'app.maintenance') {
			$router.push({
				path: '/maintenance'
			})
		}
		return error
	},
	prepare(url) {
		return process.env.VUE_APP_API_URL + '/api' + url
	},
	language() {
		return i18n.locale
	}
}

<template>
  <div class="folder-layout">
    <b-tooltip
      multilined
      :key="title"
      :delay="500"
      :label="title"
      position="is-bottom">
      <div class="folder-header">{{ title }}</div>
    </b-tooltip>

    <div class="folder-controls">
      <div class="flex items-center gap-2 flex-row flex-row-reverse">
        <FolderModal
          :folder="newFolder"
          :suggestableTags="suggestableTags"
          @success="$emit('success')">
          <c-button
            icon="/images/icons/add.svg">
            {{ $t('workspaces.create_folder') }}
          </c-button>
        </FolderModal>

        <c-button
          :outline="showTemplates"
          @click.prevent="toggleTemplates"
          icon="images/icons/rework/template.svg"
          v-if="hasPremiumPlan && !isMobileMode">
          {{ $t('workspaces.folderTemplates') }}
        </c-button>
      </div>

      <div class="flex flex-row-reverse items-center">
        <Dropdown
          :borderless="true"
          :values="folderSortMethods"
          :default-value="lastSortMethodFolder"
          @select="$emit('sort', $event)"
        />
        <SearchInput
          class="ml-2"
          :placeholder="$t('workspaces.search_shelf')"
          @input="$emit('search', $event)"
        />
      </div>
    </div>
    <section class="folder-grid">
      <slot/>
    </section>
  </div>
</template>

<script>
  import {mapGetters} from 'vuex'
  import PlanMixin from '@/mixins/plan'
  import FolderModal from './modals/FolderModal'
  import Dropdown from '../global/dropdowns/Dropdown'
  import SearchInput from '../global/inputs/SearchInput'

  export default {
    props: [
      'rack',
      'templates',
      'showTemplates',
      'suggestableTags',
      'folderSortMethods',
    ],
    computed: {
      ...mapGetters({
        isMobileMode: 'app/isMobileMode',
        lastSortMethodFolder: 'ui/lastSortMethodFolder',
      }),
      title() {
        if (this.showTemplates) {
          return this.$t('workspaces.folderTemplates') + ' (' + (this.templates ? this.templates.length : 0) + ')'
        }

        if (this.rack) {
          return this.rack.name + ' (' + this.rack.folder_count + ')'
        }
      },
    },
    data() {
      return {
        newFolder: {
          display_type: 'folder',
          rack_id: this.rack.id,
          order_type: 'number',
          template: null,
          partners: [],
        },
      }
    },
    methods: {
      toggleTemplates() {
        this.$emit('showTemplates', !this.showTemplates)
      }
    },
    mixins: [
      PlanMixin
    ],
    components: {
      Dropdown,
      FolderModal,
      SearchInput,
    }
  }
</script>

<style lang="scss" scoped>
  .folder-layout {
    @apply
      w-full
      h-full;

    .folder-header {
      @apply
        h-full
        text-2xl
        max-h-18
        break-all
        font-medium
        overflow-y-hidden;

      @media (max-width: $breakpoint-mobile) {
        display: none;
      }
    }

    .folder-controls {
      @apply
        flex
        mt-6
        gap-4
        items-center
        justify-between;

      @media (max-width: $breakpoint-mobile) {
        @apply
          mt-0;
      }
    }

    .folder-grid {
      @apply
        flex
        w-auto
        overflow-y-auto;
      flex-flow: row wrap;
      align-content: flex-start;
      height: calc(100% - 124px);
      margin: 24px -34px 24px -12px;

      @media (max-width: $breakpoint-mobile) {
        margin: 24px 0 0;
        height: calc(100% - 88px);
      }

      &:after {
        flex: auto;
        content: "";
      }
    }
  }
</style>

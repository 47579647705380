<template>
  <div class="c__media-grid-controls" v-if="selectedItems && selectedItems.length > 0">
    <div class="c__media-grid-control-container">
      <div class="counter">{{ selectedItems.length }}</div>
      <div class="text">{{ $t('designer.selected_files') }}</div>
    </div>
    <div class="c__media-grid-control-container">
      <Button
        @click="deleteAll"
        theme="default-error"
        icon="/images/icons/delete.svg">
        {{ $t('workspaces.delete') }}
      </Button>
      <Button
        @click="moveAll"
        icon="/images/icons/move.svg"
        class="margin-bt-left">
        {{ $t('workspaces.move') }}
      </Button>
      <Button
        @click="downloadAll"
        class="margin-bt-left"
        icon="/images/icons/download.svg">
        {{ $t('workspaces.download') }}
      </Button>
    </div>

    <!-- Modals -->
    <ConfirmModal
      v-if="!!confirmDialogData"
      :active="!!confirmDialogData"
      :title="confirmDialogData.title"
      @close="confirmDialogData = null"
      :message="confirmDialogData.message"
      :function="confirmDialogData.function"
    />
    <MoveModal
      v-if="!!moveDialogData"
      :containers="containers"
      :active="!!moveDialogData"
      @close="moveDialogData = null"
      @success="$emit('success')"
      :object_ids="moveDialogData.object_ids"
    />
  </div>
</template>

<script>
  import Helpers from '../../helpers'
  import APIFactory from '../../api/factory'
  import MoveModal from './modals/MoveModal'
  import Button from '../global/buttons/Button'
  import ConfirmModal from '../global/modals/ConfirmModal'

  export default {
    props: [
      'containers',
      'selected-items',
    ],
    data() {
      return {
        confirmDialogData: null,
        moveDialogData: null,
      }
    },
    methods: {
      deleteAll() {
        this.confirmDialogData = {
          title: this.$t('mediacenter.remove_object_confirm_title'),
          message: this.$t('mediacenter.remove_object_confirm_message'),
          function: () => {
            return APIFactory.removeAllMediaObjects(this.$route.params.slug,
              this.$route.params.teamId,
              this.selectedItems).then(res => {
              if (res && res.success) {
                if (!res.data?.deletable) {
                  Helpers.callToast('is-danger', this.$t('mediacenter.media_in_use'))
                  this.$emit('success')
                  return
                }
                Helpers.callToast('is-success', this.$t('mediacenter.remove_object_success_message'))
                this.$emit('success')
              }
            })
          }
        }
      },
      moveAll() {
        this.moveDialogData = {
          object_ids: this.selectedItems
        }
      },
      downloadAll() {
        APIFactory.downloadAllMediaObjects(this.$route.params.slug,
          this.$route.params.teamId,
          this.selectedItems)
      }
    },
    components: {
      ConfirmModal,
      MoveModal,
      Button
    }
  }
</script>

<style lang="scss" scoped>
  .c__media-grid-controls {
    bottom: 0;
    left: -56px;
    height: 94px;
    display: flex;
    background: $white;
    position: absolute;
    padding: 28px 56px;
    align-items: center;
    justify-content: space-between;
    width: calc(100% + (2 * 56px));
    box-shadow: $box-shadow-media-center-control;

    @media (max-width: $breakpoint-mobile) {
      display: none;
    }

    .c__media-grid-control-container {
      display: flex;
      align-items: center;

      .counter {
        width: 32px;
        height: 32px;
        color: $white;
        display: flex;
        flex-shrink: 0;
        font-size: 16px;
        font-weight: 500;
        border-radius: 50%;
        font-family: Roboto;
        align-items: center;
        justify-content: center;
        background: $primary-color;
      }

      .text {
        font-size: 14px;
        margin-left: 8px;
        font-family: Roboto;
        color: $darker-blue-color;
      }

      .margin-bt-left {
        margin-left: 8px;
      }
    }
  }
</style>

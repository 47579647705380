<template>
  <router-link
    tag="div"
    :to="link"
    class="c-reference-wrapper u-mt--8">
    <div class="c-reference">
      <div class="c-reference-subtitle">{{ rack }} / {{ folder }}</div>
      <div class="c-reference-title">{{ elementType }} {{ referenceElement }}</div>
    </div>
  </router-link>
</template>

<script>
export default {
  props: [
    'rack',
    'rackId',
    'folder',
    'folderId',
    'referenceId',
    'referenceType',
    'referenceElement',
  ],
  computed: {
    link() {
      let query = ''
      switch (this.referenceType) {
        case 'cover':
          query = '?cv=' + this.referenceId
          break
        case 'chapter':
          query = '?ch=' + this.referenceId
          break
        case 'page':
          query = '?pg=' + this.referenceId
          break
      }

      return '/' + this.$route.params.slug + '/workspaces/' + this.$route.params.teamId + '/'
        + this.rackId + '/' + this.folderId + '/designer' + query
    },
    elementType() {
      switch (this.referenceType) {
        case 'cover':
          return this.$t('designer.cover')
        case 'chapter':
          return this.$t('designer.chapter')
        case 'page':
          return this.$t('designer.page')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.c-reference-wrapper {
  display: flex;
  cursor: pointer;
  min-height: 66px;
  align-items: center;
  justify-content: center;
}

.c-reference {
  min-height: 64px;
  padding: 8px 16px;
  border-radius: 4px;
  width: calc(100% - 2px);
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15);

  .c-reference-subtitle {
    font-size: 12px;
    line-height: 24px;
    font-family: Roboto;
    color: $darker-blue-color;
  }

  .c-reference-title {
    text-decoration: underline;
    color: $primary-color;
    font-family: Roboto;
    font-size: 14px;
    line-height: 24px;
  }
}
</style>

<template>
    <MenuTaskBar>
        <LoadingComponent :load="loading">
            <TitledContainer
                :title="title"
                :has-controls="true">
                <form class="c-form" @submit.prevent="save">
                    <div class="c-row">
                        <div class="c-col c-col-4 coupon-card">
                            <div class="c-form-element">
                                <div class="c-form-label">Name</div>
                                <input
                                    required
                                    type="text"
                                    class="c-form-input"
                                    v-model="coupon.name"
                                    placeholder="Gutscheinname hier eintragen"
                                />
                            </div>
                            <div class="c-form-element u-mt--16">
                                <div class="c-form-label">Gutscheinlänge</div>
                                <div class="u-mt--16">
                                    <div>
                                        <b-radio
                                            name="discount"
                                            native-value="2"
                                            v-model="coupon.discount">
                                            2 Monate
                                        </b-radio>
                                    </div>
                                    <div class="u-mt--8">
                                        <b-radio
                                            name="discount"
                                            native-value="4"
                                            v-model="coupon.discount">
                                            4 Monate
                                        </b-radio>
                                    </div>
                                    <div class="u-mt--8">
                                        <b-radio
                                            name="discount"
                                            native-value="6"
                                            v-model="coupon.discount">
                                            6 Monate
                                        </b-radio>
                                    </div>
                                    <div class="u-mt--8">
                                        <b-radio
                                            name="discount"
                                            native-value="12"
                                            v-model="coupon.discount">
                                            12 Monate
                                        </b-radio>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="c-col c-col-4 coupon-card">
                            <div class="c-form-element">
                                <div class="c-form-label">Gutscheincode</div>
                                <input
                                    required
                                    type="text"
                                    class="c-form-input"
                                    v-model="coupon.code"
                                    :disabled="$route.params.id === 'new'"
                                    :placeholder="$route.params.id === 'new' ? 'Wird mit dem Speichern generiert': ''"
                                />
                            </div>
                            <div class="c-form-element u-mt--16">
                                <div class="c-form-label">Gültig bis</div>
                                <input
                                    required
                                    type="date"
                                    class="c-form-input"
                                    placeholder="Gültig bis"
                                    v-model="coupon.valid_until"
                                />
                            </div>
                            <div class="c-form-element u-mt--16">
                                <div class="c-form-label">Ist die Laufzeit des Gutscheins kostenlos?</div>
                                <div class="u-mt--8">
                                    <b-radio
                                        name="free"
                                        native-value="true"
                                        v-model="coupon.free">
                                        Ja
                                    </b-radio>
                                </div>
                                <div class="u-mt--8">
                                    <b-radio
                                        name="free"
                                        native-value="false"
                                        v-model="coupon.free">
                                        Nein
                                    </b-radio>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="c-row u-mt--16">
                        <div class="c-col c-col-12">
                            <Button
                                theme="primary"
                                type="submit">
                                Speichern
                            </Button>
                        </div>
                    </div>
                </form>
            </TitledContainer>
        </LoadingComponent>
    </MenuTaskBar>
</template>

<script>
    import Helpers from '../../helpers'
    import APIFactory from '../../api/factory'
	import Button from '../../components/global/buttons/Button'
	import MenuTaskBar from '../../components/admin/menu/MenuTaskbar'
	import TitledContainer from '../../components/admin/TitledContainer'
	import LoadingComponent from '../../components/global/LoadingComponent'

	export default {
		computed: {
			title() {
				return this.$route.params.id === 'new' ? 'Neuer Gutschein' : 'Gutschein - Code XY'
            }
        },
		data() {
			return {
				coupon: {
					discount: 2
                },
				loading: false,
			}
		},
        methods: {
			save() {
				this.loading = true
                APIFactory.createCoupon(this.coupon).then(res => {
                	if (res && res.success) {
                		Helpers.callToast('is-success', 'Der Gutschein wurde erfolgreich angelegt')
                        this.$router.push('/admin/coupons')
                    }
                }).finally(() => this.loading = false)
            }
        },
		components: {
			Button,
			MenuTaskBar,
			TitledContainer,
			LoadingComponent,
		}
	}
</script>

<style lang="scss" scoped>
    .coupon-card {
        padding: 24px;
        background: $white;
        box-shadow: $box-shadow-folder;
        border: 1px solid darken($light-blue-color, 5%);
    }
</style>

<template>
  <div class="c-chapter-menu u-mt--24">
    <div
      class="c-chapter-menu-title"
      @click.prevent="$emit('selectChapter')">
      <div :class="{
            	'c-chapter-menu-text': true,
            	'c-chapter-menu-text--selected': selected,
            	'c-chapter-menu-text--highlighted': highlighted,
            }">
        <template v-if="folder.order_type && folder.order_type === 'number'">{{ number }}.</template>
        {{ title }}
      </div>
      <div class="c-chapter-menu-icon"
           @click.stop="toggleExpand"
           v-show="hasExpandable">
        <img :src="icon">
      </div>
    </div>
    <div class="c-chapter-menu-content u-mt--16" v-show="expanded">
      <slot/>
    </div>
  </div>
</template>

<script>
  export default {
    props: [
      'title',
      'number',
      'folder',
      'expanded',
      'selected',
      'highlighted',
      'has-expandable',
    ],
    computed: {
      icon() {
        return this.expanded
          ? 'images/icons/minus.svg'
          : 'images/icons/plus.svg'
      }
    },
    methods: {
      toggleExpand() {
        this.$emit('expanded')
      }
    }
  }
</script>

<style lang="scss" scoped>
  .c-chapter-menu {
    .c-chapter-menu-title {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

    .c-chapter-menu-text {
      width: 100%;
      font-size: 18px;
      font-weight: 500;
      padding: 4px 8px;
      font-family: Roboto;
      word-wrap: break-word;
      color: $darker-blue-color;

        &:hover,
        &.c-chapter-menu-text--selected {
          cursor: pointer;
          font-weight: bold;
          color: $primary-color;
        }

      &.c-chapter-menu-text--highlighted {
        background: $yellow-color;
        margin-right: 8px;
        border-radius: 4px;
      }
    }

      .c-chapter-menu-icon {
        cursor: pointer;
      }
    }
  }
</style>

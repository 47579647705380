<template>
  <div class="c-chapter-page">
    <img
      :src="chapterImage"
      v-if="chapterImage"
      class="animOpacityOut"
    />
    <div
      class="c-chapter-overlay"
      :style="chapterBackgroundStyle"
    />
    <div class="c-chapter-title">
      <div class="c-chapter-text">
        <template v-if="folder && folder.order_type === 'number'">{{ chapter.index }} -</template>
        {{ chapter.name }}
      </div>
      <div class="c-chapter-underline"/>
    </div>
  </div>
</template>

<script>
  export default {
    props: [
      'folder',
      'chapter',
    ],
    computed: {
      chapterImage() {
        if (this.chapter && this.chapter.content) {
          return this.chapter.content.preview_type === 'image' ? this.chapter.content.preview : ''
        }
      },
      chapterBackgroundStyle() {
        return {
          'background': this.chapter.color
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .c-chapter-page {
    position: relative;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .c-chapter-overlay {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      position: absolute;
      background-image: linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, .3))
    }

    .c-chapter-title {
      color: $white;
      position: absolute;
      word-break: break-word;
      top: calc(100% * (96 / 960));
      left: calc(100% * (72 / 648));
      right: calc(100% * (72 / 648));
      font-size: 2rem;

      .c-chapter-underline {
        width: 56px;
        height: 4px;
        margin-top: 8px;
        background: $yellow-color;
      }
    }
  }
</style>

<template>
  <b-card>
    <Loader v-if="loading"/>
    <div class="avatar__container">
      <div class="avatar__wrapper">
        <div class="avatar__image">
          <img
            :class="{'rounded': rounded}"
            :src="avatarPath">
          <div class="overlay"
               @click.prevent="open">
            <b-icon
              icon="file-upload"
              size="is-large"
            />
          </div>
          <input
            type="file"
            accept="image/*"
            ref="fileUpload"
            @change="upload"
            class="is-hidden"
          />
        </div>
      </div>
    </div>
  </b-card>
</template>

<script>
  import Loader from '../global/Loader'
  import APIFactory from '../../api/factory'
  import Helpers from '../../helpers'
  import {mapGetters} from 'vuex'

  export default {
    props: [
      'id',
      'path',
      'type',
      'rounded'
    ],
    computed: {
      avatarPath() {
        return this.path
          ? process.env.VUE_APP_API_URL + this.path
          : 'images/do-logo.svg'
      },
    },
    data() {
      return {
        loading: false,
      }
    },
    methods: {
      createAPIRequest(filename, fileData) {
        return this.type === 'user'
          ? APIFactory.uploadAvatar(this.id, filename, fileData)
          : APIFactory.uploadOrganisationAvatar(this.id, filename, fileData)
      },
      upload($event) {
        let fileReader = new FileReader()
        fileReader.onload = () => {
          let filename = $event.target.files[0].name
          let fileData = fileReader.result.replace(/^data:.+;base64,/, '')
          if (filename && fileData) {
            this.loading = true
            this.createAPIRequest(filename, fileData).then(res => {
              if (res && res.success) {
                Helpers.callToast('is-success', this.$t('settings.change_avatar_successful'))
                this.$store.commit('auth/setAvatar', fileReader.result)
                this.$emit('success')
              }
            }).finally(() => this.loading = false)
          }
        }
        fileReader.readAsDataURL($event.target.files[0])
      },
      open() {
        let ref = this.$refs.fileUpload
        if (ref) {
          ref.click()
        }
      }
    },
    components: {
      Loader
    }
  }
</script>

<style lang="scss" scoped>
  .avatar__container {
    display: flex;
    align-items: center;
    justify-content: center;

    .avatar__wrapper {
      .avatar__image {
        position: relative;
        margin-bottom: .5rem;

        img {
          width: 100%;
          height: 100%;
          max-width: 200px;
          max-height: 200px;
          object-fit: cover;
          border-radius: 8px;
          border: 3px solid $primary-color;

          &.rounded {
            width: 200px;
            height: 200px;
            border-radius: 50%;
          }
        }

        &:hover > .overlay {
          display: flex;
          cursor: pointer;
        }
      }

      .avatar__email,
      .avatar__name {
        text-align: center;
      }

      .overlay {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: none;
        position: absolute;
        align-items: center;
        color: $primary-color;
        justify-content: center;
      }
    }
  }
</style>

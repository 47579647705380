<template>
  <div class="help-bubble-wrapper">
    <div
      class="help-bubble"
      @click.prevent="toggle">
      <c-icon
        :src="iconPath"
        :key="iconPath"
        :size="iconSize">
      </c-icon>
    </div>
    <div
      v-if="showContents"
      class="help-bubble-content">
      <div class="hContents">
        <loading-component :load="loading">
            <slot />
        </loading-component>
      </div>
    </div>
  </div>
</template>

<script>
  import {mapGetters} from 'vuex'

  export default {
    props: [
      'loading',
    ],
    computed: {
      ...mapGetters({
        toggleBubble: 'help/toggleBubble'
      }),
      showContents() {
        return this.toggleBubble
      },
      iconSize() {
        return this.showContents
          ? 'medium'
          : 'xlarge'
      },
      iconPath() {
        return this.showContents
          ? 'images/icons/rework/close.svg'
          : 'images/icons/rework/info.svg'
      }
    },
    methods: {
      toggle() {
        let toggleState = !this.toggleBubble
        this.$store.commit('help/setToggleBubble', toggleState)
        if (!toggleState) {
          this.$store.commit('help/setGenericView', false)
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .help-bubble-wrapper {
    @apply
      p-2
      fixed
      w-full
      hidden
      right-2
      lg:flex
      bottom-2
      lg:w-auto
      lg:items-end
      lg:flex-col-reverse;
    z-index: 5100;

    .help-bubble {
      @apply
        w-16
        h-16
        flex
        flex-shrink-0
        shadow-lg
        text-white
        rounded-full
        items-center
        bg-df-primary
        cursor-pointer
        justify-center;
    }

    .help-bubble-content {
      @apply
        p-4
        mb-2
        w-full
        h-full
        border
        flex
        flex-col
        max-h-120
        max-w-100
        bg-white
        shadow-xl
        rounded-lg;

      .hContents {
        @apply
          w-full
          overflow-y-auto;
        height: calc(100% - 28px);
      }
    }
  }
</style>
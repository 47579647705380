<template>
  <div class="c-cover-designer">
    <div class="c-cover-designer-title">{{ $t('designer.cover') }}</div>
    <div class="c-cover-designer-content">
      <ColorDesigner
        :folder="folder"
        chapter-id="cover"
        v-if="mode === 'color-mode'"
      />
      <MediaDropzone
        :maximum-items="1"
        :item="cover.content"
        @place-item="placeItem"
        :containers="containers"
        :hide-context-menu="true"
        v-if="mode === 'cover-mode'"
        :file-extensions="['jpg', 'jpeg', 'png', 'gif', 'bmp']"
      />

      <b-tooltip
        size="is-small"
        :delay="tooltipDelay"
        class="c-cover-designer-toggle"
        :label="$t('designer.cover_tooltip')">
        <div @click="toggleCoverDesign">
          <img src="images/icons/designer.svg">
        </div>
      </b-tooltip>
    </div>
  </div>
</template>

<script>
  import MediaDropzone from '../MediaDropzone'
  import ColorDesigner from './ColorDesigner'
  import {mapGetters} from 'vuex'

  export default {
    props: [
      'folder',
      'containers'
    ],
    computed: {
      ...mapGetters({
        tooltipDelay: 'ui/tooltipDelay',
      }),
      cover() {
        if (this.folder && this.folder.chapters) {
          return this.folder.chapters.find(item => item.type === 'cover')
        }
      }
    },
    data() {
      return {
        mode: 'cover-mode'
      }
    },
    created() {
      if (this.cover && this.cover.content) {
        this.mode = 'cover-mode'
      } else {
        this.mode = 'color-mode'
      }
    },
    methods: {
      placeItem($event) {
        if (this.cover) {
          this.$set(this.folder, 'dirty', true)
          this.$set(this.cover, 'content', $event.items[0])
          this.$set(this.cover, 'cover_id', $event.items[0].id)
        }
      },
      toggleCoverDesign() {
        this.mode = this.mode === 'cover-mode'
          ? 'color-mode'
          : 'cover-mode'
        this.$set(this.cover, 'mode', this.mode)
      }
    },
    components: {
      MediaDropzone,
      ColorDesigner
    }
  }
</script>

<style lang="scss" scoped>
  .c-cover-designer {
    width: 100%;
    height: 100%;
    display: flex;
    padding: 32px 56px;
    border-radius: 8px;
    flex-direction: column;
    border: solid 1px $light-blue-color;

    @media (max-width: $breakpoint-mobile) {
      height: calc(100% - 48px);
      margin-top: 16px;
      padding: 16px;
    }

    .c-cover-designer-title {
      color: $darker-blue-color;
      font-family: Roboto;
      margin-bottom: 8px;
      font-weight: 500;
      font-size: 12px;
    }

    .c-cover-designer-content {
      width: 100%;
      height: 100%;
      display: flex;
      position: relative;
      border-radius: 8px;
      flex-direction: column;
      justify-content: space-between;
      border: 1px dashed $light-blue-color;

      .c-cover-designer-part {
        width: 100%;
        height: 100%;
        flex-shrink: 0;
        border-radius: 8px;
        flex-basis: calc(50% - 8px);
      }
    }
  }

  .c-cover-designer-toggle {
    top: 16px;
    width: 32px;
    right: 16px;
    height: 32px;
    align-items: center;
    background: $white;
    position: absolute;
    border-radius: 4px;
    justify-content: center;
    box-shadow: $box-shadow-context;

    div {
      display: flex;
    }

    &:hover {
      cursor: pointer;
      background: darken($white, 5%);
    }
  }
</style>

<template>
  <SidebarLayout
    :title="$t('settings.plans')"
    :centered="true">
    <template v-if="subscription">
      <b-message type="is-success" has-icon>{{ $t('settings.plan_info') }}</b-message>
      <ServiceGrid :change-plan-mode="true">
        <ServiceGridElement
          :idx="idx"
          :key="element.id"
          :element="element"
          :change-plan-mode="true"
          :current_user="subscription.users"
          :payment_interval="subscription.rawPaymentInterval"
          :selected="app.plan.startsWith(element.id)"
          v-for="(element, idx) in plans"
        />
      </ServiceGrid>
      <ConfirmModal
        :active="confirmModal"
        @close="confirmModal = false"
        :function="cancelSubscription"
        :title="$t('settings.cancel_subscription')"
        :message="$t('settings.cancel_subscription_message')"
      />
      <div class="subscription_wrapper">
        <div
          class="subscription_info"
          v-if="subscription && subscription.discount !== 12">
          {{ $t('settings.subscription_runtime', subscription) }}
        </div>
        <div
          class="subscription_info"
          v-if="subscription && subscription.discount === 12">
          {{ $t('settings.subscription_runtime_coupon', subscription) }}
        </div>
        <div class="subscription_info" v-if="subscription.canceled_at">{{ $t('settings.subscription_canceled_at') }}
          {{ subscription.canceled_at | date }}
        </div>
        <div class="subscription_info" v-if="subscription && subscription.trial_ends_at">
          {{ $t('settings.trial_ends_at') }}{{ subscription.trial_ends_at | date }}
        </div>
        <Button
          theme="primary"
          class="u-mt--8"
          :loading="loading"
          :disabled="loading"
          v-if="!subscription.canceled_at && subscription.plan !== 'free'"
          :disable-icon-view-only-on-mobile="true"
          @click="preflightCancelSubscription">
          {{ $t('settings.cancel_subscription') }}
        </Button>
      </div>
    </template>
  </SidebarLayout>
</template>

<script>
import {mapGetters} from 'vuex'
import Helpers from '../../helpers'
import Plans from '../../data/plans'
import APIFactory from '../../api/factory'
import Plan from '../../components/settings/Plan'
import Button from '../../components/global/buttons/Button'
import SidebarLayout from '../../components/settings/SidebarLayout'
import ServiceGrid from '../../components/signup/service/ServiceGrid'
import ConfirmModal from '../../components/global/modals/ConfirmModal'
import ServiceGridElement from '../../components/signup/service/ServiceGridElement'
import moment from 'moment'

export default {
  computed: {
    ...mapGetters({
      apps: 'auth/apps',
    }),
    app() {
      if (this.apps) {
        return this.apps.find(app => app.slug === this.$route.params.slug)
      }
    }
  },
  data() {
    return {
      loading: false,
      subscription: null,
      confirmModal: false,
      plans: Plans.findPlans()
    }
  },
  created() {
    this.load()
  },
  methods: {
    load() {
      this.loading = true
      APIFactory.findSubscriptionInfo(this.$route.params.slug).then(res => {
        if (res && res.success) {
          this.subscription = {
            start_date: moment(res.data.start_date).format('DD.MM.YYYY'),
            payment_interval: this.$t('signup.' + res.data.payment_interval),
            rawPaymentInterval: res.data.payment_interval,
            end_date: moment(res.data.end_date).format('DD.MM.YYYY'),
            trial_ends_at: res.data.trial_ends_at,
            discount: res.data.discount,
            canceled_at: res.data.canceled_at,
            plan: res.data.plan,
            users: res.data.users,
          }
        }
      }).finally(() => this.loading = false)
    },
    preflightCancelSubscription() {
      this.confirmModal = true
    },
    cancelSubscription() {
      this.loading = true
      return APIFactory.cancelSubscription(this.$route.params.slug).then(res => {
        if (res && res.success) {
          Helpers.callToast('is-success', this.$t('settings.cancel_subscription_success_message'))
        }
        this.load()
      }).finally(() => {
        this.confirmModal = false
        this.loading = false
      })
    }
  },
  components: {
    ConfirmModal,
    ServiceGridElement,
    SidebarLayout,
    ServiceGrid,
    Button,
    Plan
  }
}
</script>

<style lang="scss" scoped>
.subscription_wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.subscription_info {
  top: 0;
  left: 0;
  margin-top: 8px;
  font-size: 14px;
}
</style>

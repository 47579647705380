<template>
  <div :class="{'radio-input-wrapper': true, 'radio-input-wrapper--wrap': wrap }">
    <div
      :key="index"
      class="radio-input-row"
      v-for="(radioValue, index) in values">
      <input
        type="radio"
        :name="name"
        class="radio-input"
        :value="radioValue.id"
        :checked="radioValue.id === value"
        @change="$emit('input', radioValue.id)"
      />
      <div class="radio-input-label">{{ radioValue.label }}</div>
    </div>
  </div>
</template>

<script>
  export default {
    props: [
      'values',
      'value',
      'wrap'
    ],
    data() {
      return {
        name: Math.random()
      }
    },
  }
</script>

<style lang="scss" scoped>
  .radio-input-wrapper {
    @apply
      flex;

    &.radio-input-wrapper--wrap {
      @apply
        flex-wrap;

      .radio-input-row {
        @apply
          w-full;
      }
    }

    .radio-input-row {
      @apply
        mt-2
        flex
        mr-10
        items-center;

      .radio-input {
        @apply
          h-5
          w-5;
      }

      .radio-input-label {
        @apply
          ml-2
          text-sm
          text-df-dark-blue;
      }
    }
  }
</style>
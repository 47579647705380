<template>
  <MenuTaskBar>
    <LoadingComponent :load="loading">
      <TitledContainer
        title="Gutscheine"
        :has-controls="true">
        <template slot="controls">
          <Button
            theme="primary"
            @click="$router.push('/admin/coupons/new')">
            Neuer Gutschein
          </Button>
        </template>

        <table class="c-table">
          <thead>
          <tr>
            <th>#</th>
            <th>Gutscheinname</th>
            <th>Gutscheincode</th>
            <th>Gültig bis</th>
            <th>Tarif</th>
            <th>Status</th>
            <th>Eingelöst am</th>
            <th>Aktionen</th>
          </tr>
          </thead>
          <tbody>
            <tr v-for="(coupon, index) in coupons">
              <td>{{ index + 1 }}</td>
              <td>{{ coupon.name }}</td>
              <td>{{ coupon.code }}</td>
              <td>{{ coupon.valid_until | date }}</td>
              <td>{{ coupon.discount }} Monate kostenlos</td>
              <td>{{ coupon.status }}</td>
              <td>{{ coupon.used_at | date }}</td>
              <td>
                <c-button
                  color="red"
                  class="mt-1"
                  icon="images/icons/rework/delete.svg"
                  @click.prevent="deleteInformation = coupon.id">
                </c-button>
              </td>
            </tr>
          </tbody>
        </table>
        <confirm-modal
          title="Gutschein löschen?"
          v-if="deleteInformation"
          :active="deleteInformation"
          :function="preflightRemove"
          @close="deleteInformation = null"
          @success="deleteInformation = null"
          message="Soll der Gutschein wirklich gelöscht werden?">
        </confirm-modal>
      </TitledContainer>
    </LoadingComponent>
  </MenuTaskBar>
</template>

<script>
  import APIFactory from '../../api/factory'
  import Button from '../../components/global/buttons/Button'
  import MenuTaskBar from '../../components/admin/menu/MenuTaskbar'
  import TitledContainer from '../../components/admin/TitledContainer'
  import LoadingComponent from '../../components/global/LoadingComponent'
  import ConfirmModal from '../../components/global/modals/ConfirmModal'

  export default {
    data() {
      return {
        coupons: [],
        loading: false,
        deleteInformation: null,
      }
    },
    created() {
      this.load()
    },
    methods: {
      load() {
        this.loading = true
        APIFactory.findCoupons().then(res => {
          if (res && res.success) {
            this.coupons = res.data
          }
        }).finally(() => this.loading = false)
      },
      remove(id) {
        this.loading = true
        this.deleteInformation = null
        return APIFactory.removeCoupon(id).then(res => {
          if (res && res.success) {
            this.load()
          }
        }).finally(() => this.loading = false)
      },
      preflightRemove() {
        return this.remove(this.deleteInformation)
      }
    },
    components: {
      Button,
      ConfirmModal,
      MenuTaskBar,
      TitledContainer,
      LoadingComponent,
    }
  }
</script>

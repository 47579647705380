<template>
  <section class="c__radio-group-input">
    <RadioInput
      :name="name"
      :key="value.id"
      @select="select"
      :value="value.id"
      :disabled="disabled"
      :label="$te(value.label) ? $t(value.label) : value.label"
      v-for="(value, idx) in values"
      :current-value="currentValue"
      :class="{
        'c__radio-group-input--ml': idx > 0,
        'c__radio-group-input-mt': true,
      }"
    />
  </section>
</template>

<script>
  import RadioInput from './RadioInput'

  export default {
    props: [
      'value',
      'values',
      'disabled'
    ],
    data() {
      return {
        name: Math.random(),
        currentValue: 'file',
      }
    },
    created() {
      if (this.value) {
        this.currentValue = this.value
      }
    },
    methods: {
      select($event) {
        this.currentValue = $event
        this.$emit('input', this.currentValue)
      }
    },
    components: {
      RadioInput
    }
  }
</script>

<style lang="scss" scoped>
  .c__radio-group-input {
    display: flex;
    justify-content: flex-start;

    .c__radio-group-input--ml {
      margin-left: 56px;
    }

    .c__radio-group-input-mt {
      margin-top: 16px;
    }
  }
</style>

<template>
  <div class="c-menu-taskbar">
    <div class="c-taskbar">
      <MenuTaskbarEntry
        :key="entry.link"
        :icon="entry.icon"
        :link="entry.link"
        v-for="entry in entries"
      />
    </div>
    <div class="c-taskbar-content">
      <slot/>
    </div>
  </div>
</template>

<script>
  import MenuTaskbarEntry from './MenuTaskbarEntry'

  export default {
    data() {
      return {
        entries: [
          // { icon: 'images/icons/chart.svg', link: '/admin' },
          {icon: 'images/icons/home--white.svg', link: '/admin/info'},
          {icon: 'images/icons/rework/world.svg', link: '/admin/languages'},
          {icon: 'images/icons/tags.svg', link: '/admin/coupons'},
          {icon: 'images/icons/users.svg', link: '/admin/customers'},
          {icon: 'images/icons/rework/info.svg', link: '/admin/help-contents' },
          {icon: 'images/icons/chevron-left.svg', link: '/'},
        ]
      }
    },
    components: {
      MenuTaskbarEntry
    }
  }
</script>

<style lang="scss" scoped>
  .c-menu-taskbar {
    @apply
      flex
      w-full
      h-full;

    .c-taskbar {
      @apply
        flex
        h-full
        flex-col
        flex-shrink-0
        justify-center;
      flex-basis: 100px;
      background: $primary-color;
      box-shadow: $box-shadow-outer;
    }

    .c-taskbar-content {
      @apply
        w-full
        min-w-0;
      //flex-basis: calc(100% - 100px);
    }
  }
</style>

<template>
    <form @submit.prevent="submit">
        <b-message
            has-icon
            type="is-danger"
            v-if="invalidFields.length > 0">
            {{ $t('general.validation_failed') }}{{ translatedInvalidFields }}
        </b-message>
        <b-field
            :key="index"
            :label="$t(field.label)"
            v-for="(field, index) in fields">
            <b-input
                expanded
                :type="field.type"
                v-model="field.value"
            />
        </b-field>
        <b-button
            expanded
            type="is-primary"
            :loading="loading"
            :disabled="loading"
            native-type="submit">
            {{ $t(button) }}
        </b-button>
    </form>
</template>

<script>
    export default {
    	props: {
    		fields: {
    			type: Array,
                required: true,
            },
            button: {
    			type: String,
                required: true,
            },
            loading: Boolean,
        },
        data() {
    		return {
                invalidFields: []
            }
        },
        computed: {
    		translatedInvalidFields() {
    			if (this.invalidFields) {
	                return this.invalidFields.map(item => this.$t(item)).join(', ')
                }
            }
        },
    	methods: {
    		submit() {
                let invalidFields = this.fields.filter(item => item.required && !item.value)
                if (invalidFields.length > 0) {
	                this.invalidFields = invalidFields.map(item => item.label)
                	this.$emit('invalid', this.invalidFields)
                    return
                }
			    this.invalidFields = []
                this.$emit('submit')
            }
        }
    }
</script>
<template>
  <base-modal
    :active="active"
    @close="$emit('close')"
    :title="$tc('mediacenter.move_object')">

    <div class="c__rack-container">
      <Rack
        :key="container.id"
        :container="container"
        v-for="container in containers"
        @select="selectedContainer = container.id"
        :selected="selectedContainer === container.id"
      />
    </div>

    <div
      slot="actions"
      class="modal-controls modal-controls--spacing">
      <Button
        theme="default"
        @click="$emit('close')">
        {{ $t('general.cancel') }}
      </Button>
      <Button
        @click="move"
        theme="primary"
        :disabled="!selectedContainer">
        {{ $t('workspaces.move') }}
      </Button>
    </div>
  </base-modal>
</template>

<script>
import BaseModal from '../../global/modals/BaseModal'
import Button from '../../global/buttons/Button'
import APIFactory from '../../../api/factory'
import Helpers from '../../../helpers'
import Rack from '../Rack'

export default {
  props: [
    'active',
    'object_ids',
    'containers',
  ],
  data() {
    return {
      selectedContainer: null,
      loading: false,
    }
  },
  methods: {
    createAPIRequest() {
      return this.object_ids.length > 1
        ? APIFactory.moveAllMediaObjects(this.$route.params.slug, this.$route.params.teamId, this.object_ids, this.selectedContainer)
        : APIFactory.moveMediaObject(this.$route.params.slug, this.$route.params.teamId, this.object_ids[0], this.selectedContainer)
    },
    move() {
      this.loading = true
      this.createAPIRequest().then(res => {
        if (res && res.success) {
          Helpers.callToast('is-success', this.$tc('mediacenter.move_object_success_message', this.object_ids.length))
          this.$emit('success')
        }
      }).finally(() => this.loading = false)
    },
  },
  components: {
    Rack,
    Button,
    BaseModal
  }
}
</script>

<style lang="scss" scoped>
.c__rack-container {
  padding: 24px 0;
}
</style>

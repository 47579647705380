<template>
  <div class="c-invite-container">
    <b-loading :active="loading" v-if="loading"/>
    <div class="c-invite-form" v-else>
      <div class="c-language-switcher">
        <LanguageSwitcher/>
      </div>
      <div class="invite-header">
        <img :src="icon">
        <div class="invite-header-title">{{ appName }}</div>
      </div>
      <div class="invite-message">{{ $t('signup.welcome_message') }}</div>
      <form class="c-form invite-form"
            @submit.prevent="submit">
        <div class="c-form-element u-mt--8">
          <label class="c-form-label">{{ $t('signup.firstname') }}</label>
          <input
            required
            class="c-form-input"
            v-model="signup.firstname"
            :placeholder="$t('signup.firstname_placeholder')"
          />
        </div>
        <div class="c-form-element u-mt--8">
          <label class="c-form-label">{{ $t('signup.lastname') }}</label>
          <input
            required
            class="c-form-input"
            v-model="signup.lastname"
            :placeholder="$t('signup.lastname_placeholder')"
          />
        </div>
        <div class="c-form-element u-mt--8">
          <label class="c-form-label">{{ $t('signup.password_personal') }}</label>
          <input
            required
            type="password"
            class="c-form-input"
            v-model="signup.password"
            :placeholder="$t('signup.password_personal_placeholder')"
          />
        </div>
        <div class="c-form-element u-mt--8">
          <label class="c-form-label">{{ $t('signup.password_confirm') }}</label>
          <input
            required
            type="password"
            class="c-form-input"
            v-model="signup.password_confirm"
            :placeholder="$t('signup.password_placeholder')"
          />
        </div>

        <Button
          :type="submit"
          theme="primary"
          class="u-mt--32"
          :expanded="true"
          :loading="loading"
          :disable-icon-view-only-on-mobile="true">
          {{ $t('signup.signup_now') }}
        </Button>
      </form>
      <div class="c-invite-form-footer">
        <img src="images/do-logo.svg">
        <div>Powered by
          <a href="https://digital-folder.com" class="highlighted highlighted--link">digital-folder.com</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import LanguageSwitcher from '../../components/settings/LanguageSwitcher'
  import Button from '../../components/global/buttons/Button'
  import APIFactory from '../../api/factory'
  import Helpers from '../../helpers'

  export default {
    computed: {
      icon() {
        return this.inviteData && this.inviteData.icon
          ? process.env.VUE_APP_API_URL + this.inviteData.icon
          : 'images/do-logo.svg'
      },
      appName() {
        return this.inviteData && this.inviteData.name ? this.inviteData.name : this.$t('app.name')
      },
    },
    data() {
      return {
        signup: {},
        loading: false,
        inviteData: null,
      }
    },
    created() {
      this.load()
    },
    methods: {
      load() {
        this.loading = true
        APIFactory.findInvite(this.$route.params.slug, this.$route.params.code)
        .then(res => {
          if (res && res.success) {
            this.inviteData = res.data
          }
        })
        .finally(() => this.loading = false)
      },
      submit() {
        if (this.signup.password !== this.signup.password_confirm) {
          Helpers.callToast('is-danger', this.$t('signup.confirm_not_equal'))
          return
        }

        if (!Helpers.checkPassword(this.signup.password)) {
          Helpers.callToast('is-danger', this.$t('signup.password_needs_several_chars'))
          return
        }

        // Append language
        this.signup.language = 'de'
        if (this.$route.query && this.$route.query.lang) {
          this.signup.language = this.$route.query.lang
        }

        this.loading = true
        APIFactory.simpleSignup(this.$route.params.slug, this.$route.params.code, this.signup)
        .then((res) => {
          if (res && res.success) {
            Helpers.callToast('is-success', this.$t('signup.signup_success'))
            this.$router.push('/auth/login')
          }
        })
        .finally(() => this.loading = false)
      },
    },
    components: {
      Button,
      LanguageSwitcher,
    },
  }
</script>

<style lang="scss" scoped>
  .c-invite-container {
    width: 100%;
    height: 100%;
    padding: 32px;
    overflow-y: auto;
    justify-content: center;
    background: $light-blue-color;

    @media (max-width: $breakpoint-mobile) {
      padding: 0px;
    }

    .c-invite-form {
      width: calc(100% - 64px);
      margin: auto;
      padding: 32px;
      max-width: 480px;
      border-radius: 4px;
      text-align: center;
      background: $white;
      box-shadow: $box-shadow-outer;

      @media (max-width: $breakpoint-mobile) {
        margin: 0;
        width: 100%;
        padding: 16px;
        max-width: 100%;
        border-radius: 0;
      }

      .invite-header {
        font-size: 24px;
        font-weight: 500;
        font-family: Roboto;
        color: $darker-blue-color;

        img {
          max-width: 100%;
          max-height: 150px;
        }
      }

      .invite-message {
        margin-top: 16px;
      }

      .invite-form {
        margin-top: 24px;
        text-align: left;
      }
    }

    .c-invite-form-footer {
      display: flex;
      font-size: 12px;
      padding-top: 16px;
      align-content: center;
      justify-content: center;

      @media (max-width: $breakpoint-mobile) {
        padding-top: 16px;
      }

      img {
        width: 20px;
        height: 20px;
        margin-right: 8px;
      }
    }
  }
</style>

<template>
  <drop class="c__media-input" @drop="handleDrop">
    <label class="basic">{{ title }}</label>
    <div class="advice"
         v-if="fileTypeMismatch">
      {{ $t('mediacenter.filetype_mismatch') }}
    </div>
    <template v-if="!value || !value.value">
      <div @click="$refs.upload.click()"
           class="c__media-input-area">
        <input
            type="file"
            ref="upload"
            :accept="accept"
            @change="uploadData"
        />
        <div class="c__media-input-area-icon">
          <img src="/images/icons/states/page.svg">
        </div>
        <div class="c__media-input-area-title">{{ label }}</div>
      </div>
    </template>

    <!-- File is uploaded -->
    <template v-if="value && value.value">
      <div class="c__media-input-file">
        <div class="file-preview">
          <img :src="preview">
        </div>
        <div class="file-information">
          <div class="filename">{{ value.value }}</div>
          <div class="filesize">{{ (value.size / 1000) | int }} kB</div>
        </div>
        <div class="file-actions"
             @click.prevent="reset">
          <img src="images/icons/delete.svg">
        </div>
      </div>
    </template>
  </drop>
</template>

<script>
export default {
  props: [
    'label',
    'title',
    'value',
    'object',
    'accept',
    'disabled'
  ],
  computed: {
    preview() {
      // switch (this.object.preview_type) {
      //     case 'powerpoint':
      //     case 'excel':
      //     case 'word':
      //     case 'link':
      //         return '/images/objects/' + this.object.preview_type + '.svg'
      //     case 'image':
      //         return this.object.preview
      // }
      return '/images/objects/file.svg'
    }
  },
  data() {
    return {
      fileTypeMismatch: false,
    }
  },
  methods: {
    handleDrop($event, $nativeEvent) {
      if (this.disabled) {
        return
      }

      let file = $nativeEvent.dataTransfer.files.item(0)
      if (file) {
        let fileReader = new FileReader()
        fileReader.onload = () => {
          let b64Content = fileReader.result
          if (b64Content) {
            if (this.accept && this.accept.indexOf(file.type) < 0) {
              this.fileTypeMismatch = true
              return
            }

            this.fileTypeMismatch = false
            this.$emit('input', {
              extension: file.name.split('.').pop().toLowerCase(),
              content: b64Content.split(",")[1],
              value: file.name,
              size: file.size,
              type: 'file'
            })

            // Prefill name if not filled yet
            if (this.object && !this.object.name) {
              let name = file.name
              if (name && name.indexOf('.') > -1) {
                name = name.split('.')[0]
              }
              this.$emit('changedName', name)
            }
          }
        }
        fileReader.readAsDataURL($nativeEvent.dataTransfer.files.item(0))
      }
      $nativeEvent.preventDefault()
      $nativeEvent.stopPropagation()
    },
    uploadData($event) {
      let fileReader = new FileReader()
      fileReader.onload = () => {
        let b64Content = fileReader.result
        if (b64Content) {
          this.$emit('input', {
            type: 'file',
            mime_type: $event.target.files[0].type,
            value: $event.target.files[0].name,
            size: $event.target.files[0].size,
            content: b64Content.split(",")[1],
            extension: $event.target.files[0].name.split('.').pop().toLowerCase()
          })

          // Prefill name if not filled yet
          if (this.object && !this.object.name) {
            let name = $event.target.files[0].name.indexOf('.')
                ? $event.target.files[0].name.split('.')[0]
                : $event.target.files[0].name
            this.$emit('changedName', name)
          }
        }
      }
      fileReader.readAsDataURL($event.target.files[0])
    },
    reset() {
      if (!this.disabled) {
        this.$set(this.object, 'preview', null)
        this.$set(this.object, 'preview_type', null)
        this.$emit('input', {reset: true})
      }
    }
  },
  watch: {
    object: {
      deep: true,
      handler(val) {
        if (!val || !val.data) {
          this.$emit('reset', {})
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
input[type="file"] {
  display: none;
}

.c__media-input-file {
  display: flex;
  margin-top: 8px;
  padding: 14px 16px;
  border-radius: 4px;
  background: $light-blue-color;
  justify-content: space-between;

  .file-preview {
    width: 40px;
    height: 40px;

    img {
      width: 40px;
      height: 40px;
      object-fit: cover;
      border-radius: 4px;
    }
  }

  .file-information {
    min-height: 40px;
    width: calc(100% - 76px);

    .filename {
      font-size: 14px;
      font-weight: 500;
      word-break: break-all;
      color: $darker-blue-color;
      font-family: Roboto, 'sans-serif';
    }

    .filesize {
      font-size: 12px;
      font-family: Roboto, 'sans-serif';
    }
  }

  .file-actions {
    width: 20px;
    height: 40px;
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
  }
}

.c__media-input {
  .c__media-input-area {
    display: flex;
    margin-top: 8px;
    padding: 15px 0;
    cursor: pointer;
    border-radius: 4px;
    justify-content: center;
    border: 1px dashed $dark-gray-color;

    .c__media-input-area-icon {
      img {
        width: 16px;
        height: 16px;
      }
    }

    .c__media-input-area-title {
      font-size: 14px;
      font-weight: 500;
      margin-left: 8px;
      color: $primary-color;
      text-decoration: underline;
      font-family: Roboto, sans-serif;
    }
  }
}

.advice {
  color: $red-color;
  font-size: 12px;
  font-weight: 500;
  font-family: Roboto, sans-serif;
}
</style>
